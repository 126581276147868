import { yupResolver } from "@hookform/resolvers/yup";
import { companySelector } from "ducks/Company";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { styles } from "./styles";
import { licenseGenaralSchema } from "./Validations";

const defaultValues = {
  number: "",
  japaneseLocalGovernment: null,
  category: null,
  licensedOn: null,
  licenseExpirationOn: null,
  companyName: "",
  representativeName: "",
  remarks: "",
  inputType: "manual",
  files: [],
};

/**
 * 一般廃棄物許可証のフォームを表示するコンテナコンポーネントです。
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  id = "GeneralWaste",
  onSubmit,
  onError = (err) => debugLog(err),
  ...props
}) => {
  const classes = useClasses(styles);
  const company = useSelector(companySelector);

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(licenseGenaralSchema),
  });

  const { reset, control, handleSubmit, watch, formState, trigger } = methods;
  const { isDirty } = formState;

  useEffect(() => {
    reset({
      ...defaultValues,
      ...value,
      companyName: value?.companyName ?? company?.name,
      representativeName:
        value?.representativeName ?? company?.representativeName,
      licensedCompanyId: value?.licensedCompanyId ?? company?.id,
      postalCode: value?.postalCode ?? company?.headOfficeWorkplace?.postalCode,
      prefectures:
        value?.prefectures ?? company?.headOfficeWorkplace?.prefectures,
      city: value?.city ?? company?.headOfficeWorkplace?.city,
      streetAddress:
        value?.streetAddress ?? company?.headOfficeWorkplace?.streetAddress,
      otherAddress:
        value?.otherAddress ?? company?.headOfficeWorkplace?.otherAddress,
      files: value?.files,
      inputType: value?.isMapInput ? "from-map" : "manual",
    });
  }, [reset, value, company]);

  const licensedOn = watch("licensedOn");
  const licenseExpirationOn = watch("licenseExpirationOn");
  useEffect(() => {
    if (isDirty && licenseExpirationOn) {
      trigger("licenseExpirationOn");
    }
  }, [isDirty, licensedOn, licenseExpirationOn, trigger]);

  return render({
    classes: classes,
    control: control,
    methods: methods,
    id: id,
    submit: handleSubmit(onSubmit, onError),
    ...props,
  });
};
