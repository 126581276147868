import PropTypes from "prop-types";
import { DistanceMatrixService } from "@react-google-maps/api";

/**
 * 現在地から回収ポイントまでルートの表示を行うコンテナプレゼンテーションです
 * @param {object} directionsServiceOptions ルート
 * @param {func} calculateDistanceCallback
 * @returns {JSX.Element}
 */
export const Presententer = ({
  calculateDistanceCallback,
  directionsServiceOptions,
}) => {
  return (
    <DistanceMatrixService
      options={directionsServiceOptions}
      callback={calculateDistanceCallback}
    />
  );
};

Presententer.propTypes = {
  calculateDistanceCallback: PropTypes.func,
  directionsServiceOptions: PropTypes.object,
};
