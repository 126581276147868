import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";

//スクロールのスタイルが置き換えスタイルです。
export const overideStyleScrollbarVertical = (theme) => ({
  "&::-webkit-scrollbar": {
    width: "8px",
  },

  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:vertical ": {
    backgroundColor: "rgba(0, 0, 0, 0.18)",
    borderRadius: "4px",
  },

  "&::-webkit-scrollbar-thumb:horizontal ": {
    display: "none",
  },
  "&::-webkit-scrollbar-thumb:vertical": {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.28)",
    },
  },
});
export const overideStyleScrollbarHorizontal = (theme) => ({
  "&::-webkit-scrollbar": {
    height: "8px",
  },

  "&::-webkit-scrollbar-track": {
    //"-webkit-box-shadow": " inset 0 0 6px rgba(0,0,0,0.3)",
    backgroundColor: "transparent",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:horizontal ": {
    backgroundColor: "rgba(0, 0, 0, 0.18)",
    borderRadius: "4px",
  },

  "&::-webkit-scrollbar-thumb:vertical ": {
    display: "none",
  },
  "&::-webkit-scrollbar-thumb:horizontal": {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.28)",
    },
  },
});

export const overideStyleButton = (theme) => ({
  "&.MuiButtonBase-root": {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
});
export const placeholderTextSize = () => ({
  boxSizing: "content-box",
  "&::-webkit-input-placeholder": {
    /* Edge */ fontSize: "14px",
  },
  "&:-ms-input-placeholder": {
    /* Internet Explorer 10-11 */ fontSize: "14px",
  },
  "&::placeholder": {
    fontSize: "14px",
  },
});
export const requiredField = (theme) => ({
  backgroundColor: theme.palette.required.main,
  color: theme.palette.required.contrastText,
  padding: "3px 6px",
  borderRadius: "4px",
  fontSize: "12px",
  marginLeft: theme.spacing(1),
});

export const style_inputField = (theme) => ({
  style_inputField: {
    marginTop: `${theme.spacing(1)} !important`,
    "& .MuiOutlinedInput-input": {
      // color: theme.palette.text.primary,
    },
    "& textarea": {
      ...placeholderTextSize(),
    },

    "& input": {
      ...placeholderTextSize(),
    },
  },
  [theme.breakpoints.down("sm")]: {
    style_inputField: {
      "& input": {
        paddingLeft: theme.spacing(1),
      },
    },
  },
});

export const HiddenMobile = () => {
  const theme = useTheme();
  const isHidden = useMediaQuery(theme.breakpoints.down("sm"));
  return isHidden;
};
