import { Stack, Button, Box, FormHelperText } from "@mui/material";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Group } from "../Group";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/**
 * チェックシートのグループを複数入力・表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {array} props.value 値
 * @fires Groups#onChange 変更時
 * @param {array} props.errors エラーオブジェクト配列(react-hook-formのもの)
 * @param {boolean} props.disabled 無効設定
 * @returns {JSX.Element}
 */
export const Groups = ({
  value = [],
  onChange = () => {},
  errors,
  disabled = false,
  readOnly = false,
}) => {
  const handleGroupAdd = () => {
    onChange([...(value ?? []), { name: null, items: [{ name: null }] }]);
  };

  const handleGroupChange = (index) => (newGroup) => {
    const result = [...(value ?? [])];
    result.splice(index, 1, newGroup);
    onChange(result);
  };

  const handleDelete = (index) => () => {
    const result = [...(value ?? [])];
    result.splice(index, 1);
    onChange(result);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.type === "Groups") {
      onChange(reorder(value, result.source.index, result.destination.index));
    } else {
      const targetIndex = parseInt(result.type, 10);
      let rVal = JSON.parse(JSON.stringify(value));
      rVal[targetIndex].items = reorder(
        rVal[targetIndex].items,
        result.source.index,
        result.destination.index
      );
      onChange(rVal);
    }
  };

  return (
    <Stack spacing={2}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable" type="Groups">
          {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              <Stack spacing={2}>
                {value?.map((group, index) => (
                  <Group
                    key={index}
                    id={`Group${index}`}
                    value={group}
                    number={index + 1}
                    errors={errors?.[index]}
                    disabled={disabled}
                    onChange={handleGroupChange(index)}
                    onDelete={handleDelete(index)}
                    readOnly={readOnly}
                  />
                ))}
              </Stack>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {readOnly === false && (
        <Box display={"flex"} justifyContent="center">
          <Button
            variant="contained"
            onClick={handleGroupAdd}
            disabled={disabled}
          >
            {"新しい点検グループを追加"}
          </Button>
        </Box>
      )}
      <Box>
        <FormHelperText error={!!errors?.message}>
          {errors?.message}
        </FormHelperText>
      </Box>
    </Stack>
  );
};
