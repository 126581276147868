import { updateContainerProduct, removeContainerProduct } from "api/graphql/mutations";
import {
  getContainerProduct,
  searchContainerProductsByOffset,
  searchContainersByOffset,
} from "api/graphql/queries";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toApiValue, toFormValue } from "utils/ImageFunctions";
import { useMutation } from "utils/useMutation";
import { useQuery } from "utils/useQuery";
import { useSelector } from "react-redux";
import { companySelector } from "ducks/Company";
import { useOpener } from "utils/useOpener";

/**
 * コンテナ種類詳細を表示するコンテナコンポーネントです。
 * @return {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const categoryListForm = useOpener();
  const confirm = useOpener();
  const [formValue, setFormValue] = useState(null);
  const { id } = useParams();
  const company = useSelector(companySelector);
  const navigate = useNavigate();
  const containerProduct = useQuery({
    query: getContainerProduct,
    variables: {
      id: id,
    },
    onCompleted: ({ data }) => {
      toFormValue({ files: data?.getContainerProduct?.files ?? [] })
        .then((files) => {
          setFormValue({
            ...data?.getContainerProduct,
            files: files,
          });
        })
        .finally(() => {});
    },
  });

  const containers = useQuery({
    query: searchContainersByOffset,
    variables: {
      ownerCompanyId: company?.id,
      filter: {
        and: [{ productId: { eq: id } }],
      },
    },
  });

  const searchContainerProducts = useQuery({
    query: searchContainerProductsByOffset,
    variables: {
      ownerCompanyId: company?.id,
      filter: {
        and: [{ id: { eq: id } }],
      },
    },
  });

  const [updateMutation, updateMutationStatus] = useMutation(
    updateContainerProduct,
    {
      onCompleted: (data) => {
        containerProduct.refetch().then(() => {
          categoryListForm.toggle(false);
        });
        searchContainerProducts.refetch();
        containers.refetch();
      },
      succeedMessage: "保存しました。",
      errorMessage: "エラーが発生したため、保存できませんでした。",
    }
  );

  const [removeMutation, removeMutationStatus] = useMutation(
    removeContainerProduct,
    {
      onCompleted: (data) => {
        navigate({
          pathname: `/master/container/category/list`
        }, {
          replace: true
        });
      },
      succeedMessage: "削除しました。",
      errorMessage: "エラーが発生したため、削除できませんでした。",
    }
  );

  const handleUpdate = async (data) => {
    const {
      number,
      name,
      manufacturerName,
      modelNumber,
      width,
      height,
      depth,
      volume,
      remarks,
      files,
    } = data;

    updateMutation({
      input: {
        name: name,
        number: number,
        manufacturerName: manufacturerName,
        modelNumber: modelNumber,
        width: width,
        height: height,
        depth: depth,
        volume: volume,
        remarks: remarks,
        files: await toApiValue({ files: files }),
        id: id,
        expectedVersion: containerProduct?.data?.getContainerProduct?.version,
      },
    });
  };

  const handleRemove = () => {
    removeMutation({
      input: {
        id: id
      }
    });
  }

  const handleNotification = () => {
    searchContainerProducts.refetch();
    containers.refetch();
    containerProduct.refetch();
  };

  return render({
    ...props,
    id: id,
    value: formValue,
    numberOfInstallations:
      searchContainerProducts?.data?.searchContainerProductsByOffset?.items?.[0]
        ?.numberOfInstallations ?? 0,
    numberOfStorage:
      searchContainerProducts?.data?.searchContainerProductsByOffset?.items?.[0]
        ?.numberOfStorage ?? 0,
    totalOfOwned:
      searchContainerProducts?.data?.searchContainerProductsByOffset?.items?.[0]
        ?.totalOfOwned ?? 0,
    numberOfActives:
      searchContainerProducts?.data?.searchContainerProductsByOffset?.items?.[0]
        ?.numberOfActives ?? 0,
    containers: containers?.data?.searchContainersByOffset?.items ?? [],
    categoryListForm: categoryListForm,
    onUpdate: handleUpdate,
    onRemove: handleRemove,
    loading:
      updateMutationStatus.loading ||
      searchContainerProducts.loading ||
      containers.loading ||
      removeMutationStatus.loading,
    onNotification: handleNotification,
    confirm: confirm,
  });
};
