import { API, graphqlOperation } from "utils/graphqlOperation";
import { DateTime } from "luxon";
import { findIndex, find } from "lodash";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { infoDebugLog } from "./log";

/**
 * 初期状態
 */
const initState = {
  rows: [],
  filter: null,
  sort: null,
  errors: null,
  isLoading: false,
  pageSize: 100,
  page: 0,
  total: 0,
  columnVisibility: null,
};

/**
 * 行動種類
 */
const actions = {
  setSearch: "useXGridComponents/setSearch",
  setFilter: "useXGridComponents/setFilter",
  setSort: "useXGridComponents/setSort",
  beforeFetch: "useXGridComponents/fetch/before",
  succeeded: "useXGridComponents/fetch/succeeded",
  errors: "useXGridComponents/fetch/error",
  addRow: "useXGridComponents/add/row",
  setPageSize: "useXGridComponents/set/pageSize",
  setPage: "useXGridComponents/set/page",
  setTotal: "useXGridComponents/set/total",
  setColumnVisibility: "useXGridComponents/set/columnVisibility",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.setSearch:
      return {
        ...state,
        filter: action.payload.filter,
        sort: action.payload.sort,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
      };
    case actions.setFilter:
      return {
        ...state,
        filter: action.payload,
        sort: null,
        page: 0,
      };
    case actions.setSort:
      return {
        ...state,
        sort: action.payload,
      };
    case actions.beforeFetch:
      return {
        ...state,
        rows: [],
        isLoading: true,
      };
    case actions.succeeded:
      return {
        ...state,
        rows: action.payload.rows,
        total: action.payload.total,
        isLoading: false,
      };
    case actions.errors:
      return {
        ...state,
        errors: action.payload,
        isLoading: false,
      };
    case actions.addRow:
      return {
        ...state,
        rows: [...state.rows, action.payload],
      };
    case actions.setPageSize:
      return {
        ...state,
        pageSize: action.payload,
        page: 0,
      };
    case actions.setPage:
      return {
        ...state,
        page: action.payload,
      };
    case actions.setTotal:
      return {
        ...state,
        total: action.payload,
      };
    case actions.setColumnVisibility:
      return {
        ...state,
        columnVisibility: action.payload,
      };
    default:
      return state;
  }
};

/**
 * クエリ文字列から検索条件オブジェクトを取得します。
 * @param {string} queryString クエリ文字列
 * @param {string} name 名前
 * @returns {object}
 */
export const fromQueryString = (queryString, name = "") => {
  if (!queryString || queryString === "") {
    return null;
  }

  const urlSearchParams = new URLSearchParams(queryString);

  if (
    !urlSearchParams.has(`${name}filter`) &&
    !urlSearchParams.has(`${name}sort`) &&
    !urlSearchParams.has(`${name}pageSize`) &&
    !urlSearchParams.has(`${name}page`)
  ) {
    return null;
  }

  const result = {
    filter: JSON.parse(urlSearchParams.get(`${name}filter`)),
    sort: JSON.parse(urlSearchParams.get(`${name}sort`)),
    pageSize: JSON.parse(urlSearchParams.get(`${name}pageSize`)),
    page: JSON.parse(urlSearchParams.get(`${name}page`)),
  };

  return result;
};

/**
 * stateからfilter, sort, page, pageSizeをクエリ文字列にする
 * @param {object} state
 * @param {string} name
 * @returns {string}
 */
export const toQueryString = (state, name = "") => {
  if (!state) {
    return null;
  }

  if (!state?.filter && !state?.sort && !state?.pageSize && !state?.page) {
    return null;
  }

  const result = new URLSearchParams();
  result.set(`${name}filter`, JSON.stringify(state.filter));
  result.set(`${name}sort`, JSON.stringify(state.sort));
  result.set(`${name}pageSize`, JSON.stringify(state.pageSize));
  result.set(`${name}page`, JSON.stringify(state.page));
  return result.toString();
};

const arrayToObject = (arr) => {
  if (!arr || arr.length <= 0) {
    return null;
  }

  return Object.keys(arr)
    .filter((number) => !!number)
    .reduce((acc, number) => Object.assign(acc, arr[number]), {});
};

const margeOptions = (src, ...options) => {
  if (!src && options.filter((option) => !!option).length <= 0) {
    return null;
  }

  return {
    ...src,
    ...arrayToObject(options),
  };
};

const existsFilter = (source) => {
  if (!source) {
    return false;
  }

  const result = Object.keys(source).filter((property) => {
    return !!Object.values(source[property])[0];
  });

  return result.length > 0;
};

const existsFilters = (source) => {
  infoDebugLog(`useXGridComponent → existsFilters: ${JSON.stringify(source)}`);
  if (source) {
    const orResult = [...(source?.or ?? [])].filter((item) =>
      existsFilter(item)
    );
    const andResult = [...(source?.and ?? [])].filter((item) =>
      existsFilter(item)
    );

    const exists = andResult.length > 0 || orResult.length > 0;

    return exists
      ? {
          and: andResult.length > 0 ? andResult : undefined,
          or: orResult.length > 0 ? orResult : undefined,
        }
      : null;
  }

  return source;
};

const margeFilters = (srcFilter, optionFilter) => {
  const sourceFilter = existsFilters(srcFilter);

  infoDebugLog(
    `useXGridComponent → margeFilters: \nsrcFilter: ${JSON.stringify(
      srcFilter
    )}\nsourceFilter: ${JSON.stringify(
      sourceFilter
    )}\noptionFilter: ${JSON.stringify(optionFilter)}`
  );

  if (!optionFilter) {
    return sourceFilter;
  }

  const orResult = [...(optionFilter?.or ?? [])].concat(sourceFilter?.or ?? []);
  const andResult = [...(optionFilter?.and ?? [])].concat(
    sourceFilter?.and ?? []
  );

  infoDebugLog(
    `useXGridComponent → margeFilters: \norResult: ${JSON.stringify(
      orResult
    )}\nandResult: ${JSON.stringify(andResult)}`
  );

  return {
    and: andResult.length > 0 ? andResult : undefined,
    or: orResult.length > 0 ? orResult : undefined,
  };
};

const convertAPIOperatorToOperatorValue = (
  name,
  value,
  columnType = "string"
) => {
  switch (name) {
    case "exists":
      return value ? "isNotEmpty" : "isEmpty";
    case "matchPhrase":
      return "contains";
    case "eq":
      if (columnType === "number") {
        return "=";
      }

      if (columnType === "boolean") {
        return "is";
      }

      if (columnType === "singleSelect") {
        return "is";
      }

      return "equals";
    case "ne":
      if (columnType === "number") {
        return "!=";
      }

      if (!value) {
        return "isNotEmpty";
      }

      return "isNotEmpty";
    case "gt":
      if (columnType === "number") {
        return ">";
      }
      return "after";
    case "lt":
      if (columnType === "number") {
        return "<";
      }
      return "before";
    case "gte":
      if (columnType === "number") {
        return ">=";
      }
      return "onOrAfter";
    case "lte":
      if (columnType === "number") {
        return "<=";
      }
      return "onOrBefore";
    default:
      return null;
  }
};

const convertDataGridFilterOperatorToApiOperator = (type = "string", value) => {
  switch (value?.operatorValue ?? "") {
    case "startsWith":
    case "endsWith":
    case "contains":
      return "matchPhrase";
    case "equals":
    case "=":
      return "eq";
    case "is":
      if (type === "date") {
        return "matchPhrase";
      }

      return "eq";
    case "is not":
    case "!=":
    case "not":
      return "ne";
    case "after":
    case ">":
      return "gt";
    case "onOrAfter":
    case ">=":
      return "gte";
    case "before":
    case "<":
      return "lt";
    case "onOrBefore":
    case "<=":
      return "lte";
    default:
      return value.operatorValue;
  }
};

const convertApiSortToDataGridSort = (sortObject) => {
  if (!sortObject) {
    return [];
  }

  return [
    {
      field: sortObject.field,
      sort: sortObject.direction,
    },
  ];
};

const convertApiFilterToDataGridFilter = (columns, filter, initialFilter) => {
  if (!filter) {
    let field = "";
    if (initialFilter?.defaultColumnField) {
      field = initialFilter?.defaultColumnField;
    } else {
      field = find(columns, (item) => item?.field !== "__check__")?.field || "";
    }
    return {
      items: [
        {
          columnField: field,
          id: 1,
          operatorValue: "contains",
          value: "",
        },
      ],
      linkOperator: "and",
    };
  }

  if (!filter.and && !filter.or && initialFilter?.defaultColumnField) {
    return {
      items: [
        {
          columnField: initialFilter?.defaultColumnField,
          id: findIndex(columns, { field: initialFilter?.defaultColumnField }),
          operatorValue: "contains",
          value: "",
        },
      ],
      linkOperator: "and",
    };
  }

  return {
    items: (filter.or || filter.and)?.map((obj, index) => ({
      id: index + 1,
      columnField: Object.keys(obj)[0],
      operatorValue: convertAPIOperatorToOperatorValue(
        Object.keys(Object.values(obj)[0])[0],
        Object.values(Object.values(obj)[0])[0],
        columns?.find?.((column) => column.field === Object.keys(obj)[0])?.type
      ),
      value: Object.values(Object.values(obj)[0])[0],
    })),
    linkOperator: !!filter.or ? "or" : "and",
  };
};

/**
 * XGridComponentで使用する行データ、エラー情報、検索のハンドラを提供します。
 * @param {array} columns カラム
 * @param {string} query クエリ
 * @param {object} fixedOptions 固定のオプション
 * @param {object} initialState 初期状態
 * @returns {object} 行データ、エラー情報、検索のハンドラ
 */
export const useXGridComponents = (
  columns,
  query,
  fixedOptions,
  initialState
) => {
  if (!columns) {
    throw new Error("カラムが設定されてません。");
  }

  if (!query) {
    throw new Error("クエリが設定されてません。");
  }

  const [state, dispatch] = useReducer(reducer, {
    ...initState,
    ...initialState,
  });

  const filterModel = useMemo(() => {
    return convertApiFilterToDataGridFilter(
      columns,
      state?.filter,
      initialState?.filter
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(state?.filter)]);

  const handleSortModelChange = useCallback((newSortModel) => {
    if (
      JSON.stringify(toApiSortObject(newSortModel)) === JSON.stringify([]) &&
      !!initialState?.sort
    ) {
      if (initialState.sort.direction === "desc") {
        dispatch({
          type: actions.setSort,
          payload: null,
        });
      } else {
        dispatch({
          type: actions.setSort,
          payload: initialState?.sort ?? null,
        });
      }

      return;
    }

    if (
      !!initialState?.sort &&
      JSON.stringify(toApiSortObject(newSortModel)) ===
        JSON.stringify(initialState?.sort ?? null) &&
      JSON.stringify(toApiSortObject(newSortModel)) ===
        JSON.stringify(state?.sort ?? [])
    ) {
      dispatch({
        type: actions.setSort,
        payload: initialState?.sort ?? null,
      });

      return;
    }

    if (
      JSON.stringify(state?.sort ?? []) !==
      JSON.stringify(toApiSortObject(newSortModel))
    ) {
      dispatch({
        type: actions.setSort,
        payload: toApiSortObject(newSortModel),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOptions = useCallback(
    (filter, sort, offset, limit, other) => {
      return graphqlOperation(query, {
        ...{
          filter: filter,
          sort: sort,
          offset: offset,
          limit: limit,
        },
        ...other,
      });
    },
    [query]
  );

  const fetch = useCallback(
    (filter = null, sort = null, offset, limit) => {
      dispatch({
        type: actions.beforeFetch,
      });

      API.graphql(
        getOptions(
          margeFilters(filter, fixedOptions?.filter),
          margeOptions(initialState?.sort, sort, fixedOptions?.sort),
          offset,
          limit,
          fixedOptions?.other
        )
      )
        .then((response) => {
          const data = getData(response);
          dispatch({
            type: actions.succeeded,
            payload: {
              rows: data.items,
              total: data.total,
            },
          });
        })
        .catch((error) => {
          dispatch({
            type: actions.errors,
            payload: error,
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(fixedOptions), getOptions]
  );

  const refetch = () => {
    fetch(
      state.filter,
      state.sort,
      state.page * state.pageSize,
      state.pageSize
    );
  };

  useEffect(() => {
    fetch(
      state.filter,
      state.sort,
      state.page * state.pageSize,
      state.pageSize
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(state.filter),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(state.sort),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(state.page),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(state.pageSize),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(fixedOptions),
  ]);

  const setRows = (rows) => {
    dispatch({
      type: actions.succeeded,
      payload: rows,
    });
  };

  const getData = (response) => {
    return response.data[Object.keys(response.data)[0]];
  };

  const toApiSortObject = (sortModel) => {
    return sortModel?.length >= 1
      ? {
          field: sortModel[0].field,
          direction: sortModel[0].sort,
        }
      : null;
  };

  const getFilterObject = (filterModel) => {
    if (filterModel?.items?.length >= 1) {
      const result = {
        [filterModel.linkOperator]: filterModel.items.map((value, index) => {
          if (value.operatorValue === "isEmpty") {
            return {
              [value.columnField]: { exists: false },
            };
          } else if (value.operatorValue === "isNotEmpty") {
            return {
              [value.columnField]: { exists: true },
            };
          }

          let operator = convertDataGridFilterOperatorToApiOperator(
            columns.find((column) => column.field === value.columnField).type,
            value
          );

          return {
            [value.columnField]: {
              [operator]: value?.value ?? null,
            },
          };
        }),
      };

      if (result[filterModel.linkOperator]?.length <= 0) {
        return null;
      } else {
        return result;
      }
    }

    return null;
  };

  const handleFilterModelChange = useCallback((data) => {
    let filterObject = getFilterObject(data);
    if (!filterObject && initialState?.filter?.defaultColumnField) {
      filterObject = {
        defaultColumnField: initialState?.filter?.defaultColumnField,
      };
    }
    dispatch({
      type: actions.setFilter,
      payload: filterObject,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSearchFilterObject = (data) => {
    return data
      ? {
          or: getFilterObjects(columns, data),
        }
      : null;
  };

  const getFilterObjects = (columns, data) => {
    let result = [];
    for (const column of columns) {
      if (column?.type === "date") {
        const val = getFilterObjectDateItem(column, data);
        if (val != null) {
          result.push(val);
        }
      } else if (column?.type === "number") {
        const val = getFilterObjectNumberItem(column, data);
        if (val) {
          result.push(val);
        }
      } else if (column?.type === "none") {
      } else if (column?.type === "singleSelect") {
        const items = getFilterObjectSingleSelectItem(column, data);
        for (const item of items) {
          result.push(item);
        }
      } else {
        result.push({ [column.field]: { wildcard: `*${data}*` } });
      }
    }
    return result;
  };

  const getFilterObjectDateItem = (value, data) => {
    let result = null;

    if (data === null || data === "") {
      return result;
    }

    result = DateTime.fromFormat(data, "yyyy-MM-dd", { zone: "Asia/Tokyo" });

    if (result.isValid) {
      return {
        [value.field]: { eq: result.toFormat("yyyy-MM-dd") },
      };
    }

    result = DateTime.fromFormat(data, "yyyy/MM/dd", { zone: "Asia/Tokyo" });

    if (result.isValid) {
      return {
        [value.field]: { eq: result.toFormat("yyyy-MM-dd") },
      };
    }

    return null;
  };

  const getFilterObjectSingleSelectItem = (value, data) => {
    const items = value.valueOptions.filter((value) =>
      value.label.includes(data)
    );
    return items.map((item) => ({
      [value.field]: { matchPhrase: item.value },
    }));
  };

  const getFilterObjectNumberItem = (value, data) => {
    if (isNaN(data)) {
      return null;
    }
    return { [value.field]: { eq: parseInt(data) } };
  };

  const onSearchByText = (data) => {
    const filterObject = getSearchFilterObject(data);

    dispatch({
      type: actions.setFilter,
      payload: filterObject,
    });
  };

  const onPageSizeChange = (params) => {
    dispatch({
      type: actions.setPageSize,
      payload: params,
    });
  };

  const onPageChange = (params) => {
    dispatch({
      type: actions.setPage,
      payload: params,
    });
  };

  const onColumnVisibilityChange = (params, event, details) => {
    let result = details.api.getAllColumns();
    result = result.map((item) => ({
      ...item,
      hide: params.field === item.field ? !params.isVisible : item.hide,
    }));
    dispatch({
      type: actions.setColumnVisibility,
      payload: result,
    });
  };

  return {
    params: {
      paginationMode: "server",
      filterMode: "server",
      sortingMode: "server",
      onFilterModelChange: handleFilterModelChange,
      onSortModelChange: handleSortModelChange,
      onSearchByText,
      onPageSizeChange,
      onPageChange,
      onColumnVisibilityChange,
      filterModel: filterModel,
      sortModel: convertApiSortToDataGridSort(state?.sort),
      columns: state.columnVisibility ? state.columnVisibility : columns,
      loading: state.isLoading,
      pageSize: state.pageSize,
      page: state.page,
      rowCount: state.total,
      rows: state.rows,
    },
    functions: {
      setRows,
      refetch,
    },
    error: state.errors,
    search: {
      filter: state.filter,
      sort: state.sort,
      page: state.page,
      pageSize: state.pageSize,
    },
  };
};
