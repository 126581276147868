import { yupResolver } from "@hookform/resolvers/yup";
import { companySelector } from "ducks/Company";
import { useEffect, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { debugLog } from "utils/log";
import { SimpleFormDefaultValue } from "../../Utils";
import { basicSchema } from "./Validation";

/**
 * 紙マニフェスト簡易入力を表示するコンテナコンポーネントです。
 * @callback render
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onNext = (data) => debugLog("検証に成功: ", data),
  onError = (err) => debugLog("検証に失敗: ", err),
  ...props
}) => {
  const [open, setOpen] = useState(null);
  const methods = useForm({
    defaultValues: {
      ...SimpleFormDefaultValue,
      ...value,
    },
    resolver: yupResolver(basicSchema),
  });
  const company = useSelector(companySelector);

  useEffect(() => {
    methods.reset({
      ...SimpleFormDefaultValue,
      ...value,
    });
  }, [methods, value]);

  const handleSelectedClientWorkplace = (params) => {
    methods.setValue("dischargesWorkplace", params[0]);
    methods.setValue("wastes", null);
    methods.setValue("flow", null);
    methods.setValue("quantity", null);
    setOpen(null);
  };

  const handleSelectedWastes = (e) => {
    methods.setValue("wastes", e?.[0]);
    methods.setValue("flow", null);
    methods.setValue("quantity", {
      value: null,
      unit: e?.[0]?.quantityUnit,
    });
    setOpen(null);
  };

  const handleWasteFlowSelected = (params) => {
    methods.setValue("flow", params);
    setOpen(null);
  };

  useImperativeHandle(props._ref, () => ({
    next: async () => {
      const success = await methods.trigger();

      if (success) {
        const result = methods.getValues();
        try {
          onNext && onNext(result);
        } catch {
          onError && onError();
        }
      } else {
        onError && onError(methods.formState.errors);
      }
    },
  }));

  return render({
    company: company,
    value: methods.watch(),
    control: methods.control,
    open: open,
    onOpenDialog: (name) => () => {
      setOpen(name);
    },
    onCloseDialog: () => setOpen(null),
    onSelectedClientWorkplace: handleSelectedClientWorkplace,
    onSelectedWastes: handleSelectedWastes,
    onWasteFlowSelected: handleWasteFlowSelected,
    ...props,
  });
};
