import { useFormContext } from "react-hook-form";
import { styles } from "./styles";
import { useState } from "react";
import useClasses from "utils/useClasses";

/**
 * 基本情報を入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, isEditMode, ...props }) => {
  const classes = useClasses(styles);
  const { control, setValue, clearErrors } = useFormContext();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const handleSelected = (params) => {
    setSelectedValue(params);
  };

  const handleDecided = () => {
    setValue("targetWaste", selectedValue?.[0]);
    setOpen(false);
  };

  return render({
    selectedValue: selectedValue,
    open: open,
    classes: classes,
    control: control,
    clearErrors: clearErrors,
    onOpenModal: () => setOpen(true),
    onCloseModal: () => setOpen(false),
    onSelected: handleSelected,
    onDecided: handleDecided,
    isEditMode: isEditMode,
    ...props,
  });
};
