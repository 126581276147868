import yup, { dateSchema, textSchema, remarksSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  installationWorkplace: yup.object().required(),
  shippedOn: dateSchema.required(),
  installedOn: dateSchema.required(),
  vehicle: yup.object().nullable(),
  personInChargeName: textSchema,
  remarks: remarksSchema.nullable(),
});
