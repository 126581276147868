import { useSelector } from "react-redux";
import { selector } from "ducks/IndustrySectors";
import { debugLog } from "utils/log";
import lodash from "lodash";

/**
 * 業種を選択するコンテナコンポーネントです。
 * @param {string} label ラベル
 * @param {boolean} error エラー時
 * @param {string} helperText 補助的テキスト
 * @param {object | array} 選択値
 * @fires Container#onChange 変更時
 * @param {boolean} multiple 複数選択可
 * @return {JSX.Element}
 */
export const Container = ({
  render,
  label = "業種",
  error = false,
  helperText = "",
  value = "",
  onChange = (value) => debugLog(value),
  multiple = false,
  ...props
}) => {
  const state = useSelector(selector);

  const handleChange = (e) => {
    onChange(state.industrySectors.find((item) => item.id === e.target.value));
  };

  return render({
    label: label,
    value: value,
    industories: lodash.groupBy(
      lodash.orderBy(state.industrySectors, ["asc", "code"]),
      "largeClass.code"
    ),
    isLoading: state.isLoading,
    multiple: multiple,
    helperText: helperText,
    onChange: handleChange,
    error: error,
    ...props,
  });
};
