import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";
/**
 * ファイルのアップロードを表示するコンポーネントです
 * @param {string} label ラベル
 * @param {object} value ギャラリーの値
 * @param {object} onChange アップロードされたイメージを取得する
 * @param {object} multiple 複数のファイルを選択できるようになる
 */
export const UploadFilePDF = containerPresententer(Container, Presententer);
