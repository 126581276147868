import { InformationItem } from "views/atoms";
import { Stack, Skeleton } from "@mui/material";
import { formatDisplayAddress } from "utils/format";
import { getWorkplace } from "api/graphql/queries";
import { useQuery } from "utils/useQuery";

/**
 * 事業場の情報を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @returns {JSX.Element}
 */
export const Workplaces = ({ value }) => {
  const workplace = useQuery({
    query: getWorkplace,
    variables: {
      id: value?.installationWorkplaceId,
    },
  });

  if (workplace.loading) {
    return (
      <Stack spacing={2}>
        <Skeleton variant="rectangular" height={40} />
        <Skeleton variant="rectangular" height={40} />
        <Skeleton variant="rectangular" height={40} />
        <Skeleton variant="rectangular" height={40} />
        <Skeleton variant="rectangular" height={40} />
      </Stack>
    );
  }

  if (workplace?.error) {
    return <></>;
  }

  return (
    <Stack>
      <InformationItem label={"事業者"}>
        {value?.installationCompanyName ?? ""}
      </InformationItem>
      <InformationItem label={"事業場"}>
        {value?.installationWorkplaceName ?? ""}
      </InformationItem>
      <InformationItem label={"住所"}>
        {formatDisplayAddress(workplace?.data?.getWorkplace)}
      </InformationItem>
      <InformationItem label={"現在のコンテナ管理番号"}>
        {value?.number ?? ""}
      </InformationItem>
      <InformationItem label={"現在のコンテナ種類"}>
        {value?.productName ?? ""}
      </InformationItem>
    </Stack>
  );
};
