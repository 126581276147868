import { containerPresententer, withForwardRef } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * コンテナ移動入力フォームを表示するコンポーネントです。
 * @param {boolean} disabled 無効設定
 * @fires ExchangeForm#onSubmit 送信時
 * @fires ExchangeForm#onError エラー時
 */
export const MovementForm = withForwardRef(
  containerPresententer(Container, Presententer)
);
