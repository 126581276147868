import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * ダイアログを表示するコンポーネントです。
 * @param {boolean} open ダイヤログをオープンかオフにする
 * @param {func} onClose ダイヤログを閉じる
 * @param {func} title ダイアログのタイル
 * @param {array} tabs タブの内容　例　tabs:[{icon: "",label:"",content:""}]
 * @param {number} tab たプのナンバー
 */
export const FullScreenDialogStyle2 = containerPresententer(
  Container,
  Presententer
);
