import { Box, Grid, Button } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import {
  InputFieldPopppSelect,
  SelectPopup,
  ModalPopup,
} from "views/molecules";
import { ClientSelector, ClientWorkplaceSelector } from "views/molecules";

/**
 * 運搬区間情報を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Elemnt}
 */
export const Presententer = (props) => {
  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12}>
        <Box fontWeight="fontWeightBold">{props.index + 1}区間</Box>
      </Grid> */}
      <Grid item xs={9}>
        <Controller
          name={`transportProcesses[${props.index}].trustee`}
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <InputFieldPopppSelect
                label="運搬受託者"
                value={value}
                onChange={onChange}
                placeholder="運搬受託者を選択します。"
                required={true}
                showIcon={false}
                textConfirm="選択"
                error={error}
                onOpenModal={props.onOpenModal("client", value)}
                disabledButtom={true}
              />
              <ModalPopup
                open={props.open === "client"}
                onClose={props.onCloseModal}
              >
                <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                  <ClientSelector
                    value={props.selecedValue}
                    onSelected={props.onSelectedClient}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={props.onCloseModal}
                    sx={{
                      marginRight: "10px",
                    }}
                  >
                    {"キャンセル"}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={props.onDecidedClient}
                  >
                    {"決定"}
                  </Button>
                </Box>
              </ModalPopup>
            </>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`transportProcesses[${props.index}].method`}
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SelectPopup
              label="運搬方法"
              value={value}
              onChange={onChange}
              placeholder="未選択"
              required={true}
              error={error}
              options={props.transportationMethodOptions}
              optionValueField="code"
              optionNameField="name"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`transportProcesses[${props.index}].destination`}
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <InputFieldPopppSelect
                label="運搬先の事業場"
                value={value}
                onChange={onChange}
                placeholder="運搬先の事業場を選択します。"
                required={true}
                showIcon={false}
                textConfirm="選択"
                error={error}
                onOpenModal={props.onOpenModal("client-workplace", value)}
              />
              <ModalPopup
                open={props.open === "client-workplace"}
                onClose={props.onCloseModal}
              >
                <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                  <Box sx={{ height: "70vh" }}>
                    <ClientWorkplaceSelector
                      autoHeight={false}
                      value={props.selecedValue}
                      onSelected={props.onSelectedClientWorkplace}
                      fixedOptions={{
                        filter: {
                          or: [
                            // TODO: 運搬区間を複数にした場合、最終区間が処分場、それ以外は積替え保管場所で指定する必要あり。
                            /*{
                            categoryId: {
                              eq: "transshipmentStorageFacility",
                            },
                          },*/
                            {
                              categoryId: {
                                eq: "disposalPlant",
                              },
                            },
                          ],
                        },
                      }}
                    />
                  </Box>
                  <Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={props.onCloseModal}
                      sx={{
                        marginRight: "10px",
                      }}
                    >
                      {"キャンセル"}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={props.onDecidedClientWorkplace}
                    >
                      {"決定"}
                    </Button>
                  </Box>
                </Box>
              </ModalPopup>
            </>
          )}
        />
      </Grid>
    </Grid>
  );
};
