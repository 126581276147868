import { yupResolver } from "@hookform/resolvers/yup";
import { wasteDischargeIntervalsSelector } from "ducks/WasteDischargeIntervals";
import { wastePackingsSelector } from "ducks/WastePackings";
import { wasteQuantityUnitsSelector } from "ducks/WasteQuantityUnits";
import { useState, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { styles } from "./styles";
import { basicSchema } from "./Validations/basic";

const defaultValues = {
  number: null,
  type: undefined,
  kind: null,
  name: null,
  wastePacking: null,
  packingDetails: null,
  quantityUnit: undefined,
  weightConvertedValue: null,
  dischargeFrequency: {
    wasteDischargeInterval: undefined,
    numberOfTimes: null,
  },
  dischargeAmountPerOneTime: null,
  isImportedWaste: false,
};

/**
 * 事業場の廃棄物基本情報を登録編集するコンテナコンポーネントです。
 * @callback render
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onSubmit = (data) => debugLog("廃棄物基本情報フォーム.検証成功", data),
  onError = (err) => debugLog("廃棄物基本情報フォーム.検証失敗", err),
  onNotificationChanged = (data) =>
    debugLog("廃棄物基本情報フォーム.変更通知", data),
  editMode = false,
  ...props
}) => {
  const classes = useClasses(styles);
  const wasteQuantityUnits = useSelector(wasteQuantityUnitsSelector);
  const wastePackings = useSelector(wastePackingsSelector);
  const wasteDischargeIntervals = useSelector(wasteDischargeIntervalsSelector);
  const [open, setOpen] = useState(false);
  const { trigger, getValues, control, formState, setValue, watch } = useForm({
    mode: "onBlur",
    resolver: yupResolver(basicSchema),
    defaultValues: {
      ...defaultValues,
      ...value,
    },
  });

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      trigger().then((res) => {
        if (res) {
          onSubmit && onSubmit(getValues());
        } else {
          onError(formState.errors);
        }
      });
    },
  }));

  const handleSelected = (data) => {
    setValue("type", data[0]);
    setOpen(false);
  };

  const handleNotificationChanged = () => {
    onNotificationChanged(watch());
  };

  return render({
    wasteQuantityUnits: wasteQuantityUnits,
    wastePackings: wastePackings,
    wasteDischargeIntervals: wasteDischargeIntervals,
    classes: classes,
    control: control,
    open: open,
    onOpenModal: () => setOpen(true),
    onCloseModal: () => setOpen(false),
    onSelected: handleSelected,
    quantityUnitName: watch("quantityUnit")?.name ?? "",
    onNotificationChanged: handleNotificationChanged,
    editMode: editMode,
    setValue: setValue,
    ...props,
  });
};
