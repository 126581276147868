import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { XGridComponent } from "views/molecules";

/**
 * カウンターを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} proos.title タイトル
 * @param {number} props.value カウント
 * @returns {JSX.Element}
 */
export const Counter = ({ title = "", value = 0 }) => {
  return (
    <Paper elevation={1}>
      <Stack width={120} p={1}>
        <Box>
          <Typography
            sx={{
              color: "gray",
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: "right",
          }}
        >
          <Typography fontSize={24} fontWeight={"bold"}>
            {value}
          </Typography>
        </Box>
      </Stack>
    </Paper>
  );
};

/**
 * コンテナ管理の種類詳細 - 保管情報を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @return {JSX.Element}
 */
export const Presententer = ({
  value,
  numberOfInstallations,
  numberOfStorage,
  totalOfOwned,
  numberOfActives,
  onSelected,
  onClick,
}) => {
  return (
    <>
      <Stack spacing={2}>
        <Box
          display={"flex"}
          flexDirection="row"
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} spacing={1}>
            <Counter title={"総数"} value={numberOfActives} />
            <Counter title={"設置可能数"} value={numberOfStorage} />
            <Counter title={"設置数"} value={numberOfInstallations} />
          </Stack>
          <Box>
            <Button variant="contained" disabled={!value} onClick={onClick}>
              {"コンテナを追加する"}
            </Button>
          </Box>
        </Box>
        <Box>
          <XGridComponent
            rows={value ?? []}
            columns={[
              {
                field: "number",
                headerName: "管理番号",
                width: 200,
                flex: 1,
              },
              {
                field: "installationCompanyName",
                headerName: "設置/保管場所の事業者",
                width: 250,
                flex: 1,
              },
              {
                field: "installationWorkplaceName",
                headerName: "設置/保管場所の事業場",
                width: 250,
                flex: 1,
              },
              {
                field: "daysElapsedAfterInstallation",
                headerName: "設置日数",
                width: 100,
                renderCell: (params) => {
                  return params.row.daysElapsedAfterInstallation
                    ? `${params.row.daysElapsedAfterInstallation}日`
                    : "";
                },
              },
              {
                field: "state",
                headerName: "状態",
                renderCell: (params) => {
                  return params.value === "active" ? `稼働中` : "停止中";
                },
              },
            ]}
            onChangeSelection={onSelected}
            onDownload={false}
            onFilter={false}
            onSearcher={false}
            onColumns={false}
            titleTable={""}
          />
        </Box>
      </Stack>
    </>
  );
};
