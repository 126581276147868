import React from "react";
import { RenderButtonToolBar } from "utils/XgridExpand";
import { StepGuider } from "views/atoms";
import { XGridComponent, FullScreenDialog } from "views/molecules";
import { WorkplaceForm } from "../WorkplaceForm";

/**
 * 事業者・事業場の表示を行うプレゼンテーションコンポーネントです。
 * @param {array} rows　テーブルの行
 * @param {array} columns テーブルのコラム
 * @param {boolean} open ダイヤログが有効か無効を設定する
 * @param {func} onOpenDialog ダイヤログを開くイベントです
 * @param {object} value 現在の事業場情報です
 * @param {func} onChangeSelection テーブルのデータを更新する時のイベントです
 * @param {string} titleTable テーブルのタイトル
 * @param {func} onChangeDataSubmit 事業場フォームのデータを取得するイベントです
 * @returns
 */
export const Presententer = (props) => {
  return (
    <div>
      <XGridComponent
        rows={props.rows}
        columns={props.columns}
        value={props.value}
        onChangeSelection={props.onChangeSelection}
        checkboxSelection={false}
        multipleSelection={false}
        onDownload={true}
        titleTable={props.titleTable}
        actionButton={
          <RenderButtonToolBar
            label="新しい事業所・事業場を登録"
            onClick={props.onOpenDialog}
          />
        }
      />
      <FullScreenDialog
        formId={"workplace-form"}
        open={props.open}
        title="新しい事業場の登録"
        textConfirm="登録"
        onClose={props.onCloseDialog}
      >
        <WorkplaceForm
          id={"workplace-form"}
          onSubmit={props.onChangeDataSubmit}
        />
      </FullScreenDialog>
      <StepGuider value={props.GuiderSteps} />
    </div>
  );
};
