import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  DialogActions,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "views/atoms";
import { basicSchema } from "./Validation";
import { debugLog } from "utils/log";

const defaultValues = {
  // date: DateTime.now(),
  odometer: null,
};

/**
 * オドメーターを更新するフォームコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {boolean} props.open 開閉状態
 * @fires ODOMeterForm#onClose 閉じるとき
 * @param {boolean} disabled 無効設定
 * @fires ODOMeterForm#onSubmit 送信時
 * @fires ODOMeterForm#onError エラー時
 * @returns {JSX.Element}
 */
export const ODOMeterForm = ({
  value,
  open = false,
  onClose = () => {},
  disabled = false,
  onSubmit = () => {},
  onError = (err) => debugLog(err),
}) => {
  const { control, getValues, trigger, formState, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    reset({
      ...defaultValues,
      ...value,
    });
  }, [reset, value]);

  const handleClick = () => {
    trigger().then((res) => {
      if (res) {
        onSubmit && onSubmit(getValues());
      } else {
        onError(formState.errors);
      }
    });
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{"ODOメーターの記録を更新する"}</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          {/* <Controller
            name={`date`}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <DatePicker
                label="記録日"
                required={true}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
                disabled={disabled}
              />
            )}
          /> */}
          <Controller
            name={`odometer`}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                label="ODOメーターの値"
                required={true}
                placeholder="ODOメーターの値を入力します。"
                value={value}
                onChange={onChange}
                error={error}
                disabled={disabled}
              />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose} disabled={disabled}>
          {"キャンセル"}
        </Button>
        <Button autoFocus onClick={handleClick} disabled={disabled}>
          {"更新"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
