import WarningIcon from "@mui/icons-material/Warning";
import { Box, Button, Link, Paper, Stack, Typography } from "@mui/material";
import { useOpener } from "utils/useOpener";
import { ApplyForm } from "../ApplyForm";
import { DetailsFrame } from "views/organisms/DetailsFrame";

/**
 * EDI連携が未設定時に表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {boolean} props.disabled 無効設定
 * @fires NotSet#onNotificationChange 変更通知
 * @returns {JSX.Element}
 */
export const NotSet = ({
  onNotificationChange = () => {},
  disabled = false,
}) => {
  const form = useOpener();

  return (
    <DetailsFrame>
      <Paper
        elevation={1}
        sx={{
          p: 2,
        }}
      >
        <Stack spacing={2}>
          <Stack direction={"row"} justifyContent="right">
            <Button
              variant="contained"
              color="primary"
              onClick={() => form.toggle(true)}
              disabled={disabled}
            >
              EDI接続の利用を申請する
            </Button>
          </Stack>
          <Stack justifyContent="center">
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <WarningIcon color="warning" sx={{ fontSize: "2rem", mr: 1.5 }} />
              <Typography
                variant="h6"
                sx={{
                  color: "warning.main",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
                align="center"
              >
                JWNETとのEDI接続による連携設定が行われていません。
              </Typography>
            </Box>
          </Stack>
          <Stack justifyContent="center">
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14px",
              }}
            >
              <div>
                {
                  "※EDIシステムは、JWNETに登録して、EDI方式でJWNETに接続するシステムを言います。"
                }
              </div>
              <div>
                {"JWNETのEDI方式に関する詳細は"}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.jwnet.or.jp/jwnet/practice/info/edi/annnai.html"
                >
                  {"こちら"}
                </Link>
              </div>
            </Box>
          </Stack>
        </Stack>
      </Paper>
      <ApplyForm
        value={null}
        open={form.open}
        onClose={() => form.toggle(false)}
        onCompleted={onNotificationChange}
      />
    </DetailsFrame>
  );
};
