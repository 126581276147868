import { Box, Grid, Dialog, Button } from "@mui/material";
import React from "react";
import { PDFViewer, Copyright } from "views/atoms";

/**
 * 認証ページのテンプレートを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <div>
      <Grid container component="main" className={props.classes.root}>
        <Grid item xs={12} sm={8} md={5}>
          <Box className={props.classes.box_wraper_left}>
            <Box className={props.classes.box_logo} pt={4}>
              <img
                src={`${window.location.origin}/logo.png`}
                alt="logo"
                style={{
                  width: "350px",
                }}
              />
            </Box>
            <Box>{props.children}</Box>
            <Box pb={4}>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="text" onClick={props.onClickPrivacyPolicy}>
                  プライバシーポリシー
                </Button>
                <Button variant="text" onClick={props.onTermsService}>
                  サービス利用規約
                </Button>
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <Copyright />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={7} className={props.classes.image} />
      </Grid>
      <Dialog open={Boolean(props.pdf)} onClose={props.onClose}>
        <PDFViewer files={props.pdf} />
      </Dialog>
    </div>
  );
};
