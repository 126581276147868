import React from "react";
import { Panel } from "../Panel";
import { TextField } from "../TextField";

/**
 * ユーザープロフィールの表示を行うプレゼンテーションコンポーネントです
 * @param {string} value ユーザー情報
 */
export const Presententer = (props) => {
  return (
    <>
      <Panel
        title="基本情報"
        subTitle="一部の情報はサービスを利用する他のユーザーに表示される場合があります。"
      >
        <TextField label="名前" value="テスト　太郎" />
        <TextField label="組織名" value="株式会社フリーナー" />
        <TextField label="所属" value="" />
        <TextField label="役職" value="" />
      </Panel>
      <Panel title="連絡先情報">
        <TextField label="メール" value="test@greener.co.jp" />
        <TextField label="電話" value="" />
      </Panel>
    </>
  );
};
