import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  appBarMobile: {
    top: "auto !important",
    bottom: 0,
    "& .MuiToolbar-root": {
      justifyContent: "space-between",
      padding: 0,
    },
  },
  toolbarMobile: {
    "& .buttonMenuMobile": {
      minWidth: "auto ",
      padding: "8px 16px 2px",
      flexDirection: "column",
      justifyContent: "space-between",
      textTransform: "none",
      fontSize: "13px",
      "&.more": {
        "& .MuiButton-startIcon": {
          margin: 0,
          fontSize: "14px",
        },
        "& svg": {
          fontSize: "24px",
        },
      },

      "& .MuiListItemIcon-root": {
        minWidth: "auto",
        color: "#fff",
      },

      "& .MuiTypography-root": {
        fontSize: "12px",
      },
      "& svg": {
        fontSize: "22px",
      },
    },
    "& .list_menu": {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 0,
    },
  },

  activedMenuMobile: {},
}));

