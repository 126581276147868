import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { If } from "views/atoms";

/**
 * サイドメニューを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @param {array} props.routes ルート情報配列
 * @param {boolean} props.open 開閉状態
 * @param {object} props.classes
 * @param {boolean} props.exact
 * @param {string} props.subheader
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  const { routes, open, classes, exact, subheader } = props;

  return (
    <List subheader={<SubHeader label={subheader} open={open} />}>
      {routes.map((route, index) => (
        <SideMenuItem
          path={route.path}
          text={open ? route.title : route.shortTitle}
          icon={route.icon}
          key={index}
          classes={classes}
          exact={exact}
          open={open}
        />
      ))}
    </List>
  );
};

/**
 * リストのヘッダーを表示するコンポーネントです。
 * @param {object} props - プロパティ
 * @param {string} props.label - ラベル
 * @param {boolean} props.open - 開閉状態
 * @returns {JSX.Element}
 */
const SubHeader = ({ label, open = false }) => {
  if (!label || open === false) {
    return <></>;
  }

  return (
    <Stack
      sx={{
        m: 1,
        pb: 1,
        borderBottom: "1px solid grey",
      }}
    >
      <span
        style={{
          color: "grey",
          fontSize: "0.8rem",
        }}
      >
        {label}
      </span>
    </Stack>
  );
};

/**
 * サイドメニューのアイテムを表すプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 */
const SideMenuItem = ({ path, text, icon, exact, open }) => {
  const theme = useTheme();
  const renderLink = useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <NavLink
          style={({ isActive }) => ({
            backgroundColor: isActive && theme.palette.grey[200],
          })}
          to={path}
          ref={ref}
          end={exact}
          {...itemProps}
        />
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [path, exact]
  );

  return (
    <ListItem
      disablePadding
      sx={{
        "& a.active": {
          borderLeft: `5px solid ${theme.palette.secondary.light}`,
        },
      }}
    >
      <ListItemButton
        component={renderLink}
        sx={
          open === false
            ? {
                flexDirection: "column",
                justifyContent: "center",
                alignItem: "center",
              }
            : {}
        }
      >
        <If condition={!!icon}>
          <ListItemIcon
            sx={{
              color: "icon.mainMenu",
              "&.MuiListItemIcon-root": {
                display: open === false && "contents",
              },
            }}
          >
            {icon}
          </ListItemIcon>
        </If>
        <ListItemText
          primaryTypographyProps={{
            fontSize: open === false ? "0.5rem" : "0.9rem",
            color: "text.secondary",
          }}
          primary={text}
        />
      </ListItemButton>
    </ListItem>
  );
};
