import { API, graphqlOperation } from "utils/graphqlOperation";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCourseAutomationSetting,
  searchRegularlyWasteCollectionsOrdersByOffset,
  listVirtualCoursePointsByOffset,
  searchUserAccountsByOffset,
  searchVehiclesByOffset,
} from "api/graphql/queries";
import {
  deleteCourse,
  updateVirtualCourseOrder,
  updateAutomationSettingCourse,
  moveCollectionCyclesToTrash,
  createCourseAutomationSetting,
} from "api/graphql/mutations";

/**
 * @returns {object}
 */

const offset = 0;
// Be carefull while setting MAX_RETRY_ATTEMPTS because it executes MAX_RETRY_ATTEMPTS-1 times , which means if you set 5 it retry only 4 times.
const MAX_RETRY_ATTEMPTS = 5;

export const getAutomationSettings = async () => {
  return await API.graphql(graphqlOperation(getCourseAutomationSetting));
};

export const getUnassignedOrders = async (params) => {
  return await API.graphql(
    graphqlOperation(searchRegularlyWasteCollectionsOrdersByOffset, {
      ...params,
    })
  );
};

const onDeleteCourse = async (params) => {
  return await API.graphql(
    graphqlOperation(deleteCourse, {
      input: { ...params },
    })
  );
};

const getUserAccountsByOffset = async () => {
  return await API.graphql(graphqlOperation(searchUserAccountsByOffset));
};

const getVehiclesByOffset = async () => {
  return await API.graphql(graphqlOperation(searchVehiclesByOffset));
};

const updateAutomationSetting = async (params) => {
  return await API.graphql(
    graphqlOperation(updateAutomationSettingCourse, {
      input: params,
    })
  );
};

const updateCollectionCyclesToTrash = async (params) => {
  return await API.graphql(
    graphqlOperation(moveCollectionCyclesToTrash, {
      input: params,
    })
  );
};

const updateLogisticsWorkAllocationCourseOrder = async (params) => {
  return await API.graphql(
    graphqlOperation(updateVirtualCourseOrder, {
      input: params,
    })
  );
};

const getVirtualCoursePointsByOffset = async ({
  offset,
  limit,
  virtualCourseId,
}) => {
  let retryCount = 0;
  while (retryCount < MAX_RETRY_ATTEMPTS) {
    try {
      const response = await API.graphql(
        graphqlOperation(listVirtualCoursePointsByOffset, {
          offset,
          limit,
          virtualCourseId,
        })
      );
      return response;
    } catch (error) {
      retryCount++;
      if (retryCount === MAX_RETRY_ATTEMPTS) {
        throw error; // Throw the original error after maximum retries
      }
      console.warn(`Attempt ${retryCount} failed: ${error?.message}`);
    }
  }
};

export const createAutomationSetting = async () => {
  return await API.graphql(graphqlOperation(createCourseAutomationSetting));
};

export const fetchAutomationSettings = createAsyncThunk(
  "automationSettings/fetch",
  async (_, thunkAPI) => {
    try {
      const result = await getAutomationSettings();
      return result.data.getCourseAutomationSetting;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const fetchTrashedOrders = createAsyncThunk(
  "trashedUnassignedSettings/fetch",
  async (params, thunkAPI) => {
    try {
      const result = await getUnassignedOrders(params);
      return result.data.searchRegularlyWasteCollectionsOrdersByOffset;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const fetchUnassignedOrders = createAsyncThunk(
  "unassignedSettings/fetch",
  async (params, thunkAPI) => {
    try {
      const result = await getUnassignedOrders(params);
      return result.data.searchRegularlyWasteCollectionsOrdersByOffset;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const fetchAllData = createAsyncThunk(
  "data/fetchAllData",
  async (_, thunkAPI) => {
    const params = {
      limit: 10,
      offset,
      filter: {
        and: [
          {
            assigned: { eq: false },
          },
          {
            trashed: { eq: false },
          },
        ],
      },
    };
    try {
      const [result1, result2, result3] = await Promise.all([
        getAutomationSettings(),
        getUnassignedOrders(params),
        getUnassignedOrders({
          ...params,
          filter: {
            and: [
              {
                assigned: { eq: false },
              },
              {
                trashed: { eq: true },
              },
            ],
          },
        }),
      ]);
      return {
        done: result1.data.getCourseAutomationSetting,
        still: result2.data.searchRegularlyWasteCollectionsOrdersByOffset,
        trashed: result3.data.searchRegularlyWasteCollectionsOrdersByOffset,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const deleteCourseThunk = createAsyncThunk(
  "data/deleteCourse",
  async (params, thunkAPI) => {
    try {
      const result = await onDeleteCourse(params);
      return result.data.deleteCourse;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const fetchUserAccountsByOffset = createAsyncThunk(
  "data/fetchUserAccounts",
  async (_, thunkAPI) => {
    try {
      const result = await getUserAccountsByOffset();
      return result.data.searchUserAccountsByOffset;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const fetchVehiclesByOffset = createAsyncThunk(
  "data/fetchVehicles",
  async (_, thunkAPI) => {
    try {
      const result = await getVehiclesByOffset();
      return result.data.searchVehiclesByOffset;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const fetchVirtualCoursePointsByOffset = createAsyncThunk(
  "data/fetchVirtualCoursePoints",
  async (params, thunkAPI) => {
    try {
      const result = await getVirtualCoursePointsByOffset(params);
      return {
        points: result.data.listVirtualCoursePointsByOffset,
        virtualCourseId: params.virtualCourseId,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const updateCourseOrderThunk = createAsyncThunk(
  "data/updateCourseOrder",
  async (params, thunkAPI) => {
    try {
      const result = await updateLogisticsWorkAllocationCourseOrder(params);
      return result.data.updateVirtualCourseOrder;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const updateAutomationSettingThunk = createAsyncThunk(
  "data/updateAutomationSetting",
  async (params, thunkAPI) => {
    try {
      const result = await updateAutomationSetting(params);
      return result.data.updateAutomationSettingCourse;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const updateCollectionCyclesToTrashThunk = createAsyncThunk(
  "data/updateCollectionCyclesToTrash",
  async (params, thunkAPI) => {
    try {
      const result = await updateCollectionCyclesToTrash(params);
      return result.data.moveCollectionCyclesToTrash;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const fetchPointsInBackgroundThunk = createAsyncThunk(
  "points/fetchPointsInBackground",
  async (virtualCourseIds, thunkAPI) => {
    try {
      // Array to store promises for fetching points
      const fetchPromises = virtualCourseIds.map(async (virtualCourseId) => {
        const result = await getVirtualCoursePointsByOffset({
          virtualCourseId,
        });
        return {
          points: result.data.listVirtualCoursePointsByOffset,
          virtualCourseId: virtualCourseId,
        };
      });

      // Wait for all promises to resolve in parallel
      const results = await Promise.all(fetchPromises);

      return results;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const unassignedParallelSearch = createAsyncThunk(
  "unassignedParallelSearch/fetch",
  async (paramsArray, thunkAPI) => {
    try {
      // Ensure paramsArray is an array
      if (!Array.isArray(paramsArray)) {
        throw new Error("paramsArray must be an array.");
      }

      // Limit the number of params to three
      if (paramsArray.length > 3) {
        throw new Error("You can only pass up to three arrays in paramsArray.");
      }

      // Perform parallel API calls
      const results = await Promise.all(
        paramsArray.map((params) => getUnassignedOrders(params))
      );

      // Return the results in an array
      return results.map(
        (result) => result.data.searchRegularlyWasteCollectionsOrdersByOffset
      );
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);

export const createAutomationSettingsThunk = createAsyncThunk(
  "data/createAutomationSettings",
  async (_, thunkAPI) => {
    try {
      const result = await createAutomationSetting();
      return result.data.createCourseAutomationSetting;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.message,
      });
    }
  }
);
