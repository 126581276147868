import { WarningOutlined } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button, Stack, Typography } from "@mui/material";
import { useOpener } from "utils/useOpener";
import { If } from "views/atoms";
import { ApplyForm } from "../../ApplyForm";
import { isConfirming, isInvalid } from "../utils";
import { deleteJwnetMember } from "api/graphql/mutations";
import { ConfirmDialog } from "views/molecules";
import { useMutation } from "utils/useMutation";

/**
 * EDI接続の利用を申請する進捗を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @fires ApplyForEDIConnection#onNotificationChange 変更通知
 * @returns {JSX.Element}
 */
export const ApplyForEDIConnection = ({
  value = null,
  onNotificationChange = () => {},
}) => {
  const applyForm = useOpener();
  const removeForm = useOpener();

  const [removeMutation, removeMutationStatus] = useMutation(
    deleteJwnetMember,
    {
      onCompleted: () => {
        removeForm.toggle(false)
        onNotificationChange();
      },
      succeedMessage: "削除しました。",
      errorMessage: "エラーが発生したため、削除できませんでした。",
    }
  );

  if (!value) {
    return <></>;
  }

  return (
    <>
      <Stack spacing={1}>
        <Typography>{`加入者番号：${value?.number ?? ""}`}</Typography>
        <Typography>{`EDI利用確認キー：${
          value?.ediUseConfirmationKey ?? ""
        }`}</Typography>
        <If condition={isInvalid(value)}>
          <Stack>
            <Stack>
              <Typography
                color="secondary"
                sx={{ display: "flex", alignItems: "center", mb: 2 }}
              >
                <WarningOutlined
                  fontSize="large"
                  color="secondary"
                  sx={{ mr: 1 }}
                />
                手続きに失敗しました。申請内容を確認後に再度申請してください。
              </Typography>
            </Stack>
            <Stack>
              <Stack direction={"row"} spacing={1}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => removeForm.toggle(true)}
                >
                  {"削除"}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => applyForm.toggle(true)}
                >
                  {"修正"}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </If>
        <If condition={isConfirming(value)}>
          <Typography
            color="primary"
            sx={{ display: "flex", alignItems: "center", mb: 2 }}
          >
            <InfoOutlinedIcon fontSize="large" color="primary" sx={{ mr: 1 }} />
            EDI設定情報を確認中です。お時間がかかる場合がございます。
          </Typography>
        </If>
      </Stack>
      <ApplyForm
        value={value}
        open={applyForm.open}
        onClose={() => applyForm.toggle(false)}
        onCompleted={onNotificationChange}
      />
      <ConfirmDialog
        disabled={removeMutationStatus.loading}
        open={removeForm.open}
        title="削除確認"
        message="削除します。よろしいですか？"
        positiveText="はい"
        negativeText="いいえ"
        onPositive={() => {
          removeMutation({
            input: {
              id: value.id,
            },
          });
        }}
        onNegative={() => removeForm.toggle(false)}
      />
    </>
  );
};
