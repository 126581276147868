import awsmobile from "./aws-exports";
export const awsAmplifyConfig = {
  Auth: {
    Cognito: {
      userPoolClientId: awsmobile.aws_user_pools_web_client_id,
      userPoolId: awsmobile.aws_user_pools_id,
      identityPoolId: awsmobile.aws_cognito_identity_pool_id,
      allowGuestAccess: true,
    },
  },
  API: {
    GraphQL: {
      endpoint: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      defaultAuthMode: "userPool",
    },
  },
};
