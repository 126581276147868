import { onExitguiderSteps } from "ducks/GuiderSteps/slice";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
/**
 * @returns　JSX Element
 */
export const Container = ({ render, value = [], ...props }) => {
  const classes = useStyles();
  const { stepsEnabled, initialStep } = useSelector(
    (state) => state.GuiderSteps
  );
  const dispatch = useDispatch();
  const handleExitBeginner = () => {
    dispatch(onExitguiderSteps());
  };

  return render({
    classes: classes,
    stepsEnabled: stepsEnabled,
    steps: value,
    initialStep: initialStep,
    onExit: handleExitBeginner,
    ...props,
  });
};
