import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 整備内容を入力するコンポーネントです。
 * @param {boolean} open 開閉状態
 * @param {object} value 値
 * @fires MaintenanceForm#onClose 閉じる時
 * @fires MaintenanceForm#onSubmit 送信時
 * @fires MaintenanceForm#onError エラー時
 */
export const MaintenanceForm = containerPresententer(Container, Presententer);
