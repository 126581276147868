import React from "react";
import { If } from "views/atoms";

/**
 * 認証後のページ基盤のプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export function Presententer({ company, user, children }) {
  return (
    <>
      <If condition={!!company && !!user} elseComponent={<></>}>
        {children}
      </If>
    </>
  );
}
