import { DateTime } from "luxon";
import { companySelector } from "ducks/Company";
import { useSelector } from "react-redux";
import { get } from "lodash";
import {
  searchManagementVehiclesByOffset,
  searchVehicleRemindNotificationsByOffset,
} from "api/graphql/queries";
import {
  fromQueryString,
  toQueryString,
  useXGridComponents,
} from "utils/useXGridComponents";
import { formatDisplayDateFromISO } from "utils/format";
import { useNavigate, useLocation } from "react-router-dom";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

const columns = [
  {
    field: "modelName",
    headerName: "車種",
    minWidth: 160,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "number",
    headerName: "車輛ナンバー",
    minWidth: 170,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "parkingCertificateOwnerWorkplaceName",
    headerName: "保管場所",
    flex: 1,
    minWidth: 180,
    valueGetter: (params) =>
      `${params.row?.parkingCertificateOwnerCompanyName ?? ""} ${
        params.row?.parkingCertificateOwnerWorkplaceName ?? ""
      }`,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "instrumentUpdatedAt",
    headerName: "最終整備日",
    minWidth: 180,
    flex: 1,
    valueFormatter: (params) => {
      return params.value
        ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd")
        : "";
    },
    filterable: false,
  },
  {
    field: "certificateExpiredOn",
    headerName: "車検満了日",
    minWidth: 180,
    flex: 1,
    valueFormatter: (params) => {
      return params.value
        ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd")
        : "";
    },
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
];

const remindColumns = [
  {
    field: "vehicleNumber",
    headerName: "車輛ナンバー",
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "vehicleModelName",
    headerName: "車種",
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "remindName",
    headerName: "リマインド名称",
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "message",
    headerName: "リマインドメッセージ",
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "notificationAt",
    headerName: "リマインド発生日",
    flex: 1,
    renderCell: (params) => {
      return formatDisplayDateFromISO({ source: params?.value });
    },
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
];

/**
 * 車輛管理一覧を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const company = useSelector(companySelector);
  const xGridObject = useXGridComponents(
    columns,
    searchManagementVehiclesByOffset,
    {
      other: {
        ownerCompanyId: company?.id,
      },
    },
    {
      sort: {
        direction: "asc",
        field: "modelName",
      },
      ...fromQueryString(location.search),
      filter: {
        defaultColumnField: get(columns, "[1].field"),
      },
    }
  );

  const remindList = useXGridComponents(
    remindColumns,
    searchVehicleRemindNotificationsByOffset,
    {
      other: {
        ownerCompanyId: company?.id,
      },
    }
  );

  const handleSelectedVehicle = (params) => {
    navigate(
      {
        pathname: location.pathname,
        search: toQueryString(xGridObject.search),
      },
      {
        replace: true,
      }
    );

    navigate(`${location.pathname}/${params[0]}`);
  };

  const handleSelectedRemind = (params) => {
    navigate(
      `/vehicles-menu/list/${
        remindList.params.rows.find((row) => row.vehicleRemindId === params[0])
          ?.vehicleId
      }`
    );
  };

  return render({
    ...props,
    xGridParams: xGridObject.params,
    remindListParams: remindList.params,
    onSelectedVehicle: handleSelectedVehicle,
    onSelectedRemind: handleSelectedRemind,
  });
};
