import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messages: [],
};

const Alert = createSlice({
  name: "alert",
  initialState,
  reducers: {
    add: (state, action) => {
      state.messages.push(action.payload);
    },
    remove: (state, action) => {
      state.messages.splice(action.payload, 1);
    },
  },
});

export const { add, remove } = Alert.actions;

export const selector = (store) => store.Alert;

export default Alert.reducer;
