import { Box, Container, Paper } from "@mui/material";
import React from "react";

/**
 * セクションを表示するコンポーネントです。
 * @param {object} classes クラス名
 * @param {int} number ステップのナンバー
 * @param {string} title ステップのタイトル
 * @param {JSX.Elemnt} children ステップの内容
 * @returns {JSX.Elemnt} JSX 要素
 */

export const Presententer = (props) => {
  return (
    <Container maxWidth="md">
      <Paper elevation={3}>
        <Box padding={3} mb={4}>
          <Box className={props.classes.header}>
            {props.number ? (
              <span className={props.classes.number}>{props.number}</span>
            ) : null}

            {props.title}
          </Box>
          <Box p={3} pb={0}>
            {props.children}
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};
