import { useEffect, useState } from "react";

export const useMaintenanceListner = (url) => {
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  useEffect(() => {
    fetch(url)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        if (result.maintenaceActive) {
          setMaintenanceMode(true);
        } else {
          setMaintenanceMode(false);
        }
      })
      .catch((err) => {
        setMaintenanceMode(false);
      });
  }, [url]);

  return {
    maintenanceMode,
  };
};
