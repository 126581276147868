import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * muiのDataGridで使用するセルのツールチップを表すコンポーネントです。
 */
export const CellTooltip = containerPresententer(Container, Presententer);

/**
 * セルのツールチップを表示する処理です。はみ出した部分は[...]が表示されます。
 * GridColDefのrenderCellに付与して使用してください。
 * @param {object} params 値
 * @returns {JSX.Element}
 */
export const renderCellTooltip = (props) => (params) => {
  if (params.formattedValue) {
    return (
      <CellTooltip title={params.formattedValue} {...props}>
        {params.formattedValue}
      </CellTooltip>
    );
  } else {
    return <>{params?.formattedValue}</>;
  }
};
