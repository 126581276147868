import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 車輛管理詳細を表示するコンポーネントです。
 */
export const Details = containerPresententer(
  Container,
  Presententer
);
