import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";
import { withErrorBoundary } from "react-error-boundary";
import { Error } from "views/molecules";

/**
 * 電子マニフェストの詳細画面を表示するコンポーネントです。
 * @param {object} props プロパティ
 */
export const ElectronicManifestView = withErrorBoundary(
  containerPresententer(Container, Presententer),
  {
    FallbackComponent: (props) => <Error {...props} />,
    onError(error, info) {
      // Do something with the error
      // E.g. log to an error logging client here
      console.error(error, info);
    },
  }
);
