import reducer from "./slice";
import {
  selector,
  wasteReportingMediaSelector,
  isLoadingSelector,
  errorSelector,
} from "./slice";
import { fetchWasteReportingMedia } from "./api";

export default reducer;

export {
  wasteReportingMediaSelector,
  selector,
  isLoadingSelector,
  errorSelector,
  fetchWasteReportingMedia,
};
