import { FullScreenDialog } from "views/molecules";
import {
  Container,
  Paper,
  Stack,
  Box,
  Avatar,
  Typography,
  Checkbox,
  Button,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import {
  RadioGroup,
  DatePicker,
  TextField,
  TimePicker,
  EmailChipsField,
} from "views/atoms";
import { SelectPopup, FieldDecorateFrame } from "views/molecules";
import { basicSchema } from "./Validation";
import { getVehicleRemind } from "api/graphql/queries";
import { deleteVehicleRemind } from "api/graphql/mutations";
import { useQuery } from "utils/useQuery";
import { useMutation } from "utils/useMutation";

/**
 * ラベリングするコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} label ラベル
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 */
const Labeling = ({ label, children }) => {
  return (
    <Box>
      {label && (
        <Typography
          sx={{
            fontSize: "14px",
            color: "gray",
          }}
        >
          {label}
        </Typography>
      )}
      <Box>{children}</Box>
    </Box>
  );
};

const defaultValues = {
  name: null,
  message: null,
  criteria: "or",
  enableDate: false,
  date: null,
  enableODOmeter: false,
  odometer: null,
  enableCertificateDays: false,
  certificateDays: null,
  notificationTimes: null,
  enableRepeatDays: false,
  repeatDays: null,
  emails: [],
};

const isEmpty = (value) => {
  if (!value || value?.length === 0) {
    return true;
  }

  return false;
};

const toFormValue = (apiValue) => {
  if (!!apiValue) {
    const criteriaObj = isEmpty(apiValue?.criteria?.[0]?.and)
      ? apiValue?.criteria?.[0]?.or?.[0]
      : apiValue?.criteria?.[0]?.and?.[0];

    return {
      id: apiValue?.id,
      name: apiValue?.name,
      message: apiValue?.message,
      criteria: isEmpty(apiValue?.criteria?.[0]?.and) ? "or" : "and",
      enableDate: !!criteriaObj?.date,
      date: criteriaObj?.date?.gte,
      enableODOmeter: !!criteriaObj?.odometer,
      odometer: criteriaObj?.odometer?.gte,
      enableCertificateDays: !!criteriaObj?.certificateDays,
      certificateDays: !!criteriaObj?.certificateDays
        ? {
            code: criteriaObj?.certificateDays?.lte,
            name: criteriaObj?.certificateDays?.lte,
          }
        : null,
      notificationTimes:
        apiValue?.notifications?.[0]?.notificationAt?.substring(0, 5),
      repeatDays: {
        code: apiValue?.notifications?.[0]?.repeatDays,
        name: apiValue?.notifications?.[0]?.repeatDays,
      },
      emails: apiValue?.notifications?.[0]?.destinationEmails?.map(
        (destinationEmail) => destinationEmail?.email
      ),
      version: apiValue?.version,
    };
  } else {
    return null;
  }
};

/**
 * リマインドを入力するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} title タイトル
 * @param {boolean} open 開閉状態
 * @fires RemindForm#onClose 閉じる時
 * @param {object} value 値
 * @fires RemindForm#onSubmit 送信時
 * @fires RemindForm#onError エラー時
 * @param {boolean} disabled 無効時
 * @returns {JSX.Element}
 */
export const RemindForm = ({
  title = "新しいリマインドの登録",
  open,
  onClose = () => {},
  value,
  onSubmit = () => {},
  onError = () => {},
  onDeleted = () => {},
  disabled = false,
  customErrors = null,
}) => {
  const vehicleRemind = useQuery({
    query: getVehicleRemind,
    onCompleted: (params) => {
      reset({
        ...defaultValues,
        ...toFormValue(params.data?.getVehicleRemind),
      });
    },
    skip: true,
  });

  const [deleteVehicleRemindMutation, deleteVehicleRemindStatus] = useMutation(
    deleteVehicleRemind,
    {
      onCompleted: (params) => {
        onDeleted && onDeleted();
        onClose && onClose();
      },
    }
  );

  const { trigger, reset, watch, formState, control } = useForm({
    mode: "onBlur",
    resolver: yupResolver(basicSchema),
    defaultValues: {
      ...defaultValues,
      ...value,
    },
  });

  useEffect(() => {
    if (open) {
      if (!value) {
        reset({
          ...defaultValues,
        });
      } else {
        reset(defaultValues);
        vehicleRemind.refetch({
          id: value?.id,
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, open, value]);

  const handleDelete = () => {
    deleteVehicleRemindMutation({
      input: {
        id: value?.id,
      },
    });
  };

  const handleClickRegister = async () => {
    const result = await trigger();
    if (result) {
      onSubmit &&
        onSubmit({
          ...watch(),
          expectedVersion: value?.version,
        });
    } else {
      onError && onError(formState.errors);
    }
  };

  return (
    <FullScreenDialog
      title={title}
      textConfirm="保存"
      open={open}
      onClose={onClose}
      onClickSubmit={handleClickRegister}
      disabled={
        disabled || vehicleRemind.loading || deleteVehicleRemindStatus.loading
      }
      action={
        <>
          {value && (
            <Button
              variant="contained"
              color="error"
              onClick={handleDelete}
              disabled={
                disabled ||
                vehicleRemind.loading ||
                deleteVehicleRemindStatus.loading
              }
              sx={{
                mr: 2,
              }}
            >
              {"削除"}
            </Button>
          )}
        </>
      }
    >
      <Container>
        <Stack spacing={2}>
          <Paper
            sx={{
              p: 3,
            }}
          >
            <Stack spacing={2}>
              <Box display="flex" flexDirection={"row"} alignItems="center">
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    backgroundColor: "black",
                    mr: 2,
                  }}
                >
                  1
                </Avatar>
                <Typography
                  sx={{
                    fontSize: "18px",
                  }}
                >
                  {"基本項目"}
                </Typography>
              </Box>
              <Box>
                <Controller
                  name={`name`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="名称"
                      required={true}
                      placeholder="名称を入力します。"
                      value={value}
                      onChange={onChange}
                      error={!!error || !!customErrors?.name}
                      helperText={error?.message}
                      disabled={
                        disabled ||
                        vehicleRemind.loading ||
                        deleteVehicleRemindStatus.loading
                      }
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name={`message`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="リマインドメッセージ"
                      required={false}
                      placeholder="通知の際のメッセージを入力します。"
                      value={value}
                      onChange={onChange}
                      error={error || !!customErrors?.message}
                      rows={3}
                      multiline={true}
                      disabled={
                        disabled ||
                        vehicleRemind.loading ||
                        deleteVehicleRemindStatus.loading
                      }
                      fullWidth
                    />
                  )}
                />
              </Box>
            </Stack>
          </Paper>
          <Paper
            sx={{
              p: 3,
            }}
          >
            <Stack spacing={2}>
              <Box display="flex" flexDirection={"row"}>
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    backgroundColor: "black",
                    mr: 2,
                  }}
                >
                  2
                </Avatar>
                <Typography
                  sx={{
                    fontSize: "18px",
                  }}
                >
                  {"リマインド条件"}
                </Typography>
              </Box>
              <Box>
                <Controller
                  control={control}
                  name="criteria"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <RadioGroup
                      label="一致条件"
                      required={true}
                      disabled={
                        disabled ||
                        vehicleRemind.loading ||
                        deleteVehicleRemindStatus.loading
                      }
                      value={value}
                      onChange={onChange}
                      row={true}
                      options={[
                        { value: "or", label: "OR" },
                        { value: "and", label: "AND" },
                      ]}
                      error={Boolean(error || customErrors?.criteria)}
                      helperText={
                        error?.message || customErrors?.criteria?.message
                      }
                    />
                  )}
                />
              </Box>
              <Labeling label="日付">
                <Box display="flex" flexDirection={"row"}>
                  <Controller
                    name={`enableDate`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Checkbox
                        checked={value}
                        onChange={(event) => onChange(event.target.checked)}
                        disabled={
                          disabled ||
                          vehicleRemind.loading ||
                          deleteVehicleRemindStatus.loading
                        }
                      />
                    )}
                  />
                  <Controller
                    name={`date`}
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <Stack spacing={1} direction="row" alignItems={"center"}>
                        <DatePicker
                          value={value}
                          onChange={onChange}
                          error={!!error || customErrors?.date}
                          helperText={
                            error?.message || customErrors?.date?.message
                          }
                          disabled={
                            disabled ||
                            vehicleRemind.loading ||
                            deleteVehicleRemindStatus.loading ||
                            !watch()?.enableDate
                          }
                        />
                        <div>以降</div>
                      </Stack>
                    )}
                  />
                </Box>
              </Labeling>
              <Labeling label="ODOメーター">
                <Box display="flex" flexDirection={"row"}>
                  <Controller
                    name={`enableODOmeter`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Checkbox
                        checked={value}
                        onChange={(event) => onChange(event.target.checked)}
                        disabled={disabled}
                      />
                    )}
                  />
                  <Controller
                    name={`odometer`}
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <Stack
                          spacing={1}
                          direction="row"
                          alignItems={"center"}
                        >
                          <TextField
                            value={value ?? null}
                            type="number"
                            onChange={onChange}
                            error={!!error || !!customErrors?.odometer}
                            helperText={
                              error?.message || customErrors?.odometer?.message
                            }
                            placeholder="ODOメーターを入力します。"
                            disabled={
                              disabled ||
                              vehicleRemind.loading ||
                              deleteVehicleRemindStatus.loading ||
                              !watch()?.enableODOmeter
                            }
                          />
                          <div>以上</div>
                        </Stack>
                      );
                    }}
                  />
                </Box>
              </Labeling>
              <Labeling label="車検満了日">
                <Box display="flex" flexDirection={"row"} alignItems="center">
                  <Controller
                    name={`enableCertificateDays`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Checkbox
                        checked={value}
                        onChange={(event) => onChange(event.target.checked)}
                        disabled={
                          disabled ||
                          vehicleRemind.loading ||
                          deleteVehicleRemindStatus.loading
                        }
                      />
                    )}
                  />
                  <Controller
                    name={`certificateDays`}
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <SelectPopup
                        hideLabel={true}
                        required={false}
                        onChange={onChange}
                        value={value}
                        error={error || customErrors?.certificateDays}
                        options={[...Array(100)].map((_, i) => ({
                          code: i + 1,
                          name: i + 1,
                        }))}
                        optionValueField="code"
                        optionNameField="name"
                        disabled={
                          disabled ||
                          vehicleRemind.loading ||
                          deleteVehicleRemindStatus.loading ||
                          !watch()?.enableCertificateDays
                        }
                      />
                    )}
                  />
                  <Typography
                    sx={{
                      ml: 2,
                    }}
                  >
                    {"日前"}
                  </Typography>
                </Box>
              </Labeling>
            </Stack>
          </Paper>
          <Paper
            sx={{
              p: 3,
            }}
          >
            <Stack spacing={2}>
              <Box display="flex" flexDirection={"row"}>
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    backgroundColor: "black",
                    mr: 2,
                  }}
                >
                  3
                </Avatar>
                <Typography
                  sx={{
                    fontSize: "18px",
                  }}
                >
                  {"通知条件"}
                </Typography>
              </Box>
              <Box>
                <FieldDecorateFrame label="通知の送信時間" required={true}>
                  {(params) => (
                    <Box>
                      <Controller
                        name={`notificationTimes`}
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <TimePicker
                            variant="outlined"
                            onChange={onChange}
                            value={value}
                            error={!!error || !!customErrors?.notificationTimes}
                            helperText={
                              error?.message ||
                              customErrors?.notificationTimes?.message
                            }
                            minutesStep={30}
                            disabled={
                              disabled ||
                              vehicleRemind.loading ||
                              deleteVehicleRemindStatus.loading
                            }
                          />
                        )}
                      />
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "gray",
                        }}
                      >
                        {
                          "※ネットワーク等で遅延が発生するため、通知時間を保障するものではありません。"
                        }
                      </Typography>
                    </Box>
                  )}
                </FieldDecorateFrame>
              </Box>
              <Labeling label="通知の繰り返し">
                <Box display="flex" flexDirection={"row"}>
                  <Controller
                    name={`enableRepeatDays`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Checkbox
                        checked={value}
                        onChange={(event) => onChange(event.target.checked)}
                        disabled={
                          disabled ||
                          vehicleRemind.loading ||
                          deleteVehicleRemindStatus.loading
                        }
                      />
                    )}
                  />
                  <Controller
                    name={`repeatDays`}
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <Stack spacing={1} direction="row" alignItems={"center"}>
                        <SelectPopup
                          hideLabel={true}
                          required={false}
                          onChange={onChange}
                          value={value}
                          error={error || customErrors?.repeatDays}
                          options={[...Array(10)].map((_, i) => ({
                            code: i + 1,
                            name: i + 1,
                          }))}
                          optionValueField="code"
                          optionNameField="name"
                          disabled={
                            disabled ||
                            vehicleRemind.loading ||
                            deleteVehicleRemindStatus.loading ||
                            !watch()?.enableRepeatDays
                          }
                        />
                        <div>{"日ごとに通知する"}</div>
                      </Stack>
                    )}
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "gray",
                  }}
                >
                  {"※リマインド条件を満たしている間、通知を繰り返します。"}
                </Typography>
              </Labeling>
              <Box>
                <Controller
                  name={`emails`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <>
                      <EmailChipsField
                        label="メールアドレス"
                        required={true}
                        value={value}
                        onChange={onChange}
                        error={!!error || !!customErrors?.emails}
                        helperText={
                          error?.message || customErrors?.emails?.message
                        }
                        disabled={
                          disabled ||
                          vehicleRemind.loading ||
                          deleteVehicleRemindStatus.loading
                        }
                      />
                    </>
                  )}
                />
              </Box>
            </Stack>
          </Paper>
        </Stack>
      </Container>
    </FullScreenDialog>
  );
};
