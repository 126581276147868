/**
 * 自由入力可能なオートコンプリートコンテナコンポーネントです。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({ render, onChange = () => {}, ...props }) => {
  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return render({
    onChange: handleChange,
    ...props,
  });
};
