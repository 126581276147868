import { useEffect, useState } from "react";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { getTodayAssignedWasteCollectionScheduleCount } from "api/graphql/queries";
import { debugLog } from "utils/log";

export const Container = ({ render, ...props }) => {
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);

  const load = () => {
    setLoading(true);
    return API.graphql(graphqlOperation(getTodayAssignedWasteCollectionScheduleCount))
      .then((res) => {
        setValue(res.data.getTodayAssignedWasteCollectionScheduleCount);
      })
      .catch((err) => {
        debugLog("getTodayAssignedWasteCollectionScheduleCountの呼び出し失敗: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    load();
  }, []);

  return render({
    value: value,
    loading: loading,
    ...props,
  });
};
