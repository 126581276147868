import React from "react";
import { InformationItem } from "views/atoms";
import { DateTime } from "luxon";

/**
 * 基本情報のタブの表示を行うプレゼンテーションコンポーネントです
 * @param {object} value 事業場情報
 */
export const Presententer = (props) => {
  const { value } = props;
  return (
    <div>
      <InformationItem label="車輛ナンバー">{value?.number}</InformationItem>
      <InformationItem label="車種">{value?.modelName}</InformationItem>
      <InformationItem label="車輛証明事業場">
        {value?.parkingCertificateOwnerWorkplace?.name}
      </InformationItem>
      <InformationItem label="最大積載量">
        {value?.loadCapacity && `${value?.loadCapacity}kg`}
      </InformationItem>
      <InformationItem label="車輛総重量">
        {value?.totalWeight && `${value?.totalWeight}kg`}
      </InformationItem>
      <InformationItem label="乗車定員">
        {value?.ridingCapacity && `${value?.ridingCapacity}人`}
      </InformationItem>
      <InformationItem label="車輛購入年月日">
        {value?.purchasedOn ? DateTime.fromISO(value?.purchasedOn).toFormat("yyyy/MM/dd") : ""}
      </InformationItem>
      <InformationItem label="車輛初年度登録">
        {value?.firstYearRegistrationOn
          ? DateTime.fromISO(value?.firstYearRegistrationOn).toFormat("yyyy/MM/dd")
          : ""}
      </InformationItem>
      <InformationItem label="備考">{value?.remarks}</InformationItem>
    </div>
  );
};
