import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * ファイルアップローダーを表示するコンポーネントです
 * @param {string} label ラベル
 * @param {string} dropzoneText ドロップゾーンのテキスト
 * @param {string[]} accept react-dropzoneのものと同様(https://react-dropzone.js.org/, https://developer.mozilla.org/en-US/docs/Web/API/window/showOpenFilePicker)
 * @param {number} maxSize 最大ファイルサイズ
 * @param {boolean} multiple 複数アップロードできるか
 * @param {disabled} disabled 無効設定
 * @param {number} maxFiles 最大ファイル数
 * @param {number} minSize 最小ファイルサイズ
 * @param {boolean} error エラーであるか
 * @param {string} helperText 補助的テキスト
 * @fires FileUploader#onChange 変更時
 * @returns JSX.Element
 */
export const Uploader = containerPresententer(Container, Presententer);
