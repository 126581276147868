import { Container, Fade, Modal } from "@mui/material";
import clsx from "clsx";

/**
 * モーダルポップアップを表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={clsx(props.classes.modal, props.className)}
      open={props.open}
      onClose={props.onClose}
      closeAfterTransition={true}
      disableEnforceFocus
    >
      <Fade in={props.open}>
        <Container maxWidth="md" className="modal_container">
          {props.children}
        </Container>
      </Fade>
    </Modal>
  );
};
