import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { debugLog } from "utils/log";
import { basicSchema } from "./Validations/basic";
import { useParams } from "react-router-dom";

const defaultValues = {
  dischargesWorkplace: undefined,
  transporter: undefined,
  wastes: undefined,
  remark: "",
  cycles: undefined,
};

/**
 * 定期回収を入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @fires Container#onSubmit 送信時
 * @fires Container#onError エラー時
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onSubmit = (data) => debugLog(data),
  onError = (err) => debugLog(err),
  ...props
}) => {
  const { id, workplaceId } = useParams();
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    methods.reset({ ...defaultValues, ...value });
  }, [methods, value]);

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      methods.trigger().then((res) => {
        if (res) {
          onSubmit(methods.getValues());
        } else {
          onError(methods.formState.errors);
        }
      });
    },
  }));

  return render({
    methods: methods,
    id: id,
    workplaceId: workplaceId,
    ...props,
  });
};
