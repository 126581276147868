/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const confirmContainerLocation = /* GraphQL */ `
  mutation ConfirmContainerLocation($input: ConfirmContainerLocationInput!) {
    confirmContainerLocation(input: $input) {
      id
    }
  }
`;
export const createContainer = /* GraphQL */ `
  mutation CreateContainer($input: CreateContainerInput!) {
    createContainer(input: $input) {
      id
      product {
        id
        number
        name
        manufacturerName
        modelNumber
        width
        height
        depth
        volume
        remarks
        state
        ownerCompanyId
        files {
          name
          url
          order
          label
        }
        version
      }
      additionalType {
        id
        order
        name
        removedAt
      }
      addedOn
      manageWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
        belongInCompanyId
        belongInCompanyName
        precautions {
          id
          name
          message
          fileCount
          ownerCompanyId
          lastUpdatedOn
        }
      }
      installationLocationCategory
      installationWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
        belongInCompanyId
        belongInCompanyName
        precautions {
          id
          name
          message
          fileCount
          ownerCompanyId
          lastUpdatedOn
        }
      }
      installedOn
      number
      remarks
      state
      files {
        name
        url
        order
        label
      }
      version
    }
  }
`;
export const createContainerAdditionalTypeWithIAM = /* GraphQL */ `
  mutation CreateContainerAdditionalTypeWithIAM(
    $input: CreateContainerAdditionalTypeWithIAMInput!
  ) {
    createContainerAdditionalTypeWithIAM(input: $input) {
      id
      order
      name
      removedAt
    }
  }
`;
export const createContainerDisuseTypeWithIAM = /* GraphQL */ `
  mutation CreateContainerDisuseTypeWithIAM(
    $input: CreateContainerDisuseTypeWithIAMInput!
  ) {
    createContainerDisuseTypeWithIAM(input: $input) {
      id
      order
      name
      removedAt
    }
  }
`;
export const createContainerInBulk = /* GraphQL */ `
  mutation CreateContainerInBulk($input: CreateContainerInBulkInput!) {
    createContainerInBulk(input: $input) {
      id
    }
  }
`;
export const createContainerProduct = /* GraphQL */ `
  mutation CreateContainerProduct($input: CreateContainerProductInput!) {
    createContainerProduct(input: $input) {
      id
      number
      name
      manufacturerName
      modelNumber
      width
      height
      depth
      volume
      remarks
      state
      ownerCompanyId
      files {
        name
        url
        order
        label
      }
      version
    }
  }
`;
export const installContainer = /* GraphQL */ `
  mutation InstallContainer($input: InstallContainerInput!) {
    installContainer(input: $input) {
      id
    }
  }
`;
export const removeContainer = /* GraphQL */ `
  mutation RemoveContainer($input: RemoveContainerInput!) {
    removeContainer(input: $input) {
      id
    }
  }
`;
export const removeContainerAdditionalTypeWithIAM = /* GraphQL */ `
  mutation RemoveContainerAdditionalTypeWithIAM(
    $input: RemoveContainerAdditionalTypeWithIAMInput!
  ) {
    removeContainerAdditionalTypeWithIAM(input: $input) {
      id
    }
  }
`;
export const removeContainerDisuseTypeWithIAM = /* GraphQL */ `
  mutation RemoveContainerDisuseTypeWithIAM(
    $input: RemoveContainerDisuseTypeWithIAMInput!
  ) {
    removeContainerDisuseTypeWithIAM(input: $input) {
      id
    }
  }
`;
export const removeContainerProduct = /* GraphQL */ `
  mutation RemoveContainerProduct($input: RemoveContainerProductInput!) {
    removeContainerProduct(input: $input) {
      id
    }
  }
`;
export const removeInstallationContainer = /* GraphQL */ `
  mutation RemoveInstallationContainer(
    $input: RemoveInstallationContainerInput!
  ) {
    removeInstallationContainer(input: $input) {
      id
    }
  }
`;
export const replaceContainer = /* GraphQL */ `
  mutation ReplaceContainer($input: ReplaceContainerInput!) {
    replaceContainer(input: $input) {
      id
    }
  }
`;
export const resumeUseContainer = /* GraphQL */ `
  mutation ResumeUseContainer($input: ResumeUseContainerInput!) {
    resumeUseContainer(input: $input) {
      id
    }
  }
`;
export const resumeUseContainerProduct = /* GraphQL */ `
  mutation ResumeUseContainerProduct($input: ResumeUseContainerProductInput!) {
    resumeUseContainerProduct(input: $input) {
      id
    }
  }
`;
export const stopUsingContainer = /* GraphQL */ `
  mutation StopUsingContainer($input: StopUsingContainerInput!) {
    stopUsingContainer(input: $input) {
      id
    }
  }
`;
export const stopUsingContainerProduct = /* GraphQL */ `
  mutation StopUsingContainerProduct($input: StopUsingContainerProductInput!) {
    stopUsingContainerProduct(input: $input) {
      id
    }
  }
`;
export const transferContainer = /* GraphQL */ `
  mutation TransferContainer($input: TransferContainerInput!) {
    transferContainer(input: $input) {
      id
    }
  }
`;
export const updateContainer = /* GraphQL */ `
  mutation UpdateContainer($input: UpdateContainerInput!) {
    updateContainer(input: $input) {
      id
      product {
        id
        number
        name
        manufacturerName
        modelNumber
        width
        height
        depth
        volume
        remarks
        state
        ownerCompanyId
        files {
          name
          url
          order
          label
        }
        version
      }
      additionalType {
        id
        order
        name
        removedAt
      }
      addedOn
      manageWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
        belongInCompanyId
        belongInCompanyName
        precautions {
          id
          name
          message
          fileCount
          ownerCompanyId
          lastUpdatedOn
        }
      }
      installationLocationCategory
      installationWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
        belongInCompanyId
        belongInCompanyName
        precautions {
          id
          name
          message
          fileCount
          ownerCompanyId
          lastUpdatedOn
        }
      }
      installedOn
      number
      remarks
      state
      files {
        name
        url
        order
        label
      }
      version
    }
  }
`;
export const updateContainerProduct = /* GraphQL */ `
  mutation UpdateContainerProduct($input: UpdateContainerProductInput!) {
    updateContainerProduct(input: $input) {
      id
      number
      name
      manufacturerName
      modelNumber
      width
      height
      depth
      volume
      remarks
      state
      ownerCompanyId
      files {
        name
        url
        order
        label
      }
      version
    }
  }
`;
export const createCourseAutomationSetting = /* GraphQL */ `
  mutation CreateCourseAutomationSetting {
    createCourseAutomationSetting {
      id
      courses {
        id
        name
        departureTime
        assignedVehicle {
          id
          number
          modelOfVehicleName
        }
        assignedUsers {
          id
          name
        }
        pointTotal
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const createCycleCategoryWithIAM = /* GraphQL */ `
  mutation CreateCycleCategoryWithIAM(
    $input: CreateCycleCategoryWithIAMInput!
  ) {
    createCycleCategoryWithIAM(input: $input) {
      id
      order
      name
      removedAt
    }
  }
`;
export const createIncreasedRegularlyWasteCollectionSchedule = /* GraphQL */ `
  mutation CreateIncreasedRegularlyWasteCollectionSchedule(
    $input: CreateIncreasedRegularlyWasteCollectionScheduleInput!
  ) {
    createIncreasedRegularlyWasteCollectionSchedule(input: $input) {
      id
      wasteGeneratorCompany {
        id
        name
        phoneticGuidesOfName
      }
      wasteCollectionWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
      }
      wasteCarrierCompany {
        id
        name
        phoneticGuidesOfName
      }
      waste {
        id
        number
        type {
          id
          largeClass {
            code
            name
            removedAt
          }
          middleClass {
            code
            name
            removedAt
          }
          smallClass {
            code
            name
            removedAt
          }
          group {
            id
            name
            removedAt
          }
          removedAt
        }
        kind
        name
        packing {
          code
          name
          removedAt
        }
        packingDetails
        quantityUnit {
          code
          name
          removedAt
        }
        weightConvertedValue
        weightConvertedValueUnit {
          id
          order
          name
          removedAt
        }
        dischargeFrequency {
          interval {
            id
            order
            name
            removedAt
          }
          numberOfTimes
        }
        dischargeAmountPerOneTime
        isImportedWaste
        state
        dischargeSourceWorkplaceId
        version
      }
      wasteQuantity
      scheduleDate
      scheduleTimeRangeStart
      scheduleTimeRangeEnd
      createdAt
      updatedAt
      version
    }
  }
`;
export const createIndividualityWasteCollectionOrder = /* GraphQL */ `
  mutation CreateIndividualityWasteCollectionOrder(
    $input: CreateIndividualityWasteCollectionOrderInput!
  ) {
    createIndividualityWasteCollectionOrder(input: $input) {
      id
      wasteGeneratorCompany {
        id
        name
        phoneticGuidesOfName
      }
      wasteCollectionWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
      }
      wasteCarrierCompany {
        id
        name
        phoneticGuidesOfName
      }
      scheduleDate
      scheduleTimeRangeStart
      scheduleTimeRangeEnd
      remarks
      wastes {
        id
        waste {
          id
          number
          type {
            id
            largeClass {
              code
              name
              removedAt
            }
            middleClass {
              code
              name
              removedAt
            }
            smallClass {
              code
              name
              removedAt
            }
            group {
              id
              name
              removedAt
            }
            removedAt
          }
          kind
          name
          packing {
            code
            name
            removedAt
          }
          packingDetails
          quantityUnit {
            code
            name
            removedAt
          }
          weightConvertedValue
          weightConvertedValueUnit {
            id
            order
            name
            removedAt
          }
          dischargeFrequency {
            interval {
              id
              order
              name
              removedAt
            }
            numberOfTimes
          }
          dischargeAmountPerOneTime
          isImportedWaste
          state
          dischargeSourceWorkplaceId
          version
        }
        quantity
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const createLogisticsWorkAllocation = /* GraphQL */ `
  mutation CreateLogisticsWorkAllocation(
    $input: CreateLogisticsWorkAllocationInput!
  ) {
    createLogisticsWorkAllocation(input: $input) {
      id
      date
      courses {
        id
        name
        departureDate
        departureTime
        assignedVehicle {
          id
          number
          modelOfVehicleName
        }
        assignedUsers {
          id
          name
        }
        points {
          id
          workplace {
            id
            name
            phoneticGuidesOfName
            postalCode
            prefectures {
              code
              name
              removedAt
            }
            city
            streetAddress
            otherAddress
            email
            phone
            fax
            position {
              lat
              lng
            }
          }
          assignedWasteCollectionSchedules {
            id
            wasteGeneratorCompany {
              id
              name
              phoneticGuidesOfName
            }
            wasteCollectionWorkplace {
              id
              name
              phoneticGuidesOfName
              postalCode
              prefectures {
                code
                name
                removedAt
              }
              city
              streetAddress
              otherAddress
              email
              phone
              fax
              position {
                lat
                lng
              }
            }
            wasteCarrierCompany {
              id
              name
              phoneticGuidesOfName
            }
            waste {
              id
              number
              type {
                id
                largeClass {
                  code
                  name
                  removedAt
                }
                middleClass {
                  code
                  name
                  removedAt
                }
                smallClass {
                  code
                  name
                  removedAt
                }
                group {
                  id
                  name
                  removedAt
                }
                removedAt
              }
              kind
              name
              packing {
                code
                name
                removedAt
              }
              packingDetails
              quantityUnit {
                code
                name
                removedAt
              }
              weightConvertedValue
              weightConvertedValueUnit {
                id
                order
                name
                removedAt
              }
              dischargeFrequency {
                interval {
                  id
                  order
                  name
                  removedAt
                }
                numberOfTimes
              }
              dischargeAmountPerOneTime
              isImportedWaste
              state
              dischargeSourceWorkplaceId
              version
            }
            wasteQuantity
            scheduleDate
            scheduleTimeRangeStart
            scheduleTimeRangeEnd
            createdAt
            updatedAt
            version
          }
          createdAt
          updatedAt
          version
        }
        pointTotal
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const createLogisticsWorkAllocationWithSettings = /* GraphQL */ `
  mutation CreateLogisticsWorkAllocationWithSettings(
    $input: CreateLogisticsWorkAllocationWithSettingsInput!
  ) {
    createLogisticsWorkAllocationWithSettings(input: $input)
  }
`;
export const createRegularlyWasteCollectionCycles = /* GraphQL */ `
  mutation CreateRegularlyWasteCollectionCycles(
    $input: CreateRegularlyWasteCollectionOrderCyclesInput!
  ) {
    createRegularlyWasteCollectionCycles(input: $input) {
      id
      cycleCategoryId
      cycleCategoryName
      cycleStartDate
      cycleEndDate
      scheduleTimeRangeStart
      scheduleTimeRangeEnd
      remarks
      months
      weeks
      intervalDays
      weekDays
      createdAt
      updatedAt
      version
    }
  }
`;
export const createRegularlyWasteCollectionOrder = /* GraphQL */ `
  mutation CreateRegularlyWasteCollectionOrder(
    $input: CreateRegularlyWasteCollectionOrderInput!
  ) {
    createRegularlyWasteCollectionOrder(input: $input) {
      id
      wasteGeneratorCompany {
        id
        name
        phoneticGuidesOfName
      }
      wasteCollectionWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
      }
      wasteCarrierCompany {
        id
        name
        phoneticGuidesOfName
      }
      waste {
        id
        number
        type {
          id
          largeClass {
            code
            name
            removedAt
          }
          middleClass {
            code
            name
            removedAt
          }
          smallClass {
            code
            name
            removedAt
          }
          group {
            id
            name
            removedAt
          }
          removedAt
        }
        kind
        name
        packing {
          code
          name
          removedAt
        }
        packingDetails
        quantityUnit {
          code
          name
          removedAt
        }
        weightConvertedValue
        weightConvertedValueUnit {
          id
          order
          name
          removedAt
        }
        dischargeFrequency {
          interval {
            id
            order
            name
            removedAt
          }
          numberOfTimes
        }
        dischargeAmountPerOneTime
        isImportedWaste
        state
        dischargeSourceWorkplaceId
        version
      }
      remarks
      cycles {
        id
        cycleCategoryId
        cycleCategoryName
        cycleStartDate
        cycleEndDate
        scheduleTimeRangeStart
        scheduleTimeRangeEnd
        remarks
        months
        weeks
        intervalDays
        weekDays
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse($input: DeleteCourseInput!) {
    deleteCourse(input: $input)
  }
`;
export const deleteIndividualityWasteCollectionOrder = /* GraphQL */ `
  mutation DeleteIndividualityWasteCollectionOrder(
    $input: DeleteIndividualityWasteCollectionOrderInput!
  ) {
    deleteIndividualityWasteCollectionOrder(input: $input)
  }
`;
export const deleteIndividualityWasteCollectionOrders = /* GraphQL */ `
  mutation DeleteIndividualityWasteCollectionOrders(
    $input: DeleteIndividualityWasteCollectionOrdersInput!
  ) {
    deleteIndividualityWasteCollectionOrders(input: $input)
  }
`;
export const deleteLogisticsWorkAllocation = /* GraphQL */ `
  mutation DeleteLogisticsWorkAllocation(
    $input: DeleteLogisticsWorkAllocationInput!
  ) {
    deleteLogisticsWorkAllocation(input: $input) {
      ids
    }
  }
`;
export const deleteRegularWasteCollectionOrder = /* GraphQL */ `
  mutation DeleteRegularWasteCollectionOrder(
    $input: DeleteRegularWasteCollectionOrder!
  ) {
    deleteRegularWasteCollectionOrder(input: $input)
  }
`;
export const deleteRegularWasteCollectionOrders = /* GraphQL */ `
  mutation DeleteRegularWasteCollectionOrders(
    $input: DeleteRegularWasteCollectionOrdersInput!
  ) {
    deleteRegularWasteCollectionOrders(input: $input)
  }
`;
export const moveCollectionCyclesToTrash = /* GraphQL */ `
  mutation MoveCollectionCyclesToTrash(
    $input: MoveCollectionCyclesToTrashInput!
  ) {
    moveCollectionCyclesToTrash(input: $input)
  }
`;
export const publishCreateWorkAllocationWithSettingsStart = /* GraphQL */ `
  mutation PublishCreateWorkAllocationWithSettingsStart(
    $result: AllocationMessageInput!
  ) {
    publishCreateWorkAllocationWithSettingsStart(result: $result) {
      message
      ownerCompanyId
    }
  }
`;
export const recordCarryWorkJournalInstrument = /* GraphQL */ `
  mutation RecordCarryWorkJournalInstrument(
    $input: RecordCarryWorkJournalInstrumentInput!
  ) {
    recordCarryWorkJournalInstrument(input: $input) {
      type
      odometer
      updatedAt
    }
  }
`;
export const recordCarryWorkLocation = /* GraphQL */ `
  mutation RecordCarryWorkLocation($input: RecordCarryWorkLocationInput!) {
    recordCarryWorkLocation(input: $input) {
      arrivedAt
      departedAt
      version
    }
  }
`;
export const recordWasteCollectionTasks = /* GraphQL */ `
  mutation RecordWasteCollectionTasks(
    $input: RecordWasteCollectionTasksInput!
  ) {
    recordWasteCollectionTasks(input: $input) {
      id
      workplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
        belongInCompanyId
        belongInCompanyName
        precautions {
          id
          name
          message
          fileCount
          ownerCompanyId
          lastUpdatedOn
        }
      }
      tasks {
        id
        waste {
          id
          number
          type {
            id
            largeClass {
              code
              name
              removedAt
            }
            middleClass {
              code
              name
              removedAt
            }
            smallClass {
              code
              name
              removedAt
            }
            group {
              id
              name
              removedAt
            }
            removedAt
          }
          kind
          name
          packing {
            code
            name
            removedAt
          }
          packingDetails
          quantityUnit {
            code
            name
            removedAt
          }
          weightConvertedValue
          weightConvertedValueUnit {
            id
            order
            name
            removedAt
          }
          dischargeFrequency {
            interval {
              id
              order
              name
              removedAt
            }
            numberOfTimes
          }
          dischargeAmountPerOneTime
          isImportedWaste
          state
          dischargeSourceWorkplaceId
          version
        }
        wasteQuantity
        scheduleDate
        scheduleTimeRangeStart
        scheduleTimeRangeEnd
        record {
          wasteFlowId
          quantity
          quantityUnit {
            code
            name
            removedAt
          }
          weightOfKg
          remarks
          createdAt
          updatedAt
          version
        }
        remarks
        createdAt
        updatedAt
        version
      }
      passedRecord {
        arrivedAt
        departedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const reduceRegularlyWasteCollectionSchedule = /* GraphQL */ `
  mutation ReduceRegularlyWasteCollectionSchedule(
    $input: ReduceRegularlyWasteCollectionScheduleInput!
  ) {
    reduceRegularlyWasteCollectionSchedule(input: $input) {
      id
      scheduleDate
    }
  }
`;
export const removeCycleCategoryWithIAM = /* GraphQL */ `
  mutation RemoveCycleCategoryWithIAM(
    $input: RemoveCycleCategoryWithIAMInput!
  ) {
    removeCycleCategoryWithIAM(input: $input) {
      id
    }
  }
`;
export const removeIncreasedRegularlyWasteCollectionSchedule = /* GraphQL */ `
  mutation RemoveIncreasedRegularlyWasteCollectionSchedule($id: ID!) {
    removeIncreasedRegularlyWasteCollectionSchedule(id: $id) {
      id
    }
  }
`;
export const sortCarryWorkLocations = /* GraphQL */ `
  mutation SortCarryWorkLocations($input: SortCarryWorkLocationsInput!) {
    sortCarryWorkLocations(input: $input) {
      id
      name
      departureDate
      departureTime
      assignedVehicle {
        id
        number
        modelOfVehicleName
      }
      assignedUsers {
        id
        name
      }
      instruments {
        type
        odometer
        updatedAt
      }
      items {
        id
        workplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
          belongInCompanyId
          belongInCompanyName
          precautions {
            id
            name
            message
            fileCount
            ownerCompanyId
            lastUpdatedOn
          }
        }
        tasks {
          id
          waste {
            id
            number
            type {
              id
              largeClass {
                code
                name
                removedAt
              }
              middleClass {
                code
                name
                removedAt
              }
              smallClass {
                code
                name
                removedAt
              }
              group {
                id
                name
                removedAt
              }
              removedAt
            }
            kind
            name
            packing {
              code
              name
              removedAt
            }
            packingDetails
            quantityUnit {
              code
              name
              removedAt
            }
            weightConvertedValue
            weightConvertedValueUnit {
              id
              order
              name
              removedAt
            }
            dischargeFrequency {
              interval {
                id
                order
                name
                removedAt
              }
              numberOfTimes
            }
            dischargeAmountPerOneTime
            isImportedWaste
            state
            dischargeSourceWorkplaceId
            version
          }
          wasteQuantity
          scheduleDate
          scheduleTimeRangeStart
          scheduleTimeRangeEnd
          record {
            wasteFlowId
            quantity
            quantityUnit {
              code
              name
              removedAt
            }
            weightOfKg
            remarks
            createdAt
            updatedAt
            version
          }
          remarks
          createdAt
          updatedAt
          version
        }
        passedRecord {
          arrivedAt
          departedAt
          version
        }
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const stopRegularlyWasteCollectionCycle = /* GraphQL */ `
  mutation StopRegularlyWasteCollectionCycle(
    $input: StopRegularlyWasteCollectionCycleInput!
  ) {
    stopRegularlyWasteCollectionCycle(input: $input) {
      id
      cycleCategoryId
      cycleCategoryName
      cycleStartDate
      cycleEndDate
      scheduleTimeRangeStart
      scheduleTimeRangeEnd
      remarks
      months
      weeks
      intervalDays
      weekDays
      createdAt
      updatedAt
      version
    }
  }
`;
export const updateAutomationSettingCourse = /* GraphQL */ `
  mutation UpdateAutomationSettingCourse(
    $input: UpdateAutomationSettingCourseInput!
  ) {
    updateAutomationSettingCourse(input: $input) {
      id
      course {
        id
        name
        departureTime
        assignedVehicle {
          id
          number
          modelOfVehicleName
        }
        assignedUsers {
          id
          name
        }
        pointTotal
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const updateLogisticsWorkAllocation = /* GraphQL */ `
  mutation UpdateLogisticsWorkAllocation(
    $input: UpdateLogisticsWorkAllocationInput!
  ) {
    updateLogisticsWorkAllocation(input: $input) {
      id
      date
      courses {
        id
        name
        departureDate
        departureTime
        assignedVehicle {
          id
          number
          modelOfVehicleName
        }
        assignedUsers {
          id
          name
        }
        points {
          id
          workplace {
            id
            name
            phoneticGuidesOfName
            postalCode
            prefectures {
              code
              name
              removedAt
            }
            city
            streetAddress
            otherAddress
            email
            phone
            fax
            position {
              lat
              lng
            }
          }
          assignedWasteCollectionSchedules {
            id
            wasteGeneratorCompany {
              id
              name
              phoneticGuidesOfName
            }
            wasteCollectionWorkplace {
              id
              name
              phoneticGuidesOfName
              postalCode
              prefectures {
                code
                name
                removedAt
              }
              city
              streetAddress
              otherAddress
              email
              phone
              fax
              position {
                lat
                lng
              }
            }
            wasteCarrierCompany {
              id
              name
              phoneticGuidesOfName
            }
            waste {
              id
              number
              type {
                id
                largeClass {
                  code
                  name
                  removedAt
                }
                middleClass {
                  code
                  name
                  removedAt
                }
                smallClass {
                  code
                  name
                  removedAt
                }
                group {
                  id
                  name
                  removedAt
                }
                removedAt
              }
              kind
              name
              packing {
                code
                name
                removedAt
              }
              packingDetails
              quantityUnit {
                code
                name
                removedAt
              }
              weightConvertedValue
              weightConvertedValueUnit {
                id
                order
                name
                removedAt
              }
              dischargeFrequency {
                interval {
                  id
                  order
                  name
                  removedAt
                }
                numberOfTimes
              }
              dischargeAmountPerOneTime
              isImportedWaste
              state
              dischargeSourceWorkplaceId
              version
            }
            wasteQuantity
            scheduleDate
            scheduleTimeRangeStart
            scheduleTimeRangeEnd
            createdAt
            updatedAt
            version
          }
          createdAt
          updatedAt
          version
        }
        pointTotal
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const updateLogisticsWorkAllocationCourse = /* GraphQL */ `
  mutation UpdateLogisticsWorkAllocationCourse(
    $input: UpdateLogisticsWorkAllocationCourseInput!
  ) {
    updateLogisticsWorkAllocationCourse(input: $input) {
      id
      date
      course {
        id
        name
        departureDate
        departureTime
        assignedVehicle {
          id
          number
          modelOfVehicleName
        }
        assignedUsers {
          id
          name
        }
        points {
          id
          workplace {
            id
            name
            phoneticGuidesOfName
            postalCode
            prefectures {
              code
              name
              removedAt
            }
            city
            streetAddress
            otherAddress
            email
            phone
            fax
            position {
              lat
              lng
            }
          }
          assignedWasteCollectionSchedules {
            id
            wasteGeneratorCompany {
              id
              name
              phoneticGuidesOfName
            }
            wasteCollectionWorkplace {
              id
              name
              phoneticGuidesOfName
              postalCode
              prefectures {
                code
                name
                removedAt
              }
              city
              streetAddress
              otherAddress
              email
              phone
              fax
              position {
                lat
                lng
              }
            }
            wasteCarrierCompany {
              id
              name
              phoneticGuidesOfName
            }
            waste {
              id
              number
              type {
                id
                largeClass {
                  code
                  name
                  removedAt
                }
                middleClass {
                  code
                  name
                  removedAt
                }
                smallClass {
                  code
                  name
                  removedAt
                }
                group {
                  id
                  name
                  removedAt
                }
                removedAt
              }
              kind
              name
              packing {
                code
                name
                removedAt
              }
              packingDetails
              quantityUnit {
                code
                name
                removedAt
              }
              weightConvertedValue
              weightConvertedValueUnit {
                id
                order
                name
                removedAt
              }
              dischargeFrequency {
                interval {
                  id
                  order
                  name
                  removedAt
                }
                numberOfTimes
              }
              dischargeAmountPerOneTime
              isImportedWaste
              state
              dischargeSourceWorkplaceId
              version
            }
            wasteQuantity
            scheduleDate
            scheduleTimeRangeStart
            scheduleTimeRangeEnd
            createdAt
            updatedAt
            version
          }
          createdAt
          updatedAt
          version
        }
        pointTotal
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const updateLogisticsWorkAllocationCoursesOrder = /* GraphQL */ `
  mutation UpdateLogisticsWorkAllocationCoursesOrder(
    $input: UpdateLogisticsWorkAllocationCoursesOrderInput!
  ) {
    updateLogisticsWorkAllocationCoursesOrder(input: $input) {
      id
      date
      courses {
        id
        name
        departureDate
        departureTime
        assignedVehicle {
          id
          number
          modelOfVehicleName
        }
        assignedUsers {
          id
          name
        }
        points {
          id
          workplace {
            id
            name
            phoneticGuidesOfName
            postalCode
            prefectures {
              code
              name
              removedAt
            }
            city
            streetAddress
            otherAddress
            email
            phone
            fax
            position {
              lat
              lng
            }
          }
          assignedWasteCollectionSchedules {
            id
            wasteGeneratorCompany {
              id
              name
              phoneticGuidesOfName
            }
            wasteCollectionWorkplace {
              id
              name
              phoneticGuidesOfName
              postalCode
              prefectures {
                code
                name
                removedAt
              }
              city
              streetAddress
              otherAddress
              email
              phone
              fax
              position {
                lat
                lng
              }
            }
            wasteCarrierCompany {
              id
              name
              phoneticGuidesOfName
            }
            waste {
              id
              number
              type {
                id
                largeClass {
                  code
                  name
                  removedAt
                }
                middleClass {
                  code
                  name
                  removedAt
                }
                smallClass {
                  code
                  name
                  removedAt
                }
                group {
                  id
                  name
                  removedAt
                }
                removedAt
              }
              kind
              name
              packing {
                code
                name
                removedAt
              }
              packingDetails
              quantityUnit {
                code
                name
                removedAt
              }
              weightConvertedValue
              weightConvertedValueUnit {
                id
                order
                name
                removedAt
              }
              dischargeFrequency {
                interval {
                  id
                  order
                  name
                  removedAt
                }
                numberOfTimes
              }
              dischargeAmountPerOneTime
              isImportedWaste
              state
              dischargeSourceWorkplaceId
              version
            }
            wasteQuantity
            scheduleDate
            scheduleTimeRangeStart
            scheduleTimeRangeEnd
            createdAt
            updatedAt
            version
          }
          createdAt
          updatedAt
          version
        }
        pointTotal
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const updateVirtualCourseOrder = /* GraphQL */ `
  mutation UpdateVirtualCourseOrder($input: UpdateVirtualCourseOrderInput!) {
    updateVirtualCourseOrder(input: $input) {
      id
      courses {
        id
        name
        departureTime
        assignedVehicle {
          id
          number
          modelOfVehicleName
        }
        assignedUsers {
          id
          name
        }
        pointTotal
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const createChecksheet = /* GraphQL */ `
  mutation CreateChecksheet($input: CreateChecksheetInput!) {
    createChecksheet(input: $input) {
      id
      name
      description
      personInChargeName
      checkedOn
      sections {
        order
        name
        items {
          order
          name
          checked
        }
      }
      files {
        name
        url
        order
        label
      }
      remarks
      state
      version
    }
  }
`;
export const createChecksheetCategoryWithIAM = /* GraphQL */ `
  mutation CreateChecksheetCategoryWithIAM(
    $input: CreateChecksheetCategoryWithIAMInput!
  ) {
    createChecksheetCategoryWithIAM(input: $input) {
      id
      name
      removedAt
    }
  }
`;
export const createChecksheetTemplate = /* GraphQL */ `
  mutation CreateChecksheetTemplate($input: CreateChecksheetTemplateInput!) {
    createChecksheetTemplate(input: $input) {
      id
      category {
        id
        name
        removedAt
      }
      name
      description
      remarks
      state
      sections {
        order
        name
        items {
          order
          name
        }
      }
      version
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient($input: CreateClientInput!) {
    createClient(input: $input) {
      id
      name
      phoneticGuidesOfName
      categories {
        id
        order
        name
        removedAt
      }
      corporateNumber
      representativeName
      establishmentedOn
      url
      remarks
      state
      headOfficeWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
      }
      companyId
      profileVersion
    }
  }
`;
export const createClients = /* GraphQL */ `
  mutation CreateClients($input: CreateClientsInput!) {
    createClients(input: $input) {
      id
      name
      phoneticGuidesOfName
      categories {
        id
        order
        name
        removedAt
      }
      corporateNumber
      representativeName
      establishmentedOn
      url
      remarks
      state
      headOfficeWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
      }
      companyId
      profileVersion
    }
  }
`;
export const createCollectionPrecaution = /* GraphQL */ `
  mutation CreateCollectionPrecaution(
    $input: CreateCollectionPrecautionInput!
  ) {
    createCollectionPrecaution(input: $input) {
      id
      name
      tags
      message
      ownerCompanyId
      ownerWorkplaceId
      files {
        name
        url
        order
        label
      }
      version
    }
  }
`;
export const createCompanyCategoryWithIAM = /* GraphQL */ `
  mutation CreateCompanyCategoryWithIAM(
    $input: CreateCompanyCategoryWithIAMInput!
  ) {
    createCompanyCategoryWithIAM(input: $input) {
      id
      order
      name
      removedAt
    }
  }
`;
export const createContract = /* GraphQL */ `
  mutation CreateContract($input: CreateContractInput!) {
    createContract(input: $input) {
      id
      number
      name
      type {
        id
        order
        name
        removedAt
      }
      executedOn
      termOfAgreementStartedOn
      termOfAgreementExpirationOn
      isAutomaticallyRenewed
      remarks
      state
      ownerCompanyId
      partyCompanies {
        id
        name
        phoneticGuidesOfName
      }
      files {
        name
        url
        order
        label
      }
      version
    }
  }
`;
export const createWorkplace = /* GraphQL */ `
  mutation CreateWorkplace($input: CreateWorkplaceInput!) {
    createWorkplace(input: $input) {
      id
      name
      phoneticGuidesOfName
      categories {
        id
        order
        name
        removedAt
      }
      code
      industrySector {
        id
        largeClass {
          code
          name
          removedAt
        }
        middleClass {
          code
          name
          removedAt
        }
        smallClass {
          code
          name
          removedAt
        }
        subClass {
          code
          name
          removedAt
        }
        removedAt
      }
      postalCode
      prefectures {
        code
        name
        removedAt
      }
      city
      streetAddress
      otherAddress
      email
      phone
      fax
      position {
        lat
        lng
      }
      remarks
      state
      belongInCompanyId
      profileVersion
      addressVersion
      contactDetailsVersion
      isMapInput
    }
  }
`;
export const createWorkplaceCategoryWithIAM = /* GraphQL */ `
  mutation CreateWorkplaceCategoryWithIAM(
    $input: CreateWorkplaceCategoryWithIAMInput!
  ) {
    createWorkplaceCategoryWithIAM(input: $input) {
      id
      order
      name
      removedAt
    }
  }
`;
export const createWorkplaces = /* GraphQL */ `
  mutation CreateWorkplaces($input: [CreateWorkplaceInput]!) {
    createWorkplaces(input: $input) {
      id
      name
      phoneticGuidesOfName
      categories {
        id
        order
        name
        removedAt
      }
      code
      industrySector {
        id
        largeClass {
          code
          name
          removedAt
        }
        middleClass {
          code
          name
          removedAt
        }
        smallClass {
          code
          name
          removedAt
        }
        subClass {
          code
          name
          removedAt
        }
        removedAt
      }
      postalCode
      prefectures {
        code
        name
        removedAt
      }
      city
      streetAddress
      otherAddress
      email
      phone
      fax
      position {
        lat
        lng
      }
      remarks
      state
      belongInCompanyId
      profileVersion
      addressVersion
      contactDetailsVersion
      isMapInput
    }
  }
`;
export const deleteCollectionPrecaution = /* GraphQL */ `
  mutation DeleteCollectionPrecaution(
    $input: DeleteCollectionPrecautionInput!
  ) {
    deleteCollectionPrecaution(input: $input) {
      id
    }
  }
`;
export const parseBulkWorkplaceCreateExcel = /* GraphQL */ `
  mutation ParseBulkWorkplaceCreateExcel($input: WorkplaceExcelFileInput!) {
    parseBulkWorkplaceCreateExcel(input: $input) {
      id
      name
      companyName
      phoneticGuidesOfName
      categories {
        id
        order
        name
        removedAt
      }
      code
      industrySectorId
      postalCode
      prefectures {
        code
        name
        removedAt
      }
      city
      streetAddress
      otherAddress
      email
      phone
      fax
      remarks
      belongInCompanyId
    }
  }
`;
export const parseClientsExcel = /* GraphQL */ `
  mutation ParseClientsExcel($input: CreateClientsFileInput) {
    parseClientsExcel(input: $input) {
      id
      name
      phoneticGuidesOfName
      categories {
        id
        order
        name
        removedAt
      }
      corporateNumber
      representativeName
      establishmentedOn
      url
      remarks
      state
      headOfficeWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
      }
      companyId
      profileVersion
    }
  }
`;
export const removeChecksheet = /* GraphQL */ `
  mutation RemoveChecksheet($input: RemoveChecksheetInput!) {
    removeChecksheet(input: $input) {
      id
    }
  }
`;
export const removeChecksheetCategoryWithIAM = /* GraphQL */ `
  mutation RemoveChecksheetCategoryWithIAM(
    $input: RemoveChecksheetCategoryWithIAMInput!
  ) {
    removeChecksheetCategoryWithIAM(input: $input) {
      id
    }
  }
`;
export const removeChecksheetTemplate = /* GraphQL */ `
  mutation RemoveChecksheetTemplate($input: RemoveChecksheetTemplateInput!) {
    removeChecksheetTemplate(input: $input) {
      id
    }
  }
`;
export const removeClient = /* GraphQL */ `
  mutation RemoveClient($input: RemoveClientInput!) {
    removeClient(input: $input) {
      id
    }
  }
`;
export const removeCompanyCategoryWithIAM = /* GraphQL */ `
  mutation RemoveCompanyCategoryWithIAM(
    $input: RemoveCompanyCategoryWithIAMInput!
  ) {
    removeCompanyCategoryWithIAM(input: $input) {
      id
    }
  }
`;
export const removeContract = /* GraphQL */ `
  mutation RemoveContract($input: RemoveContractInput!) {
    removeContract(input: $input) {
      id
    }
  }
`;
export const removeWorkplace = /* GraphQL */ `
  mutation RemoveWorkplace($input: RemoveWorkplaceInput!) {
    removeWorkplace(input: $input) {
      id
    }
  }
`;
export const removeWorkplaceCategoryWithIAM = /* GraphQL */ `
  mutation RemoveWorkplaceCategoryWithIAM(
    $input: RemoveWorkplaceCategoryWithIAMInput!
  ) {
    removeWorkplaceCategoryWithIAM(input: $input) {
      id
    }
  }
`;
export const updateChecksheet = /* GraphQL */ `
  mutation UpdateChecksheet($input: UpdateChecksheetInput!) {
    updateChecksheet(input: $input) {
      id
      name
      description
      personInChargeName
      checkedOn
      sections {
        order
        name
        items {
          order
          name
          checked
        }
      }
      files {
        name
        url
        order
        label
      }
      remarks
      state
      version
    }
  }
`;
export const updateChecksheetTemplate = /* GraphQL */ `
  mutation UpdateChecksheetTemplate($input: UpdateChecksheetTemplateInput!) {
    updateChecksheetTemplate(input: $input) {
      id
      category {
        id
        name
        removedAt
      }
      name
      description
      remarks
      state
      sections {
        order
        name
        items {
          order
          name
        }
      }
      version
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      id
      name
      phoneticGuidesOfName
      categories {
        id
        order
        name
        removedAt
      }
      corporateNumber
      representativeName
      establishmentedOn
      url
      remarks
      state
      headOfficeWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
      }
      companyId
      profileVersion
    }
  }
`;
export const updateCollectionPrecaution = /* GraphQL */ `
  mutation UpdateCollectionPrecaution(
    $input: UpdateCollectionPrecautionInput!
  ) {
    updateCollectionPrecaution(input: $input) {
      id
      name
      tags
      message
      ownerCompanyId
      ownerWorkplaceId
      files {
        name
        url
        order
        label
      }
      version
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      id
      name
      phoneticGuidesOfName
      categories {
        id
        order
        name
        removedAt
      }
      corporateNumber
      representativeName
      establishmentedOn
      url
      headOfficeWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
      }
      profileVersion
    }
  }
`;
export const updateContract = /* GraphQL */ `
  mutation UpdateContract($input: UpdateContractInput!) {
    updateContract(input: $input) {
      id
      number
      name
      type {
        id
        order
        name
        removedAt
      }
      executedOn
      termOfAgreementStartedOn
      termOfAgreementExpirationOn
      isAutomaticallyRenewed
      remarks
      state
      ownerCompanyId
      partyCompanies {
        id
        name
        phoneticGuidesOfName
      }
      files {
        name
        url
        order
        label
      }
      version
    }
  }
`;
export const updateWorkplace = /* GraphQL */ `
  mutation UpdateWorkplace($input: UpdateWorkplaceInput!) {
    updateWorkplace(input: $input) {
      id
      name
      phoneticGuidesOfName
      categories {
        id
        order
        name
        removedAt
      }
      code
      industrySector {
        id
        largeClass {
          code
          name
          removedAt
        }
        middleClass {
          code
          name
          removedAt
        }
        smallClass {
          code
          name
          removedAt
        }
        subClass {
          code
          name
          removedAt
        }
        removedAt
      }
      postalCode
      prefectures {
        code
        name
        removedAt
      }
      city
      streetAddress
      otherAddress
      email
      phone
      fax
      position {
        lat
        lng
      }
      remarks
      state
      belongInCompanyId
      profileVersion
      addressVersion
      contactDetailsVersion
      isMapInput
    }
  }
`;
export const createVehicle = /* GraphQL */ `
  mutation CreateVehicle($input: CreateVehicleInput!) {
    createVehicle(input: $input) {
      id
      number
      modelName
      parkingCertificateOwnerWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
      }
      loadCapacity
      totalWeight
      ridingCapacity
      purchasedOn
      firstYearRegistrationOn
      remarks
      state
      ownerCompanyId
      version
    }
  }
`;
export const createVehicleMaintenanceRecord = /* GraphQL */ `
  mutation CreateVehicleMaintenanceRecord(
    $input: CreateVehicleMaintenanceRecordInput!
  ) {
    createVehicleMaintenanceRecord(input: $input) {
      id
      vehicleId
      vehicleModelName
      vehicleNumber
      maintenanceWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
        belongInCompanyId
        belongInCompanyName
        precautions {
          id
          name
          message
          fileCount
          ownerCompanyId
          lastUpdatedOn
        }
      }
      number
      category
      maintenancedOn
      price
      details {
        order
        method
        part
        content
        quantityValue
        quantityUnit
        remarks
      }
      files {
        name
        url
        order
        label
      }
      remarks
      state
      version
    }
  }
`;
export const createVehiclePeriodicInspection = /* GraphQL */ `
  mutation CreateVehiclePeriodicInspection(
    $input: CreateVehiclePeriodicInspectionInput!
  ) {
    createVehiclePeriodicInspection(input: $input) {
      id
      vehicleId
      vehicleModelName
      vehicleNumber
      name
      description
      personInChargeName
      checkedOn
      sections {
        order
        name
        items {
          order
          name
          checked
        }
      }
      files {
        name
        url
        order
        label
      }
      remarks
      state
      version
    }
  }
`;
export const createVehicleRemind = /* GraphQL */ `
  mutation CreateVehicleRemind($input: CreateVehicleRemindInput!) {
    createVehicleRemind(input: $input) {
      id
      name
      message
      criteria {
        and {
          date {
            gte
            lte
            eq
          }
          odometer {
            gte
            lte
            eq
          }
          certificateDays {
            gte
            lte
            eq
          }
        }
        or {
          date {
            gte
            lte
            eq
          }
          odometer {
            gte
            lte
            eq
          }
          certificateDays {
            gte
            lte
            eq
          }
        }
      }
      notifications {
        notificationAt
        repeatDays
        destinationEmails {
          email
          suppressionState
          suppressionedAt
        }
      }
      vehicleId
      version
    }
  }
`;
export const createVehicleSafetyInspectionMaintenanceRecord = /* GraphQL */ `
  mutation CreateVehicleSafetyInspectionMaintenanceRecord(
    $input: CreateVehicleSafetyInspectionMaintenanceRecordInput!
  ) {
    createVehicleSafetyInspectionMaintenanceRecord(input: $input) {
      id
      vehicleId
      vehicleModelName
      vehicleNumber
      maintenanceWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
        belongInCompanyId
        belongInCompanyName
        precautions {
          id
          name
          message
          fileCount
          ownerCompanyId
          lastUpdatedOn
        }
      }
      number
      category
      maintenancedOn
      price
      details {
        order
        method
        part
        content
        quantityValue
        quantityUnit
        remarks
      }
      files {
        name
        url
        order
        label
      }
      remarks
      state
      version
    }
  }
`;
export const createVehicleSafetyInspectionRecord = /* GraphQL */ `
  mutation CreateVehicleSafetyInspectionRecord(
    $input: CreateVehicleSafetyInspectionRecordInput!
  ) {
    createVehicleSafetyInspectionRecord(input: $input) {
      id
      vehicleId
      vehicleModelName
      vehicleNumber
      inspectionWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
        belongInCompanyId
        belongInCompanyName
        precautions {
          id
          name
          message
          fileCount
          ownerCompanyId
          lastUpdatedOn
        }
      }
      receivedOn
      completedOn
      expiredOn
      price
      result
      files {
        name
        url
        order
        label
      }
      remarks
      state
      version
    }
  }
`;
export const deleteVehicleRemind = /* GraphQL */ `
  mutation DeleteVehicleRemind($input: DeleteVehicleRemindInput!) {
    deleteVehicleRemind(input: $input) {
      id
    }
  }
`;
export const removeVehicle = /* GraphQL */ `
  mutation RemoveVehicle($input: RemoveVehicleInput!) {
    removeVehicle(input: $input) {
      id
    }
  }
`;
export const removeVehicleMaintenanceRecord = /* GraphQL */ `
  mutation RemoveVehicleMaintenanceRecord(
    $input: RemoveVehicleMaintenanceRecordInput!
  ) {
    removeVehicleMaintenanceRecord(input: $input) {
      id
    }
  }
`;
export const removeVehiclePeriodicInspection = /* GraphQL */ `
  mutation RemoveVehiclePeriodicInspection(
    $input: RemoveVehiclePeriodicInspectionInput!
  ) {
    removeVehiclePeriodicInspection(input: $input) {
      id
    }
  }
`;
export const removeVehicleSafetyInspectionRecord = /* GraphQL */ `
  mutation RemoveVehicleSafetyInspectionRecord(
    $input: RemoveVehicleSafetyInspectionRecordInput!
  ) {
    removeVehicleSafetyInspectionRecord(input: $input) {
      id
    }
  }
`;
export const updateVehicle = /* GraphQL */ `
  mutation UpdateVehicle($input: UpdateVehicleInput!) {
    updateVehicle(input: $input) {
      id
      number
      modelName
      parkingCertificateOwnerWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
      }
      loadCapacity
      totalWeight
      ridingCapacity
      purchasedOn
      firstYearRegistrationOn
      remarks
      state
      ownerCompanyId
      version
    }
  }
`;
export const updateVehicleCertificate = /* GraphQL */ `
  mutation UpdateVehicleCertificate($input: UpdateVehicleCertificateInput!) {
    updateVehicleCertificate(input: $input) {
      vehicleId
      expiredOn
      updatedAt
    }
  }
`;
export const updateVehicleInstrument = /* GraphQL */ `
  mutation UpdateVehicleInstrument($input: UpdateVehicleInstrumentInput!) {
    updateVehicleInstrument(input: $input) {
      vehicleId
      odometer
      updatedAt
    }
  }
`;
export const updateVehicleMaintenanceRecord = /* GraphQL */ `
  mutation UpdateVehicleMaintenanceRecord(
    $input: UpdateVehicleMaintenanceRecordInput!
  ) {
    updateVehicleMaintenanceRecord(input: $input) {
      id
      vehicleId
      vehicleModelName
      vehicleNumber
      maintenanceWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
        belongInCompanyId
        belongInCompanyName
        precautions {
          id
          name
          message
          fileCount
          ownerCompanyId
          lastUpdatedOn
        }
      }
      number
      category
      maintenancedOn
      price
      details {
        order
        method
        part
        content
        quantityValue
        quantityUnit
        remarks
      }
      files {
        name
        url
        order
        label
      }
      remarks
      state
      version
    }
  }
`;
export const updateVehiclePeriodicInspection = /* GraphQL */ `
  mutation UpdateVehiclePeriodicInspection(
    $input: UpdateVehiclePeriodicInspectionInput!
  ) {
    updateVehiclePeriodicInspection(input: $input) {
      id
      vehicleId
      vehicleModelName
      vehicleNumber
      name
      description
      personInChargeName
      checkedOn
      sections {
        order
        name
        items {
          order
          name
          checked
        }
      }
      files {
        name
        url
        order
        label
      }
      remarks
      state
      version
    }
  }
`;
export const updateVehicleRemind = /* GraphQL */ `
  mutation UpdateVehicleRemind($input: UpdateVehicleRemindInput!) {
    updateVehicleRemind(input: $input) {
      id
      name
      message
      criteria {
        and {
          date {
            gte
            lte
            eq
          }
          odometer {
            gte
            lte
            eq
          }
          certificateDays {
            gte
            lte
            eq
          }
        }
        or {
          date {
            gte
            lte
            eq
          }
          odometer {
            gte
            lte
            eq
          }
          certificateDays {
            gte
            lte
            eq
          }
        }
      }
      notifications {
        notificationAt
        repeatDays
        destinationEmails {
          email
          suppressionState
          suppressionedAt
        }
      }
      vehicleId
      version
    }
  }
`;
export const updateVehicleSafetyInspectionRecord = /* GraphQL */ `
  mutation UpdateVehicleSafetyInspectionRecord(
    $input: UpdateVehicleSafetyInspectionRecordInput!
  ) {
    updateVehicleSafetyInspectionRecord(input: $input) {
      id
      vehicleId
      vehicleModelName
      vehicleNumber
      inspectionWorkplace {
        id
        name
        phoneticGuidesOfName
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
        email
        phone
        fax
        position {
          lat
          lng
        }
        belongInCompanyId
        belongInCompanyName
        precautions {
          id
          name
          message
          fileCount
          ownerCompanyId
          lastUpdatedOn
        }
      }
      receivedOn
      completedOn
      expiredOn
      price
      result
      files {
        name
        url
        order
        label
      }
      remarks
      state
      version
    }
  }
`;
export const cancelPlanSubscribeProcedure = /* GraphQL */ `
  mutation CancelPlanSubscribeProcedure(
    $input: CancelPlanSubscribeProcedureInput!
  ) {
    cancelPlanSubscribeProcedure(input: $input) {
      id
    }
  }
`;
export const cancelPlanSubscriber = /* GraphQL */ `
  mutation CancelPlanSubscriber($input: CancelPlanSubscriberInput!) {
    cancelPlanSubscriber(input: $input) {
      id
    }
  }
`;
export const changePlan = /* GraphQL */ `
  mutation ChangePlan($input: ChangePlanInput!) {
    changePlan(input: $input) {
      id
      name
      description
    }
  }
`;
export const confirmPlanSubscribeProcedure = /* GraphQL */ `
  mutation ConfirmPlanSubscribeProcedure(
    $input: ConfirmPlanSubscribeProcedureInput!
  ) {
    confirmPlanSubscribeProcedure(input: $input) {
      code
    }
  }
`;
export const createInquiry = /* GraphQL */ `
  mutation CreateInquiry($input: CreateInquiryInput!) {
    createInquiry(input: $input) {
      id
      inquiryProcessing {
        id
        order
        name
        removedAt
      }
      method
      receivedAt
      category
      companyName
      nameOfInquirer
      phone
      email
      message
      version
    }
  }
`;
export const createInquiryProcessingWithIAM = /* GraphQL */ `
  mutation CreateInquiryProcessingWithIAM(
    $input: CreateInquiryProcessingWithIAMInput!
  ) {
    createInquiryProcessingWithIAM(input: $input) {
      id
      order
      name
      removedAt
    }
  }
`;
export const createPlanSubscribeProcedure = /* GraphQL */ `
  mutation CreatePlanSubscribeProcedure(
    $input: CreatePlanSubscribeProcedureInput!
  ) {
    createPlanSubscribeProcedure(input: $input) {
      id
      companyName
      contactPersonnel
      email
      plan {
        id
        name
      }
      url
      expireAt
      state {
        id
        order
        name
        removedAt
      }
      version
    }
  }
`;
export const createPlanSubscribeProcedureStatusWithIAM = /* GraphQL */ `
  mutation CreatePlanSubscribeProcedureStatusWithIAM(
    $input: CreatePlanSubscribeProcedureStatusWithIAMInput!
  ) {
    createPlanSubscribeProcedureStatusWithIAM(input: $input) {
      id
      order
      name
      removedAt
    }
  }
`;
export const createPlanSubscriber = /* GraphQL */ `
  mutation CreatePlanSubscriber($input: CreatePlanSubscriberInput!) {
    createPlanSubscriber(input: $input) {
      companyName
      phoneticGuidesOfCompanyName
      corporateNumber
      representativeName
      establishmentedOn
      url
      userAccountName
      departments
      position
      email
      phone
    }
  }
`;
export const createSystemNotificationWithIAM = /* GraphQL */ `
  mutation CreateSystemNotificationWithIAM(
    $input: CreateSystemNotificationInput!
  ) {
    createSystemNotificationWithIAM(input: $input) {
      id
      validityStartAt
      validityEndAt
      message
    }
  }
`;
export const deleteSystemNotificationWithIAM = /* GraphQL */ `
  mutation DeleteSystemNotificationWithIAM(
    $input: DeleteSystemNotificationInput!
  ) {
    deleteSystemNotificationWithIAM(input: $input) {
      id
    }
  }
`;
export const extendExpirationForPlanSubscribeProcedure = /* GraphQL */ `
  mutation ExtendExpirationForPlanSubscribeProcedure(
    $input: ExtendExpirationForPlanSubscribeProcedureInput!
  ) {
    extendExpirationForPlanSubscribeProcedure(input: $input) {
      id
    }
  }
`;
export const publishCreateSystemNotificationResult = /* GraphQL */ `
  mutation PublishCreateSystemNotificationResult(
    $result: CreateSystemNotificationResultInput!
  ) {
    publishCreateSystemNotificationResult(result: $result) {
      id
      validityStartAt
      validityEndAt
      message
    }
  }
`;
export const removeInquiryProcessingWithIAM = /* GraphQL */ `
  mutation RemoveInquiryProcessingWithIAM(
    $input: RemoveInquiryProcessingWithIAMInput!
  ) {
    removeInquiryProcessingWithIAM(input: $input) {
      id
    }
  }
`;
export const removePlanSubscribeProcedureStatusWithIAM = /* GraphQL */ `
  mutation RemovePlanSubscribeProcedureStatusWithIAM(
    $input: RemovePlanSubscribeProcedureStatusWithIAMInput!
  ) {
    removePlanSubscribeProcedureStatusWithIAM(input: $input) {
      id
    }
  }
`;
export const updateInquiryResponseStatus = /* GraphQL */ `
  mutation UpdateInquiryResponseStatus($input: CreateInquiryProgressInput!) {
    updateInquiryResponseStatus(input: $input) {
      id
      inquiryId
      progressedAt
      inquiryProcessing {
        id
        order
        name
        removedAt
      }
      details
    }
  }
`;
export const createJwnetMember = /* GraphQL */ `
  mutation CreateJwnetMember(
    $input: CreateJwnetMemberInput!
    $inquiryCriteria: ManifestCriteriaInquiryInput!
  ) {
    createJwnetMember(input: $input, inquiryCriteria: $inquiryCriteria) {
      id
      number
      ediUseConfirmationKey
      publicConfirmationNumber
      ediValidity
      version
    }
  }
`;
export const createPaperManifest = /* GraphQL */ `
  mutation CreatePaperManifest($input: CreatePaperManifestInput!) {
    createPaperManifest(input: $input) {
      id
      paperManifestType {
        code
        name
        removedAt
      }
      manifestInfoType {
        code
        name
        removedAt
      }
      manifestCardinalNumberType {
        code
        name
        removedAt
      }
      issueDate
      issueNumber
      issuePersonInCharge
      referenceNumber
      intermediateProcessingManagementType {
        code
        name
        removedAt
      }
      lastDisposalPlantDescribeType {
        code
        name
        removedAt
      }
      isTransshipmentStorageUsing
      wasteGeneratorCompany {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      wasteGeneratorWorkplace {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      waste {
        types {
          wasteType {
            id
            largeClass {
              code
              name
              removedAt
            }
            middleClass {
              code
              name
              removedAt
            }
            smallClass {
              code
              name
              removedAt
            }
            group {
              id
              name
              removedAt
            }
            removedAt
          }
          wasteTypeName
        }
        name
        quantityValue
        quantityUnit {
          code
          name
          removedAt
        }
        quantityUnitName
        packingName
        disposalMethodName
        hazardousSubstanceName
        remarks
      }
      primaryManifestRecord {
        primaryManifestIssuerName
        primaryManifestIssueNumber
        primaryManifestType {
          code
          name
          removedAt
        }
      }
      plannedLastDisposalPlant {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      wasteCarrierCompany {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      wasteTransportDestination {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      wasteTransshipmentStorage {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      wasteDisposalCompany {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      wasteTransportReport {
        wasteCarrierCompanyName
        wasteCarrierPersonInCharge
        endOfTransportationDate
        valuablesQuantityValue
        valuablesQuantityUnit {
          code
          name
          removedAt
        }
        valuablesQuantityUnitName
      }
      wasteDisposalReport {
        wasteDisposalCompanyName
        wasteDisposalPersonInCharge
        endOfDisposalDate
        endOfLastDisposalDate
      }
      wasteLastDisposalPlant {
        contractNumber
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      relation {
        wasteGeneratorCompanyId
        wasteGeneratorWorkplaceId
        wasteId
        wasteFlowId
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const deleteJwnetMember = /* GraphQL */ `
  mutation DeleteJwnetMember($input: DeleteJwnetMemberInput!) {
    deleteJwnetMember(input: $input) {
      id
    }
  }
`;
export const removePaperManifest = /* GraphQL */ `
  mutation RemovePaperManifest($input: RemovePaperManifestInput!) {
    removePaperManifest(input: $input) {
      id
    }
  }
`;
export const requestJwnetTransportReportCancellation = /* GraphQL */ `
  mutation RequestJwnetTransportReportCancellation(
    $input: RequestJwnetTransportReportCancellationInput!
  ) {
    requestJwnetTransportReportCancellation(input: $input) {
      requestId
    }
  }
`;
export const requestJwnetTransportReportChanges = /* GraphQL */ `
  mutation RequestJwnetTransportReportChanges(
    $input: RequestJwnetTransportReportChangesInput!
  ) {
    requestJwnetTransportReportChanges(input: $input) {
      requestId
    }
  }
`;
export const requestJwnetTransportReportRegistration = /* GraphQL */ `
  mutation RequestJwnetTransportReportRegistration(
    $input: RequestJwnetTransportReportRegistrationInput!
  ) {
    requestJwnetTransportReportRegistration(input: $input) {
      requestId
    }
  }
`;
export const updatePaperManifest = /* GraphQL */ `
  mutation UpdatePaperManifest($input: UpdatePaperManifestInput!) {
    updatePaperManifest(input: $input) {
      id
      paperManifestType {
        code
        name
        removedAt
      }
      manifestInfoType {
        code
        name
        removedAt
      }
      manifestCardinalNumberType {
        code
        name
        removedAt
      }
      issueDate
      issueNumber
      issuePersonInCharge
      referenceNumber
      intermediateProcessingManagementType {
        code
        name
        removedAt
      }
      lastDisposalPlantDescribeType {
        code
        name
        removedAt
      }
      isTransshipmentStorageUsing
      wasteGeneratorCompany {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      wasteGeneratorWorkplace {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      waste {
        types {
          wasteType {
            id
            largeClass {
              code
              name
              removedAt
            }
            middleClass {
              code
              name
              removedAt
            }
            smallClass {
              code
              name
              removedAt
            }
            group {
              id
              name
              removedAt
            }
            removedAt
          }
          wasteTypeName
        }
        name
        quantityValue
        quantityUnit {
          code
          name
          removedAt
        }
        quantityUnitName
        packingName
        disposalMethodName
        hazardousSubstanceName
        remarks
      }
      primaryManifestRecord {
        primaryManifestIssuerName
        primaryManifestIssueNumber
        primaryManifestType {
          code
          name
          removedAt
        }
      }
      plannedLastDisposalPlant {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      wasteCarrierCompany {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      wasteTransportDestination {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      wasteTransshipmentStorage {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      wasteDisposalCompany {
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      wasteTransportReport {
        wasteCarrierCompanyName
        wasteCarrierPersonInCharge
        endOfTransportationDate
        valuablesQuantityValue
        valuablesQuantityUnit {
          code
          name
          removedAt
        }
        valuablesQuantityUnitName
      }
      wasteDisposalReport {
        wasteDisposalCompanyName
        wasteDisposalPersonInCharge
        endOfDisposalDate
        endOfLastDisposalDate
      }
      wasteLastDisposalPlant {
        contractNumber
        name
        phone
        postalCode
        prefectures {
          code
          name
          removedAt
        }
        city
        streetAddress
        otherAddress
      }
      relation {
        wasteGeneratorCompanyId
        wasteGeneratorWorkplaceId
        wasteId
        wasteFlowId
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const createApiClientWithIAM = /* GraphQL */ `
  mutation CreateApiClientWithIAM($input: CreateApiClientInput!) {
    createApiClientWithIAM(input: $input) {
      id
      tenantId
      name
    }
  }
`;
export const createUserAccount = /* GraphQL */ `
  mutation CreateUserAccount($input: CreateUserAccountInput!) {
    createUserAccount(input: $input) {
      id
      planSubscriberId
      name
      organizationName
      departments
      position
      email
      phone
      roleName
      state
    }
  }
`;
export const createUserAccountWithIAM = /* GraphQL */ `
  mutation CreateUserAccountWithIAM($input: CreateUserAccountWithIAMInput!) {
    createUserAccountWithIAM(input: $input) {
      id
      planSubscriberId
      name
      organizationName
      departments
      position
      email
      phone
      roleName
      state
    }
  }
`;
export const removeUserAccount = /* GraphQL */ `
  mutation RemoveUserAccount($input: RemoveUserAccountInput!) {
    removeUserAccount(input: $input) {
      id
    }
  }
`;
export const resumeUserAccount = /* GraphQL */ `
  mutation ResumeUserAccount($input: ResumeUserAccountInput!) {
    resumeUserAccount(input: $input) {
      id
    }
  }
`;
export const suspendUserAccount = /* GraphQL */ `
  mutation SuspendUserAccount($input: SuspendUserAccountInput!) {
    suspendUserAccount(input: $input) {
      id
    }
  }
`;
export const updateUserAccount = /* GraphQL */ `
  mutation UpdateUserAccount($input: UpdateUserAccountInput!) {
    updateUserAccount(input: $input) {
      id
      planSubscriberId
      name
      organizationName
      departments
      position
      email
      phone
      roleName
      state
    }
  }
`;
export const updateUserRole = /* GraphQL */ `
  mutation UpdateUserRole($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input) {
      id
      name
      userAccountId
    }
  }
`;
export const createIndustrialWasteLicense = /* GraphQL */ `
  mutation CreateIndustrialWasteLicense(
    $input: CreateIndustrialWasteLicenseInput!
  ) {
    createIndustrialWasteLicense(input: $input) {
      id
      number
      japaneseLocalGovernment {
        id
        prefectures {
          code
          name
          removedAt
        }
        cityCode
        checkDigit
        cityName
        removedAt
      }
      category {
        code
        order
        name
        removedAt
      }
      licensedOn
      licenseExpirationOn
      certifiedAsExcellentOn
      companyName
      representativeName
      postalCode
      prefectures {
        code
        name
        removedAt
      }
      city
      streetAddress
      otherAddress
      isMapInput
      remarks
      state
      licensedCompanyId
      files {
        name
        url
        order
        label
      }
      version
    }
  }
`;
export const createMunicipalSolidWasteLicense = /* GraphQL */ `
  mutation CreateMunicipalSolidWasteLicense(
    $input: CreateMunicipalSolidWasteLicenseInput!
  ) {
    createMunicipalSolidWasteLicense(input: $input) {
      id
      number
      japaneseLocalGovernment {
        id
        prefectures {
          code
          name
          removedAt
        }
        cityCode
        checkDigit
        cityName
        removedAt
      }
      category {
        code
        order
        name
        removedAt
      }
      licensedOn
      licenseExpirationOn
      companyName
      representativeName
      postalCode
      prefectures {
        code
        name
        removedAt
      }
      city
      streetAddress
      otherAddress
      remarks
      state
      licensedCompanyId
      files {
        name
        url
        order
        label
      }
      isMapInput
      version
    }
  }
`;
export const createWaste = /* GraphQL */ `
  mutation CreateWaste($input: CreateWasteInput!) {
    createWaste(input: $input) {
      id
      number
      type {
        id
        largeClass {
          code
          name
          removedAt
        }
        middleClass {
          code
          name
          removedAt
        }
        smallClass {
          code
          name
          removedAt
        }
        group {
          id
          name
          removedAt
        }
        removedAt
      }
      kind
      name
      packing {
        code
        name
        removedAt
      }
      packingDetails
      quantityUnit {
        code
        name
        removedAt
      }
      weightConvertedValue
      weightConvertedValueUnit {
        id
        order
        name
        removedAt
      }
      dischargeFrequency {
        interval {
          id
          order
          name
          removedAt
        }
        numberOfTimes
      }
      dischargeAmountPerOneTime
      isImportedWaste
      state
      dischargeSourceWorkplaceId
      version
    }
  }
`;
export const createWasteFlow = /* GraphQL */ `
  mutation CreateWasteFlow($input: CreateWasteFlowInput!) {
    createWasteFlow(input: $input) {
      id
      number
      name
      targetWasteType {
        id
        largeClass {
          code
          name
          removedAt
        }
        middleClass {
          code
          name
          removedAt
        }
        smallClass {
          code
          name
          removedAt
        }
        group {
          id
          name
          removedAt
        }
        removedAt
      }
      targetWasteKind
      transportProcesses {
        section
        carrierCompany {
          id
          name
          phoneticGuidesOfName
        }
        transportMethod {
          code
          name
          removedAt
        }
        transportDestinationWorkplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
        }
        version
      }
      disposalProcess {
        disposalCompany {
          id
          name
          phoneticGuidesOfName
        }
        disposalWorkplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
        }
        disposalMethod {
          code
          largeClassName
          middleClassName
          smallClassName
          removedAt
        }
        disposalMethodDetails
        version
      }
      lastDisposalProcesses {
        disposalWorkplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
        }
        version
      }
      state
      managedCompanyId
      version
    }
  }
`;
export const createWasteManagementMethod = /* GraphQL */ `
  mutation CreateWasteManagementMethod(
    $input: CreateWasteManagementMethodInput!
  ) {
    createWasteManagementMethod(input: $input) {
      id
      targetWasteType {
        id
        largeClass {
          code
          name
          removedAt
        }
        middleClass {
          code
          name
          removedAt
        }
        smallClass {
          code
          name
          removedAt
        }
        group {
          id
          name
          removedAt
        }
        removedAt
      }
      reportingMedium {
        id
        order
        name
        removedAt
      }
      wasteFlowId
      state
      managedWasteId
      version
    }
  }
`;
export const createWasteReportingSetting = /* GraphQL */ `
  mutation CreateWasteReportingSetting(
    $input: CreateWasteReportingSettingInput!
  ) {
    createWasteReportingSetting(input: $input) {
      id
      validityStartOn
      validityEndOn
      reportingMedia
      wasteManagementMethodId
      version
    }
  }
`;
export const deleteWasteReportingSetting = /* GraphQL */ `
  mutation DeleteWasteReportingSetting(
    $input: DeleteWasteReportingSettingInput!
  ) {
    deleteWasteReportingSetting(input: $input) {
      id
    }
  }
`;
export const removeWaste = /* GraphQL */ `
  mutation RemoveWaste($input: RemoveWasteInput!) {
    removeWaste(input: $input) {
      id
    }
  }
`;
export const removeWasteFlow = /* GraphQL */ `
  mutation RemoveWasteFlow($input: RemoveWasteFlowInput!) {
    removeWasteFlow(input: $input) {
      id
    }
  }
`;
export const removeWasteLicense = /* GraphQL */ `
  mutation RemoveWasteLicense($input: RemoveWasteLicenseInput!) {
    removeWasteLicense(input: $input) {
      id
    }
  }
`;
export const resumeWasteManagementMethod = /* GraphQL */ `
  mutation ResumeWasteManagementMethod(
    $input: ResumeWasteManagementMethodInput!
  ) {
    resumeWasteManagementMethod(input: $input) {
      id
    }
  }
`;
export const suspendWasteManagementMethod = /* GraphQL */ `
  mutation SuspendWasteManagementMethod(
    $input: SuspendWasteManagementMethodInput!
  ) {
    suspendWasteManagementMethod(input: $input) {
      id
    }
  }
`;
export const updateIndustrialWasteLicense = /* GraphQL */ `
  mutation UpdateIndustrialWasteLicense(
    $input: UpdateIndustrialWasteLicenseInput!
  ) {
    updateIndustrialWasteLicense(input: $input) {
      id
      number
      japaneseLocalGovernment {
        id
        prefectures {
          code
          name
          removedAt
        }
        cityCode
        checkDigit
        cityName
        removedAt
      }
      category {
        code
        order
        name
        removedAt
      }
      licensedOn
      licenseExpirationOn
      certifiedAsExcellentOn
      companyName
      representativeName
      postalCode
      prefectures {
        code
        name
        removedAt
      }
      city
      streetAddress
      otherAddress
      isMapInput
      remarks
      state
      licensedCompanyId
      files {
        name
        url
        order
        label
      }
      version
    }
  }
`;
export const updateMunicipalSolidWasteLicense = /* GraphQL */ `
  mutation UpdateMunicipalSolidWasteLicense(
    $input: UpdateMunicipalSolidWasteLicenseInput!
  ) {
    updateMunicipalSolidWasteLicense(input: $input) {
      id
      number
      japaneseLocalGovernment {
        id
        prefectures {
          code
          name
          removedAt
        }
        cityCode
        checkDigit
        cityName
        removedAt
      }
      category {
        code
        order
        name
        removedAt
      }
      licensedOn
      licenseExpirationOn
      companyName
      representativeName
      postalCode
      prefectures {
        code
        name
        removedAt
      }
      city
      streetAddress
      otherAddress
      remarks
      state
      licensedCompanyId
      files {
        name
        url
        order
        label
      }
      isMapInput
      version
    }
  }
`;
export const updateWaste = /* GraphQL */ `
  mutation UpdateWaste($input: UpdateWasteInput!) {
    updateWaste(input: $input) {
      id
      number
      type {
        id
        largeClass {
          code
          name
          removedAt
        }
        middleClass {
          code
          name
          removedAt
        }
        smallClass {
          code
          name
          removedAt
        }
        group {
          id
          name
          removedAt
        }
        removedAt
      }
      kind
      name
      packing {
        code
        name
        removedAt
      }
      packingDetails
      quantityUnit {
        code
        name
        removedAt
      }
      weightConvertedValue
      weightConvertedValueUnit {
        id
        order
        name
        removedAt
      }
      dischargeFrequency {
        interval {
          id
          order
          name
          removedAt
        }
        numberOfTimes
      }
      dischargeAmountPerOneTime
      isImportedWaste
      state
      dischargeSourceWorkplaceId
      version
    }
  }
`;
export const updateWasteFlow = /* GraphQL */ `
  mutation UpdateWasteFlow($input: UpdateWasteFlowInput!) {
    updateWasteFlow(input: $input) {
      id
      number
      name
      targetWasteType {
        id
        largeClass {
          code
          name
          removedAt
        }
        middleClass {
          code
          name
          removedAt
        }
        smallClass {
          code
          name
          removedAt
        }
        group {
          id
          name
          removedAt
        }
        removedAt
      }
      targetWasteKind
      transportProcesses {
        section
        carrierCompany {
          id
          name
          phoneticGuidesOfName
        }
        transportMethod {
          code
          name
          removedAt
        }
        transportDestinationWorkplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
        }
        version
      }
      disposalProcess {
        disposalCompany {
          id
          name
          phoneticGuidesOfName
        }
        disposalWorkplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
        }
        disposalMethod {
          code
          largeClassName
          middleClassName
          smallClassName
          removedAt
        }
        disposalMethodDetails
        version
      }
      lastDisposalProcesses {
        disposalWorkplace {
          id
          name
          phoneticGuidesOfName
          postalCode
          prefectures {
            code
            name
            removedAt
          }
          city
          streetAddress
          otherAddress
          email
          phone
          fax
          position {
            lat
            lng
          }
        }
        version
      }
      state
      managedCompanyId
      version
    }
  }
`;
export const updateWasteReportingSetting = /* GraphQL */ `
  mutation UpdateWasteReportingSetting(
    $input: UpdateWasteReportingSettingInput!
  ) {
    updateWasteReportingSetting(input: $input) {
      id
      validityStartOn
      validityEndOn
      reportingMedia
      wasteManagementMethodId
      version
    }
  }
`;
