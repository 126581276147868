import { createSlice } from "@reduxjs/toolkit";

const defaultValue = {
  status: false,
};

const loading = createSlice({
  name: "Loading",
  initialState: defaultValue,
  reducers: {
    on: (state, action) => {
      state.status = true;
    },
    off: (state, action) => {
      state.status = false;
    },
    toggle: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const selector = (store) => store.Loading;

export const { on, off, toggle } = loading.actions;

export default loading.reducer;
