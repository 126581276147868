import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * フルスクリーンダイアログを表示するコンポーネントです
 * @param {boolean} open ダイアログを開閉するイベントです。
 * @param {func} onClose ダイアログを閉じるイベントです。
 *
 * @param {string} title ダイアログのタイトル
 * @param {string} avatarLabel ヘッダー表示のアバターを表示する
 * @param {boolean} showAppbar ヘッダー表示か非表示にする　例）true
 *
 * @callback children ダイアログの内容
 *
 */

export const FullScreenDialogMobile = containerPresententer(
  Container,
  Presententer
);
