import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";
/**
 * アイテム選択を表示するコンポーネントです
 * @param {JSX} icon icon
 * @param {string} className 初心者をするときにカラス名が必要です。
 * @param {func} onClick ボタンを押すイベント
 * @param {string} label ボタンのラベル
 * @returns　JSX Element
 */
export const RenderMenuSelectItem = containerPresententer(
  Container,
  Presententer
);
