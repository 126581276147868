import { useStyles } from "./styles";
import useClasses from "utils/useClasses";

/**
 * 事業者のタブを表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 事業場情報
 * @param {object} props その他プロパティ
 * @return {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  const classes = useClasses(useStyles);

  return render({ ...props, classes: classes, value: value });
};
