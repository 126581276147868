import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 *　ラディオを表示するコンポーネントです
 * @param {string} label テキストのレベル
 * @param {funct} onChange データ更新を処理する
 * @param {boolean} required 必須かどうか設定
 * @param {string} value ラディオの値
 * @param {array} options Radioの配列　例）[{value:"", mainTitle:"", subTitle: ""}]
 * @param {boolean} row 水平または垂直に設定 false
 * @param {boolean} sizeLarge 大木サイズに設定 例）true
 * @returns {JSX.Elemnt} JSX 要素
 */
export const RadioGroup = containerPresententer(Container, Presententer);
