import { Box } from "@mui/material";
import React from "react";
import { FixedSizeList } from "react-window";
import { CollectPoint } from "../CollectPoint";
import { Nothing } from "../Nothing";

const RenderCollectPoint = ({ data, index, style }) => {
  return (
    <div style={{ ...style, paddingRight: "8px" }}>
      <CollectPoint value={data.items[index]} index={index + 1} />
    </div>
  );
};

/**
 * 1コースの回収情報を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box sx={{ marginRight: "-8px" }} mt={1}>
      {props.value && props.value?.length > 0 ? (
        <FixedSizeList
          height={props.height - 120}
          itemCount={props.value?.length}
          itemData={{
            items: props.value,
          }}
          itemSize={166}
        >
          {RenderCollectPoint}
        </FixedSizeList>
      ) : (
        <Nothing />
      )}
    </Box>
  );
};
