import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Grid, Paper } from "@mui/material";
import { Selector as ContainerDisuseTypesSelector } from "ducks/ContainerDisuseTypes/Components";
import { useEffect, useImperativeHandle } from "react";
import { Controller, useForm } from "react-hook-form";
import { debugLog } from "utils/log";
import { DatePicker, TextField } from "views/atoms";
import { FileGallery, Uploader } from "views/molecules";
import { basicSchema } from "./Validations";

const defaultValues = {
  disuseType: null,
  disusedOn: null,
  remarks: null,
  files: [],
};

/**
 * コンテナ使用停止をフォームを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @fires StopUsingForm#onSubmit 送信時
 * @fires StopUsingForm#onError エラー時
 * @param {object} _ref
 * @returns {JSX.Element}
 */
export const StopUsingForm = ({
  onSubmit,
  onError = (err) => debugLog("コンテナ使用停止フォームのエラー: ", err),
  loading = false,
  _ref,
}) => {
  const { control, getValues, trigger, formState, reset, watch } = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset]);

  useImperativeHandle(_ref, () => ({
    submit: () => {
      trigger().then((res) => {
        if (res) {
          onSubmit && onSubmit(getValues());
        } else {
          onError(formState.errors);
        }
      });
    },
    get: () => watch(),
  }));

  return (
    <Container maxWidth="md">
      <Paper
        elevation={3}
        sx={{
          p: 5,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name={`disuseType`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <ContainerDisuseTypesSelector
                  label="停止種別"
                  value={value}
                  onChange={onChange}
                  placeholder="未選択"
                  required={true}
                  error={error}
                  disabled={loading}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={`disusedOn`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <DatePicker
                  label="停止日"
                  required={true}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  disabled={loading}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={`remarks`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  label="備考"
                  required={false}
                  placeholder="特記事項があれば記載します。"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  multiline={true}
                  rows={3}
                  disabled={loading}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={`files`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <>
                  <Uploader
                    fullWidth={true}
                    maxSize={26214400}
                    onChange={(files) => {
                      onChange([...(value ?? []), ...files]);
                    }}
                    dropzoneText="最大ファイルサイズ:25MB、ファイル形式:JPEG、PDF"
                    accept={[".pdf", ".jpg", ".jpeg"]}
                    disabled={loading}
                    error={Boolean(error)}
                    helperText={error?.message}
                  />
                  <FileGallery
                    value={value}
                    onChange={onChange}
                    disabled={loading}
                  />
                </>
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
