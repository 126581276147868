import { css } from "@emotion/css";
import { useTheme } from "@emotion/react";
import { useMemo } from "react";

/**
 * MaterialUI v5移行用のロジックです。
 * makeStylesの代わりとなるものです。
 * HOOKSのため記述位置に注意してください。
 * @param {object} stylesElement スタイルオブジェクト
 * @returns {object}
 */
const useClasses = (stylesElement) => {
  const theme = useTheme();
  return useMemo(() => {
    const rawClasses =
      typeof stylesElement === "function"
        ? stylesElement(theme)
        : stylesElement;
    const prepared = {};

    Object.entries(rawClasses).forEach(([key, value = {}]) => {
      prepared[key] = css(value);
    });

    return prepared;
  }, [stylesElement, theme]);
};

export default useClasses;
