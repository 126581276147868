import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
} from "@mui/material";
import clsx from "clsx";

/**
 *　/**
 * ラディオの表示を行うプレゼンテーションコンポーネントです。
 * @param {string} label テキストのレベル
 * @param {object} classes クラス名
 * @param {funct} onChange データ更新を処理する
 * @param {boolean} required 必須かどうか設定
 * @param {string} value テキストフィールドのバリュー
 * @param {array} options Radioの配列　例）[{value:"", mainTitle:"", subTitle: ""}]
 */
export const Presententer = ({
  label,
  onChange,
  value,
  classes,
  required = false,
  options = [],
  row = false,
  sizeLarge = true,
  ...props
}) => {
  return (
    <Box display="flex" flexDirection="column" fontSize="14px">
      {label ? (
        <div className={classes.label}>
          {label}{" "}
          {required ? <span className={classes.required}>必須</span> : ""}
        </div>
      ) : (
        ""
      )}
      <FormControl component="fieldset" fullWidth error={props.error} disabled={props.disabled}>
        <RadioGroup
          aria-label="gender"
          name={`${label}radio`}
          value={value}
          onChange={onChange}
          row={row}
        >
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={
                <Radio
                  color="primary"
                  className={clsx(sizeLarge && classes.sizeLarge)}
                />
              }
              className={clsx(row === false && classes.controlLabelRadio)}
              label={
                <Box>
                  <Box className={classes.mainTitle}>{option.mainTitle}</Box>
                  {option.subTitle ? (
                    <Box className={classes.subTitle}>{option.subTitle}</Box>
                  ) : (
                    ""
                  )}
                </Box>
              }
            />
          ))}
        </RadioGroup>
        <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
};
