import { useState } from "react";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { get } from "lodash";
import { createPaperManifest } from "api/graphql/mutations";
import { searchPaperManifestsByOffset } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { companySelector } from "ducks/Company";
import { PhoneNumberFormatUtil } from "utils/format";
import { debugLog } from "utils/log";
import {
  fromQueryString,
  toQueryString,
  useXGridComponents,
} from "utils/useXGridComponents";
import {
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-pro";

const columns = [
  {
    field: "issueDate",
    headerName: "交付日",
    minWidth: 120,
    flex: 1,
    valueFormatter: (params) => {
      return `${
        params?.value
          ? DateTime.fromISO(params?.value).toFormat("yyyy/MM/dd")
          : ""
      }`;
    },
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "issueNumber",
    headerName: "交付番号",
    minWidth: 140,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "wasteGeneratorCompanyName",
    headerName: "排出事業者",
    minWidth: 160,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "wasteGeneratorWorkplaceName",
    headerName: "排出事業場",
    minWidth: 160,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "wasteTypeName",
    headerName: "廃棄物の種類",
    minWidth: 160,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "wasteName",
    headerName: "廃棄物名",
    minWidth: 160,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "wasteQuantityValue",
    headerName: "廃棄物の数量",
    minWidth: 180,
    flex: 1,
    type: "number",
    filterOperators: getGridNumericOperators().filter(
      (operator) =>
        operator.value === ">" ||
        operator.value === ">=" ||
        operator.value === "<" ||
        operator.value === "<=" ||
        operator.value === "=" ||
        operator.value === "!="
    ),
  },
  {
    field: "wasteQuantityUnitName",
    headerName: "廃棄物の単位",
    minWidth: 180,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "wasteTypeCode",
    headerName: "廃棄物種類コード",
    minWidth: 220,
    flex: 1,
    hide: true,
    filterable: false,
  },
];

/**
 * 紙マニフェストの一覧を表示するコンテナコンポーネントです
 * @callback render
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const company = useSelector(companySelector);
  const xGridObject = useXGridComponents(
    columns,
    searchPaperManifestsByOffset,
    null,
    {
      sort: {
        direction: "desc",
        field: "issueDate",
      },
      ...fromQueryString(location.search),
      filter: {
        defaultColumnField: get(columns, "[2].field"),
      },
    }
  );

  const getDate = (value) => {
    if (!value || value === "") {
      return null;
    }

    let result = DateTime.fromFormat(value, "yyyy/MM/dd");

    if (result.isValid) {
      return result.toISODate();
    }

    result = DateTime.fromJSDate(new Date(value));

    if (result.isValid) {
      return result.toISODate();
    }

    return value;
  };

  const createManifest = (data) => {
    const {
      name,
      phone,
      postalCode,
      prefectures,
      city,
      streetAddress,
      otherAddress,
      paperManifestType,
      manifestInfoType,
      manifestCardinalNumberType,
      issueDate,
      intermediateProcessingManagementType,
      lastDisposalPlantDescribeType,
      wasteGeneratorCompany,
      wasteGeneratorWorkplace,
      waste,
      plannedLastDisposalPlant,
      wasteCarrierCompany,
      wasteTransportDestination,
      wasteTransshipmentStorage,
      wasteDisposalCompany,
      wasteLastDisposalPlant,
      wasteTransportReport,
      wasteDisposalReport,
      ...other
    } = data;

    return API.graphql(
      graphqlOperation(createPaperManifest, {
        input: {
          ownerCompanyId: company.id,
          paperManifestTypeCode: paperManifestType?.code,
          manifestInfoTypeCode: manifestInfoType?.code,
          manifestCardinalNumberTypeCode: manifestCardinalNumberType?.code,
          issueDate: getDate(issueDate),
          intermediateProcessingManagementTypeCode:
            intermediateProcessingManagementType?.code,
          lastDisposalPlantDescribeTypeCode:
            lastDisposalPlantDescribeType?.code,
          wasteGeneratorCompany: {
            ...wasteGeneratorCompany,
            id: undefined,
            prefecturesCode: wasteGeneratorCompany?.prefectures?.code,
            prefectures: undefined,
            phone: wasteGeneratorCompany?.phone
              ? PhoneNumberFormatUtil.formatInternational(
                  wasteGeneratorCompany.phone
                )
              : null,
          },
          wasteGeneratorWorkplace: {
            ...wasteGeneratorWorkplace,
            prefecturesCode: wasteGeneratorWorkplace?.prefectures?.code,
            prefectures: undefined,
            phone: wasteGeneratorWorkplace?.phone
              ? PhoneNumberFormatUtil.formatInternational(
                  wasteGeneratorWorkplace.phone
                )
              : null,
          },
          waste: {
            ...waste,
            quantityValue:
              !waste.quantityValue || waste.quantityValue === ""
                ? null
                : waste.quantityValue,
            quantityUnitCode: waste.quantityUnit?.code,
            types: waste.types?.map((type) => {
              return {
                id: type.wasteType.id,
                name: type.wasteTypeName,
              };
            }),
            quantityUnit: undefined,
          },
          plannedLastDisposalPlant: {
            ...plannedLastDisposalPlant,
            prefecturesCode:
              plannedLastDisposalPlant?.prefectures?.code ?? null,
            prefectures: undefined,
            phone: plannedLastDisposalPlant?.phone
              ? PhoneNumberFormatUtil.formatInternational(
                  plannedLastDisposalPlant.phone
                )
              : null,
          },
          wasteCarrierCompany: {
            ...wasteCarrierCompany,
            id: undefined,
            prefecturesCode: wasteCarrierCompany?.prefectures?.code,
            prefectures: undefined,
            phone: wasteCarrierCompany?.phone
              ? PhoneNumberFormatUtil.formatInternational(
                  wasteCarrierCompany.phone
                )
              : null,
          },
          wasteTransportDestination: {
            ...wasteTransportDestination,
            prefecturesCode: wasteTransportDestination?.prefectures?.code,
            prefectures: undefined,
            phone: wasteTransportDestination?.phone
              ? PhoneNumberFormatUtil.formatInternational(
                  wasteTransportDestination.phone
                )
              : null,
          },
          wasteTransshipmentStorage: {
            ...wasteTransshipmentStorage,
            prefecturesCode: wasteTransshipmentStorage?.prefectures?.code,
            prefectures: undefined,
            phone: wasteTransshipmentStorage?.phone
              ? PhoneNumberFormatUtil.formatInternational(
                  wasteTransshipmentStorage.phone
                )
              : null,
          },
          wasteDisposalCompany: {
            ...wasteDisposalCompany,
            prefecturesCode: wasteDisposalCompany?.prefectures?.code,
            prefectures: undefined,
            phone: wasteDisposalCompany?.phone
              ? PhoneNumberFormatUtil.formatInternational(
                  wasteDisposalCompany.phone
                )
              : null,
          },
          wasteLastDisposalPlant: {
            ...wasteLastDisposalPlant,
            prefecturesCode: wasteLastDisposalPlant?.prefectures?.code,
            prefectures: undefined,
            phone: wasteLastDisposalPlant?.phone
              ? PhoneNumberFormatUtil.formatInternational(
                  wasteLastDisposalPlant.phone
                )
              : null,
          },
          wasteTransportReport: {
            ...wasteTransportReport,
            valuablesQuantityUnitCode:
              wasteTransportReport.valuablesQuantityUnit?.code,
            valuablesQuantityValue:
              !wasteTransportReport?.valuablesQuantityValue ||
              wasteTransportReport?.valuablesQuantityValue === ""
                ? null
                : wasteTransportReport?.valuablesQuantityValue,
            valuablesQuantityUnit: undefined,
            endOfTransportationDate: getDate(
              wasteTransportReport?.endOfTransportationDate
            ),
          },
          wasteDisposalReport: {
            ...wasteDisposalReport,
            endOfDisposalDate: getDate(wasteDisposalReport?.endOfDisposalDate),
            endOfLastDisposalDate: getDate(
              wasteDisposalReport?.endOfLastDisposalDate
            ),
          },
          ...other,
        },
      })
    );
  };

  const handleClick = (params) => {
    navigate(
      {
        pathname: location.pathname,
        search: toQueryString(xGridObject.search),
      },
      {
        replace: true,
      }
    );

    navigate(`${location.pathname}/${params[0]}`);
  };

  const handleSubmit = (data) => {
    setIsLoading(false);
    createManifest(data)
      .then((res) => {
        xGridObject.functions.refetch();
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        setOpen(false);
      })
      .catch((err) => {
        debugLog("登録失敗: ", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return render({
    open: open,
    xGridParams: xGridObject.params,
    isLoading: isLoading,
    onClick: handleClick,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    onSubmit: handleSubmit,
    ...props,
  });
};
