import { DetailsFrame } from "views/organisms/DetailsFrame";
import { Paper, Stack, Skeleton, Typography } from "@mui/material";
import { Caption } from "views/organisms/DetailsFrame/ChildComponents";
import { If, InformationItem } from "views/atoms";
import { useQuery } from "utils/useQuery";
import { DateTime } from "luxon";
import { getPlanSubscriber, listWorkplacesTotals } from "api/graphql/queries";
import { userSelector, isLoadingSelector, errorSelector } from "ducks/User";
import { useSelector } from "react-redux";
import { XGridComponent } from "views/molecules";

const isoDateToText = (value) => {
  if (!value) {
    return "";
  }

  const result = DateTime.fromISO(value, { setZone: "Asia/Tokyo" });

  if (result.isValid) {
    return result.toFormat("yyyy/MM/dd");
  }

  return "";
};

const columns = [
  {
    field: "calculatedOn",
    headerName: "算出日",
    width: 150,
    renderCell: (params) => isoDateToText(params.value),
  },
  {
    field: "count",
    headerName: "有効事業所数",
    type: "number",
    width: 120,
    renderCell: (params) =>
      (params?.row?.workplaceCount ?? 0) +
      (params?.row?.clientWorkplaceCount ?? 0),
    sortable: false,
  },
];

/**
 * システムの加入者情報を表示するコンポーネントです。
 */
export const SystemSubscriberView = () => {
  const currentUser = useSelector(userSelector);
  const isLoading = useSelector(isLoadingSelector);
  const error = useSelector(errorSelector);

  const workplacesTotals = useQuery({
    query: listWorkplacesTotals,
    variables: {
      planSubscriberId: currentUser?.planSubscriberId,
      sortDirection: "DESC",
    },
    onCompleted: () => {},
    onError: () => {},
  });

  const systemSubscriber = useQuery({
    query: getPlanSubscriber,
    variables: {
      id: currentUser?.planSubscriberId,
    },
    onCompleted: () => {},
  });

  if (error) {
    return (
      <Typography color="error.main">{"エラーが発生しました。"}</Typography>
    );
  }

  return (
    <DetailsFrame
      caption={
        <Stack>
          <Caption title={`加入者情報`} subTitle={``} />
        </Stack>
      }
      actions={<></>}
    >
      <Paper
        elevation={2}
        sx={{
          p: 2,
        }}
      >
        <If
          condition={systemSubscriber.loading === false || isLoading}
          elseComponent={
            <Stack spacing={2}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Stack>
          }
        >
          <Stack>
            <InformationItem label={"事業場数"}>
              {systemSubscriber?.data?.getPlanSubscriber?.workplaceCount +
                systemSubscriber?.data?.getPlanSubscriber?.clientWorkplaceCount}
            </InformationItem>
            <InformationItem label={"プラン名"}>
              {systemSubscriber?.data?.getPlanSubscriber?.plan?.name ?? ""}
            </InformationItem>
            <InformationItem label={"加入日"}>
              {isoDateToText(
                systemSubscriber?.data?.getPlanSubscriber?.startedUsingAt
              )}
            </InformationItem>
          </Stack>
        </If>
      </Paper>
      <Paper
        sx={{
          p: 2,
          mt: 1,
        }}
      >
        <XGridComponent
          columns={columns}
          checkboxSelection={false}
          multipleSelection={false}
          onDownload={false}
          onFilter={false}
          onColumns={false}
          rows={
            workplacesTotals?.data?.listWorkplacesTotals?.items?.map(
              (i, index) => ({ id: index, ...i })
            ) ?? []
          }
        />
      </Paper>
    </DetailsFrame>
  );
};
