import { useState } from "react";
import useClasses from "utils/useClasses";
import { styles } from "./styles";

/**
 * コースのメニューを表示するコンテナコンポーネントです。
 * @param {*} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string} id 固有番号
 * @param {object} value コース情報
 * @fires Container#onLeft リストを左へ移動させるとき
 * @fires Container#onRight リストを右へ移動させるとき
 * @fires Container#onOrderTimes 時間の並び変えを行うとき
 * @fires Container#onReleaseItems 割り当てを解除するとき
 * @fires Container#onLeft コースを解除するとき
 * @param {boolean} left リストを左へ移動させるボタンの有効状態
 * @param {boolean} right リストを右へ移動させるボタンの有効状態
 * @param {object} props その他プロパティ
 * @returns
 */
export const Container = ({ render, id, value, ...props }) => {
  const classes = useClasses(styles);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setAnchorEl(null);
    }
  };

  const onMenuItemClick = (itemId) => {
    setAnchorEl(null);
    props.handleMenuItemClick(itemId);
  };

  return render({
    classes: classes,
    onCloseMenu: handleClose,
    open: Boolean(anchorEl),
    anchorEl: anchorEl,
    onToggle: handleClick,
    onListKeyDown: handleListKeyDown,
    onMenuItemClick,
    ...props,
  });
};
