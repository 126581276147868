import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useStyles } from "./styles";
/**
 * 複数イメージのアップロードの表示を行うコンテナコンポーネントです
 * @param {object} onChange アップロードされたイメージを取得する
 * @callback render
 */
export const Container = ({ render, label, value, onChange, ...props }) => {
  const classes = useStyles();
  const [files, setFiles] = useState({});
  const [newName, setNewName] = useState({ name: "", size: "", suffix: "" });
  const [errors, setErrors] = useState(null);
  const [showUpload, setShowUpload] = useState(true);
  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          const uploadFile = reader.result;

          setFiles({
            name: file?.name,
            size: file?.size,
            file: uploadFile,
          });
          const name = file?.name;
          let nameSplit = name.split(".");
          setNewName({
            name: nameSplit[0],
            suffix: nameSplit[1],
            size: formatBytes(file?.size, 2),
          });
          onChange({
            name: name,
            size: file?.size,
            file: uploadFile,
          });
          setErrors(null);
          setShowUpload((prevState) => !prevState);
        };
        reader.readAsArrayBuffer(file);
      });
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setErrors(`ファイルサイズが25MBより大きいです。`);
          }
          if (err.code === "file-invalid-type") {
            setErrors(`ファイル形式がPDFじゃありませんでした。`);
          }
        });
      });
    },
    [onChange]
  );
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: ".pdf",
    maxSize: 26214400,
    multiple: false,
    onDrop: onDrop,
  });
  const handleDeleteFile = () => {
    setFiles(null);
    setErrors(null);
    onChange && onChange({});
    setShowUpload(true);
  };
  const handleChangeNewName = (e) => {
    setNewName({
      ...newName,
      name: e.target.value,
    });
    onChange({
      ...files,
      name: e.target.value,
    });
  };

  const handleGetNewTitle = (index, newTitle) => {
    const newFiles = files.map((file, i) => {
      if (i === index) {
        return {
          ...file,
          name: newTitle,
        };
      }
      return file;
    });
    setFiles(newFiles);
  };
  return render({
    ...props,
    label: label,
    classes: classes,
    value: value,
    files: files,
    errors: errors,
    getRootProps: getRootProps,
    getInputProps: getInputProps,
    onDeleteFile: handleDeleteFile,
    onGetNewTitle: handleGetNewTitle,

    newName: newName,
    onChangeName: handleChangeNewName,
    showUpload: showUpload,
  });
};
