import CancelIcon from "@mui/icons-material/Cancel";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ReportIcon from "@mui/icons-material/Report";
import { Box, Button, Grid, Stack } from "@mui/material";
import { Controller } from "react-hook-form";
import { DatePicker, FreeSolo } from "views/atoms";
import { FieldDecorateFrame, InputField } from "views/molecules";
import { QuantityField } from "../QuantityField";

const valuablesUnits = [
  {
    code: 1,
    name: "t",
  },
  {
    code: 2,
    name: "㎥",
  },
  {
    code: 3,
    name: "kg",
  },
  {
    code: 4,
    name: "リットル",
  },
];

/**
 * 運搬終了報告を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box pt={3} pb={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
            {!props.existValue && !props.requesting && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<ReportIcon />}
                onClick={props.onReport}
              >
                報告
              </Button>
            )}
            {props.existValue && !props.requesting && (
              <>
                <Button
                  variant="contained"
                  color="info"
                  startIcon={<ModeEditIcon />}
                  onClick={props.onEdit}
                >
                  修正
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<CancelIcon />}
                  onClick={props.onCancel}
                >
                  取消
                </Button>
              </>
            )}
            {props?.requesting && (
              <Button variant="outlined" disabled>
                EDI送信中
              </Button>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <FieldDecorateFrame label="運搬終了日" required={true}>
            {(params) => (
              <Controller
                name="transportationEndedOn"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <DatePicker
                      value={value}
                      onChange={onChange}
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  );
                }}
              />
            )}
          </FieldDecorateFrame>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="transportPersonInCharge"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FieldDecorateFrame label="運搬担当者" required={true}>
                {(params) => (
                  <FreeSolo
                    value={value}
                    onChange={onChange}
                    error={Boolean(error)}
                    helperText={error?.message}
                    required={params.required}
                    options={[]}
                  />
                )}
              </FieldDecorateFrame>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="reporter"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FieldDecorateFrame label="報告担当者">
                {(params) => (
                  <FreeSolo
                    value={value}
                    onChange={onChange}
                    error={Boolean(error)}
                    helperText={error?.message}
                    required={params.required}
                    options={[]}
                  />
                )}
              </FieldDecorateFrame>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="quantity"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FieldDecorateFrame label="運搬量">
                  {(params) => (
                    <QuantityField
                      value={value}
                      onChange={onChange}
                      error={Boolean(error)}
                      helperText={
                        error?.message ||
                        error?.value?.message ||
                        error?.unit?.message
                      }
                    />
                  )}
                </FieldDecorateFrame>
              );
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="valuablesQuantity"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FieldDecorateFrame label="有価物収拾量">
                  {(params) => (
                    <QuantityField
                      value={value}
                      onChange={onChange}
                      error={Boolean(error)}
                      helperText={
                        error?.message ||
                        error?.value?.message ||
                        error?.unit?.message
                      }
                      units={valuablesUnits}
                    />
                  )}
                </FieldDecorateFrame>
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="vehicleNumber"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputField
                name="name"
                label="車両番号"
                value={value}
                onChange={onChange}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="remarks"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputField
                label="備考"
                value={value}
                onChange={onChange}
                error={error}
                multiline={true}
                rows={1}
                placeholder="特記事項があれば記載します。"
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
