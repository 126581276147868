import { listWasteReportingMedia } from "api/graphql/queries";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "utils/graphqlOperation";

/**
 * 報告媒体を取得します。
 * @returns {object}
 */
const fetch = async () => {
  try {
    const fetchedData = await API.graphql(
      graphqlOperation(listWasteReportingMedia)
    );
    return fetchedData.data.listWasteReportingMedia.items;
  } catch (error) {
    throw error;
  }
};

export const fetchWasteReportingMedia = createAsyncThunk(
  "wasteReportingMedia/fetch",
  async (_, thunkApi) => {
    return fetch();
  }
);
