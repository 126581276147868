import useClasses from "utils/useClasses";
import { styles } from "./styles";

/*
 * object shape
 *   order[回収順番]: number
 *   times[時刻]: object
 *     start[開始]: Date
 *     end[終了]: Date
 *   name[回収場所]: string
 *   wasteName[廃棄物名]: string
 *   quantity[数量]: number
 *   unit[単位]: string ???
 */

/**
 * 1回収場所を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  const classes = useClasses(styles);
  return render({
    classes: classes,
    value: value,
    ...props,
  });
};
