import { listPlans } from "api/graphql/queries";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "utils/graphqlOperation";

/**
 * 加入プランを取得します。
 * @returns {object}
 */
const fetch = async () => {
  try {
    const fetchedData = await API.graphql(graphqlOperation(listPlans));
    return fetchedData.data.listPlans.items;
  } catch (error) {
    throw error;
  }
};

export const fetchPlans = createAsyncThunk(
  "plans/fetch",
  async (_, thunkApi) => {
    return fetch();
  }
);
