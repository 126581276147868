import { useCallback } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import PropTypes from "prop-types";

const options = {
  // デフォルトUI（衛星写真オプションなど）をキャンセルします。
  disableDefaultUI: true,
  mapTypeControl: true,
  mapTypeControlOptions: {
    position: 7.0,
    mapTypeIds: ["roadmap", "satellite", "hybrid"],
  },
};

/**
 * グーグルマップを表示するコンテナコンポーネントです。
 * @param {JSX.Element} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} center It contains the center elements object
 * @param {JSX.Element} children
 * @param {func} onClick It handles click event in map
 * @returns {JSX.Element}
 */
export const Container = ({ render, center, children, onClick, mapRef }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_LICENSE_KEY,
    region: "JP",
    language: "ja",
  });

  const onMapLoad = useCallback(
    (map) => {
      mapRef.current = map;
    },
    [mapRef]
  );

  const onUnmount = useCallback(() => {
    mapRef.current = null;
  }, [mapRef]);

  return render({
    onMapLoad,
    onUnmount,
    isLoaded,
    loadError,
    options,
    center,
    children,
    onClick,
  });
};

Container.propTypes = {
  render: PropTypes.func,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    zoom: PropTypes.number,
  }).isRequired,
  children: PropTypes.node,
  onClick: PropTypes.func,
  mapRef: PropTypes.object,
};
