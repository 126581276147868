import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing(2),
    "& .collect_item": {
      padding: "0 16px",
      marginBottom: "16px",
      "& .MuiListItem-secondaryAction": {
        paddingRight: theme.spacing(1),
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& .MuiListItemSecondaryAction-root": {
        right: "-24px",
      },
      "& .MuiListItemText-multiline": {
        margin: 0,
      },
      "& .number": {
        textAlign: "center",
        border: "1px solid",
        borderRadius: "20px",
        borderColor: theme.palette.primary.main,
        padding: 0,
        minWidth: "40px",
        miHeight: "30px",
      },
    },
  },

  collect_title: {
    fontSize: "13px",
    color: theme.palette.grey[800],
    marginBottom: "5px",
    display: "block",
    fontWeight: "600",
  },
  collect_Subtitle: {
    fontSize: "11px",
    color: theme.palette.grey[500],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& .time": {
      display: "flex",
      alignItems: "center",
      "& svg": {
        fontSize: "18px",
        marginRight: "5px",
      },
    },
    "& span": {
      width: "100%",
    },
    "@media (max-width: 321px)": {
      flexDirection: "column",
      justifyContent: "flex-start",
      "& .time": {
        marginBottom: "5px",
      },
    },
  },
}));
