import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toFiles } from "utils/ImageFunctions";

/**
 * ファイルアップローダーを表示するコンテナコンポーネントです
 */
export const Container = ({
  render,
  label = "添付ファイル",
  dropzoneText = "",
  accept,
  maxSize = Infinity,
  multiple = true,
  disabled = false,
  maxFiles = 0,
  minSize = 0,
  onChange,
  error = false,
  helperText = "",
  ...props
}) => {
  const [message, setMessage] = useState(null);

  useEffect(() => {
    setMessage(null);
  }, []);

  const handleDrop = (params) => {
    setMessage(null);
    toFiles({ fileObjects: params }).then((res) => {
      onChange(res);
    });
  };

  const handleRejection = (params) => {
    let result = [];
    for (let param of params) {
      for (let err of param.errors) {
        switch (err.code) {
          case "file-invalid-type":
            result.push(param.file.name + " はファイル形式が違います。");
            break;
          case "file-too-large":
            result.push(param.file.name + " はファイルサイズが大きすぎます。");
            break;
          case "file-too-small":
            result.push(param.file.name + " はファイルサイズが小さすぎます。");
            break;
          case "too-many-files":
            result.push("ファイル数が多すぎます。");
            break;
          default:
            result.push(
              param.file.name + " に対して不明なエラーが発生しました。"
            );
            break;
        }
      }
    }
    setMessage(result);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept,
    maxSize: maxSize,
    minSize: minSize,
    multiple: multiple,
    disabled: disabled,
    maxFiles: maxFiles,
    onDrop: handleDrop,
    onDropRejected: handleRejection,
  });

  return render({
    ...props,
    label: label,
    error: error,
    helperText: error,
    getRootProps: getRootProps,
    getInputProps: getInputProps,
    dropzoneText: dropzoneText,
    message: message,
  });
};
