import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Typography, Button } from "@mui/material";

/**
 * エラーを表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <div className={props.classes.outer}>
    <div className={props.classes.root}>
      <div className="icon_area">
        <ErrorOutlineIcon sx={{ fontSize: 40 }} />
      </div>
      <div className="title_area">
        <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
          {props.title}
        </Typography>
      </div>
      <div className="message_area">
        <Typography sx={{ fontSize: 13 }}>{props.message}</Typography>
      </div>
      <div className="action_area">
        <div className="details_area"></div>
        <div className="retry_area">
          <Button variant="contained" onClick={props.resetErrorBoundary}>
            {"再読み込み"}
          </Button>
        </div>
      </div>
    </div>
  </div>
);
