import { Box, Typography, LinearProgress } from "@mui/material";
import { Stack } from "@mui/material";

/**
 * この関数は、合計に対する完了したタスクの割合と数を示す進行状況バーを返します タスク。
 * @param props - props は、Presenter コンポーネントに渡されるプロパティを含むオブジェクトです。
 * @returns 進行状況バーと、合計に対する完了した項目のパーセンテージと数を表示する React コンポーネント。
 */

export const Presententer = (props) => {
  const { total = 0, done = 0 } = props;
  const percent = Math.round((done / total) * 100) || 0;

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
      style={{
        marginTop: "-4px",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" value={percent} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">
            {`${percent}%`}
          </Typography>
        </Box>
      </Box>
      <Typography variant="body2" color="text.secondary">
        {`${done}／${total}`}
      </Typography>
    </Stack>
  );
};
