import { createSlice } from "@reduxjs/toolkit";
const defaultValue = {
  collects: [],
};
const CollectsMobile = createSlice({
  name: "collectsMobile",
  initialState: defaultValue,
  reducers: {
    onGetCollects: (state, action) => {
      state.collects = action.payload;
    },
  },
});

export const { onGetCollects } = CollectsMobile.actions;
export default CollectsMobile.reducer;
