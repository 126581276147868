import {
  AppBar,
  Button,
  CircularProgress,
  Container,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { Close, Save as SaveIcon } from "@mui/icons-material";
import React, { forwardRef } from "react";

export const TRANSITION = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Presententer = (props) => (
  <>
    <Dialog fullScreen open={props?.open} TransitionComponent={TRANSITION}>
      <AppBar
        position="fixed"
        color="primary"
        className={props.classes.appBar}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            disabled={props?.closeDisabled}
            onClick={props?.onClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography variant="h6" className={props?.classes.title}>
            {props?.title}
          </Typography>
          {props?.action}
          {props.visibilityConfirm && (
            <Button
              form={props.formId}
              variant={props?.disabled ? "contained" : "outlined"}
              autoFocus
              color="inherit"
              type={props.formId ? "submit" : "button"}
              onClick={props.onSubmit}
              disabled={props?.disabled || props?.isSubmit}
              startIcon={
                props?.isSubmit ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  <SaveIcon />
                )
              }
            >
              {props?.textConfirm}
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <div className={props.classes.appBarSpacer} />
      <Container
        maxWidth={props.container ? "lg" : ""}
        className={props.classes.main_main_container}
      >
        {props.children}
      </Container>
    </Dialog>
  </>
);
