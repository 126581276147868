import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 車輛管理定期点検を表示するコンポーネントです。
 */
export const Inspection = containerPresententer(
  Container,
  Presententer
);
