import { FormProvider } from "react-hook-form";
import { BusinessPerson } from "./ChildComponents";

/**
 * 取引先の登録・編集を行うのフォームを表示するプレゼンテーションコンポーネントです。
 * @param {object} props ポロパティ
 * @param {object} control　reactのcontrol
 * @param {array} truckType 車種
 * @param {func} onOpenModal 事業場の種類
 * @returns {JSX.Elemnt} JSX 要素
 */
export const Presententer = (props) => {
  return (
    <form onSubmit={props.submit} id={props.id} noValidate>
      <FormProvider {...props.methods}>
        <BusinessPerson />
      </FormProvider>
    </form>
  );
};
