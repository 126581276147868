import {
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput
} from "@mui/material";

/**
 * テキストフィールドを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {boolean} props.required 必須であるか
 * @param {string} props.label ラベル
 * @param {boolean} props.error エラー
 * @param {string} props.helperText 補助的テキスト
 * @param {boolean} props.fullWidth 最大幅にするか
 * @param {object} props.other その他プロパティ
 * @returns {JSX.Element}
 */
export const TextField = ({
  required = false,
  label = "",
  error = false,
  helperText = "",
  fullWidth = false,
  ...other
}) => {
  const { InputProps, ...props } = other;

  return (
    <FormControl fullWidth={fullWidth} error={error}>
      <FormLabel
        sx={{
          fontSize: "14px",
          pb: 1,
          ...(required === true
            ? {
                "&:after": {
                  color: "#fff",
                  content: `"必須"`,
                  backgroundColor: "#f44336",
                  fontSize: "12px",
                  marginLeft: "8px",
                  padding: "3px 6px",
                  borderRadius: "4px",
                },
              }
            : null),
        }}
      >
        {label}
      </FormLabel>
      <OutlinedInput {...InputProps} {...props} />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};
