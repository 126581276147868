import { useEffect, useState } from "react";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { searchWasteManagementMethodsByOffset } from "api/graphql/queries";
import { debugLog } from "utils/log";

/**
 * 処理フローを表示するコンテナコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  const [options, setOptions] = useState(null);

  useEffect(() => {
    API.graphql(
      graphqlOperation(searchWasteManagementMethodsByOffset, {
        managedWasteId: value?.waste.id,
        filter: {
          and: [{ state: { eq: "active" } }],
        },
      })
    )
      .then((res) => {
        setOptions(res.data.searchWasteManagementMethodsByOffset.items);
      })
      .catch((err) => {
        debugLog("フロー管理方法取得失敗: ", err);
      })
      .finally(() => {});
  }, [value]);

  return render({
    value: value,
    options: options,
    ...props,
  });
};
