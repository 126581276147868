import CommuteIcon from "@mui/icons-material/Commute";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import { Grid } from "@mui/material";
import { RenderMenuSelectItem } from "views/atoms";

/**
 * 車輛管理画面を表示するプレゼンテーションコンポーネントです
 */
export const Presententer = (props) => {
  return (
    <Grid container spacing={2} sx={{ marginTop: 0 }}>
      <Grid item>
        <RenderMenuSelectItem
          icon={CommuteIcon}
          label="車輛一覧"
          className="list"
          onClick={() => props.onClick("list")}
        />
      </Grid>
      <Grid item>
        <RenderMenuSelectItem
          icon={PlaylistAddCheckOutlinedIcon}
          label="定期点検記録簿"
          className="periodic-inspections"
          onClick={() => props.onClick("periodic-inspections")}
        />
      </Grid>
      <Grid item>
        <RenderMenuSelectItem
          icon={DescriptionOutlinedIcon}
          label="整備記録簿"
          className="maintenances"
          onClick={() => props.onClick("maintenances")}
        />
      </Grid>
      <Grid item>
        <RenderMenuSelectItem
          icon={TaskOutlinedIcon}
          label="車検記録簿"
          className="safeties"
          onClick={() => props.onClick("safeties")}
        />
      </Grid>
    </Grid>
  );
};
