import { useParams } from "react-router-dom";
import { useMutation } from "utils/useMutation";
import {
  createVehiclePeriodicInspection,
  createVehicleMaintenanceRecord,
  createVehicleSafetyInspectionRecord,
  updateVehicleInstrument,
  updateVehicleCertificate,
  createVehicleRemind,
  updateVehicleRemind,
} from "api/graphql/mutations";
import {
  getManagementVehicle,
  searchVehicleRemindsByOffset,
} from "api/graphql/queries";
import { toApiValue } from "utils/ImageFunctions";
import { formatJSDateToISODate } from "utils/format";
import { useOpener } from "utils/useOpener";
import { useRef, useState } from "react";
import { useQuery } from "utils/useQuery";

/**
 * 車輛管理詳細を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const { id } = useParams();
  const periodicInspectionsRef = useRef(null);
  const maintenanceRef = useRef(null);
  const safetiesRef = useRef(null);
  const vehiclePeriodicInspectionForm = useOpener();
  const vehicleSafetyInspectionForm = useOpener();
  const vehicleInspectionDateForm = useOpener();
  const odoMeterForm = useOpener();
  const [customErrors, setCustomErrors] = useState(null);
  const remindForm = useOpener({
    onChange: (result) => {
      if (result) {
        setCustomErrors(null);
      }
    },
  });

  const managementVehicle = useQuery({
    query: getManagementVehicle,
    variables: {
      id: id,
    },
  });

  const reminds = useQuery({
    query: searchVehicleRemindsByOffset,
    variables: {
      vehicleId: id,
    },
  });

  const [
    createVehiclePeriodicInspectionMutation,
    createVehiclePeriodicInspectionMutationStatus,
  ] = useMutation(createVehiclePeriodicInspection, {
    onCompleted: (params) => {
      periodicInspectionsRef?.current?.refresh();
      vehiclePeriodicInspectionForm.toggle(false);
      managementVehicle.refetch({
        id: id,
      });
    },
    succeedMessage: "登録しました。",
    errorMessage: "エラーが発生したため、登録できませんでした。",
  });

  const [
    createVehicleMaintenanceRecordMutation,
    createVehicleMaintenanceRecordMutationStatus,
  ] = useMutation(createVehicleMaintenanceRecord, {
    onCompleted: (params) => {
      maintenanceRef?.current?.refresh();
      vehiclePeriodicInspectionForm.toggle(false);
    },
    succeedMessage: "登録しました。",
    errorMessage: "エラーが発生したため、登録できませんでした。",
  });

  const [
    createVehicleSafetyInspectionRecordMutation,
    createVehicleSafetyInspectionRecordMutationStatus,
  ] = useMutation(createVehicleSafetyInspectionRecord, {
    onCompleted: (params) => {
      safetiesRef?.current?.refresh();
      vehicleSafetyInspectionForm.toggle(false);
      managementVehicle.refetch({
        id: id,
      });
    },
    succeedMessage: "登録しました。",
    errorMessage: "エラーが発生したため、登録できませんでした。",
  });

  const [
    updateVehicleInstrumentMutation,
    updateVehicleInstrumentMutationStatus,
  ] = useMutation(updateVehicleInstrument, {
    onCompleted: (params) => {
      managementVehicle.refetch({
        id: id,
      });
      odoMeterForm.toggle(false);
    },
    succeedMessage: "更新しました。",
    errorMessage: "エラーが発生したため、更新できませんでした。",
  });

  const [
    updateVehicleCertificateMutation,
    updateVehicleCertificateMutationStatus,
  ] = useMutation(updateVehicleCertificate, {
    onCompleted: (params) => {
      managementVehicle.refetch({
        id: id,
      });
      vehicleInspectionDateForm.toggle(false);
    },
    succeedMessage: "更新しました。",
    errorMessage: "エラーが発生したため、更新できませんでした。",
  });

  const handleErrors = ({
    errors = [],
    monitoredTypes = true,
    onCatched = console.log,
  }) => {
    for (const error of errors) {
      if (
        (typeof monitoredTypes === "boolean" && monitoredTypes === true) ||
        (typeof monitoredTypes === "string" &&
          error.errorType === monitoredTypes) ||
        (Array.isArray(monitoredTypes) &&
          monitoredTypes.some((type) => type === error.errorType))
      ) {
        onCatched({ info: error.errorInfo });
        break;
      }
    }
  };

  const [createVehicleRemindMutation, createVehicleRemindStatus] = useMutation(
    createVehicleRemind,
    {
      onBeforeExecute: () => {
        setCustomErrors(null);
      },
      onCompleted: (params) => {
        reminds.refetch({
          vehicleId: id,
        });
        remindForm.toggle(false);
      },
      onError: ({ errors }) => {
        handleErrors({
          errors: errors,
          monitoredTypes: "InvalidEmailException",
          onCatched: ({ info }) => {
            setCustomErrors({
              emails: {
                message: `入力されたメールアドレスは無効です：${info[0].email}`,
              },
            });
          },
        });
      },
      succeedMessage: "登録しました。",
      errorMessage: "エラーが発生したため、登録できませんでした。",
    }
  );

  const [updateVehicleRemindMutation, updateVehicleRemindStatus] = useMutation(
    updateVehicleRemind,
    {
      onBeforeExecute: () => {
        setCustomErrors(null);
      },
      onCompleted: (params) => {
        reminds.refetch({
          vehicleId: id,
        });
        remindForm.toggle(false);
      },
      onError: ({ errors }) => {
        handleErrors({
          errors: errors,
          monitoredTypes: "InvalidEmailException",
          onCatched: ({ info }) => {
            setCustomErrors({
              emails: {
                message: `入力されたメールアドレスは無効です：${info[0].email}`,
              },
            });
          },
        });
      },
      succeedMessage: "更新しました。",
      errorMessage: "エラーが発生したため、更新できませんでした。",
    }
  );

  const handleCreateVehicleRemind = (data) => {
    const {
      name,
      message,
      criteria,
      enableDate,
      date,
      enableODOmeter,
      odometer,
      enableCertificateDays,
      certificateDays,
      notificationTimes,
      repeatDays,
      emails,
    } = data ?? {};

    const criteriaObj = [];

    if (enableDate) {
      criteriaObj.push({
        date: {
          gte: formatJSDateToISODate(date),
        },
      });
    }

    if (enableODOmeter) {
      criteriaObj.push({
        odometer: {
          gte: odometer,
        },
      });
    }

    if (enableCertificateDays) {
      criteriaObj.push({
        certificateDays: {
          lte: certificateDays?.code,
        },
      });
    }

    createVehicleRemindMutation({
      input: {
        name: name,
        message: message,
        criteria: {
          and: criteria === "and" ? criteriaObj : undefined,
          or: criteria === "or" ? criteriaObj : undefined,
        },
        notifications: {
          notificationAt: notificationTimes ? notificationTimes + ":00" : null,
          repeatDays: repeatDays?.code,
          destinationEmails: emails,
        },
        vehicleId: id,
      },
    });
  };

  const handleUpdateVehicleRemind = (data) => {
    const {
      id,
      name,
      message,
      criteria,
      enableDate,
      date,
      enableODOmeter,
      odometer,
      enableCertificateDays,
      certificateDays,
      notificationTimes,
      repeatDays,
      emails,
      version,
    } = data ?? {};

    const criteriaObj = [];

    if (enableDate) {
      criteriaObj.push({
        date: {
          gte: formatJSDateToISODate(date),
        },
      });
    }

    if (enableODOmeter) {
      criteriaObj.push({
        odometer: {
          gte: odometer,
        },
      });
    }

    if (enableCertificateDays) {
      criteriaObj.push({
        certificateDays: {
          lte: certificateDays?.code,
        },
      });
    }

    updateVehicleRemindMutation({
      input: {
        id: id,
        name: name,
        message: message,
        criteria: {
          and: criteria === "and" ? criteriaObj : undefined,
          or: criteria === "or" ? criteriaObj : undefined,
        },
        notifications: {
          notificationAt: notificationTimes ? notificationTimes + ":00" : null,
          repeatDays: repeatDays?.code,
          destinationEmails: emails,
        },
        expectedVersion: version,
      },
    });
  };

  const handleUpdateVehicleInstrument = (data) => {
    const { odometer } = data;

    updateVehicleInstrumentMutation({
      input: {
        vehicleId: id,
        odometer: odometer,
      },
    });
  };

  const handleUpdateVehicleCertificate = (data) => {
    const { certificateExpiredOn } = data;

    updateVehicleCertificateMutation({
      input: {
        vehicleId: id,
        expiredOn: formatJSDateToISODate(certificateExpiredOn),
      },
    });
  };

  const handleCreateVehiclePeriodicInspection = async (value) => {
    const {
      personInChargeName,
      checksheetTemplateId,
      checkedOn,
      remarks,
      files,
      sections,
      expectedChecksheetTemplateVersion,
    } = value;

    createVehiclePeriodicInspectionMutation({
      input: {
        expectedChecksheetTemplateVersion: expectedChecksheetTemplateVersion,
        vehicleId: id,
        checksheetTemplateId: checksheetTemplateId,
        personInChargeName: personInChargeName,
        checkedOn: formatJSDateToISODate(checkedOn),
        remarks: remarks,
        files: await toApiValue({ files: files }),
        sections: sections?.map((section) => ({
          ...section,
          name: undefined,
          items: section?.items?.map((item) => ({
            ...item,
            name: undefined,
          })),
        })),
      },
    });
  };

  const handleCreateVehicleMaintenanceRecord = async (value) => {
    const {
      maintenanceWorkplace,
      number,
      category,
      maintenancedOn,
      price,
      details,
      files,
      remarks,
    } = value;

    createVehicleMaintenanceRecordMutation({
      input: {
        vehicleId: id,
        maintenanceWorkplaceId: maintenanceWorkplace?.id,
        number: number,
        category: category,
        maintenancedOn: formatJSDateToISODate(maintenancedOn),
        price: price,
        details: details?.map((detail, index) => ({
          ...detail,
          id: undefined,
        })),
        files: await toApiValue({ files: files }),
        remarks: remarks,
      },
    });
  };

  const handleCreateVehicleSafetyInspectionRecord = async (data) => {
    const {
      inspectionWorkplace,
      receivedOn,
      completedOn,
      expiredOn,
      ignoreSynchronizeCurrentExpiryDate,
      price,
      result,
      files,
    } = data ?? {};

    createVehicleSafetyInspectionRecordMutation({
      input: {
        vehicleId: id,
        inspectionWorkplaceId: inspectionWorkplace?.id,
        receivedOn: formatJSDateToISODate(receivedOn),
        completedOn: formatJSDateToISODate(completedOn),
        expiredOn: formatJSDateToISODate(expiredOn),
        ignoreSynchronizeCurrentExpiryDate: ignoreSynchronizeCurrentExpiryDate,
        price: price,
        result: result,
        files: await toApiValue({ files: files }),
      },
    });
  };

  const handleDeletedRemind = () => {
    reminds.refetch({
      vehicleId: id,
    });
  };

  return render({
    ...props,
    id: id,
    loading:
      createVehiclePeriodicInspectionMutationStatus.loading ||
      createVehicleMaintenanceRecordMutationStatus.loading ||
      createVehicleSafetyInspectionRecordMutationStatus.loading ||
      managementVehicle.loading ||
      updateVehicleInstrumentMutationStatus.loading ||
      updateVehicleCertificateMutationStatus.loading ||
      reminds.loading ||
      createVehicleRemindStatus.loading ||
      updateVehicleRemindStatus.loading,
    onCreateVehiclePeriodicInspection: handleCreateVehiclePeriodicInspection,
    vehiclePeriodicInspectionForm: vehiclePeriodicInspectionForm,
    periodicInspectionsRef: periodicInspectionsRef,
    maintenanceRef: maintenanceRef,
    safetiesRef: safetiesRef,
    onCreateVehicleMaintenanceRecord: handleCreateVehicleMaintenanceRecord,
    onCreateVehicleSafetyInspectionRecord:
      handleCreateVehicleSafetyInspectionRecord,
    vehicleSafetyInspectionForm: vehicleSafetyInspectionForm,
    value: managementVehicle?.data?.getManagementVehicle,
    vehicleInspectionDateForm: vehicleInspectionDateForm,
    odoMeterForm: odoMeterForm,
    remindForm: remindForm,
    onUpdateVehicleInstrument: handleUpdateVehicleInstrument,
    onUpdateVehicleCertificate: handleUpdateVehicleCertificate,
    reminds: reminds.data?.searchVehicleRemindsByOffset?.items,
    onCreateVehicleRemind: handleCreateVehicleRemind,
    onUpdateVehicleRemind: handleUpdateVehicleRemind,
    onDeletedRemind: handleDeletedRemind,
    customErrors: customErrors,
    setCustomErrors: setCustomErrors,
  });
};
