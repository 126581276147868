import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Link, Typography, Button, Stack } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { add as addAlert } from "ducks/Alert";

/**
 * Fileオブジェクトをbase64に変換します。
 * @param {object} file ファイルオブジェクト
 */
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * CSVファイルをアップロードするコンポーネントです。
 * @param {object} props
 * @param {string[]} props.acceptedFileTypes 受け入れるファイルタイプの配列
 * @param {function} props.onTemplateDownload テンプレートをダウンロードする関数
 * @param {string} props.templateLinkTitle テンプレートのリンクのタイトル
 * @param {object} props.sx
 * @param {function} props.onFileDrop ファイルをドロップしたときに呼ばれる関数
 * @param {node} props.conversionInstructions ファイルを変換する前に表示する文字列
 * @param {boolean} props.isUploading アップロード中かどうか
 * @param {function} props.setIsUploading アップロード中かどうかを設定する関数
 */

export const ExcelUpload = ({
  acceptedFileTypes = [".xlsx"],
  onTemplateDownload,
  templateLinkTitle,
  sx,
  onFileDrop,
  isUploading,
  setIsUploading,
  ...props
}) => {
  const [progress, setPrgress] = useState(0);
  const [buffer, setBuffer] = useState(10);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const progressRef = useRef(() => {});

  useEffect(() => {
    if (file && isUploading) {
      progressRef.current = () => {
        if (progress > 100) {
          setPrgress(0);
          setBuffer(10);
        } else {
          const diff = Math.random() * 10;
          const diff2 = Math.random() * 10;
          setPrgress(progress + diff);
          setBuffer(progress + diff + diff2);
        }
      };
    }
  }, [file, progress, isUploading]);

  useEffect(() => {
    if (isUploading) {
      const timer = setInterval(() => {
        progressRef.current();
      }, 500);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isUploading]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        setIsUploading(true);
        toBase64(file).then((result) => {
          const fileObj = {
            name: file.name,
            context: result,
          };
          setFile(fileObj);
          onFileDrop(fileObj);
        });
      });
    },
    [onFileDrop, setIsUploading]
  );

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: acceptedFileTypes.join(","),
    onDrop: onDrop,
  });

  if (fileRejections.length) {
    dispatch(
      addAlert({
        value: "CSV ファイルのみが受け入れられます。",
        severity: "error",
      })
    );
  }

  return (
    <>
      {isUploading && (
        <Box sx={{ width: "40%", mb: 5, textAlign: "center" }}>
          <Typography>{file?.name}</Typography>
          <LinearProgress
            color="success"
            variant="buffer"
            value={progress}
            valueBuffer={buffer}
          />
          <Typography>
            ファイルをアップロードして解析しています。お待ちください。
          </Typography>
        </Box>
      )}
      <Stack
        textAlign="center"
        flex="1"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="flex-end"
      >
        <Box
          height="300px"
          width="500px"
          border={3}
          borderColor="#420CBB"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gridGap={3}
          mb={2}
          sx={{
            borderStyle: "dashed",
            ...sx,
          }}
          {...getRootProps({ className: "dropzone" })}
        >
          <input {...getInputProps()} />
          <Typography fontWeight={500} mb={2}>
            ここにファイルをドロップしてください。
          </Typography>
          <Typography mb={2}>または</Typography>
          <Button variant="outlined" color="primary">
            ファイル選択
          </Button>
          <Typography mt={2} fontSize={13} fontWeight={600}>
            (*.xlsx ファイルのみ)
          </Typography>
        </Box>
        <Link
          style={{
            cursor: "pointer",
          }}
          underline="always"
          textAlign="center"
          onClick={onTemplateDownload}
          width="500px"
        >
          {templateLinkTitle}
        </Link>
      </Stack>
    </>
  );
};

ExcelUpload.propTypes = {
  acceptedFileTypes: PropTypes.array.isRequired,
  onTemplateDownload: PropTypes.func.isRequired,
  templateLinkTitle: PropTypes.string.isRequired,
  sx: PropTypes.object,
  onFileDrop: PropTypes.func,
  isUploading: PropTypes.bool,
  setIsUploading: PropTypes.func,
};

