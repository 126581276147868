import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Marker } from "@react-google-maps/api";

/**
 * アイコンや場所情報の表示を行うコンテナプレゼンテーションです
 * @param {object} marker It contains marker details 
 * @param {number} index It gives this marker index
 * @param {number} noOfMarkers It gives all the counts of available marker in current window.
 * @param {func} onClickMarker It trigger when the marker is click.
 * @param {bool} isActive  It decides wheather the marker is current clicked or not
 * @param {object} classes It containes the styles for marker
 * @returns
 */
export const Presententer = ({
  marker,
  index,
  noOfMarkers,
  isActive,
  onClickMarker,
  animate,
  classes,
}) => {
  const theme = useTheme();
  const mainColor = theme.palette.common.black;
  return (
    <Marker
      key={isActive}
      position={{
        lat: marker?.lat,
        lng: marker?.lng,
      }}
      animation={animate ? window.google.maps.Animation.DROP : "none"}
      label={{
        text: `${marker.label}`,
        className: classes.label,
        color: mainColor,
        fontSize: isActive ? "16px" : "14px",
        fontWeight: "500",
      }}
      icon={{
        url: "/marker_region_in_bg.png",
        scaledSize: {
          width: isActive ? 70 : 50,
          height: isActive ? 70 : 50,
        },
      }}
      zIndex={isActive ? noOfMarkers + 1 : noOfMarkers - index}
      onClick={() => onClickMarker(marker)}
    />
  );
};

Presententer.propTypes = {
  marker: PropTypes.object,
  index: PropTypes.number,
  noOfMarkers: PropTypes.number,
  onClickMarker: PropTypes.func,
  isActive: PropTypes.bool,
  animate: PropTypes.bool,
  classes: PropTypes.object,
};
