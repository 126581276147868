import CommuteIcon from "@mui/icons-material/Commute";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import { DateTime } from "luxon";
import { ChecksheetsTemplateSelector, WithSeparator } from "views/molecules";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { Caption } from "views/organisms/DetailsFrame/ChildComponents";
import {
  BellConfig,
  BoxConfig,
  MaintenanceRecordForm,
  Maintenances,
  ODOMeterForm,
  PeriodicInspections,
  RemindForm,
  Safeties,
  VehicleInspectionDateForm,
  VehicleInspectionForm,
  VehicleInspectionRecordForm,
} from "views/organisms/Vehicles/Details";
import { formatDisplayDate, formatDisplayDateFromISO } from "utils/format";

const isEmpty = (value) => {
  if (!value || value?.length === 0) {
    return true;
  }

  return false;
};

/**
 * 車輛詳細を表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <>
      <DetailsFrame
        caption={
          <Box display="flex" flexDirection={"row"} paddingLeft="16px">
            <Box display="flex" flexDirection={"row"}>
              <Avatar sx={{ width: 58, height: 58, marginRight: 3 }}>
                <CommuteIcon sx={{ fontSize: "2.5rem" }} />
              </Avatar>
              <Caption
                title={props.value?.number ?? ""}
                subTitle={props.value?.modelName ?? ""}
              />
            </Box>
          </Box>
        }
        actions={
          <Stack direction={"row"} spacing={2}>
            <BoxConfig
              title="車検満了日"
              onClick={() => props.vehicleInspectionDateForm.toggle(true)}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "16px",
                }}
              >
                {formatDisplayDate({
                  source: props.value?.certificateExpiredOn,
                })}
              </Typography>
            </BoxConfig>
            <BoxConfig
              title="ODOメーター"
              onClick={() => props.odoMeterForm.toggle(true)}
            >
              <Box display="flex" flexDirection={"row"} alignItems="center">
                <Typography
                  sx={{
                    color: "gray",
                    fontSize: "14px",
                  }}
                >
                  {formatDisplayDateFromISO({
                    source: props.value?.instrumentUpdatedAt,
                  })
                    ? `(${formatDisplayDateFromISO({
                        source: props.value?.instrumentUpdatedAt,
                      })})`
                    : ""}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "16px",
                  }}
                >
                  {props.value?.odometer}
                </Typography>
              </Box>
            </BoxConfig>
          </Stack>
        }
      >
        <Stack spacing={1}>
          {props?.reminds?.map((remind, index) => {
            const criteria = isEmpty(remind?.criteria?.[0]?.or)
              ? remind?.criteria?.[0]?.and
              : remind?.criteria?.[0]?.or;

            const date =
              criteria?.[0]?.date || criteria?.[1]?.date || criteria?.[2]?.date;
            const odometer =
              criteria?.[0]?.odometer ||
              criteria?.[1]?.odometer ||
              criteria?.[2]?.odometer;
            const certificateDays =
              criteria?.[0]?.certificateDays ||
              criteria?.[1]?.certificateDays ||
              criteria?.[2]?.certificateDays;

            const chipValues = [
              {
                text: `日付：${
                  date?.gte
                    ? DateTime.fromISO(date?.gte).toFormat("yyyy/MM/dd")
                    : ""
                }`,
                value: date?.gte ?? null,
              },
              {
                text: `ODOメーター：${odometer?.gte ?? ""}`,
                value: odometer?.gte ?? null,
              },
              {
                text: `車検満了日：${
                  certificateDays?.lte ? certificateDays?.lte + "日前" : ""
                }`,
                value: certificateDays?.lte ?? null,
              },
            ].filter((v) => !!v.value);

            return (
              <BellConfig
                key={index}
                title={remind?.name}
                onClick={() => {
                  props.remindForm.changeOptions({
                    title: "リマインドの編集",
                    value: remind,
                  });
                  props.remindForm.toggle(true);
                }}
              >
                <WithSeparator
                  separator={`${
                    isEmpty(remind?.criteria?.[0]?.or) ? "and" : "or"
                  }`}
                >
                  {chipValues.map((v, i) => (
                    <Chip key={`chip${index}${i}`} label={v.text} />
                  ))}
                </WithSeparator>
              </BellConfig>
            );
          })}
          <Box display="flex" flexDirection={"row"} justifyContent="right">
            <Button
              variant="contained"
              sx={{
                margin: "1px",
              }}
              onClick={() => props.checkSheetSelector.toggle(true)}
            >
              {"定期点検を行う"}
            </Button>
            <Button
              variant="contained"
              sx={{
                margin: "1px",
              }}
              onClick={() => props.maintenanceRecordForm.toggle(true)}
            >
              {"整備記録を登録する"}
            </Button>
            <Button
              variant="contained"
              sx={{
                margin: "1px",
              }}
              onClick={() => props.vehicleSafetyInspectionForm.toggle(true)}
            >
              {"車検記録を登録する"}
            </Button>
            <Button
              variant="contained"
              sx={{
                margin: "1px",
              }}
              onClick={() => {
                props.remindForm.changeOptions({
                  title: "新しいリマインドの登録",
                  value: null,
                });
                props.remindForm.toggle(true);
              }}
            >
              {"リマインドを追加する"}
            </Button>
          </Box>
          <TabContext value={props.tab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={props.onChangeTab}
                aria-label="lab API tabs example"
              >
                <Tab label="定期点検" value="inspection" />
                <Tab label="整備記録" value="maintenance" />
                <Tab label="車検履歴" value="safety" />
              </TabList>
            </Box>
            <Paper>
              <TabPanel value="inspection">
                <PeriodicInspections
                  vehicleId={props.id}
                  _ref={props.periodicInspectionsRef}
                />
              </TabPanel>
              <TabPanel value="maintenance">
                <Maintenances
                  vehicleId={props.id}
                  _ref={props.maintenanceRef}
                />
              </TabPanel>
              <TabPanel value="safety">
                <Safeties vehicleId={props.id} _ref={props.safetiesRef} />
              </TabPanel>
            </Paper>
          </TabContext>
        </Stack>
        <ODOMeterForm
          value={props.value}
          open={props.odoMeterForm.open}
          onClose={() => props.odoMeterForm.toggle(false)}
          disabled={props.loading}
          onSubmit={props.onUpdateVehicleInstrument}
        />
        <VehicleInspectionDateForm
          value={props.value}
          open={props.vehicleInspectionDateForm.open}
          onClose={() => props.vehicleInspectionDateForm.toggle(false)}
          disabled={props.loading}
          onSubmit={props.onUpdateVehicleCertificate}
        />
        <RemindForm
          title={props?.remindForm?.options?.title}
          value={props?.remindForm?.options?.value}
          open={props.remindForm.open}
          onSubmit={(data) => {
            if (!!props?.remindForm?.options?.value) {
              props?.onUpdateVehicleRemind(data);
            } else {
              props?.onCreateVehicleRemind(data);
            }
          }}
          onClose={() => props.remindForm.toggle(false)}
          onDeleted={() => props?.onDeletedRemind?.()}
          disabled={props.loading}
          customErrors={props.customErrors}
        />
        <VehicleInspectionForm
          open={props.vehiclePeriodicInspectionForm.open}
          onClose={() => props.vehiclePeriodicInspectionForm.toggle(false)}
          checksheetTemplate={props.checkSheetSelector?.options}
          onSubmit={props.onCreateVehiclePeriodicInspection}
          disabled={props.loading}
        />
        <ChecksheetsTemplateSelector
          open={props.checkSheetSelector.open}
          onClick={(checksheet) => {
            props.checkSheetSelector.changeOptions(checksheet);
            props.checkSheetSelector.toggle(false);
            props.vehiclePeriodicInspectionForm.toggle(true);
          }}
          onClose={() => props.checkSheetSelector.toggle(false)}
        />
        <MaintenanceRecordForm
          open={props.maintenanceRecordForm.open}
          onClose={() => props.maintenanceRecordForm.toggle(false)}
          onSubmit={props.onCreateVehicleMaintenanceRecord}
          disabled={props.loading}
        />
        <VehicleInspectionRecordForm
          open={props.vehicleSafetyInspectionForm.open}
          onClose={() => props.vehicleSafetyInspectionForm.toggle(false)}
          disabled={props.loading}
          onSubmit={props.onCreateVehicleSafetyInspectionRecord}
          currentExpiryDate={props.value?.certificateExpiredOn}
        />
      </DetailsFrame>
    </>
  );
};
