import { debugLog } from "utils/log";
// process.env.REACT_APP_GOOGLE_API_LICENSE_KEY

/**
 * 到着予定時刻(秒)を取得します。
 * @param {object} current 現在地(lat, lng)
 * @param {object} next 次の地点(lat, lng)
 * @returns {number}
 */
export const getEstimatedArrivalTime = (
  current,
  next,
  onSucceeded = (data) => debugLog("所要時間: ", data),
  onError = (err) => debugLog("所要時間の取得に失敗: ", err)
) => {
  if (!current || !next) {
    return;
  }
  let directionsService = new window.google.maps.DirectionsService();

  return directionsService.route(
    {
      origin: `${current.lat},${current.lng}`,
      destination: `${next.lat},${next.lng}`,
      travelMode: "DRIVING",
    },
    function (response, status) {
      if (status === "OK") {
        onSucceeded(response.routes[0].legs[0].duration.value);
      } else {
        onError(`Directions request failed due to ${status}`);
      }
    }
  );
};

/**
 * 住所から座標を取得します。
 * @param {string} address 住所
 * @returns {Promise<google.maps.GeocoderResponse>} 結果オブジェクト
 */
export const getLatLng = (address) => {
  let geoCoder = new window.google.maps.Geocoder();
  return geoCoder.geocode(
    {
      address: address,
      region: "jp",
    },
    function (results, status) {
      debugLog("GoogleMapsAPIから住所取得: ", address, results, status);

      switch (status) {
        case window.google.maps.GeocoderStatus.OK:
          break;
        case window.google.maps.GeocoderStatus.ZERO_RESULTS:
          alert("住所が見つかりませんでした。");
          break;
        case window.google.maps.GeocoderStatus.ERROR:
          alert("Googleサーバー接続に失敗しました。");
          break;
        case window.google.maps.GeocoderStatus.INVALID_REQUEST: // クエリが不足している可能性がある
        case window.google.maps.GeocoderStatus.OVER_QUERY_LIMIT: // リクエストの制限回数を超えた
        case window.google.maps.GeocoderStatus.REQUEST_DENIED: // 何らかの理由でリクエストが拒否されている
        case window.google.maps.GeocoderStatus.UNKNOWN_ERROR: // リクエストが処理できなかった。もう一度試すと正常に処理される可能性がある
          alert("原因不明のエラーが発生しました。");
          break;
        default:
          alert("原因不明のエラーが発生しました。");
          break;
      }
    }
  );
};

/**
 * workplaceオブジェクトを持つオブジェクトから住所文字列を取得します。
 * @param {object} obj workplaceオブジェクトを持つオブジェクト
 * @returns {string} 住所
 */
export const getAddress = (obj) => {
  const { workplace } = obj;
  return `${workplace?.prefectures?.name}${workplace?.city}${workplace?.streetAddress}${workplace?.otherAddress}`;
};

/**
 * レスポンスから経度緯度を取得します。
 * @param {object} response レスポンス
 * @returns {object} 経度緯度({ lat, lng })
 */
export const fetchLatLng = (response) => {
  return {
    lat: response?.results?.[0]?.geometry?.location?.lat(),
    lng: response?.results?.[0]?.geometry?.location?.lng(),
  };
};

/**
 * 経度緯度を付与します。
 * @param {array} objs workplaceオブジェクトを持つオブジェクト配列
 * @returns {array} 経度緯度を付与したオブジェクト配列
 */
export const attachLatLng = async (objs) => {
  let results = [];
  for (const [index, promise] of objs
    .map((point) => getLatLng(getAddress(point)))
    .entries()) {
    let result;
    try {
      result = await promise;
      // await sleep(1000);
    } catch (err) {
      debugLog("住所の取得に失敗:", err, "取得に失敗した住所: ", objs[index]);
    } finally {
      results.push({
        ...objs[index],
        ...fetchLatLng(result),
      });
    }
  }
  return results;
};

/**
 * タスクがすべて完了したか確認します。
 * @param {array} tasks タスクオブジェクト配列
 * @returns {boolean}
 */
export const isAllTaskCompleted = (tasks) => {
  // タスクが完了してないものが含まれていない
  return tasks?.filter((task) => !isTaskCompleted(task))?.length === 0;
};

/**
 * 未入力のタスクが含まれているか確認します。
 * @param {array} tasks タスクオブジェクト配列
 * @returns {boolean}
 */
export const includesTasksNotEnterd = (tasks) => {
  return tasks?.filter((task) => !isTaskCompleted(task)).length > 0;
};

/**
 * タスクが完了したかを確認します。
 * @param {object} obj タスクオブジェクト
 * @returns {boolean}
 */
const isTaskCompleted = (obj) => {
  return obj.record;
};

/**
 * 場所を通過したか確認します。
 * @param {object} obj CarryWorkLocationオブジェクト
 * @returns {boolean}
 */
export const isPassed = (obj) => {
  if (
    !obj ||
    !obj.passedRecord ||
    !obj.passedRecord?.arrivedAt ||
    !obj.passedRecord?.departedAt
  ) {
    return false;
  }

  return true;
};
