import { NotSet } from "./NotSet";
import { Progresses } from "./Progresses";
import { isConfirming, isInvalid, isValid } from "./Progresses/utils";

export const Jwnet = ({ value, onNotificationChange = () => {} }) => {
  if (!value) {
    return (
      <NotSet onNotificationChange={onNotificationChange} disabled={false} />
    );
  }

  if (isInvalid(value) || isConfirming(value) || isValid(value)) {
    return (
      <Progresses value={value} onNotificationChange={onNotificationChange} />
    );
  }

  return <></>;
};
