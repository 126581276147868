import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 車輛選択するコンポーネントです。
 * @fires VehiclesSelector#onSelected
 * @return {JSX.Element}
 */
export const VehicleSelector = containerPresententer(Container, Presententer);
