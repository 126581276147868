import React from "react";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Card,
  DialogTitle,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { CarryWorkJournalListItem } from "views/organisms/Allocation";
import CsvExporter from "views/organisms/CsvExporter";

/**
 *  回収記録表一覧を表示するプレゼンテーションコンポーネントです。
 * @param {object} props
 * @returns JSX 要素
 */
export const Presententer = (props) => (
  <Paper
    sx={{
      p: 2,
    }}
  >
    <Box sx={{ mb: 1, display: "flex", justifyContent: "space-between" }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton color="default" onClick={props?.onBeforeDate}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <LocalizationProvider
          dateAdapter={AdapterLuxon}
          adapterLocale={"jp-JP"}
        >
          <MobileDatePicker
            inputFormat="yyyy/MM/dd"
            showToolbar={false}
            value={props?.mobileDate}
            // onChange={props?.onChangeDate}
            onChange={props?.onChangeDate}
            onAccept={props?.onAccept}
            renderInput={({ inputProps }) => {
              return (
                <Button
                  variant="text"
                  color="inherit"
                  onClick={inputProps.onClick}
                >
                  <Typography variant="h6">
                    {props?.date
                      ?.setLocale("jp-JP")
                      ?.toFormat("yyyy/MM/dd (EEE)")}
                  </Typography>
                </Button>
              );
            }}
          />
        </LocalizationProvider>
        <IconButton color="default" onClick={props?.onNextDate}>
          <KeyboardArrowRightIcon />
        </IconButton>
        <Button color="primary" variant="outlined" onClick={props?.onSetToday}>
          今日
        </Button>
      </Stack>
      <CsvExporter
        fetchData={props.fetchData}
        filename={`回収記録-${props?.date
          ?.setLocale("jp-JP")
          ?.toFormat("yyyy/MM/dd")}`}
        columns={props.gridColumns}
      >
        <LoadingButton
          variant="outlined"
          sx={{
            height: 35,
          }}
          loading={props.isLoading}
          endIcon={<FileDownloadOutlinedIcon color="inherit" />}
        >
          エクスポート
        </LoadingButton>
      </CsvExporter>
    </Box>
    <Box>
      <Stack spacing={2}>
        {props?.journals === null ? (
          <>
            <Skeleton variant="rectangular" height={100} />
            <Skeleton variant="rectangular" height={100} />
            <Skeleton variant="rectangular" height={100} />
            <Skeleton variant="rectangular" height={100} />
            <Skeleton variant="rectangular" height={100} />
          </>
        ) : (
          props.journals.map((journal) => {
            return (
              <CarryWorkJournalListItem
                key={journal.id}
                name={journal.name}
                assignedUsers={journal.assignedUsers}
                assignedVehicle={journal.assignedVehicle}
                total={journal.taskTotal}
                done={journal.taskDoneCount}
                notes={journal.notes}
                onNoteClick={() => props.onNoteClick(journal.id)}
                onClick={() => props?.onNavigateDetails(journal.id)}
                showProgressBar
              />
            );
          })
        )}
      </Stack>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={props.noteId}
        onClose={props.onClose}
        scroll="paper"
      >
        <DialogTitle id="scroll-dialog-title">回収記録備考</DialogTitle>
        <DialogContent
          sx={{
            background: "#f6f7ff",
          }}
          dividers
        >
          {props.journals
            ?.find((journal) => journal.id === props.noteId)
            ?.notes?.map((note) => {
              return (
                <Card
                  key={note.index}
                  sx={{
                    mb: 1,
                    p: 1,
                    borderRadius: "5px",
                    boxShadow: 1,
                    minWidth: 10,
                    background: "#EAEAEA",
                  }}
                  variant="outlined"
                >
                  <b>
                    回収番号 {note.index + 1}:{" "}
                    {note.workplace?.belongInCompanyName?.trim()}{" "}
                    {note.workplace?.name?.trim()}
                  </b>
                  <Typography component="div" whiteSpace="break-spaces">
                    {note.remark}
                  </Typography>
                </Card>
              );
            })}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={props.onClose}>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  </Paper>
);
