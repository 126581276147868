import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { fetchPrefectures } from "./api";

/*
object shape
  []
    code
    name
*/

const prefecturesSlice = createSlice({
  name: "prefectures",
  initialState: {
    prefectures: null,
    isLoading: false,
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrefectures.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchPrefectures.fulfilled, (state, action) => {
        state.prefectures = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchPrefectures.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

/**
 * ストアから都道府県全体を取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.Prefectures;

/**
 * ストアから都道府県を取得します。
 */
export const prefecturesSelector = createSelector(
  selector,
  (prefectures) => prefectures.prefectures
);

/**
 * ストアから読み込みを取得します。
 */
export const isLoadingSelector = createSelector(
  selector,
  (prefectures) => prefectures.isLoading
);

/**
 * ストアからエラーを取得します。
 */
export const errorSelector = createSelector(
  selector,
  (prefectures) => prefectures.error
);

export default prefecturesSlice.reducer;
