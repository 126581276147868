import { styles } from "./styles";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { basicSchema } from "./Validations/basic";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { companyCategoriesSelector } from "ducks/CompanyCategories";
import { useSelector } from "react-redux";

const defaultValues = {
  categories: undefined,
  name: null,
  corporateNumber: null,
  representativeName: null,
  establishmentedOn: undefined,
  url: null,
  remarks: null,
  headOfficeWorkplace: {
    industrySector: undefined,
    name: null,
    prefectures: undefined,
    city: null,
    streetAddress: null,
    otherAddress: null,
    phone: null,
    fax: null,
    email: null,
  },
};

/**
 * 取引先の登録・編集を行うのフォームを表示するコンテナコンポーネントです。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({
  render,
  id,
  onSubmit,
  onError = (err) => debugLog(err),
  value,
  ...props
}) => {
  const companyCategories = useSelector(companyCategoriesSelector);
  const classes = useClasses(styles);
  const [open, setOpen] = useState(false);
  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    methods.reset({
      ...defaultValues,
      ...value,
    });
  }, [methods, value]);

  return render({
    ComanyCategories: companyCategories,
    id: id,
    classes: classes,
    methods: methods,
    submit: methods.handleSubmit(onSubmit, onError),
    open: open,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    companyId: value?.companyId,
    ...props,
  });
};
