import React from "react";
import { RegularlyDetails } from "views/templates/Order";

/**
 * 定期回収の詳細を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <RegularlyDetails
    {...props}
  />
);
