import React from "react";

const createSeparator = ({ separator, index }) => {
  if (typeof separator === "string") {
    return separator;
  }

  return React.cloneElement(separator, { key: `separator-${index}` });
};

/**
 * セパレーターを挿入するコンポーネントです。
 * @param {object} props プロパティ
 * @param {array} children 子要素配列
 * @param {boolean} leading 先頭の区切りを入れるかどうか
 * @param {boolean} trailing 末尾の区切りを入れるかどうか
 * @param {string | JSX.Element} セパレート
 * @returns {JSX.Element}
 */
export const WithSeparator = ({ children, leading, trailing, separator }) => {
  let childrenWithSeparator = React.Children.toArray(children);

  for (let i = 1; i < childrenWithSeparator.length; i += 2) {
    childrenWithSeparator.splice(
      i,
      0,
      createSeparator({ separator: separator, index: (i - 1) / 2 })
    );
  }

  if (leading) {
    childrenWithSeparator.unshift(
      createSeparator({ separator: separator, index: "leading" })
    );
  }

  if (trailing) {
    childrenWithSeparator.unshift(
      createSeparator({ separator: separator, index: "trailing" })
    );
  }

  return childrenWithSeparator;
};
