import { useEffect } from "react";
import { useStyles } from "./styles";

/**
 * 引数を受けてJSX Elemntを返します。
 * @param {array} rows XGridのデータが表示する
 * @param {array} columns XGridのコラム名
 * @param {boolean} onToolbar ツールバーが隠れるようにする
 * @param {boolean} onSearcher フリー検索が隠れるようにする
 * @param {object} actionButton 別のボータンが表示　例：新しい事業所。事業場を登録
 * @param {boolean} multipleSelection 複数行を選択できる
 * @param {func} onClose モーダルを閉じる
 * @param {func} onChangeSelection 選択されたアイテムを更新する
 * @param {array} value デフォルトデータ
 * @param {boolean} onDownload ダウンロードのボータンを有効か無効に設定する
 * @param {boolean} onColumns カラムのボータンを有効か無効に設定する
 * @param {boolean} onFilter フリーターのボータンを有効か無効に設定する
 * @param {object} IconDelete 削除アイコン
 * @param {number} pageSizeDisplay 表示する項目数を表示する
 * @param {boolean} disableSelectionOnClick 行をクリックしないようにする
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({
  render,
  rows,
  columns,
  page,
  autoHeight = true,
  onToolbar = true,
  actionButton,
  multipleSelection = false,
  onClose,
  onPageSizeChange,
  onPageChange,
  checkboxSelection = false,
  onChangeSelection,
  value,
  onSearcher = false,
  onDownload = false,
  onColumns = true,
  onFilter = true,
  titleTable = "",
  textConfirm = "更新",
  pageSizeDisplay = 3,
  disableSelectionOnClick = false,
  rowCount,
  sortModel,
  paginationMode,
  customTools,
  ...props
}) => {
  const classes = useStyles();
  const selectionModel = Array.isArray(value)
    ? value.map((v) => v.id)
    : value
    ? [value.id]
    : [];

  useEffect(() => {
    if (selectionModel?.length) {
      onChangeSelection && onChangeSelection(selectionModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const handleOnChangeSelectionModel = (params) => {
    if (multipleSelection || (params.length === 1 && params[0] !== value?.id)) {
      onChangeSelection && onChangeSelection(params);
    }
  };

  const handleCloseModal = () => {
    onClose && onClose();
  };

  const handleSubmit = () => {
    onChangeSelection && onChangeSelection(selectionModel);
  };
  return render({
    classes: classes,
    columns: columns,
    rows: rows,

    onPageSizeChange: onPageSizeChange,
    onPageChange: onPageChange,
    actionButton: actionButton,
    titleTable: titleTable,
    textConfirm: textConfirm,
    selectionModel: selectionModel,
    multipleSelection: multipleSelection,
    onChangeSelectionModel: handleOnChangeSelectionModel,

    //複数行を選択できる
    onCloseModal: handleCloseModal,
    onSubmit: handleSubmit,

    autoHeight: autoHeight,
    page: page,
    rowCount: rowCount,
    paginationMode: paginationMode,
    sortModel: sortModel,
    checkboxSelection: checkboxSelection,
    onSearcher: onSearcher,
    onDownload: onDownload,
    onColumns: onColumns,
    onFilter: onFilter,
    onToolbar: onToolbar,
    disableSelectionOnClick: disableSelectionOnClick,
    customTools: customTools,
    ...props,
  });
};
