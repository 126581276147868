import { Button, Stack } from "@mui/material";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { MaintenancesDetails as Details } from "views/organisms/Vehicles/Common";
import { VehiclesMaintenanceForm } from "views/organisms/Vehicles/Details/VehiclesMaintenanceForm";
import { ConfirmDialog } from "views/molecules";

/**
 * 車輛詳細点検詳細を表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <>
      <DetailsFrame
        caption={<></>}
        actions={
          <Stack spacing={2} direction={"row"}>
            <Button
              variant="contained"
              color={"error"}
              sx={{
                margin: "1px",
              }}
              onClick={() => props.confirmDialog.toggle(true)}
              disabled={props.loading}
            >
              {"削除する"}
            </Button>
            <Button
              variant="contained"
              sx={{
                margin: "1px",
              }}
              onClick={() => props.form.toggle(true)}
              disabled={props.loading}
            >
              {"編集する"}
            </Button>
          </Stack>
        }
      >
        <Details value={props.value} />
        <VehiclesMaintenanceForm
          open={props.form.open}
          onClose={() => props.form.toggle(false)}
          title={"整備記録の編集"}
          confirmText={"保存"}
          value={props.value}
          onSubmit={props.onUpdate}
        />
        <ConfirmDialog
          title="削除確認"
          message="削除します。よろしいですか？"
          open={props.confirmDialog?.open}
          onNegative={() => props.confirmDialog.toggle(false)}
          onPositive={props.onRemove}
          onClose={() => props.confirmDialog.toggle(false)}
          disabled={props.loading}
          positiveText={"はい"}
          negativeText={"いいえ"}
        />
      </DetailsFrame>
    </>
  );
};
