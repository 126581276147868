import { useEffect, useState } from "react";

export const Container = ({ render, ...props }) => {
  const [value, setValue] = useState();
  useEffect(() => {
    setTimeout(() => {
      const result = "1,245t";
      setValue(result);
    }, 800);
  }, []);

  return render({
    value: value,
    ...props,
  });
};
