import { getTodayMyAssignedWasteCollectionScheduleInfo } from "api/graphql/queries";
import { useQuery } from "utils/useQuery";

/**
 * モバイルのダッシュボードを表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const todayMyAssignedWasteCollectionScheduleInfo = useQuery({
    query: getTodayMyAssignedWasteCollectionScheduleInfo,
  });

  return render({
    ...props,
    loading: todayMyAssignedWasteCollectionScheduleInfo.loading,
    value:
      todayMyAssignedWasteCollectionScheduleInfo?.data
        ?.getTodayMyAssignedWasteCollectionScheduleInfo,
  });
};
