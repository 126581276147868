import React from "react";
import { WorkplaceSelector } from "views/molecules";

/**
 * 自社事業場の表示を行うプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => (
  <WorkplaceSelector
    autoHeight={true}
    companyId={props.id}
    onChange={props.onTransite}
    visibleCompanyName={false}
    {...props}
  />
);
