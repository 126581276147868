/**
 * VehiclePlanFormで使用するユーティリティです
 */

import { debugLog } from "utils/log";
import { v4 } from "uuid";
import __ from "lodash";

/**
 * 割り当て済みコース配列を取得します。
 * @param {object} value 値
 * @returns {array}
 */
const findAssignedCourses = (value) => {
  return value.done.courses;
};

/**
 * 未割当の回収情報を取得します。
 * @param {object} value 値
 * @returns {array}
 */
const findUnassignedCycles = (value) => {
  return value.still;
};

/**
 * ゴミ箱のコレクション情報を取得する。
 * @param {object} value 値
 * @returns {array}
 */
const findTrashedCycles = (value) => {
  return value.trashed;
};

/**
 * コースを検索します。
 * @param {array} courses
 * @param {string} id 対象のコースid
 * @returns {object}
 */
const findCourse = (courses, id) => {
  return courses.find((course) => course.id === id);
};

/**
 * コース情報から地点オブジェクトのインデックス値を取得します。
 * @param {array} course コース情報
 * @param {object} cycle 定期回収情報
 * @returns {number}
 */
const findPointIndex = (course, cycle) => {
  return course.points.findIndex(
    (points) =>
      points.assignedRegularlyWasteCollectionCycles.find(
        (o) => o.cycleId === cycle.cycleId
      ) !== undefined
  );
};

/**
 * 定期回収情報全体から定期回収情報インデックスを取得します。
 * @param {array} cycles 定期回収情報全体
 * @param {object} cycle 定期回収情報
 * @returns {number}
 */
const findCycleIndex = (cycles, cycle) => {
  return cycles.findIndex((o) => o.cycleId === cycle.cycleId);
};

/**
 * コース情報から地点情報位置とスケジュール情報位置を取得します。
 * ない場合nullが設定されます。
 * @param {object} course コース情報
 * @param {object} cycle 検索対象の定期回収情報
 * @returns {object}
 */
const findNormalizeIndexFromCycle = (course, cycle) => {
  const pointIndex = findPointIndex(course, cycle);
  const cycleIndex =
    pointIndex !== -1
      ? findCycleIndex(
          course.points[pointIndex].assignedRegularlyWasteCollectionCycles,
          cycle
        )
      : -1;
  return {
    point: pointIndex !== -1 ? pointIndex : null,
    cycle: cycleIndex !== -1 ? cycleIndex : null,
  };
};

/**
 * 回収先情報配列から回収情報を取り出します。
 * @param {array} points 回収先情報配列
 * @returns {array} 定期回収情報配列
 */
const flatCycles = (points) => {
  return points
    .map((point) => point.assignedRegularlyWasteCollectionCycles)
    .flat();
};

/**
 * 定期回収を結合します。
 * @param {object} course コース
 * @param {object} cycle 対象の定期回収
 * @param {object} afterCycle 対象の次の定期回収
 * @remarks 同じ地点情報でcycleとafterCycleの事業場が同じ場合の結合処理です。
 */
const joinBeforeAfterCycle = (course, cycle, afterCycle) => {
  if (
    cycle?.wasteCollectionWorkplace?.id ===
    afterCycle?.wasteCollectionWorkplace?.id
  ) {
    let indexes = findNormalizeIndexFromCycle(course, cycle);
    let afterIndexes = findNormalizeIndexFromCycle(course, afterCycle);

    if (
      course.points[indexes.point]?.id !== course.points[afterIndexes.point]?.id
    ) {
      const remove = course.points.splice(indexes.point, 1);
      afterIndexes = findNormalizeIndexFromCycle(course, afterCycle);
      course.points[
        afterIndexes.point
      ].assignedRegularlyWasteCollectionCycles.splice(
        afterIndexes.cycle,
        0,
        ...remove[0].assignedRegularlyWasteCollectionCycles
      );
    }
  }
};

/**
 * 定期回収をコースに入れ込みます。
 * @param {object} course コース
 * @param {object} cycle 定期回収
 * @param {object} beforeDestCycle 入れ込む定期回収の前定期回収
 * @param {object} afterDestCycle 入れ込む定期回収の後定期回収
 */
const insertCycle = (course, cycle, beforeDestCycle, afterDestCycle) => {
  checkWasteCollectionWorkplace(
    cycle,
    beforeDestCycle,
    afterDestCycle,
    () => {
      // 前と同じだった
      const destIndexes = findNormalizeIndexFromCycle(course, beforeDestCycle);

      course.points[
        destIndexes.point
      ].assignedRegularlyWasteCollectionCycles.splice(
        destIndexes.cycle + 1,
        0,
        cycle
      );
    },
    () => {
      // 後と同じだった
      const destIndexes = findNormalizeIndexFromCycle(course, afterDestCycle);

      course.points[
        destIndexes.point
      ].assignedRegularlyWasteCollectionCycles.splice(
        destIndexes.cycle,
        0,
        cycle
      );
    },
    () => {
      // 前後と違った
      if (beforeDestCycle) {
        const destIndexes = findNormalizeIndexFromCycle(
          course,
          beforeDestCycle
        );

        course.points.splice(
          destIndexes.point + 1,
          0,
          createCoursePoint(undefined, cycle.wasteCollectionWorkplace, [cycle])
        );
      } else if (afterDestCycle) {
        const destIndexes = findNormalizeIndexFromCycle(course, afterDestCycle);

        course.points.splice(
          destIndexes.point,
          0,
          createCoursePoint(undefined, cycle.wasteCollectionWorkplace, [cycle])
        );
      } else {
        throw new Error("不明なエラー");
      }
    },
    () => {
      // 前後と違っており、跨いでいる
      const destIndexes = findNormalizeIndexFromCycle(course, beforeDestCycle);

      const removeDestCycles = course.points[
        destIndexes.point
      ].assignedRegularlyWasteCollectionCycles.splice(destIndexes.cycle + 1);

      course.points.splice(
        destIndexes.point + 1,
        0,
        createCoursePoint(undefined, cycle.wasteCollectionWorkplace, [cycle])
      );

      course.points.splice(
        destIndexes.point + 2,
        0,
        createCoursePoint(
          undefined,
          removeDestCycles[0].wasteCollectionWorkplace,
          removeDestCycles
        )
      );
    },
    () => {
      course.points.push(
        createCoursePoint(undefined, cycle.wasteCollectionWorkplace, [cycle])
      );
    }
  );
};

/**
 * 地点情報を挿入します。
 * @param {object} course コース
 * @param {object} point 対象の地点
 * @param {object} beforeDestCycle 対象の地点の前の定期回収
 * @param {object} afterDestCycle 対象の地点の後の定期回収
 */
const insertPoint = (course, point, beforeDestCycle, afterDestCycle) => {
  checkWasteCollectionWorkplace(
    point.assignedRegularlyWasteCollectionCycles[0],
    beforeDestCycle,
    afterDestCycle,
    () => {
      // 前と同じだった
      const destIndexes = findNormalizeIndexFromCycle(course, beforeDestCycle);

      course.points[
        destIndexes.point
      ].assignedRegularlyWasteCollectionCycles.splice(
        destIndexes.cycle + 1,
        0,
        ...point.assignedRegularlyWasteCollectionCycles
      );
    },
    () => {
      // 後と同じだった
      const destIndexes = findNormalizeIndexFromCycle(course, afterDestCycle);

      course.points[
        destIndexes.point
      ].assignedRegularlyWasteCollectionCycles.splice(
        destIndexes.cycle,
        0,
        ...point.assignedRegularlyWasteCollectionCycles
      );
    },
    () => {
      // 前後と違った
      if (beforeDestCycle) {
        let destIndexes = findNormalizeIndexFromCycle(course, beforeDestCycle);

        course.points.splice(destIndexes.point + 1, 0, point);
      } else if (afterDestCycle) {
        let destIndexes = findNormalizeIndexFromCycle(course, afterDestCycle);

        course.points.splice(destIndexes.point, 0, point);
      } else {
        throw new Error("不明なエラー");
      }
    },
    () => {
      // 前後の情報と事業場が違う・跨ぐ
      const destIndexes = findNormalizeIndexFromCycle(course, beforeDestCycle);

      const removeDestCycles = course.points[
        destIndexes.point
      ].assignedRegularlyWasteCollectionCycles.splice(destIndexes.cycle + 1);

      course.points.splice(destIndexes.point + 1, 0, point);

      course.points.splice(
        destIndexes.point + 2,
        0,
        createCoursePoint(
          undefined,
          removeDestCycles[0].wasteCollectionWorkplace,
          removeDestCycles
        )
      );
    },
    () => {
      course.points.push(point);
    }
  );
};

/**
 * スケジュールの回収場所が同じか確認します。
 * @param {object} targetCycle 対象の定期回収
 * @param {object} beforeCycle 前の定期回収
 * @param {object} afterCycle 後の定期回収
 * @fires sameWasteCollectionWorkplace#onBefore 前と同じだった
 * @fires sameWasteCollectionWorkplace#onAfter 後と同じだった
 * @fires sameWasteCollectionWorkplace#onDifferent 前後違った
 * @fires sameWasteCollectionWorkplace#onDifferentStraddling 前後違っておりかつ事業場が同じ(またぎチェック)
 * @fires sameWasteCollectionWorkplace#onNothing 前後のスケジュールは無かった
 */
const checkWasteCollectionWorkplace = (
  targetCycle,
  beforeCycle,
  afterCycle,
  onBefore,
  onAfter,
  onDifferent,
  onDifferentStraddling,
  onNothing
) => {
  debugLog(`対象: ${targetCycle?.wasteCollectionWorkplace?.name}
   前: ${beforeCycle?.wasteCollectionWorkplace?.name}
   後: ${afterCycle?.wasteCollectionWorkplace?.name}
   `);

  if (!beforeCycle && !afterCycle) {
    onNothing && onNothing();
    return;
  }

  if (
    beforeCycle?.wasteCollectionWorkplace?.id ===
    targetCycle?.wasteCollectionWorkplace?.id
  ) {
    debugLog("前と同じだった");
    onBefore && onBefore();
    return;
  }

  if (
    afterCycle?.wasteCollectionWorkplace?.id ===
    targetCycle?.wasteCollectionWorkplace?.id
  ) {
    debugLog("後と同じだった");
    onAfter && onAfter();
    return;
  }

  if (
    beforeCycle?.wasteCollectionWorkplace?.id ===
    afterCycle?.wasteCollectionWorkplace?.id
  ) {
    debugLog("前後と違っており、跨いでいる");
    onDifferentStraddling && onDifferentStraddling();
    return;
  } else {
    debugLog("前後と違った");
    onDifferent && onDifferent();
    return;
  }
};

/**
 * オーダーし直します。
 * @param {object[]} source 対象のリスト
 * @param {number} fromIndex 移動元のインデックス
 * @param {number} toIndex 移動先のインデックス
 * @returns {object[]} オーダーし直したリスト
 */
export const reorder = (source, fromIndex, toIndex) => {
  const result = Array.from(source);
  const [removed] = result.splice(fromIndex, 1);
  result.splice(toIndex, 0, removed);
  return result;
};

/**
 * CourseDestinationを生成します。
 * @param {object} workplace 事業場情報
 * @param {array} orders 回収情報
 * @returns {object} CourseDestination
 */
export const createCoursePoint = (id = v4(), workplace, orders = []) => {
  return {
    id: id,
    workplace: workplace,
    assignedRegularlyWasteCollectionCycles: orders,
  };
};

/**
 * リストアイテムを移動させます。
 * @param {object} src 移動元リスト
 * @param {object} dest 移動先リスト
 * @param {number} srcIndex 対象のインデックス
 * @param {number} destIndex 移動先インデックス
 * @returns {object} 移動結果
 */
export const moveListItem = (src, dest, srcIndex, destIndex) => {
  let _src = __.cloneDeep(src);
  let _dest = __.cloneDeep(dest);
  let removed = _src.splice(srcIndex, 1);
  _dest.splice(destIndex, 0, removed[0]);
  return {
    src: _src,
    dest: _dest,
  };
};

/**
 * 未配車へ移動する処理です。
 * @param {object} value 値
 * @param {object} droppedResult ドロップ結果オブジェクト
 * @returns {object}
 */
export const moveToUnassigned = (droppedResult, value, isTrashed = false) => {
  debugLog("未配車へ移動");
  const result = __.cloneDeep(value);
  const course = findCourse(
    findAssignedCourses(result),
    droppedResult.source.droppableId
  );
  let cyclesOfFlat = flatCycles(course.points);
  const beforeCycle = __.cloneDeep(
    cyclesOfFlat[droppedResult.source.index - 1]
  );

  const afterCycle = __.cloneDeep(cyclesOfFlat[droppedResult.source.index + 1]);

  const indexes = findNormalizeIndexFromCycle(
    course,
    cyclesOfFlat[droppedResult.source.index]
  );

  if (
    course.points[indexes.point].assignedRegularlyWasteCollectionCycles.length >
    1
  ) {
    // スケジュールを抜き出す
    let removed = course.points[
      indexes.point
    ].assignedRegularlyWasteCollectionCycles.splice(indexes.cycle, 1)[0];
    if (isTrashed) removed = { ...removed, isTrashed };
    result.still.splice(droppedResult.destination.index, 0, removed);
  } else {
    // 地点を抜き出す
    let removed = course.points.splice(indexes.point, 1)[0];
    if (isTrashed) removed = { ...removed, isTrashed };

    result.still.splice(
      droppedResult.destination.index,
      0,
      isTrashed
        ? {
            ...removed.assignedRegularlyWasteCollectionCycles[0],
            isTrashed,
          }
        : removed.assignedRegularlyWasteCollectionCycles[0]
    );
  }

  cyclesOfFlat = flatCycles(course.points);

  joinBeforeAfterCycle(course, beforeCycle, afterCycle);

  debugLog("結果: ", result);
  return result;
};

/**
 * 未配車へ移動する処理です。
 * @param {object} value 値
 * @param {object} droppedResult ドロップ結果オブジェクト
 * @returns {object}
 */
export const moveToTrashed = (droppedResult, value) => {
  debugLog("未配車へ移動");
  const result = __.cloneDeep(value);
  const course = findCourse(
    findAssignedCourses(result),
    droppedResult.source.droppableId
  );
  let cyclesOfFlat = flatCycles(course.points);
  const beforeCycle = __.cloneDeep(
    cyclesOfFlat[droppedResult.source.index - 1]
  );

  const afterCycle = __.cloneDeep(cyclesOfFlat[droppedResult.source.index + 1]);

  const indexes = findNormalizeIndexFromCycle(
    course,
    cyclesOfFlat[droppedResult.source.index]
  );

  if (
    course.points[indexes.point].assignedRegularlyWasteCollectionCycles.length >
    1
  ) {
    // スケジュールを抜き出す
    let removed = course.points[
      indexes.point
    ].assignedRegularlyWasteCollectionCycles.splice(indexes.cycle, 1)[0];
    result.trashed.splice(droppedResult.destination.index, 0, removed);
  } else {
    // 地点を抜き出す
    let removed = course.points.splice(indexes.point, 1)[0];
    result.trashed.splice(
      droppedResult.destination.index,
      0,
      removed.assignedRegularlyWasteCollectionCycles[0]
    );
  }

  cyclesOfFlat = flatCycles(course.points);

  joinBeforeAfterCycle(course, beforeCycle, afterCycle);

  debugLog("結果: ", result);
  return result;
};

/**
 * 同一コースの回収スケジュールを並び替えます。
 * @param {object} value コース情報全体
 * @param {DroppedResult} droppedResult ドラッグ情報
 * @returns {object} 結果
 */
export const reorderOfCycle = (value, droppedResult) => {
  // 結果
  const result = __.cloneDeep(value);

  // 対象コース
  const course = findCourse(
    findAssignedCourses(result),
    droppedResult.source.droppableId
  );

  // コース内全スケジュール
  let cyclesOfFlat = flatCycles(course.points);

  // 移動元・移動先スケジュール情報(コピー)
  const srcCycle = __.cloneDeep(cyclesOfFlat[droppedResult.source.index]);

  // 移動元・移動先インデックス情報
  const srcIndexes = findNormalizeIndexFromCycle(course, srcCycle);

  if (
    course.points[srcIndexes.point].assignedRegularlyWasteCollectionCycles
      .length > 1
  ) {
    debugLog(
      "地点は複数のスケジュールを持っている: ",
      course.points[srcIndexes.point].assignedRegularlyWasteCollectionCycles
    );

    const removeCycle = course.points[
      srcIndexes.point
    ].assignedRegularlyWasteCollectionCycles.splice(srcIndexes.cycle, 1)[0];

    cyclesOfFlat = flatCycles(course.points);

    const beforeDestCycle = __.cloneDeep(
      cyclesOfFlat[droppedResult.destination.index - 1]
    );

    const afterDestCycle = __.cloneDeep(
      cyclesOfFlat[droppedResult.destination.index]
    );

    insertCycle(course, removeCycle, beforeDestCycle, afterDestCycle);
  } else {
    debugLog(
      "地点は複数のスケジュールを持っていない: ",
      course.points[srcIndexes.point].assignedRegularlyWasteCollectionCycles
    );

    const removePoint = course.points.splice(srcIndexes.point, 1)[0];

    cyclesOfFlat = flatCycles(course.points);

    const beforeSrcCycle = __.cloneDeep(
      cyclesOfFlat[droppedResult.source.index - 1]
    );

    const afterSrcCycle = __.cloneDeep(
      cyclesOfFlat[droppedResult.source.index]
    );

    const beforeDestCycle = __.cloneDeep(
      cyclesOfFlat[droppedResult.destination.index - 1]
    );

    const afterDestCycle = __.cloneDeep(
      cyclesOfFlat[droppedResult.destination.index]
    );

    //         ・動かしたスケジュールの元の位置の前後に同一の事業場が存在するか？
    // 　：元の位置の前後が同一の事業場が存在する場合
    // 　　・既に登録されている（バージョンが設定されている）地点は存在するか？
    // 　　　：既に登録されている地点が存在する場合、バージョン値の高い地点に統合する（同値の場合は並び順が上の地点）
    // 　　　：既に登録されている地点が存在しない場合、並び順が上の地点に統合する
    joinBeforeAfterCycle(course, beforeSrcCycle, afterSrcCycle);

    checkWasteCollectionWorkplace(
      removePoint.assignedRegularlyWasteCollectionCycles[0],
      beforeDestCycle,
      afterDestCycle,
      () => {
        // 前と同じだった
        const destIndexes = findNormalizeIndexFromCycle(
          course,
          beforeDestCycle
        );

        course.points[
          destIndexes.point
        ].assignedRegularlyWasteCollectionCycles.splice(
          destIndexes.cycle + 1,
          0,
          removePoint.assignedRegularlyWasteCollectionCycles[0]
        );
      },
      () => {
        // 後と同じだった
        const destIndexes = findNormalizeIndexFromCycle(course, afterDestCycle);

        course.points[
          destIndexes.point
        ].assignedRegularlyWasteCollectionCycles.splice(
          destIndexes.cycle,
          0,
          removePoint.assignedRegularlyWasteCollectionCycles[0]
        );
      },
      () => {
        // 　　　　新しい地点としてコースに設定する
        // 　　　　・設定先が一つの地点を跨ぐか？
        //           移動先の前後に同一の事業場が存在しない場合
        // 　　　　　　残ったスケジュールの数が多い方を既存の地点（同値の場合は並び順が上の地点）とし、もう一方を新しい地点とする
        // 前後と違った
        if (beforeDestCycle) {
          let destIndexes = findNormalizeIndexFromCycle(
            course,
            beforeDestCycle
          );

          course.points.splice(destIndexes.point + 1, 0, removePoint);
        } else if (afterDestCycle) {
          let destIndexes = findNormalizeIndexFromCycle(course, afterDestCycle);

          course.points.splice(destIndexes.point, 0, removePoint);
        } else {
          throw new Error("不明なエラー");
        }
      },
      () => {
        // 　　　：移動先の前後に同一の事業場が存在しない場合、そのままの地点でコースに設定する
        // 　　　　・設定先が一つの地点を跨ぐか？
        // 　　　　　：一つの地点を跨ぐ場合
        // 　　　　　　残ったスケジュールの数が多い方を既存の地点（同値の場合は並び順が上の地点）とし、もう一方を新しい地点とする
        // 前後の情報と事業場が違う・跨ぐ
        const destIndexes = findNormalizeIndexFromCycle(course, afterDestCycle);

        const removeDestCycles = course.points[
          destIndexes.point
        ].assignedRegularlyWasteCollectionCycles.splice(destIndexes.cycle);

        course.points.splice(destIndexes.point + 1, 0, removePoint);

        course.points.splice(
          destIndexes.point + 2,
          0,
          createCoursePoint(
            undefined,
            removeDestCycles[0].wasteCollectionWorkplace,
            removeDestCycles
          )
        );
      }
    );
  }

  debugLog("結果: ", result);

  return result;
};

/**
 * オーダーし直します。
 * @param {object[]} source 対象のリスト
 * @param {DropResult} dropResult DropResultオブジェクト
 * @returns {object[]} オーダーし直したリスト
 */
export const reorderFromDropResult = (source, dropResult) => {
  return reorder(source, dropResult.source.index, dropResult.destination.index);
};

/**
 * 未割当からコースに移動させたときの結果を取得します。
 * @param {object} droppedResult ドロップ結果オブジェクト
 * @param {object} value 値
 * @returns {object}
 */
export const moveToCourse = (droppedResult, value) => {
  debugLog("未割当からコースに移動: ", droppedResult, value);
  const result = __.cloneDeep(value);
  if (droppedResult.source.droppableId === "stillCollects") {
    const unassignedCycles = findUnassignedCycles(result) ?? [];
    const unassignedCycle = unassignedCycles.splice(
      unassignedCycles.findIndex(
        (i) => i.cycleId === droppedResult.draggableId
      ),
      1
    )[0];

    const assignCourse = findAssignedCourses(result).find(
      (item) => item.id === droppedResult.destination.droppableId
    );

    const cyclesOfFlat = flatCycles(assignCourse.points);
    insertCycle(
      assignCourse,
      unassignedCycle,
      cyclesOfFlat[droppedResult.destination.index - 1],
      cyclesOfFlat[droppedResult.destination.index]
    );
  }
  if (droppedResult.source.droppableId === "trashedCourses") {
    const trashedCycles = findTrashedCycles(result) ?? [];
    const trashedCycle = trashedCycles.splice(
      trashedCycles.findIndex((i) => i.cycleId === droppedResult.draggableId),
      1
    )[0];

    const assignCourse = findAssignedCourses(result).find(
      (item) => item.id === droppedResult.destination.droppableId
    );

    const cyclesOfFlat = flatCycles(assignCourse.points);

    insertCycle(
      assignCourse,
      trashedCycle,
      cyclesOfFlat[droppedResult.destination.index - 1],
      cyclesOfFlat[droppedResult.destination.index]
    );
  }

  debugLog("結果: ", result);

  return result;
};

/**
 * 回収情報を別のコースに移動させます。
 * @param {object} droppedResult ドロップ結果オブジェクト
 * @param {object} value 値
 * @returns {object}
 */
export const changeCourse = (droppedResult, value) => {
  debugLog("回収情報を別コースに移動: ", droppedResult, value);
  // 結果
  const result = __.cloneDeep(value);

  // 全コース情報
  const courses = findAssignedCourses(result);

  // 移動元コース
  const srcCourse = findCourse(courses, droppedResult.source.droppableId);

  // 移動元スケジュール(フラット)
  let srcCyclesOfFlat = flatCycles(srcCourse.points);

  const srcBeforeCycle = __.cloneDeep(
    srcCyclesOfFlat[droppedResult.source.index - 1]
  );
  const srcAfterCycle = __.cloneDeep(
    srcCyclesOfFlat[droppedResult.source.index + 1]
  );

  // 移動元インデックス情報
  let srcIndexes = findNormalizeIndexFromCycle(
    srcCourse,
    srcCyclesOfFlat[droppedResult.source.index]
  );

  // 移動先コース
  const destCourse = findCourse(courses, droppedResult.destination.droppableId);

  // 移動先スケジュール(フラット)
  let destCyclesOfFlat = flatCycles(destCourse.points);

  // 移動元の地点 | スケジュールを引き抜く
  if (
    srcCourse.points[srcIndexes.point].assignedRegularlyWasteCollectionCycles
      .length > 1
  ) {
    // スケジュールを引き抜く
    const remove = srcCourse.points[
      srcIndexes.point
    ].assignedRegularlyWasteCollectionCycles.splice(srcIndexes.cycle, 1)[0];

    // 結合処理
    joinBeforeAfterCycle(srcCourse, srcBeforeCycle, srcAfterCycle);

    // 入れ込む
    insertCycle(
      destCourse,
      remove,
      destCyclesOfFlat[droppedResult.destination.index - 1],
      destCyclesOfFlat[droppedResult.destination.index]
    );
  } else {
    // 地点を引き抜く
    const remove = srcCourse.points.splice(srcIndexes.point, 1)[0];

    // 結合処理
    joinBeforeAfterCycle(srcCourse, srcBeforeCycle, srcAfterCycle);

    // 入れ込む
    insertPoint(
      destCourse,
      remove,
      destCyclesOfFlat[droppedResult.destination.index - 1],
      destCyclesOfFlat[droppedResult.destination.index]
    );
  }

  debugLog("結果: ", result);

  return result;
};

/**
 * オーダーし直します。
 * @param {object[]} val 対象のリスト
 * @param {DropResult} dropResult DropResultオブジェクト
 * @returns {object[]} オーダーし直したリスト
 */
export const moveItemsBetweenStillTrash = (val, droppedResult) => {
  debugLog("コースのゴミ箱ステータスを切り替えます。: ", droppedResult, val);
  const { still, trashed } = val;
  const sourceIndex = droppedResult.source.index;
  const destinationIndex = droppedResult.destination.index;

  if (droppedResult.source.droppableId === "trashedCourses") {
    // Move from trashed to still
    const movedItem = trashed[sourceIndex];
    const updatedStill = [...still];
    updatedStill.splice(destinationIndex, 0, movedItem);
    const updatedTrashed = trashed.filter((_, index) => index !== sourceIndex);

    debugLog("結果: ", "トラッシュからスティルへ移動");
    return {
      still: updatedStill,
      trashed: updatedTrashed,
    };
  } else if (droppedResult.destination.droppableId === "trashedCourses") {
    // Move from still to trashed
    const movedItem = still[sourceIndex];
    const updatedTrashed = [...trashed];
    updatedTrashed.splice(destinationIndex, 0, movedItem);
    const updatedStill = still.filter((_, index) => index !== sourceIndex);

    debugLog("結果: ", "スティルからトラッシュへ移動");
    return {
      still: updatedStill,
      trashed: updatedTrashed,
    };
  }

  // No change if neither source nor destination is trashedCourses
  debugLog("結果: ", "変更なし");
  return {
    still,
    trashed,
  };
};

/**
 * The function `moveToUnassign` removes a course from a given value object and adds its assigned waste
 * collection schedules to the `still` property of the object.
 * @param value - The `value` parameter is an object that contains the data structure you want to
 * modify. It likely has properties such as `done` and `still`, which contain arrays of courses and
 * schedules respectively.
 * @param index - The `index` parameter represents the index of the course in the `courses` array that
 * needs to be moved to the `still` object.
 * @returns The function `moveToUnassign` returns an object with the following properties:
 */
export const moveToUnassign = (value, index) => {
  let clonedValue = __.cloneDeep(value);
  const course = clonedValue.done.courses[index];
  const schedules = course.points.flatMap(
    (point) => point.assignedRegularlyWasteCollectionCycles
  );
  let result = {
    ...clonedValue,
    done: {
      ...clonedValue.done,
      courses: clonedValue.done.courses.map((v, i) => {
        if (i === index) {
          return {
            ...v,
            points: [],
          };
        }
        return v;
      }),
    },
    still: [...clonedValue.still, ...schedules],
  };

  debugLog("時間ソート結果:", result);
  return result;
};

/**
 * The `toDate` function converts a string in the format "hours:minutes:seconds" to a JavaScript `Date`
 * object.
 * @param value - The `value` parameter is a string representing a time in the format "HH:MM:SS", where
 * HH represents hours, MM represents minutes, and SS represents seconds.
 * @returns a Date object.
 */
export const toDate = (value) => {
  if (!value) {
    return value;
  }

  let result = new Date();
  let [hours, minutes, seconds] = value.split(":");

  result.setHours(+hours);
  result.setMinutes(minutes);
  result.setSeconds(seconds);

  return result;
};

/**
 * The `ordersByTimeRange` function takes an input value and index, sorts the assigned waste collection
 * schedules based on start time and duration, and returns the updated value.
 * @param value - The `value` parameter is an object that contains the data for the orders. It has a
 * property called `courses`, which is an array of courses. Each course has a property called `points`,
 * which is an array of points. Each point has a property called `assignedWasteCollectionSchedules
 * @param index - The `index` parameter in the `ordersByTimeRange` function represents the index of the
 * course within the `courses` array in the `value` object. It is used to access the specific course
 * and perform operations on it.
 * @returns The function `ordersByTimeRange` returns the modified `value` object with the
 * `courses[index].points` array sorted based on the assigned waste collection schedules' time range.
 */
export const ordersByTimeRange = (value, index) => {
  let result = __.cloneDeep(value);
  const course = result.courses[index];
  const schedules = course.points
    ?.flatMap((point) => point.assignedRegularlyWasteCollectionCycles)
    .sort((a, b) => {
      // 重み
      let weight = 0;

      const aStartDate = toDate(a.scheduleTimeRangeStart);
      const aEndDate = toDate(a.scheduleTimeRangeEnd);
      const bStartDate = toDate(b.scheduleTimeRangeStart);
      const bEndDate = toDate(a.scheduleTimeRangeEnd);

      // 到着時間
      if (aStartDate.getTime() < bStartDate.getTime()) {
        weight -= 10;
      } else if (aStartDate.getTime() > bStartDate.getTime()) {
        weight += 10;
      }

      // 作業時間
      if (
        aEndDate.getTime() - aStartDate.getTime() <
        bEndDate.getTime() - bStartDate.getTime()
      ) {
        weight -= 1;
      } else if (
        aEndDate.getTime() - aStartDate.getTime() >
        bEndDate.getTime() - bStartDate.getTime()
      ) {
        weight += 1;
      }

      return weight;
    });

  let orderedPoints = [];
  let before = null;
  let pointIndex = 0;
  for (let i = 0; i < schedules.length; i++) {
    let target = schedules[i];
    if (before === null) {
      orderedPoints.push({
        id: v4(),
        assignedRegularlyWasteCollectionCycles: [target],
        workplace: target.wasteCollectionWorkplace,
      });

      before = target;
      continue;
    }

    if (
      before.wasteCollectionWorkplace.id === target.wasteCollectionWorkplace.id
    ) {
      orderedPoints[pointIndex].assignedRegularlyWasteCollectionCycles.push(
        target
      );
      before = target;
      continue;
    }

    if (
      before.wasteCollectionWorkplace.id !== target.wasteCollectionWorkplace.id
    ) {
      orderedPoints.push({
        id: v4(),
        assignedRegularlyWasteCollectionCycles: [target],
        workplace: target.wasteCollectionWorkplace,
      });

      before = target;
      pointIndex++;
      continue;
    }
  }

  course.points = orderedPoints;

  debugLog("時間ソート結果:", result);
  return result;
};

/**
 * The `findArrayDifferences` function compares two arrays of objects and returns the differences between them as
 * an array of objects with a type of "remove" or "add".
 * @param {Array} originalArray - The `originalArray` parameter is an array representing the original set of items.
 * @param {Array} modifiedArray - The `modifiedArray` parameter is an array that contains objects. Each object in the
 * array represents an item and has an `id` property.
 * @returns The function `findArrayDifferences` returns an array of objects representing the differences between
 * the `originalArray` and `modifiedArray`. Each object in the returned array has two properties: `type` and
 * `item`. The `type` property can be either "remove" or "add", indicating whether the item is missing
 * in `modifiedArray` or `originalArray` respectively. The `item` property contains the corresponding item.
 */

export const findArrayDifferences = (originalArray, modifiedArray) => {
  const differences = [];

  for (const originalItem of originalArray) {
    if (
      !modifiedArray.some((modifiedItem) => modifiedItem.id === originalItem.id)
    ) {
      differences.push({
        type: "add",
        item: originalItem,
      });
    }
  }

  for (const modifiedItem of modifiedArray) {
    if (
      !originalArray.some((originalItem) => originalItem.id === modifiedItem.id)
    ) {
      differences.push({
        type: "remove",
        item: modifiedItem,
      });
    }
  }

  return differences;
};
