import { Box } from "@mui/material";
import React from "react";
import { CollectDialog, Content, Header } from "views/organisms/Collects";

/**
 *  回収記録の表示を行うプレゼンテーションコンポーネントです。
 * @param {object} value 回収記録情報
 * @param {boolean} open　ダイアログを無効か有効にする
 * @param {func} onClose　ダイアログを閉じる
 * @param {func} onOpen　ダイアログを開く
 * @param {func} getDataSubmit　ダイアログの値を習得します
 * @returns JSX 要素
 */
export const Presententer = (props) => (
  <Box pr={3} pl={3}>
    <Header value={props.value} onClick={props.onOpen} />
    <Content value={props.collectDefault} />
    <CollectDialog
      open={props.open}
      onCloseDialog={props.onClose}
      value={props.collectDefault}
      getDataSubmit={props.getDataSubmit}
    />
  </Box>
);
