import { InformationItem } from "views/atoms";
import React from "react";

/**
 * 処理フロー処分情報をタブ形式で表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <>
      <InformationItem label="処分受託者">
        {props.value?.disposalProcess?.disposalCompany?.name ?? ""}
      </InformationItem>
      <InformationItem label="処分事業場">
        {props.value?.disposalProcess?.disposalWorkplace?.name ?? ""}
      </InformationItem>
      <InformationItem label="処分方法">
        {(props.value?.disposalProcess?.disposalMethod?.smallClassName ||
          props.value?.disposalProcess?.disposalMethod?.middleClassName ||
          props.value?.disposalProcess?.disposalMethod?.largeClassName) ??
          ""}
      </InformationItem>
    </>
  );
};
