import { styles } from "./styles";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { getWasteFlow } from "api/graphql/queries";
import { useEffect, useState } from "react";
import { debugLog } from "utils/log";

/**
 * 処理フロー詳細を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  const classes = styles();
  const [innerValue, setInnerValue] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (value) {
      setLoading(true);
      API.graphql(
        graphqlOperation(getWasteFlow, {
          id: value.wasteFlowId,
        })
      )
        .then((res) => {
          setInnerValue(res.data.getWasteFlow);
        })
        .catch((err) => {
          debugLog("処理フローの呼び出し失敗: ", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [value]);

  return render({
    value: innerValue,
    classes: classes,
    loading: loading,
    ...props,
  });
};
