import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { Forbidden } from "./Forbidden";
import { NotFound } from "./NotFound";
import { Application } from "./Application";

/**
 * 申請ページを表示するアプリケーションコンポーネントです。
 * @returns {JSX.Element}
 */
export const InviteSubApplication = () => {
  return (
    <>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/invite_confirming/" element={<Application />} />
          <Route path="/invite_confirming/forbidden" element={<Forbidden />} />
          <Route path="/invite_confirming/error" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};
