import { useNavigate } from "react-router-dom";
import { useQuery } from "utils/useQuery";
import { Layout } from "./Layout";
import { getAlert } from "api/graphql/queries";

/**
 * 車輛リマインドボタンコンポーネントです。
 * @returns {JSX.Element}
 */
export const Button = () => {
  const navigate = useNavigate();
  const vehicleRemindNotifications = useQuery({
    query: getAlert,
    variables: null,
    onCompleted: () => {},
    onError: () => {},
  });

  const handleClick = () => {
    navigate({
      pathname: `/vehicles-menu/list`,
    });
  };

  return (
    <Layout
      loading={vehicleRemindNotifications.loading}
      count={vehicleRemindNotifications.data?.getAlert?.vehicleRemindCount}
      onClick={handleClick}
    />
  );
};
