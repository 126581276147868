import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { InformationItem } from "views/atoms";
import { FileGallery } from "views/molecules";
import { formatDisplayDate } from "utils/format";

/**
 * 基本情報を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @fires Basic#onClick クリック時
 * @returns {JSX.Element}
 */
export const Basic = ({ value, onClick = () => {} }) => {
  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Box
          display={"flex"}
          flexDirection="row"
          justifyContent={"space-between"}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "17px",
                textDecoration: "underline",
              }}
            >
              {"基本情報"}
            </Typography>
          </Box>
          <Box>
            {value?.state === "active" && (
              <Button variant="contained" onClick={onClick} disabled={!value}>
                {"基本情報を編集する"}
              </Button>
            )}
          </Box>
        </Box>
        <Box>
          <InformationItem label={"管理番号"}>
            {value?.number ?? ""}
          </InformationItem>
        </Box>
        <Box>
          <InformationItem label={"管理事業場"}>
            {`${value?.manageWorkplace?.belongInCompanyName ?? ""} ${
              value?.manageWorkplace?.name ?? ""
            }`}
          </InformationItem>
        </Box>
        <Box>
          <InformationItem label={"追加日"}>
            {`${formatDisplayDate({ source: value?.addedOn })} (${
              value?.additionalType?.name ?? ""
            })`}
          </InformationItem>
        </Box>
        <Box>
          <InformationItem label={"備考"}>
            {value?.remarks ?? ""}
          </InformationItem>
        </Box>
        <Box>
          <InformationItem label={"添付ファイル"}>
            <FileGallery value={value?.files} disabled={true} />
          </InformationItem>
        </Box>
      </Stack>
    </Paper>
  );
};
