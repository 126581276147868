export const styles = (theme) => ({
  box_menu_popper: {
    zIndex: theme.zIndex.modal + 1,
  },
  box__Title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px 8px",
    cursor: "auto",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
    },
  },
});
