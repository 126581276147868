import { WarningOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import { FixedSizeList } from "react-window";
import { Header } from "..";
import { RenderCard } from "../Card";

/**
 * 回収コースを表示するプレゼンテーションコンポーネントです。
 * @param {object} classes クラス名
 * @param {object} value　回収コース
 * @param {func} onClick　コースのヘッダーをクリックするイベント
 * @returns
 */
export const Presententer = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        marginRight: (theme) => theme.spacing(2),
        padding: (theme) => theme.spacing(1),
        transition: "0.3s all linear",
        borderRadius: 1,
        "&:hover": {
          boxShadow: 3,
        },
      }}
    >
      <Box
        onClick={() => props.onClick(props.value.id)}
        sx={{ cursor: "pointer" }}
      >
        <Header value={props.value} />
      </Box>
      <Box className={props.classes.cards} sx={{ marginRight: "-8px" }} mt={2}>
        {props.value?.cards && props.value?.cards?.length > 1 ? (
          <FixedSizeList
            height={
              props.value?.cards?.length > 3
                ? 4 * 97
                : props.value?.cards?.length * 97
            }
            itemCount={props.value?.cards?.length}
            itemData={{
              items: props.value?.cards,
            }}
            itemSize={97}
          >
            {RenderCard}
          </FixedSizeList>
        ) : (
          <Box
            sx={{
              textAlign: "center",
              padding: (theme) => theme.spacing(2, 0),
            }}
          >
            <WarningOutlined fontSize="large" color="secondary" />
            <Typography color="secondary">配車コースがありません。</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
