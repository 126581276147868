import { debugLog } from "utils/log";
import {
  isAllTaskCompleted,
  isPassed,
} from "views/templates/Mobile/Collect/Utils";
import { useStyles } from "./styles";

/**
 * コースリストのヘッダーを表示するコンテナコンポーネントです。
 * @param {object} value 値
 * @param {boolean} current 現在のコース項目であるか
 * @fires Container#onClick クリックされたか
 * @param {object} props その他プロパティ
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({
  render,
  index,
  value,
  current = false,
  onClick = () => debugLog("コースリストのヘッダーがクリックされた"),

  ...props
}) => {
  const classes = useStyles();

  return render({
    completed: isAllTaskCompleted(value?.tasks),
    passed: isPassed(value),
    classes: classes,
    index: index,
    value: value,
    current: current,
    onClick: onClick,
    ...props,
  });
};
