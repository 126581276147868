import {
  Box,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Grid,
  Select,
  Switch,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { DateRangePicker, FieldDecorateFrame } from "views/molecules";

/**
 * 集計条件の設定を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box pt={3} pb={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FieldDecorateFrame label="集計期間" required={true}>
            {(params) => (
              <Controller
                name="aggregateOn"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <DateRangePicker
                      id="aggregateOn"
                      value={value}
                      onChange={onChange}
                      error={Boolean(error)}
                      helperText={
                        error?.message ||
                        error?.start?.message ||
                        error?.end?.message
                      }
                    />
                  );
                }}
              />
            )}
          </FieldDecorateFrame>
        </Grid>
        <Grid item xs={12}>
          <FieldDecorateFrame label="集計の種類" required={true}>
            {(params) => (
              <Controller
                name="mode"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <Select
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        props.trigger("aggregateOn");
                      }}
                      error={error}
                    >
                      <MenuItem value="">
                        <em>未選択</em>
                      </MenuItem>
                      {props.modeList.map((mode) => (
                        <MenuItem value={mode.id} key={mode.id}>
                          {mode.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={error}>
                      {error?.message}
                    </FormHelperText>
                  </>
                )}
              />
            )}
          </FieldDecorateFrame>
        </Grid>
        <Grid item xs={12}>
          <FieldDecorateFrame label="集計方法" required={true}>
            {(params) => (
              <Controller
                name="method"
                control={props.control}
                defaultValue={10}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <Select value={value} onChange={onChange} error={error}>
                      <MenuItem value="">
                        <em>未選択</em>
                      </MenuItem>
                      {props.methodList.map((method) => (
                        <MenuItem value={method.id} key={method.id}>
                          {method.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={error}>
                      {error?.message}
                    </FormHelperText>
                  </>
                )}
              />
            )}
          </FieldDecorateFrame>
        </Grid>
        <Grid item xs={12}>
          <FieldDecorateFrame label="集計値" required={true}>
            {(params) => (
              <Controller
                name="dataset"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <Select value={value} onChange={onChange} error={error}>
                      <MenuItem value="">
                        <em>未選択</em>
                      </MenuItem>
                      {props.datasetList.map((dataset) => (
                        <MenuItem value={dataset.id} key={dataset.id}>
                          {dataset.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={error}>
                      {error?.message}
                    </FormHelperText>
                  </>
                )}
              />
            )}
          </FieldDecorateFrame>
        </Grid>
        <Grid item xs={12}>
          <FieldDecorateFrame label="集計対象日" required={true}>
            {(params) => (
              <Controller
                name="dateType"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <Select value={value} onChange={onChange} error={error}>
                      <MenuItem value="">
                        <em>未選択</em>
                      </MenuItem>
                      {props.dateTypeList.map((dateType) => (
                        <MenuItem value={dateType.id} key={dateType.id}>
                          {dateType.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={error}>
                      {error?.message}
                    </FormHelperText>
                  </>
                )}
              />
            )}
          </FieldDecorateFrame>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">集約列の設定</FormLabel>
            <FormGroup>
              <Controller
                name="groupColumns.logisticsCourseName"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="配車リストの名称"
                  />
                )}
              />
              <Controller
                name="groupColumns.wasteGeneratorCompanyName"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="排出事業者"
                  />
                )}
              />
              <Controller
                name="groupColumns.wasteCollectionWorkplaceCode"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="排出事業場の固有コード"
                  />
                )}
              />
              <Controller
                name="groupColumns.wasteCollectionWorkplaceName"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="排出事業場"
                  />
                )}
              />
              <Controller
                name="groupColumns.wasteCollectionWorkplacePrefecture"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="排出事業場の所在地（都道府県）"
                  />
                )}
              />
              <Controller
                name="groupColumns.wasteCollectionWorkplaceCity"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="排出事業場の所在地（市区町村）"
                  />
                )}
              />
              <Controller
                name="groupColumns.wasteLargeClassName"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="廃棄物の種類（大分類）"
                  />
                )}
              />
              <Controller
                name="groupColumns.wasteMiddleClassName"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="廃棄物の種類（中分類）"
                  />
                )}
              />
              <Controller
                name="groupColumns.wasteSmallClassName"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="廃棄物の種類（小分類）"
                  />
                )}
              />
              <Controller
                name="groupColumns.wasteKindName"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="廃棄物の種類（詳細）"
                  />
                )}
              />
              <Controller
                name="groupColumns.wasteName"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="廃棄物の名称"
                  />
                )}
              />
              <Controller
                name="groupColumns.wasteDisposalCompanyName"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="処分事業者"
                  />
                )}
              />
              <Controller
                name="groupColumns.wasteDisposalWorkplaceName"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="処分事業場"
                  />
                )}
              />
              <Controller
                name="groupColumns.wasteDisposalWorkplacePrefecture"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="処分事業場の所在地（都道府県）"
                  />
                )}
              />
              <Controller
                name="groupColumns.wasteDisposalWorkplaceCity"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="処分事業場の所在地（市区町村）"
                  />
                )}
              />
              <Controller
                name="groupColumns.wasteDisposalMethodLargeClassName"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="処分方法（大分類）"
                  />
                )}
              />
              <Controller
                name="groupColumns.wasteDisposalMethodMiddleClassName"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="処分方法（中分類）"
                  />
                )}
              />
              <Controller
                name="groupColumns.wasteDisposalMethodSmallClassName"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="処分方法（小分類）"
                  />
                )}
              />
              <Controller
                name="groupColumns.wasteDisposalMethodDetails"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={onChange} />}
                    label="処分方法（詳細）"
                  />
                )}
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};
