import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { searchJwnetManifestsByOffset } from "api/graphql/queries";
import { useNavigate, useLocation } from "react-router";
import { useXGridComponents } from "utils/useXGridComponents";
import { DateTime } from "luxon";
import { toQueryString, fromQueryString } from "utils/useXGridComponents";
import {
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-pro";

const columns = [
  {
    field: "passedOverOn",
    headerName: "引渡日",
    minWidth: 120,
    flex: 1,
    valueFormatter: (params) => {
      return params?.value
        ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd")
        : "";
    },
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "registeredOn",
    headerName: "登録日",
    minWidth: 120,
    flex: 1,
    valueFormatter: (params) => {
      return params?.value
        ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd")
        : "";
    },
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "lastUpdatedOn",
    headerName: "最終更新日",
    minWidth: 160,
    flex: 1,
    valueFormatter: (params) => {
      return params?.value
        ? DateTime.fromISO(params.value, { setZone: "Asia/Tokyo" }).toFormat(
            "yyyy/MM/dd"
          )
        : "";
    },
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "manifestNumber",
    headerName: "マニフェスト番号",
    minWidth: 200,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "manifestInfoTypeName",
    headerName: "登録の状態",
    minWidth: 230,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "transportReportCompleted",
    headerName: "運搬",
    minWidth: 120,
    flex: 1,
    renderCell: (params) => {
      return params?.value ? <CheckCircleOutlineOutlinedIcon /> : "";
    },
    type: "boolean",
  },
  {
    field: "disposalReportCompleted",
    headerName: "処分",
    minWidth: 120,
    flex: 1,
    renderCell: (params) => {
      return params?.value ? <CheckCircleOutlineOutlinedIcon /> : "";
    },
    type: "boolean",
  },
  {
    field: "lastDisposalReportCompleted",
    headerName: "最終",
    minWidth: 120,
    flex: 1,
    renderCell: (params) => {
      return params?.value ? <CheckCircleOutlineOutlinedIcon /> : "";
    },
    type: "boolean",
  },
  {
    field: "wasteCategoryName",
    headerName: "廃棄物の分類名",
    minWidth: 190,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "wasteKind",
    headerName: "廃棄物の種類",
    minWidth: 170,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "wasteName",
    headerName: "廃棄物の名称",
    minWidth: 170,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "wasteQuantityValue",
    headerName: "廃棄物の数量",
    minWidth: 170,
    flex: 1,
    type: "number",
    filterOperators: getGridNumericOperators().filter(
      (operator) =>
        operator.value === ">" ||
        operator.value === ">=" ||
        operator.value === "<" ||
        operator.value === "<=" ||
        operator.value === "=" ||
        operator.value === "!="
    ),
  },
  {
    field: "wasteQuantityUnitName",
    headerName: "廃棄物の数量単位",
    minWidth: 200,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "confirmedWasteQuantityValue",
    headerName: "廃棄物の確定数量",
    minWidth: 200,
    flex: 1,
    type: "number",
    filterOperators: getGridNumericOperators().filter(
      (operator) =>
        operator.value === ">" ||
        operator.value === ">=" ||
        operator.value === "<" ||
        operator.value === "<=" ||
        operator.value === "=" ||
        operator.value === "!="
    ),
  },
  {
    field: "confirmedWasteQuantityUnitName",
    headerName: "廃棄物の確定数量単位",
    minWidth: 220,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "wasteDischargeCompanyName",
    headerName: "排出事業者",
    minWidth: 150,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "wasteDischargeWorkplaceName",
    headerName: "排出事業場",
    minWidth: 150,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
];

/**
 * 電子マニフェストの一覧を表示するコンテナコンポーネントです
 * @callback render
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const xGridObject = useXGridComponents(
    columns,
    searchJwnetManifestsByOffset,
    null,
    {
      sort: {
        direction: "desc",
        field: "passedOverOn",
      },
      ...fromQueryString(location.search),
    }
  );

  const handleClick = (params) => {
    navigate(
      {
        pathname: location.pathname,
        search: toQueryString(xGridObject.search),
      },
      {
        replace: true,
      }
    );

    navigate(`${location.pathname}/${params[0]}`);
  };

  return render({
    onClick: handleClick,
    xGridParams: xGridObject.params,
    ...props,
  });
};
