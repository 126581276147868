import { useNavigate, useLocation } from "react-router-dom";

/**
 * 外部サービス連携のメニューコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (data) => {
    navigate(`${location.pathname}/${data}`);
  };

  return render({ onClick: handleClick, ...props });
};
