import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Paper, Stack } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { DatePicker, TextField } from "views/atoms";
import { schema } from "./schema";

const defaultValues = {
  companyName: null,
  corporateNumber: null,
  representativeName: null,
  establishmentedOn: null,
  url: null,
  phoneticGuidesOfCompanyName: null
};

/**
 * 企業フォームを表示するコンポーネント。
 * @param {object} props プロパティ
 * @param {object} prop.value 値
 * @fires CompanyForm#onNext 次へ
 * @returns {JSX.Element}
 */
export const CompanyForm = ({ value = null, onNext = () => {} }) => {
  const { control, getValues, trigger, formState } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(schema),
  });

  const handleNext = async () => {
    const result = await trigger();

    if (result) {
      onNext({ 
        ...getValues(),
        phoneticGuidesOfCompanyName: null,
      });
    } else {
      console.log(formState.errors);
    }
  };

  return (
    <Paper
      sx={{
        p: 3,
      }}
    >
      <Stack spacing={2}>
        <Controller
          name={`companyName`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextField
                label="会社名"
                placeholder="例) 株式会社 テスト"
                required={true}
                value={value}
                onChange={onChange}
                error={Boolean(error)}
                helperText={error?.message}
                fullWidth={true}
              />
            );
          }}
        />
        <Controller
          name={`corporateNumber`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextField
                label="法人番号"
                required={false}
                value={value}
                onChange={onChange}
                error={Boolean(error)}
                helperText={error?.message}
                inputProps={{
                  maxLength: 13,
                }}
              />
            );
          }}
        />
        <Controller
          name={`representativeName`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextField
                label="代表者"
                required={false}
                value={value}
                onChange={onChange}
                error={Boolean(error)}
                helperText={error?.message}
                fullWidth={true}
              />
            );
          }}
        />
        <Controller
          name={`establishmentedOn`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <DatePicker
                label="設立日"
                placeholder="例）2021/06/06"
                required={false}
                value={value}
                onChange={onChange}
                error={Boolean(error)}
                helperText={error?.message}
              />
            );
          }}
        />
        <Controller
          name={`url`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextField
                label="企業ホームページ"
                placeholder="例）https://ecpope-pro.co.jp"
                required={false}
                value={value}
                onChange={onChange}
                error={Boolean(error)}
                helperText={error?.message}
                fullWidth={true}
              />
            );
          }}
        />
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={handleNext}>
            {"次へ"}
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};
