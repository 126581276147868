import { Container } from "@mui/material";
import React from "react";
import { RenderButtonToolBar } from "utils/XgridExpand";
import { FullScreenDialog, XGridComponent } from "views/molecules";
import { CategoryListForm } from "views/organisms/Masters/Container";

const Form = React.forwardRef((props, ref) => (
  <CategoryListForm {...props} _ref={ref} />
));

/**
 * コンテナ管理画面の表示を行うプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <>
      <XGridComponent
        onChangeSelection={props.onSelected}
        checkboxSelection={false}
        multipleSelection={false}
        onDownload={true}
        actionButton={
          <RenderButtonToolBar
            label="新しいコンテナの種類を追加する"
            onClick={props.onOpenDialog}
          />
        }
        {...props.xGridParams}
      />
      <FullScreenDialog
        onClose={props.onCloseDialog}
        title={"新しいコンテナの種類の登録"}
        textConfirm={"登録"}
        open={props.open}
        isSubmit={props.isSubmit}
        onClickSubmit={props.onClickRegister}
      >
        <Container>
          <Form
            onSubmit={props.onSubmit}
            loading={props.isSubmit}
            ref={props.formRef}
          />
        </Container>
      </FullScreenDialog>
    </>
  );
};
