import { DayOfWeek, Week } from "utils/Context";

export const Container = ({ render, value, ...props }) => {

  return render({
    value: value,
    DayOfWeek: DayOfWeek,
    Week: Week,
    ...props,
  });
};
