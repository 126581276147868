import { makeStyles } from "@mui/styles";

export const styles = makeStyles((theme) => ({
  override: {
    "& [data-rsbs-overlay], [data-rsbs-backdrop], [data-rsbs-root]:after": {
      bottom: "58px",
    },
    "& [data-rsbs-footer]": {
      padding: 0,
    },
  },
  footer: {
    zIndex: 5,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: theme.palette.text.hint,
  },
  buttonMenuMobile: {
    minWidth: "auto ",
    padding: "8px 16px 3px",
    flexDirection: "column",
    justifyContent: "center",
    textTransform: "none",
    fontSize: "13px",
    "& .MuiButton-startIcon": {
      marginLeft: "0",
      marginRight: " 0",
    },
  },
}));
