import { useRef, useState } from "react";

/**
 * チェックシート一覧を表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  isSubmit,
  onSubmit,
  formOpener,
  ...props
}) => {
  const formRef = useRef(null);
  const [formValue, setFormValue] = useState([]);

  const onSelected = (params) => {
    props.onSelected(
      props.xGridParams.rows.filter((row) => params.includes(row.id))
    );
  };

  const handleClickRegister = () => {
    formRef?.current?.submit();
  };

  return render({
    ...props,
    formRef: formRef,
    onSelected: onSelected,
    isSubmit: isSubmit,
    onClickRegister: handleClickRegister,
    formOpener: formOpener,
    formValue: formValue,
    setFormValue: setFormValue,
    onSubmit: onSubmit
  });
};
