import { DomainOutlined } from "@mui/icons-material";
import { Avatar, Box, Button } from "@mui/material";
import React from "react";
import { FullScreenDialog, ConfirmDialog } from "views/molecules";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { Caption } from "views/organisms/DetailsFrame/ChildComponents";
import { RegularlyDetails, RegularlyForm } from "views/organisms/Order";

const Form = React.forwardRef((props, ref) => (
  <RegularlyForm {...props} _ref={ref} />
));

/**
 * 定期回収情報を詳しく表示するプレゼンテーションコンポーネントです。
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  value,
  open,
  onCloseDialog,
  onOpenDialog,
  onConfirm,
  onSubmit,
  isSubmit,
  formRef,
  onChange,
  onNotificationChange,
  openDeleteConfirm,
  onCloseDeleteConfirm,
  onOpenDeleteConfirm,
  onDelete,
}) => {
  return (
    <div>
      <DetailsFrame
        caption={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Avatar
              sx={{
                marginRight: 3,
              }}
            >
              <DomainOutlined />
            </Avatar>
            <Caption
              title={`${value?.wasteGeneratorCompany?.name ?? ""}${
                value?.wasteCollectionWorkplace?.name ?? ""
              }`}
              subTitle={`〒${
                value?.wasteCollectionWorkplace?.postalCode ?? ""
              } ${value?.wasteCollectionWorkplace?.prefectures?.name ?? ""}${
                value?.wasteCollectionWorkplace?.city ?? ""
              }${value?.wasteCollectionWorkplace?.streetAddress ?? ""}${
                value?.wasteCollectionWorkplace?.otherAddress ?? ""
              }`}
            />
          </div>
        }
        actions={
          <Box>
            {false && (
              <Button
                variant="contained"
                color="primary"
                onClick={onOpenDialog}
              >
                {"定期回収を編集する"}
              </Button>
            )}
            <Button
              sx={{
                marginLeft: 2,
              }}
              variant="contained"
              color="error"
              onClick={onOpenDeleteConfirm}
            >
              {"定期回収を削除する"}
            </Button>
          </Box>
        }
      >
        <RegularlyDetails
          value={value}
          onNotificationChange={onNotificationChange}
        />
        <FullScreenDialog
          open={open}
          onClose={onCloseDialog}
          title={"定期回収を編集する"}
          textConfirm={"保存"}
          onClickSubmit={onConfirm}
          isSubmit={isSubmit}
          disabled={isSubmit}
        >
          <Form value={value} ref={formRef} onSubmit={onSubmit} />
        </FullScreenDialog>
        <ConfirmDialog
          title="削除確認"
          message="この定期回収で作成した過去の回収記録も削除されますが、よろしいですか？"
          open={openDeleteConfirm}
          positiveText="はい"
          negativeText="いいえ"
          onPositive={onDelete}
          disabled={isSubmit}
          onNegative={onCloseDeleteConfirm}
        ></ConfirmDialog>
      </DetailsFrame>
    </div>
  );
};
