import React from "react";
import { InspectionList } from "views/templates/Mobile//Vehicles/InspectionList";
import { AppBar } from "views/organisms/Mobile/Common/AppBar";
import { Stack } from "@mui/material";

/**
 * モバイルの車輛点検一覧を表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => (
  <Stack
    sx={{
      "@media print": {
        width: 375,
      },
    }}
  >
    <AppBar />
    <InspectionList {...props} />
  </Stack>
);
