import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  collect_header: {
    marginTop: theme.spacing(1),
    fontSize: "14px",
    "& .collect_avatar": {
      fontSize: "12px",
      backgroundColor: theme.palette.icon.company,
      width: "35px",
      height: "35px",
      marginRight: theme.spacing(1),
    },
  },
  collect_name: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.grey[600],
  },
  collect_truck: {
    display: "flex",
    alignItems: "center",

    color: theme.palette.grey[600],

    "& span": {
      display: "block",
      fontWeight: "500",
    },
  },
}));