import React from "react";
import { StepFrame } from "views/molecules";
import { WasteBasicForm } from "../../WasteBasicForm";
import { WasteFlowSelector } from "views/molecules";
import { FormHelperText } from "@mui/material";
import { isEmpty } from "lodash";

const Form = React.forwardRef((props, ref) => (
  <WasteBasicForm {...props} _ref={ref} />
));

/**
 * 取引先事業場廃棄物情報を新規登録するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <>
      <StepFrame number="1" title="基本情報">
        <Form
          ref={props.formRef}
          onSubmit={props.onSubmit}
          onNotificationChanged={props.onNotificationChanged}
        />
      </StepFrame>
      <StepFrame number="2" title="処理フロー">
        <>
          {!isEmpty(props.fixedFilter) && (
            <WasteFlowSelector
              value={props.value}
              multiple={true}
              checkbox={true}
              onSelected={props.onWasteFlowSelected}
              managedCompanyId={props.managedCompanyId}
              fixedFilter={props.fixedFilter}
            />
          )}
          {!props.wasteType && (
            <FormHelperText error={true}>
              {"廃棄物の種類を選択してください。"}
            </FormHelperText>
          )}
          <FormHelperText error={true}>{props.error?.message}</FormHelperText>
        </>
      </StepFrame>
    </>
  );
};
