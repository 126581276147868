import { Button, DialogContentText, Stack } from "@mui/material";
import React from "react";
import { ConfirmDialog, FullScreenDialog } from "views/molecules";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { Caption } from "views/organisms/DetailsFrame/ChildComponents";
import {
  ContractDetailsTabs,
  ContractForm,
} from "views/organisms/Masters/Partner";

/**
 * 契約詳細画面を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  classes,
  tab = 0,
  getTab,
  value,
  isOpenEditDialog,
  isOpenRemoveDialog,
  onOpenEditDialog,
  onOpenRemoveDialog,
  onCloseEditDialog,
  onCloseRemoveDialog,
  onUpdate,
  onRemove,
  isSubmit,
  client,
  ...props
}) => (
  <div>
    <DetailsFrame
      caption={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Caption title={value?.name} />
        </div>
      }
      actions={
        tab === 0 && client?.state !== "removed" ? (
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="error"
              onClick={onOpenRemoveDialog}
              disabled={!value}
            >
              {"契約を削除する"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onOpenEditDialog}
              disabled={!value}
            >
              {"契約を編集する"}
            </Button>
          </Stack>
        ) : (
          <></>
        )
      }
    >
      <ContractDetailsTabs value={value} onChangeTab={getTab} />
      <FullScreenDialog
        isSubmit={isSubmit}
        disabled={isSubmit}
        open={isOpenEditDialog}
        onClose={onCloseEditDialog}
        title={`契約書の編集`}
        textConfirm="保存"
        formId="EditContract"
      >
        <ContractForm
          id={"EditContract"}
          onSubmit={onUpdate}
          value={props.format(value)}
          readOnlyControls={{
            contractType: true,
            parties: true,
          }}
        />
      </FullScreenDialog>
      <ConfirmDialog
        title="削除します。よろしいですか？"
        message={
          <DialogContentText>
            削除されたデータを元に戻すことはできません。
          </DialogContentText>
        }
        open={isOpenRemoveDialog}
        positiveText={"削除"}
        negativeText={"キャンセル"}
        onPositive={onRemove}
        onNegative={onCloseRemoveDialog}
        onClose={onCloseRemoveDialog}
      />
    </DetailsFrame>
  </div>
);
