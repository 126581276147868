import { Box, Typography } from "@mui/material";
import React from "react";

/**
 * 内容を囲むする表示を行うプレゼンテーションです。
 * @param {string} value 値
 * @param {string} label ラベル
 */
export const Presententer = (props) => {
  return (
    <Box sx={{ bgcolor: "#fff", p: 3, pl: 5, pr: 5, mb: 3 }}>
      <Typography sx={{ fontWeight: 600 }}>{props.title}</Typography>
      {props.subTitle && (
        <Typography
          sx={{
            fontSize: "13px",
            color: (theme) => theme.palette.grey[500],
            letterSpacing: "2px",
            mt: 1,
          }}
        >
          {props.subTitle}
        </Typography>
      )}
      <Box mt={1}>{props.children}</Box>
    </Box>
  );
};
