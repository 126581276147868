import { ArrowBackIosOutlined } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Slide,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={{ flex: 1 }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, pl: 5, pr: 5 }}>{children}</Box>}
    </Box>
  );
}
/**
 * ダイアログを表示するコンポーネントです。
 * @param {object} classes クラス名
 * @param {object} open ダイアログオープン
 * @param {object} onClose ダイアログを閉じる

 */
export const Presententer = (props) => {
  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{ position: "relative", color: (theme) => theme.palette.grey[800] }}
        elevation={0}
        color="inherit"
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <ArrowBackIosOutlined />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          bgcolor: (theme) => theme.palette.background.default,
          flexGrow: 1,
          display: "flex",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={props.tabNumber}
          onChange={props.onChangeTab}
          sx={{
            bgcolor: "#fff",
            minWidth: 270,
            "& .MuiTabs-indicator": {
              left: 0,
              right: "auto",
              width: "4px",
              bgcolor: (theme) => theme.palette.secondary.light,
            },
            "& .Mui-selected": {
              bgcolor: (theme) => theme.palette.grey[200],
              color: (theme) => `${theme.palette.text.secondary} !important`,
            },
          }}
        >
          {props.tabs.map((tab, i) => (
            <Tab
              icon={tab?.icon}
              label={tab?.label}
              {...a11yProps(0)}
              className={props.classes.tab}
              key={i}
            />
          ))}
        </Tabs>
        {props.tabs.map((tab, i) => (
          <TabPanel value={props.tabNumber} index={i} key={i}>
            {tab.content}
          </TabPanel>
        ))}
      </Box>
    </Dialog>
  );
};
