import { useEffect, useState } from "react";

/**
 * 引数を受けてJSX Elemntを返します。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({ render, ...props }) => {
  const { id, value, api, field } = props;
  const [time, setTime] = useState();
  useEffect(() => {
    if (value) {
      setTime(value);
    }
  }, [value]);
  const handleChange = (event) => {
    if (event?.toString() === "Invalid Date" || event === null) {
      setTime(value);
    } else {
      api.setEditCellValue({ id, field, value: event }, event);
      api.commitCellChange({ id, field });
      setTime(event);
    }
  };
  return render({
    value: time,
    handleChange: handleChange,
    ...props,
  });
};
