import React from "react";
import { XGridComponent } from "views/molecules";

/**
 * 処理フローを表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <XGridComponent
      onChangeSelection={props.onSelected}
      checkboxSelection={false}
      multipleSelection={false}
      onDownload={true}
      {...props.xGridParams}
    />
  );
};
