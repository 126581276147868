import { Button } from "@mui/material";
import { Form } from "features/MembersManage/Form";
import { Table } from "features/MembersManage/Table";
import { useRef } from "react";
import { FullScreenDialog } from "views/molecules";

/**
 * メンバー管理一覧を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const List = (props) => {
  const ref = useRef(null);

  return (
    <>
      <Table
        onSelected={props.onSelected}
        actions={
          <Button
            variant="contained"
            onClick={() => props.form.toggle(true)}
            disabled={props.loading}
          >
            {"新しいメンバーを招待"}
          </Button>
        }
        _ref={props.tableRef}
      />
      <FullScreenDialog
        open={props.form.open}
        onClose={() => props.form.toggle(false)}
        title={`新しいメンバーの招待`}
        textConfirm={"登録"}
        isSubmit={props.loading}
        disabled={props.loading}
        onClickSubmit={() => ref?.current?.submit?.()}
      >
        <Form
          value={null}
          onSubmit={props.onCreate}
          disabled={props.loading}
          customErrors={props.customErrors}
          _ref={ref}
        />
      </FullScreenDialog>
    </>
  );
};
