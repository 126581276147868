import { Container, Grid } from "@mui/material";
import React from "react";
import { Controller, FormProvider } from "react-hook-form";
import {
  AddressFromPostCode,
  DatePicker,
  FileGallery,
  InputField,
  Uploader,
} from "views/molecules";
import { CategorySelector } from "./CategorySelector";
import { LocalGovernmentComboBox } from "./LocalGovernmentComboBox";

/**
 * 新しい事業場を登録するフォームを表示するコンポーネントです。
 * @param {object} props ポロパティ
 * @param {object} control　reactのcontrol
 * @param {array} businessTypes 事業場の種類
 * @returns {JSX.Elemnt} JSX 要素
 */
export const Presententer = (props) => {
  return (
    <FormProvider {...props.methods}>
      <form id={props.id} onSubmit={props.submit} noValidate>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="number"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    label="許可番号"
                    value={value}
                    onChange={onChange}
                    required={true}
                    error={error}
                    placeholder="許可番号を入力します。"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="japaneseLocalGovernment"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <LocalGovernmentComboBox
                    value={value}
                    onChange={onChange}
                    error={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="category"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <CategorySelector
                    value={value}
                    onChange={onChange}
                    error={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="licensedOn"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    label="許可の年月日"
                    value={value}
                    onChange={onChange}
                    required={true}
                    error={error}
                    disableFuture={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="licenseExpirationOn"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    label="許可の有効年月日"
                    value={value}
                    onChange={onChange}
                    required={true}
                    disablePast={true}
                    error={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="companyName"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    label="会社名"
                    value={value}
                    onChange={onChange}
                    required={true}
                    error={error}
                    placeholder="会社名を入力します。"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="representativeName"
                control={props.control}
                render={({ field: { value, onChange } }) => (
                  <InputField
                    label="代表者名"
                    value={value}
                    onChange={onChange}
                    placeholder="代表者名を入力します。"
                  />
                )}
              />
            </Grid>
            <AddressFromPostCode control={props.control} />
            <Grid item xs={12}>
              <Controller
                name="remarks"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    label="備考"
                    value={value}
                    onChange={onChange}
                    error={error}
                    multiline={true}
                    rows={3}
                    placeholder="特記事項があれば記載します。"
                  />
                )}
              />
            </Grid>
            <Grid item sx={12}>
              <Controller
                name="files"
                control={props.control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <Uploader
                      fullWidth={true}
                      maxSize={26214400}
                      onChange={(files) => {
                        onChange([...(value ?? []), ...files]);
                      }}
                      dropzoneText="最大ファイルサイズ:25MB"
                    />
                    <FileGallery value={value} onChange={onChange} />
                  </>
                )}
              />
            </Grid>
          </Grid>
        </Container>
      </form>
    </FormProvider>
  );
};
