import { FormProvider } from "react-hook-form";
import { StepFrame } from "views/molecules";
import { BasicInformation, CollectCycleForm } from "./ChildComponents";

/**
 * 定期回収を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <FormProvider {...props.methods}>
      <StepFrame number="1" title="基本情報">
        <BasicInformation companyId={props.id} />
      </StepFrame>
      <StepFrame number="2" title="回収サイクル">
        <CollectCycleForm />
      </StepFrame>
    </FormProvider>
  );
};
