import { generateClient } from "aws-amplify/api";
const API = generateClient();

const graphqlOperation = (query, variables = null) => {
  if (query && variables) {
    return {
      query: query,
      variables: variables,
    };
  }
  return {
    query: query,
  };
};

export { graphqlOperation, API };
