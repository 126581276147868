import { onCloseDialog, onOpenDialog } from "ducks/Dialog/slice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const Container = ({ render, value, label, ...props }) => {
  const { isOpen } = useSelector((state) => state.Dialog);
  const dispatch = useDispatch();

  const [textValue, setTextValue] = useState(value);

  const open = isOpen.some((v) => v === `${label}-EditTextField`);
  const handleOpenDialg = () => {
    dispatch(onOpenDialog(`${label}-EditTextField`));
  };

  const handleCloseDialg = () => {
    dispatch(onCloseDialog(`${label}-EditTextField`));
  };

  const handleGetOnchange = (params) => {
    setTextValue(params);
  };

  return render({
    textValue: textValue,
    label: label,

    open: open,
    onOpen: handleOpenDialg,
    onClose: handleCloseDialg,
    getOnchange: handleGetOnchange,
    ...props,
  });
};
