import { memo } from "react";
import PropTypes from "prop-types";
import { DirectionsRenderer } from "@react-google-maps/api";

/**
 * 現在地から回収ポイントまでルートの表示を行うコンテナプレゼンテーションです
 * @param {object} directions ルート
 * @returns {JSX.Element}
 */
export const Presententer = memo(
  ({ directions }) => {
    if (!directions) return null;
    return (
      <DirectionsRenderer
        directions={directions}
        options={{
          suppressMarkers: true,
        }}
      />
    );
  },
  (prevProps, nextProps) => {
    return prevProps.directions === nextProps.directions;
  }
);

Presententer.propTypes  = {
  directions: PropTypes.object,
}