import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 認証後のページテンプレートを表示するコンポーネントです。
 */
export const AuthenticatedTemplate = containerPresententer(
  Container,
  Presententer
);
