import { yupResolver } from "@hookform/resolvers/yup";
import { WarningOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  Stack,
  Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Selector as FlowSelector } from "features/Collects/Records/Pc/Form/Flows/Selector";
import { getWasteFullName } from "features/Collects/utils";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { If } from "views/atoms";
import { InputField } from "views/molecules";
import { schema } from "./schema";
import { parseNumber, emptyStringWhenNull } from "utils/format";

const defaultValues = {
  wasteFlowId: null,
  quantity: null,
  remarks: "",
};

/**
 * 回収数量を入力するコンポーネントです。
 * @param {object} props プロパティ
 * @param {boolean} props.open 開閉状態
 * @param {object} props.record 回収記録
 * @param {object} props.task 回収タスク
 * @param {boolean} props.disabled 無効設定
 * @fires Layout#onClose 閉じる時
 * @fires Layout#onSave 保存時
 * @fires Layout#onError エラー時
 * @returns {JSX.Element}
 */
export const Layout = ({
  open = false,
  onClose = () => {},
  onSave = () => {},
  onError = () => {},
  item,
  record,
  task,
  disabled = false,
  loading = false,
}) => {
  const [selectorLoading, setSelectorLoading] = useState(false);
  const { control, getValues, trigger, formState, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...record,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (open) {
      reset({
        ...defaultValues,
        ...record,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleSave = async () => {
    const result = await trigger();

    if (result) {
      const { quantity, wasteFlowId, remarks } = getValues();

      onSave({
        input: {
          locationId: item.id,
          recordTasks: [
            {
              taskId: task.id,
              wasteFlowId: wasteFlowId,
              quantity: parseNumber(emptyStringWhenNull(quantity)) ?? 0,
              quantityUnitCode: task?.waste?.quantityUnit?.code,
              weightOfKg: task?.waste?.weightConvertedValue
                ? (parseNumber(emptyStringWhenNull(quantity)) ?? 0) *
                  task.waste.weightConvertedValue
                : null,
              remarks: remarks,
              recordVersion: task?.record?.version,
            },
          ],
        },
      });
    } else {
      onError(formState.errors);
    }
  };

  return (
    <Dialog open={open} onClose={loading === true ? null : onClose}>
      <Stack
        spacing={2}
        sx={{
          p: 2,
        }}
      >
        <Stack>
          <Typography>{"未入力分は0で登録されます。"}</Typography>
        </Stack>
        <Stack>
          <Controller
            control={control}
            name="wasteFlowId"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <>
                <If condition={!value && selectorLoading === false}>
                  <Typography
                    color="secondary"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <WarningOutlined />
                    {"処理フローが選択されていません。"}
                  </Typography>
                </If>
                <FlowSelector
                  id={task?.waste?.id}
                  value={value}
                  onChange={onChange}
                  disabled={disabled || loading || selectorLoading}
                  onNotificationLoadingChange={setSelectorLoading}
                />
              </>
            )}
          />
        </Stack>
        <Controller
          control={control}
          name="quantity"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Stack>
              <InputField
                value={value}
                onChange={onChange}
                error={error}
                label={getWasteFullName(task?.waste)}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {task?.waste?.quantityUnit?.name}
                    </InputAdornment>
                  ),
                }}
                disabled={disabled || loading || selectorLoading}
              />
              {task?.weightConvertedValue && (
                <Typography>{`重量換算係数 ${task?.weightConvertedValue} ${task?.weightConvertedValueUnit.name}/${task?.waste?.packing?.name}`}</Typography>
              )}
            </Stack>
          )}
        />
        <Stack>
          <Controller
            control={control}
            name="remarks"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputField
                label="特記事項"
                placeholder="特記事項を入力します。"
                multiline
                rows={4}
                value={value}
                onChange={onChange}
                error={error}
                disabled={disabled || loading || selectorLoading}
              />
            )}
          />
        </Stack>
      </Stack>
      <DialogActions>
        <Button
          onClick={onClose}
          color="error"
          disabled={disabled || loading || selectorLoading}
        >
          {"キャンセル"}
        </Button>
        <Button
          onClick={handleSave}
          autoFocus
          disabled={disabled || loading || selectorLoading}
        >
          {"保存"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
