import React from "react";
import { XGridComponent } from "views/molecules";

/**
 * 廃棄物種類を選択するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <XGridComponent
    {...props.xGridParams}
    value={props.value}
    onChangeSelection={props.onSelected}
    checkboxSelection={props.checkbox}
    multipleSelection={props.multiple}
    titleTable={props.title}
    onDownload={false}
    rowsPerPageOptions={[5]}
    autoHeight={false}
  />
);
