import { debugLog } from "utils/log";
import { useStyles } from "./styles";

/**
 * コースリストを表示するコンテナコンポーネントです。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({
  render,
  value,
  index,
  onClickNumber = (data) => debugLog("番号のクリック: ", data),
  onOpenDialog,
  next,
  ...props
}) => {
  const classes = useStyles();

  return render({
    classes: classes,
    value: value,
    index: index,
    next: next,
    onClickNumber: onClickNumber,
    onOpenDialog: onOpenDialog,
    ...props,
  });
};
