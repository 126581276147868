import { Slide } from "@mui/material";
import { ChatOutlined } from "@mui/icons-material";
import { Alert } from "@mui/lab";
import React from "react";

/**
 * 通知の表示を行うコンテナプレゼンテーションです
 * @param {JSX} icon 通知のアイコン
 * @param {string} message 通知の内容
 * @param {func} onClick 通知をクリックするイベント
 * @param {boolean} open 通知が有効か無効を設定
 * @param {func} onClose 通知を閉じるイベントです
 */
export const Presententer = (props) => {
  return (
    <Slide in={props.open} direction="left" mountOnEnter unmountOnExit>
      <Alert
        icon={<ChatOutlined color="inherit" />}
        severity="success"
        onClick={props.onClick}
      >
        {props.message}
      </Alert>
    </Slide>
  );
};
