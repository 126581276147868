/**
 * 車輛管理整備記録を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, value, loading, form, onUpdate, confirmDialog, onRemove, ...props }) => {
  return render({
    ...props,
    form: form,
    value: value,
    onUpdate: onUpdate,
    loading: loading,
    confirmDialog: confirmDialog,
    onRemove: onRemove,
  });
};
