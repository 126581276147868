import { Box } from "@mui/material";
import { FlowRadio } from "../FlowRadio";
import { ModalPopup } from "views/molecules";

/**
 * 処理フローを選択するの表示を行うコンテナプレゼンテーションです
 * @param {object} value 処理フローの値
 * @returns
 */
export const Presententer = (props) => {
  return (
    <ModalPopup open={props.open} onClose={props.onClose}>
      <Box sx={{ bgcolor: "#fff", p: 3, maxHeight: "450px", overflow: "auto" }}>
        <FlowRadio display={true} option={props.value} />
      </Box>
    </ModalPopup>
  );
};
