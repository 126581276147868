import React from "react";
import { VehicleSelector } from "views/molecules";

/**
 * 車輛マスタの画面の表示を行うプレゼンテーションコンポーネントです。
 * @param {array} dataTable テーブルのヘダー
 * @param {func} onTransite　クリックされた車輛が情報を取得するイベント
 * @param {func} submit 車輛追加フォームをサブミットする
 * @param {func} onSubmitted 車輛追加フォームの値を取得します。
 * @returns JSX　要素
 */
export const Presententer = (props) => <VehicleSelector {...props} />;
