import { Box, Button, Typography } from "@mui/material";
import { FullScreenDialogMobile } from "views/molecules";
import { FlowDetailModal, FlowRadioGroup } from "./Child";

/**
 * 処理フローを選択するの表示を行うコンテナプレゼンテーションです
 * @param {object} classes クラス名
 * @param {boolean} open　ダイヤログを無効か有効にする
 * @param {func} onClose　ダイヤログを閉じる
 * @returns
 */
export const Presententer = (props) => {
  return (
    <div>
      <FullScreenDialogMobile
        open={props.open}
        onClose={props.onClose}
        showAppbar={true}
        avatarLabel={props.wasteType}
        title={props.wasteType}
      >
        <Box>
          <Box pt={2}>
            <Typography align="center" sx={{ mb: 1 }}>
              処理フローを選択してください。
            </Typography>
          </Box>
          <Box height={props.screenHeight - 190}>
            <FlowRadioGroup
              value={props.selected}
              options={props.options}
              onChange={props.onChangeFlow}
              onClickDetails={props.onClickDetails}
            />
          </Box>
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              top: "auto",
              width: "100%",
              left: 0,
              right: "auto",
              p: 2,
              pb: 4,
              zIndex: 1,
              backgroundColor: "#fff",
            }}
          >
            <Button
              fullWidth
              variant="contained"
              onClick={props.onConfirm}
              disabled={!props.selected}
            >
              OK
            </Button>
          </Box>
        </Box>
      </FullScreenDialogMobile>
      <FlowDetailModal
        open={props.openDetails}
        onClose={props.onCloseDetails}
        value={props.selectedDetails}
      />
    </div>
  );
};
