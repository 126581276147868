import { API, graphqlOperation } from "utils/graphqlOperation";
import { getWaste, getClient } from "api/graphql/queries";
import { useState, useEffect, useCallback } from "react";
import { toggle } from "ducks/Loading";
import { useDispatch } from "react-redux";
import { debugLog } from "utils/log";
import { useQuery } from "utils/useQuery";
import { useNavigate, useParams } from "react-router-dom";

/**
 * 取引先詳細ページでの廃棄物詳細の画面を表示するコンテナコンポーネントです。
 * @param {props} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const [value, setValue] = useState(null);
  const { id, wasteId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const client = useQuery({
    query: getClient,
    variables: {
      id: id,
    },
    onError: () => {
      navigate(
        {
          pathname: `/NotFound`,
        },
        {
          replace: true,
        }
      );
    },
  });

  const load = useCallback(() => {
    dispatch(toggle(true));
    API.graphql(
      graphqlOperation(getWaste, {
        id: wasteId,
      })
    )
      .then((res) => {
        setValue(res.data.getWaste);
      })
      .catch((err) => {
        debugLog("処理フロー取得に失敗: ", err);
      })
      .finally(() => {
        dispatch(toggle(false));
      });
  }, [dispatch, wasteId]);

  useEffect(() => {
    load();
  }, [load]);

  return render({
    value: value,
    id: id,
    wasteId: wasteId,
    load: load,
    client: client?.getClient?.data,
    ...props,
  });
};
