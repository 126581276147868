export const styles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& input, & select": {
      boxSizing: "content-box",
    },
    "& .modal_container": {
      "&:focus-visible": {
        outline: "none",
      },
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "transparent",
      },
      backgroundColor: theme.palette.background.paper,
    },
  },
});
