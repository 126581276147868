import * as yup from "yup";
export const Validations = yup.object().shape({
  // waste: yup.object().shape({
  //   id: yup.string().required(),
  // }),
  // sections: yup.array().of(
  //   yup.object().shape({
  //     transportTrustee: yup.object().required().nullable(),
  //     transportationMethod: yup.string().required(),
  //     destination: yup.object().required().nullable(),
  //   })
  // ),
  // disposaloffice: yup.string().required(),
  // disposalMethod: yup.string().required(),
});
