import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React from "react";

/**
 * 数量を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <FormControl
    sx={{ m: 1, minWidth: 200 }}
    disabled={props.disabled}
    error={props.error}
  >
    <InputLabel htmlFor="grouped-select">{props.label}</InputLabel>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <TextField
        id="quantity"
        variant="outlined"
        value={props.value?.value ?? ""}
        onChange={props.onChangeQuantity}
        error={props.error}
      />
      <Select
        id="grouped-select"
        value={props.value?.unit?.code ?? ""}
        label={props.label}
        onChange={props.onChangeUnit}
        multiple={false}
      >
        <MenuItem value="">
          <em>未選択</em>
        </MenuItem>
        {props.Units.map((unit, index) => (
          <MenuItem value={unit.code} key={index}>
            {unit.name}
          </MenuItem>
        ))}
      </Select>
    </div>
    <FormHelperText>{props.helperText}</FormHelperText>
  </FormControl>
);
