import { Grid, FormLabel, Typography } from "@mui/material";
import { Controller, useWatch, useFormState } from "react-hook-form";
import { isEmpty } from "lodash";
import { InputField } from "../InputField";
import { RadioGroup } from "views/atoms";
import { PostCodeInputField } from "../PostCodeInputField";
import { SelectPopup } from "../SelectPopup";
import { GoogleMapLocationPicker } from "views/organisms/GoogleMapLocationPicker";

/**
 * 住所を入力するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  const mapInputType = useWatch({
    control: props.control,
    name: `${props?.suffix}inputType`,
  });

  const { errors } = useFormState({
    control: props.control,
  });

  const isMapInputMissing =
    !isEmpty(errors) &&
    (errors[`${props?.suffix ?? ""}postalCode`] ||
      errors[`${props?.suffix}prefectures`] ||
      errors[`${props?.suffix}city`]);
  
  const isMapInput = mapInputType === "from-map";

  return (
    <>
      {mapInputType && (
        <Grid item xs={12}>
          <FormLabel className={props.classes.label}>住所入力方法：</FormLabel>
          <br />
          <Controller
            name={`${props?.suffix}inputType`}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <RadioGroup
                row
                required={false}
                value={value}
                options={props.addressInputType}
                onChange={(event) => {
                  props.resetAddress(event);
                  onChange(event);
                }}
                error={error}
              />
            )}
          />
        </Grid>
      )}
      {isMapInput && (
        <Grid item xs={12}>
          <GoogleMapLocationPicker
            setValue={props.setValue}
            suffix={props.suffix}
            clearErrors={props.clearErrors}
            control={props.control}
          />
          {isMapInputMissing && (
            <>
              <Typography fontSize={13} mx={2} color="red">
                地図から住所を選択してください。
              </Typography>
            </>
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <Controller
          name={`${props?.suffix ?? ""}postalCode`}
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <PostCodeInputField
              label="郵便番号"
              required={true}
              placeholder="例）000-0000"
              value={value}
              onGetFullAddress={props.onGetFullAddress}
              onChange={onChange}
              error={error}
              disabled={isMapInput}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`${props?.suffix}prefectures`}
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <SelectPopup
                label="都道府県"
                required={true}
                value={value}
                onChange={onChange}
                error={error}
                options={props.Prefectures}
                optionValueField="code"
                optionNameField="name"
                disabled={isMapInput}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`${props?.suffix}city`}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="市区町村"
              required={true}
              placeholder="例）北九州市若松区"
              value={value}
              onChange={onChange}
              error={error}
              InputProps={{
                readOnly: isMapInput,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`${props?.suffix}streetAddress`}
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="町域"
              required={true}
              placeholder="例）中川町"
              value={value}
              onChange={onChange}
              error={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`${props?.suffix}otherAddress`}
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="町域以降"
              required={true}
              placeholder="例）3-2　建物名"
              value={value}
              onChange={onChange}
              error={error}
            />
          )}
        />
      </Grid>
    </>
  );
};
