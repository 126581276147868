import React, { useRef, useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { Box, Grid, Typography, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import PropTypes from "prop-types";

import { CarryWorkJournalListItem } from "views/organisms/Allocation";
import {
  automationsSliceSelector,
  addNewCourse,
  deleteCourseThunk,
  updateCoursesOrder,
  updateCourseOrderThunk,
  removeNewCourse,
} from "ducks/Automation";
import { ConfirmDialog } from "views/molecules";
import { add as addAlert } from "ducks/Alert";
import { useOpener } from "utils/useOpener";

const grid = 8;

const getCourseItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 1,
  margin: `0 0 ${grid}px 0`,
  borderRadius: "3px",
  background: isDragging ? "#e8f5ff" : "none",
  ...draggableStyle,
});

const getCoursesListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#EBECF0" : "none",
  padding: "5px 10px",
});

export const CourseList = ({ onSelectCourse, setResetPage }) => {
  const [error, setError] = useState("");
  const [draggingStartHeight, setDraggingStartHeight] = useState(0);
  const [isSequenceChanged, setIsSequenceChanged] = useState(false);
  const [isUpdatingCourses, setIsUpdatingCourses] = useState(false);
  const [deletingCourseId, setDeletingCourseId] = useState(null);

  const { data, isLoading, isDeleting } = useSelector(automationsSliceSelector);
  const [courses, setCourses] = useState(data?.done?.courses || []);
  const dispatch = useDispatch();
  const errorRef = useRef(null);
  const dropableContainer = useRef(null);
  const deleteConfirmation = useOpener();

  useEffect(() => {
    if (courses?.length) {
      const isDifferent = courses.some(
        (item, index) => item.id !== data?.done?.courses[index]?.id
      );
      setIsSequenceChanged(isDifferent);
    }
  }, [courses, data?.done?.courses]);

  const onDragEnd = (result) => {
    setDraggingStartHeight(0);
    const {
      destination: { index: destinationIndex },
      source: { index: sourceIndex },
    } = result;
    if (destinationIndex === sourceIndex) {
      return;
    }

    const courseItems = [...courses];
    const course = courseItems.splice(sourceIndex, 1)[0];
    courseItems.splice(destinationIndex, 0, course);
    setCourses(courseItems);
    setError("");
  };

  const handleDeleteCourse = (courseId) => {
    const params = {
      id: courseId,
      courseType: "virtual",
    };
    const course = data?.done?.courses?.find(({ id }) => id === courseId);
    if (course?.isNewCourse) {
      dispatch(removeNewCourse(courseId));
      dispatch(
        addAlert({
          value: "コースを削除しました。",
          severity: "success",
        })
      );
      return;
    }
    dispatch(deleteCourseThunk(params)).then((response) => {
      if (response.type === "data/deleteCourse/fulfilled") {
        dispatch(
          addAlert({
            value: "コースを削除しました。",
            severity: "success",
          })
        );
        setResetPage((prevState) => !prevState);
        setCourses((prev) => prev.filter((item) => item.id !== courseId));
        deleteConfirmation.toggle(false);
      }
      if (response.type === "data/deleteCourse/rejected") {
        dispatch(
          addAlert({
            value: "エラーが発生したため、コースを削除できませんでした。",
            severity: "error",
          })
        );
      }
    });
  };

  const handleSelectCourse = (course) => {
    if (isSequenceChanged) {
      errorRef?.current?.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        setError(
          "別のシーケンスを追加する前に、シーケンスを保存または破棄してください。"
        );
      }, 500);
      return;
    }
    onSelectCourse(course);
  };

  const handleAddNewCourse = () => {
    const tempCourseId = uuid();
    const newCourse = {
      id: tempCourseId,
      assignedUsers: [],
      points: [],
      name: "新しい配車リスト",
      departureTime: DateTime.now().toISOTime(),
      assignedVehicle: null,
      isNewCourse: true,
    };
    dispatch(addNewCourse(newCourse));
    onSelectCourse(newCourse);
  };

  const handleUpdateCoursesOrder = () => {
    setIsUpdatingCourses(true);
    const params = {
      courses: courses?.map((item) => {
        return {
          id: item.id,
        };
      }),
    };

    dispatch(updateCourseOrderThunk(params)).then((response) => {
      if (response.type === "data/updateCourseOrder/fulfilled") {
        dispatch(updateCoursesOrder(response.payload));
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
      }
      if (response.type === "data/updateCourseOrder/rejected") {
        dispatch(
          addAlert({
            value: "エラーが発生したため、コースを削除できませんでした。",
            severity: "error",
          })
        );
      }
    });
    setIsUpdatingCourses(false);
  };

  const handleSequenceChangeCancel = () => {
    setCourses(data?.done?.courses);
    setError("");
  };

  const onBeforeDragStart = () => {
    const offsetHeight = dropableContainer.current.offsetHeight;
    setDraggingStartHeight(offsetHeight);
  };

  const handleDeleteCancel = () => {
    setDeletingCourseId(null);
    deleteConfirmation.toggle(false);
  };

  return (
    <Box boxShadow={3} p={3} borderRadius={1} marginBottom={2}>
      <DragDropContext
        onDragEnd={onDragEnd}
        onBeforeDragStart={onBeforeDragStart}
      >
        {courses?.length > 0 && (
          <Grid container justifyContent="end" spacing={2}>
            <Grid
              item
              xs={3}
              sx={{
                marginRight: 2,
              }}
              textAlign="center"
            >
              <Typography fontWeight="bold">配⾞数</Typography>
            </Grid>
          </Grid>
        )}
        {data?.done && (
          <Box flexGrow={1} ref={dropableContainer}>
            {courses?.length > 0 && (
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      ...getCoursesListStyle(snapshot.isDraggingOver),
                      height: draggingStartHeight
                        ? draggingStartHeight - 50
                        : "auto",
                    }}
                  >
                    {courses?.map((course, i) => (
                      <Draggable
                        key={course.id}
                        draggableId={course.id}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getCourseItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            onClick={() => handleSelectCourse(course)}
                            key={i}
                          >
                            <Grid
                              container
                              spacing={2}
                              justifyContent="flex-start"
                            >
                              <Grid item flex="1 0 83%">
                                <CarryWorkJournalListItem
                                  name={course.name}
                                  assignedUsers={course.assignedUsers}
                                  assignedVehicle={course.assignedVehicle}
                                  clickDisabled
                                />
                              </Grid>
                              <Grid
                                item
                                flex="0 0 10%"
                                justifyContent="center"
                                display="flex"
                                alignItems="center"
                              >
                                <Typography fontSize={15} fontWeight={600}>
                                  {course?.points?.length ||
                                    course.pointTotal ||
                                    0}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                flex="0 0 7%"
                                justifyContent="center"
                                display="flex"
                                alignItems="center"
                                sx={{
                                  cursor: "pointer",
                                }}
                              >
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDeletingCourseId(course.id);
                                    deleteConfirmation.toggle(true);
                                  }}
                                  aria-label="delete"
                                  disabled={isLoading || isDeleting}
                                >
                                  <DeleteIcon color="error" />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            )}
            <Grid
              item
              width="90%"
              mt={2}
              display="flex"
              justifyContent="space-between"
            >
              <Stack display="flex" flexDirection="row">
                <LoadingButton
                  sx={{
                    marginLeft: 3,
                  }}
                  loading={isUpdatingCourses}
                  variant="contained"
                  onClick={handleUpdateCoursesOrder}
                  disabled={!isSequenceChanged || isUpdatingCourses}
                >
                  更新(並び順)
                </LoadingButton>
                <LoadingButton
                  sx={{
                    marginLeft: 3,
                  }}
                  loading={isUpdatingCourses}
                  variant="outlined"
                  color="error"
                  onClick={handleSequenceChangeCancel}
                  disabled={!isSequenceChanged || isUpdatingCourses}
                >
                  取消
                </LoadingButton>
              </Stack>
              <LoadingButton
                onClick={handleAddNewCourse}
                variant="outlined"
                disabled={isUpdatingCourses || isSequenceChanged}
              >
                ＋ 新しい配車リストを追加する
              </LoadingButton>
            </Grid>
            <Box ref={errorRef}>
              {error && (
                <Typography mt={1} ml={3} color="error" fontSize={12}>
                  {error}
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </DragDropContext>
      <ConfirmDialog
        title="削除確認"
        message="このコースを削除すると、それに関連付けられたすべてのポイントと収集サイクルが未割り当てのセクションに移動されます。この操作は元に戻せません。続行してもよろしいですか?"
        open={deleteConfirmation.open}
        positiveText="はい"
        negativeText="いいえ"
        onPositive={() => handleDeleteCourse(deletingCourseId)}
        disabled={isDeleting}
        onNegative={handleDeleteCancel}
        showProgress={isDeleting}
      ></ConfirmDialog>
    </Box>
  );
};

CourseList.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object),
  onSelectCourse: PropTypes.func,
  onCourseSelect: PropTypes.func,
};
