import { searchRegularlyWasteCollectionsOrdersByOffset } from "api/graphql/queries";
import { deleteRegularWasteCollectionOrders } from "api/graphql/mutations";
import { add as addAlert } from "ducks/Alert";
import { DateTime } from "luxon";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { debugLog } from "utils/log";
import { useXGridComponents } from "utils/useXGridComponents";
import { getText as getCycleCategoryText } from "../CycleCategory";
import { getGridStringOperators } from "@mui/x-data-grid-pro";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import { useOpener } from "utils/useOpener";

const columns = [
  {
    field: "wasteGeneratorCompanyName",
    headerName: "排出者",
    minWidth: 150,
    flex: 1,
    valueGetter: (params) => params.row?.wasteGeneratorCompany?.name,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "wasteCollectionWorkplaceName",
    headerName: "排出事業場",
    minWidth: 150,
    flex: 1,
    valueGetter: (params) => params.row.wasteCollectionWorkplace?.name,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "wasteTypeLargeClassName",
    headerName: "廃棄物種類大分類",
    minWidth: 200,
    flex: 1,
    valueGetter: (params) => params.row?.waste?.type?.largeClass?.name,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "wasteTypeMiddleClassName",
    headerName: "廃棄物種類中分類",
    minWidth: 200,
    flex: 1,
    valueGetter: (params) => params.row?.waste?.type?.middleClass?.name,
    hide: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "wasteTypeSmallClassName",
    headerName: "廃棄物種類小分類",
    minWidth: 200,
    flex: 1,
    valueGetter: (params) => params.row?.waste?.type?.smallClass?.name,
    hide: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "wasteName",
    headerName: "廃棄物",
    minWidth: 150,
    flex: 1,
    valueGetter: (params) => params.row?.waste?.name,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "cycleCategoryName",
    headerName: "回収頻度",
    minWidth: 170,
    flex: 1,
    valueGetter: (params) => getCycleCategoryText(params.row),
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
    filterable: false,
  },
  {
    field: "cycleStartDate",
    headerName: "回収開始日",
    minWidth: 170,
    flex: 1,
    valueFormatter: (params) => {
      return params?.value
        ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd")
        : "";
    },
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "cycleEndDate",
    headerName: "回収終了日",
    minWidth: 170,
    flex: 1,
    valueFormatter: (params) => {
      return params?.value
        ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd")
        : "";
    },
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
];

/**
 * 定期回収一覧を表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @fires Container#onSelected 選択されたとき
 * @param {Promise} submit 送信時
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onSelected = (data) => debugLog(data),
  submit = (data) => Promise.resolve(data),
  initialState,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedRows, setSelctedRows] = useState([]);
  const deleteConfirmation = useOpener();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const cols = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 60,
    },
    ...columns,
  ];

  const xGridObject = useXGridComponents(
    cols,
    searchRegularlyWasteCollectionsOrdersByOffset,
    null,
    initialState
  );
  const formRef = useRef(null);
  const handeChangeSelection = (rows) => {
    setSelctedRows(rows);
  };

  const handleSubmit = (data) => {
    setIsSubmit(true);
    submit(data)
      .then((result) => {
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        xGridObject.functions.refetch();
        setOpen(false);
      })
      .catch((err) => {
        debugLog("Order.Spot.submit.error: ", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleConfirm = () => {
    formRef.current.submit();
  };

  const handleActionClick = (action) => {
    if (action === "delete") {
      deleteConfirmation.toggle(true);
    }
  };

  const deleteRecords = async (ids) => {
    return await API.graphql(
      graphqlOperation(deleteRegularWasteCollectionOrders, {
        input: {
          collectionCycleIds: ids,
        },
      })
    );
  };

  const onDelete = () => {
    setIsSubmit(true);
    deleteRecords(selectedRows)
      .then((response) => {
        const deletedCycles = response.data.deleteRegularWasteCollectionOrders;
        dispatch(
          addAlert({
            value: "削除しました。",
            severity: "success",
          })
        );

        const rows = xGridObject.params.rows.filter(
          (item) => !deletedCycles.includes(item.cycleId)
        );
        xGridObject.functions.setRows({ rows });
        deleteConfirmation.toggle(false);
      })
      .catch(() => {
        dispatch(
          addAlert({
            value: "エラーが発生したため、削除できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return render({
    open: open,
    onCloseDialog: () => setOpen(false),
    onOpenDialog: () => setOpen(true),
    onSubmit: handleSubmit,
    value: value,
    onChangeSelection: handeChangeSelection,
    isSubmit: isSubmit,
    xGridParams: xGridObject.params,
    formRef: formRef,
    onConfirm: handleConfirm,
    navigate: navigate,
    selectedRows: selectedRows,
    handleActionClick: handleActionClick,
    deleteConfirmation: deleteConfirmation,
    onDelete: onDelete,
    handlePopoverOpen: handlePopoverOpen,
    handlePopoverClose: handlePopoverClose,
    anchorEl: anchorEl,
    ...props,
  });
};
