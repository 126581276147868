import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import useSize from "utils/useSize";

/**
 * パスワードを入力するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.label ラベル
 * @param {string} props.value 値
 * @param {boolean} props.fullWidth 幅を広げるかどうか
 * @fires PasswordTextField#onChange 変更時
 * @returns {JSX.Element}
 */
const PasswordTextField = ({
  value,
  onChange = () => {},
  fullWidth = false,
  error = false,
  helperText = "",
  inputRef,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { isMobileSize } = useSize();

  return (
    <TextField
      value={value}
      onChange={onChange}
      id="password-textbox"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(event) => {
                event.preventDefault();
              }}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={showPassword ? "text" : "password"}
      variant={isMobileSize === true ? "standard" : "outlined"}
      error={error}
      helperText={helperText}
      fullWidth={fullWidth}
      inputRef={inputRef}
    />
  );
};

export default PasswordTextField;
