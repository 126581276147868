import { Stepper as MuiStepper, Step, Box, StepLabel } from "@mui/material";
import React from "react";

/*
  {
    stepProps: {},
    labelProps: {},
    content: <></>
  }
*/

/**
 * ステッパーを表示するコンポーネント。
 * @param {object} props プロパティ
 * @param {number} props.activeStep アクティブなステップ
 * @param {array} props.options 個ステップコンポーネント
 * @param {object} props.options.stepProps ステッププロパティ
 * @param {object} props.options.labelProps ラベルプロパティ
 * @param {JSX.Element} props.options.content ステップのコンポーネント
 * @returns {JSX.Element}
 */
export const Stepper = ({ activeStep = 0, options = [] }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <MuiStepper
        activeStep={activeStep}
        sx={{
          pb: 2,
        }}
      >
        {options.map((option, index) => (
          <Step key={index} {...option?.stepProps}>
            <StepLabel {...option?.labelProps}>{option?.label ?? ""}</StepLabel>
          </Step>
        ))}
      </MuiStepper>
      {options?.[activeStep]?.content}
    </Box>
  );
};
