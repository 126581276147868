import { makeStyles } from '@mui/styles';

export const stylesCreator = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    marginTop: theme.spacing(12),
  },
  appBar: {
  },
  appBarSpacer: theme.mixins.toolbar,
  main_main_container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}));
