import { Close } from "@mui/icons-material";
import {
  AppBar,
  Container,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { forwardRef } from "react";
import { RenderAvatar } from "utils/XgridExpand";

const TRANSITION = forwardRef((props, ref) => {
  return <Slide direction="left" ref={ref} {...props} />;
});
export const Presententer = (props) => {
  return (
    <Dialog
      fullScreen
      open={props?.open}
      TransitionComponent={TRANSITION}
      className={props.classes.dialogPaperStyles}
      disableEnforceFocus
    >
      {props.showAppbar && (
        <>
          <AppBar
            position="fixed"
            color="primary"
            className={props.classes.appBar}
            elevation={0}
          >
            <Toolbar>
              {props.avatarLabel && <RenderAvatar label={props.avatarLabel} />}
              <Typography ml={1} variant="h6" className={props?.classes.title}>
                {props?.title}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={props?.onClose}
                aria-label="close"
              >
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={props.classes.appBarSpacer} />
        </>
      )}

      <Container className={"main_main_container"}>{props.children}</Container>
    </Dialog>
  );
};
