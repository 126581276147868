import { useMutation } from "utils/useMutation";
import { useOpener } from "utils/useOpener";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { userSelector } from "ducks/User";
import { useSelector } from "react-redux";
import { createUserAccount } from "api/graphql/mutations";

/**
 * メンバー管理一覧を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const currentUser = useSelector(userSelector);
  const navigate = useNavigate();
  const [customErrors, setCustomErrors] = useState(null);
  const form = useOpener({
    onChange: () => {
      setCustomErrors(null);
    },
  });
  const tableRef = useRef(null);

  if (currentUser?.roleName === "一般ユーザー") {
    navigate({
      pathname: "/",
    });
  }

  const [createMutation, createMutationState] = useMutation(createUserAccount, {
    onBeforeExecute: () => {
      setCustomErrors(null);
    },
    onCompleted: (data) => {
      tableRef.current.refetch();
      form.toggle(false);
    },
    onError: ({ errors }) => {
      for (let error of errors) {
        if (error.errorType === "UsernameExistsException") {
          setCustomErrors({
            email: {
              message: "入力されたメールアドレスは既に登録されています。",
            },
          });
        }
      }
    },
    succeedMessage: "登録しました。",
    errorMessage: "エラーが発生したため、登録に失敗しました。",
  });

  const handleCreate = (value) => {
    createMutation({
      input: { ...value },
    });
  };

  const handleSelected = (params) => {
    navigate(`/members/${params[0]}`);
  };

  return render({
    ...props,
    form: form,
    loading: createMutationState.loading,
    onCreate: handleCreate,
    onSelected: handleSelected,
    tableRef: tableRef,
    customErrors: customErrors,
  });
};
