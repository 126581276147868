import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { debugLog } from "utils/log";
import { useStyles } from "./styles";

/*
  object shape
    id
    name
    departureDate
    departureTime
    assignedVehicle
    assignedUsers
    items
*/

/**
 * モバイルの回収一覧を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {Promise} search 検索処理
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  search = (date) => Promise.resolve(date),
  ...props
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [journals, setJournals] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    search(date)
      .then((res) => {
        setJournals(res.items);
      })
      .catch((err) => {
        debugLog("モバイル回収一覧データ取得失敗: ", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [search, date]);

  const handleStartCollect = (id) => {
    navigate(`/mobile-collect/${id}`);
  };

  return render({
    classes: classes,
    date: DateTime.fromJSDate(date)
      .setLocale("ja")
      .toFormat("yyyy/MM/dd (ccc)"),
    journals: journals,
    isLoading: isLoading,
    onStartCollect: handleStartCollect,
    onBeforeDate: () =>
      setDate(DateTime.fromJSDate(date).minus({ days: 1 }).toJSDate()),
    onNextDate: () =>
      setDate(DateTime.fromJSDate(date).plus({ days: 1 }).toJSDate()),
    ...props,
  });
};
