import { requiredField, style_inputField } from "utils/styles";

export const styles = ((theme) => ({
  ...style_inputField(theme),
  emptyValue: {
    "& .MuiSelect-root": {
      color: theme.palette.text.disabled,
      fontSize: "14px",
    },
  },
  label: {
    color: theme.palette.text.secondary,
  },
  required: {
    ...requiredField(theme),
  },
  popupMultiple: {},
  placeholderStyle: { fontSize: "14px", color: theme.palette.grey[400] },
  hideLabel: {
    display: "none"
  },
}));
