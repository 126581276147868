import { Box, Container, Grid, Paper } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import {
  ClientWorkplaceSelector,
  DatePicker,
  FieldDecorateFrame,
  InputField,
  InputFieldPopppSelect,
  ModalPopup,
  WasteSelector,
} from "views/molecules";
import { QuantityField } from "../QuantityField";
import { WasteFlowSelector } from "views/molecules";

/**
 * 紙マニフェスト簡易入力を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Elemnt}
 */
export const Presententer = (props) => {
  return (
    <>
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ p: 6 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name={"dischargesWorkplace"}
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <InputFieldPopppSelect
                      label={"排出事業場"}
                      value={value}
                      onChange={onChange}
                      required={true}
                      placeholder={"排出事業場を選択します。"}
                      error={error}
                      onOpenModal={props.onOpenDialog("workplace")}
                      textConfirm="選択"
                    />
                    <ModalPopup
                      open={props.open === "workplace"}
                      onClose={props.onCloseDialog}
                    >
                      <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                        <ClientWorkplaceSelector
                          autoHeight={false}
                          value={props.selecedValue}
                          onSelected={props.onSelectedClientWorkplace}
                          fixedOptions={{
                            filter: {
                              and: [
                                {
                                  categoryId: {
                                    eq: "dischargeSite",
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      </Box>
                    </ModalPopup>
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"wastes"}
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <InputFieldPopppSelect
                      label={"廃棄物"}
                      value={
                        value
                          ? {
                              id: value.id,
                              name:
                                value.type?.name ||
                                value.type?.smallClass?.name ||
                                value.type?.middleClass?.name ||
                                value.type?.largeClass?.name ||
                                "",
                            }
                          : ""
                      }
                      onChange={onChange}
                      required={true}
                      placeholder={"事業場から排出される廃棄物を選択します。"}
                      error={error}
                      onOpenModal={props.onOpenDialog("wastes")}
                      textConfirm="選択"
                      disabledButtom={!props?.value?.dischargesWorkplace?.id}
                    />
                    <ModalPopup
                      open={props.open === "wastes"}
                      onClose={props.onCloseDialog}
                    >
                      <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                        <WasteSelector
                          dischargeSourceWorkplaceId={
                            props?.value?.dischargesWorkplace?.id
                          }
                          onSelected={props.onSelectedWastes}
                          value={value}
                        />
                      </Box>
                    </ModalPopup>
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"flow"}
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <InputFieldPopppSelect
                      label={"処理フロー"}
                      value={value}
                      onChange={onChange}
                      required={true}
                      placeholder={
                        value === null ? "処理フローを選択します。" : "選択済み"
                      }
                      error={error}
                      onOpenModal={props.onOpenDialog("flow")}
                      textConfirm="選択"
                      disabledButtom={!props?.value?.wastes}
                    />
                    <ModalPopup
                      open={props.open === "flow"}
                      onClose={props.onCloseDialog}
                    >
                      <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                        <WasteFlowSelector
                          value={props.value}
                          multiple={false}
                          checkbox={false}
                          onSelected={props.onWasteFlowSelected}
                          managedCompanyId={props?.company?.id}
                          fixedFilter={{
                            and: [
                              {
                                managementWasteId: {
                                  eq: props?.value?.wastes?.id,
                                },
                              },
                            ],
                          }}
                        />
                      </Box>
                    </ModalPopup>
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="issueNumber"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    label="交付番号"
                    required={true}
                    placeholder="例）01234567890"
                    value={value}
                    onChange={onChange}
                    error={error}
                    inputProps={{
                      maxLength: 11,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="issueDate"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <DatePicker
                      label="交付日"
                      placeholder="例）2021/06/06"
                      value={value}
                      onChange={onChange}
                      error={error}
                      required={false}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="quantity"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <FieldDecorateFrame label="数量">
                      {(params) => (
                        <QuantityField
                          value={value}
                          onChange={onChange}
                          error={Boolean(error)}
                          helperText={
                            error?.message ||
                            error?.value?.message ||
                            error?.unit?.message
                          }
                          disabledUnit={true}
                        />
                      )}
                    </FieldDecorateFrame>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="remarks"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    label="備考・通信欄"
                    multiline
                    rows={4}
                    value={value}
                    onChange={onChange}
                    placeholder="特記事項があれば入力します。"
                    required={false}
                    error={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="endOfTransportationDate"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <DatePicker
                      label="運搬終了年月日"
                      placeholder="例）2021/06/06"
                      value={value}
                      onChange={onChange}
                      error={error}
                      required={false}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="wasteCarrierPersonInCharge"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <InputField
                      label="運搬担当者の氏名"
                      value={value}
                      onChange={onChange}
                      placeholder="運搬担当者の氏名を入力します。"
                      required={false}
                      error={error}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="valuables"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <FieldDecorateFrame label="有価物収拾量">
                      {(params) => (
                        <QuantityField
                          value={value}
                          onChange={onChange}
                          error={Boolean(error)}
                          helperText={
                            error?.message ||
                            error?.value?.message ||
                            error?.unit?.message
                          }
                        />
                      )}
                    </FieldDecorateFrame>
                  );
                }}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Controller
                name="file"
                control={props.control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <>
                      <FieldDecorateFrame label="関連ファイル" required={true}>
                        {(params) => (
                          <FileUploader
                            maxSize={26214400}
                            onChange={onChange}
                          />
                        )}
                      </FieldDecorateFrame>
                      <div
                        style={{
                          display: "flex",
                          direction: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {value && (
                          <Thumbnail
                            base64={value?.context}
                            deletable={true}
                            onClick={props.onOpenFile}
                            onDelete={props.onDeleteFile}
                            title={value?.name}
                            width={200}
                          />
                        )}
                      </div>
                      <Dialog
                        open={Boolean(props.open)}
                        onClose={props.onCloseFile}
                      >
                        <Viewer
                          base64={value?.context}
                          fileName={value?.name}
                          download={true}
                          deletable={true}
                          onDelete={props.onDeleteFile}
                        />
                      </Dialog>
                    </>
                  );
                }}
              />
            </Grid> */}
          </Grid>
        </Paper>
      </Container>
    </>
  );
};
