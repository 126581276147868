import { useStyles } from "./styles";

/**
 * PDF・画像を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string} title タイトル
 * @fires Container#onClick クリック時
 * @fires Container#onDelete 削除時
 * @param {boolean} deletable 削除可能か
 * @param {string} base64 base64文字列
 * @param {boolean} download ダウンロード可能か
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  title = "",
  onClick = () => {},
  onDelete = () => {},
  deletable = false,
  base64,
  download = false,
  ...props
}) => {
  const classes = useStyles();

  /**
   * ファイル名を生成します。
   * @param {string} base64 base64文字列
   * @returns {string}
   */
  const createFileName = (base64) => {
    if (!title || !base64) {
      return "";
    }

    const extensions = getExtensions(base64);
    if (!extensions) {
      return "";
    }

    return `${title}.${extensions}`;
  };

  /**
   * 拡張子を取得します。
   * @param {string} base64 base64文字列
   * @returns {string | null}
   */
  const getExtensions = (base64) => {
    if (!base64) {
      return null;
    } else {
      return base64
        .toString()
        .slice(base64.indexOf("/") + 1, base64.indexOf(";"))
        .toLowerCase();
    }
  };

  /**
   * base64文字列がPDFか確認します。
   * @returns {boolean}
   */
  const isPDF = (base64) => {
    const result = getExtensions(base64);
    return result ? result === "pdf" : false;
  };

  /**
   * base64文字列が画像か確認します。
   * @returns {boolean}
   */
  const isImage = (base64) => {
    const result = getExtensions(base64);
    const imagesExtensions = [
      "apng",
      "avif",
      "gif",
      "jpeg",
      "png",
      "svg+xml",
      "webp",
    ];
    return result ? imagesExtensions.includes(result) : false;
  };

  return render({
    base64: base64,
    classes: classes,
    title: title,
    fileName: createFileName(base64),
    onClick: onClick,
    onDelete: onDelete,
    deletable: deletable,
    isPDF: isPDF(base64),
    isImage: isImage(base64),
    download: download,
    ...props,
  });
};
