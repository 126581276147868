import { useEffect, useState, useCallback } from "react";

import "./style.css";

const FIVEMINTOMILLISECOND = 1 * 60 * 1000;
const ONEMINTOMILLISECOND = 60 * 1000;

function convertDateTime(input) {
  const startDateTime = new Date(input.startDateTime);
  const endDateTime = new Date(input.endDateTime);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "narrow",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  };
  const formattedStartDate = startDateTime.toLocaleDateString("ja-JP", options);
  const formattedEndDate = endDateTime.toLocaleDateString("ja-JP", options);

  return `${formattedStartDate} - ${formattedEndDate}`;
}

export const MaintenanceMode = ({ url }) => {
  const [schedule, setSchedule] = useState(null);

  const reloadToHomePage = () => {
    window.location.reload();
  };

  const fetchSchedule = useCallback(async () => {
    try {
      const response = await fetch(url);
      if (response.status === 200) {
        const result = await response.json();
        if (result.maintenaceActive) {
          setSchedule(result);
          return;
        }
      }
      reloadToHomePage();
    } catch (err) {
      reloadToHomePage();
    }
  }, [url]);

  useEffect(() => {
    fetchSchedule();
    const timer = setInterval(fetchSchedule, FIVEMINTOMILLISECOND);
    return () => clearInterval(timer);
  }, [fetchSchedule]);

  useEffect(() => {
    if (schedule?.endDateTime) {
      const intervalId = setInterval(() => {
        const currentTime = new Date();
        const endDateTime = new Date(schedule?.endDateTime);
        if (currentTime >= endDateTime) {
          fetchSchedule();
        }
      }, ONEMINTOMILLISECOND);

      return () => clearInterval(intervalId);
    }
  }, [fetchSchedule, schedule?.endDateTime]);

  if (!schedule) {
    return null;
  }

  return (
    <div id="wrapper">
      <div id="container" className="maintenance-full-screen">
        <div className="maintenance-text-center">
          <h1 className="text-h1">ただいまシステムメンテナンス中です</h1>
          <div className="maintenance-text-center">
            <h3 className="text-h3">【メンテナンス日時】</h3>
            <div
              id="maintenanceScheduleRange"
              className="maintenance-text-large maintenance-is-danger"
            >
              {convertDateTime(schedule)}
            </div>
            <div className="maintenance-is-danger">
              ※メンテナンス終了時間は都合により変更する場合がございます。
            </div>
          </div>
          <p>
            ご利用の皆様にはご迷惑をおかけし、申し訳ございません。
            <br />
            メンテナンス終了までしばらくお待ちください。
          </p>
        </div>
      </div>
    </div>
  );
};
