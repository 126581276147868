import { setVisilityBeginnersMark } from "ducks/GuiderSteps/slice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { MasterGuiderSteps } from "utils/GuiderSteps";
import useClasses from "utils/useClasses";
import { styles } from "./styles";
import { toggle } from "ducks/Loading";
import { Monitoring } from "App";
import { useContext } from "react";

/**
 * マスタ管理画面を表示するコンテナコンポーネントです
 * @return {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isGeneralWastesPlan, loading, error } = useContext(Monitoring);

  useEffect(() => {
    dispatch(setVisilityBeginnersMark(MasterGuiderSteps?.length > 0));
  }, [dispatch]);

  const handleClick = (param) => {
    navigate(`${location.pathname}/${param}`);
  };

  dispatch(toggle(loading));

  return render({
    classes: classes,
    onClick: handleClick,
    GuiderSteps: MasterGuiderSteps,
    isGeneralWastesPlan: isGeneralWastesPlan,
    loading: loading,
    error: error,
    ...props,
  });
};
