import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 回収コースを表示するコンポーネントです。
 */
export const Courses = containerPresententer(Container, Presententer);

export const RenderColumnCourse =
  (onClickCourses) =>
  ({ data, index, style }) => {
    const item = {
      value: data.items[index],
      displayNumber: data.displayNumber,
    };
    return (
      <div style={style}>
        <Courses value={item.value} onClickCourses={onClickCourses} />
      </div>
    );
  };
