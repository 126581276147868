import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { DateTime } from "luxon";
import { debugLog } from "utils/log";
import { basicSchema } from "./Validations/basic";

const defaultValues = {
  transportationEndedOn: new Date(),
  transportPersonInCharge: "",
  reporter: "",
  quantity: undefined,
  valuablesQuantity: undefined,
  vehicleNumberListed: "",
  remarks: "",
};

/**
 * 運搬終了報告を行うコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onReport = (data) => debugLog("収集運搬終了報告成功: ", data),
  onEdit = (data) => debugLog("収集運搬終了報告修正: ", data),
  onCancel = (data) => debugLog("収集運搬終了報告取消: ", data),
  onError = (data) => debugLog("収集運搬終了報告失敗: ", data),
  requesting,
  ...props
}) => {
  debugLog("HaulersReportFormのvalue: ", value);
  const convert = useCallback(
    (report) => {
      return {
        ...defaultValues,
        ...report,
        transportationEndedOn:
          report?.transportationEndedOn &&
          DateTime.fromFormat(
            report?.transportationEndedOn,
            "yyyy-LL-dd"
          ).toJSDate(),
        vehicleNumber: report?.vehicleNumberListed,
        transportPersonInCharge: report?.personInChargeListed,
        sectionNumber: value?.sectionNumber,
        memberNumber:
          value?.carrier?.subcontractorMemberNumber === ""
            ? value?.carrier?.memberNumber
            : value?.carrier?.subcontractorMemberNumber,
        quantity: {
          value: report?.transportQuantity ?? null,
          unit: report?.transportWasteQuantityUnit ?? null,
        },
        valuablesQuantity: {
          value: report?.valuablesQuantity ?? null,
          unit: report?.valuablesQuantityUnit ?? null,
        },
        transportQuantity: undefined,
        transportWasteQuantityUnit: undefined,
        valuablesQuantityUnit: undefined,
        personInChargeListed: undefined,
        vehicleNumberListed: undefined,
      };
    },
    [value]
  );

  const methods = useForm({
    mode: "onBlur",
    defaultValues: convert(value?.report),
    resolver: yupResolver(basicSchema),
  });

  const { trigger, getValues, reset, formState, control } = methods;

  useEffect(() => {
    reset(convert(value?.report));
  }, [reset, value, convert]);

  const handleReport = () => {
    trigger().then((res) => {
      if (res) {
        onReport(getValues());
      } else {
        onError(formState.errors);
      }
    });
  };

  const handleEdit = () => {
    trigger().then((res) => {
      if (res) {
        onEdit(getValues());
      } else {
        onError(formState.errors);
      }
    });
  };

  const handleCancel = () => {
    onCancel(convert(value?.report));
  };

  return render({
    control: control,
    existValue: !!value?.report?.transportationEndedOn,
    onReport: handleReport,
    onEdit: handleEdit,
    onCancel: handleCancel,
    requesting: requesting,
    ...props,
  });
};
