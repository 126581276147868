import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 *　ポップアップセレクションでデータを選択するコンポーネントです。
 * @param {string} label セレクションのラベル
 * @param {boolean} hideLabel ラベルが隠れるようにする
 * @param {string} placeholder プレースホルダー
 * @param {boolean} required 必須かどうかを設定する
 * @param {object} value 選択されたアイテムです {code:"", name:""}
 * @param {func} onChange 選択するアイテムが更新するイベントです
 * @param {array} options アイテム配列 例）[{code: "", name:""}]
 * @param {string} error エラーが出る
 * @param {boolean} disabled 無効にする
 */
export const SelectPopup = containerPresententer(Container, Presententer);
