import yup, { dateSchema, remarksSchema, textSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  replacementContainer: yup.object().required(),
  storageWorkplace: yup.object().required(),
  shippedOn: dateSchema.required(),
  replacedOn: dateSchema.required(),
  storedOn: dateSchema.required(),
  vehicle: yup.object().nullable(),
  personInChargeName: textSchema.nullable(),
  remarks: remarksSchema.nullable(),
});
