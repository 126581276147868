import {
  Alert,
  Autocomplete,
  Skeleton,
  Stack,
  TextField,
  FormHelperText,
  FormControl,
  FormLabel,
} from "@mui/material";
import { listJapaneseLocalGovernments } from "api/graphql/queries";
import { useMemo } from "react";
import { debugLog } from "utils/log";
import { useQuery } from "utils/useQuery";
import { Mark } from "views/atoms";

/**
 * 地方公共団体を選択するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {boolean} props.disabled 無効設定
 * @param {boolean} props.required 必須設定
 * @fires LocalGovernmentComboBox#onChange 変更時
 * @returns JSX
 */
export const LocalGovernmentComboBox = ({
  value,
  disabled = false,
  required = true,
  error = false,
  helperText = null,
  onChange = (data) => debugLog(data),
}) => {
  const {
    data,
    error: apiError,
    loading,
  } = useQuery({
    query: listJapaneseLocalGovernments,
    variables: {
      wasteLicenseGrantor: "municipalSolidWaste",
    },
  });

  const prefectures = useMemo(() => {
    return data?.listJapaneseLocalGovernments?.items?.reduce(
      (previous, current) => {
        if (!previous.includes(current.prefectures.name)) {
          previous.push(current.prefectures.name);
        }

        return previous;
      },
      ["未選択"]
    );
  }, [data?.listJapaneseLocalGovernments?.items]);

  const municipalities = useMemo(() => {
    return (
      data?.listJapaneseLocalGovernments?.items
        ?.filter((item) => item.prefectures.name === value?.prefectures?.name)
        ?.map((item) => item.cityName) ?? []
    );
  }, [data?.listJapaneseLocalGovernments?.items, value?.prefectures?.name]);

  const handleChangePrefectures = (event, newValue) => {
    if (!newValue || newValue === "") {
      onChange(null);
      return;
    }

    onChange(
      data?.listJapaneseLocalGovernments?.items?.find(
        (item) => item.prefectures.name === newValue && item.cityName === ""
      )
    );
  };

  const handleChangeMunicipalities = (event, newValue) => {
    onChange(
      data?.listJapaneseLocalGovernments?.items?.find(
        (item) =>
          value.prefectures.name === item.prefectures.name &&
          item.cityName === (newValue ?? "")
      )
    );
  };

  if (loading === true) {
    return (
      <Stack spacing={1} direction={"row"}>
        <Skeleton variant="rectangular" width={210} height={50} />
        <Skeleton variant="rectangular" width={210} height={50} />
      </Stack>
    );
  }

  if (apiError) {
    return <Alert severity="error">{apiError}</Alert>;
  }

  return (
    <FormControl>
      <Stack spacing={2}>
        <Stack direction={"row"} spacing={1}>
          <FormLabel
            sx={{
              fontSize: "14px",
            }}
          >
            {"許可主体"}
          </FormLabel>
          <Mark text={"必須"} />
        </Stack>
        <Stack spacing={1} direction={"row"}>
          <Autocomplete
            disablePortal
            disabled={disabled}
            value={value?.prefectures?.name ?? ""}
            onChange={handleChangePrefectures}
            id="prefecturesName"
            options={prefectures ?? [""]}
            sx={{ width: 210 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="都道府県"
                required={required}
                error={error}
              />
            )}
          />
          <Autocomplete
            disablePortal
            disabled={disabled || !value?.prefectures?.name}
            value={value?.cityName ?? ""}
            onChange={handleChangeMunicipalities}
            id="municipalitiesName"
            options={municipalities ?? [""]}
            sx={{ width: 210 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="市区町村"
                required={required}
                error={error}
              />
            )}
          />
        </Stack>
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </Stack>
    </FormControl>
  );
};
