import { useEffect, useState } from "react";
/**
 * 配車計画フォームを表示するコンポーネントです。
 * @param {*} param0
 * @returns
 */
export const Container = ({
  render,
  open = false,
  onClose,
  value = "",
  label = "",
  getOnchange,
  ...props
}) => {
  const [newValue, setNewValue] = useState(value);
  useEffect(() => {
    if (value) {
      setNewValue(value);
    }
  }, [value]);
  const handleChangeTab = (e) => {
    setNewValue(e.target.value);
  };

  const handleConfirm = () => {
    getOnchange && getOnchange(newValue);
    onClose && onClose();
  };

  return render({
    open,
    onClose: onClose,

    onConfirm: handleConfirm,
    label: label,
    newValue: newValue,
    onChange: handleChangeTab,
    ...props,
  });
};
