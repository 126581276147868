import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  number: {
    textAlign: "center",
    border: " 2px solid",
    borderRadius: "20px",
    borderColor: theme.palette.text.disabled,
    color: theme.palette.text.disabled,
    padding: 0,
    minWidth: "40px",
    miHeight: "30px",
    marginRight: theme.spacing(1),
  },
  time: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    color: theme.palette.grey[400],
    "& svg": {
      fontSize: "18px",
      marginRight: "5px",
    },
  },
  collect_detail: {
    display: "flex",
    flexDirection: "column",
    height: "100svh",
    backgroundColor: theme.palette.background.default,
  },
  //ヘッダー
  collect_header: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  //コンテンツ
  collect_content: { flex: 1, padding: theme.spacing(5) },
  input_style_mobile: {
    backgroundColor: theme.palette.background.paper,
    "& input": {
      textAlign: "right",
      fontSize: "30px",
      fontWeight: 600,
      color: theme.palette.grey[700],
      paddingRight: theme.spacing(2),
    },
  },
  //フッター
  collect_switch: {
    textAlign: "center",
    border: "1px solid",
    borderColor: theme.palette.grey[800],
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: "4px",
    marginBottom: theme.spacing(2),
    "& .MuiTypography-root": {
      fontSize: "13px",
      fontWeight: "600",
    },
    "& .MuiFormControlLabel-root": {
      margin: 0,
    },
  },
  collect_footer: { padding: theme.spacing(5) },
}));
