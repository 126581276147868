import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  //wrapper: { display: "flex", flexDirection: "column" },
  //content: { flex: 1 },
  footer: {
    position: "fixed",
    bottom: "0",
    top: "auto",
    zIndex: "3",
    width: "100%",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  hiddenTabIndicator: { display: "none" },

  footer_tabs: {
    backgroundColor: theme.palette.text.hint,
    width: "100%",
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-around",
    },
    "& .tab": {
      padding: 0,
      minHeight: "58px",
      "& svg": {
        marginBottom: "0 !important",
      },
    },
  },
  tab_main_content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(10),
    "-webkit-overflow-scrolling": "touch",
    overflowY: "auto",
  },
}));
