import { useState } from "react";
import { styles } from "./styles";
import useClasses from "utils/useClasses";

/**
 * テキストフィルドの表示を行うコンテナコンポーネントです。
 * @callback render
 */
export const Container = ({ render, ...props }) => {
  const classes = useClasses(styles);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return render({
    classes: classes,
    showPassword: showPassword,
    onClickShowPassword: handleClickShowPassword,
    onMouseDownPassword: handleMouseDownPassword,
    ...props,
  });
};
