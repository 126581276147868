import { useState } from "react";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { styles } from "./styles";

/**
 * 車輛を表示・選択するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 車輛
 * @param {array} vehicles 選択できる車輛情報
 * @fires Container#onChange 変更時
 * @param {string} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value = null,
  vehicles = [],
  onChange = (data) => debugLog(data),
  disabledVehicles = [],
  ...props
}) => {
  const classes = useClasses(styles);
  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    setEdit(!edit);
  };

  const handleChange = (e) => {
    onChange(e.target.value);
    setEdit(false);
  };

  const handleClickAway = (e) => {
    setEdit(false);
    e.preventDefault();
    e.stopPropagation();
  };

  return render({
    classes: classes,
    onChange: handleChange,
    handleClickAway: handleClickAway,
    value: value,
    onEdit: handleEdit,
    edit: edit,
    vehicles: vehicles,
    disabledVehicles: disabledVehicles,
    ...props,
  });
};
