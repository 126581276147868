import { onCloseDialog } from "ducks/Dialog/slice";
import { actSearchCard } from "ducks/Schedules/slice";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";

/**
 * ドロワーの表示を行うコンテナコンポーネントです。
 * @callback render
 * @param {object} children ドロワーの内容
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({ render, children, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.Dialog);
  const handleGetValueSearch = (value) => {
    dispatch(actSearchCard({ value: value, searchFrom: "schedules" }));
  };
  return render({
    classes,
    children,
    onCloseDrawer: () => dispatch(onCloseDialog("DrawerOnRight")),
    open: !isOpen.some((v) => v === "DrawerOnRight"),
    onChangeSearch: handleGetValueSearch,
    ...props,
  });
};
