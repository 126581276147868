import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { v4 as uuid } from "uuid";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { useDispatch } from "react-redux";
import { DateTime } from "luxon";
import { isEqual, map, uniqBy } from "lodash";
import { add as addAlert } from "ducks/Alert";
import {
  updateLogisticsWorkAllocationCourse,
  updateLogisticsWorkAllocationCoursesOrder,
  deleteCourse,
} from "api/graphql/mutations";
import { searchWasteCollectionSchedulesByOffset } from "api/graphql/queries";

import { debugLog } from "utils/log";

const defaultValue = {
  done: [],
  still: [],
};

const grid = 8;
const limit = 50;
/**
 * The function `getCourseItemStyle` returns the style object for a draggable course item, with
 * different styles applied based on whether it is being dragged or not.
 * @param isDragging - A boolean value indicating whether the item is currently being dragged or not.
 * @param draggableStyle - An object that contains the styles for the draggable item.
 */
const getCourseItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: "3px",
  background: isDragging ? "#e8f5ff" : "none",
  ...draggableStyle,
});

/**
 * The getCoursesListStyle function returns a style object based on whether the list is being dragged
 * over or not.
 * @param isDraggingOver - isDraggingOver is a boolean value that indicates whether an item is
 * currently being dragged over the courses list.
 */
const getCoursesListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#EBECF0" : "none",
  padding: "5px 10px",
});

/**
 * The function `haveSameIds` checks if two arrays have the same set of ids.
 * @param {Array} arr1 - The `arr1` parameter is an array that contains objects. Each object in the array has
 * an `id` property.
 * @param {Array} arr2 - The parameter `arr2` is an array that represents a collection of objects. Each object
 * in the array has an `id` property.
 * @returns {Boolean} The function `haveSameIds` returns a boolean value indicating whether the two input arrays
 * `arr1` and `arr2` have the same set of ids.
 */
const haveSameIds = (arr1, arr2) => {
  const idsSet1 = new Set(map(arr1, "id"));
  const idsSet2 = new Set(map(arr2, "id"));
  return isEqual(idsSet1, idsSet2);
};

/**
 * 配車表の表示を行うコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object[]} value 回収スケジュール
 * @param {string} date 収集日
 * @param {func} setValue
 * @param {object} props
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  date,
  total,
  setValue,
  setTotal,
  ...props
}) => {
  const [openedCourseId, setOpenedCourseId] = useState(null);
  const [items, setItems] = useState(defaultValue);
  const [isSequenceChanged, setIsSequenceChanged] = useState(false);
  const [isUpdatingCourses, setIsUpdatingCourses] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fetchedCount, setFetchedCount] = useState(false);
  const [loadingUnAssigned, setLoadingUnAssigned] = useState(false);
  const [isPointsLoading, setPointsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [error, setError] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [draggingStartHeight, setDraggingStartHeight] = useState(0);
  const dispatch = useDispatch();
  const prevValueRef = useRef(value);
  const prevDateRef = useRef(date);
  const errorRef = useRef(null);
  const courseNameRef = useRef(null);
  const dropableContainer = useRef(null);

  useEffect(() => {
    if (
      value &&
      value !== prevValueRef.current &&
      date !== prevDateRef.current
    ) {
      setItems({
        ...value,
        still: value?.still || [],
      });
    } else if (value) {
      setItems((prevItem) => ({
        ...value,
        still:
          value?.still?.length && !prevItem.still.length
            ? value?.still
            : prevItem?.still,
      }));
      setFetchedCount((prevValue) =>
        !prevValue && value?.still?.length ? value?.still.length : prevValue
      );
    }
  }, [value, date]);

  useEffect(() => {
    if (items?.done?.courses?.length) {
      const isDifferent = items.done.courses.some(
        (item, index) => item.id !== value?.done?.courses[index]?.id
      );
      setIsSequenceChanged(isDifferent);
    }
  }, [items?.done?.courses, value?.done?.courses]);

  useEffect(() => {
    if (props.isCreateNew && !value?.done?.courses.length) {
      handleAddNewCourse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isCreateNew]);

  const onDragEnd = (result) => {
    setDraggingStartHeight(0);
    const {
      destination: { index: destinationIndex },
      source: { index: sourceIndex },
    } = result;
    const courses = [...items.done.courses];
    const course = courses.splice(sourceIndex, 1)[0];
    courses.splice(destinationIndex, 0, course);
    setItems((prevObj) => ({
      ...prevObj,
      done: {
        ...prevObj.done,
        courses,
      },
    }));
    setError("");
  };

  const handleSelectCourse = (courseId) => {
    if (isSequenceChanged) {
      errorRef?.current?.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        setError(
          "別のシーケンスを追加する前に、シーケンスを保存または破棄してください。"
        );
      }, 500);
      return;
    }
    setOpenedCourseId(courseId);
    const activePage = items?.done?.courses?.findIndex(
      (v) => v.id === courseId
    );
    setPage(activePage + 1);
  };

  const handleCloseDialog = (_, isAutoClose) => {
    if (!isAutoClose) {
      setItems({ ...value, still: value.still });
    }
    setRefresh((prev) => !prev);
    setIsSequenceChanged(false);
    setIsUpdatingCourses(false);
    setIsSubmitting(false);
    setOpenedCourseId(null);
  };

  const processGraphQLInput = (course, order) => {
    return {
      id: value.done.id,
      order,
      course: {
        id: course.version !== undefined ? course.id : undefined,
        name: courseNameRef?.current?.trim() || course.name,
        departureTime: course.departureTime,
        assignedVehicleId: course.assignedVehicle?.id,
        assignedUserIds: course.assignedUsers.map((item) => item.id),
        points: course.points.map((point) => ({
          id: point.version !== undefined ? point.id : undefined,
          workplaceId: point.workplace.id,
          assignedWasteCollectionScheduleIds:
            point.assignedWasteCollectionSchedules.map(
              (schedule) => schedule.id
            ),
          version: point.version,
        })),
        version: course.version,
      },
      version: value.done.version,
    };
  };

  const handleUpdateCoursesOrder = () => {
    setIsUpdatingCourses(true);
    API.graphql(
      graphqlOperation(updateLogisticsWorkAllocationCoursesOrder, {
        input: {
          id: value.done.id,
          courses: items.done.courses.map((course) => ({
            id: course.version !== undefined ? course.id : undefined,
            version: course.version,
          })),
          version: value.done.version,
        },
      })
    )
      .then((response) => {
        const { courses: coursesArray, ...other } =
          response.data.updateLogisticsWorkAllocationCoursesOrder;

        const modifiedCourses = coursesArray.map((course) => ({
          id: course.id,
          name: course.name,
          updatedAt: course.updatedAt,
          createdAt: course.createdAt,
          departureDate: course.departureDate,
          departureTime: course.departureTime,
          version: course.version,
        }));
        setValue({
          ...items,
          done: {
            ...items.done,
            ...other,
            courses: modifiedCourses.map((modifiedCourse, index) => ({
              ...items.done.courses[index],
              ...modifiedCourse,
            })),
          },
          still: items?.still,
        });
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
      })
      .catch((err) => {
        debugLog(
          "Allocation.courses.update.error[path:src.views.organisms.Allocation.ScheduleContainer]",
          err
        );
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsUpdatingCourses(false);
        setError("");
      });
  };

  const handleUpdateCoursesFromDialogue = () => {
    const course = items.done.courses[page - 1];
    setIsSubmitting(true);
    const input = processGraphQLInput(course, page);
    API.graphql(
      graphqlOperation(updateLogisticsWorkAllocationCourse, {
        input,
      })
    )
      .then((response) => {
        if (!response.data.updateLogisticsWorkAllocationCourse) {
          props.logisticsWorkAllocation.refetch();
          throw new Error("更新に失敗しました。");
        }
        const { course, ...other } =
          response.data.updateLogisticsWorkAllocationCourse;
        setValue((prevState) => {
          const isExisted = prevState.done.courses.some(
            (item) => item.id === course.id
          );
          return {
            done: {
              ...prevState,
              ...other,
              courses: isExisted
                ? prevState.done.courses.map((courseItem) =>
                    courseItem.id === course.id ? course : courseItem
                  )
                : [
                    ...prevState.done.courses,
                    {
                      ...course,
                      pointTotal: course.points.length,
                    },
                  ],
            },
            still: items.still || [],
          };
        });
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
      })
      .catch((err) => {
        debugLog(
          "Allocation.courses.update.error[path:src.views.organisms.Allocation.ScheduleContainer]",
          err
        );
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const unsetMessage =
    value?.done?.courses?.filter(
      (course) =>
        (course?.assignedUsers?.length ?? 0) <= 0 || !course?.assignedVehicle
    )?.length > 0;

  const course = useMemo(() => {
    const course = items?.done?.courses?.find(
      (course) => course.id === openedCourseId
    );

    if (course) {
      return {
        ...course,
        points:
          course?.points
            .map(({ id, assignedWasteCollectionSchedules }, index) => {
              return assignedWasteCollectionSchedules.map((value) => ({
                parentId: id,
                ...value,
                order: value.order || index + 1,
              }));
            })
            .flat() || [],
      };
    }
    return null;
  }, [items?.done?.courses, openedCourseId]);

  const openedDialogueTitle = `${date
    ?.setLocale("jp-JP")
    ?.toFormat("yyyy/MM/dd (EEE)")}の${course?.name}を編集`;

  const handleAddNewCourse = () => {
    const tempCourseId = uuid();
    const newCourse = {
      id: tempCourseId,
      assignedUsers: [],
      points: [],
      name: "新しい配車リスト",
      departureTime: DateTime.now().toISOTime(),
      assignedVehicle: null,
    };
    setPage(items.done.courses.length + 1);
    setItems((prevItems) => {
      return {
        ...prevItems,
        done: {
          ...prevItems.done,
          courses: [...prevItems.done.courses, newCourse],
        },
      };
    });
    setOpenedCourseId(tempCourseId);
    props.setIsCreateNew(false);
  };

  const hasNextPage = fetchedCount < total;

  const getWasteCollectionSchdeules = (offsetValue) => {
    if (offsetValue > 0 && hasNextPage) {
      setLoadingUnAssigned(true);
      API.graphql(
        graphqlOperation(searchWasteCollectionSchedulesByOffset, {
          filter: {
            and: [
              {
                assigned: { eq: false },
                or: [
                  { date: { eq: date.toISODate() } },
                  { date: { eq: date.plus({ day: 1 }).toISODate() } },
                ],
              },
            ],
          },
          sort: {
            field: "date",
          },
          limit,
          offset: offsetValue,
        })
      )
        .then((respone) => {
          const fetchedUnassingedItems =
            respone.data.searchWasteCollectionSchedulesByOffset.items;
          setItems((prevState) => ({
            ...prevState,
            still: [...prevState.still, ...fetchedUnassingedItems],
          }));
          setTotal(respone.data.searchWasteCollectionSchedulesByOffset.total);
          setFetchedCount(
            (prvState) => prvState + fetchedUnassingedItems.length
          );
        })
        .catch((err) => {
          debugLog("スポット回収.更新後", err);
        })
        .finally(() => {
          setLoadingUnAssigned(false);
        });
    }
  };

  const handleLoadMore = (value) => {
    if (value > 0) {
      const offsetValue = offset + limit;
      setOffset(offsetValue);
      getWasteCollectionSchdeules(offsetValue);
    }
  };

  const handleDeleteCourse = (courseId) => {
    if (courseId) {
      setLoadingUnAssigned(true);
      API.graphql(
        graphqlOperation(deleteCourse, {
          input: {
            id: courseId,
          },
        })
      )
        .then((response) => {
          const courseId = response.data.deleteCourse;
          setValue({
            done: {
              ...value.done,
              courses: value.done.courses.filter(
                (course) => course.id !== courseId
              ),
            },
          });
          props.searchWasteCollectionSchedules.refetch();
          addAlert({
            value: "登録しました。",
            severity: "success",
          });
        })
        .catch((err) => {
          dispatch(
            addAlert({
              value: "エラーが発生したため、削除が完了できませんでした。",
              severity: "error",
            })
          );
        })
        .finally(() => {
          setLoadingUnAssigned(false);
        });
    }
  };

  const handlePageChange = (_, pageValue) => {
    const course = items?.done?.courses?.[pageValue - 1];
    setPage(pageValue);
    const idsMatch = haveSameIds(value.still, items.still);
    if (!idsMatch) {
      setItems({
        ...value,
        still: uniqBy([...items.still, ...value.still], "id"),
      });
    }
    setOffset(0);
    setItems((value) => {
      return {
        ...value,
        still: [],
      };
    });
    setOpenedCourseId(course.id);
  };

  const handleSequenceChangeCancel = () => {
    setItems({
      ...value,
      still: value?.still,
    });
    setError("");
  };

  const onBeforeDragStart = () => {
    const offsetHeight = dropableContainer.current.offsetHeight;
    setDraggingStartHeight(offsetHeight);
  };

  const setIsPointsLoading = useCallback((value) => {
    setPointsLoading(value);
  }, []);

  return render({
    unsetMessage,
    value,
    date,
    isSubmitting,
    openedCourseId,
    onDragEnd,
    handleUpdateCoursesOrder,
    handleSelectCourse,
    handleCloseDialog,
    handleUpdateCoursesFromDialogue,
    items,
    course,
    page,
    setItems,
    isSequenceChanged,
    getCourseItemStyle,
    getCoursesListStyle,
    isUpdatingCourses,
    openedDialogueTitle,
    handleAddNewCourse,
    handleDeleteCourse,
    handlePageChange,
    handleSequenceChangeCancel,
    setValue,
    errorRef,
    error,
    courseNameRef,
    setFetchedCount,
    refresh,
    setRefresh,
    draggingStartHeight,
    dropableContainer,
    onBeforeDragStart,
    isPointsLoading,
    setIsPointsLoading,
    ...props,
    loadingUnAssigned,
    hasNextPage,
    handleLoadMore,
  });
};
