import {
  removeVehiclePeriodicInspection,
  updateVehiclePeriodicInspection,
} from "api/graphql/mutations";
import { getVehiclePeriodicInspection } from "api/graphql/queries";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toApiValue, toFormValue } from "utils/ImageFunctions";
import { useMutation } from "utils/useMutation";
import { useOpener } from "utils/useOpener";
import { useQuery } from "utils/useQuery";

/**
 * 車輛管理定期点検を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const [formValue, setFormValue] = useState(null);
  const { id, inspectionId } = useParams();
  const form = useOpener();
  const confirmDialog = useOpener();
  const navigate = useNavigate();

  const inspections = useQuery({
    query: getVehiclePeriodicInspection,
    variables: {
      id: inspectionId,
    },
    onCompleted: ({ data }) => {
      toFormValue({
        files: data?.getVehiclePeriodicInspection?.files ?? [],
      }).then((files) => {
        setFormValue({
          ...data?.getVehiclePeriodicInspection,
          files: files,
        });
      });
    },
  });

  const [updateMutation, updateMutationState] = useMutation(
    updateVehiclePeriodicInspection,
    {
      onCompleted: (data) => {
        inspections.refetch({
          id: inspectionId,
        });
        form.toggle(false);
      },
      succeedMessage: "保存しました。",
      errorMessage: "エラーが発生したため、保存できませんでした。",
    }
  );

  const [removeMutation, removeMutationStatus] = useMutation(
    removeVehiclePeriodicInspection,
    {
      onCompleted: (params) => {
        navigate(`/vehicles-menu/list/${id}`);
      },
      succeedMessage: "削除しました。",
      errorMessage: "エラーが発生したため、削除できませんでした。",
    }
  );

  const onRemove = () => {
    removeMutation({
      input: {
        id: inspectionId,
      },
    });
  };

  const onUpdate = async (data) => {
    const { personInChargeName, checkedOn, remarks, files, sections } = data;

    updateMutation({
      input: {
        id: inspections?.data?.getVehiclePeriodicInspection?.id,
        personInChargeName: personInChargeName,
        checkedOn: checkedOn,
        remarks: remarks,
        files: await toApiValue({ files: files }),
        sections: sections?.map((section) => ({
          ...section,
          items: section?.items?.map((item) => ({
            ...item,
            name: undefined,
          })),
          name: undefined,
        })),
        expectedVersion:
          inspections?.data?.getVehiclePeriodicInspection?.version,
      },
    });
  };

  return render({
    ...props,
    form: form,
    value: formValue,
    loading:
      removeMutationStatus?.loading ||
      inspections?.loading ||
      updateMutationState?.loading ||
      false,
    onUpdate: onUpdate,
    onRemove: onRemove,
    confirmDialog: confirmDialog,
  });
};
