import { styles } from "./styles";

/**
 * エラーを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  title = "エラー",
  message = "表示中に問題が発生しました。",
  error,
  resetErrorBoundary,
  ...props
}) => {
  const classes = styles();

  console.log(error);

  return render({
    classes: classes,
    title: title,
    message: message,
    error: error,
    resetErrorBoundary: resetErrorBoundary,
    ...props,
  });
};
