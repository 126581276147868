import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * コースリストを表示するコンポーネントです。
 * @param {object} value コース情報
 * @param {object} index コースのインデックス
 * @param {func} onClickNumber コースのナンバーを押すときのイベント
 * @param {func} onOpenDialog 詳細ダイヤログを開くイベント
 * @param {boolean} nextDestination 次の目的地
 */
export const CollectLists = containerPresententer(Container, Presententer);
