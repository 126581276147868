import { makeStyles } from "@mui/styles";

export const styles = makeStyles((theme) => ({
  breadcrumbs: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
    "& a.MuiLink-root": {
      color: theme.palette.grey[500],
    },
    "& .MuiBreadcrumbs-li .MuiSvgIcon-root": {
      fontSize: "1.2rem",
      marginRight: theme.spacing(1),
    },
    "& .MuiBreadcrumbs-li p.MuiTypography-root": {
      color: theme.palette.grey[700],
    },
  },
  link: {
    display: "flex",
    alignItems: "center",
  },
}));
