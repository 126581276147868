export const styles = (theme) => {
  return {
    container: {
      fontSize: "12px",
      marginBottom: "3px",
      "& span": {
        color: "#fff",
        borderRadius: "4px",
        padding: "3px 6px",
      },
    },
  };
};
