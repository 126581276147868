import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { fetchTransportMethods } from "./api";

/*
object shape
  []
    code
    name
*/

const transportMethodsSlice = createSlice({
  name: "transportMethods",
  initialState: {
    transportMethods: null,
    isLoading: false,
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransportMethods.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchTransportMethods.fulfilled, (state, action) => {
        state.transportMethods = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchTransportMethods.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

/**
 * ストアから運搬方法全体を取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.TransportMethods;

/**
 * ストアから運搬方法を取得します。
 */
export const transportMethodsSelector = createSelector(
  selector,
  (transportMethods) => transportMethods.transportMethods
);

/**
 * ストアから読み込みを取得します。
 */
export const isLoadingSelector = createSelector(
  selector,
  (transportMethods) => transportMethods.isLoading
);

/**
 * ストアからエラーを取得します。
 */
export const errorSelector = createSelector(
  selector,
  (transportMethods) => transportMethods.error
);

export default transportMethodsSlice.reducer;
