import { createSlice } from "@reduxjs/toolkit";
const defaultValue = {
  waste: {},
};
const orderSpot = createSlice({
  name: "Spot",
  initialState: defaultValue,
  reducers: {
    ItemWasteEdit: (state, action) => {
      state.waste = action.payload;
    },
  },
});

export const { ItemWasteEdit } = orderSpot.actions;
export default orderSpot.reducer;
