import { useDispatch } from "react-redux";
import { signOut as authSignOut } from "aws-amplify/auth";
import { set, AUTHSTATES } from "ducks/Auth";

export const useSignOut = () => {
  const dispatch = useDispatch();
  const signOut = async () => {
    await authSignOut();
    dispatch(set(AUTHSTATES.SIGN_IN));
  };
  return {
    signOut,
  };
};

