import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { basicSchema } from "./Validation";
import { useEffect } from "react";
import { debugLog } from "utils/log";
import { v4 } from "uuid";

const defaultValues = {
  method: null,
  part: null,
  content: null,
  quantityValue: null,
  quantityUnit: "個",
};

/**
 * 整備内容を入力するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  open = false,
  onClose = () => {},
  onSubmit = () => {},
  onError = debugLog,
  value,
  ...props
}) => {
  const { trigger, control, formState, watch, reset, } = useForm({
    mode: "onBlur",
    resolver: yupResolver(basicSchema),
    defaultValues: {
      ...defaultValues,
      ...value,
    },
  });

  useEffect(() => {
    if (open) {
      reset({
        ...defaultValues,
        ...value,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, JSON.stringify(value)]);

  const handleSubmit = () => {
    trigger().then((res) => {
      if (res) {
        onSubmit({
          id: value?.id ?? v4(),
          ...watch(),
        });
      } else {
        onError(formState.errors);
      }
    });
  };

  return render({
    ...props,
    control: control,
    open: open,
    onClose: onClose,
    onSubmit: handleSubmit,
  });
};
