import { Box, Button, Container, Dialog, Grid, TextField } from "@mui/material";
import React from "react";

const RenderButton = (props) => (
  <Button
    variant="contained"
    color={props.color}
    onClick={props.onClick}
    sx={{ minWidth: "120px", ml: 2 }}
  >
    {props.label}
  </Button>
);
/**
 * テキストを変更するダイアログを表示するコンポーネントです。
 * @param {object} open ダイアログオープン
 * @param {object} onClose ダイアログを閉じる
 * @param {func} onConfirm OKボタンを押すイベント
 * @param {string} label ラベル
 * @param {object} newValue テキストの値
 * @param {func} onChange 値を更新するイベント

 */
export const Presententer = (props) => {
  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <Container maxWidth="sm">
        <Grid container spacing={2} sx={{ p: 3 }}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <TextField
              id={`standard-basic-${props?.label}`}
              variant="standard"
              autoFocus={true}
              fullWidth
              label={props.label}
              value={props.newValue}
              onChange={props.onChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <RenderButton
                onClick={props.onClose}
                label="キャンセル"
                color="secondary"
              />
              <RenderButton
                onClick={props.onConfirm}
                label="OK"
                color="primary"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
};
