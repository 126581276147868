import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 処理フローの詳細モダールを表示するコンポーネントです。
 * @param {object} value 処理フロー詳細情報
 * @param {boolean} open モダールを有効か無効にする
 * @param {func} onClose モダールを閉じるイベント
 */
export const FlowDetailModal = containerPresententer(Container, Presententer);
