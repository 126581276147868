import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import { Avatar, Button, Stack } from "@mui/material";
import React from "react";
import { If } from "views/atoms";
import { ConfirmDialog, FullScreenDialog, TabsDetails } from "views/molecules";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { Caption } from "views/organisms/DetailsFrame/ChildComponents";
import { CategoryListForm } from "views/organisms/Masters/Container";
import { AddDialogForm } from "views/organisms/Masters/ContainerCategoryDetails";
import { Basic } from "views/organisms/Masters/ContainerCategoryDetails/Basic";
import { Safekeeping } from "views/organisms/Masters/ContainerCategoryDetails/Safekeeping";

const Form = React.forwardRef((props, ref) => (
  <CategoryListForm {...props} _ref={ref} />
));

/**
 * コンテナ種類詳細を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 */
export const Presententer = (props) => {
  const formatText = (value, format = () => "") => {
    if (!value || value === 0) {
      return "";
    } else {
      return format(value);
    }
  };

  const formatCaption = (value) => {
    if (value) {
      return [
        formatText(value?.width, (v) => `幅 ${v}mm`),
        formatText(value?.height, (v) => `高さ ${v}mm`),
        formatText(value?.depth, (v) => `奥行 ${v}mm`),
        formatText(value?.volume, (v) => `容積 ${v}㎥`),
      ]
        .filter((v) => v && v !== "")
        .join(" / ");
    } else {
      return "";
    }
  };

  return (
    <div>
      <DetailsFrame
        caption={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                marginRight: 3,
                width: 58,
                height: 58,
              }}
            >
              <InsertPhotoOutlinedIcon sx={{ fontSize: "2.5rem" }} />
            </Avatar>
            <div>
              <Caption
                title={props.value?.name ?? ""}
                subTitle={formatCaption(props.value)}
              />
            </div>
          </div>
        }
        actions={
          <>
            <If condition={props.tab === 0 && props.value?.state !== "removed"}>
              <Stack spacing={2} direction="row">
                {
                  <If condition={!!props.value && props.numberOfActives <= 0}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => props.confirm.toggle(true)}
                      disabled={props.loading || !props.value}
                    >
                      削除する
                    </Button>
                  </If>
                }
                {
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => props.categoryListForm.toggle(true)}
                    disabled={props.loading || !props.value}
                  >
                    編集する
                  </Button>
                }
              </Stack>
            </If>
          </>
        }
      >
        <TabsDetails
          value={props.tab}
          onChangeTab={props.onChangeTab}
          disabled={props.loading}
          tabs={[
            {
              name: "基本情報",
              tabContent: <Basic value={props.value} />,
            },
            {
              name: "保有情報",
              tabContent: (
                <Safekeeping
                  id={props.id}
                  value={props.containers}
                  numberOfInstallations={props.numberOfInstallations}
                  numberOfStorage={props.numberOfStorage}
                  totalOfOwned={props.totalOfOwned}
                  numberOfActives={props.numberOfActives}
                  onClick={() => props.addDialogForm.toggle(true)}
                />
              ),
            },
          ]}
        />
        <AddDialogForm
          name={props.value?.name}
          open={props.addDialogForm.open}
          onClose={() => props.addDialogForm.toggle(false)}
          onNotification={props.onNotification}
          productId={props.id}
          totalOfOwned={props.totalOfOwned}
        />
        <FullScreenDialog
          onClose={() => props.categoryListForm.toggle(false)}
          title={"コンテナの種類の編集"}
          textConfirm={"保存"}
          open={props.categoryListForm.open}
          isSubmit={props.loading}
          onClickSubmit={props.onClickSave}
        >
          <Form
            value={props.value}
            onSubmit={props.onSubmit}
            ref={props.formRef}
            loading={props.loading}
          />
        </FullScreenDialog>
      </DetailsFrame>
      <ConfirmDialog
        title="削除確認"
        message="削除します。よろしいですか？"
        open={props.confirm.open}
        positiveText={"削除"}
        negativeText={"キャンセル"}
        onPositive={props.onRemove}
        onNegative={() => props.confirm.toggle(false)}
        disabled={props.isSubmit}
      />
    </div>
  );
};

export default Presententer;
