import { useRef } from "react";

/**
 * コンテナ管理画面を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, isSubmit, ...props }) => {
  const formRef = useRef(null);
  const onSelected = (params) => {
    props.onSelected(
      props.xGridParams.rows.filter((row) => params.includes(row.id))
    );
  };

  const handleClickRegister = () => {
    formRef?.current?.submit();
  };

  return render({
    ...props,
    formRef: formRef,
    onSelected: onSelected,
    isSubmit: isSubmit,
    onClickRegister: handleClickRegister,
  });
};
