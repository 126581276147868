import { Box, Typography, Tooltip, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

/**
 * ファイルプレビューのヘッダーを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} src ファイルソース
 * @param {string} name ファイル名
 * @param {string} label ラベル
 * @param {boolean} disabled 無効設定
 * @param {JSX.Element} actions アクションエリア
 * @fires FilePreview#onDelete 削除時
 * @returns {JSX.Element}
 */
export const Header = ({
  src,
  label = "",
  name = "",
  disabled = false,
  actions = <></>,
  onDelete = () => ({
    src: src,
    label: label,
    name: name,
  }),
}) => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.download = name;
    link.href = src;
    link.target = "_blank";
    link.click();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant="h6">{label}</Typography>
      </Box>
      <Box>
        {disabled === false && (
          <Tooltip title="削除">
            <IconButton aria-label="delete" onClick={onDelete} size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip
          title={
            <>
              {"ダウンロード"}
              <br />
              {"(ダウンロードできない場合、別ウィンドウが開きます。)"}
            </>
          }
        >
          <IconButton
            disabled={!src}
            aria-label="download"
            onClick={handleDownload}
            size="large"
          >
            <FileDownloadIcon />
          </IconButton>
        </Tooltip>
        {actions}
      </Box>
    </Box>
  );
};
