import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * チェックシートテンプレート詳細を表示するコンポーネントです。
 * @param {object} value 値
 * @param {boolean} isSubmit 送信中
 * @fires Details#onUpdate 更新時
 */
export const Details = containerPresententer(Container, Presententer);
