import { Typography } from "@mui/material";
import {
  Button,
  Container, Dialog, Menu,
  MenuItem,
  Paper,
  Stack
} from "@mui/material";
import React from "react";
import { DatePicker, Mark } from "views/atoms";
import {
  FieldDecorateFrame,
  FullScreenDialog, XGridComponent
} from "views/molecules";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { Caption } from "views/organisms/DetailsFrame/ChildComponents";
import { WasteFlowManagementForm } from "views/organisms/Masters/Partner/PartnerDetailTabs/WasteFlowManagementForm";
import { WasteFlowTabs } from "views/organisms/Masters/Partner/WasteFlowTabs";

const Form = React.forwardRef((props, ref) => (
  <WasteFlowManagementForm {...props} _ref={ref} />
));

/**
 * 処理フロー詳細画面を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <div>
      <DetailsFrame
        caption={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <Mark
                text={
                  props.value?.disposalProcess?.disposalMethod
                    ?.smallClassName ||
                  props.value?.disposalProcess?.disposalMethod
                    ?.middleClassName ||
                  props.value?.disposalProcess?.disposalMethod
                    ?.largeClassName ||
                  ""
                }
                backgroundColor={"#00e676"}
              />
              <Caption
                title={
                  props.flow?.name ||
                  props.flow?.targetWasteType?.smallClass?.name ||
                  props.flow?.targetWasteType?.middleClass?.name ||
                  props.flow?.targetWasteType?.largeClass?.name
                }
              />
            </div>
          </div>
        }
        actions={
          <>
            {props.value?.state === "active" &&
              (props?.client?.state
                ? props?.client?.state !== "removed"
                : false) &&
              (props?.workplace?.state
                ? props?.workplace?.state !== "removed"
                : false) && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={props.onStopUseFlow}
                >
                  {"処理フローの利用を停止"}
                </Button>
              )}
            {props.value?.state === "inactive" &&
              (props?.workplace?.state
                ? props?.workplace?.state !== "removed"
                : false) && (
                <Button
                  variant="contained"
                  color="info"
                  onClick={props.onResumeUseFlow}
                >
                  {"処理フローの利用を再開"}
                </Button>
              )}
          </>
        }
      >
        <Stack spacing={2}>
          <WasteFlowTabs value={props.flow} />
          <Paper
            sx={{
              p: 2,
            }}
          >
            <Stack spacing={2}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant={"h6"}
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  {"報告方法"}
                </Typography>
                {props.value?.state === "active" &&
                  (props?.workplace?.state
                    ? props?.workplace?.state !== "removed"
                    : false) && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={props.onOpen}
                    >
                      {"報告方法を追加"}
                    </Button>
                  )}
              </Stack>
              <XGridComponent
                checkboxSelection={false}
                multipleSelection={false}
                onDownload={false}
                onSearcher={false}
                onFilter={false}
                onColumns={false}
                titleTable={""}
                {...props.xGridParams}
              />
            </Stack>
          </Paper>
        </Stack>
        <Menu
          id="item-menu"
          anchorEl={props.menuAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(props.menuAnchor)}
          onClose={props.onCloseMenu}
        >
          <MenuItem
            disabled={props.value?.state === "inactive"}
            onClick={props.onDeleteReportingSetting}
          >
            削除
          </MenuItem>
        </Menu>
        <Dialog open={props.modalOpen} onClose={props.onCloseModal}>
          <Stack spacing={1} p={2}>
            <FieldDecorateFrame required={false}>
              {(params) => (
                <DatePicker
                  label="終了日"
                  value={props.endDate}
                  onChange={props.onChangeEndDate}
                  required={true}
                  error={Boolean(props.error)}
                  helperText={props.error}
                  disableFuture={false}
                />
              )}
            </FieldDecorateFrame>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={props.onCloseModal}
                sx={{
                  marginRight: "10px",
                }}
              >
                {"キャンセル"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={props.onUpdateReportingSetting}
              >
                {"決定"}
              </Button>
            </Stack>
          </Stack>
        </Dialog>
        <FullScreenDialog
          title={`処理フロー${
            props.flow?.name ? `「${props.flow?.name}」` : ""
          }の報告方法を追加`}
          textConfirm="登録"
          open={props.open}
          onClose={props.onClose}
          onClickSubmit={props.onSubmit}
          isSubmit={props.isSubmit}
          disabled={props.isSubmit}
          container={false}
        >
          <Container maxWidth="md">
            <Paper
              elevation={3}
              sx={{
                padding: 5,
              }}
            >
              <Form onSubmit={props.onRegister} ref={props.formRef} />
            </Paper>
          </Container>
        </FullScreenDialog>
      </DetailsFrame>
    </div>
  );
};
