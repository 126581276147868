import { Button, Stack } from "@mui/material";
import { ConfirmDialog } from "views/molecules";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { VehicleInspectionEditForm } from "views/organisms/Vehicles";
import { InspectionsDetails } from "views/organisms/Vehicles/Common";

/**
 * 車輛点検を表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = ({
  onUpdate,
  onRemove,
  confirmDialog,
  value,
  form,
  loading = false,
}) => {
  return (
    <>
      <DetailsFrame
        caption={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
        }
        actions={
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              color="error"
              onClick={() => confirmDialog.toggle(true)}
              disabled={!value}
            >
              {"チェック内容を削除する"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => form.toggle(true)}
              disabled={!value}
            >
              {"チェック内容を編集する"}
            </Button>
          </Stack>
        }
      >
        <InspectionsDetails value={value} />
      </DetailsFrame>
      <VehicleInspectionEditForm
        value={value}
        title={`${value?.name ?? ""}の編集`}
        open={form.open}
        onClose={() => form.toggle(false)}
        disabled={loading}
        onSubmit={onUpdate}
      />
      <ConfirmDialog
        title="削除確認"
        message="削除します。よろしいですか？"
        open={confirmDialog?.open}
        onNegative={() => confirmDialog.toggle(false)}
        onPositive={onRemove}
        disabled={loading}
        positiveText="はい"
        negativetext="いいえ"
        onClose={() => confirmDialog.toggle(false)}
      />
    </>
  );
};
