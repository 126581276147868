import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 回収記録のヘッダーを表示するコンポーネントです。
 * @param {object} value 回収記録情報
 * @param {func} onClick ボタンのイベント
 */
export const Header = containerPresententer(Container, Presententer);
