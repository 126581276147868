import { Link } from "@mui/material";
import React from "react";
import { InformationItem } from "views/atoms";
import { PhoneNumberFormatUtil } from "utils/format";
import { DateTime } from "luxon";

/**
 * 基本情報のタブの表示を行うプレゼンテーションコンポーネントです
 * @param {object} classes クラス名
 * @param {object} value 事業場情報
 */
export const Presententer = (props) => {
  const { value } = props;
  return (
    <div>
      <InformationItem label="法人番号">
        {value?.corporateNumber}
      </InformationItem>
      <InformationItem label="代表者">
        {value?.representativeName}
      </InformationItem>
      <InformationItem label="設立">
        {value?.establishmentedOn
          ? DateTime.fromJSDate(new Date(value?.establishmentedOn)).toFormat(
              "yyyy/MM/dd"
            )
          : ""}
      </InformationItem>
      <InformationItem label="本社所在地">
        {value &&
          `〒${value?.headOfficeWorkplace?.postalCode ?? ""} ${value?.headOfficeWorkplace?.prefectures?.name ?? ""}${value?.headOfficeWorkplace?.city ?? ""}${value?.headOfficeWorkplace?.streetAddress ?? ""}${value?.headOfficeWorkplace?.otherAddress ?? ""}`}
      </InformationItem>
      <InformationItem label="本社電話番号">
        {PhoneNumberFormatUtil.formatNational(
          value?.headOfficeWorkplace?.phone
        )}
      </InformationItem>
      <InformationItem label="本社FAX番号">
        {PhoneNumberFormatUtil.formatNational(value?.headOfficeWorkplace?.fax)}
      </InformationItem>
      <InformationItem label="本社メールアドレス">
        {value?.headOfficeWorkplace?.email}
      </InformationItem>
      <InformationItem label="企業ホームページ">
        {value?.url && (
          <Link href={value.url} target="_blank" rel="noopener noreferrer">
            {value.url}
          </Link>
        )}
      </InformationItem>
    </div>
  );
};
