import { Alert, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { SelectPopup } from "views/molecules";
import { selector } from "../index";

/**
 * チェックシートカテゴリを選択するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.label ラベル
 * @param {boolean} props.required 必須設定
 * @param {object} props.value 値
 * @fires Selector#onChange 変更時
 * @param {boolean} props.error エラーオブジェクト
 * @param {boolean} props.disabled 無効設定
 * @returns {JSX.Element}
 */
export const Selector = ({
  label = "追加種別",
  required = true,
  value,
  onChange,
  error,
  disabled = false,
}) => {
  const checksheetCategories = useSelector(selector);

  if (checksheetCategories?.loading) {
    return <Skeleton />;
  }

  if (checksheetCategories?.error) {
    return (
      <Alert severity="error">
        {"エラーが発生したため、表示することができませんでした。"}
      </Alert>
    );
  }

  return (
    <SelectPopup
      label={label}
      required={required}
      value={value}
      onChange={onChange}
      error={error}
      options={checksheetCategories?.data ?? []}
      optionValueField="id"
      optionNameField="name"
      disabled={disabled}
    />
  );
};
