import React from "react";
import { InformationItem } from "views/atoms";

/**
 * 廃棄物詳細を表示するプレゼンテーションコンポーネントです
 * @param {object} classes クラス名
 * @param {object} value 事業場情報
 */
export const Presententer = (props) => {
  return (
    <div>
      <InformationItem label="形状">{props.value?.shape ?? ""}</InformationItem>
      <InformationItem label="臭い">{props.value?.smell ?? ""}</InformationItem>
      <InformationItem label="色">{props.value?.color ?? ""}</InformationItem>
      <InformationItem label="比重">
        {props.value?.specificGravity ?? ""}
      </InformationItem>
      <InformationItem label="pH">{props.value?.pH ?? ""}</InformationItem>
      <InformationItem label="沸点">
        {props.value?.boiling ?? ""}
      </InformationItem>
      <InformationItem label="融点">
        {props.value?.melting ?? ""}
      </InformationItem>
      <InformationItem label="発熱量">
        {props.value?.calorific ?? ""}
      </InformationItem>
      <InformationItem label="粘度">
        {props.value?.viscosity ?? ""}
      </InformationItem>
      <InformationItem label="水分">
        {props.value?.moisture ?? ""}
      </InformationItem>

      <InformationItem label="特定含有廃棄物の混入">
        {props.value?.includesSpecifieWastes ?? ""}
      </InformationItem>
      <InformationItem label="有害特性">
        {props.value?.harmful ?? ""}
      </InformationItem>
      <InformationItem label="経時変化">
        {props.value?.changeOvertime ?? ""}
      </InformationItem>
      <InformationItem label="関連法規">
        {props.value?.relatedRegulations ?? ""}
      </InformationItem>
      <InformationItem label="水道水源における消毒副生成前駆物質">
        {props.value?.precursor ?? ""}
      </InformationItem>
      <InformationItem label="その他含有物質">
        {props.value?.other ?? ""}
      </InformationItem>
      <InformationItem label="PRTR対象物質">
        {props.value?.PRTR ?? ""}
      </InformationItem>
      <InformationItem label="特別注意事項">
        {props.value?.remarks ?? ""}
      </InformationItem>
    </div>
  );
};
