import React from "react";
import { VehicleSelector, FullScreenDialog } from "views/molecules";
import { VehicleForm } from "views/organisms/Masters/Vehicle";

/**
 * 配車タブ詳細の表示を行うプレゼンテーションコンポーネントです。
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <>
    <VehicleSelector {...props} />
    <FullScreenDialog
      onClose={props.onCloseDialog}
      title={"車輛情報の編集"}
      textConfirm={"更新"}
      open={props.open}
      formId="vehicle-form"
      isSubmit={props.isLoading}
    >
      <VehicleForm
        id="vehicle-form"
        onSubmit={props.submit}
        value={props.selectedItem}
      />
    </FullScreenDialog>
  </>
);
