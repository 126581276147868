import React from "react";
import { Controller, FormProvider } from "react-hook-form";
import {
  Container,
  Dialog,
  Paper,
  Grid,
  FormControl,
  FormHelperText,
  IconButton,
} from "@mui/material";
import ChipInput from "material-ui-chip-input";
import { FieldDecorateFrame, FileUploader, InputField } from "views/molecules";
import { Thumbnail, PDFViewer } from "views/atoms";
import GetAppIcon from "@mui/icons-material/GetApp";

/**
 * 注意点情報を登録するフォームのプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <form onSubmit={props.handleSubmit} id={props.id} noValidate>
      <FormProvider {...props.methods}>
        <Container maxWidth="md">
          <Paper
            elevation={3}
            sx={{
              padding: 5,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <InputField
                      label="タイトル"
                      required={true}
                      placeholder="タイトルを入力します。"
                      value={value}
                      onChange={onChange}
                      error={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="tags"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <FieldDecorateFrame label="ラベル">
                      {(params) => (
                        <FormControl
                          error={error}
                          variant="outlined"
                          size="medium"
                          fullWidth
                        >
                          <ChipInput
                            variant="outlined"
                            placeholder="ラベルを付ける場合に入力します。（複数入力可）"
                            defaultValue={value}
                            onChange={onChange}
                            required={params.required}
                            fullWidth
                            InputProps={{
                              style: {
                                padding: "12.5px 14px 3.5px",
                                fontSize: "14px",
                              },
                            }}
                          />
                          <FormHelperText>{error?.message}</FormHelperText>
                        </FormControl>
                      )}
                    </FieldDecorateFrame>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="message"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <InputField
                      label="本文"
                      placeholder="本文を入力します。"
                      value={value}
                      multiline
                      rows={3}
                      onChange={onChange}
                      error={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="files"
                  control={props.control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <FileUploader
                        label="添付ファイル"
                        maxSize={26214400}
                        accept={[".jpg", ".jpeg", ".pdf"]}
                        onChange={(file) => onChange([...value, file])}
                      />
                      <div
                        style={{
                          display: "flex",
                          direction: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {value?.map((file, index) => (
                          <Thumbnail
                            key={index}
                            base64={file.context}
                            deletable={true}
                            onClick={() =>
                              props.handleSelectedFileUrlChanged(file.context)
                            }
                            onDelete={() =>
                              onChange(value.filter((_, i) => i !== index))
                            }
                            title={file.name}
                            width={200}
                            height={144}
                          />
                        )) ?? <></>}
                      </div>
                    </>
                  )}
                />
                <Dialog
                  open={Boolean(props.selectedFileUrl)}
                  onClose={() => props.handleSelectedFileUrlChanged(null)}
                >
                  <PDFViewer files={props.selectedFileUrl} />
                  <IconButton
                    component={"a"}
                    download
                    href={props.selectedFileUrl}
                  >
                    <GetAppIcon />
                  </IconButton>
                </Dialog>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </FormProvider>
    </form>
  );
};
