import { add as addAlert } from "ducks/Alert";
import { useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { debugLog } from "utils/log";
import { useOpener } from "utils/useOpener";

/**
 * 処理フロー詳細を表示するコンテナコンポーネントです。
 * @param {object} props プロパティ
 * @callback render
 * @returns JSX.Element
 */
export const Container = ({
  render,
  value,
  submit = (data) => Promise.resolve(data),
  onRemove,
  loading,
  ...props
}) => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const confirm = useOpener();

  const handleSubmit = (data) => {
    setIsSubmit(true);
    submit(data)
      .then((res) => {
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        setOpen(false);
      })
      .catch((err) => {
        debugLog(err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const format = useMemo(() => {
    if (!value) {
      return null;
    }

    const {
      targetWasteType,
      transportProcesses,
      disposalProcess,
      lastDisposalProcesses,
      ...other
    } = value;

    return {
      targetWaste: targetWasteType,
      transportProcesses: transportProcesses.map((item, index) => ({
        section: index + 1,
        trustee: item.carrierCompany,
        method: item.transportMethod,
        destination: item.transportDestinationWorkplace,
      })),
      disposalProcess: {
        client: {
          belongInCompanyId: disposalProcess.disposalCompany.id,
          id: disposalProcess.disposalWorkplace.id,
          name: disposalProcess.disposalWorkplace.name,
        },
        method: disposalProcess.disposalMethod.code,
        details: disposalProcess.disposalMethodDetails,
      },
      lastDisposalWorkplaces: lastDisposalProcesses.map(
        (item) => item.disposalWorkplace
      ),
      ...other,
    };
  }, [value]);

  const handleClickRegister = () => {
    formRef.current.submit();
  };

  return render({
    ...props,
    open: open,
    onOpenDialog: () => setOpen(true),
    onCloseDialog: () => setOpen(false),
    onSubmit: handleSubmit,
    formRef: formRef,
    onClickRegister: handleClickRegister,
    isSubmit: isSubmit || loading,
    value: value,
    format: format,
    onRemove: onRemove,
    confirm: confirm,
  });
};
