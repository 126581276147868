import reducer from "./slice";
import {
  selector,
  companyCategoriesSelector,
  isLoadingSelector,
  errorSelector,
} from "./slice";
import { fetchCompanyCategories } from "./api";

export default reducer;
export {
  fetchCompanyCategories,
  selector,
  companyCategoriesSelector,
  isLoadingSelector,
  errorSelector,
};
