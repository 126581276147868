/**
 * InformationItemコンポーネントをグループ化するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string | JSX.Element} subheader サブヘッダー
 * @param {string} className クラス名
 * @param {object} style スタイルオブジェクト
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  subheader,
  className,
  style,
  ...props
}) => {
  return render({
    subheader: subheader,
    className: className,
    style: style,
    ...props,
  });
};
