import yup, { dateSchema } from "utils/yup";

const objectSchema = yup.object().shape({
  number: yup.string().required(),
  installationWorkplace: yup.object().required(),
  installedOn: dateSchema.required(),
  installationLocationCategory: yup.string().required()
});

export const basicSchema = yup.object().shape({
  configs: yup.array().of(objectSchema).required(),
});
