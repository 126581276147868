import { useEffect, useState } from "react";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { format as fileSizeFormat } from "views/molecules/FileUploader/Container";
import { getCollectionPrecaution } from "api/graphql/queries";
import { debugLog } from "utils/log";
import { useStyles } from "./styles";

const readBlobAsDataUrl = (blob) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new Error("Problem parsing input blob."));
    };
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
};

const format = (value) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = { ...value };
      for (const file of result.files) {
        const res = await fetch(file.url, {
          method: "GET",
          mode: "cors",
        });
        const blob = await res.blob();
        const dataUrl = await readBlobAsDataUrl(blob);
        file.extensions = file?.name.split(".").pop();
        file.size = fileSizeFormat(blob.size, 2);
        file.context = dataUrl;
      }
      resolve(result);
    } catch {
      reject(new Error("Problem formatting value."));
    }
  });
};

export const searchCollectionPrecautionsByOffset = /* GraphQL */ `
  query SearchCollectionPrecautionsByOffset(
    $ownerCompanyId: ID
    $filter: SearchableCollectionPrecautionFilterInput
    $sort: SearchableCollectionPrecautionSortInput
    $offset: Int
    $limit: Int
  ) {
    searchCollectionPrecautionsByOffset(
      ownerCompanyId: $ownerCompanyId
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        name
      }
      nextToken
      total
    }
  }
`;

/**
 * ナビモードを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {number} number 番号
 * @param {object} value 値
 * @param {boolean} open 開くかどうか
 * @fires Container#onClose 閉じたとき
 * @param {string} scheduledTime 予定時間
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({
  render,
  number,
  value,
  open = false,
  onClose,
  scheduledTime,
  ...props
}) => {
  const classes = useStyles();
  const [position, setPosition] = useState(null);
  const [destination, setDestination] = useState(null);
  const [searchedCollectionPrecautions, setSearchedCollectionPrecautions] =
    useState([]);
  const [
    isLoadingSearchedCollectionPrecautions,
    setIsLoadingSearchedCollectionPrecautions,
  ] = useState(false);
  const [selectedCollectionPrecaution, setSelectedCollectionPrecaution] =
    useState(null);
  const [isLoadingCollectionPrecaution, setIsLoadingCollectionPrecaution] =
    useState(false);
  const [
    isOpenCollectionPrecautionDialog,
    setIsOpenCollectionPrecautionDialog,
  ] = useState(false);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setPosition(position);
    });

    if (value && value?.workplace) {
      setDestination({ lat: value?.lat, lng: value?.lng });
      setIsLoadingSearchedCollectionPrecautions(true);
      API.graphql(
        graphqlOperation(searchCollectionPrecautionsByOffset, {
          filter: {
            and: [
              {
                ownerWorkplaceId: {
                  eq: value.workplace.id,
                },
              },
            ],
          },
        })
      )
        .then((res) => {
          const items = res.data.searchCollectionPrecautionsByOffset.items;
          setSearchedCollectionPrecautions(items);
        })
        .catch((err) => {
          debugLog("情報の取得に失敗: ", err);
          setSearchedCollectionPrecautions([]);
        })
        .finally(() => setIsLoadingSearchedCollectionPrecautions(false));
    }
  }, [value]);

  const handleClickCollectionPrecautionListItem = (id) => {
    setIsLoadingCollectionPrecaution(true);
    setIsOpenCollectionPrecautionDialog(true);
    API.graphql(
      graphqlOperation(getCollectionPrecaution, {
        id: id,
      })
    )
      .then((res) => {
        format(res.data.getCollectionPrecaution).then((res) => {
          setSelectedCollectionPrecaution(res);
        });
      })
      .catch((err) => {
        debugLog("情報の取得に失敗: ", err);
        setSelectedCollectionPrecaution(null);
      })
      .finally(() => setIsLoadingCollectionPrecaution(false));
  };

  return render({
    ...props,
    position: position,
    classes: classes,
    number: number,
    value: value,
    open: open,
    onClose: onClose,
    scheduledTime: scheduledTime,
    destination: destination,
    searchedCollectionPrecautions: searchedCollectionPrecautions,
    isLoadingSearchedCollectionPrecautions:
      isLoadingSearchedCollectionPrecautions,
    selectedCollectionPrecaution: selectedCollectionPrecaution,
    isLoadingCollectionPrecaution: isLoadingCollectionPrecaution,
    isOpenCollectionPrecautionDialog: isOpenCollectionPrecautionDialog,
    onClickCollectionPrecautionListItem:
      handleClickCollectionPrecautionListItem,
    onCloseCollectionPrecautionDialog: () =>
      setIsOpenCollectionPrecautionDialog(false),
  });
};
