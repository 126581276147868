import { createSlice } from "@reduxjs/toolkit";
const defaultValue = {
  stepsEnabled: false,
  initialStep: 0,
  //steps: [],
  visibility: true,
};
const guiderSteps = createSlice({
  name: "GuiderSteps",
  initialState: defaultValue,
  reducers: {
    onOpenguiderSteps: (state, action) => {
      state.stepsEnabled = !state.stepsEnabled;
    },
    onExitguiderSteps: (state, action) => {
      state.stepsEnabled = false;
    },
    setVisilityBeginnersMark: (state, action) => {
      const visibility = action.payload;
      if (visibility) {
        state.visibility = false;
      } else {
        state.visibility = true;
      }
    },
  },
});

export const {
  onOpenguiderSteps,
  onExitguiderSteps,
  setVisilityBeginnersMark,
} = guiderSteps.actions;
export default guiderSteps.reducer;
