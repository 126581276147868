export const styles = (theme) => ({
  caption: {
    "& > .title-area": {
      "& > .title": {
        fontSize: "24px",
        fontWeight: "bold",
        color: theme.palette.grey[600],
      },
    },
    "& > .subTitle-area": {
      "& > .subTitle": {
        fontSize: "16px",
        color: theme.palette.grey[500],
      },
    },
  },
});
