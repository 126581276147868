import { Link as LinkMateial, Typography } from "@mui/material";
import React from "react";

const Presententer = (props) => {
  const { providerName, systemUrl } = props;
  return (
    <Typography color="textSecondary" align="center" variant="caption">
      {"© "}
      <LinkMateial
        color="inherit"
        href={systemUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {` ${providerName}. `}
      </LinkMateial>
      {"All Right Reserved."}
    </Typography>
  );
};

export default Presententer;
