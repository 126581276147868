import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { selector } from "ducks/Prefectures";

const initialAddress = {
  prefectures: "",
  city: "",
  streetAddress: "",
  otherAddress: "",
  postalCode: "",
};
/**
 * 住所を入力するコンテナコンポーネントです
 * @param {string} suffix Controllerの名前　例）name={suffix + "name"}
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({ render, suffix = "", ...props }) => {
  const [address, setAddress] = useState({
    manual: initialAddress,
    fromMap: initialAddress,
  });
  const classes = useStyles();
  const prefectures = useSelector(selector).prefectures;
  const {
    control,
    formState: { errors },
    trigger,
    setValue,
    getValues,
    clearErrors,
  } = useFormContext();

  useEffect(() => {
    const values = getCurrentFormValue();
    const inputType = getValues("inputType");
    if (inputType === "manual") {
      setAddress({ ...address, manual: values });
    }
    if (inputType === "from-map") {
      setAddress({ ...address, fromMap: values });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suffix]);

  const handleGetFullAddress = (address) => {
    const prefecture = prefectures.find((v) => v.name === address?.pref);
    setValue(`${suffix}prefectures`, prefecture);
    setValue(`${suffix}city`, address.city ?? "");
    setValue(`${suffix}streetAddress`, address.town ?? "");
    if (Object.keys(errors).length > 0) {
      trigger([
        `${suffix}prefectures`,
        `${suffix}city`,
        `${suffix}streetAddress`,
      ]);
    }
  };

  const addressInputType = [
    { value: "manual", label: "手入力", className: classes.label },
    { value: "from-map", label: "地図から", className: classes.label },
  ];

  const getCurrentFormValue = () => {
    const [prefectures, city, streetAddress, otherAddress, postalCode] =
      getValues([
        `${suffix}prefectures`,
        `${suffix}city`,
        `${suffix}streetAddress`,
        `${suffix}otherAddress`,
        `${suffix}postalCode`,
      ]);
    return {
      prefectures,
      city,
      streetAddress,
      otherAddress,
      postalCode,
    };
  };

  const setForm = ({
    prefectures,
    city,
    streetAddress,
    otherAddress,
    postalCode,
  }) => {
    setValue(`${suffix}prefectures`, prefectures);
    setValue(`${suffix}city`, city);
    setValue(`${suffix}streetAddress`, streetAddress);
    setValue(`${suffix}otherAddress`, otherAddress);
    setValue(`${suffix}postalCode`, postalCode);
  };

  const resetAddress = (event) => {
    clearErrors();
    const currentFormValue = getCurrentFormValue();
    if (event.target.value === "manual") {
      setForm(address.manual);
      setAddress({ ...address, fromMap: currentFormValue });
    }
    if (event.target.value === "from-map") {
      setForm(address.fromMap);
      setAddress({ ...address, manual: currentFormValue });
    }
  };

  return render({
    classes: classes,
    control: control,
    suffix: suffix,
    onGetFullAddress: handleGetFullAddress,
    Prefectures: prefectures,
    setValue: setValue,
    addressInputType: addressInputType,
    resetAddress: resetAddress,
    clearErrors: clearErrors,
    ...props,
  });
};
