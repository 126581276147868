import { useState, useRef, useMemo, useEffect } from "react";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { getAggregationWasteCollectionRecord } from "api/graphql/queries";
import { debugLog } from "utils/log";
import { DateTime } from "luxon";
import {
  Bar,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  useGridApiRef,
  gridVisibleRowCountSelector,
} from "@mui/x-data-grid-pro";

const datasetList = [
  { id: "wasteCollectionRecordActualValue", name: "回収記録の数量" },
  { id: "weightConversionValue", name: "重量換算値(kg)" },
];

const dateTypeList = [
  { id: "wasteCollectionScheduleDate", name: "回収予定日" },
];

const methodList = [
  { id: "sum", name: "合計" },
  { id: "avg", name: "平均" },
  { id: "count", name: "回数" },
  { id: "max", name: "最大" },
  { id: "min", name: "最小" },
];

export const modeList = [
  { id: "daily", name: "日別集計" },
  { id: "weekly", name: "週別集計" },
  { id: "monthly", name: "月別集計" },
  { id: "yearly", name: "年別集計" },
  { id: "totaling", name: "合算" },
];

const groupColumns = [
  {
    field: "logistics_course_name",
    headerName: "配車リストの名称",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "waste_generator_company_name",
    headerName: "排出事業者",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "waste_collection_workplace_code",
    headerName: "排出事業場の固有コード",
  },
  {
    field: "waste_collection_workplace_name",
    headerName: "排出事業場",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "waste_collection_workplace_prefecture",
    headerName: "排出事業場の所在地（都道府県）",
    minWidth: 300,
    flex: 1,
  },
  {
    field: "waste_collection_workplace_city",
    headerName: "排出事業場の所在地（市区町村）",
    minWidth: 290,
    flex: 1,
  },
  {
    field: "waste_large_class_name",
    headerName: "廃棄物の種類（大分類）",
    minWidth: 240,
    flex: 1,
  },
  {
    field: "waste_middle_class_name",
    headerName: "廃棄物の種類（中分類）",
    minWidth: 240,
    flex: 1,
  },
  {
    field: "waste_small_class_name",
    headerName: "廃棄物の種類（小分類）",
    minWidth: 240,
    flex: 1,
  },
  {
    field: "waste_kind_name",
    headerName: "廃棄物の種類（詳細）",
    minWidth: 230,
    flex: 1,
  },
  { field: "waste_name", headerName: "廃棄物の名称", minWidth: 165, flex: 1 },
  {
    field: "waste_disposal_company_name",
    headerName: "処分事業者",
    minWidth: 165,
    flex: 1,
  },
  {
    field: "waste_disposal_workplace_name",
    headerName: "処分事業場",
    minWidth: 165,
    flex: 1,
  },
  {
    field: "waste_disposal_workplace_prefecture",
    headerName: "処分事業場の所在地（都道府県）",
    minWidth: 290,
    flex: 1,
  },
  {
    field: "waste_disposal_workplace_city",
    headerName: "処分事業場の所在地（市区町村）",
    minWidth: 290,
    flex: 1,
  },
  {
    field: "waste_disposal_method_large_class_name",
    headerName: "処分方法（大分類）",
    minWidth: 220,
    flex: 1,
  },
  {
    field: "waste_disposal_method_middle_class_name",
    headerName: "処分方法（中分類）",
    minWidth: 220,
    flex: 1,
  },
  {
    field: "waste_disposal_method_small_class_name",
    headerName: "処分方法（小分類）",
    minWidth: 220,
    flex: 1,
  },
  {
    field: "waste_disposal_method_details",
    headerName: "処分方法（詳細）",
    minWidth: 220,
    flex: 1,
  },
  {
    field: "waste_quantity_unit_name",
    headerName: "単位",
    minWidth: 120,
    flex: 1,
  },
];

const cacheStore = new Map();

const generateDateRange = (startDate, endDate) => {
  const rangeCacheKey = `date-${startDate.toISOString().split("T")[0]}${
    endDate.toISOString().split("T")[0]
  }`;
  if (cacheStore.has(rangeCacheKey)) {
    return cacheStore.get(rangeCacheKey);
  }
  const dateArray = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const dateString = `${year}-${month}-${day}`;
    dateArray.push(dateString);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  dateArray.push("total");
  cacheStore.set(rangeCacheKey, dateArray);
  return dateArray;
};

const getFormattedDate = (date) => {
  if (!date) {
    return "";
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const generateWeekRange = (startDate, endDate) => {
  const dateArray = [];
  const weekRangeCacheKey = `week-${startDate.toISOString().split("T")[0]}${
    endDate.toISOString().split("T")[0]
  }`;
  if (cacheStore.has(weekRangeCacheKey)) {
    return cacheStore.get(weekRangeCacheKey);
  }
  let currentDate = new Date(getFormattedDate(startDate));
  let lastDate = new Date(getFormattedDate(endDate));

  while (currentDate <= lastDate) {
    const year = currentDate.getFullYear();
    const janFirst = new Date(year, 0, 1);
    const daysSinceJanFirst = Math.ceil(
      (currentDate - janFirst) / (1000 * 60 * 60 * 24)
    );
    const week = Math.ceil(daysSinceJanFirst / 7);
    const weekKey = `${year}.${week}`;
    dateArray.push(weekKey);

    currentDate.setDate(currentDate.getDate() + 7);
  }
  dateArray.push("total");
  cacheStore.set(weekRangeCacheKey, dateArray);
  return dateArray;
};

const generateMonthsRange = (startDate, endDate) => {
  const dateArray = [];
  let currentDate = new Date(getFormattedDate(startDate));
  let lastDate = new Date(getFormattedDate(endDate));

  while (currentDate <= lastDate) {
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const monthKey = `${year}.${month}`;
    dateArray.push(monthKey);

    currentDate.setMonth(currentDate.getMonth() + 1);
  }
  dateArray.push("total");
  return dateArray;
};

const getYearRange = (startDate, endDate) => {
  const dateArray = [];
  let currentDate = new Date(getFormattedDate(startDate));
  let lastDate = new Date(getFormattedDate(endDate));
  while (currentDate <= lastDate) {
    const year = currentDate.getFullYear();
    dateArray.push(year);
    currentDate.setFullYear(currentDate.getFullYear() + 1);
  }
  dateArray.push("total");
  return dateArray;
};

const getColumns = (aggregationColumns) => {
  return aggregationColumns.map(
    (key) =>
      ({
        field: key,
        headerName: key !== "total" ? key : "合計",
        minWidth: groupColumns.find((j) => j.field === key)?.minWidth ?? 160,
        flex: groupColumns.find((j) => j.field === key)?.flex ?? 1,
      } ?? [])
  );
};

const getGraphColumns = (aggregationColumns, mode) => {
  if (!aggregationColumns?.length) {
    return { graph: [] };
  }
  let filteredColumns;
  if (mode === "totaling") {
    filteredColumns = aggregationColumns;
  } else {
    filteredColumns = aggregationColumns.filter(
      (column) => column.key !== "total"
    );
  }
  return {
    graph: filteredColumns.map((aggregationColumn) => ({
      date: aggregationColumn.key === "total" ? "合計" : aggregationColumn.key,
      value: aggregationColumn.value,
    })),
  };
};

const generateColumns = (aggregationColumns, config) => {
  const {
    mode,
    aggregateOn: { start, end },
  } = config;
  switch (mode) {
    case "daily": {
      const dateRange = generateDateRange(start, end);

      return getColumns(dateRange);
    }
    case "weekly": {
      const weekRange = generateWeekRange(start, end);
      return getColumns(weekRange);
    }
    case "monthly": {
      const monthRange = generateMonthsRange(start, end);
      return getColumns(monthRange);
    }
    case "yearly": {
      const yearRange = getYearRange(start, end);
      return getColumns(yearRange);
    }
    default:
      return getColumns(aggregationColumns);
  }
};

const convertTextColumns = (items, config) => {
  return (
    items?.[0]?.groupColumns
      ?.map((i) => groupColumns.find((j) => j.field === i.key))
      ?.concat(
        generateColumns(
          items?.[0]?.aggregationColumns.map(({ key }) => key),
          config
        )
      ) || []
  );
};

const getFormattedAggregationColumn = (aggregationColumns, config) => {
  const {
    mode,
    aggregateOn: { start, end },
  } = config;
  const lookupMap = Object.fromEntries(
    aggregationColumns.map((c) => [c.key, c.value])
  );
  switch (mode) {
    case "daily": {
      const dateRange = generateDateRange(start, end);
      return dateRange.map((date) => [date, lookupMap[date] ?? 0]);
    }
    case "weekly": {
      const weekRange = generateWeekRange(start, end);
      return weekRange.map((date) => [date, lookupMap[date] ?? 0]);
    }
    case "monthly": {
      const monthRange = generateMonthsRange(start, end);
      return monthRange.map((date) => [date, lookupMap[date] ?? 0]);
    }
    case "yearly": {
      const yearRange = getYearRange(start, end);
      return yearRange.map((date) => [date, lookupMap[date] ?? 0]);
    }
    default:
      return aggregationColumns.map((c) => [c.key, c.value]);
  }
};

const convertTextDataRows = (items, config) =>
  items.map((item, index) => ({
    ...Object.fromEntries(
      item.groupColumns
        .map((c) => [c.key, c.value])
        .concat(getFormattedAggregationColumn(item.aggregationColumns, config))
    ),
    id: index,
  }));

const convertGraphColumns = (items) => {
  return items?.[0]?.groupColumns
    ?.map((i) => groupColumns.find((j) => j.field === i.key))
    ?.concat([
      {
        field: "graph",
        flex: 1,
        headerName: "グラフ",
        renderCell: renderGraphCell,
        minWidth: 600,
      },
    ]);
};

const convertGraphItem = ({ aggregationColumns, config } = null) => {
  const {
    mode,
    aggregateOn: { start, end },
  } = config;
  const lookupMap = Object.fromEntries(
    aggregationColumns.map((c) => [c.key, c.value])
  );

  switch (mode) {
    case "daily": {
      const dateRange = generateDateRange(start, end);
      const columns = dateRange.map((key) => {
        const value = lookupMap[key] ?? 0;
        return {
          key,
          value: value,
        };
      });

      return getGraphColumns(columns, config.mode);
    }
    case "weekly": {
      const weekRange = generateWeekRange(start, end);
      const columns = weekRange.map((item) => {
        const value = lookupMap[item] ?? 0;
        return {
          key: item,
          value: value,
        };
      });
      return getGraphColumns(columns, config.mode);
    }
    case "monthly": {
      const monthRange = generateMonthsRange(start, end);
      const columns = monthRange.map((item) => {
        const value = lookupMap[item] ?? 0;
        return {
          key: item,
          value: value,
        };
      });
      return getGraphColumns(columns, config.mode);
    }
    case "yearly": {
      const yearRange = getYearRange(start, end);
      const columns = yearRange.map((item) => {
        const value = lookupMap[item] ?? 0;
        return {
          key: item,
          value: value,
        };
      });
      return getGraphColumns(columns, config.mode);
    }
    default: {
      return getGraphColumns(aggregationColumns, config.mode);
    }
  }
};

const renderGraphCell = (params) => {
  return (
    <ResponsiveContainer height={150}>
      <ComposedChart data={params.value}>
        <XAxis dataKey="date" padding={{ right: 50, left: 50 }} />
        <YAxis yAxisId={1} label={{ value: "回収量", angle: -90, dx: -20 }} />
        <Tooltip />
        <CartesianGrid strokeDasharray="3 3" />
        <Bar
          yAxisId={1}
          dataKey="value"
          name="数量"
          barSize={50}
          fill="#8884d8"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const convertGraphDataRows = (items, config) => {
  return items.map((item, index) => {
    const data = {
      ...Object.fromEntries(item.groupColumns.map((c) => [c.key, c.value])),
      ...convertGraphItem({ ...item, config }),
      id: index,
    };
    if (config.dataset === datasetList.at(1).id) {
      data.waste_quantity_unit_name = "kg";
    }
    return data;
  });
};

const escapeRegExp = (value) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

const useFetch = (params) => {
  const [state, setState] = useState({
    items: [],
    total: 0,
    loading: false,
    error: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      setState({ ...state, loading: true });
      const { aggregateOn, ...other } = params;
      try {
        const result = await API.graphql(
          graphqlOperation(getAggregationWasteCollectionRecord, {
            aggregateOn: {
              start: DateTime.fromJSDate(aggregateOn.start).toISODate(),
              end: DateTime.fromJSDate(aggregateOn.end).toISODate(),
            },
            ...other,
          })
        );

        setState((previous) => ({
          ...previous,
          items: result.data.getAggregationWasteCollectionRecord.items ?? [],
          total: result.data.getAggregationWasteCollectionRecord.total ?? 0,
        }));
      } catch (error) {
        setState((previous) => ({
          ...previous,
          error: error,
        }));
      } finally {
        setState((previous) => ({
          ...previous,
          loading: false,
        }));
      }
    };

    if (!!params) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(params)]);

  return state;
};

export const Container = ({ render, ...props }) => {
  const formRef = useRef(null);
  const gridApiRef = useGridApiRef();
  const [config, setConfig] = useState();
  const [page, setPage] = useState(null);
  const [cache, setCache] = useState({});
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [graphMode, setGraphMode] = useState(true);
  const [appliedFilter, setAppliedFilter] = useState(false);
  const [filteredRowCount, setFilteredRowCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [columns, setColumns] = useState({
    graphColumn: [],
    textColumn: [],
  });
  const { items, total, loading, error } = useFetch(config);

  useEffect(() => {
    if (appliedFilter) {
      const count = gridVisibleRowCountSelector(gridApiRef.current.state);
      setFilteredRowCount(count);
    } else {
      setFilteredRowCount(0);
      setPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilter]);

  useEffect(() => {
    setCache({});
  }, [items]);

  const visibleRows = useMemo(() => {
    if (!items.length) {
      return [];
    }
    if (appliedFilter || searchText) {
      return items;
    }
    const start = page * pageSize;
    const end = start + pageSize;

    const currentPageRows = items.slice(start, end);

    return currentPageRows;
  }, [page, items, appliedFilter, searchText, pageSize]);

  useEffect(() => {
    if (items.length) {
      setColumns({
        graphColumn: convertGraphColumns(items),
        textColumn: convertTextColumns(items, config),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  if (!!error) {
    debugLog(`データ取得に失敗しました。${error}`);
    throw new Error();
  }

  const getAllRemainingData = async () => {
    return graphMode ? [] : convertTextDataRows(items, config);
  };

  const handleRegister = () => {
    formRef.current.submit();
  };

  const handleSubmit = (data) => {
    setPage(0);
    setConfig(data);
    setOpen(false);
  };

  const dataTable = useMemo(() => {
    if (!visibleRows.length) {
      return {
        columns: graphMode ? columns.graphColumn : columns.textColumn,
        rows: [],
      };
    }

    if (searchText || appliedFilter) {
      let data;
      if (graphMode) {
        data = {
          columns: columns.graphColumn,
          rows: convertGraphDataRows(visibleRows, config),
        };
      } else {
        data = {
          columns: columns.textColumn,
          rows: convertTextDataRows(visibleRows, config),
        };
      }
      if (searchText) {
        const regex = new RegExp(escapeRegExp(searchText), "i");
        data.rows = data.rows.filter((row) => {
          return Object.keys(row).some((field) => {
            return regex.test(row[field].toString());
          });
        });
      }
      return data;
    }

    const pageKey = page;
    if (graphMode) {
      if (cache[pageKey]?.graphRow) {
        return {
          columns: columns.graphColumn,
          rows: cache[pageKey].graphRow,
        };
      }
      const rows = convertGraphDataRows(visibleRows, config);
      cache[pageKey] = { graphRow: rows };
      return {
        columns: columns.graphColumn,
        rows: rows,
      };
    }
    if (cache[pageKey]?.textRow) {
      return {
        columns: columns.textColumn,
        rows: cache[pageKey].textRow,
      };
    }

    const rows = convertTextDataRows(visibleRows, config);
    cache[pageKey] = { textRow: rows };
    return {
      columns: columns.textColumn,
      rows: rows,
    };
  }, [
    config,
    visibleRows,
    graphMode,
    columns.graphColumn,
    columns.textColumn,
    cache,
    page,
    appliedFilter,
    searchText,
  ]);

  const onSearch = (text) => {
    setSearchText(text);
  };

  const handleChangeMode = (name) => {
    setGraphMode(name === "graph");
  };

  const getFileName = () => {
    const data = new Date();
    const year = data.getFullYear();
    const month = data.getMonth() + 1;
    const day = data.getDate();
    return `集計結果-${year}/${month}/${day}`;
  };

  const onPageChange = (page) => {
    setPage(page);
  };

  const onFilterModelChange = (value) => {
    const isValue = value.items.some((item) => item.value);
    setAppliedFilter(isValue);
  };

  const rowCount = useMemo(() => {
    if (appliedFilter) return filteredRowCount;
    if (searchText) return dataTable.rows.length;
    return total;
  }, [appliedFilter, dataTable, searchText, total, filteredRowCount]);

  const onPageSizeChange = (page) => {
    setCache({});
    setPage(0);
    setPageSize(page);
  };

  return render({
    open: open,
    formRef: formRef,
    onCloseDialog: () => setOpen(false),
    onOpenDialog: () => setOpen(true),
    onSubmit: handleSubmit,
    onRegister: handleRegister,
    config: config,
    dataTable: dataTable,
    isSubmit: loading,
    datasetList: datasetList,
    dateTypeList: dateTypeList,
    onSearch: onSearch,
    methodList: methodList,
    modeList: modeList,
    graphMode: graphMode,
    onChangeMode: handleChangeMode,
    total: total,
    pageSize: pageSize,
    handleDownloadAll: getAllRemainingData,
    getFileName: getFileName,
    onPageChange: onPageChange,
    rowCount: rowCount,
    page: page,
    onFilterModelChange: onFilterModelChange,
    onPageSizeChange: onPageSizeChange,
    gridApiRef: gridApiRef,
    ...props,
  });
};
