import reducer from "./slice";
import {
  selector,
  rolesSelector,
  isLoadingSelector,
  errorSelector,
} from "./slice";
import { fetchRoles } from "./api";

export default reducer;

export {
  rolesSelector,
  selector,
  isLoadingSelector,
  errorSelector,
  fetchRoles,
};
