import CancelIcon from "@mui/icons-material/Cancel";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { Switcher } from "utils/ImageFunctions";
import { Image } from "views/atoms/Image";
import { OtherFile } from "./OtherFile";
import { SinglePDFPage } from "./SinglePDFPage";

// gif
// data:image/gif;base64,R0lGODlhAQABAAAAACw=

// png -url
// https://www.w3.org/Graphics/PNG/text2.png

// pdf
// data:application/pdf;base64,JVBERi0xLjMKJbe+raoKMSAwIG9iago8PAovVHlwZSAvQ2F0YWxvZwovUGFnZXMgMiAwIFIKPj4KZW5kb2JqCjIgMCBvYmoKPDwKL1R5cGUgL1BhZ2VzCi9LaWRzIFsgNCAwIFIgXQovQ291bnQgMQo+PgplbmRvYmoKMyAwIG9iago8PAovUHJvZHVjZXIgKEhhcnUgRnJlZSBQREYgTGlicmFyeSAyLjIuMSkKPj4KZW5kb2JqCjQgMCBvYmoKPDwKL1R5cGUgL1BhZ2UKL01lZGlhQm94IFsgMCAwIDU5NS4yNzYgODQxLjg5MDAxIF0KL0NvbnRlbnRzIDUgMCBSCi9SZXNvdXJjZXMgPDwKL1Byb2NTZXQgWyAvUERGIC9UZXh0IC9JbWFnZUIgL0ltYWdlQyAvSW1hZ2VJIF0KPj4KL1BhcmVudCAyIDAgUgo+PgplbmRvYmoKNSAwIG9iago8PAovTGVuZ3RoIDYgMCBSCj4+CnN0cmVhbQ0KCmVuZHN0cmVhbQplbmRvYmoKNiAwIG9iagowCmVuZG9iagp4cmVmCjAgNwowMDAwMDAwMDAwIDY1NTM1IGYNCjAwMDAwMDAwMTUgMDAwMDAgbg0KMDAwMDAwMDA2NCAwMDAwMCBuDQowMDAwMDAwMTIzIDAwMDAwIG4NCjAwMDAwMDAxODQgMDAwMDAgbg0KMDAwMDAwMDM0OCAwMDAwMCBuDQowMDAwMDAwNDAyIDAwMDAwIG4NCnRyYWlsZXIKPDwKL1Jvb3QgMSAwIFIKL0luZm8gMyAwIFIKL1NpemUgNwo+PgpzdGFydHhyZWYKNDE5CiUlRU9GCg==

// pdf - url
// http://www.ll-p.co.jp/wp/wp-content/uploads/2018/06/test.pdf

// htm
// data:text/html,%3Ch1%3EHello%2C%20World!%3C%2Fh1%3E

// html - url
// https://templateexcel.com/blog-entry-210.html

// pdf - url
// https://uploads.codesandbox.io/uploads/user/a9c2a2d1-a6a2-41ec-9bf3-88ace03ac0fb/1ov4-test.pdf

/**
 * 削除ボタンを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @fires DeleteButton#onClick クリック時
 * @returns {JSX.Element}
 */
const DeleteButton = ({ onClick }) => {
  return (
    <IconButton
      size="medium"
      onClick={onClick}
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        marginTop: -10,
        marginRight: -10,
      }}
    >
      <CancelIcon />
    </IconButton>
  );
};

/**
 * ファイルのサムネイルを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} id 識別子
 * @param {boolean} props.loading 読み込み中
 * @param {boolean} props.disabled 無効設定
 * @param {string} props.src ファイルソース
 * @param {string} props.name 名前
 * @param {string} props.label ラベル
 * @param {number} props.width 幅
 * @param {number} props.height 高さ
 * @fires FileThumbnail#onClick クリック時
 * @fires FileThumbnail#onDelete 削除時
 * @returns {JSX.Element}
 */
export const FileThumbnail = ({
  id,
  loading = false,
  disabled = false,
  src,
  name = "",
  label = "",
  width = 144,
  height = 144,
  onClick = () => ({
    id: id,
    src: src,
    name: name,
    label: label,
  }),
  onDelete = () => ({
    id: id,
    src: src,
    name: name,
    label: label,
  }),
}) => {

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          width: width,
          height: height,
          position: "relative",
          cursor: "pointer",
          margin: "10px",
        }}
        onClick={onClick}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "lightgrey",
          }}
        >
          {loading === true && (
            <CircularProgress color="info" size={height} thickness={2} />
          )}
          {loading === false && (
            <Switcher src={src}>
              {({ isPDF, isImage, isOther }) => (
                <>
                  {isPDF === true && (
                    <SinglePDFPage src={src} height={height} />
                  )}
                  {isImage === true && (
                    <Image
                      src={src}
                      alt={name ?? "画像"}
                      width={width}
                      height={height}
                    />
                  )}
                  {isOther === true && (
                    <OtherFile
                      sx={{
                        fontSize: height,
                      }}
                    />
                  )}
                </>
              )}
            </Switcher>
          )}
        </div>
        <Box
          component={"span"}
          sx={(theme) => ({
            position: "absolute",
            bottom: "0",
            left: "0",
            width: "100%",
            lineHeight: "30px",
            padding: "0 5px",
            textAlign: "center",
            backgroundColor: " rgba(0,0,0,.4)",
            color: theme.palette.background.paper,
            fontSize: "12px",
          })}
        >
          {label}
        </Box>
      </div>
      {disabled === false && <DeleteButton onClick={onDelete} />}
    </div>
  );
};
