import { createSlice } from "@reduxjs/toolkit";
import { fetchSystemAlert, markAsReadSystemAlert } from "./api";
import { createSelector } from "reselect";

/*
  object shape
    notification
      id [String]
      severity [String]
      message [String | object]
      title [String]

    notification of severity
      "error"
      "warning"
      "info"
      "success"
*/

const systemAlertSlice = createSlice({
  name: "systemAlert",
  initialState: {
    notifications: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    subscribe: (state, action) => {
      if (state.notifications.length > 0) {
        const index = state.notifications.findIndex(
          (notification) => notification.id === action.payload.id
        );
        if (index !== -1) {
          state.notifications.splice(index, 1, action.payload);
        } else {
          state.notifications.push(action.payload);
        }
      } else {
        state.notifications.push(action.payload);
      }
      if (Array.isArray(action.payload)) {
        for (let notification of action.payload) {
          state.notifications.push(notification);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSystemAlert.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchSystemAlert.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.notifications = [];
          for (let notification of action.payload) {
            state.notifications.push(notification);
          }
        }
        state.isLoading = false;
      })
      .addCase(fetchSystemAlert.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      })
      
      .addCase(markAsReadSystemAlert.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(markAsReadSystemAlert.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.notifications = [];
          for (let notification of action.payload) {
            state.notifications.push(notification);
          }
        }
        state.isLoading = false;
      })
      .addCase(markAsReadSystemAlert.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

export const { subscribe } = systemAlertSlice.actions;

/**
 * ストアからシステム通知を取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.SystemAlert;

/**
 * ストアから通知情報を取得します。
 */
export const notificationsSelector = createSelector(
  selector,
  (systemAlert) => systemAlert.notifications
);

/**
 * ストアから通知情報を取得します。
 */
export const isLoadingSelector = createSelector(
  selector,
  (systemAlert) => systemAlert.isLoading
);

/**
 * ストアから通知情報を取得します。
 */
export const errorSelector = createSelector(
  selector,
  (systemAlert) => systemAlert.error
);

export default systemAlertSlice.reducer;
