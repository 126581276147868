/**
 * 画像を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.src 表示する画像ファイル
 * @param {string} props.alt 画像の代替テキスト
 * @param {string} props.id 識別子
 * @param {integer} props.width 幅
 * @param {integer} props.height 高さ
 * @param {object} props.other その他
 * @returns {JSX.Element} JSX.Element
 */
export const Image = ({ src, alt = "", id, width, height, ...other }) => {
  return (
    <img src={src} alt={alt} id={id} width={width} height={height} {...other} />
  );
};
