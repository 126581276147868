import { createIndividualityWasteCollectionOrder } from "api/graphql/mutations";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { companySelector } from "ducks/Company";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { debugLog } from "utils/log";
import { toQueryString, fromQueryString } from "utils/useXGridComponents";

/**
 * スポット回収一覧を表示するコンテナコンポーネントです
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const company = useSelector(companySelector);

  const handleTransite = (e) => {
    navigate(
      {
        pathname: location.pathname,
        search: toQueryString(e.info.search),
      },
      {
        replace: true,
      }
    );

    navigate(`${location.pathname}/${e.value}`);
  };

  const create = (data) => {
    debugLog("pages.Order.SpotList", data);

    return API.graphql(
      graphqlOperation(createIndividualityWasteCollectionOrder, {
        input: {
          ownerCompanyId: company.id,
          wasteGeneratorCompanyId: data.dischargesWorkplace.belongInCompanyId,
          wasteCollectionWorkplaceId: data.dischargesWorkplace.id,
          wasteCarrierCompanyId: company.id,
          scheduleDate: DateTime.fromJSDate(new Date(data.date)).toISODate(),
          scheduleTimeRangeStart: data.times.start + ":00",
          scheduleTimeRangeEnd: data.times.end + ":00",
          remarks: data.remarks,
          wastes: data.wastes.map((item) => ({
            wasteId: item.waste.id,
            quantity: item.quantity,
          })),
        },
      })
    );
  };

  return render({
    onTransite: handleTransite,
    submit: create,
    initialState: {
      sort: {
        direction: "desc",
        field: "scheduleDate",
      },
      ...fromQueryString(location.search),
    },
    ...props,
  });
};
