import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  timeLine: {
    "& .timelineConnector": {
      backgroundColor: theme.palette.primary.light,
      color: "#fff",
      "&.timelineConnector_disabled": {
        backgroundColor: theme.palette.grey[300],
      },
      "&.timelineConnector_complete": {
        backgroundColor: theme.palette.primary.main,
      },
      "&.timelineConnector_incomplete": {
        backgroundColor: "dodgerblue"
      },
      "&.timelineConnector_next": {
        backgroundColor: `${theme.palette.primary.main}`
      }
    },
    "& .timeLine_number": {
      textAlign: "center",
      borderWidth: "2px",
      borderRadius: "20px",
      //borderColor: theme.palette.text.disabled,
      padding: 0,
      minWidth: "30px",
      height: theme.spacing(3),
      borderColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
      "&.request": {
        "& svg": {
          position: "absolute",
          width: "18px",
          height: "18px",
          borderRadius: "50%",
          backgroundColor: theme.palette.secondary.light,
          padding: "2px",
          top: "-9px",
          right: "-6px",
          color: theme.palette.background.paper,
        },
        "& .MuiButton-startIcon": {
          margin: 0,
        },
      },
      "&.completed": {
        borderColor: `${theme.palette.grey[500]} !important`,
        color: `${theme.palette.grey[500]} !important`,
        "& svg": {
          position: "absolute",
          width: "18px",
          height: "18px",
          borderRadius: "50%",
          backgroundColor: theme.palette.secondary.main,
          padding: "2px",
          top: "-9px",
          right: "-6px",
          color: theme.palette.background.paper,
        },
        "& .MuiButton-startIcon": {
          margin: 0,
        },
      },
      "&.nextDestination": {
        borderColor: `${theme.palette.primary.main} !important`,
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: "#fff !important",
      },
      "&.incompleted": {
        borderColor: `dodgerblue !important`,
        color: `dodgerblue`,
      },
    },
  },

  connector_completed: {
    backgroundColor: theme.palette.primary.main,
  },
  collect_item: {
    "& .MuiListItem-secondaryAction": {
      paddingRight: theme.spacing(1),
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiListItemSecondaryAction-root": {
      right: "-24px",
    },
    "& .MuiListItemText-multiline": {
      margin: 0,
    },
  },
}));
