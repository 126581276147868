import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * コンテナ管理の表を表示するコンポーネントです。
 * @param {object} value 値
 * @fires ContainerDetails#onNotification 変更通知
 * @returns {JSX.Element}
 */
export const List = containerPresententer(Container, Presententer);
