import reducer from "./slice";
import {
  selector,
  disposalMethodsSelector,
  isLoadingSelector,
  errorSelector,
} from "./slice";
import { fetchDisposalMethods } from "./api";

export default reducer;

export {
  disposalMethodsSelector,
  selector,
  isLoadingSelector,
  errorSelector,
  fetchDisposalMethods,
};
