import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Box,
  Chip,
  Dialog,
  IconButton,
  Stack,
  Typography,
  Skeleton,
} from "@mui/material";
import React, { useState } from "react";
import { PDFViewer, Thumbnail } from "views/atoms";

/**
 * 注意点を表示するモバイルのコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {string} props.value.name 名称
 * @param {Date} props.value.updatedAt 更新日
 * @param {array} props.value.tags タグ
 * @param {string} props.value.message メッセージ
 * @param {array} props.value.files ファイル
 * @param {boolean} props.loading 読み込み中であるか
 * @returns {JSX.Element} JSX.Element
 */
export const Item = ({ value, loading = false }) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);

  if (loading) {
    return (
      <Stack spacing={3}>
        <Skeleton />
        <Skeleton />
        <Stack direction="row" spacing={2}>
          <Skeleton width={60} height={50} />
          <Skeleton width={60} height={50} />
          <Skeleton width={60} height={50} />
        </Stack>
        <Skeleton variant="rectangular" height={200} />
        <Skeleton variant="rectangular" height={200} />
        <Skeleton variant="rectangular" height={200} />
      </Stack>
    );
  }

  return (
    <Stack spacing={3}>
      <Typography
        variant="h6"
        sx={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {value?.name ?? ""}
      </Typography>
      {value?.tags?.length > 0 && (
        <Box
          sx={{
            width: "90vw",
            overflow: "auto",
          }}
        >
          <Stack spacing={1} direction={"row"}>
            {value?.tags?.map((tag, index) => (
              <Chip label={tag} key={index} />
            ))}
          </Stack>
        </Box>
      )}
      {value?.message && (
        <Box
          sx={{
            p: 2,
            whiteSpace: "break-spaces",
            border: "1px solid darkgray",
            borderRadius: "6px",
            wordWrap: "break-word",
          }}
        >
          {value?.message ?? ""}
        </Box>
      )}
      {value?.files?.map((file, index) => (
        <Thumbnail
          key={index}
          base64={file.context}
          deletable={false}
          onClick={() => setSelectedFileUrl(file.context)}
          title={file.name}
          width={200}
          height={144}
        />
      )) ?? <></>}
      <Dialog
        open={Boolean(selectedFileUrl)}
        onClose={() => setSelectedFileUrl(null)}
      >
        <PDFViewer files={selectedFileUrl} />
        <IconButton component={"a"} download href={selectedFileUrl}>
          <GetAppIcon />
        </IconButton>
      </Dialog>
    </Stack>
  );
};
