import { debugLog } from "utils/log";
import { useStyles } from "./styles";

/**
 * コースリストの項目を表示するコンテナコンポーネントです。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({
  render,
  belongInCompanyName,
  name,
  onClick = () => debugLog("コースリストの項目がクリックされた"),
}) => {
  const classes = useStyles();

  return render({
    classes: classes,
    belongInCompanyName,
    name,
    onClick,
  });
};
