import { LogoutOutlined } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import {
  Avatar,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  Link,
  Dialog,
} from "@mui/material";
import { AllPagesPDFView } from "./AllPagesPDFViewer";
import { useState } from "react";
import PrivacyPolicyPDF from "resources/pdf/PrivacyPolicy.pdf";
import TermsServicePDF from "resources/pdf/TermsOfService.pdf";
import { If } from "views/atoms";

/**
 * ポップアップメニューを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {object} props.anchorEl アンカー要素
 * @param {boolean} props.open 開閉状態
 * @fires PopupMenu#onClose 閉じる時
 * @fires PopupMenu#onSignOut サインアウト時
 * @returns {JSX.Element}
 */
export const PopupMenu = ({
  value,
  anchorEl,
  open = false,
  onClose = () => {},
  onSignOut = () => {},
}) => {
  const [pdf, setPDF] = useState(null);

  return (
    <Menu
      id="user-account-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        spacing={1}
        width={"100%"}
        p={3}
      >
        <Avatar
          src={value?.icon}
          sx={{
            width: 64,
            height: 64,
            backgroundColor: (theme) => theme.palette.icon.toolbarUser,
          }}
        >
          <PersonIcon />
        </Avatar>
        <Typography>{value?.name}</Typography>
        <Typography
          sx={{
            fontSize: "14px",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {value?.email ?? ""}
        </Typography>
      </Stack>
      <Divider />
      <MenuItem onClick={onSignOut}>
        <ListItemIcon>
          <LogoutOutlined fontSize="medium" />
        </ListItemIcon>
        <ListItemText>ログアウト</ListItemText>
      </MenuItem>
      <If condition={false}>
        {/* todo: 利用規約とプライバシーポリシーの話が出たらIfコンポーネントを消す */}
        <Divider />
        <Stack direction={"row"} spacing={2}>
          <Link
            onClick={() => setPDF(PrivacyPolicyPDF)}
            component="button"
            sx={{ p: 1 }}
          >
            プライバシーポリシー
          </Link>
          <Link
            onClick={() => setPDF(TermsServicePDF)}
            component="button"
            sx={{ p: 1 }}
          >
            サービス利用規約
          </Link>
        </Stack>
      </If>
      <Dialog
        onClose={() => setPDF(null)}
        open={Boolean(pdf)}
        PaperProps={{
          sx: {
            width: "fit-content",
            maxWidth: "95vw",
          },
        }}
      >
        <AllPagesPDFView source={pdf} />
      </Dialog>
    </Menu>
  );
};
