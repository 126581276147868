import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useStyles } from "./styles";
import useClasses from "utils/useClasses";

/**
 * 日付の選択の表示を行うコンテナコンポーネントです
 * @callback render
 * @param {string} value 日付
 * @param {func} onChange 日付を更新するイベントです。
 * @param {string} label 日付テキストのラベル
 * @param {string} hideLabel
 * @param {boolean} required 必須設定
 */

export const Container = ({
  render,
  value,
  onChange,
  label = "",
  hideLabel = false,
  required = true,
  error = null,
  ...props
}) => {
  const classes = useClasses(useStyles);
  const [date, setDate] = useState(null);
  const handleOnChange = (date) => {
    const isDate = DateTime.fromJSDate(date).toFormat("yyyy/MM/dd");
    onChange && onChange(isDate);
    setDate(isDate);
  };

  useEffect(() => {
    if (value) {
      setDate(value);
    } else {
      setDate(null);
    }
  }, [value]);

  return render({
    styleClass: classes,
    label: label,
    date: date,
    datechange: handleOnChange,
    required: required,
    error: error,
    hideLabel: hideLabel,
    ...props,
  });
};
