import { styles } from "./styles";
import useClasses from "utils/useClasses";
import { red } from '@mui/material/colors';

/**
 * マークを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string} backgroundColor 背景色
 * @param {string} text テキスト
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, backgroundColor = red[400], text, ...props }) => {
  const classes = useClasses(styles);

  return render({
    classes: classes,
    backgroundColor: backgroundColor,
    text: text,
    ...props,
  });
};
