import { Button, Stack } from "@mui/material";
import React from "react";
import { If, Mark } from "views/atoms";
import { ConfirmDialog, FullScreenDialog } from "views/molecules";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { Caption } from "views/organisms/DetailsFrame/ChildComponents";
import { FlowForm } from "views/organisms/Masters/Flow";
import { Details } from "views/organisms/Masters/Flow/Details";

const Form = React.forwardRef((props, ref) => (
  <FlowForm {...props} _ref={ref} />
));

/**
 * 事業場情報を詳しく表示するプレゼンテーションコンポーネントです。
 * @param {object} props
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  const {
    value,
    open,
    onOpenDialog,
    onCloseDialog,
    onSubmit,
    isSubmit,
    onClickRegister,
    onRemove,
    confirm,
    loading,
  } = props;
  return (
    <div>
      <DetailsFrame
        caption={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Mark
                text={
                  (value?.disposalProcess?.disposalMethod?.smallClassName ||
                    value?.disposalProcess?.disposalMethod?.middleClassName ||
                    value?.disposalProcess?.disposalMethod?.largeClassName) ??
                  ""
                }
                backgroundColor={"#0BB929"}
              />
              <Caption
                title={
                  value?.name ||
                  value?.targetWasteType?.smallClass?.name ||
                  value?.targetWasteType?.middleClass?.name ||
                  value?.targetWasteType?.largeClass?.name ||
                  ""
                }
              />
            </div>
          </div>
        }
        actions={
          <If condition={value?.state === "active"}>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="error"
                onClick={() => confirm.toggle(true)}
                disabled={!value || loading}
              >
                {"削除する"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onOpenDialog}
                disabled={!value || loading}
              >
                {"編集する"}
              </Button>
            </Stack>
          </If>
        }
      >
        <Details value={value} />
        <FullScreenDialog
          open={open}
          onClose={onCloseDialog}
          title="処理フローの編集"
          textConfirm="保存"
          onClickSubmit={onClickRegister}
          isSubmit={isSubmit}
          disabled={isSubmit}
        >
          <Form
            ref={props.formRef}
            value={props.format}
            onSubmit={onSubmit}
            isEditMode={true}
          />
        </FullScreenDialog>
      </DetailsFrame>
      <ConfirmDialog
        title="削除確認"
        message="削除します。よろしいですか？"
        open={confirm.open}
        positiveText={"削除"}
        negativeText={"キャンセル"}
        onPositive={onRemove}
        onNegative={() => confirm.toggle(false)}
        disabled={props.isSubmit}
      />
    </div>
  );
};
