import { containerPresententer } from "utils/HoC";
import Container from "./Container";
import Presententer from "./Presententer";

/**
 * コピーライトを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.systemUrl　システムのURL
 * @param {string} props.providerName システム名
 */
const Copyright = containerPresententer(Container, Presententer);

export default Copyright;
