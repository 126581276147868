import { useState, useRef, useEffect } from "react";
import useClasses from "utils/useClasses";
import { styles } from "./styles";

/**
 * 回収場所リストを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 回収場所リスト
 * @param {string} droppableId Droppableコンポーネントのid
 * @returns {func}
 */
export const Container = ({
  render,
  value,
  droppableId = "CollectPoints",
  searchWord = "",
  maxHeight,
  maxWidth,
  nextDay,
  ...props
}) => {
  // State to track whether the Droppable should be disabled
  const [isDropDisabled, setIsDropDisabled] = useState(false);
  // Create a ref for the Droppable container
  const droppableRef = useRef(null);
  const classes = useClasses(styles);

  // Intersection Observer callback to check if the Droppable is visible
  const handleIntersection = (entries) => {
    const [entry] = entries;
    setIsDropDisabled(!entry.isIntersecting);
  };
  // Set up the Intersection Observer
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection);
    const element = droppableRef.current;

    if (element) {
      observer.observe(element);
    }

    return () => {
      observer.unobserve(element);
    };
  }, []);

  return render({
    classes: classes,
    value: value,
    droppableId: droppableId,
    maxHeight: maxHeight,
    maxWidth: maxWidth,
    nextDay: nextDay,
    isDropDisabled: isDropDisabled,
    droppableRef: droppableRef,
    ...props,
  });
};
