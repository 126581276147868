import reducer from "./slice";
import {
  selector,
  modelOfVehiclesSelector,
  isLoadingSelector,
  errorSelector,
} from "./slice";
import { fetchModelOfVehicles } from "./api";

export default reducer;
export {
  modelOfVehiclesSelector,
  selector,
  fetchModelOfVehicles,
  isLoadingSelector,
  errorSelector,
};
