/**
 * 紙マニフェストの詳細を表示するコンテナコンポーネントです
 * @callback render
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  return render({
    ...props,
  });
};
