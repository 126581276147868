import { useDispatch, useSelector } from "react-redux";
import { remove, selector } from "ducks/Alert";

/**
 * アラートを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, vertical, horizontal, ...props }) => {
  const dispatch = useDispatch();
  const messages = useSelector(selector).messages;

  const handleClose = (index) => (e) => {
    dispatch(remove(index));
  };

  return render({
    messages: messages,
    onClose: handleClose,
    ...props,
  });
};
