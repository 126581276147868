import { listWasteDischargeIntervals } from "api/graphql/queries";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "utils/graphqlOperation";

/**
 * 排出する廃棄物の頻度種類を取得します。
 * @returns {object}
 */
const fetch = async () => {
  try {
    const fetchedData = await API.graphql(
      graphqlOperation(listWasteDischargeIntervals)
    );
    return fetchedData.data.listWasteDischargeIntervals.items;
  } catch (error) {
    throw error;
  }
};

export const fetchWasteDischageIntervals = createAsyncThunk(
  "wasteDischageIntervals/fetch",
  async (_, thunkApi) => {
    return fetch();
  }
);
