import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Box,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import {
  InputField,
  InputFieldPopppSelect,
  ModalPopup,
  SelectPopup,
  WasteTypeSelector,
} from "views/molecules";

/**
 * 事業場の廃棄物基本情報を登録編集するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Controller
          name="number"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="管理番号"
              required={false}
              placeholder="管理番号を入力します。"
              value={value}
              onChange={(event) => {
                onChange(event);
                props.onNotificationChanged();
              }}
              error={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Controller
          name="type"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <>
                <InputFieldPopppSelect
                  label="廃棄物の種類"
                  placeholder="廃棄物の種類を選択します。"
                  required={true}
                  showIcon={false}
                  value={{
                    name:
                      value?.smallClass?.name ||
                      value?.middleClass?.name ||
                      value?.largeClass?.name ||
                      "",
                  }}
                  disabledButtom={props.editMode}
                  onChange={(event) => {
                    onChange(event);
                    props.onNotificationChanged();
                  }}
                  onOpenModal={props.onOpenModal}
                  error={error}
                />
                <ModalPopup open={props.open} onClose={props.onCloseModal}>
                  <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                    <WasteTypeSelector
                      onSelected={(data) => {
                        props.onSelected(data);
                        onChange(data?.[0]);
                        props.onNotificationChanged();
                      }}
                      value={props.selectedValue}
                    />
                  </Box>
                </ModalPopup>
              </>
            );
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Controller
          name="kind"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="廃棄物の詳細種類"
              required={false}
              placeholder="選択した廃棄物の種類を詳しく分ける場合に入力します。"
              value={value}
              onChange={(event) => {
                onChange(event);
                props.onNotificationChanged();
              }}
              error={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Controller
          name="name"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="廃棄物の名称"
              required={false}
              placeholder="廃棄物の名称を入力します。"
              value={value}
              onChange={(event) => {
                onChange(event);
                props.onNotificationChanged();
              }}
              error={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Controller
          name="packing"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <SelectPopup
                label="廃棄物の荷姿"
                value={value}
                onChange={(event) => {
                  onChange(event);
                  props.onNotificationChanged();
                }}
                required={true}
                options={props.wastePackings}
                optionValueField="code"
                optionNameField="name"
                error={error}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Controller
          name="packingDetails"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="廃棄物の荷姿詳細"
              required={false}
              placeholder="廃棄物の荷姿を詳しく分ける場合に入力します。"
              value={value}
              onChange={(event) => {
                onChange(event);
                props.onNotificationChanged();
              }}
              error={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Controller
          name="quantityUnit"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SelectPopup
              label="廃棄物の数量入力単位"
              value={value}
              onChange={(event) => {
                onChange(event);
                props.setValue("weightConvertedValue", null);
                props.onNotificationChanged();
              }}
              required={true}
              options={props.wasteQuantityUnits}
              optionValueField="code"
              optionNameField="name"
              error={error}
            />
          )}
        />
      </Grid>
      {props.quantityUnitName !== "t" && props.quantityUnitName !== "kg" && (
        <Grid item xs={12} md={12}>
          <Controller
            name="weightConvertedValue"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputField
                label="重量換算係数"
                required={false}
                placeholder="例）12"
                value={value}
                onChange={(event) => {
                  onChange(event);
                  props.onNotificationChanged();
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      Kg／{`${props.quantityUnitName}`}
                    </InputAdornment>
                  ),
                }}
                error={error}
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12} md={6}>
            <Controller
              name="dischargeFrequency.wasteDischargeInterval"
              control={props.control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <SelectPopup
                    label="排出頻度"
                    value={value}
                    onChange={(event) => {
                      onChange(event);
                      props.onNotificationChanged();
                    }}
                    required={true}
                    options={props.wasteDischargeIntervals}
                    optionValueField="id"
                    optionNameField="name"
                    error={error}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="dischargeFrequency.numberOfTimes"
              control={props.control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <InputField
                    placeholder="例）4"
                    value={value}
                    onChange={(event) => {
                      onChange(event);
                      props.onNotificationChanged();
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">回</InputAdornment>
                      ),
                    }}
                    error={error}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Controller
          name="dischargeAmountPerOneTime"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="1回の排出量"
              required={true}
              placeholder="例）12"
              value={value}
              onChange={(event) => {
                onChange(event);
                props.onNotificationChanged();
              }}
              error={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Controller
          name="isImportedWaste"
          control={props.control}
          defaultValue={true}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    onChange={(event) => {
                      onChange(event);
                      props.onNotificationChanged();
                    }}
                    color="primary"
                  />
                }
                label="輸入廃棄物"
                labelPlacement="end"
              />
              <FormHelperText error={true}>{error?.message}</FormHelperText>
            </>
          )}
        />
      </Grid>
    </Grid>
  );
};
