import React from "react";
import { InformationItem } from "views/atoms";

/**
 * 事業者のタブを表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <div>
    <InformationItem label="固有コード">
      {props.value?.code ?? ""}
    </InformationItem>
    <InformationItem label="業種">
      {props.value?.industrySector?.subClass?.name ||
        props.value?.industrySector?.smallClass?.name ||
        props.value?.industrySector?.middleClass?.name ||
        props.value?.industrySector?.largeClass?.name ||
        ""}
    </InformationItem>
    <InformationItem label="名称">{props.value?.name ?? ""}</InformationItem>
    <InformationItem label="所在地">
      {`〒${props.value?.postalCode ?? ""} ${
        props.value?.prefectures?.name ?? ""
      }${props.value?.city ?? ""}${props.value?.streetAddress ?? ""}${
        props.value?.otherAddress ?? ""
      }`}
    </InformationItem>
    <InformationItem label="電話番号">
      {props.value?.phone ?? ""}
    </InformationItem>
    <InformationItem label="FAX番号">{props.value?.fax ?? ""}</InformationItem>
    <InformationItem label="メールアドレス">
      {props.value?.email ?? ""}
    </InformationItem>
    <InformationItem label="特記事項">
      <div
        style={{
          whiteSpace: "pre",
        }}
      >
        {props.value?.remarks ?? ""}
      </div>
    </InformationItem>
  </div>
);
