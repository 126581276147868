import { Box } from "@mui/material";

const RenderNumber = (props) => {
  return (
    <Box sx={{ justifyContent: "center", display: "flex", width: "100%" }}>
      {/* {rows?.findIndex((v) => v.id === id) + 1} */}
    </Box>
  );
};
const columns = [
  {
    field: "no",
    headerName: "No",
    width: 100,
    renderCell: RenderNumber,
  },
  {
    field: "emissionBusiness",
    headerName: "排出事業場",
    flex: 1,
  },
  {
    field: "arrivalTime",
    headerName: "到着時間",
    width: 180,
  },
  {
    field: "departureTime",
    headerName: "出発時間",
    width: 180,
  },
  {
    field: "waste",
    headerName: "廃棄物",
    flex: 1,
  },
  {
    field: "quantity",
    headerName: "数量",
    width: 110,
    valueFormatter: (params) => {
      return `${params?.value }袋`;
    },
  },
  {
    field: "inputTime",
    headerName: "入力日時",
    width: 180,
  },
];
/**
 * 引数を受けて、JSX.Elementを返します。
 * @param {object} props プロパティ
 * @callback render
 * @returns
 */
export const Container = ({ render, value, ...props }) => {
  const RenderNumber = ({ id }) => {
    return (
      <Box sx={{ justifyContent: "center", display: "flex", width: "100%" }}>
        {value?.findIndex((v) => v.id === id) + 1}
      </Box>
    );
  };
  const numberColumn = {
    field: "no",
    headerName: "No",
    width: 100,
    renderCell: RenderNumber,
  };
  const newColumns = columns.concat(numberColumn);
  return render({
    rows: value,
    columns: newColumns,
    ...props,
  });
};
