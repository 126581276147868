import { Close, EditOutlined } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import useClasses from "utils/useClasses";
import { v4 } from "uuid";
import { styles } from "./styles";

/**
 * 排出情報を入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const classes = useClasses(styles);
  const [selectedValue, setSelectedValue] = useState(null);
  const { control, watch, clearErrors, setValue, formState: { errors } } = useFormContext();
  const [open, setOpen] = useState(false);
  const formRef = useRef(null);

  const handleClickDelete = (index) => {
    const result = [...watch("wastes")];
    result.splice(index, 1);
    setValue("wastes", result);
  };

  const handleClickEdit = (data) => {
    setSelectedValue(data);
    setOpen(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "",
      minWidth: 100,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const apiRef = params.api;
        const index = apiRef.getRowIndex(params.row.id);
        const data = params.row;
        return (
          <Box>
            <IconButton
              onClick={() =>
                handleClickEdit({
                  index: index,
                  data: data,
                })
              }
            >
              <EditOutlined />
            </IconButton>
            <IconButton onClick={() => handleClickDelete(index)}>
              <Close />
            </IconButton>
          </Box>
        );
      },
      renderHeader: () => " ",
    },
    {
      field: "廃棄物の種類",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.waste.type.smallClass.name ||
          params.row.waste.type.middleClass.name ||
          params.row.waste.type.largeClass.name ||
          ""
        }`;
      },
    },
    {
      field: "name",
      headerName: "廃棄物の名称",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => params.row.waste.name,
    },
    {
      field: "quantity",
      headerName: "排出量",
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        const { row } = params;
        return `${params.value} ${row.waste.quantityUnit.name}`;
      },
    },
  ];

  const handleSubmit = (data) => {
    const result = [...watch("wastes")];
    if (selectedValue) {
      result.splice(selectedValue.index, 1, { id: v4(), ...data });
    } else {
      result.push({ id: v4(), ...data });
    }
    setValue("wastes", result);
    clearErrors("wastes");
    setOpen(false);
    setSelectedValue(null);
  };

  const handleDecided = () => {
    formRef.current.submit();
  };

  return render({
    dischargeSourceWorkplaceId: watch("dischargesWorkplace")?.id,
    classes: classes,
    control: control,
    rows: watch("wastes") ?? [],
    columns: columns,
    selectedValue: selectedValue,
    open: open,
    onOpenModal: () => setOpen(true),
    onCloseModal: () => {
      setSelectedValue(null);
      setOpen(false);
    },
    onDecided: handleDecided,
    onSubmit: handleSubmit,
    formRef: formRef,
    errors: errors,
    ...props,
  });
};
