import { createSlice } from "@reduxjs/toolkit";
import { fetchCompanyCategories } from "./api";
import { createSelector } from "reselect";

const companyCategoriesSlice = createSlice({
  name: "companyCategories",
  initialState: {
    data: null,
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyCategories.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchCompanyCategories.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchCompanyCategories.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

/**
 * ストアから事業者種類を取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.CompanyCategories;

/**
 * ストアから事業者種類を取得します。
 */
export const companyCategoriesSelector = createSelector(
  selector,
  (companyCategories) => companyCategories.data
);

/**
 * ストアから読み込みを取得します。
 */
export const isLoadingSelector = createSelector(
  selector,
  (companyCategories) => companyCategories.isLoading
);

/**
 * ストアからエラーを取得します。
 */
export const errorSelector = createSelector(
  selector,
  (companyCategories) => companyCategories.error
);

export default companyCategoriesSlice.reducer;
