import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * タスクを入力するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 入力
 * @param {object} props.task タスク概要
 * @param {func} props.onChange タスクを変更時のコールバック
 * @param {boolean} props.error ヘルパーテキストをエラー表示にするかどうか
 * @param {string} props.helperText ヘルパーテキスト
 */
export const TaskField = containerPresententer(Container, Presententer);
