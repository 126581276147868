import { useState, useEffect } from "react";
import { useStyles } from "./styles";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { debugLog } from "utils/log";
import { searchWasteManagementMethodsByOffset } from "api/graphql/queries";
import { useOpener } from "utils/useOpener";

/*
 * object shape
 *   createdAt
 *   id
 *   record
 *   scheduleDate
 *   scheduleTimeRangeEnd
 *   scheduleTimeRangeStart
 *   updatedAt
 *   version
 *   waste
 *     dischargesAmountPerOneTime
 *     dischargeFrequency
 *       interval
 *       numberOfTimes
 *     id
 *     isImportedWaste
 *     kind
 *     name
 *     number
 *     packing
 *       code
 *       name
 *     packingDetails
 *     quantityUnit
 *       code
 *       name
 *     state
 *     version
 *     weightConvertedValue
 *     weightConvertedValueUnit
 *       id
 *       order
 *       name
 *     wasteQuantity
 */

/*
 *  onChange event
 *   taskId x
 *	 wasteFlowId
 *	 quantity
 *	 quantityUnitCode x
 *	 weightOfKg
 *	 remarks ??
 *	 recordVersion x
 */

const numberOfDigits = (value) => {
  return (
    value
      .toString()
      .match(new RegExp(/^([1-9][0-9]{0,4}|0)(\.[0-9]{1,3})?$/)) !== null
  );
};

/**
 * タスクを入力するコンテナコンポーネントです。
 * @param {Object} props - プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  task,
  onChange = (data) => debugLog("タスク入力変更時: ", data),
  error,
  helperText,
  ...props
}) => {
  const classes = useStyles();
  const wasteFlowDetailsOpener = useOpener();
  const flowOpener = useOpener();
  const remarksOpener = useOpener();
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState(null);
  const [customError, setCustomError] = useState(null);

  const getValueFromTask = () => {
    return {
      taskId: task.id,
      quantityUnitCode: task?.waste?.quantityUnit?.code,
    };
  };

  const quantityValidate = (value) => {
    setCustomError(null);

    if (!!value) {
      if (isNaN(value)) {
        setCustomError({
          message: "数値を入力してください。",
        });

        return;
      }

      const val = parseFloat(value);

      if (val < 0) {
        setCustomError({
          message: "正の数値を入力してください。",
        });

        return;
      }

      if (numberOfDigits(val) === false) {
        setCustomError({
          message: "整数部5桁、小数部3桁までで入力してください。",
        });

        return;
      }
    }
  };

  useEffect(() => {
    if (!!task) {
      setLoading(true);
      API.graphql(
        graphqlOperation(searchWasteManagementMethodsByOffset, {
          managedWasteId: task?.waste?.id,
          filter: {
            and: [
              {
                state: { eq: "active" },
              },
            ],
          },
        })
      )
        .then((res) => {
          const items = res.data.searchWasteManagementMethodsByOffset.items;
          setOptions(items);
          if (items.length === 1) {
            onChange({
              ...value,
              ...getValueFromTask(),
              record: {
                ...task?.record,
                wasteFlowId: items[0].wasteFlowId,
              },
            });
          }
        })
        .catch((err) => {
          debugLog("searchWasteManagementMethodsByOffsetの呼び出し失敗: ", err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(task)]);

  const handleChangeQuantity = (e) => {
    const data = e.target.value;
    if(isNaN(data)) return;
    quantityValidate(data);
    onChange({
      ...value,
      ...getValueFromTask(),
      record: {
        ...value?.record,
        quantity: data,
        weightOfKg: task?.waste?.weightConvertedValue
          ? data * task?.waste?.weightConvertedValue
          : null,
      },
    });
  };

  const handleSelectedFlow = (data) => {
    onChange({
      ...value,
      ...getValueFromTask(),
      record: {
        ...value?.record,
        wasteFlowId: data.wasteFlowId,
      },
    });
  };

  const handleChangeRemarks = (e) => {
    onChange({
      ...value,
      ...getValueFromTask(),
      record: {
        ...value.record,
        remarks: e.target.value,
      },
    });
  };

  return render({
    classes: classes,
    wasteFlowDetailsOpener: wasteFlowDetailsOpener,
    flowOpener: flowOpener,
    remarksOpener: remarksOpener,
    value: value,
    task: task,
    error: customError || error,
    helperText: helperText,
    options: options,
    onChangeQuantity: handleChangeQuantity,
    onSelectedFlow: handleSelectedFlow,
    onChangeRemarks: handleChangeRemarks,
    loading: loading,
    ...props,
  });
};
