import { Box, Paper, Button, Skeleton, Typography } from "@mui/material";
import { WarningOutlined } from "@mui/icons-material";
import React from "react";
import PropTypes from "prop-types";
import { AutoSizer } from "react-virtualized";
import { DragDropContext } from "react-beautiful-dnd";
import _ from "lodash";
import { TextSearcher } from "views/molecules/TextSearcher";
import {
  GoogleMapApi,
  Direction,
  Marker,
  // DistanceMatrix,
} from "views/organisms/GoogleMapForWeb";
import { StillCourse, CollectPoints } from "../ChildComponents";
import { SideMenu } from "../SideMenu";
import { Drivers, Vehicle, Name } from "views/organisms/Allocation";

/**
 * 配車計画を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @param {func} onPointsDragEnd
 * @param {Array{}} menuList
 * @param {object} course
 * @param {func} onChangeName
 * @param {func} handleMenuItemClick
 * @param {func} onChangeDrivers
 * @param {Array{}} drivers
 * @param {func} onChangeVehicle
 * @param {Array{}} vehicles
 * @param {Array{}} items
 * @param {func} handleRenderDirection
 * @param {object} containerRef
 * @param {Array{}} markerPoints
 * @param {object} mapCenter
 * @param {Array{}} markerPositions
 * @param {func} onClickMarker
 * @param {object} movingPoint
 * @param {object} listRef
 * @param {string} activeCollectionPointId
 * @param {string} wordOfStillCollects
 * @param {func} onSearchStillCollects
 * @param {boolean} isMapRenderAvailable
 * @param {boolean} isSearching
 * @param {boolean} hasNextPage
 * @param {func} handleLoadMore
 * @param {boolean} loadingUnAssigned
 * @param {boolean} isPointsLoading
 * @param {object} mapRef
 * @returns {JSX.Element}
 */
export const Presententer = ({
  onPointsDragEnd,
  renderDirection,
  menuList,
  course,
  handleOnChangeName,
  handleMenuItemClick,
  onChangeDrivers,
  drivers,
  onChangeVehicle,
  vehicles,
  items,
  handleRenderDirection,
  containerRef,
  markerPoints,
  mapCenter,
  markerPositions,
  onClickMarker,
  movingPoint,
  listRef,
  activeCollectionPointId,
  wordOfStillCollects,
  onSearchStillCollects,
  isMapRenderAvailable,
  isSearching,
  hasNextPage,
  handleLoadMore,
  loadingUnAssigned,
  isPointsLoading,
  courseNameRef,
  date,
  mapRef,
}) => (
  <DragDropContext onDragEnd={onPointsDragEnd}>
    <Box
      height="100vh"
      width="100vw"
      position="absolute"
      top={0}
      left={0}
      display="flex"
      pt={8}
      boxSizing="border-box"
    >
      <Box width="290px" overflow="auto">
        <Box
          display="flex"
          alignItems="center"
          p={1}
          backgroundColor="rgba(0, 0, 0, 0.06)"
        >
          <Box className="horizontalScroll" flexGrow={1} ml={1}>
            <Name
              size="small"
              value={course?.name || ""}
              onChange={handleOnChangeName}
              courseRef={courseNameRef}
            />
          </Box>
          <Box className="horizontalScroll">
            <SideMenu
              id={course?.id}
              title="配車リスト"
              menuList={menuList.map((item) => {
                return {
                  ...item,
                  disabled: !course?.points?.length,
                };
              })}
              value={course}
              handleMenuItemClick={handleMenuItemClick}
            />
          </Box>
        </Box>
        <Box
          className="horizontalScroll"
          p={1}
          backgroundColor="rgba(0, 0, 0, 0.06)"
        >
          <Drivers
            value={course?.assignedUsers}
            onChange={onChangeDrivers}
            drivers={drivers}
            size="small"
          />
        </Box>
        <Box
          className="horizontalScroll"
          p={1}
          backgroundColor="rgba(0, 0, 0, 0.06)"
        >
          <Vehicle
            value={course?.assignedVehicle}
            onChange={onChangeVehicle}
            vehicles={vehicles}
            size="small"
          />
        </Box>
        <Box
          ref={containerRef}
          sx={{
            flexGrow: 1,
            height: "calc(100% - 150px)",
            overflow: "hidden",
          }}
        >
          {!isPointsLoading && (
            <AutoSizer>
              {({ width, height }) => (
                <CollectPoints
                  maxHeight={height - 10}
                  maxWidth={width}
                  value={course?.points}
                  listRef={listRef}
                  nextDay={date?.plus({ days: 1 })}
                  activePointId={activeCollectionPointId}
                  showMissingMessage
                />
              )}
            </AutoSizer>
          )}
          {isPointsLoading &&
            Array.from({ length: 3 }).map((_, index) => (
              <Skeleton
                key={index}
                sx={{
                  borderRadius: 1,
                  marginBottom: 1,
                  marginTop: 1,
                  marginRight: 2,
                  marginLeft: 1,
                }}
                variant="rectangular"
                height="30%"
                animation="wave"
              />
            ))}
        </Box>
      </Box>
      <Box width="280px">
        <Paper sx={{ height: "100%", p: 1 }}>
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box
              fontWeight="fontWeightMedium"
              fontSize="16px"
              textAlign="center"
              color="text.primary"
            >
              {"定期回収"}
            </Box>
            <Box margin="8px">
              <TextSearcher
                value={wordOfStillCollects}
                fieldSize="small"
                iconSize="small"
                onChangeSearch={onSearchStillCollects}
              />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                width: "100%",
              }}
            >
              {!isSearching && !isPointsLoading && (
                <StillCourse
                  hasNextPage={hasNextPage}
                  handleLoadMore={handleLoadMore}
                  value={items?.still}
                  loading={loadingUnAssigned}
                  nextDay={date?.plus({ days: 1 })}
                />
              )}
              {(isSearching || isPointsLoading) &&
                Array.from({ length: 3 }).map((_, index) => (
                  <Skeleton
                    key={index}
                    sx={{
                      borderRadius: 1,
                      marginBottom: 2,
                    }}
                    variant="rectangular"
                    width="100%"
                    height="30%"
                    animation="wave"
                  />
                ))}
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box
        style={{
          width: "calc(100% - 570px)",
        }}
      >
        <Box my={1} display="flex" justifyContent="space-between" mr={4}>
          <Typography
            color="secondary"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "calc(100% - 150px)",
            }}
          >
            {!markerPoints.length && !isPointsLoading && (
              <>
                <WarningOutlined
                  fontSize="small"
                  color="secondary"
                  sx={{ mr: 1 }}
                />
                表示可能なルートがありません
              </>
            )}
          </Typography>
          <Button
            color="primary"
            variant="outlined"
            disabled={markerPoints?.flat().length < 2 || !isMapRenderAvailable}
            onClick={handleRenderDirection}
          >
            ルート確認
          </Button>
        </Box>
        <Box
          style={{
            height: "calc(100vh - 117px)",
            padding: "5px",
            paddingTop: "0",
            borderRadius: "10px",
          }}
        >
          {markerPoints ? (
            <GoogleMapApi center={mapCenter} mapRef={mapRef}>
              {markerPositions.length &&
                _.uniqBy(markerPositions, "label").map((marker, index) => {
                  return (
                    <React.Fragment key={marker.id}>
                      <Marker
                        noOfMarkers={markerPositions.length}
                        onClickMarker={onClickMarker}
                        index={index}
                        marker={marker}
                        animate={
                          marker.id === activeCollectionPointId ||
                          marker.id === movingPoint?.id
                        }
                        isActive={marker.id === activeCollectionPointId}
                      ></Marker>
                    </React.Fragment>
                  );
                })}

              {renderDirection &&
                !isPointsLoading &&
                markerPoints.map((points, index) => (
                  <React.Fragment key={index}>
                    <Direction points={points} />
                    {/* TODO: API コストのため、方向マトリックス サービスは現在機能していません。距離マトリックス サービスが必要な場合は、これらのコメントを削除してください。 */}
                    {/* <DistanceMatrix points={points} /> */}
                  </React.Fragment>
                ))}
            </GoogleMapApi>
          ) : (
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: 1,
              }}
              height="100%"
              animation="wave"
            />
          )}
        </Box>
      </Box>
    </Box>
  </DragDropContext>
);

Presententer.propTypes = {
  onPointsDragEnd: PropTypes.func,
  renderDirection: PropTypes.bool,
  menuList: PropTypes.array,
  course: PropTypes.object,
  onChangeName: PropTypes.func,
  handleMenuItemClick: PropTypes.func,
  onChangeDrivers: PropTypes.func,
  drivers: PropTypes.array,
  onChangeVehicle: PropTypes.func,
  vehicles: PropTypes.array,
  items: PropTypes.object,
  handleRenderDirection: PropTypes.func,
  containerRef: PropTypes.object,
  markerPoints: PropTypes.array,
  mapCenter: PropTypes.object,
  markerPositions: PropTypes.array,
  onClickMarker: PropTypes.func,
  movingPoint: PropTypes.object,
  listRef: PropTypes.object,
  activeCollectionPointId: PropTypes.string,
  wordOfStillCollects: PropTypes.string,
  onSearchStillCollects: PropTypes.func,
  hasNextPage: PropTypes.bool,
  handleLoadMore: PropTypes.func,
  loadingUnAssigned: PropTypes.bool,
  isSearching: PropTypes.bool,
  isPointsLoading: PropTypes.bool,
  isMapRenderAvailable: PropTypes.bool,
  date: PropTypes.object.isRequired,
  mapRef: PropTypes.object,
};
