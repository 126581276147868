import yup, { textSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  number: textSchema.nullable(),
  name: textSchema.nullable(),
  type: yup.object().required(),
  kind: textSchema.nullable(),
  wastePacking: yup.object().nullable(),
  packing: yup.object().required(),
  packingDetails: textSchema.nullable(),
  quantityUnit: yup.object().nullable().required(),
  weightConvertedValue: yup.number().nullable(),
  dischargeFrequency: yup
    .object()
    .shape({
      wasteDischargeInterval: yup.object().required(),
      numberOfTimes: yup.number().required(),
    })
    .required(),
  dischargeAmountPerOneTime: yup.number().required(),
  isImportedWaste: yup.boolean(),
});
