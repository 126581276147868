import { Paper, Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { ApplyForEDIConnection } from "./ApplyForEDIConnection";
import { isValid } from "./utils";

const options = [
  {
    label: "EDI接続の登録",
    component: (props) => <ApplyForEDIConnection {...props} />,
  },
  {
    label: "登録完了",
    component: (props) => <></>,
  },
];

const fetchCurrentStep = (value) => {
  return isValid(value) ? 2 : 0;
};

/**
 * EDI利用手続き進捗状態を表します。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @fires Progresses#onNotificationChange 変更通知
 * @returns {JSX.Element}
 */
export const Progresses = ({ value, onNotificationChange = () => {} }) => {
  if (!value) {
    return <></>;
  }

  return (
    <DetailsFrame>
      <Paper
        elevation={2}
        sx={{
          p: 2,
        }}
      >
        <Stepper orientation="vertical" activeStep={fetchCurrentStep(value)}>
          {options.map((option, index) => {
            return (
              <Step key={index} expanded={true}>
                <StepLabel>{option.label ?? ""}</StepLabel>
                <StepContent>
                  {option.component({
                    value: value,
                    onNotificationChange: onNotificationChange,
                  })}
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </Paper>
    </DetailsFrame>
  );
};
