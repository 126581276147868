import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  Stack,
} from "@mui/material";
import { useOpener } from "utils/useOpener";
import { InformationItem } from "views/atoms";
import { Details } from "../Details";

/**
 * フロー選択する項目を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {object} props.option 選択値・表示項目
 * @fires Item#onChange 変更時
 * @returns {JSX.Element}
 */
export const Item = ({ value, onChange = () => {}, option }) => {
  const details = useOpener();

  return (
    <>
      <Stack
        spacing={0.5}
        sx={{
          p: 1,
          border: "1px solid grey",
          borderRadius: "5px",
          backgroundColor:
            value === option?.wasteFlowId ? "rgba(0, 0, 200, 0.05)" : undefined,
        }}
      >
        <Stack spacing={0.5} onClick={() => onChange(option?.wasteFlowId)}>
          <FormControlLabel
            value={option?.wasteFlowId}
            control={<Radio checked={value === option?.wasteFlowId} />}
            label={
              option?.targetWasteType?.smallClass?.name ||
              option?.targetWasteType?.middleClass?.name ||
              option?.targetWasteType?.largeClass?.name ||
              ""
            }
          />
          <InformationItem label="処分事業場">
            {`${option?.disposalProcess?.disposalCompany?.name} ${option?.disposalProcess?.disposalWorkplace?.name}`}
          </InformationItem>
          <InformationItem label="処分方法">
            {`${
              option?.disposalProcess?.disposalMethod?.smallClassName ||
              option?.disposalProcess?.disposalMethod?.middleClassName ||
              option?.disposalProcess?.disposalMethod?.largeClassName
            } (${option?.disposalProcess?.disposalMethod?.code})`}
          </InformationItem>
        </Stack>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => details.toggle(true)}
        >
          詳細を表示する
        </Button>
      </Stack>
      <Dialog
        open={details.open}
        onClose={() => details.toggle(false)}
        aria-labelledby="flows-details-dialog"
      >
        <DialogTitle>{"フロー詳細"}</DialogTitle>
        <DialogContent>
          <Details value={option?.wasteFlowId} />
        </DialogContent>
      </Dialog>
    </>
  );
};
