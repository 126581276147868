import { useMemo } from "react";
import { useNavigate, useLocation } from "react-router";
import { v4 } from "uuid";
import { useStyles } from "./styles";

const INIT_STATE = [
  {
    id: v4(),
    month: new Date(),
    course: [
      {
        name: "若戸コース",
        id: v4(),
        driverName: {
          lastName: "太郎",
          fullName: "テスト　太郎",
        },
        driverInfo: {
          icon: "https://picsum.photos/seed/picsum/200/300",
          number: "福岡999た2325",
          type: "ごみ車　（3tパッカー）",
        },
        cards: [
          {
            id: v4(),
            field: "FM",
            name: "八幡郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 32",
              createdAt: 1623675112632,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局2",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局3",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "八幡郵便局4",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 32",
              createdAt: 1623675112632,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局6",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
        ],
      },
      {
        name: "若松コース",
        id: v4(),
        driverName: {
          lastName: "太郎",
          fullName: "テスト　太郎",
        },
        driverInfo: {
          icon: "https://picsum.photos/seed/picsum/200/300",
          number: "福岡999た2325",
          type: "ごみ車　（3tパッカー）",
        },
        cards: [
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
        ],
      },
      {
        name: "三々森コース",
        id: v4(),
        driverName: {
          lastName: "太郎",
          fullName: "テスト　太郎",
        },
        driverInfo: {
          icon: "https://picsum.photos/seed/picsum/200/300",
          number: "福岡999た2325",
          type: "ごみ車　（3tパッカー）",
        },
        cards: [
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
        ],
      },
      {
        name: "小倉西コース",
        id: v4(),
        driverName: {
          lastName: "太郎",
          fullName: "テスト　太郎",
        },
        driverInfo: {
          icon: "https://picsum.photos/seed/picsum/200/300",
          number: "福岡999た2325",
          type: "ごみ車　（3tパッカー）",
        },
        cards: [],
      },
      {
        name: "二島コース",
        id: v4(),
        cards: [
          {
            id: v4(),
            field: "FM",
            name: "八幡郵便局4",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 32",
              createdAt: 1623675112632,
            },
          },
        ],
      },
      {
        name: "戸畑コース",
        id: v4(),
        driverName: {
          lastName: "太郎",
          fullName: "テスト　太郎",
        },
        driverInfo: {
          icon: "https://picsum.photos/seed/picsum/200/300",
          number: "福岡999た2325",
          type: "ごみ車　（3tパッカー）",
        },
        cards: [
          {
            id: v4(),
            field: "FM",
            name: "八幡郵便局4",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 32",
              createdAt: 1623675112632,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
        ],
      },
      {
        name: "小倉南コース",
        id: v4(),
        driverName: {
          lastName: "太郎",
          fullName: "テスト　太郎",
        },
        driverInfo: {
          icon: "https://picsum.photos/seed/picsum/200/300",
          number: "福岡999た2325",
          type: "ごみ車　（3tパッカー）",
        },
        cards: [],
      },
    ],
  },
  {
    id: v4(),
    month: new Date("2021/11/01"),
    course: [
      {
        name: "若戸コース",
        id: v4(),
        driverName: {
          lastName: "太郎",
          fullName: "テスト　太郎",
        },
        driverInfo: {
          icon: "https://picsum.photos/seed/picsum/200/300",
          number: "福岡999た2325",
          type: "ごみ車　（3tパッカー）",
        },
        cards: [
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "八幡郵便局4",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 32",
              createdAt: 1623675112632,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "八幡郵便局4",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 32",
              createdAt: 1623675112632,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "八幡郵便局4",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 32",
              createdAt: 1623675112632,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
        ],
      },
      {
        name: "若松コース",
        id: v4(),
        driverName: {
          lastName: "太郎",
          fullName: "テスト　太郎",
        },
        driverInfo: {
          icon: "https://picsum.photos/seed/picsum/200/300",
          number: "福岡999た2325",
          type: "ごみ車　（3tパッカー）",
        },
        cards: [
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "八幡郵便局4",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 32",
              createdAt: 1623675112632,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "八幡郵便局4",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 32",
              createdAt: 1623675112632,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
        ],
      },
      {
        name: "三々森コース",
        id: v4(),
        driverName: {
          lastName: "太郎",
          fullName: "テスト　太郎",
        },
        driverInfo: {
          icon: "https://picsum.photos/seed/picsum/200/300",
          number: "福岡999た2325",
          type: "ごみ車　（3tパッカー）",
        },
        cards: [
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "八幡郵便局4",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 32",
              createdAt: 1623675112632,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "八幡郵便局4",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 32",
              createdAt: 1623675112632,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
        ],
      },
      {
        name: "小倉西コース",
        id: v4(),
        driverName: {
          lastName: "太郎",
          fullName: "テスト　太郎",
        },
        driverInfo: {
          icon: "https://picsum.photos/seed/picsum/200/300",
          number: "福岡999た2325",
          type: "ごみ車　（3tパッカー）",
        },
        cards: [
          {
            id: v4(),
            field: "FM",
            name: "八幡郵便局4",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 32",
              createdAt: 1623675112632,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "八幡郵便局4",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 32",
              createdAt: 1623675112632,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "八幡郵便局4",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 32",
              createdAt: 1623675112632,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
        ],
      },
      {
        name: "二島コース",
        id: v4(),
        cards: [
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "八幡郵便局4",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 32",
              createdAt: 1623675112632,
            },
          },
        ],
      },
      {
        name: "戸畑コース",
        id: v4(),
        driverName: {
          lastName: "太郎",
          fullName: "テスト　太郎",
        },
        driverInfo: {
          icon: "https://picsum.photos/seed/picsum/200/300",
          number: "福岡999た2325",
          type: "ごみ車　（3tパッカー）",
        },
        cards: [
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "八幡郵便局4",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 32",
              createdAt: 1623675112632,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "八幡郵便局4",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 32",
              createdAt: 1623675112632,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "八幡郵便局4",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 32",
              createdAt: 1623675112632,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "若松郵便局1",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 27",
              createdAt: 1623675112627,
            },
          },
          {
            id: v4(),
            field: "FM",
            name: "西小倉郵便局5",
            productField: "不燃ごみ（3袋～5袋）",
            times: {
              date: "毎日",
              time: "07 : 00 ~ 16 : 31",
              createdAt: 1623675112631,
            },
          },
        ],
      },
      {
        name: "小倉南コース",
        id: v4(),
        driverName: {
          lastName: "太郎",
          fullName: "テスト　太郎",
        },
        driverInfo: {
          icon: "https://picsum.photos/seed/picsum/200/300",
          number: "福岡999た2325",
          type: "ごみ車　（3tパッカー）",
        },
        cards: [],
      },
    ],
  },
];
/**
 * 引数を受けてJSX Elemntを返します。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({ render, month, ...props }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation()
  const rows = useMemo(
    () =>
      INIT_STATE.find((v) => v.month?.getDate() === month?.getDate())?.course,
    [month]
  );

  const handleClickCourses = (id) => {
    const isCourses = rows.find((v) => v.id === id);
    navigate(`${location.pathname}/${id}/view`, {
      status: isCourses,
    });
  };

  return render({
    classes: classes,

    value: rows,
    onClickCourses: handleClickCourses,
    ...props,
  });
};
