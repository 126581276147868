import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  linkRouter: {
    color: theme.palette.info.main,
    fontSize: "14px",
  },
  buttonStyle: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));
