export const styles = ((theme) => ({
  tab: {
    flexDirection: "row",
    justifyContent: "flex-start",
    color: theme.palette.text.secondary,
    minHeight: theme.spacing(6),
    "& svg": {
      marginBottom: "0 !important",
      marginRight: theme.spacing(2),
      color: theme.palette.icon.mainMenu,
    },
  },
}));

