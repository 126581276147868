import React, { useState, useCallback, useMemo } from "react";
import { onEstimatedTimeArrival } from "ducks/Direction/slice";
import { useDispatch } from "react-redux";
import { DirectionsRenderer, DirectionsService } from "@react-google-maps/api";

const areEqual = (prevState, nextState) => {
  return prevState.lat === nextState.lat && prevState.lng === nextState.lng;
};

/**
 * 現在地から回収ポイントまでルートを表示します。
 * @param {number} lat 緯度
 * @param {number} lng 経度
 */
export const Direction = React.memo(({ lat, lng, origin }) => {
  const [currentDirection, setCurrentDirection] = useState(null);
  const dispatch = useDispatch();

  const directionsCallback = useCallback(
    (res) => {
      if (res !== null) {
        if (res.status === "OK") {
          const { routes } = res;
          if (routes.length === 1) {
            dispatch(onEstimatedTimeArrival(routes[0].legs[0].duration?.value));
          }
          setCurrentDirection(res);
        } else {
          console.log("response: ", res);
        }
      }
    },
    [dispatch]
  );

  const directionsServiceOptions = useMemo(() => {
    return {
      destination: { lat, lng },
      origin: origin,
      travelMode: window.google.maps.TravelMode.DRIVING,
      avoidTolls: true,
      avoidHighways: true,
    };
  }, [lat, lng, origin]);

  const directionsRendererOptions = useMemo(() => {
    return {
      directions: currentDirection,
      suppressMarkers: true,
    };
  }, [currentDirection]);

  return (
    <>
      {lat && lng && origin && (
        <DirectionsService
          options={directionsServiceOptions}
          callback={directionsCallback}
        />
      )}
      {currentDirection && (
        <DirectionsRenderer options={directionsRendererOptions} />
      )}
    </>
  );
}, areEqual);
