import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  Checkbox,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { formatDisplayDate } from "utils/format";
import { Alert, FileGallery } from "views/molecules";
import { VehicleInspectionEditForm } from "views/organisms/Mobile/Vehicle/VehicleInspectionEditForm";

/**
 * モバイルの車輛点検詳細を表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <>
      <Stack
        display={"column"}
        justifyContent="space-between"
        sx={(theme) => ({
          height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        })}
      >
        <Stack spacing={2}>
          <Stack
            direction="row"
            spacing={1}
            onClick={props.onBack}
            sx={{
              mt: 2,
              ml: 1,
            }}
          >
            <WestOutlinedIcon />
            <Typography>{"点検一覧に戻る"}</Typography>
          </Stack>
          <Stack>
            <Card
              sx={{
                p: 1,
              }}
            >
              <CardActionArea>
                <Typography
                  sx={{
                    color: "gray",
                  }}
                  variant="button"
                >
                  {"点検者"}
                </Typography>
                <Typography variant="subtitle1">
                  {props.value?.personInChargeName ?? ""}
                </Typography>
              </CardActionArea>
            </Card>
            <Card
              sx={{
                p: 1,
              }}
            >
              <CardActionArea>
                <Typography
                  sx={{
                    color: "gray",
                  }}
                  variant="button"
                >
                  {"点検日"}
                </Typography>
                <Typography variant="subtitle1">
                  {formatDisplayDate({ source: props.value?.checkedOn })}
                </Typography>
              </CardActionArea>
            </Card>
            <Card
              sx={{
                p: 1,
              }}
            >
              <CardActionArea>
                <Typography
                  sx={{
                    color: "gray",
                  }}
                  variant="button"
                >
                  {"備考"}
                </Typography>
                <Typography
                  sx={{
                    whiteSpace: "pre",
                  }}
                  variant="subtitle1"
                >
                  {props.value?.remarks ?? ""}
                </Typography>
              </CardActionArea>
            </Card>
            <Card
              sx={{
                p: 1,
              }}
            >
              <CardActionArea>
                <Typography
                  sx={{
                    color: "gray",
                  }}
                  variant="button"
                >
                  {"添付ファイル"}
                </Typography>
                <Box>
                  <FileGallery value={props.value?.files} disabled={true} />
                </Box>
              </CardActionArea>
            </Card>
          </Stack>
          {props.value?.sections?.map((section, index) => (
            <Paper
              key={index}
              sx={{
                p: 1,
              }}
            >
              <Stack direction="row" alignItems={"center"}>
                <Avatar
                  sx={{
                    backgroundColor: "black",
                    mr: 2,
                    width: "32px",
                    height: "32px",
                  }}
                >
                  {section.order + 1}
                </Avatar>
                <Typography>{section?.name ?? ""}</Typography>
              </Stack>
              <Stack>
                {section?.items?.map((item, index) => (
                  <Stack
                    key={index}
                    direction={"row"}
                    justifyContent="space-between"
                  >
                    <Stack direction={"row"} spacing={1} alignItems="center">
                      <Box>{item?.order + 1}</Box>
                      <Box>{item?.name ?? ""}</Box>
                    </Stack>
                    <Box>
                      <Checkbox checked={item?.checked} />
                    </Box>
                  </Stack>
                ))}
              </Stack>
            </Paper>
          ))}
        </Stack>
        <Box
          sx={{
            p: 2,
            position: "sticky",
            bottom: 0,
            backgroundColor: "#fff",
          }}
        >
          <Button
            variant="contained"
            fullWidth
            onClick={() => props.vehicleInspectionForm.toggle(true)}
            disabled={props.loading}
          >
            {"編集"}
          </Button>
        </Box>
      </Stack>
      <Alert />
      <VehicleInspectionEditForm
        value={props?.value}
        title={`${props?.value?.name}(${formatDisplayDate({
          source: props.value?.checkedOn,
        })})の編集`}
        open={props.vehicleInspectionForm?.open}
        onClose={() => props.vehicleInspectionForm?.toggle(false)}
        disabled={props.loading}
        onSubmit={props.onUpdate}
      />
    </>
  );
};
