import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selector,
  markAsReadSystemAlert,
  fetchSystemAlert,
} from "../../../ducks/SystemAlert";

/**
 * システムアラートを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {integer} maxCount 通知の最大表示数
 * @param {object} props その他プロパティ
 * @returns {func}
 */
export const Container = ({
  render,
  maxCount = 3,
  ...props
}) => {
  const dispatch = useDispatch();
  const data = useSelector(selector);

  useEffect(() => {
    dispatch(fetchSystemAlert());
  }, [dispatch]);

  const handleClose = (id) => (event) => {
    dispatch(
      markAsReadSystemAlert([id])
    );
  };

  return render({
    value: data?.notifications ?? [],
    onClose: handleClose,
    maxCount: maxCount,
    isLoading: data?.isLoading ?? false,
    ...props,
  });
};
