import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { searchManagementVehiclesByOffset } from "api/graphql/queries";
import { ShowMore } from "utils/useShowMore";

/**
 * 選択項目を表示するコンポーネントです。
 * @param {string} title タイトル
 * @param {string} subtitle サブタイトル
 * @fires Item#onClick クリック時
 * @returns {JSX.Element}
 */
const Item = ({ src = "", title = "", subtitle = "", onClick = () => {} }) => {
  return (
    <Card onClick={onClick}>
      <CardActionArea>
        <CardContent>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar src={src}>
                <LocalShippingOutlinedIcon />
              </Avatar>
              <Stack spacing={1}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {title ?? ""}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "gray",
                  }}
                >
                  {subtitle ?? ""}
                </Typography>
              </Stack>
            </Stack>
            <Box>
              <ChevronRightIcon />
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

/**
 * モバイルの車輛点検一覧を表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <Stack
      spacing={2}
      sx={{
        p: 2,
      }}
    >
      <Box>
        <Typography>{"車輛点検"}</Typography>
      </Box>
      <ShowMore
        query={searchManagementVehiclesByOffset}
        variables={{
          ownerCompanyId: props.company?.id,
        }}
        loadCount={10}
      >
        {({ data, loading, error, hasNext, onNext }) => {
          if (error) {
            return (
              <Alert severity="error">
                {"エラーが発生したため、表示することができませんでした。"}
              </Alert>
            );
          }

          if (data?.length <= 0 && loading === false) {
            return (
              <Alert severity="warning">{"該当の情報はありません。"}</Alert>
            );
          } else {
            return (
              <>
                {data?.map((item, index) => (
                  <Item
                    key={index}
                    title={item?.number}
                    subtitle={item?.modelName}
                    onClick={props.onClick(item?.id)}
                  />
                ))}
                {loading === true && (
                  <>
                    <Skeleton variant="rectangular" height={100} />
                    <Skeleton variant="rectangular" height={100} />
                    <Skeleton variant="rectangular" height={100} />
                    <Skeleton variant="rectangular" height={100} />
                    <Skeleton variant="rectangular" height={100} />
                    <Skeleton variant="rectangular" height={100} />
                  </>
                )}
                {loading === false && hasNext === true && (
                  <Button
                    onClick={onNext}
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      m: 1,
                    }}
                  >
                    {"さらに表示する"}
                  </Button>
                )}
              </>
            );
          }
        }}
      </ShowMore>
    </Stack>
  );
};
