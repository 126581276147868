import { Container, Dialog, Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { DatePicker, Thumbnail, Viewer } from "views/atoms";
import {
  FieldDecorateFrame,
  FileUploader,
  InputField,
  SelectPopup,
} from "views/molecules";

/**
 * 資料を入力するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="file"
            control={props.control}
            render={({ field: { value, onChange } }) => {
              return (
                <>
                  <FieldDecorateFrame label="ファイル" required={true}>
                    {(params) => (
                      <FileUploader
                        maxSize={26214400}
                        onChange={onChange}
                      />
                    )}
                  </FieldDecorateFrame>
                  <div
                    style={{
                      display: "flex",
                      direction: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {value && (
                      <Thumbnail
                        base64={value?.context}
                        deletable={true}
                        onClick={props.onOpenFile}
                        onDelete={props.onDeleteFile}
                        title={value?.name}
                        width={200}
                      />
                    )}
                  </div>
                  <Dialog
                    open={Boolean(props.open)}
                    onClose={props.onCloseFile}
                  >
                    <Viewer
                      base64={value?.context}
                      fileName={value?.name}
                      download={true}
                      deletable={true}
                      onDelete={props.onDeleteFile}
                    />
                  </Dialog>
                </>
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={`category`}
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <SelectPopup
                  label="カテゴリ"
                  required={false}
                  value={value}
                  onChange={onChange}
                  error={error}
                  options={props.DocumentCategories}
                  optionValueField="code"
                  optionNameField="name"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldDecorateFrame label="受領日">
            {(params) => (
              <Controller
                name="receivedAt"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    value={value}
                    onChange={onChange}
                    error={Boolean(error)}
                    helperText={error?.message}
                  />
                )}
              />
            )}
          </FieldDecorateFrame>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="issuer"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputField
                label="担当者"
                value={value}
                onChange={onChange}
                required={false}
                error={error}
                placeholder="担当者を入力します。"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="remarks"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputField
                label="備考"
                value={value}
                onChange={onChange}
                error={error}
                multiline={true}
                rows={3}
                placeholder="備考を入力します。"
              />
            )}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
