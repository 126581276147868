import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    "& .line": {
      backgroundColor: theme.palette.primary.light,
      "&.completed": {
        backgroundColor: theme.palette.grey[300],
      },
      "&.current": {
        backgroundColor: `${theme.palette.primary.main}`,
      },
      "&.incomplete": {
        backgroundColor: "dodgerblue",
      },
    },
    "& .number": {
      textAlign: "center",
      borderWidth: "2px",
      borderRadius: "20px",
      padding: 0,
      minWidth: "30px",
      height: theme.spacing(3),
      borderColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
      "& .MuiButton-startIcon": {
        margin: 0,
      },
      "& svg": {
        position: "absolute",
        width: "18px",
        height: "18px",
        borderRadius: "50%",
        backgroundColor: theme.palette.secondary.light,
        padding: "2px",
        top: "-9px",
        right: "-6px",
        color: theme.palette.background.paper,
      },
      "&.request": {},
      "&.completed": {
        borderColor: `${theme.palette.grey[500]} !important`,
        color: `${theme.palette.grey[500]} !important`,
      },
      "&.current": {
        borderColor: `${theme.palette.primary.main} !important`,
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: "#fff !important",
      },
      "&.incomplete": {
        borderColor: `dodgerblue !important`,
        color: `dodgerblue`,
      },
    },
  },
}));
