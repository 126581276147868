import { Box } from "@mui/material";
import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { areEqual, FixedSizeList } from "react-window";
import { CollectPoint } from "../CollectPoint";

/**
 * ドラッグされている時の行を表示するメソッドです。
 * @param {object[]} items 行リスト
 * @param {number} displayNumber 番号を表示するか
 * @returns {func}
 */
const RenderRowClone = (items) => (provided, snapshot, rubric) => {
  if (!items || !items[rubric.source.index]) {
    return null;
  }
  
  return (
    <div
      style={provided.draggableProps.style}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
    >
      <CollectPoint value={items[rubric.source.index]} />
    </div>
  );
};

/**
 * 行を表示するメソッドです。
 * @returns {func}
 */
const RenderRow = React.memo(({ data, nextDay, index, style }) => {
  const item = data.items[index];

  const displayable = data.searchWord
    ? item.name.indexOf(data.searchWord) !== -1
    : true;
  const isActive = item.id === data.activePointId;

  return (
    <Draggable draggableId={item.id} index={index} key={item.id}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            opacity: displayable ? "1.0" : "0.2",
            ...style,
          }}
        >
          <CollectPoint
            isActive={isActive}
            value={item}
            nextDay={data.nextDay}
          />
        </div>
      )}
    </Draggable>
  );
}, areEqual);

/**
 * 回収場所リストを表示するプレゼンテーションコンポーネントです。
 * @param {object[]} props プロパティ
 * @returns {JSX.Element} コンポーネント
 */
export const Presententer = (props) => (
  <div ref={props.droppableRef}>
    <Droppable
      droppableId={props.droppableId}
      isDropDisabled={props.isDropDisabled}
      renderClone={RenderRowClone(props.value, props.classes)}
      mode="virtual"
    >
      {(provided, snapshot) => (
        <>
          {!props.value || props.value.length <= 0 ? (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              backgroundColor="rgba(0, 0, 0, 0.06)"
              width={props.maxWidth}
              p={1}
            >
              <Box color="text.secondary" className={props.classes.noData}>
                データがありません。
              </Box>
            </Box>
          ) : (
            <Box
              {...provided.droppableProps}
              backgroundColor="rgba(0, 0, 0, 0.06)"
              width={props.maxWidth}
              p={1}
            >
              <FixedSizeList
                height={props.maxHeight}
                width={props.maxWidth - 16}
                itemCount={props.value.length}
                itemData={{
                  items: props.value,
                  displayNumber: true,
                  searchWord: props.searchWord,
                  nextDay: props.nextDay,
                  activePointId: props.activePointId,
                }}
                itemSize={162}
                outerRef={provided.innerRef}
                className="points"
                ref={props.listRef}
              >
                {RenderRow}
              </FixedSizeList>
            </Box>
          )}
        </>
      )}
    </Droppable>
  </div>
);
