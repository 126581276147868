export const styles = (theme) => ({
  title: {
    fontSize: "18px",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  context: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    overflowY: "auto",
  },
  actions: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    "& button": {
      marginRight: theme.spacing(2),
    },
  },
});
