import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * ユーザーボタンを表示するンコンポーネントです。
 * @param {object} value ユーザー情報
 * @param {func} onSignOut
 */
export const UserProfileButton = containerPresententer(Container, Presententer);
