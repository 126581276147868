import React from "react";
import { TabsDetails } from "views/molecules";
import { TabSimpleInformation } from "./ChildComponents";

/**
 * 車輛情報タブの表示を行うプレゼンテーションコンポーネントです
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <div>
    <TabsDetails
      getTab={props.getTab}
      tabs={[
        {
          name: "基本情報",
          tabContent: <TabSimpleInformation value={props?.value?.basic} />,
        },
      ]}
    />
  </div>
);
