import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 産業廃棄物許可証の情報を詳しく表示する。
 * @param {object} props プロパティ
 */
export const IndustrialWasteLicenseDetails = containerPresententer(
  Container,
  Presententer
);
