import { Box } from "@mui/material";
import React from "react";
import { Controller, FormProvider } from "react-hook-form";
import { FullScreenDialog, XGridComponent } from "views/molecules";
/**
 * 回収記録の編集ダイアログの表示を行うプレゼンテーションコンポーネントです。
 * @param {object} classes クラス名
 * @param {boolean} open ダイアログが有効無効を設定する
 * @param {func} onCloseDialog ダイヤログを閉じるイベントです
 *
 * @param {func} onSubmit 登録ボータンを押す時の処理
 * @param {object} methods react-form
 * @returns {JSX.Element} JSX 要素
 */
export const Presententer = (props) => {
  return (
    <FullScreenDialog
      open={props.open}
      onClose={props.onCloseDialog}
      title="【若戸コース】2021/10/01（金）の回収記録を編集"
      textConfirm="保存"
      onClickSubmit={props.onSubmit}
      container={false}
    >
      <FormProvider {...props.methods}>
        <Box>
          <Controller
          name ="collects"
          control={props.control}
          render={({field:{value,onChange}})=>(
            <XGridComponent
            rows={props.rows}
            columns={props.columns}
            onToolbar={false}
            checkboxSelection={false}
            multipleSelection={false}
            disableSelectionOnClick={false}
            disableColumnMenu={true}
            pageSizeDisplay={10}
          />
          )}
          />
          
        </Box>
      </FormProvider>
    </FullScreenDialog>
  );
};
