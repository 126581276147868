/**
 * 車検記録簿詳細整備を表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  form,
  value,
  loading,
  onUpdate,
  confirmDialog,
  onRemove,
  ...props
}) => {
  return render({
    ...props,
    form: form,
    value: value,
    loading: loading,
    onUpdate: onUpdate,
    confirmDialog: confirmDialog,
    onRemove: onRemove,
  });
};
