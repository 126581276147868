import { useImperativeHandle } from "react";
import { get } from "lodash";
import { searchUserAccountsByOffset } from "api/graphql/queries";
import { useXGridComponents } from "utils/useXGridComponents";
import { XGridComponent } from "views/molecules";
import { withForwardRef } from "utils/HoC";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

const columns = [
  {
    field: "status",
    headerName: "ステータス",
    width: 150,
    renderCell: (params) => {
      return `${params.row.state !== "active" ? "停止" : ""}`;
    },
    sortable: false,
    filterable: false,
  },
  {
    field: "roleName",
    headerName: "権限",
    width: 150,
    sortable: false,
    filterable: false,
  },
  {
    field: "name",
    headerName: "名前",
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "email",
    headerName: "メールアドレス",
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
];

/**
 * テーブルを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {boolean} props.loading 読み込み中であるか
 * @param {JSX.Element} props.actions アクション要素
 * @param {object} props.ref
 * @returns {JSX.Element}
 */
export const Table = withForwardRef(({ onSelected = () => {}, actions = <>

    </>, _ref }) => {
  const xGridObject = useXGridComponents(
    columns,
    searchUserAccountsByOffset,
    null,
    {
      filter: {
        defaultColumnField: get(columns, "[2].field"),
      },
    }
  );

  useImperativeHandle(_ref, () => ({
    refetch: () => {
      xGridObject.functions.refetch();
    },
  }));

  return (
    <XGridComponent
      onChangeSelection={onSelected}
      checkboxSelection={false}
      multipleSelection={false}
      onDownload={true}
      actionButton={actions}
      {...xGridObject.params}
    />
  );
});
