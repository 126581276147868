import { useState } from "react";
import { useStyles } from "./styles";

/**
 * PDFビューワー表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {array} files PDFファイル
 * @fires Container#onScroll スクロール時
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, width = 600, height = 600, files, onScroll, ...props }) => {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);

  const handleLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return render({
    classes: classes,
    width: width,
    height: height,
    files: files,
    onLoadSuccess: handleLoadSuccess,
    onScroll: onScroll,
    numPages: numPages,
    ...props,
  });
};
