/**
 * 妥当であるかを確認します。
 * @param {object} value 値
 * @returns {boolean}
 */
export const isValid = (value) => {
  return value?.ediValidity === "valid";
};

/**
 * 妥当ではないを確認します。
 * @param {object} value 値
 * @returns {boolean}
 */
export const isInvalid = (value) => {
  return value?.ediValidity === "invalid";
};

/**
 * 確認中であるかを確認します。
 * @param {object} value 値
 * @returns {boolean}
 */
export const isConfirming = (value) => {
  return value?.ediValidity === "unknown";
};

