import { ApplicationForm } from "../ApplicationForm";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { confirmPlanSubscribeProcedure } from "api/graphql/mutations";
import { FormPage } from "../FormPage";
import { useIAMAuthorizeMutation } from "utils/useIAMAuthorizeMutation";

/**
 * 申請ページを表示するコンポーネントです。
 * @returns {JSX.Element}
 */
export const Application = () => {
  const navigate = useNavigate();
  const search = useLocation().search;

  const queries = queryString.parse(search);

  const handleErrors = ({
    errors = [],
    monitoredTypes = true,
    onCatched = console.log,
  }) => {
    for (const error of errors) {
      if (
        (typeof monitoredTypes === "boolean" && monitoredTypes === true) ||
        (typeof monitoredTypes === "string" &&
          error.errorType === monitoredTypes) ||
        (Array.isArray(monitoredTypes) &&
          monitoredTypes.some((type) => type === error.errorType))
      ) {
        onCatched({ info: error.errorInfo });
        return true;
      }

      return false;
    }
  };

  const [confirmMutation, confirmMutationStatus] = useIAMAuthorizeMutation(
    confirmPlanSubscribeProcedure,
    {
      onError: ({ errors }) => {
        const catched = handleErrors({
          errors: errors,
          monitoredTypes: "InvalidStateException",
          onCatched: ({ info }) => {
            navigate(
              {
                pathname: "/invite_confirming/forbidden",
              },
              {
                replace: true,
              }
            );
          },
        });

        if (!catched) {
          navigate(
            {
              pathname: "/invite_confirming/error",
            },
            {
              replace: true,
            }
          );
        }
      },
    }
  );

  useEffect(() => {
    if (!!queries?.["code"]) {
      confirmMutation({
        input: {
          code: queries["code"],
        },
      });
    } else {
      navigate(
        {
          pathname: "/invite_confirming/error",
        },
        {
          replace: true,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queries?.["code"])]);

  return (
    <FormPage title={`利用申請`}>
      <ApplicationForm />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={confirmMutationStatus.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </FormPage>
  );
};
