import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import {
  useNavigate,
  useLocation,
  matchPath,
  generatePath,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import useClasses from "utils/useClasses";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { getWorkplace, getCollectionPrecaution } from "api/graphql/queries";
import {
  updateCollectionPrecaution,
  deleteCollectionPrecaution,
} from "api/graphql/mutations";
import { debugLog } from "utils/log";
import { add as addAlert } from "ducks/Alert";
import { toggle } from "ducks/Loading";
import { format as fileSizeFormat } from "views/molecules/FileUploader/Container";
import { useQuery } from "utils/useQuery";

const readBlobAsDataUrl = (blob) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new Error("Problem parsing input blob."));
    };
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
};

const formatCollectionPrecaution = (value) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = { ...value };
      for (const file of result.files) {
        const res = await fetch(file.url, {
          method: "GET",
          mode: "cors",
        });
        const blob = await res.blob();
        const dataUrl = await readBlobAsDataUrl(blob);
        file.extensions = file?.name.split(".").pop();
        file.size = fileSizeFormat(blob.size, 2);
        file.context = dataUrl;
      }
      resolve(result);
    } catch {
      reject(new Error("Problem formatting value."));
    }
  });
};

/**
 * 回収注意点詳細画面を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const classes = useClasses(useStyles);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [value, setValue] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const { id, workplaceId } = useParams();
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const workplace = useQuery({ query: getWorkplace, variables: { id: workplaceId } });

  const load = useCallback(() => {
    dispatch(toggle(true));
    API.graphql(
      graphqlOperation(getCollectionPrecaution, {
        id: id,
      })
    )
      .then((res) => {
        formatCollectionPrecaution(res.data.getCollectionPrecaution).then(
          (data) => {
            dispatch(toggle(false));
            setValue(data);
          }
        );
      })
      .catch((err) => {
        dispatch(toggle(false));
        debugLog(err);
      });
  }, [dispatch, id]);

  useEffect(() => {
    load();
  }, [load]);

  const handleGetTabNumber = (newTab) => {
    setTab(newTab);
  };

  const handleUpdate = (params) => {
    setIsSubmit(true);
    const { id, name, message, tags, files, version } = params;
    API.graphql(
      graphqlOperation(updateCollectionPrecaution, {
        input: {
          id: id,
          name: name,
          message: message,
          tags: tags,
          files:
            files?.map((file, index) => ({
              name: file.name,
              base64: file.context,
              order: index,
              label: file.name,
            })) ?? null,
          expectedVersion: version,
        },
      })
    )
      .then((res) => {
        dispatch(
          addAlert({
            value: "更新しました。",
            severity: "success",
          })
        );
        setIsOpenEditDialog(false);
        load();
      })
      .catch((err) => {
        dispatch(
          addAlert({
            value: "エラーが発生したため、更新できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleDelete = () => {
    API.graphql(
      graphqlOperation(deleteCollectionPrecaution, {
        input: {
          id: value.id,
        },
      })
    )
      .then((res) => {
        dispatch(
          addAlert({
            value: "削除しました。",
            severity: "success",
          })
        );
        // TODO：ルートを整理して共通のパスを利用するように変更する必要あり。
        const match = matchPath(
          {
            path: "/master/partner/:partnerId/workplace/:workplaceId",
            end: false,
          },
          location.pathname
        );
        if (match) {
          navigate(generatePath(match.pattern.path, match.params), {
            replace: true,
          });
        } else {
          navigate(`/`, { replace: true });
        }
      })
      .catch((err) => {
        dispatch(
          addAlert({
            value: "エラーが発生したため、削除できませんでした。",
            severity: "error",
          })
        );
      });
  };

  return render({
    ...props,
    classes: classes,
    value: value,
    tab: tab,
    getTab: handleGetTabNumber,
    isOpenEditDialog: isOpenEditDialog,
    isOpenDeleteDialog: isOpenDeleteDialog,
    onOpenEditDialog: () => setIsOpenEditDialog(true),
    onOpenDeleteDialog: () => setIsOpenDeleteDialog(true),
    onCloseEditDialog: () => setIsOpenEditDialog(false),
    onCloseDeleteDialog: () => setIsOpenDeleteDialog(false),
    onUpdate: handleUpdate,
    onDelete: handleDelete,
    isSubmit: isSubmit,
    workplace: workplace?.data?.getWorkplace
  });
};
