import { Box, Tab, Tabs } from "@mui/material";
import React from "react";

const TabPanel = (props) => {
  const { children, value, index, classes, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.tab_main_content}>{children}</Box>
      )}
    </div>
  );
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/**
 * 車輛情報タブの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @param {object} value 事業場情報
 *
 * @param {object} classes クラス名
 * @param {number} tabNumber タブ
 * @param {func} onChangeTab タブを更新する時のイベント
 * @returns
 */
export const Presententer = (props) => (
  <Box className={props.classes.wrapper}>
    <Box className={props.classes.content}>
      {props.tabs.map((tab, index) => (
        <TabPanel
          key={index}
          value={props.tabNumber}
          index={index}
          classes={props.classes}
        >
          {tab.tabContent}
        </TabPanel>
      ))}
    </Box>
    <Box className={props.classes.footer}>
      {/* <div className={props.classes.toolbar} /> */}
      <Tabs
        value={props.tabNumber}
        onChange={props.onChangeTab}
        aria-label="mobile tab details"
        TabIndicatorProps={{ className: props.classes.hiddenTabIndicator }}
        textColor="primary"
        className={props.classes.footer_tabs}
      >
        {props.tabs.map((tab, index) => (
          <Tab
            className={"tab"}
            key={index}
            label={tab.name}
            icon={tab?.icon}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
    </Box>
  </Box>
);
