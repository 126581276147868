import yup, { remarksSchema, textSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  category: yup
    .object()
    .shape({
      id: yup.string().required(),
      name: yup.string().required(),
    })
    .nullable()
    .required(),
  name: textSchema.required(),
  description: textSchema,
  remarks: remarksSchema,
});
