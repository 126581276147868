import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { styles } from "./styles";
import { contractSchema } from "./Validations";
import useClasses from "utils/useClasses";
import { debugLog } from "utils/log";
import { contractTypesSelector } from "ducks/ContractTypes";
import { useSelector } from "react-redux";

const defaultValues = {
  number: "",
  name: "",
  contractType: null,
  parties: [],
  executedOn: null,
  contractOn: {
    start: null,
    end: null,
  },
  isAutomaticallyRenewed: true,
  remarks: "",
  files: [],
};

const listContractTitle = [
  "産業廃棄物処理委託契約書",
  "産業廃棄物収集運搬委託契約書",
  "一般廃棄物処理委託契約書",
  "一般廃棄物収集運搬委託契約書",
];

const contractTypePartyNumberMap = {
  twoParties: 2,
};

/**
 * 契約情報を入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @fires Container#onSubmit 送信時
 * @fires Container#onError エラー時
 * @param {object} props その他プロパティ
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({
  render,
  value,
  id = "contract-form",
  onSubmit = (data) => {
    debugLog(data);
  },
  onError = (err) => {
    debugLog(err);
  },
  ...props
}) => {
  const contractTypes = useSelector(contractTypesSelector);
  const classes = useClasses(styles);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [contractorSelectorIndex, setContractorSelectorIndex] = useState(null);
  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(contractSchema),
  });
  const { reset, control, handleSubmit, setValue, watch } = methods;

  useEffect(() => {
    reset({
      ...defaultValues,
      ...value,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, JSON.stringify(value)]);

  const { fields } = useFieldArray({
    control,
    name: "parties",
  });

  const handleChangeContractType = (e) => {
    const selectedContractType = e.target.value;
    const selectedPartyNumber =
      selectedContractType !== undefined
        ? contractTypePartyNumberMap[selectedContractType.id]
        : 0;
    const currentParties = [...watch("parties")];
    const selectedParties = [...Array(selectedPartyNumber)].map((_, index) => {
      return {
        companyId: null,
        name: "",
      };
    });
    selectedParties.splice(
      0,
      currentParties.length,
      ...currentParties.splice(0, selectedPartyNumber)
    );
    setValue("parties", selectedParties);
  };

  const handleSelectedContractor = (params) => {
    const selectedValue = {
      name: params.name,
      companyId: params.companyId ?? params.id,
    };
    setValue(`parties[${contractorSelectorIndex}]`, selectedValue);
    setContractorSelectorIndex(null);
  };

  return render({
    classes: classes,
    control: control,
    methods: methods,
    id: id,
    submit: handleSubmit(onSubmit, onError),
    contractorSelectorIndex: contractorSelectorIndex,
    onOpenModal: (index) => setContractorSelectorIndex(index),
    onCloseModal: () => setContractorSelectorIndex(null),
    onChangeContractor: handleSelectedContractor,
    listContractTitle: listContractTitle,
    contractTypes: contractTypes,
    onChangeContractType: handleChangeContractType,
    parties: fields,
    selectedFileUrl: selectedFileUrl,
    handleSelectedFileUrlChanged: (url) => setSelectedFileUrl(url),
    ...props,
  });
};
