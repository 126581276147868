import {
  CircularProgress,
  Container,
  Dialog,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { RenderButtonToolBar } from "utils/XgridExpand";
import { If } from "views/atoms";
import { FullScreenDialog, XGridComponent } from "views/molecules";
import { LicenseGeneralWasteForm } from "../LicenseGeneralWasteForm";
import { LicenseIndustrialWasteForm } from "../LicenseIndustrialWasteForm";
import { Monitoring } from "App";
import { useContext } from "react";

/**
 * 許可証種類を選択するコンポーネントです。
 * @param {object} props プロパティ
 * @param {boolean} props.open 開閉状態
 * @fires TypeSelector#onClose 閉じる時
 * @fires TypeSelector#onClickIndustrial 産業廃棄物処理業が押された時
 * @fires TypeSelector#onClickGeneral 一般廃棄物処理業が押された時
 * @returns {JSX.Element} コンポーネント
 */
const TypeSelector = ({
  open = false,
  onClose = () => {},
  onClickIndustrial = () => {},
  onClickGeneral = () => {},
}) => {
  const { isGeneralWastesPlan, loading, error } = useContext(Monitoring);

  return (
    <Dialog open={open} onClose={onClose}>
      <If condition={!!error}>
        <Typography>{"エラーが発生しました。"}</Typography>
      </If>
      <If
        condition={loading === false}
        elseComponent={
          <Container
            maxWidth="sm"
            sx={{
              p: 2,
            }}
          >
            <CircularProgress />
          </Container>
        }
      >
        <Container
          maxWidth="sm"
          sx={{
            p: 2,
          }}
        >
          <List
            subheader={
              <ListSubheader component="div" sx={{ fontSize: 18 }}>
                登録する廃棄物許可証の種類を選択
              </ListSubheader>
            }
          >
            <If condition={!isGeneralWastesPlan}>
              <ListItem disablePadding>
                <ListItemButton onClick={onClickIndustrial}>
                  <ListItemText primary="産業廃棄物処理業" />
                </ListItemButton>
              </ListItem>
            </If>
            <ListItem disablePadding>
              <ListItemButton onClick={onClickGeneral}>
                <ListItemText primary="一般廃棄物処理業" />
              </ListItemButton>
            </ListItem>
          </List>
        </Container>
      </If>
    </Dialog>
  );
};

/**
 * 許可証情報を表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <>
      <XGridComponent
        onChangeSelection={props.onChangeSelection}
        checkboxSelection={false}
        multipleSelection={false}
        onDownload={true}
        actionButton={
          <RenderButtonToolBar
            label="新しい廃棄物許可証を追加"
            onClick={props.onOpenSelectModal}
          />
        }
        {...props.xGridParams}
      />
      <TypeSelector
        open={props.openModal}
        onClose={props.onCloseSelectModal}
        onClickGeneral={props.onOpenDialogGeneral}
        onClickIndustrial={props.onOpenDialogIndustrial}
      />
      <FullScreenDialog
        open={props.openDialogIndustrial}
        formId="IndustrialWaste"
        title="新しい産業廃棄物許可証の登録"
        textConfirm="登録"
        onClose={props.onCloseDialogIndustrial}
        isSubmit={props.loading}
      >
        <LicenseIndustrialWasteForm
          id="IndustrialWaste"
          onSubmit={props.onSubmitIndustrialWaste}
        />
      </FullScreenDialog>
      <FullScreenDialog
        open={props.openDialogGeneral}
        formId="GeneralWaste"
        title="新しい一般廃棄物許可証の登録"
        textConfirm="登録"
        onClose={props.onCloseDialogGeneral}
        isSubmit={props.loading}
      >
        <LicenseGeneralWasteForm
          id="GeneralWaste"
          onSubmit={props.onSubmitGeneralWaste}
        />
      </FullScreenDialog>
    </>
  );
};
