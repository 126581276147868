import { useState } from "react";
import { styles } from "./styles";
import useClasses from "utils/useClasses";

/**
 * アカウント管理を表示するコンテナコンポーネントです。
 * @param {*} param0
 * @returns
 */
export const Container = ({ render, open = false, onClose, ...props }) => {
  const classes = useClasses(styles);
  const [tab, setTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  return render({
    classes: classes,
    open,
    onClose: onClose,

    tab: tab,
    onChangeTab: handleChangeTab,

    ...props,
  });
};
