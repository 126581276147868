import { Grid } from "@mui/material";
import React from "react";
import { XGridComponent } from "views/molecules";

/**
 *  回収記録メインの表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props
 * @returns JSX 要素
 */
export const Presententer = (props) => (
  <Grid container spacing={2} mt={2}>
    <Grid item md={12} xs={12}>
      <XGridComponent
        rows={props.rows}
        columns={props.columns}
        onToolbar={false}
        checkboxSelection={false}
        multipleSelection={false}
        disableSelectionOnClick={false}
        disableColumnMenu={true}
        pageSizeDisplay={10}
      />
    </Grid>
  </Grid>
);
