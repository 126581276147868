import {
  CloudDownloadOutlined,
  FilterListOutlined,
  ViewColumnOutlined,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import {
  DataGridPro,
  GridOverlay,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  jaJP,
} from "@mui/x-data-grid-pro";
import clsx from "clsx";
import React from "react";
import { RenderSVG } from "views/atoms";
import { TextSearcher } from "views/molecules";

const CustomToolbar = (props) => {
  const isSpecifiedTools =
    props.onSearcher ||
    props.onColumns ||
    props.onFilter ||
    props.onDownload ||
    props.actionButton !== undefined;

  return (
    <div className={props.classes.header}>
      <Typography variant="h6">{props?.titleTable}</Typography>
      {isSpecifiedTools && (
        <GridToolbarContainer className={props.classes.toolbar_container}>
          <div className={props.classes.header_left}>
            {props.onSearcher && (
              <div
                className={clsx(
                  props.classes.header_search,
                  "guiderXGridSearch"
                )}
              >
                <TextSearcher onChangeSearch={props.onSearchByText} />
              </div>
            )}
            {props.onColumns && (
              <GridToolbarColumnsButton
                variant="outlined"
                startIcon={<ViewColumnOutlined />}
                className={clsx(
                  "guiderXGridColumn",
                  props.classes.header_button
                )}
              />
            )}
            {props.onFilter && (
              <GridToolbarFilterButton
                variant="outlined"
                startIcon={<FilterListOutlined />}
                className={clsx(
                  props.classes.header_button,
                  props.classes.header_button_filter,
                  "guiderXGridFilter"
                )}
              />
            )}
            {props.onDownload && (
              <GridToolbarExport
                variant="outlined"
                csvOptions={{
                  utf8WithBom: true,
                }}
                printOptions={{
                  disableToolbarButton: true,
                }}
                startIcon={<CloudDownloadOutlined />}
                className={clsx(
                  "guiderXGridExport",
                  props.classes.header_button,
                  props.classes.header_button_filter,
                )}
              />
            )}
            {props?.customTools}
          </div>
          <div
            className={clsx(props.classes.header_right, "guiderToolbarButton")}
          >
            {props?.actionButton}
          </div>
        </GridToolbarContainer>
      )}
    </div>
  );
};

function CustomNoRowsOverlay(props) {
  return (
    <GridOverlay className={props.classes.noRows_root}>
      <RenderSVG />
      <div className={props.classes.noRows_label}>行がありません</div>
    </GridOverlay>
  );
}

/**
 * 事業場の選択を表示するコンポーネントです。
 * @param {object} classes クラス名
 * @param {array} columns テーブルのコラム
 * @param {array} rows　テーブルの行
 * @param {boolean} onToolbar ツールバーが隠れるようにする
 * @param {boolean} onSearcher フリー検索が隠れるようにする
 * @param {object} actionButton 別のボータンが表示　例：新しい事業所。事業場を登録
 * @param {func} onClickOpenDialog ダイヤログを開くイベントです。
 * @param {boolean} multipleSelection 複数行を選択する可能性が無効化有効にする
 * @param {func} onCloseModal モーダルを閉じるイベントです
 * @param {func} onSubmit 変更ボータンを押す時のイベントです
 * @param {array} selectionModel 選択された行
 * @param {number} pageSize ページに表示する行数を設定する
 * @param {number} onPageSizeChange ページに表示する行が変更された時のイベントです
 * @param {number} onChangeSelectionModel　選択された行を取得する
 * @param {boolean} checkboxSelection 行頭にチェックボックスを表示する
 * @param {boolean} disableSelectionOnClick 行をクリックしないようにする
 * @param {string} titleTable テーブルのタイトル
 * @param {boolean} onDownload ダウンロードのボータンを有無に設定する
 * @returns {JSX.Element} JSX 要素
 */

export const Presententer = (props) => {
  return (
    <>
      <DataGridPro
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        className={clsx(
          props.classes.content_table,
          props.multipleSelection === false
            ? props.classes.hidenCheckBoxHeader
            : ""
        )}
        //カラム
        columns={props.columns}
        rows={props.rows}
        //行の高さを自動的に調整する
        autoHeight={props.autoHeight}
        //XGridのコンポーネントです
        components={{
          Toolbar: props.onToolbar && CustomToolbar,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        onFilterModelChange={props.onFilterModelChange}
        //XGridのコンポーネントに引数を渡す
        componentsProps={{
          toolbar: {
            classes: props.classes,
            onClickOpenDialog: props.onClickOpenDialog,
            actionButton: props.actionButton,
            titleTable: props.titleTable,
            onSearcher: props.onSearcher,
            onColumns: props.onColumns,
            onFilter: props.onFilter,
            onDownload: props.onDownload,
            onSearchByText: props.onSearchByText,
            customTools: props.customTools,
          },
          noRowsOverlay: {
            classes: props.classes,
          },
        }}
        //ヘーダの高さ
        headerHeight={48}
        //ページに表示する行数を設定する
        pageSize={props.pageSize}
        //ページに表示する行数が変更された時のイベントです
        onPageSizeChange={props.onPageSizeChange}
        onPageChange={props.onPageChange}
        page={props.page}
        rowCount={props.rowCount}
        //配列行数
        rowsPerPageOptions={[10, 20, 50, 100]}
        //ページ付け
        pagination
        //行頭にチェックボックスを表示する
        checkboxSelection={props.checkboxSelection}
        //選択された行
        selectionModel={props.selectionModel}
        //選択された行数を非表示に設定する
        hideFooterSelectedRowCount
        //行を選択する時のイベントです
        onSelectionModelChange={(selection) => {
          // const newSelectionModel = selection.selectionModel;
          const newSelectionModel = selection;

          if (
            newSelectionModel?.length > 1 &&
            props.multipleSelection === false
          ) {
            const selectionSet = new Set(props.selectionModel);
            const result = newSelectionModel.filter(
              (s) => !selectionSet.has(s)
            );

            props.onChangeSelectionModel(result);
          } else {
            props.onChangeSelectionModel(newSelectionModel);
          }
        }}
        //複数行選択を無効にする
        disableMultipleSelection={true}
        // showToolbar
        disableSelectionOnClick={props.disableSelectionOnClick}
        //マウスポインターを変える
        getCellClassName={() =>
          `${props.disableSelectionOnClick ? "" : props.classes.onCursor}`
        }
        //カラムの幅の調整を無効にする
        disableColumnResize={false}
        paginationMode={props.paginationMode}
        sortModel={props.sortModel}
        onSortModelChange={props.onSortModelChange}
        {...props}
      />
    </>
  );
};
