import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Toolbar,
  Typography,
  Stack,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { If } from "views/atoms";

/**
 * フォームのページを表示するコンポーネント。
 * @param {object} props プロパティ
 * @param {string} props.title タイトル
 * @param {JSX.Element} props.children 子要素
 * @returns {JSX.Element}
 */
export const FormPage = ({ title = "", children = <></> }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar elevation={0} component="nav">
        <Toolbar>
          <Stack spacing={2} direction="row">
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
            <If condition={process.env.REACT_APP_IS_DEMO === "true"}>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontWeight: "bold",
                }}
              >
                {"DEMO"}
              </Typography>
            </If>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          p: 2,
          backgroundColor: grey[200],
          width: "100vw",
          minHeight: "100vh",
          height: "100%",
        }}
      >
        <Toolbar />
        <Container>{children}</Container>
      </Box>
    </Box>
  );
};
