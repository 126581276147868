import PropTypes from "prop-types";
import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * アイコンや場所情報を表示するコンポーネントです
 * @param {object} marker
 * @param {number} index
 * @param {number} noOfMarkers
 * @param {func} onClickMarker
 * @param {bool} isActive
 * @param {bool} animate
 */
export const Marker = containerPresententer(Container, Presententer);

Marker.propTypes = {
  marker: PropTypes.object,
  index: PropTypes.number,
  noOfMarkers: PropTypes.number,
  onClickMarker: PropTypes.func,
  isActive: PropTypes.bool,
  animate: PropTypes.bool,
};
