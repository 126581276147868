import React from "react";
import { RenderButtonToolBar } from "utils/XgridExpand";
import { XGridComponent, FullScreenDialog } from "views/molecules";
import { VehicleForm } from "views/organisms/Masters/Vehicle";

/**
 * 事業者・事業場の表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <>
      <XGridComponent
        autoHeight={props.autoHeight}
        onClose={props.onClose}
        onChangeSelection={props.onSelected}
        checkboxSelection={props.checkbox}
        multipleSelection={props.multiple}
        titleTable={props.title}
        onDownload={true}
        actionButton={
          props.action && (
            <RenderButtonToolBar
              label="新しい車輛を追加"
              className="vehicleTable"
              onClick={props.onOpenDialog}
            />
          )
        }
        {...props.xGridParams}
      />
      <FullScreenDialog
        formId="vehicle-form"
        title={"新しい車輛の追加"}
        textConfirm={"登録"}
        open={props.open}
        onClose={props.onCloseDialog}
        isSubmit={props.isSubmit}
        disabled={props.isSubmit}
      >
        <VehicleForm
          id="vehicle-form"
          onSubmit={props.onSubmit}
          companyId={props.companyId}
        />
      </FullScreenDialog>
    </>
  );
};
