import { useSelector } from "react-redux";
import { userSelector } from "ducks/User";

export const Container = ({ render, ...props }) => {
  const currentUser = useSelector(userSelector);

  return render({
    isNotGeneralUser:
      currentUser?.roleName === "オーナー" ||
      currentUser?.roleName === "管理者",
    ...props,
  });
};
