import { getJwnetManifest } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { toggle, selector } from "ducks/Loading";
import { DateTime } from "luxon";
import { useCallback, useEffect, useRef, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { debugLog } from "utils/log";
import {
  requestJwnetTransportReportRegistration,
  requestJwnetTransportReportChanges,
  requestJwnetTransportReportCancellation,
} from "api/graphql/mutations";
import { listJwnetManifestRequests } from "api/graphql/queries";

/**
 * 電子マニフェストの詳細画面を表示するコンテナコンポーネントです。
 * @param {object} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns
 */
export const Container = ({ render, ...props }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [manifest, setManifest] = useState(null);
  const [requestingSections, setRequestingSections] = useState(null);
  const [error, setError] = useState(null);
  const globalLoading = useSelector(selector).status;
  const [tab, setTab] = useState(1); // 報告できる最初の区間の割り出し処理が必要
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  useErrorHandler(error);

  const requesting = () => {
    return API.graphql(
      graphqlOperation(listJwnetManifestRequests, {
        manifestId: id,
      })
    )
      .then((res) => {
        setRequestingSections(res.data.listJwnetManifestRequests.items);
      })
      .catch((err) => {
        debugLog("送信中の要求内容の取得に失敗");
      });
  };

  const cancel = (data) => {
    setIsSubmit(true);
    return API.graphql(
      graphqlOperation(requestJwnetTransportReportCancellation, {
        input: {
          manifestId: id,
          sectionNumber: data.sectionNumber,
          memberNumber: data.memberNumber,
        },
      })
    )
      .then((res) => {
        debugLog(
          "収集運搬取消成功: ",
          res?.data?.requestJwnetTransportReportCancellation
        );
        load();
      })
      .catch((err) => {
        debugLog("収集運搬取消失敗: ", err);
        setError(
          "ElectronicManifestView - requestJwnetTransportReportCancellation"
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const modify = (data) => {
    setIsSubmit(true);

    const { manifestApprovalPendingStatus, ...other } = data;
    const { transportationEndedOn, quantity, valuablesQuantity } = other;

    return API.graphql(
      graphqlOperation(requestJwnetTransportReportChanges, {
        input: {
          ...other,
          manifestId: id,
          transportationEndedOn: DateTime.fromJSDate(
            transportationEndedOn
          ).toISODate(),
          transportQuantity: quantity.value,
          transportWasteQuantityUnitCode: quantity?.unit?.code,
          valuablesQuantity: valuablesQuantity?.value,
          valuablesQuantityUnitCode: valuablesQuantity?.unit?.code,
          quantity: undefined,
        },
      })
    )
      .then((res) => {
        debugLog(
          "収集運搬修正成功: ",
          res?.data?.requestJwnetTransportReportChanges
        );
        load();
      })
      .catch((err) => {
        debugLog("収集運搬修正失敗: ", err);
        setError("ElectronicManifestView - requestJwnetTransportReportChanges");
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const report = (data) => {
    setIsSubmit(true);

    const { manifestApprovalPendingStatus, ...other } = data;
    const { transportationEndedOn, quantity, valuablesQuantity } = other;

    return API.graphql(
      graphqlOperation(requestJwnetTransportReportRegistration, {
        input: {
          ...other,
          manifestId: id,
          transportationEndedOn: DateTime.fromJSDate(
            transportationEndedOn
          ).toISODate(),
          transportQuantity: quantity.value,
          transportWasteQuantityUnitCode: quantity?.unit?.code,
          valuablesQuantity: valuablesQuantity?.value,
          valuablesQuantityUnitCode: valuablesQuantity?.unit?.code,
          quantity: undefined,
        },
      })
    )
      .then((res) => {
        debugLog(
          "収集運搬報告成功: ",
          res?.data?.requestJwnetTransportReportRegistration
        );
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
      })
      .catch((err) => {
        debugLog("収集運搬報告失敗: ", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
        setError("ElectronicManifestView - report");
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const load = useCallback(async () => {
    dispatch(toggle(true));
    await requesting();
    return API.graphql(
      graphqlOperation(getJwnetManifest, {
        id: id,
      })
    )
      .then((res) => {
        const result = res.data.getJwnetManifest;
        const t =
          result.wasteTransportSections?.find(
            (section) => section.sectionNumber === tab
          )?.[0]?.sectionNumber ?? 1;
        debugLog("getJwnetManifestの実行に成功: ", result);
        setManifest(result);
        setTab(t);
      })
      .catch((err) => {
        debugLog("getJwnetManifestの実行に失敗: ", err);
        setError("ElectronicManifestView - getJwnetManifest");
      })
      .finally(() => {
        dispatch(toggle(false));
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    load();
  }, [load]);

  const handleReport = (data) => {
    report(data).then(() => {
      load()?.then?.(() => {
        setOpen(false);
      });
    });
  };

  const handleEdit = (data) => {
    modify(data);
  };

  const handleCancel = (data) => {
    cancel(data);
  };

  const existsRequesting = () => {
    return requestingSections && (requestingSections?.length ?? 0) > 0;
  };

  const allRequesting = () => {
    if (
      !manifest?.wasteTransportSections ||
      (manifest?.wasteTransportSections?.length ?? 0) <= 0
    ) {
      // 区間は存在しない
      return false;
    }

    if (!existsRequesting()) {
      // 処理中のものは無い
      return false;
    }

    return manifest?.wasteTransportSections?.every((section) =>
      requestingSections
        .map((section) => section.sectionNumber)
        .includes(section.sectionNumber)
    );
  };

  return render({
    value: manifest,
    open: open,
    isSubmit: isSubmit,
    loading: globalLoading,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    formRef: formRef,
    onReport: handleReport,
    onEdit: handleEdit,
    onCancel: handleCancel,
    tab: tab,
    onChangeTab: handleChangeTab,
    requestingSections: requestingSections,
    existsRequesting: existsRequesting(),
    allRequesting: allRequesting(),
    ...props,
  });
};
