import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { Avatar, Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { ConfirmForm } from "views/organisms/ContainerManage/ConfirmForm";
import { Details } from "views/organisms/ContainerManage/Details";
import { DetailsFrame } from "views/organisms/DetailsFrame";

const formatText = (value, format = () => null) => {
  if (!value || value === 0) {
    return null;
  } else {
    return format(value);
  }
};

const formatCaption = (value) => {
  if (!value) {
    return null;
  }

  return [
    formatText(value.width, (v) => `幅 ${v}mm`),
    formatText(value.height, (v) => `高さ ${v}mm`),
    formatText(value?.depth, (v) => `奥行 ${v}mm`),
    formatText(value?.volume, (v) => `容積 ${v}㎥`),
    formatText(value?.number, (v) => `管理番号 ${v}`),
  ]
    .filter((v) => v && v !== "")
    .join(" / ");
};

/**
 * コンテナ管理の詳細を表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  const getDaysElapsed = (installedOn) => {
    if (!installedOn) {
      return "";
    }

    return `経過日数：${Math.floor(
      -1 * DateTime.fromISO(installedOn).diffNow("days").days
    )}日`;
  };

  return (
    <>
      <Box>
        <DetailsFrame
          caption={
            <Box display={"flex"} flexDirection={"row"}>
              <Box>
                <Avatar
                  sx={{
                    marginRight: 3,
                    width: 58,
                    height: 58,
                  }}
                >
                  <InsertPhotoIcon sx={{ fontSize: "2.5rem" }} />
                </Avatar>
              </Box>
              <Stack>
                <Typography
                  sx={{
                    fontSize: "26px",
                    fontWeight: "bold",
                  }}
                >
                  <Stack spacing={2} direction="row">
                    {props.value?.product?.number ? (
                      <div>{`[${props.value?.product?.number}]`}</div>
                    ) : null}
                    {props.value?.product?.manufacturerName ? (
                      <div>{`${props.value?.product?.manufacturerName}`}</div>
                    ) : null}
                    {props.value?.product?.modelNumber ? (
                      <div>{props.value?.product?.modelNumber}</div>
                    ) : null}
                  </Stack>
                </Typography>
                <Box display="flex" flexDirection={"row"} alignItems="center">
                  <Typography
                    sx={{
                      marginRight: 2,
                      color: "gray",
                    }}
                  >
                    {formatCaption({
                      width: props.value?.product?.width,
                      height: props.value?.product?.height,
                      depth: props.value?.product?.depth,
                      volume: props.value?.product?.volume,
                      number: props.value?.number,
                    })}
                  </Typography>
                  <Chip
                    avatar={<AccessTimeIcon />}
                    label={getDaysElapsed(props.value?.installedOn)}
                  />
                </Box>
              </Stack>
            </Box>
          }
          actions={
            <>
              <Button
                variant="contained"
                onClick={() => props.confirmDialog.toggle(true)}
                disabled={props.stopMutationStatus?.loading || props?.loading}
              >
                {"現在地の確認を行う"}
              </Button>
            </>
          }
        >
          <Details
            value={props.details}
            basic={props.value}
            disabled={props.stopMutationStatus?.loading || props?.loading}
          />
        </DetailsFrame>
      </Box>
      <ConfirmForm
        details={props.details}
        open={props.confirmDialog.open}
        onClose={() => props.confirmDialog.toggle(false)}
        onSubmit={props.onUpdateLastConfirm}
        disabled={props.loading}
      />
    </>
  );
};

export default Presententer;
