import React from "react";
import { grey } from "@mui/material/colors";

/**
 * InformationItemコンポーネントをグループ化するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <div
    style={{
      paddingBottom: "15px",
      ...props.style,
    }}
    className={props.className}
  >
    <Subheader subheader={props.subheader} />
    <div className="content">{props.children}</div>
  </div>
);

/**
 * サブヘッダーを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
const Subheader = (props) => {
  // nullやundefined
  if (!props.subheader) {
    return <></>;
  }

  // React.Element
  if (React.isValidElement(props.subheader)) {
    return <div className="subheader">{props.subheader}</div>;
  }

  // 文字列
  if (typeof props.subheader === "string") {
    return (
      <div
        className="subheader"
        style={{
          paddingBottom: "10px",
        }}
      >
        <span
          style={{
            textDecoration: "underline",
            color: grey[600],
            fontSize: "17px",
            fontWeight: 500,
          }}
        >
          {props.subheader}
        </span>
      </div>
    );
  }

  // 不明
  return <></>;
};
