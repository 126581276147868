import { useCallback, useState } from "react";

/**
 * 引数を受けてJSX Elemntを返します。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({ render, ...props }) => {
  const { id, value, api, field } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(true);
  const handleChange = (event) => {
    api.setEditCellValue({ id, field, value: event.target.value }, event);
    api.commitCellChange({ id, field });
  };
  const handleClose = () => {
    setOpen(false);
    api.setCellMode(id, field, "view");
  };

  const handleRef = (element) => {
    if (element) {
      setAnchorEl(element);
    }
  };

  const handleSubmit = useCallback(() => {
    setOpen(false);
    api.commitCellChange({ id, field });
    api.setCellMode(id, field, "view");
  }, [api, id, field]);

  return render({
    handleRef: handleRef,
    id: id,
    open: open,
    anchorEl: anchorEl,
    handleClose: handleClose,
    value: value,
    handleChange: handleChange,
    handleSubmit: handleSubmit,

    ...props,
  });
};
