import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { getIndividualityWasteCollectionOrder } from "api/graphql/queries";
import { deleteIndividualityWasteCollectionOrder } from "api/graphql/mutations";
import { debugLog } from "utils/log";
import { toggle } from "ducks/Loading";
import { useDispatch } from "react-redux";

/**
 * スポット回収詳細情報を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const { id } = useParams();
  const [value, setValue] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggle(true));
    API.graphql(
      graphqlOperation(getIndividualityWasteCollectionOrder, {
        id: id,
      })
    )
      .then((res) => {
        setValue(
          res.data.getIndividualityWasteCollectionOrder
        );
      })
      .catch((err) => {
        debugLog("スポット回収詳細.失敗: ", err);
      })
      .finally(() => {
        dispatch(toggle(false));
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const update = (data) => {
    // UNDONE: api待ち
    /*
    const {
      ...other
    } = data;

    return API.graphql(graphqlOperation(createAPI, {
      input: {
        ...other
      }
    }))
    */

    return Promise.resolve();
  };

  const deleteApi = (value) => {
    const { id } = value;
    return API.graphql(
      graphqlOperation(deleteIndividualityWasteCollectionOrder, {
        input: { id: id },
      })
    );
  };

  return render({
    value: value,
    submit: update,
    exeDelete: deleteApi,
    ...props,
  });
};
