import { listWasteDisposalMethods } from "api/graphql/queries";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "utils/graphqlOperation";

/**
 * 処分方法を取得します。
 * @returns {object}
 */
const fetch = async () => {
  try {
    const fetchedData = await API.graphql(
      graphqlOperation(listWasteDisposalMethods)
    );
    return fetchedData.data.listWasteDisposalMethods.items;
  } catch (error) {
    throw error;
  }
};

export const fetchDisposalMethods = createAsyncThunk(
  "disposalMethods/fetch",
  async (_, thunkApi) => {
    return fetch();
  }
);
