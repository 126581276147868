import { useRef, useEffect } from "react";

/**
 * Custom React hook to track and retrieve the previous value of any input value using useRef and useEffect.
 * @param {*} value - The value that you want to keep track of and get the previous value of.
 * @returns The previous value of the input value.
 */
export const usePreviousValue = (value) => {
  const previousValueRef = useRef(null);
  useEffect(() => {
    previousValueRef.current = value;
  }, [value]);

  return previousValueRef.current;
};

/**
 * The function `useIsFirstRender` returns `true` on the first render and `false` on subsequent
 * renders.
 * @returns a boolean value. If it is the first render, it returns true. Otherwise, it returns false.
 */
export function useIsFirstRender() {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}

export function useIsMounted() {
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return isMountedRef;
}
