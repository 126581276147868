import yup, { textSchema, remarksSchema, dateSchema } from "utils/yup";

export const contractSchema = yup.object().shape({
  partnerCompanyId: textSchema,
  number: textSchema,
  name: textSchema.required(),
  contractType: yup.object().required().typeError("選択してください"),
  parties: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          companyId: yup.string().nullable().required(),
        })
        .test(
          "duplicates",
          "同じ事業者が設定されています。",
          (value, context) => {
            return (
              value.companyId == null ||
              !context.parent ||
              context.parent.length ===
                new Set(context.parent.map((i) => i.companyId)).size
            );
          }
        )
    )
    .test("includePartner", "契約者に取引先が設定されていません。", (value, context) => {
      const partnerCompanyId = context.parent.partnerCompanyId;
      if (partnerCompanyId == null || value.length === 0) {
        return true;
      }
      return (
        value.some((i) => i.companyId == null) || value.some((i) => i.companyId === partnerCompanyId)
      );
    })
    .required(),
  executedOn: dateSchema.required(),
  contractOn: yup
    .object()
    .shape({
      start: dateSchema.required(),
      end: dateSchema.required(),
    })
    .test(
      "contradiction",
      "開始日より後の日付を入力してください",
      function (value) {
        if (!value?.start || !value?.end) {
          return true;
        } else {
          return value.start < value.end;
        }
      }
    )
    .required(),
  isAutomaticallyRenewed: yup.boolean().required(),
  remarks: remarksSchema,
  files: yup.array().nullable(),
});
