import useClasses from "utils/useClasses";
import { styles } from "./styles";

/**
 * 詳細ページの枠組みを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {JSX.Element} caption 表題コンポーネント
 * @param {JSX.Element} actions 行動コンポーネント
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  caption = <></>,
  actions = <></>,
  ...props
}) => {
  const classes = useClasses(styles);

  return render({
    classes: classes,
    actions: actions,
    caption: caption,
    ...props,
  });
};
