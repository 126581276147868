import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Box,
  Card,
  CardActionArea,
  IconButton,
  Typography,
} from "@mui/material";

/**
 * 設定を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.title タイトル
 * @fires Config#onClick クリック時
 * @param {JSX.Element} props.children 子要素
 * @returns {JSX.Element}
 */
export const BellConfig = ({ title, onClick, children }) => {
  return (
    <Card onClick={onClick}>
      <CardActionArea>
        <Box
          display="flex"
          flexDirection={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Box display="flex" flexDirection={"row"}>
            <NotificationsActiveOutlinedIcon
              sx={{
                marginLeft: "8px",
                marginRight: "16px",
              }}
            />
            <Typography>{title}</Typography>
          </Box>
          <Box display="flex" flexDirection={"row"} alignItems={"center"}>
            {children}
            <IconButton
              aria-label="settings"
              sx={{
                marginLeft: "16px",
                marginRight: "8px",
              }}
            >
              <SettingsOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
};
