import reducer from "./slice";
import {
  selector,
  wasteQuantityUnitsSelector,
  isLoadingSelector,
  errorSelector,
} from "./slice";
import { fetchWasteQuantityUnits } from "./api";

export default reducer;

export {
  wasteQuantityUnitsSelector,
  selector,
  isLoadingSelector,
  errorSelector,
  fetchWasteQuantityUnits,
};
