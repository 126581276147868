import { Box, Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import {
  // ClientSelector,
  ClientWorkplaceSelector,
  DatePicker,
  InputField,
  InputFieldPopppSelect,
  ModalPopup,
  TimeRangePicker,
} from "views/molecules";
import { FieldDecorateFrame } from "views/molecules";
// import { ComanyCategories } from "utils/Context";

/**
 * 基本情報を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Elemnt}
 */
export const Presententer = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          name={"dischargesWorkplace"}
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <InputFieldPopppSelect
                label={"排出事業場"}
                value={
                  value
                    ? {
                        id: value.id,
                        name: `${value.belongInCompanyName} ${value.name}`,
                      }
                    : null
                }
                onChange={onChange}
                required={true}
                placeholder={"排出事業場を選択します。"}
                error={error}
                onOpenModal={props.onOpenModal("clientWorkplace")}
                textConfirm="選択"
              />
              {/* 排出事業場選択, 追加なし */}
              <ModalPopup
                open={props.open === "clientWorkplace"}
                onClose={props.onCloseModal}
              >
                <Box pt={3} pb={3} sx={{height: "85vh"}}>
                  <ClientWorkplaceSelector
                    autoHeight={false}
                    value={props.selecedValue}
                    onSelected={props.onSelectedClientWorkplace}
                    fixedOptions={{
                      filter: {
                        and: [
                          {
                            categoryId: {
                              eq: "dischargeSite",
                            },
                          },
                        ],
                      },
                    }}
                  />
                </Box>
              </ModalPopup>
            </>
          )}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <Controller
          name={"transporter"}
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <InputFieldPopppSelect
                label={"収集運搬業者"}
                value={value}
                onChange={onChange}
                required={true}
                placeholder={"収集運搬業者を選択します。"}
                error={error}
                onOpenModal={props.onOpenModal("client")}
                textConfirm="選択"
              />
              <ModalPopup
                open={props.open === "client"}
                onClose={props.onCloseModal}
              >
                <Box pt={3} pb={3}>
                  <ClientSelector
                    supplierCompanyId={"b"}
                    value={props.selecedValue}
                    onSelected={props.onSelectedClient}
                    // UNDONE: apiに事業場区分が追加されたら対応する
                    // fixedOptions={{
                    //   filter: {
                    //     categories: { eq: ComanyCategories.filter(item => item.name === "排出者").map(item => item.code) }
                    //   }
                    // }}
                  />
                </Box>
              </ModalPopup>
            </>
          )}
        />
      </Grid> */}
      <Grid item xs={12}>
        <Controller
          name="date"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <DatePicker
              label="回収日"
              value={value}
              onChange={onChange}
              required={true}
              error={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="times"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FieldDecorateFrame label="回収時間" required={true}>
              {(params) => (
                <TimeRangePicker
                  value={value}
                  onChange={onChange}
                  error={Boolean(error)}
                  minutesStep={5}
                  helperText={
                    error?.message ||
                    error?.start?.message ||
                    error?.end?.message
                      ? "必須入力項目です"
                      : ""
                  }
                />
              )}
            </FieldDecorateFrame>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="remarks"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="備考"
              placeholder="特記事項があれば入力します。"
              multiline
              rows={4}
              value={value}
              onChange={onChange}
              error={error}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
