import yup from "utils/yup";

export const basicScheme = yup.object().shape({
  waste: yup.object().default(null).required(),
  quantity: yup.number().default(null).required(),
  files: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      extensions: yup.string(),
      size: yup.string(),
      context: yup.string(),
    })
  ),
});
