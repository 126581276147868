import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box, TextField } from "@mui/material";
import jaLocale from "date-fns/locale/ja";
import React from "react";

/**
 * 時間を編集するテキストの表示を行うプレゼンテーションコンポーネントです。
 * @param {string} value 備考の値
 * @param {func} handleChange 値を更新するイベント
 * @returns {JSX.Element} JSX 要素
 */
export const Presententer = (props) => {
  return (
    <Box>
      <LocalizationProvider
        utils={DateFnsUtils}
        adapterLocale={jaLocale}
        dateAdapter={AdapterDateFns}
      >
        <TimePicker
          ampm={false}
          openTo="hours"
          views={["hours", "minutes"]}
          inputFormat="HH:mm:ss"
          mask="__:__:__"
          value={props.value}
          onChange={props.handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ "& .MuiOutlinedInput-notchedOutline": { borderWidth: 0 } }}
            />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};
