import yup, { textSchema, emailSchema, phoneSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  industrySector: yup.object().nullable(),
  name: textSchema.required(),
  postalCode: yup.string().postalCode().required(),
  prefectures: yup.object().required(),
  city: textSchema.required(),
  streetAddress: textSchema.required(),
  otherAddress: textSchema.required(),
  phone: phoneSchema.nullable(),
  fax: phoneSchema.nullable(),
  email: emailSchema.nullable(),
});
