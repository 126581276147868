import { useLocation, useNavigate } from "react-router-dom";

/**
 * 車輛管理画面を表示するコンテナコンポーネントです
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (param) => {
    navigate(`${location.pathname}/${param}`);
  };

  return render({
    onClick: handleClick,
    ...props,
  });
};
