import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import React from "react";
import { TimePicker } from "views/atoms";

/**
 * 時間の期間を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  label,
  error,
  helperText,
  value,
  onChange,
  minutesStep,
  ...props
}) => {
  return (
    <FormGroup>
      <FormLabel
        sx={{
          fontSize: "14px",
        }}
        component="legend"
      >
        {label}
      </FormLabel>
      <FormGroup
        sx={{
          border: "1px solid #aaa",
          borderColor: error ? "red" : "#aaa",
          padding: "12px",
          borderRadius: "4px",
          width: "260px",
        }}
        row={true}
      >
        <TimePicker
          value={value?.start}
          onChange={onChange("start")}
          inputVariant="standard"
          minutesStep={minutesStep}
        />
        <Box mr={1} ml={1} display="flex" mb="10px" alignItems="center">
          ～
        </Box>
        <TimePicker
          value={value?.end}
          onChange={onChange("end")}
          inputVariant="standard"
          minutesStep={minutesStep}
        />
      </FormGroup>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormGroup>
  );
};
