import yup, { textSchema, dateSchema, emailSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  name: textSchema.required(),
  enableDate: yup.boolean().required(),
  date: dateSchema
    .nullable()
    .test("empty", "入力してください。", function (value) {
      if (this.parent.enableDate === true && (!value || value === "")) {
        return false;
      }

      return true;
    }),
  criteria: yup
    .string()
    .required()
    .test("empty", "条件を設定してください。", function (value) {
      if (
        this.parent.enableDate === false &&
        this.parent.enableODOmeter === false &&
        this.parent.enableCertificateDays === false
      ) {
        return false;
      }

      return true;
    }),
  enableODOmeter: yup.boolean().required(),
  odometer: yup
    .number()
    .integer()
    .positive()
    .nullable()
    .transform((value, originalValue) =>
      String(originalValue).trim() === "" ? null : value
    )
    .test("empty", "入力してください。", function (value) {
      if (this.parent.enableODOmeter === true && (!value || value === "")) {
        return false;
      }

      return true;
    }),
  enableCertificateDays: yup.boolean(),
  certificateDays: yup
    .object()
    .nullable()
    .test("empty", "入力してください。", function (value) {
      if (
        this.parent.enableCertificateDays === true &&
        (!value || value === "")
      ) {
        return false;
      }

      return true;
    }),
  notificationTimes: textSchema.required(),
  enableRepeatDays: yup.boolean().required(),
  repeatDays: yup
    .object()
    .nullable()
    .test("empty", "入力してください。", function (value) {
      if (this.parent.enableRepeatDays === true && (!value || value === "")) {
        return false;
      }

      return true;
    }),
  emails: yup.array().of(emailSchema).min(1).nullable().required(),
});
