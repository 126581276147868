
/**
 * 外部サービス連携のメニューコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, onClick, ...props }) => {
  return render({ onClick: onClick, ...props });
};
