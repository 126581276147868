import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 定期点検一覧を表示するコンポーネントです。
 */
export const PeriodicInspections = containerPresententer(
  Container,
  Presententer
);
