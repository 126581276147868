import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * フルスクリーンダイアログを表示するコンポーネントです
 * @param {boolean} open ダイアログを開閉するイベントです。
 * @param {string} formId フォームのId
 * @param {string} textConfirm submitボタンのテキストです。
 * @param {string} title ダイアログのタイトル
 * @param {func} onClickSubmit submitボタンをクリックする時のイベントです。
 * @param {func} onClose ダイアログを閉じるイベントです。
 * @param {boolean} disabled 無効有効設定
 * @param {object} action 別の分が表示したい
 * @param {boolean} isSubmit
 * @param {boolean} container　コンテナ
 * @param {boolean} closeDisabled 閉じるアイコンを無効にする
 * @callback children ダイアログの内容
 *
 */

export const FullScreenDialog = containerPresententer(Container, Presententer);
