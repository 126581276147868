import React from "react";
import { RenderButtonToolBar } from "utils/XgridExpand";
import { Box, Typography, Popover } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BatchPredictionIcon from "@mui/icons-material/BatchPrediction";
import { GridToolbarContainer } from "@mui/x-data-grid";
import {
  FullScreenDialog,
  XGridComponent,
  ConfirmDialog,
} from "views/molecules";
import { RegularlyForm } from "../RegularlyForm";

const Form = React.forwardRef((props, ref) => (
  <RegularlyForm {...props} _ref={ref} />
));

/**
 * 定期回収一覧の表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 70px)",
        width: "100%",
      }}
    >
      <XGridComponent
        {...props.xGridParams}
        rows={props.xGridParams.rows.map((row) => ({
          id: row.cycleId,
          ...row,
        }))}
        height="calc(100vh - 70px)"
        width="100%"
        onClose={props.onCloseDialog}
        autoHeight={false}
        selectionModel={props.selectedRows}
        onChangeSelection={props.onChangeSelection}
        checkboxSelection={true}
        multipleSelection={true}
        onDownload={true}
        onRowClick={(params) => {
          props.navigate(`/order/regularly/${params.row.acceptingOrderId}`);
        }}
        customTools={
          <GridToolbarContainer>
            <Box
              sx={{
                width: 55,
                height: 55,
              }}
            >
              {props.selectedRows?.length ? (
                <Box
                  variant="outlined"
                  color="error"
                  size="small"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    background: "#ffffff",
                    width: 55,
                    mt: "3px",
                    border: "2px solid #5a7ab3",
                    borderRadius: 1,
                    height: 55,
                    cursor: "pointer",
                  }}
                  onMouseEnter={props.handlePopoverOpen}
                >
                  <BatchPredictionIcon
                    sx={{
                      color: "#5a7ab3",
                    }}
                  />
                  <Typography
                    fontSize={10}
                    sx={{
                      color: "#5a7ab3",
                    }}
                  >
                    一括操作
                  </Typography>
                </Box>
              ) : null}
              <Popover
                anchorEl={props.anchorEl}
                open={Boolean(props.anchorEl)}
                onClose={props.handlePopoverClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                sx={{
                  ml: 4,
                }}
              >
                <Box
                  component="button"
                  color="red"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  px={1}
                  py={1}
                  borderColor="#5a7ab3"
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      background: "#4f4f4f",
                      color: "#fff",
                    },
                  }}
                  onClick={() => {
                    props.handlePopoverClose();
                    props.handleActionClick("delete");
                  }}
                >
                  <DeleteForeverIcon
                    sx={{
                      fontSize: 20,
                    }}
                  />{" "}
                  定期回収一覧の一括削除{" "}
                  <b>({props.selectedRows?.length || 0}件)</b>
                </Box>
              </Popover>
            </Box>
          </GridToolbarContainer>
        }
        actionButton={
          <RenderButtonToolBar
            label="新しい定期回収を追加"
            onClick={props.onOpenDialog}
          />
        }
      />
      <FullScreenDialog
        open={props.open}
        onClose={props.onCloseDialog}
        title={"新しい定期回収を登録"}
        textConfirm={"登録"}
        onClickSubmit={props.onConfirm}
        isSubmit={props.isSubmit}
        disabled={props.isSubmit}
      >
        <Form ref={props.formRef} onSubmit={props.onSubmit} />
      </FullScreenDialog>
      <ConfirmDialog
        title="削除確認"
        message="この定期回収で作成した過去の回収記録も削除されますが、よろしいですか？"
        open={props.deleteConfirmation.open}
        positiveText="はい"
        negativeText="いいえ"
        onPositive={props.onDelete}
        disabled={props.isSubmit}
        onNegative={() => props.deleteConfirmation.toggle(false)}
        showProgress={props.isSubmit}
      ></ConfirmDialog>
    </Box>
  );
};
