import { Button } from "@mui/material";
import { FullScreenDialog, XGridComponent } from "views/molecules";
import { WorkplaceForm } from "views/organisms/Masters/Workplace";

/**
 * 事業場を選択するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <>
    <XGridComponent
      autoHeight={props.autoHeight}
      onChangeSelection={props.onChangeSelection}
      checkboxSelection={false}
      multipleSelection={props.multiple}
      onDownload={true}
      titleTable={props.title}
      actionButton={
        props.belongInCompanyId ? (
          <Button
            variant="contained"
            color="primary"
            onClick={props.onOpenDialog}
          >
            {"新しい事業場を追加"}
          </Button>
        ) : (
          <></>
        )
      }
      {...props.xGridParams}
    />
    <FullScreenDialog
      formId={"workplace-form"}
      open={props.open}
      title="新しい事業場の追加"
      textConfirm="登録"
      onClose={props.onCloseDialog}
      isSubmit={props.isSubmit}
      disabled={props.isSubmit}
    >
      <WorkplaceForm id={"workplace-form"} onSubmit={props.onSubmit} />
    </FullScreenDialog>
  </>
);
