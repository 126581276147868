import { CircularProgress } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";

/**
 * 業種を選択するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  if (props.isLoading) {
    return <CircularProgress />;
  }

  return (
    <FormControl
      disabled={props.disabled}
      error={props.error}
      sx={{ minWidth: 200 }}
    >
      {props.label && (
        <InputLabel htmlFor="grouped-select">{props.label}</InputLabel>
      )}
      <Select
        defaultValue=""
        id="grouped-select"
        label={props.label}
        value={
          !props.value
            ? ""
            : Array.isArray(props.value)
            ? props.value.map((item) => item.id)
            : props.value.id
        }
        onChange={props.onChange}
      >
        <MenuItem value="">
          <em>未選択</em>
        </MenuItem>
        {Object.keys(props.industories).map((key) => {
          return [
            <ListSubheader>{key}</ListSubheader>,
            props.industories[key]
              ?.filter((item) => item.removedAt === null)
              ?.map((item, index) => {
                return (
                  <MenuItem value={item.id} key={item.id}>
                    {item.subClass.name ||
                      item.smallClass.name ||
                      item.middleClass.name ||
                      item.largeClass.name}
                  </MenuItem>
                );
              }),
          ];
        })}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
};
