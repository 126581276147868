import yup, { textSchema } from "utils/yup";

const groupSchema = yup.object().shape({
  name: textSchema.required(),
  items: yup
    .array()
    .of(yup.object().shape({ name: textSchema.required() }))
    .min(1)
    .required(),
});

export const basicSchema = yup.object().shape({
  sections: yup.array().of(groupSchema).min(1).required(),
});
