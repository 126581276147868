import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Container, Link, Paper, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "utils/TemporaryModules";
import { FullScreenDialog, InputField } from "views/molecules";
import { schema } from "./schema";
import { createJwnetMember } from "api/graphql/mutations";
import { DateTime } from "luxon";

const defaultValues = {
  number: null,
  usageConfirmationKey: null,
};

/**
 * JWNET申請を行うコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {boolean} props.open 開閉状態
 * @fires ApplyForm#onClose 閉じる時
 * @fires ApplyForm#onCompleted 完了時
 * @fires ApplyForm#onError エラー時
 * @returns {JSX.Element}
 */
export const ApplyForm = ({
  value = null,
  open = false,
  onClose = () => {},
  onCompleted = () => {},
  onError = () => {},
}) => {
  const [createJwnetMemberMutation, createJwnetMemberMutationStatus] =
    useMutation(
      createJwnetMember,
      {
        onCompleted: (data) => {
          onCompleted(data);
          onClose();
        },
        succeedMessage: "申請しました。",
        errorMessage: "エラーが発生したため、申請に失敗しました。",
      },
      false,
      null,
      null
    );

  const loading = createJwnetMemberMutationStatus.loading;

  const { control, getValues, trigger, formState, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      number: value?.number,
      ediUseConfirmationKey: value?.ediUseConfirmationKey,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (open === true) {
      reset({
        ...defaultValues,
        number: value?.number,
        ediUseConfirmationKey: value?.ediUseConfirmationKey,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, open]);

  const handleApplied = async () => {
    const result = await trigger();

    if (result) {
      const { number, ediUseConfirmationKey } = getValues();

      const today = DateTime.now();
      createJwnetMemberMutation({
        input: {
          number: number,
          ediUseConfirmationKey: ediUseConfirmationKey,
          whenManifestChanged: "doNothing",
          whenManifestCanceled: "doNothing",
        },
        inquiryCriteria: {
          manifestExtractDay: "registered",
          extractDateFrom: today.toISODate(),
          extractDateTo: today.toISODate(),
          isIncludingCancellation: true,
        },
      });
    } else {
      onError && onError(formState.errors);
    }
  };

  return (
    <FullScreenDialog
      open={open}
      onClose={onClose}
      title={"EDI接続の利用を申請"}
      textConfirm={"申請"}
      onClickSubmit={handleApplied}
      isSubmit={loading}
      disabled={loading}
      container={false}
    >
      <Container>
        <Paper
          elevation={3}
          sx={{
            p: 5,
          }}
        >
          <Stack spacing={2}>
            <Stack>
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.74)",
                  fontSize: "14px",
                }}
              >
                {
                  "本システムはお客様のユーザー体験を向上させるため、EDI接続先を複数で運用します。"
                }
                <br />
                {
                  "これによりJWNETからのEDI接続に関する通知が複数行われることがあります。"
                }
                <br />
                {
                  "また、EDI接続先は増減することがあり、増減に伴うお客様による追加の操作は必要ありませんが、再度通知が行われることがあります。"
                }
                <br />
                {"利用申請を行うことで、以上の内容に同意したこととします。"}
              </Typography>
            </Stack>
            <Stack>
              <Controller
                name="number"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    label="加入者番号"
                    value={value}
                    placeholder="収集運搬業者の加入者番号を入力してください。"
                    onChange={onChange}
                    required={true}
                    error={error}
                    disabled={loading}
                    inputProps={{
                      maxLength: 7,
                    }}
                  />
                )}
              />
            </Stack>
            <Stack>
              <Controller
                name="ediUseConfirmationKey"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    label="EDI利用確認キー"
                    value={value}
                    placeholder="EDI利用確認キーを入力してください。"
                    onChange={onChange}
                    required={true}
                    error={error}
                    disabled={loading}
                    inputProps={{
                      maxLength: 8,
                    }}
                  />
                )}
              />
              <Box
                sx={{
                  fontSize: "14px",
                }}
              >
                {"EDI利用確認キーの閲覧方法は"}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.jwnet.or.jp/jwnet/faq/page_584.html"
                >
                  {"こちら"}
                </Link>
              </Box>
            </Stack>
          </Stack>
        </Paper>
      </Container>
    </FullScreenDialog>
  );
};
