import { useEffect, useState, useCallback } from "react";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { getAggregationWasteCollectionRecord } from "api/graphql/queries";
import { Interval, DateTime } from "luxon";
import { debugLog } from "utils/log";

/**
 * 開始日から終了日までのDateTime配列を生成します。
 * @param {object} interval Interval
 * @returns {DateTime[]}
 */
function* createDays(interval) {
  let cursor = interval.start.startOf("day");
  while (cursor < interval.end) {
    yield cursor;
    cursor = cursor.plus({ days: 1 });
  }
}

export const Container = ({ render, ...props }) => {
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const start = DateTime.now().minus({ days: 7 });
  const end = DateTime.now();

  const fetchHeader = (response) => {
    let items = response.data.getAggregationWasteCollectionRecord.items.map(
      (item) => item.groupColumns[0].value
    );

    if (
      items.findIndex((item) => item === "kg") !== -1 &&
      items.findIndex((item) => item === "t") !== -1
    ) {
      items = items.filter((item) => item !== "kg");
    }

    return items.reduce(function (target, key, index) {
      target[`${key}`] = true;
      return target;
    }, {});
  };

  const fetchItems = (response) => {
    let items = response.data.getAggregationWasteCollectionRecord.items.flatMap(
      (item) =>
        item.aggregationColumns.map((aggregationColumn) => {
          return {
            date: aggregationColumn.key,
            [item.groupColumns[0].value]: aggregationColumn.value,
          };
        })
    );

    let result = [];

    for (const day of createDays(Interval.fromDateTimes(start, end))) {
      let itemsOfDate = items.filter((item) => item.date === day.toISODate());

      if (itemsOfDate.length > 0) {
        let kg = itemsOfDate.find((item) => item["kg"] !== undefined);
        let t = itemsOfDate.find((item) => item["t"] !== undefined);
        let m3 = itemsOfDate.find((item) => item["m3"] !== undefined);
        let liter = itemsOfDate.find((item) => item["リットル"] !== undefined);
        let quantity = itemsOfDate.find((item) => item["個・台"] !== undefined);

        if (kg && t) {
          t = {
            ...t,
            t: t.t + kg.kg / 1000,
          };

          // tにまとめたので未定義状態にする
          kg = undefined;
        }

        result.push({
          ...kg,
          ...t,
          ...m3,
          ...liter,
          ...quantity,
        });
      } else {
        result.push({
          date: day.toISODate(),
          t: 0,
        });
      }
    }

    debugLog("ダッシュボードのitems: ", result);

    return result;
  };

  const load = useCallback(() => {
    setLoading(true);
    return API.graphql(
      graphqlOperation(getAggregationWasteCollectionRecord, {
        aggregateOn: {
          start: start.toISODate(),
          end: end.toISODate(),
        },
        mode: "daily",
        dataset: "wasteCollectionRecordActualValue",
        dateType: "wasteCollectionScheduleDate",
        method: "sum",
        groupColumns: {
          logisticsCourseName: false,
          wasteGeneratorCompanyName: false,
          wasteCollectionWorkplaceCode: false,
          wasteCollectionWorkplaceName: false,
          wasteCollectionWorkplacePrefecture: false,
          wasteCollectionWorkplaceCity: false,
          wasteLargeClassName: false,
          wasteMiddleClassName: false,
          wasteSmallClassName: false,
          wasteKindName: false,
          wasteName: false,
          wasteDisposalCompanyName: false,
          wasteDisposalWorkplaceName: false,
          wasteDisposalWorkplacePrefecture: false,
          wasteDisposalWorkplaceCity: false,
          wasteDisposalMethodLargeClassName: false,
          wasteDisposalMethodMiddleClassName: false,
          wasteDisposalMethodSmallClassName: false,
          wasteDisposalMethodDetails: false,
          wasteQuantityUnitName: true,
        },
      })
    )
      .then((res) => {
        setValue({
          header: fetchHeader(res),
          items: fetchItems(res),
        });
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return render({
    value: value,
    loading: loading,
    ...props,
  });
};
