import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Stack, Typography } from "@mui/material";
import React from "react";

/**
 * 注意点タイトルを表示するコンポーネントです。
 * @returns {JSX.Element} JSX.Element
 */
export const Title = () => {
  return (
    <Stack spacing={2} direction="row">
      <span
        style={{
          color: "grey",
        }}
      >
        <WarningAmberIcon />
      </span>
      <Typography>{"回収注意点"}</Typography>
    </Stack>
  );
};
