import { onCloseDialog, onOpenDialog } from "ducks/Dialog/slice";
import { setVisilityBeginnersMark } from "ducks/GuiderSteps/slice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WorkplaceGuiderSteps } from "utils/GuiderSteps";
import { debugLog } from "utils/log";
import { PhoneNumberFormatUtil } from "utils/format";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

function getFullAddress(params) {
  const { row } = params;
  return `〒${row?.postCode} ${row?.address}`;
}

export const columns = [
  {
    field: "name",
    headerName: "名称",
    flex: 0.5,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "address",
    headerName: "現在地",
    flex: 1,
    valueGetter: getFullAddress,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "industriesName",
    headerName: "企業",
    hide: true,
    width: 160,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "faxNumber",
    headerName: "FAX番号",
    hide: true,
    width: 160,
    valueFormatter: (params) =>
      PhoneNumberFormatUtil.formatNational(params.value),
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },

  {
    field: "phoneNumber",
    headerName: "電話番号",
    width: 160,
    valueFormatter: (params) =>
      PhoneNumberFormatUtil.formatNational(params.value),
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
];

/**
 * 引数を受けて、JSX Elementを返します
 * @param {object} value 現在の事業場情報です
 * @param {array} dataTable テーブルのデータ
 * @param {string} titleTable モーダルの時にテーブルのタイトルが表示する
 * @param {boolean} checkboxSelection 行頭にチェックボックスを表示する
 * @param {object} props プロパティ
 * @callback render
 * @returns
 */
export const Container = ({
  render,
  value,
  submit,
  onSubmitted,
  dataTable = [],
  getItemSelected,
  titleTable = "",
  ...props
}) => {
  const dispatch = useDispatch();

  const { isOpen } = useSelector((state) => state.Dialog);
  const open = isOpen.some((v) => v === "corporationAddNew");

  useEffect(() => {
    dispatch(setVisilityBeginnersMark(WorkplaceGuiderSteps.length > 0));
  }, [dispatch]);

  const handleCloseDialog = () => {
    dispatch(onCloseDialog("corporationAddNew"));
  };
  const handleOpenDialog = () => {
    dispatch(onOpenDialog("corporationAddNew"));
  };

  const handleOnChangeSeletion = (items) => {
    const selected = dataTable.find((row) => row.id === items[0]);
    getItemSelected && getItemSelected(selected);
  };

  const handleOnChangeFormAddNew = (value) => {
    submit(value)
      .then((result) => {
        debugLog("workplace.onCreate.succeeded", result);
        onSubmitted && onSubmitted(result);
        handleCloseDialog();
      })
      .catch((error) => {
        debugLog("workplace.onCreate.failed", error);
      });
  };

  return render({
    open: open,
    onCloseDialog: handleCloseDialog,
    onOpenDialog: handleOpenDialog,
    onChangeDataSubmit: handleOnChangeFormAddNew,

    rows: dataTable,
    columns: columns,
    value: value,
    titleTable: titleTable,
    onChangeSelection: handleOnChangeSeletion,

    GuiderSteps: WorkplaceGuiderSteps,
    ...props,
  });
};
