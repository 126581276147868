import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { formatDisplayDate } from "utils/format";
import { ModalPopup } from "views/molecules";
import { useShowMore } from "utils/useShowMore";
import { listContainerMovementLogsByOffset } from "api/graphql/queries";

const Location = ({ url, workplace, category, installedOn, nextDate }) => {
  const isSaving = (end = null) => {
    return end === null;
  };

  const categoriesText = () => {
    if (!category) {
      return "";
    }

    return category === "Storage"
      ? "保管"
      : category === "Installation"
      ? "設置"
      : "";
  };

  const getDays = (start, end) => {
    if (!start) {
      return null;
    }

    return (isSaving(end) ? DateTime.now() : DateTime.fromISO(end)).diff(
      DateTime.fromISO(start),
      "days"
    ).days;
  };

  return (
    <TimelineItem>
      <TimelineOppositeContent
        color="text.secondary"
        align="left"
        sx={{ m: "auto 0", flex: 0 }}
      >
        {formatDisplayDate({
          source: installedOn,
          sourceFormat: "yyyy-MM-dd",
          destFormat: "yyyy/MM/dd",
        })}
      </TimelineOppositeContent>
      <TimelineDot>
        <Avatar src={url}>
          <PersonOutlineIcon />
        </Avatar>
      </TimelineDot>
      <TimelineContent sx={{ m: "auto 0" }}>
        {
          <Stack>
            <Box>
              <Typography
                sx={{
                  color: "gray",
                  border: "1px solid gray",
                  padding: "4px",
                }}
                component={"span"}
              >{`${categoriesText()}日数${parseInt(
                getDays(installedOn, nextDate),
                10
              )}日`}</Typography>
            </Box>
            <Box>{`${workplace?.belongInCompanyName ?? ""} ${
              workplace?.name ?? ""
            }`}</Box>
          </Stack>
        }
      </TimelineContent>
    </TimelineItem>
  );
};

const MoveMethod = ({ movedOn, remarks, vehicle, personInChargeName }) => {
  return (
    <TimelineItem>
      <TimelineOppositeContent
        color="text.secondary"
        sx={{ m: "auto 0", flex: 0 }}
      >
        {formatDisplayDate({ source: movedOn })}
      </TimelineOppositeContent>
      <TimelineSeparator
        sx={{
          padding: "0 20px 0 20px",
        }}
      >
        <TimelineConnector />
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{"移動"}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="row">
              <Typography>{"車輛番号："}</Typography>
              <Typography>{vehicle?.number ?? ""}</Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <Typography>{"車種："}</Typography>
              <Typography>{vehicle?.modelName ?? ""}</Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <Typography>{"担当者："}</Typography>
              <Typography>{personInChargeName ?? ""}</Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <Typography>{"備考："}</Typography>
              <Typography
                sx={{
                  wordBreak: "break-word",
                  whiteSpace: "pre-wrap",
                }}
              >
                {remarks ?? ""}
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      </TimelineContent>
    </TimelineItem>
  );
};

/**
 * 移動履歴を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {array} props.value 移動履歴
 * @fires MovementHistory#onNext 次へ
 * @returns {JSX.Element}
 */
export const MovementHistory = ({
  containerId,
  open = false,
  onClose = () => {},
}) => {
  const isEmpty = (arr) => {
    return !arr || arr.length <= 0;
  };

  const { data, loading, error, hasNext, onNext } = useShowMore({
    query: listContainerMovementLogsByOffset,
    variables: { containerId: containerId, sortDirection: "DESC" },
  });

  return (
    <ModalPopup open={open} onClose={onClose}>
      <Stack>
        <Box>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              p: 2,
            }}
          >
            {"移動履歴"}
          </Typography>
        </Box>
        <Box
          sx={{
            overflow: "auto",
            maxHeight: "80vh",
            m: 3,
          }}
        >
          {!error && isEmpty(data) && (
            <Typography>{"移動履歴はありません。"}</Typography>
          )}
          {error && (
            <Typography>
              {"エラーが発生したため、表示することができませんでした。"}
            </Typography>
          )}
          {!isEmpty(data) && (
            <Timeline>
              {data?.map((item, index) => (
                <React.Fragment key={index}>
                  {index === 0 && (
                    <Location
                      workplace={item?.afterLocation?.workplace}
                      category={item?.afterLocation?.category}
                      installedOn={item?.afterLocation?.installedOn}
                      nextDate={DateTime.now().toISODate()}
                    />
                  )}
                  <MoveMethod
                    movedOn={item?.movedOn}
                    remarks={item?.remarks}
                    vehicle={item?.vehicle}
                    personInChargeName={item?.personInChargeName}
                  />
                  <Location
                    workplace={item?.beforeLocation?.workplace}
                    category={item?.beforeLocation?.category}
                    installedOn={item?.beforeLocation?.installedOn}
                    nextDate={item?.movedOn}
                  />
                </React.Fragment>
              ))}
            </Timeline>
          )}
        </Box>
        <Box>
          {loading === true && <LinearProgress />}
          {loading === false && hasNext === true && (
            <Button
              onClick={onNext}
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                m: 1,
              }}
            >
              {"さらに表示する"}
            </Button>
          )}
        </Box>
      </Stack>
    </ModalPopup>
  );
};
