import { useState, useEffect } from "react";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import { useOpener } from "utils/useOpener";

/**
 * 車輛詳細を表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  id,
  onCreateVehiclePeriodicInspection,
  vehiclePeriodicInspectionForm,
  periodicInspectionsRef,
  maintenanceRef,
  safetiesRef,
  onCreateVehicleMaintenanceRecord,
  loading,
  onCreateVehicleSafetyInspectionRecord,
  vehicleSafetyInspectionForm,
  value,
  vehicleInspectionDateForm,
  odoMeterForm,
  remindForm,
  onUpdateVehicleInstrument,
  onUpdateVehicleCertificate,
  reminds,
  onCreateVehicleRemind,
  onUpdateVehicleRemind,
  onDeletedRemind,
  ...props
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tab, setTab] = useState("inspection");
  const maintenanceRecordForm = useOpener();
  const vehicleInspectionRecordForm = useOpener();
  const checkSheetSelector = useOpener();

  useEffect(() => {
    const qs = new URLSearchParams(location.search);
    setTab(qs.get("tab") ?? "inspection");
  }, [location.search]);

  const handleChangeTab = (event, newValue) => {
    navigate({
      pathname: location.pathname,
      search: `${createSearchParams({ tab: newValue })}`,
    });

    setTab(newValue);
  };

  return render({
    ...props,
    id: id,
    tab: tab,
    onChangeTab: handleChangeTab,
    odoMeterForm: odoMeterForm,
    vehicleInspectionDateForm: vehicleInspectionDateForm,
    remindForm: remindForm,
    maintenanceRecordForm: maintenanceRecordForm,
    vehicleInspectionRecordForm: vehicleInspectionRecordForm,
    checkSheetSelector: checkSheetSelector,
    onCreateVehiclePeriodicInspection: onCreateVehiclePeriodicInspection,
    vehiclePeriodicInspectionForm: vehiclePeriodicInspectionForm,
    periodicInspectionsRef: periodicInspectionsRef,
    maintenanceRef: maintenanceRef,
    onCreateVehicleMaintenanceRecord: onCreateVehicleMaintenanceRecord,
    loading: loading,
    onCreateVehicleSafetyInspectionRecord:
      onCreateVehicleSafetyInspectionRecord,
    safetiesRef: safetiesRef,
    vehicleSafetyInspectionForm: vehicleSafetyInspectionForm,
    value: value,
    onUpdateVehicleInstrument: onUpdateVehicleInstrument,
    onUpdateVehicleCertificate: onUpdateVehicleCertificate,
    reminds: reminds,
    onCreateVehicleRemind: onCreateVehicleRemind,
    onUpdateVehicleRemind: onUpdateVehicleRemind,
    onDeletedRemind: onDeletedRemind,
  });
};
