import yup, { dateSchema, remarksSchema, textSchema } from "utils/yup";

export const licenseGenaralSchema = yup.object().shape({
  number: textSchema.nullable().default(null).required(),
  japaneseLocalGovernment: yup.object().default(null).required(),
  category: yup
    .object()
    .shape({
      code: textSchema.required(),
      name: textSchema.required(),
    })
    .nullable()
    .default(null)
    .required(),
  licensedOn: dateSchema
    .required()
    .transform((value) =>
      value instanceof Date && !isNaN(value) ? value : null
    )
    .test("futureDate", "未来日が指定されています。", (value) => {
      if (value) {
        return value <= new Date();
      }
      return true;
    }),
  licenseExpirationOn: dateSchema
    .required()
    .test(
      "dateRange",
      "許可の年月日より前の日付が指定されています。",
      function (value) {
        if (value && this.parent.licensedOn) {
          return value > this.parent.licensedOn;
        }
        return true;
      }
    ),
  companyName: textSchema.nullable().default(null).required(),
  postalCode: yup.string().nullable().default(null).postalCode().required(),
  city: textSchema.nullable().default(null).required(),
  streetAddress: textSchema.nullable().default(null).required(),
  otherAddress: textSchema.nullable().default(null),
  remarks: remarksSchema.nullable().default(null),
  files: yup.array().nullable(),
});
