/**
 * 詳細ページの枠組みを表示するプレゼンテーションコンポーネントです。
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <>
      <div className={props.classes.header}>
        <div className={props.classes.caption_area}>{props.caption}</div>
        <div className={props.classes.action_area}>{props.actions}</div>
      </div>
      {props.children}
    </>
  );
};
