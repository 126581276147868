import { useNavigate } from "react-router-dom";

/**
 * モバイルのダッシュボードを表示するコンテナコンポーネントです。
 */
export const Container = ({ render, value, ...props }) => {
  const navigate = useNavigate();

  return render({
    ...props,
    onClick: (url) => navigate(url),
    value: value,
  });
};
