/**
 * 画像処理関係のユーティリティ
 */

import { debugLog } from "./log";

/**
 * apiの形に変換します。
 * @param {object} props プロパティ
 * @param {array} files ファイル配列
 * @param {func} customConvertFunc カスタムの変換処理
 * @returns {array} 変換後の値
 */
export const toApiValue = async ({ files = null, customConvertFunc }) => {
  if (!files || files?.length <= 0) {
    return null;
  }

  let result = await Promise.all(
    files.map(async (file) =>
      isDataUri(file.src) ? file : await urlToFile(file.src)
    )
  );

  return customConvertFunc
    ? result.map(customConvertFunc)
    : result.map((file, index) => ({
        name: file?.name,
        base64: file?.src,
        label: file?.label,
        order: index,
      }));
};

/**
 * フォームの形に変換します。
 * @param {object} props プロパティ
 * @param {array} files ファイル配列
 * @param {func} customConvertFunc カスタムの変換処理
 * @returns {Promise<array>} 変換後の値
 */
export const toFormValue = async ({ files = null, customConvertFunc }) => {
  if (!files || files?.length <= 0) {
    return [];
  }

  let result = await Promise.all(
    files.map(async (file) =>
      isDataUri(file.url)
        ? file
        : {
            ...(await urlToFile(file.url)),
            name: file?.name,
            label: file?.label,
          }
    )
  );

  return customConvertFunc
    ? result.map(customConvertFunc)
    : result.map((file) => ({
        name: file?.name,
        src: file?.src,
        label: file?.label,
      }));
};

/**
 * ファイルオブジェクトからBase64へ変換します。
 * @param {File} fileObject ファイルオブジェクト
 * @returns {Promise<string>}
 */
export const fileObjectToBase64 = (fileObject) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileObject);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

/**
 * URLからFileオブジェクトに変換します。
 * @param {string} url URL文字列
 * @returns {Promise<File>}
 */
export const urlToFileObject = (url) => {
  return fetch(url, {
    method: "GET",
    mode: "cors",
  })
    .then((response) => response.blob())
    .then(
      (blob) =>
        new File([blob], getFileNameFromURLString(url), {
          type: extensionToMimeType(
            getExtensionFromString(getFileNameFromURLString(url))?.toLowerCase()
          ),
        })
    )
    .then((file) => file);
};

/**
 * 拡張子からMimeTypeを取得します。
 * @param {string} extension 拡張子
 * @returns {string}
 */
const extensionToMimeType = (extension) => {
  if (!extension || extension === "") {
    return undefined;
  }

  if (extension.toLowerCase().indexOf("pdf") !== -1) {
    return "application/pdf";
  }

  if (extension.toLowerCase().indexOf("gif") !== -1) {
    return "image/gif";
  }

  if (
    ["jpeg", "jpg", "jpe", "jfif", "pjpeg", "pjp"].includes(
      extension.toLowerCase()
    )
  ) {
    return "image/jpeg";
  }

  if (extension.toLowerCase().indexOf("png") !== -1) {
    return "image/png";
  }

  if (["tiff", "tif"].includes(extension.toLowerCase())) {
    return "image/tiff";
  }

  if (extension.toLowerCase().indexOf("apng") !== -1) {
    return "image/apng";
  }

  if (extension.toLowerCase().indexOf("avif") !== -1) {
    return "image/avif";
  }

  if (extension.toLowerCase().indexOf("svg") !== -1) {
    return "image/svg+xml";
  }

  if (extension.toLowerCase().indexOf("webp") !== -1) {
    return "image/webp";
  }

  return "application/octet-stream";
};

/**
 * urlからファイルへ変換します。
 * @param {string} url URL文字列
 * @returns {Promise<object>}
 */
export const urlToFile = (url) => {
  return urlToFileObject(url)
    .then((fileObject) =>
      fileObjectToBase64(fileObject)
        .then((base64) => ({
          name: fileObject?.name,
          label: fileObject?.name,
          src: base64,
        }))
        .catch((err) => {
          debugLog("base64変換に失敗：", err);
          return {
            name: fileObject?.name,
            label: fileObject?.name,
            src: null,
          };
        })
    )
    .catch((err) => {
      debugLog("URLからリソースの取得に失敗：", err);
    });
};

/**
 * ファイルオブジェクトからファイルに変換します。
 * @param {File} fileObject ファイルオブジェクト
 * @fires toFile#onPreLoad 読み込み前時
 * @fires toFile#onLoaded 読み込み終了時
 * @fires toFile#onError エラー時
 * @returns {Promise<object>}
 */
export const toFile = ({
  fileObject,
  onPreLoad = () => {},
  onLoaded = () => {},
  onError = () => {},
}) => {
  onPreLoad({
    name: fileObject?.name,
    label: fileObject?.name,
    src: null,
    loading: true,
  });

  return fileObjectToBase64(fileObject)
    .then((res) => {
      const result = {
        name: fileObject?.name,
        label: fileObject?.name,
        src: res,
      };

      onLoaded(result);
      return result;
    })
    .catch((err) => {
      const result = {
        name: fileObject?.name,
        label: fileObject?.name,
        src: null,
        loading: false,
        error: err,
      };
      onError(result);
      return result;
    });
};

/**
 * ファイルオブジェクトを変換します。
 * @param {array} fileObjects ファイルオブジェクト
 * @fires toFiles#onPreLoadItem アイテム読み込み前
 * @fires toFiles#onLoadedItem アイテム読み込み
 * @fires toFiles#onErrorItem アイテム読み込みエラー
 * @returns {Promise array}
 */
export const toFiles = ({
  fileObjects,
  onPreLoadItem = () => {},
  onLoadedItem = () => {},
  onErrorItem = () => {},
}) => {
  if (!fileObjects || fileObjects?.length <= 0) {
    return Promise.resolve([]);
  }

  return Promise.all(
    fileObjects.map((fileObject, index) =>
      toFile({
        fileObject: fileObject,
        onPreLoad: (file) => onPreLoadItem({ index: index, data: file }),
        onLoaded: (file) => onLoadedItem({ index: index, data: file }),
        onError: (err) => onErrorItem(err),
      })
    )
  );
};

/**
 * 拡張子を取得します。
 * @param {string} base64 base64文字列
 * @returns {string | null}
 */
export const getExtensions = (base64) => {
  if (!base64) {
    return null;
  } else {
    return base64
      .toString()
      .slice(base64.indexOf("/") + 1, base64.indexOf(";"))
      .toLowerCase();
  }
};

/**
 * データが設定されているか確認します。
 * @param {string} base64 base64文字列
 * @returns {boolean}
 */
export const isNothingFromBase64 = (base64) => {
  return getExtensions(base64) === null;
};

/**
 * base64文字列がPDFか確認します。
 * @param {string} base64 base64文字列
 * @returns {boolean}
 */
export const isPDFFromBase64 = (base64) => {
  const result = getExtensions(base64);
  return result ? result === "pdf" : false;
};

/**
 * base64文字列が画像か確認します。
 * @returns {boolean}
 */
export const isImageFromBase64 = (base64) => {
  const result = getExtensions(base64);

  return result
    ? ["apng", "avif", "gif", "jpeg", "png", "svg+xml", "webp"].includes(result)
    : false;
};

/**
 * base64はPDFでも画像でもないか確認します。
 * @param {string} base64 base64文字列
 * @returns {boolean}
 */
export const isOtherFromBase64 = (base64) => {
  return !(
    isPDFFromBase64(base64) ||
    isImageFromBase64(base64) ||
    isNothingFromBase64(base64)
  );
};

/**
 * url文字列からファイル名を取得します。
 * @param {string} url Url文字列
 * @returns {boolean}
 */
export const getFileNameFromURLString = (url) => {
  if (!url) {
    return null;
  }

  return url.split("/").pop().split("?").shift().split("#").shift();
};

/**
 * 文字列からファイルの拡張子を取得します。
 * @param {string} fileName ファイル名
 * @returns {string}
 */
export const getExtensionFromString = (fileName) => {
  if (!fileName || fileName?.indexOf(".") === -1) {
    return null;
  }

  return fileName?.split(".")?.pop() ?? null;
};

/**
 * 文字列から拡張子無しであるか確認します。
 * @param {string} extension 拡張子
 * @returns {boolean}
 */
export const isNothingFromString = (extension) => {
  return extension === null || extension === "";
};

/**
 * 拡張子からPDFであるか確認します。
 * @param {string} extension 拡張子
 * @returns {boolean}
 */
export const isPDFFromExtension = (extension) => {
  if (isNothingFromString(extension)) {
    return false;
  }

  return extension === "pdf";
};

/**
 * 拡張子から画像であるか確認します。
 * @param {string} extension 拡張子
 * @returns {boolean}
 */
export const isImageFromExtension = (extension) => {
  if (isNothingFromString(extension)) {
    return false;
  }

  return [
    "apng",
    "avif",
    "gif",
    "jpg",
    "jpeg",
    "png",
    "svg",
    "webp",
    "bmp",
    "ico",
    "tiff",
    "tif",
    "cur",
  ].includes(extension);
};

/**
 * 拡張子からその他ファイルであるか確認します。
 * @param {string} extension 拡張子
 * @returns {boolean}
 */
export const isOtherFromExtension = (extension) => {
  return !(
    isPDFFromExtension(extension) ||
    isImageFromExtension(extension) ||
    isNothingFromString(extension)
  );
};

/**
 * DataUriの正規表現を表します。
 * @returns {RegExp}
 */
export const dataUriRegex = () =>
  new RegExp(
    /^\s*data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)\s*$/i
  );

/**
 * DataUriであるか確認します。
 * @param {string} value 文字列
 * @returns {boolean}
 */
export const isDataUri = (value) => {
  if (!value) {
    return false;
  }

  return (value && dataUriRegex().test(value)) === true;
};

/**
 * ファイルがどの形式かを切り替えるためのコンポーネントです。
 * @param {string} src ファイルソース
 * @param {JSX.Element} children 子要素
 * @returns {JSX.Element}
 */
export const Switcher = ({ src, children }) => {
  if (!src) {
    return children({
      isPDF: false,
      isImage: false,
      isOther: false,
    });
  }

  if (isDataUri(src)) {
    return children({
      isPDF: isPDFFromBase64(src),
      isImage: isImageFromBase64(src),
      isOther: isOtherFromBase64(src),
    });
  }

  let extension = getExtensionFromString(
    getFileNameFromURLString(src)
  )?.toLowerCase();

  return children({
    isPDF: isPDFFromExtension(extension),
    isImage: isImageFromExtension(extension),
    isOther: isOtherFromExtension(extension),
  });
};
