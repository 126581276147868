import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  collect_content: {
    padding: theme.spacing(3, 5),
  },
  input_style_mobile: {
    backgroundColor: theme.palette.background.paper,
    "& input": {
      textAlign: "right",
      fontSize: "30px",
      fontWeight: 600,
      color: theme.palette.grey[700],
      paddingRight: theme.spacing(2),
    },
    "& .MuiInputAdornment-positionEnd": {
      paddingRight: theme.spacing(1),
    },
  },
  remarks_modal_root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3, 3),
    borderRadius: theme.spacing(3),
  },
}));
