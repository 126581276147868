import { InformationItem } from "views/atoms";
import React from "react";

/**
 * 処理フロー運搬区間情報をタブ形式で表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <>
      {props.value?.transportProcesses?.map?.((transportProcess, index) => (
        <React.Fragment key={index}>
          <InformationItem label="運搬区間">
            {transportProcess?.section ?? ""}
          </InformationItem>
          <InformationItem
            label={`運搬受託者（${transportProcess?.section ?? ""}区間）`}
          >
            {transportProcess?.carrierCompany?.name ?? ""}
          </InformationItem>
          <InformationItem
            label={`運搬方法${transportProcess?.section ?? ""}区間）`}
          >
            {transportProcess?.transportMethod?.name ?? ""}
          </InformationItem>
          <InformationItem
            label={`運搬先の事業場（${transportProcess?.section ?? ""}区間）`}
          >
            {transportProcess.transportDestinationWorkplace?.belongInCompanyName ?? " "}
            {transportProcess.transportDestinationWorkplace?.name ?? ""}
          </InformationItem>
        </React.Fragment>
      )) ?? <></>}
    </>
  );
};
