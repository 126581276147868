import React from "react";
import { LicenseList } from "views/templates/Masters";

/**
 * 許可証の表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns
 */
export const Presententer = (props) => {
  return <LicenseList {...props} />;
};
