import React from "react";
import { Inspection } from "views/templates/Mobile//Vehicles/Inspection";
import { AppBar } from "views/organisms/Mobile/Common/AppBar";
import { Stack } from "@mui/material";

/**
 * モバイルの車輛点検を表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => (
  <Stack
    sx={{
      "@media print": {
        width: 375,
      },
    }}
  >
    <AppBar />
    <Inspection {...props} />
  </Stack>
);
