import { Timeline } from "@mui/lab";
import { Box } from "@mui/material";
import clsx from "clsx";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ReactBottomSheet } from "views/molecules";
import { CollectDetails } from "../CollectDetails";
import { CollectLists } from "../CollectLists";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  MozUserSelect: "none",
  WebkitUserSelect: "none",
  msUserSelect: "none",
  background: "#fff",
  ...draggableStyle,
});
const item = (props) => (items) => (next) => (provided, snapshot, rubric) => {
  const data = items[rubric.source.index];
  return (
    <>
      <div
        ref={provided.innerRef}
        {...provided.dragHandleProps}
        {...provided.draggableProps}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
      >
        <CollectLists
          value={data}
          index={rubric.source.index}
          onClickNumber={props.onClickNumber}
          onOpenDialog={props.handleOpenDetailsDialog}
          next={next}
        />
      </div>
      {provided.placeholder}
    </>
  );
};

const itemClone =
  (props) => (items) => (next) => (provided, snapshot, rubric) => {
    const data = items[rubric.source.index];
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        className={clsx(
          props.classes.collect_timeline,
          props.classes.itemClone
        )}
      >
        <div>
          <CollectLists
            value={data}
            index={rubric.source.index}
            onClickNumber={props.onClickNumber}
            onOpenDialog={props.onOpenDialog}
            next={next}
          />
        </div>
      </div>
    );
  };

/**
 * 回収を開始するリスト表示を行うプレゼンテーションコンポーネントです。
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <>
      <ReactBottomSheet
        isOpen={props.open}
        onClose={props.onClose}
        defaultsHeight={290}
        defaultsMaxHeight={
          props.screenHeight - 73 > props?.value?.length * 54 + 20
            ? props?.value?.length * 54 + 20
            : props.screenHeight - 73
        }
      >
        <Box>
          <Timeline
            sx={{ padding: 0 }}
            className={props.classes.collect_timeline}
          >
            <DragDropContext onDragEnd={props.onDragEnd}>
              <Droppable
                droppableId="collect_point"
                renderClone={itemClone(props)(props?.value)(props.next)}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={provided.droppableProps.style}
                    {...provided.droppableProps}
                  >
                    {props?.value?.map((data, index) => (
                      <Draggable
                        draggableId={data.id}
                        index={index}
                        key={data.id}
                      >
                        {item(props)(props?.value)(props.next)}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Timeline>
        </Box>
      </ReactBottomSheet>
      <CollectDetails
        open={props.detailsOpener.open}
        onClose={() => props.detailsOpener.toggle(false)}
        values={props.value}
        onSubmit={props.onRecordTask}
        index={props.collectItemIndex}
      />
    </>
  );
};
