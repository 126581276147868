import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 基本情報のタブを表示するコンポーネントです
 * @returns {JSX.Element}
 */
export const TabSimpleInformation = containerPresententer(
  Container,
  Presententer
);
