import { DateTime } from "luxon";
import { useState, useEffect } from "react";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { searchWasteCollectionSchedulesByOffset } from "api/graphql/queries";
import { debugLog } from "utils/log";
import { useIsFirstRender } from "utils/hooks";

const columns = [
  {
    field: "no",
    headerName: "No",
    width: 100,
    renderCell: (params) => {
      const apiRef = params.api;
      const index = apiRef.getRowIndex(params.row.id);
      return (
        <div
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          <span>{index + 1}</span>
        </div>
      );
    },
  },
  {
    field: "scheduleDate",
    headerName: "回収日",
    width: 160,
    valueFormatter: (params) => {
      return DateTime.fromFormat(params.value, "yyyy-MM-dd").toFormat(
        "yyyy/MM/dd"
      );
    },
  },
  {
    field: "times",
    headerName: "回収時間",
    width: 160,
    valueGetter: (params) => {
      return `${
        params.row?.scheduleTimeRangeStart
          ? params.row?.scheduleTimeRangeStart.substring(0, 5)
          : ""
      } ～ ${
        params.row?.scheduleTimeRangeEnd
          ? params.row?.scheduleTimeRangeEnd.substring(0, 5)
          : ""
      }`;
    },
  },
  {
    field: "destinationWorkplace",
    headerName: "排出事業場",
    width: 160,
    valueGetter: (params) => {
      return `${params.row?.wasteGeneratorCompany?.name} ${params.row?.wasteCollectionWorkplace?.name}`;
    },
  },
  {
    field: "wasteType",
    headerName: "廃棄物の種類",
    width: 160,
    valueGetter: (params) => {
      const { row } = params;
      const { waste } = row;
      return `${
        waste?.type?.smallClass?.name ||
        waste?.type?.middleClass?.name ||
        waste?.type?.largeClass?.name ||
        ""
      }`;
    },
  },
  {
    field: "wasteName",
    headerName: "廃棄物の名称",
    width: 160,
    valueGetter: (params) => {
      const { row } = params;
      const { waste } = row;
      return `${waste?.name}`;
    },
  },
  {
    field: "quantity",
    headerName: "排出量",
    width: 160,
    valueGetter: (params) => {
      const { row } = params;
      const { waste } = row;
      return `${row?.wasteQuantity} ${waste?.quantityUnit?.name}`;
    },
  },
];

const formatDate = (value) => {
  if (!!value) {
    return DateTime.fromFormat(value, "yyyy-MM-dd").toFormat("yyyy/MM/dd");
  }
  return value;
};

const narrowDown = (rows = [], date = null, visible = true) => {
  if (!rows || rows.length <= 0 || !date || visible === true) {
    return rows ?? [];
  }

  const nextDay = date.plus({ days: 1 });

  return rows.filter(
    (row) => formatDate(row.scheduleDate) !== nextDay.toFormat("yyyy/MM/dd")
  );
};

const compareDate = (left, right) => {
  const leftDateTime = DateTime.fromISO(left);
  const rightDateTime = DateTime.fromISO(right);

  if (leftDateTime.equals(rightDateTime)) {
    return 0;
  }

  return leftDateTime > rightDateTime ? 1 : -1;
};

const compareTime = (left, right) => {
  const leftTime = DateTime.fromSQL(left);
  const rightTime = DateTime.fromSQL(right);

  if (leftTime.equals(rightTime)) {
    return 0;
  }

  return leftTime > rightTime ? -1 : 1;
};

const timeOrder = (rows = null) => {
  if (!rows || rows.length <= 0) {
    return null;
  }

  return (
    [...rows].sort((left, right) => {
      const compareDateResult = compareDate(
        left.scheduleDate,
        right.scheduleDate
      );
      const compareTimeRangeStartResult = compareTime(
        left.scheduleTimeRangeStart,
        right.scheduleTimeRangeStart
      );
      const compareTimeRangeEndResult = compareTime(
        left.scheduleTimeRangeEnd,
        right.scheduleTimeRangeEnd
      );

      if (compareDateResult !== 0) {
        return compareDateResult;
      }

      if (compareTimeRangeStartResult !== 0) {
        return compareTimeRangeStartResult * -1;
      }

      return compareTimeRangeEndResult * -1;
    }) ?? []
  );
};

/**
 * 未配車の回収スケジュールを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  rows = [],
  date,
  refresh,
  setRefresh,
  ...props
}) => {
  const [visibleNextDay, setVisibleNextDay] = useState(false);
  const [schdeules, setSchedules] = useState(rows);
  const [loading, setLoading] = useState(false);
  const isFirstRendere = useIsFirstRender();

  useEffect(() => {
    if (refresh) {
      const controller = new AbortController();
      const { signal } = controller;
      setLoading(true);
      API.graphql(
        graphqlOperation(searchWasteCollectionSchedulesByOffset, {
          filter: {
            and: [
              {
                assigned: { eq: false },
                or: [
                  { date: { eq: date.toISODate() } },
                  { date: { eq: date.plus({ day: 1 }).toISODate() } },
                ],
              },
            ],
          },
          sort: {
            field: "date",
          },
          offset: 0,
        }),
        signal
      )
        .then((response) => {
          const { data } = response;
          const { searchWasteCollectionSchedulesByOffset } = data;
          const { items } = searchWasteCollectionSchedulesByOffset;
          setSchedules(items);
          setRefresh(false);
        })
        .catch((err) => {
          debugLog("Fetch Error", err);
        })
        .finally(() => {
          setLoading(false);
        });

      return () => {
        controller.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstRendere, refresh]);

  const handleChangeNextDay = (event) => {
    setVisibleNextDay(event.target.checked);
  };

  return render({
    rows: narrowDown(timeOrder(schdeules), date, visibleNextDay),
    rawRows: schdeules,
    columns: columns,
    visibleNextDay: visibleNextDay,
    onChangeNextDay: handleChangeNextDay,
    loading: loading,
    ...props,
  });
};
