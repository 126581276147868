import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  fetchVehiclesByOffset,
  fetchUserAccountsByOffset,
  fetchVirtualCoursePointsByOffset,
  unassignedParallelSearch,
} from "ducks/Automation";
import { add as addAlert } from "ducks/Alert";
import {
  reorderFromDropResult,
  moveItemsBetweenStillTrash,
  moveToUnassigned,
  moveToCourse,
  moveToTrashed,
  reorderOfCycle,
} from "views/organisms/Allocation/utils";
import { searchForKeys } from "utils/functions";

function searchObjectsByKeyword(data, keyword) {
  return data.filter((obj) =>
    obj.searchKeys.some((key) => key?.trim().includes(keyword.trim()))
  );
}

export const LIMITPERPAGE = 10;
export const ordersType = {
  still: "still",
  trashed: "trashed",
  done: "done",
};

export const DROPPABLEID = {
  TRASH: "trashedCourses",
  UNASSIGNED: "stillCollects",
};

const order = {
  query: "",
  total: 0,
  offset: 0,
  searching: false,
};

const searchInitialValues = {
  [ordersType.still]: order,
  [ordersType.trashed]: order,
};

const initialValues = {
  [ordersType.still]: [],
  [ordersType.trashed]: [],
  [ordersType.done]: {
    id: null,
    courses: [],
  },
};

const { data: _data, ...initialPagination } = order;
const initialPaginationState = {
  [ordersType.still]: {
    ...initialPagination,
    loading: false,
  },
  [ordersType.trashed]: {
    ...initialPagination,
    loading: false,
  },
};
const initialOrderFetchParams = [
  {
    limit: LIMITPERPAGE,
    offset: 0,
    filter: {
      and: [
        {
          assigned: { eq: false },
        },
        {
          trashed: { eq: false },
        },
      ],
    },
  },
  {
    limit: LIMITPERPAGE,
    offset: 0,
    filter: {
      and: [
        {
          assigned: { eq: false },
        },
        {
          trashed: { eq: true },
        },
      ],
    },
  },
];

const objKeys = ["name"];
export const useInitialState = (courseId, listRef) => {
  const [items, setItems] = useState(initialValues);
  const [paginationState, setPaginationState] = useState(
    initialPaginationState
  );
  const [trashedIds, setTrashedIds] = useState([]);
  const [searchedState, setSearchedState] = useState(searchInitialValues);
  const [activeCollectionPointId, setActiveCollectionPointId] = useState(null);
  const [renderDirection, setRenderDirection] = useState(false);
  const [isMapRenderAvailable, setIsMapRenderAvailable] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [tempList, setTempList] = useState([]);
  const dispatch = useDispatch();

  const { vehicles, drivers, data } = useSelector((state) => state.Automation);

  useEffect(() => {
    if (!drivers?.length) {
      dispatch(fetchUserAccountsByOffset());
    }
    if (!vehicles?.length) {
      dispatch(fetchVehiclesByOffset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setItems((prevState) => ({
      ...prevState,
      done: data.done,
    }));
  }, [data.done]);

  const fetchUnassignedOrders = useCallback(async () => {
    const params = initialOrderFetchParams;
    setPaginationState((prevState) => ({
      ...prevState,
      still: {
        ...prevState.still,
        loading: true,
      },
      trashed: {
        ...prevState.trashed,
        loading: true,
      },
    }));
    const response = await dispatch(unassignedParallelSearch(params));
    const { type, payload } = response;
    if (type === "unassignedParallelSearch/fetch/fulfilled") {
      const [stillResult, trashedResult] = payload;
      setTrashedIds((prevIds) =>
        _.uniq([
          ...prevIds,
          ...(trashedResult.items.map((item) => item.cycleId) || []),
        ])
      );
      setItems((prevState) => ({
        ...prevState,
        still: stillResult.items || [],
        trashed: trashedResult.items || [],
      }));

      setPaginationState((prevState) => ({
        ...prevState,
        still: {
          ...prevState.still,
          offset: LIMITPERPAGE,
          total: stillResult.total,
          loading: false,
        },
        trashed: {
          ...prevState.trashed,
          offset: LIMITPERPAGE,
          total: trashedResult.total,
          loading: false,
        },
      }));
    } else {
      setPaginationState((prevState) => ({
        ...prevState,
        still: {
          ...prevState.still,
          loading: false,
        },
        trashed: {
          ...prevState.trashed,
          loading: false,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId]);

  useEffect(() => {
    setRenderDirection(false);
    setSearchedState(searchInitialValues);
    setPaginationState(initialPaginationState);
    const fetchPoints = async () => {
      const params = {
        virtualCourseId: courseId,
      };
      await dispatch(fetchVirtualCoursePointsByOffset(params));
      setIsMapRenderAvailable(true);
    };
    if (courseId) {
      fetchUnassignedOrders();
      fetchPoints();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId]);

  useEffect(() => {
    if (searchedState.still.query.length) {
      const params = {
        limit: LIMITPERPAGE,
        offset: 0,
        filter: {
          and: [
            {
              assigned: { eq: false },
            },
            {
              trashed: { eq: false },
            },
          ],
        },
        searchQuery: searchedState.still.query,
      };
      const fetcheSearched = async () => {
        setSearchedState((prevState) => ({
          ...prevState,
          still: {
            ...prevState.still,
            searching: true,
          },
        }));
        const response = await dispatch(unassignedParallelSearch([params]));
        if (response.type === "unassignedParallelSearch/fetch/fulfilled") {
          const searchedItems = response.payload[0]?.items || [];
          const localItems = items.still.map((item) => ({
            item: { ...item },
            searchKeys: _.compact(searchForKeys(item, objKeys)),
          }));
          const matchedItems =
            searchObjectsByKeyword(localItems, searchedState.still.query)?.map(
              (v) => v.item
            ) || [];
          setSearchedState((prevState) => {
            return {
              ...prevState,
              still: {
                ...prevState.still,
                total: response.payload[0].total,
                offset: 0,
                searching: false,
              },
            };
          });
          const foundItems = _.uniqBy(
            [...searchedItems, ...matchedItems],
            "cycleId"
          );
          const assignedOrdersIds =
            listRef.current?.props?.itemData?.items.map(
              (item) => item.cycleId
            ) || [];
          const existingIds = _.concat(
            assignedOrdersIds,
            items?.trashed.map((item) => item.cycleId)
          );
          setItems((prevState) => ({
            ...prevState,
            still: foundItems.filter(
              (item) => !_.includes(existingIds, item.cycleId)
            ),
          }));
        }
        if (response.type === "unassignedParallelSearch/fetch/rejected") {
          setSearchedState((prevState) => ({
            ...prevState,
            still: {
              ...prevState.still,
              searching: false,
            },
          }));
        }
      };
      fetcheSearched();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedState.still.query]);

  useEffect(() => {
    if (searchedState.trashed.query.length) {
      const params = {
        limit: LIMITPERPAGE,
        offset: 0,
        filter: {
          and: [
            {
              assigned: { eq: false },
            },
            {
              trashed: { eq: true },
            },
          ],
        },
        searchQuery: searchedState.trashed.query,
      };
      const fetcheSearched = async () => {
        setSearchedState((prevState) => ({
          ...prevState,
          trashed: {
            ...prevState.trashed,
            searching: true,
          },
        }));
        const response = await dispatch(unassignedParallelSearch([params]));
        if (response.type === "unassignedParallelSearch/fetch/fulfilled") {
          const searchedItems = response.payload[0]?.items || [];
          const localItems = items.trashed.map((item) => ({
            item: { ...item },
            searchKeys: _.compact(searchForKeys(item, objKeys)),
          }));
          const matchedItems =
            searchObjectsByKeyword(
              localItems,
              searchedState.trashed.query
            )?.map((v) => v.item) || [];
          setSearchedState((prevState) => {
            return {
              ...prevState,
              trashed: {
                ...prevState.trashed,
                total: response.payload[0].total,
                offset: 0,
                searching: false,
              },
            };
          });
          const foundItems = _.uniqBy(
            [...searchedItems, ...matchedItems],
            "cycleId"
          );
          const assignedOrdersIds =
            listRef.current?.props?.itemData?.items.map(
              (item) => item.cycleId
            ) || [];
          const existingIds = _.concat(
            assignedOrdersIds,
            items?.still.map((item) => item.cycleId)
          );
          setItems((prevState) => ({
            ...prevState,
            trashed: foundItems.filter((i) => !existingIds.includes(i.cycleId)),
          }));
        } else {
          setSearchedState((prevState) => ({
            ...prevState,
            trashed: {
              ...prevState.trashed,
              searching: false,
            },
          }));
        }
      };
      fetcheSearched();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedState.trashed.query]);

  const onChangeDrivers = (val) => {
    setItems((prevState) => {
      return {
        ...prevState,
        done: {
          ...prevState.done,
          courses: prevState.done.courses.map((item) => {
            if (item.id === courseId) {
              return {
                ...item,
                assignedUsers: val,
              };
            }
            return item;
          }),
        },
      };
    });
  };

  const onChangeVehicle = (val) => {
    setItems((prevState) => {
      return {
        ...prevState,
        done: {
          ...prevState.done,
          courses: prevState.done.courses.map((item) => {
            if (item.id === courseId) {
              return {
                ...item,
                assignedVehicle: val,
              };
            }
            return item;
          }),
        },
      };
    });
  };

  const handleOnChangeName = (val) => {
    setItems((prevState) => {
      return {
        ...prevState,
        done: {
          ...prevState.done,
          courses: prevState.done.courses.map((item) => {
            if (item.id === courseId) {
              return {
                ...item,
                name: val,
              };
            }
            return item;
          }),
        },
      };
    });
  };

  const onSearchStateReset = (type) => {
    const assignedOrdersIds =
      listRef.current?.props?.itemData?.items.map((item) => item.cycleId) || [];
    const existingIds = _.concat(
      assignedOrdersIds,
      items[
        type === ordersType.still ? ordersType.trashed : ordersType.still
      ].map((item) => item.cycleId)
    );

    const tempListItems = _.filter(
      tempList?.length ? tempList : items?.[type] || [],
      (item) => !_.includes(existingIds, item.cycleId)
    );
    setItems((prevState) => ({
      ...prevState,
      [type]: tempListItems,
    }));
    setTempList([]);
    setSearchedState(searchInitialValues);
    return tempListItems.map((item) => item.cycleId);
  };

  const onSearchedWordSet = (text, type) => {
    setSearchedState((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        query: text,
      },
    }));
    if (!text.length) {
      onSearchStateReset(type);
      return;
    }
    text.length && setTempList(items[type]);
  };

  const handleLoadMore = async (type) => {
    setIsLoadingMore(true);
    const tracker = {
      courseIds: listRef?.current
        ? listRef.current?.props?.itemData?.items.map((item) => item.cycleId)
        : [],
      stillIds: items.still.map((item) => item.cycleId),
      trashedIds: items.trashed.map((item) => item.cycleId),
    };
    const existingIds = _.concat(
      tracker.courseIds,
      tracker.stillIds,
      tracker.trashedIds
    );

    if (searchedState[type].query.length) {
      const offset = searchedState[type].offset + LIMITPERPAGE;
      const params = {
        limit: LIMITPERPAGE,
        offset: offset,
        filter: {
          and: [
            {
              assigned: { eq: false },
            },
            {
              trashed: type === ordersType.still ? { eq: false } : { eq: true },
            },
          ],
        },
        searchQuery: searchedState[type].query,
      };
      const response = await dispatch(unassignedParallelSearch([params]));
      if (response.type === "unassignedParallelSearch/fetch/fulfilled") {
        const newItems = _.filter(
          response.payload[0].items,
          (item) => !_.includes(existingIds, item.cycleId)
        );
        setSearchedState((prevState) => {
          return {
            ...prevState,
            [type]: {
              ...prevState[type],
              offset: offset,
            },
          };
        });
        setItems((prevState) => ({
          ...prevState,
          [type]: [...prevState[type], ...newItems],
        }));
      }
      setIsLoadingMore(false);
      return;
    }
    const params = {
      limit: LIMITPERPAGE,
      offset: paginationState[type].offset,
      filter: {
        and: [
          {
            assigned: { eq: false },
          },
          {
            trashed: { eq: type === ordersType.still ? false : true },
          },
        ],
      },
    };
    const response = await dispatch(unassignedParallelSearch([params]));
    const { type: resultType, payload } = response;
    if (resultType === "unassignedParallelSearch/fetch/fulfilled") {
      const items = payload[0]?.items || [];
      const total = payload[0]?.total;
      const newItems = _.filter(
        items,
        (item) => !_.includes(existingIds, item.cycleId)
      );
      if (type === ordersType.trashed) {
        setTrashedIds((prevIds) =>
          _.uniq([...prevIds, ...(items.map((item) => item.cycleId) || [])])
        );
      }
      setItems((prevState) => ({
        ...prevState,
        [type]: [...prevState[type], ...newItems],
      }));
      setPaginationState((prevState) => ({
        ...prevState,
        [type]: {
          ...prevState[type],
          total: total || prevState[type].total,
          offset: prevState[type].offset + LIMITPERPAGE,
        },
      }));
    }
    setIsLoadingMore(false);
  };

  const handleReorder = (params) => {
    setRenderDirection(false);
    switch (params.source.droppableId) {
      case DROPPABLEID.UNASSIGNED: {
        setItems((prevState) => ({
          ...prevState,
          still: reorderFromDropResult(items.still, params),
        }));
        break;
      }
      case DROPPABLEID.TRASH: {
        setItems((prevState) => ({
          ...prevState,
          trashed: reorderFromDropResult(items.trashed, params),
        }));
        break;
      }
      default:
        const paramsValue = {
          ...params,
          source: {
            ...params.source,
            droppableId: courseId,
          },
        };
        setItems(reorderOfCycle(items, paramsValue));
        break;
    }
  };

  const alertDropDisable = (message) => {
    dispatch(
      addAlert({
        value: message ?? "検索中にドロップすることはできません。",
        severity: "info",
      })
    );
  };

  const handleMove = (params) => {
    setRenderDirection(false);
    const sourceId = params.source.droppableId;
    const destinationId = params.destination.droppableId;

    if (
      sourceId === DROPPABLEID.UNASSIGNED &&
      destinationId === DROPPABLEID.TRASH
    ) {
      if (searchedState.trashed.query.length) {
        alertDropDisable();
        return;
      }
      setItems((prevState) => ({
        ...prevState,
        ...moveItemsBetweenStillTrash(items, params),
      }));
      return;
    }

    if (
      sourceId === DROPPABLEID.TRASH &&
      destinationId === DROPPABLEID.UNASSIGNED
    ) {
      if (searchedState.still.query.length) {
        alertDropDisable();
        return;
      }
      setItems((prevState) => ({
        ...prevState,
        ...moveItemsBetweenStillTrash(items, params),
      }));
      return;
    }

    if (destinationId === DROPPABLEID.UNASSIGNED) {
      if (searchedState.still.query.length) {
        alertDropDisable();
        return;
      }
      const paramValue = {
        ...params,
        source: {
          ...params.source,
          droppableId: courseId,
        },
      };
      setItems(moveToUnassigned(paramValue, items));
      return;
    }

    if (sourceId === DROPPABLEID.UNASSIGNED) {
      const paramValue = {
        ...params,
        destination: {
          ...params.destination,
          droppableId: courseId,
        },
      };

      setItems(moveToCourse(paramValue, items));
      return;
    }

    if (destinationId === DROPPABLEID.TRASH) {
      if (searchedState.still.query.length) {
        alertDropDisable();
        return;
      }
      const paramsValue = {
        ...params,
        source: {
          ...params.source,
          droppableId: courseId,
        },
      };
      setItems(moveToTrashed(paramsValue, items));
      return;
    }

    if (sourceId === DROPPABLEID.TRASH) {
      const paramValue = {
        ...params,
        destination: {
          ...params.destination,
          droppableId: courseId,
        },
      };
      setItems(moveToCourse(paramValue, items));
      return;
    }
  };

  return {
    items,
    setItems,
    searchedState,
    setSearchedState,
    onChangeDrivers,
    onChangeVehicle,
    handleOnChangeName,
    onSearchedWordSet,
    activeCollectionPointId,
    setActiveCollectionPointId,
    renderDirection,
    setRenderDirection,
    isMapRenderAvailable,
    setIsMapRenderAvailable,
    paginationState,
    isLoadingMore,
    handleLoadMore,
    handleReorder,
    handleMove,
    trashedIds,
    onSearchStateReset,
  };
};
