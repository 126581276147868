/**
 * プランによって制限を行う処理群
 */

/**
 * 制限する事業場区分を取得します。
 * @returns {array}
 */
const fetchRestrictWorkplaceCategories = () => {
  return ["コンテナ保管場所", "コンテナ設置場所"];
};

/**
 * 事業場区分を制限します。
 * @param {object} props - プロパティ 
 * @param {array} props.workplaceCategories - 事業場区分配列
 * @param {boolean} props.isGeneralWastesPlan - 一般廃棄物管理プランであるか？
 * @returns {JSX.Element}
 */
export const restrictWorkplaceCategories = ({
  workplaceCategories = [],
  isGeneralWastesPlan = false,
}) => {
  if (!workplaceCategories || workplaceCategories.length <= 0) {
    return [];
  }

  if (isGeneralWastesPlan) {
    const restrictWorkplaceCategories = fetchRestrictWorkplaceCategories();
    return workplaceCategories.filter(
      (category) =>
        !restrictWorkplaceCategories.some(
          (restrictWorkplaceCategory) =>
            restrictWorkplaceCategory === category.name
        )
    );
  }

  return workplaceCategories;
};

/**
 * 現在のプランが一般廃棄物管理プランであるか確認します。
 * @param {object | string} value 加入者情報 又は 文字列
 * @returns {boolean}
 */
export const isGeneralWastesPlan = (value) => {
  if (!value) {
    // 値が取れない場合はひとまず隠す
    return true;
  }

  if (typeof value === "string") {
    return value === "一般廃棄物管理プラン";
  }

  return value?.plan?.name === "一般廃棄物管理プラン";
};

/**
 * 一般廃棄物管理プラン時、制限するルートパスを取得します。
 * @returns {string[]} - 制限するルートパス配列
 */
const fetchRestrictRoutePaths = () => {
  // ダッシュボード
  const dashboard = [];

  // 日常業務
  const bussiness = [
    // コンテナ管理
    "/container-list",
    "/container-list/:id",
    // 伝票入力
    "/slip",
    "/slip/electronic",
    "/slip/electronic/:id",
    "/slip/paper",
    "/slip/paper/:id",
  ];

  // 管理機能
  const management = [
    // コンテナ管理
    "/master/container/category/list",
    "/master/container/category/list/:id",
    "/master/container/category/list/:id/product/:productId",
    // 外部接続サービス設定(現在JWNETだけなのでメニューごと消す 2023/1/23)
    "/link",
    "/link/jwnet",
  ];

  // モバイル
  const mobile = [];

  return [...dashboard, ...bussiness, ...management, ...mobile];
};

/**
 * ルート情報を制限します。
 * @param {object} props - プロパティ
 * @param {object[]} props.routes - ルート情報配列
 * @param {boolean} props.isGeneralWastesPlan - 一般廃棄物管理プラン
 */
export const restrictRoutes = ({
  routes = [],
  isGeneralWastesPlan = false,
}) => {
  if (!routes || routes.length <= 0) {
    return [];
  }

  if (isGeneralWastesPlan) {
    const restrictRoutesPaths = fetchRestrictRoutePaths();
    return routes.filter(
      (route) =>
        !restrictRoutesPaths.some(
          (restrictRoute) => restrictRoute === route.path
        )
    );
  }

  return routes;
};
