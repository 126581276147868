import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useOpener } from "utils/useOpener";
import { PrivacyPolicyAgreeDialog } from "../PrivacyPolicyAgreeDialog";
import { TermsOfServiceAgreeDialog } from "../TermsOfServiceAgreeDialog";

/**
 * 同意ダイアログを表示するコンポーネント。
 * @param {object} props プロパティ
 * @fires AgreeDialog#onClose 閉じる時
 * @fires AgreeDialog#onAgree 同意時
 * @param {boolean} props.open 開閉状態
 * @param {boolean} props.disabled 無効設定
 * @returns {JSX.Element}
 */
export const AgreeDialog = ({
  open = false,
  onClose = () => {},
  onAgree = () => {},
  disabled = false,
}) => {
  const [value, setValue] = useState(null);
  const privacyPolicy = useOpener();
  const termsOfService = useOpener();

  useEffect(() => {
    if (open) {
      privacyPolicy.toggle(open);
      setValue(null);
    } else {
      privacyPolicy.toggle(open);
      termsOfService.toggle(open);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleAgreePrivacyPolicy = () => {
    privacyPolicy.toggle(false);
    setValue((prevState) => ({
      ...prevState,
      agreedOfPrivacyPolicy: DateTime.now(),
    }));
    termsOfService.toggle(true);
  };

  const handleAgree = () => {
    const result = {
      ...value,
      agreedOfTermsOfService: DateTime.now(),
    };
    setValue(result);
    termsOfService.toggle(false);
    onAgree(result);
  };

  return (
    <>
      <PrivacyPolicyAgreeDialog
        open={privacyPolicy.open}
        disabled={disabled}
        onClose={onClose}
        onAgree={handleAgreePrivacyPolicy}
      />
      <TermsOfServiceAgreeDialog
        open={termsOfService.open}
        disabled={disabled}
        onClose={onClose}
        onAgree={handleAgree}
      />
    </>
  );
};
