import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { Alert, XGridComponent } from "views/molecules";
import { InspectionExpirationDateForm } from "views/organisms/Mobile/Vehicle/InspectionExpirationDateForm";
import { ODOMeterForm } from "views/organisms/Mobile/Vehicle/ODOMeterForm";
import { ChecksheetsTemplateSelector } from "views/molecules/ChecksheetsTemplateSelector";
import { VehicleInspectionForm } from "views/organisms/Mobile/Vehicle/VehicleInspectionForm";
import { formatDisplayDate, formatDisplayDateFromISO } from "utils/format";

/**
 * 編集マークを表示するコンポーネントです。
 * @param {JSX.Element} children 子要素
 * @returns {JSX.Element}
 */
const EditableMark = ({ children = <></> }) => {
  return (
    <Stack
      direction={"row"}
      sx={{
        borderBottom: "1px solid #2196f3",
      }}
    >
      <ModeEditOutlinedIcon />
      <Box>{children}</Box>
    </Stack>
  );
};

/**
 * 編集可能な項目を表示するコンポーネントです。
 * @param {string} label ラベル
 * @param {JSX.Element} children 子要素
 * @fires EditableItem#onClick クリック時
 * @returns {JSX.Element}
 */
const EditableItem = ({ label = "", onClick = () => {}, children = <></> }) => {
  return (
    <Card onClick={onClick}>
      <CardActionArea>
        <CardContent>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Box>
              <Typography
                sx={{
                  color: "gray",
                }}
              >
                {label}
              </Typography>
            </Box>
            <Box>
              <EditableMark>{children}</EditableMark>
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

/**
 * モバイルの車輛点検を表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <>
      <Stack
        direction="column"
        justifyContent={"space-between"}
        sx={(theme) => ({
          height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        })}
      >
        <Stack spacing={2}>
          <Stack
            direction="row"
            spacing={1}
            onClick={props.onBack}
            sx={{
              mt: 2,
              ml: 1,
            }}
          >
            <WestOutlinedIcon />
            <Typography>{"一覧に戻る"}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Stack direction="row" alignItems="center">
              <Avatar
                src={null}
                sx={{
                  ml: 2,
                  mr: 2,
                }}
              >
                <LocalShippingOutlinedIcon />
              </Avatar>
              <Stack spacing={1}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {props.value?.number}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "gray",
                  }}
                >
                  {props.value?.category?.name}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <EditableItem
              label="車検満了日"
              onClick={() => {
                props?.inspectionExpirationDateForm?.toggle(true);
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                {formatDisplayDate({
                  source: props.value?.certificateExpiredOn,
                })}
              </Typography>
            </EditableItem>
            <EditableItem
              label="ODOメーター"
              onClick={() => {
                props?.odoMeterForm?.toggle(true);
              }}
            >
              <Stack direction={"row"} alignItems="center">
                <Typography
                  variant="caption"
                  sx={{
                    color: "gray",
                  }}
                >
                  {`${
                    !!props?.value?.instrumentUpdatedAt
                      ? `(${formatDisplayDateFromISO({
                          source: props?.value?.instrumentUpdatedAt,
                        })})`
                      : ``
                  }`}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {props?.value?.odometer}
                </Typography>
              </Stack>
            </EditableItem>
          </Stack>
          <Stack>
            <Typography
              sx={{
                ml: 1,
              }}
            >
              {"点検履歴"}
            </Typography>
            <XGridComponent
              onChangeSelection={props.onSelected}
              checkboxSelection={false}
              multipleSelection={false}
              onDownload={false}
              onFilter={false}
              onSearcher={false}
              onColumns={false}
              pageSize={5}
              {...props.xGridParams}
            />
          </Stack>
        </Stack>
        <Box
          sx={{
            p: 2,
            position: "sticky",
            top: "auto",
            bottom: 0,
            backgroundColor: "#fff",
          }}
        >
          <Button
            variant="contained"
            fullWidth
            onClick={() => props.checkSheetSelector.toggle(true)}
            disabled={props.loading}
          >
            {"点検を行う"}
          </Button>
        </Box>
      </Stack>
      <Alert />
      <InspectionExpirationDateForm
        value={props.value}
        open={props?.inspectionExpirationDateForm?.open}
        onClose={() => props?.inspectionExpirationDateForm?.toggle(false)}
        disabled={props.loading}
        onSubmit={props.onUpdateVehicleCertificate}
      />
      <ODOMeterForm
        value={props.value}
        open={props.odoMeterForm.open}
        onClose={() => props?.odoMeterForm?.toggle(false)}
        disabled={props.loading}
        onSubmit={props.onUpdateVehicleInstrument}
      />
      <ChecksheetsTemplateSelector
        open={props.checkSheetSelector.open}
        onClick={(checksheet) => {
          props.vehicleInspectionForm.changeOptions(checksheet);
          props.vehicleInspectionForm.toggle(true);
        }}
        onClose={() => props.checkSheetSelector.toggle(false)}
      />
      <VehicleInspectionForm
        value={props?.value}
        checksheetTemplate={props.vehicleInspectionForm?.options}
        open={props.vehicleInspectionForm?.open}
        onClose={() => props.vehicleInspectionForm?.toggle(false)}
        disabled={props.loading}
        onSubmit={props.onCreateInspection}
      />
    </>
  );
};
