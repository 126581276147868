import { Tooltip } from "@mui/material";

/**
 * muiのDataGridで使用するセルのツールチップを表すプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({ title, children, ...other }) => (
  <Tooltip
    title={title}
    PopperProps={{ disablePortal: true }}
    placement="bottom-start"
    disableInteractive
    arrow
    followCursor={true}
    {...other}
  >
    <span
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {children}
    </span>
  </Tooltip>
);
