import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  pdf_view_page: {
    height: "100%",
    maxHeight: "600px",
    overflow: "auto",
  },
  onLoading: {},

  loading_progress: { padding: "30px", textAlign: "center" },
  noData: {},
  view_file: {},

  pdf_checkbox: {
    textAlign: "right",
    padding: theme.spacing(3),
  },
}));
