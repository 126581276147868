import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import {
  useNavigate,
  useLocation,
  matchPath,
  generatePath,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import useClasses from "utils/useClasses";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { getContract } from "api/graphql/queries";
import { updateContract, removeContract } from "api/graphql/mutations";
import { debugLog } from "utils/log";
import { DateTime } from "luxon";
import { add as addAlert } from "ducks/Alert";
import { toggle } from "ducks/Loading";
import { format as fileSizeFormat } from "views/molecules/FileUploader/Container";

const readBlobAsDataUrl = (blob) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new Error("Problem parsing input blob."));
    };
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
};

const formatContract = (value) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = { ...value };
      for (const file of result.files) {
        const res = await fetch(file.url, {
          method: "GET",
          mode: "cors",
        });
        const blob = await res.blob();
        const dataUrl = await readBlobAsDataUrl(blob);
        file.extensions = file?.name.split(".").pop();
        file.size = fileSizeFormat(blob.size, 2);
        file.context = dataUrl;
      }
      resolve(result);
    } catch {
      reject(new Error("Problem formatting value."));
    }
  });
};

/**
 * 契約詳細画面を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const classes = useClasses(useStyles);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [value, setValue] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const { contractId } = useParams();
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isOpenRemoveDialog, setIsOpenRemoveDialog] = useState(false);
  const queries = new URLSearchParams(useLocation().search);
  const fromAlert = queries.get("alert") !== null;

  const load = useCallback(() => {
    dispatch(toggle(true));
    API.graphql(
      graphqlOperation(getContract, {
        id: contractId,
      })
    )
      .then((res) => {
        formatContract(res.data.getContract).then((data) => {
          setValue(data);
        });
      })
      .catch((err) => {
        dispatch(toggle(false));
        debugLog(err);
      })
      .finally(() => {
        dispatch(toggle(false));
      });
  }, [dispatch, contractId]);

  useEffect(() => {
    load();
  }, [load]);

  const format = (value) => {
    if (!value) {
      return null;
    }

    return {
      id: value.id,
      ownerCompanyId: value.ownerCompanyId,
      name: value.name,
      number: value.number,
      contractType: value.type,
      parties: value.partyCompanies.map((i) => ({
        companyId: i.id,
        name: i.name,
      })),
      executedOn: value.executedOn,
      contractOn: {
        start: value.termOfAgreementStartedOn,
        end: value.termOfAgreementExpirationOn,
      },
      isAutomaticallyRenewed: value.isAutomaticallyRenewed,
      remarks: value.remarks,
      files: value.files,
      version: value.version,
    };
  };

  const handleGetTabNumber = (newTab) => {
    setTab(newTab);
  };

  const handleUpdate = (params) => {
    setIsSubmit(true);
    const {
      id,
      number,
      name,
      contractType,
      parties,
      executedOn,
      contractOn,
      isAutomaticallyRenewed,
      files,
      remarks,
      version,
    } = params;
    API.graphql(
      graphqlOperation(updateContract, {
        input: {
          id: id,
          number: number,
          name: name,
          contractTypeId: contractType.id,
          partyCompanyIds: parties.map((party) => party.companyId),
          executedOn: DateTime.fromJSDate(new Date(executedOn)).toFormat(
            "yyyy-MM-dd"
          ),
          termOfAgreementStartedOn: DateTime.fromJSDate(
            new Date(contractOn.start)
          ).toFormat("yyyy-MM-dd"),
          termOfAgreementExpirationOn: DateTime.fromJSDate(
            new Date(contractOn.end)
          ).toFormat("yyyy-MM-dd"),
          isAutomaticallyRenewed: isAutomaticallyRenewed,
          remarks: remarks ?? "",
          files:
            files?.map((file, index) => ({
              name: file.name,
              base64: file.context,
              order: index,
              label: file.name,
            })) ?? null,
          expectedVersion: version,
        },
      })
    )
      .then((res) => {
        dispatch(
          addAlert({
            value: "更新しました。",
            severity: "success",
          })
        );
        setIsOpenEditDialog(false);

        if (fromAlert) {
          navigate(
            {
              pathname: `/alert-contracts`,
            },
            {
              replace: true,
            }
          );
        } else {
          load();
        }
      })
      .catch((err) => {
        dispatch(
          addAlert({
            value: "エラーが発生したため、更新できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleRemove = () => {
    API.graphql(
      graphqlOperation(removeContract, {
        input: {
          id: value.id,
        },
      })
    )
      .then((res) => {
        dispatch(
          addAlert({
            value: "削除しました。",
            severity: "success",
          })
        );
        // TODO：ルートを整理して共通のパスを利用するように変更する必要あり。
        const match = matchPath(
          { path: "/master/partner/:id", end: false },
          location.pathname
        );
        if (match) {
          navigate(generatePath(match.pattern.path, match.params), {
            replace: true,
          });
        } else {
          navigate(`/`, { replace: true });
        }
      })
      .catch((err) => {
        dispatch(
          addAlert({
            value: "エラーが発生したため、削除できませんでした。",
            severity: "error",
          })
        );
      });
  };

  return render({
    ...props,
    classes: classes,
    value: value,
    tab: tab,
    getTab: handleGetTabNumber,
    isOpenEditDialog: isOpenEditDialog,
    isOpenRemoveDialog: isOpenRemoveDialog,
    onOpenEditDialog: () => setIsOpenEditDialog(true),
    onOpenRemoveDialog: () => setIsOpenRemoveDialog(true),
    onCloseEditDialog: () => setIsOpenEditDialog(false),
    onCloseRemoveDialog: () => setIsOpenRemoveDialog(false),
    onUpdate: handleUpdate,
    onRemove: handleRemove,
    isSubmit: isSubmit,
    format: format,
  });
};
