import { Button, Stack } from "@mui/material";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { SafetiesDetails } from "views/organisms/Vehicles/Common";
import { VehicleInspectionRecordForm } from "views/organisms/Vehicles/Details";
import { VehiclesMaintenanceForm } from "views/organisms/Vehicles/Details/VehiclesMaintenanceForm";
import { ConfirmDialog } from "views/molecules";

/**
 * 車輛詳細点検を表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <>
      <DetailsFrame
        caption={<></>}
        actions={
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              color="secondary"
              sx={{
                margin: "1px",
              }}
              onClick={() => props.form.toggle(true)}
              disabled={props.loading}
            >
              {"編集する"}
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                margin: "1px",
              }}
              onClick={() => props.confirmDialog.toggle(true)}
              disabled={props.loading}
            >
              {"削除する"}
            </Button>
            <Button
              variant="contained"
              sx={{
                margin: "1px",
              }}
              onClick={() => props.maintenanceForm.toggle(true)}
              disabled={props.loading}
            >
              {"整備記録を追加する"}
            </Button>
          </Stack>
        }
      >
        <SafetiesDetails
          value={props.value}
          relatedSafetyInspectionId={props.safetiesId}
          onSelected={props.onSelected}
          _ref={props.safetiesDetailsRef}
        />
        <VehicleInspectionRecordForm
          value={props.value}
          open={props.form.open}
          onClose={() => props.form.toggle(false)}
          disabled={props.loading}
          onSubmit={props.onUpdate}
          title={`車検記録の編集`}
          confirmText={"保存"}
          currentExpiryDate={props.managementVehicle?.certificateExpiredOn}
        />
        <VehiclesMaintenanceForm
          open={props.maintenanceForm.open}
          onClose={() => props.maintenanceForm.toggle(false)}
          disabled={props.loading}
          onSubmit={props.onCreateMaintenance}
        />
        <ConfirmDialog
          title="削除確認"
          message="削除します。よろしいですか？"
          open={props.confirmDialog?.open}
          onNegative={() => props.confirmDialog.toggle(false)}
          onPositive={props.onRemove}
          onClose={() => props.confirmDialog.toggle(false)}
          disabled={props.loading}
          positiveText="はい"
          negativetext="いいえ"
        />
      </DetailsFrame>
    </>
  );
};
