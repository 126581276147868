import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 事業場の廃棄物基本情報を登録編集するコンポーネントです。
 */
export const WasteBasicForm = containerPresententer(
  Container,
  Presententer
);
