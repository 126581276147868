import { debugLog } from "utils/log";
import { useTheme } from "@mui/material/styles";
import useClasses from "utils/useClasses";
import { styles } from "./styles";

/**
 * フィールドコンポーネントの装飾を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  label = "",
  required = false,
  help = false,
  onClickHelp = (event) => debugLog(event),
  other = null,
  ...props
}) => {
  const theme = useTheme();
  const classes = useClasses(styles);
  return render({
    theme: theme,
    classes: classes,
    label: label,
    required: required,
    help: help,
    onClickHelp: onClickHelp,
    other: other,
    ...props,
  });
};
