import { companySelector } from "ducks/Company";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { fromQueryString, toQueryString } from "utils/useXGridComponents";

/**
 * 自社事業場情報を表示するコンテナコンポーネントです
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const company = useSelector(companySelector);

  const onTransite = (e) => {
    navigate(
      {
        pathname: location.pathname,
        search: toQueryString(e.info.search),
      },
      {
        replace: true,
      }
    );
    navigate(`${location.pathname}/${e.value[0].id}`);
  };

  return render({
    onTransite: onTransite,
    id: company?.id,
    initialState: fromQueryString(location.search),
    ...props,
  });
};
