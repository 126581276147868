import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  // コンテナー
  content: {
    padding: theme.spacing(2),
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.text.disabled,
    "&:last-child": {
      borderBottom: 0,
    },
  },
  text: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& >span": {
      color: theme.palette.grey[700],
      marginBottom: "4px",
    },

    "& .label": {
      color: theme.palette.primary.main,
      fontWeight: 600,
      minWidth: "60px",
      display: "inline-block",
    },
    "& .icon": {
      verticalAlign: "top",
    },
  },
  text_image: {
    marginTop: theme.spacing(2),
  },

  
}));
