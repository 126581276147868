import { useFormContext } from "react-hook-form";

/**
 * 最終処分情報を入力するコンテナコンポーネントです。
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const { control } = useFormContext();
  return render({
    control: control,
  });
};
