import SecurityUpdateWarningOutlinedIcon from "@mui/icons-material/SecurityUpdateWarningOutlined";
import { Grid } from "@mui/material";
import React from "react";
import { RenderMenuSelectItem } from "views/atoms";

/**
 * 外部サービス連携のメニュープレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Grid container spacing={2} sx={{ marginTop: 0 }}>
      <Grid item>
        <RenderMenuSelectItem
          icon={SecurityUpdateWarningOutlinedIcon}
          label="JWNET"
          onClick={() => props.onClick("jwnet")}
        />
      </Grid>
    </Grid>
  );
};
