import { CircularProgress, Stack } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

/**
 * PDFを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.src ファイルソース
 * @param {number} props.height 高さ
 * @param {number} props.width 幅
 * @returns {JSX.Element}
 */
export const PDFViewer = ({ src, height, width }) => {
  const [numPages, setNumPages] = useState(0);
  const [error, setError] = useState(null);

  const options = useMemo(() => {
    return {
      cMapUrl: `${process.env.PUBLIC_URL}/cmaps/`,
      cMapPacked: true,
      standardFontDataUrl: `${process.env.PUBLIC_URL}/standard_fonts/`,
    };
  }, []);

  useEffect(() => {
    setNumPages(0);
  }, [src]);

  if (!src) {
    return <></>;
  }

  const handleLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  if (error) {
    return error;
  }

  return (
    <Stack alignItems={"center"}>
      <Document
        file={src}
        loading={<CircularProgress />}
        onLoadSuccess={handleLoadSuccess}
        onLoadError={() => setError("読み込み中にエラーが発生しました。")}
        options={options}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            height={height}
            width={width ?? 900}
          />
        ))}
      </Document>
    </Stack>
  );
};
