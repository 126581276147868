import { containerPresententer } from "utils/HoC";
import { Container as ContainerOfContainer } from "./Container";
import { Presententer } from "./Presententer";

/**
 * チェックシート一覧を表示するコンポーネントです。
 */
export const List = containerPresententer(
  ContainerOfContainer,
  Presententer
);
