import { requiredField } from "utils/styles";

export const styles = ((theme) => ({
  label: {
    color: theme.palette.text.secondary,
  },
  sizeLarge: {
    "& svg": {
      fontSize: "2rem",
    },
  },
  mainTitle: {
    color: theme.palette.text.secondary,
    fontSize: "15px",
  },
  subTitle: {
    color: theme.palette.text.disabled,
    fontSize: "12px",
  },
  controlLabelRadio: {
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  required: {
    ...requiredField(theme),
  },
}));
