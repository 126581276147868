import { useState } from "react";
import { PopupMenu } from "../PopupMenu";
import { Button as UserButton } from "./Button";
import { useSelector } from "react-redux";
import { selector } from "ducks/User";
import { useSignOut } from "utils/useSignOut";
import { useNavigate } from "react-router-dom";

/**
 * ユーザーアカウントボタンを表示するコンポーネントです。
 */
export const Button = () => {
  const navigate = useNavigate();
  const [menuElement, setMenuElement] = useState(null);
  const user = useSelector(selector);
  const { signOut } = useSignOut();

  const handleSignOut = () => {
    navigate(
      {
        pathname: "/",
      },
      {
        replace: true,
      }
    );
    signOut();
  };

  return (
    <>
      <UserButton
        value={user.user}
        loading={user.isLoading}
        onClick={(event) => {
          setMenuElement(event.currentTarget);
        }}
      />
      <PopupMenu
        value={user.user}
        anchorEl={menuElement}
        open={Boolean(menuElement)}
        onClose={() => setMenuElement(null)}
        onSignOut={handleSignOut}
      />
    </>
  );
};
