import { useStyles } from "./styles";

/**
 * 引数を受けてJSX Elemntを返します。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({ render, value, index, ...props }) => {
  const classes = useStyles();
  return render({
    classes: classes,

    value: value,
    index: index,

    ...props,
  });
};