/**
 * コンテナ管理の表を表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  value,
  onNotification = () => {},
  ...props
}) => {
  return render({
    ...props,
  });
};
