export const styles = (theme) => ({
  label: {
    color: theme.palette.text.secondary,
    fontSize: "14px",
    paddingRight: 2,
    marginRight: "6px",
  },
  iconButton: {
    padding: 0,
    float: "right",
    "& svg": {
      width: "20px",
      height: "20px",
    },
  },
});
