import { Alert, CircularProgress } from "@mui/material";
import { get } from "lodash";
import { XGridComponent } from "views/molecules";
import { searchVehiclePeriodicInspectionsByOffset } from "api/graphql/queries";
import { useXGridComponents } from "utils/useXGridComponents";
import { useSelector } from "react-redux";
import { companySelector } from "ducks/Company";
import { DateTime } from "luxon";
import { formatDisplayDate } from "utils/format";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import { withForwardRef } from "utils/HoC";
import { useImperativeHandle } from "react";
import { toQueryString, fromQueryString } from "utils/useXGridComponents";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

const columns = [
  {
    field: "checkedOn",
    headerName: "点検日",
    minWidth: 100,
    valueFormatter: (params) => {
      return formatDisplayDate({ source: params.value });
    },
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "vehicleModelName",
    headerName: "車種",
  },
  {
    field: "vehicleNumber",
    headerName: "車両ナンバー",
  },
  {
    field: "personInChargeName",
    headerName: "点検者",
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "checksheetName",
    headerName: "点検の名称",
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "numberOfChecks",
    headerName: "チェック数",
    flex: 1,
    renderCell: (params) => {
      return `${params.row.numberOfChecks} / ${params.row.totalOfItems}`;
    },
    filterable: false,
  },
  {
    field: "updatedAt",
    headerName: "最終更新日",
    minWidth: 170,
    valueFormatter: (params) => {
      return params.value
        ? DateTime.fromISO(params.value, { setZone: "Asia/Tokyo" }).toFormat(
            "yyyy/MM/dd HH:mm:ss"
          )
        : "";
    },
    filterable: false,
  },
];

/**
 * 定期点検一覧を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {array} rows 配列
 * @param {boolean} loading 読み込み中
 * @param {boolean} error エラー
 * @returns {JSX.Element}
 */
export const PeriodicInspections = withForwardRef(
  ({ vehicleId, loading = false, error = null, _ref }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const company = useSelector(companySelector);
    const obj = useXGridComponents(
      columns,
      searchVehiclePeriodicInspectionsByOffset,
      {
        filter: {
          and: [
            {
              vehicleId: {
                eq: vehicleId,
              },
            },
          ],
        },
        other: {
          ownerCompanyId: company?.id,
        },
      },
      {
        sort: {
          direction: "desc",
          field: "checkedOn",
        },
        ...fromQueryString(location.search),
        filter: {
          defaultColumnField: get(columns, "[4].field"),
        },
      }
    );

    useImperativeHandle(_ref, () => ({
      refresh: () => {
        obj.functions.refetch();
      },
    }));

    const handleSelected = (params) => {
      navigate(
        {
          pathname: location.pathname,
          search: `?${createSearchParams({
            ...Object.fromEntries(new URLSearchParams(location.search)),
            ...Object.fromEntries(
              new URLSearchParams(toQueryString(obj.search))
            ),
          }).toString()}`,
        },
        {
          replace: true,
        }
      );

      navigate(`${location.pathname}/periodic-inspection/${params?.[0]}`);
    };

    if (error) {
      return (
        <Alert severity="error">
          {"エラーが発生したため、表示できませんでした。"}
        </Alert>
      );
    }

    if (loading) {
      return <CircularProgress />;
    }

    return (
      <XGridComponent
        onChangeSelection={handleSelected}
        checkboxSelection={false}
        multipleSelection={false}
        onDownload={true}
        {...obj.params}
      />
    );
  }
);
