import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 住所を入力するコンポーネントです
 * @param {string} suffix Controllerの名前　例）name={suffix + "name"}
 * @returns　JSX Element
 */
export const AddressFromPostCode = containerPresententer(
  Container,
  Presententer
);
