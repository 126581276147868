import { DateTime } from "luxon";
import { useEffect, useReducer, useState } from "react";
import { debugLog } from "utils/log";

const getThisMonth = () => {
  const now = new Date();
  return DateTime.fromJSDate(new Date(now.getFullYear(), now.getMonth(), 1));
};

const defaultValue = {
  current: DateTime.now(),
  mode: "day",
};

const ActionTypes = {
  SET_PREV: "dateStepper/set_prev",
  SET_NEXT: "dateStepper/set_next",
  SET_MODE: "dateStepper/set_mode",
  SET_VALUE: "dateStepper/set_value",
};

const reducerFunc = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_PREV: {
      const prev =
        state.mode === "day"
          ? state.current.minus({ day: 1 })
          : state.mode === "month"
          ? state.current.minus({ months: 1 })
          : state.current;
      return {
        ...state,
        current: prev,
      };
    }
    case ActionTypes.SET_NEXT: {
      const next =
        state.mode === "day"
          ? state.current.plus({ day: 1 })
          : state.mode === "month"
          ? state.current.plus({ months: 1 })
          : state.current;
      return {
        ...state,
        current: next,
      };
    }
    case ActionTypes.SET_MODE: {
      const now =
        action.payload.mode === "day"
          ? DateTime.now()
          : action.payload.mode === "month"
          ? getThisMonth()
          : DateTime.now();
      return {
        ...state,
        current: now,
        mode: action.payload.mode,
      };
    }
    case ActionTypes.SET_VALUE: {
      return {
        ...state,
        current: action.payload.current,
      };
    }
    default:
      return state;
  }
};

/**
 * 期間ステップを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {Date} value Dateオブジェクト
 * @fires Container#onChange 変更時
 * @param {string} mode 'day' | 'month'
 * @param {JSX.Element} actions ボタンなどのアクション要素
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value = DateTime.now(),
  onChange = (date) => debugLog(date),
  mode = "day",
  actions = <></>,
  ...props
}) => {
  const [date, setDate] = useState(value);
  const [state, dispatch] = useReducer(reducerFunc, defaultValue);
  const [init, setInit] = useState(false);

  useEffect(() => {
    dispatch({
      type: ActionTypes.SET_MODE,
      payload: {
        mode: mode,
      },
    });
  }, [mode]);

  useEffect(() => {
    dispatch({
      type: ActionTypes.SET_VALUE,
      payload: { current: value },
    });

    setDate(value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(value)]);

  useEffect(() => {
    if (init) {
      onChange(state.current);
    } else {
      setInit(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(state.current?.toFormat("yyyyMMdd"))]);

  const handleClickLeft = () => {
    dispatch({
      type: ActionTypes.SET_PREV,
      payload: value,
    });
  };

  const handleClickRight = () => {
    dispatch({
      type: ActionTypes.SET_NEXT,
      payload: value,
    });
  };

  const handleThis = () => {
    dispatch({
      type: ActionTypes.SET_MODE,
      payload: {
        mode: state.mode,
      },
    });
  };

  const handleChange = (value) => {
    dispatch({
      type: ActionTypes.SET_VALUE,
      payload: { current: value },
    });
  };

  const getText = () => {
    switch (mode) {
      case "day":
        return state.current.setLocale("jp-JP").toFormat("yyyy/MM/dd (EEE)");
      case "month":
        return state.current.toFormat("yyyy年MM月");
      default:
        return state.current.toISOString();
    }
  };

  return render({
    ...props,
    date: date,
    onChangeDate: (date) => setDate(date),
    onAccept: handleChange,
    value: state.current,
    text: getText(),
    onClickLeft: handleClickLeft,
    onClickRight: handleClickRight,
    onClickThis: handleThis,
    mode: state.mode,
    actions: actions,
  });
};
