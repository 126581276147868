import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * ボタンを表示するコンポーネントです
 * @param {string} label ラベル
 * @param {string} variant 例）contained・outlined・text
 * @param {string} color 例）primary・secondary・text
 * @fires RenderButton#onClick クリック時
 * @param {boolean} disabled 有効無効
 * @param {string} className クラス名
 * @param {string} icon アイコン
 * @returns {JSX.Element}
 */
export const RenderButton = containerPresententer(Container, Presententer);
