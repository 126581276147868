import { useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { debugLog } from "utils/log";
import { DetailsFormDefaultValue } from "../../Utils";

/**
 * 紙マニフェストの詳細入力するコンテナコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onSubmit = (data) => debugLog("詳細検証成功: ", data),
  onError = (err) => debugLog("詳細検証失敗: ", err),
  editable = true,
  ...props
}) => {
  const methods = useForm({
    defaultValues: {
      ...DetailsFormDefaultValue,
      ...value,
    },
  });

  useEffect(() => {
    methods.reset({
      ...DetailsFormDefaultValue,
      ...value,
    });
  }, [methods, value]);

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      methods.trigger().then((res) => {
        if (res) {
          onSubmit && onSubmit(methods.getValues());
        } else {
          onError(methods.formState.errors);
        }
      });
    },
  }));

  // todo: 各モーダルセクションフォームから値を受け取ってsetValueで設定する

  return render({
    control: methods.control,
    value: methods.watch(),
    editable: editable,
    ...props,
  });
};
