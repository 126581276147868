import { updateWorkplace, removeWorkplace } from "api/graphql/mutations";
import { getClient, getWorkplace } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { companySelector } from "ducks/Company";
import { toggle } from "ducks/Loading";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router";
import { PhoneNumberFormatUtil } from "utils/format";
import { debugLog } from "utils/log";
import { useMutation } from "utils/useMutation";

/**
 * 取引先詳細ページでの事業場詳細の画面を表示するコンテナコンポーネントです。
 * @param {object} props プロパティ
 */
export const Container = ({ render, ...props }) => {
  const dispatch = useDispatch();
  const { id, workplaceId } = useParams();
  const [value, setValue] = useState(null);
  const [client, setClient] = useState(null);
  const company = useSelector(companySelector);
  const navigate = useNavigate();

  const [removeMutation, removeMutationStatus] = useMutation(
    removeWorkplace,
    {
      onCompleted: (data) => {
        navigate(`/master/partner/${id}`);
      },
      succeedMessage: "削除しました。",
      errorMessage: "エラーが発生したため、削除できませんでした。",
    }
  );

  useEffect(() => {
    if (company) {
      dispatch(toggle(true));
      API.graphql(
        graphqlOperation(getWorkplace, {
          id: workplaceId,
        })
      )
        .then((res) => {
          const { phone, fax, ...other } = res.data.getWorkplace;
          setValue({
            phone: PhoneNumberFormatUtil.formatNational(phone),
            fax: PhoneNumberFormatUtil.formatNational(fax),
            ...other,
          });
          loadClient(id);
        })
        .catch((err) => {
          debugLog(err);
        })
        .finally(() => {
          dispatch(toggle(false));
        });
    }
  }, [id, workplaceId, dispatch, company]);

  const loadClient = (clientId) => {
    API.graphql(
      graphqlOperation(getClient, {
        id: clientId,
      })
    )
      .then((res) => {
        setClient(res.data.getClient);
      })
      .catch((err) => {
        debugLog(err);
      });
  };

  const executeUpdate = (data) => {
    const {
      categories,
      prefectures,
      industrySector,
      phone,
      fax,
      createdAt,
      updatedAt,
      version,
      state,
      belongInCompanyId,
      profileVersion,
      addressVersion,
      contactDetailsVersion,
      email,
      ...other
    } = data;

    return API.graphql(
      graphqlOperation(updateWorkplace, {
        input: {
          categoryIds: categories?.map((category) => category.id),
          prefecturesCode: prefectures.code,
          industrySectorId: industrySector?.id,
          phone: phone
            ? PhoneNumberFormatUtil.formatInternational(phone)
            : null,
          fax: fax ? PhoneNumberFormatUtil.formatInternational(fax) : null,
          expectedVersion: version,
          expectedProfileVersion: profileVersion,
          expectedAddressVersion: addressVersion,
          expectedContactDetailsVersion: contactDetailsVersion,
          email: email ? (email === "" ? null : email) : null,
          ...other,
        },
      })
    ).then((res) => {
      const { phone, fax, ...other } = res.data.updateWorkplace;
      setValue({
        phone: PhoneNumberFormatUtil.formatNational(phone),
        fax: PhoneNumberFormatUtil.formatNational(fax),
        ...other,
      });
    });
  };

  return render({
    submit: executeUpdate,
    value: value,
    client: client,
    loading: removeMutationStatus.loading,
    removeMutation,
    ...props,
  });
};
