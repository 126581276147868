import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
export const ButtonPDF = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[700],
  padding: 0,
  flexDirection: "column",
  "& .label": {
    backgroundColor: theme.palette.grey[700],
    color: theme.palette.background.paper,
    fontSize: "12px",
    width: "100%",
    lineHeight: "30px",
  },
  "& .MuiButton-startIcon": {
    margin: 0,
    "& svg": {
      fontSize: "5rem",
      margin: theme.spacing(2, 0),
    },
  },
}));
export const useStyles = makeStyles((theme) => ({
  files: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: theme.spacing(2),
  },

  loading_progress: {
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  pdf_view_mobile: {
    "& .modal_container": {
      backgroundColor: "transparent",
    },
  },
  mobile_view: { height: " 100%", maxHeight: "500px", overflow: " auto" },
}));
