import { useEffect } from "react";
import useClasses from "utils/useClasses";
import { styles } from "./styles";

/**
 * 配車計画を表示するコンテナコンポーネントです
 * @param {object} value 配車計画情報
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  const classes = useClasses(styles);

  const scrollHorizontal = (event) => {
    if (event.deltaX === -0) {
      event.stopPropagation();
      event.preventDefault();
      // noinspection JSSuspiciousNameCombination
      document?.querySelector(".scrollTarget")?.scrollBy(event.deltaY, 0);
    }
  };

    useEffect(() => {
    document
      ?.querySelector(".horizontalScroll")
      ?.addEventListener("mousewheel", scrollHorizontal);

    return () => {
      document
        ?.querySelector(".horizontalScroll")
        ?.removeEventListener("mousewheel", scrollHorizontal);
    };
  }, [value]);

  return render({
    classes: classes,
    value: value,
    ...props,
  });
};
