export const styles = ((theme) => ({
  box__times: {
    padding: 0,
    "& .MuiListItemText-root ": {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  box__number: {
    color: "#fff",
    height: "20px",
    display: "inline-block",
    minWidth: "20px",
    textAlign: "center",
    lineHeight: "20px",
    borderRadius: "50%",
    fontSize: "14px",
  },
}));
