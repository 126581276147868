/**
 * 到着予定時間の通知を表示するコンテナコンポーネントです。
 * @returns {func} 描画関数
 */
export const Container = ({ render, value, ...props }) => {
  return render({
    value: value,
    ...props,
  });
};
