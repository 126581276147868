import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOpener } from "utils/useOpener";
import { useQuery } from "utils/useQuery";

const searchCarryWorkJournalsByOffsetQuery = /* GraphQL */ `
  query SearchCarryWorkJournalsByOffset(
    $filter: SearchableCarryWorkJournalFilterInput
    $sort: SearchableCarryWorkJournalSortInput
    $offset: Int
    $limit: Int
  ) {
    searchCarryWorkJournalsByOffset(
      filter: $filter
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      items {
        id
        name
        assignedVehicle {
          id
          number
          modelOfVehicleName
        }
        assignedUsers {
          id
          name
        }
        taskTotal
        taskDoneCount
        pointTotal
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;

export const getCarryWorkJournalQuery = /* GraphQL */ `
  query GetCarryWorkJournal($id: ID!) {
    getCarryWorkJournal(id: $id) {
      id
      name
      departureDate
      departureTime
      assignedVehicle {
        id
        number
      }
      instruments {
        type
      }
    }
  }
`;

export const getManagementVehicleQuery = /* GraphQL */ `
  query GetManagementVehicle($id: ID!) {
    getManagementVehicle(id: $id) {
      id
      odometer
    }
  }
`;

/**
 * モバイルの回収一覧を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const [date, setDate] = useState(DateTime.now().toISODate());
  const [showZeroPoints, setShowZeroPoints] = useState(false);
  const odoMeterForm = useOpener();

  const collectList = useQuery({
    query: searchCarryWorkJournalsByOffsetQuery,
    variables: {
      filter: {
        date: {
          eq: date,
        },
        assignedOnly: { eq: true },
      },
    },
    onCompleted: (params) => {},
  });

  const managementVehicle = useQuery({
    query: getManagementVehicleQuery,
    variables: {},
    skip: true,
    onCompleted: () => {
      odoMeterForm?.toggle(true);
    },
  });

  const carryWorkJournal = useQuery({
    query: getCarryWorkJournalQuery,
    variables: {},
    skip: true,
    onCompleted: (data) => {
      if (
        data?.data?.getCarryWorkJournal?.assignedVehicle &&
        !!data?.data?.getCarryWorkJournal?.instruments
      ) {
        managementVehicle.refetch({
          id: data?.data?.getCarryWorkJournal?.assignedVehicle?.id,
        });
      } else {
        navigate(`/mobile-collect/${data?.data?.getCarryWorkJournal?.id}`);
      }
    },
  });

  useEffect(() => {
    setDate(DateTime.now().toISODate());
    return () => {};
  }, []);

  const handleCompletedRecordCarryWorkJournalInstrument = () => {
    navigate(
      `/mobile-collect/${carryWorkJournal?.data?.getCarryWorkJournal?.id}`
    );
  };

  const handleResume = (item) => () => {
    odoMeterForm.changeOptions({
      isDone: item?.taskDoneCount === item?.taskTotal,
    });
    carryWorkJournal.refetch({
      id: item.id,
    });
  };

  const handleBack = () => {
    const result = DateTime.fromISO(date).minus({ days: 1 });
    setShowZeroPoints(false);
    setDate(result.toISODate());
  };

  const handleNext = () => {
    const result = DateTime.fromISO(date).plus({ days: 1 });
    setShowZeroPoints(false);
    setDate(result.toISODate());
  };

  const onCheckBoxChange = () => {
    setShowZeroPoints(!showZeroPoints);
  };

  return render({
    date: date,
    onBack: handleBack,
    onNext: handleNext,
    loading:
      collectList.loading ||
      carryWorkJournal.loading ||
      managementVehicle.loading,
    items: collectList?.data?.searchCarryWorkJournalsByOffset?.items,
    hasBack: true,
    hasNext: true,
    odometer: managementVehicle?.data?.getManagementVehicle?.odometer,
    odoMeterForm: odoMeterForm,
    onResume: handleResume,
    journalId: carryWorkJournal?.data?.getCarryWorkJournal?.id,
    onCompletedRecordCarryWorkJournalInstrument:
      handleCompletedRecordCarryWorkJournalInstrument,
    showZeroPoints,
    onCheckBoxChange,
    ...props,
  });
};
