import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Container, Paper, Stack } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { debugLog } from "utils/log";
import { useOpener } from "utils/useOpener";
import { DatePicker, RadioGroup, TextField } from "views/atoms";
import {
  ClientSelector,
  ClientWorkplaceSelector,
  FullScreenDialog,
  FileGallery,
  InputFieldPopppSelect,
  ModalPopup,
  Uploader,
  ConfirmDialog,
} from "views/molecules";
import { basicSchema } from "./Validation";
import { DateTime } from "luxon";

const defaultValues = {
  inspectionCompany: null,
  inspectionWorkplace: null,
  receivedOn: null,
  completedOn: null,
  result: "Pass",
  expiredOn: null,
  price: null,
  remarks: null,
  files: [],
  ignoreSynchronizeCurrentExpiryDate: false,
};

/**
 * 車検記録を登録するフォームコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} value 値
 * @param {boolean} open 開閉状態
 * @fires VehicleInspectionRecordForm#onClose 閉じる時
 * @param {boolean} disabled 無効設定
 * @fires VehicleInspectionRecordForm#onSubmit 送信時
 * @param {string} title タイトル
 * @param {string} confirmText 確定ボタンテキスト
 * @returns {JSX.Element}
 */
export const VehicleInspectionRecordForm = ({
  open,
  onClose,
  value,
  disabled = false,
  onSubmit = (res) => debugLog(res),
  onError = (err) => debugLog(err),
  title = "新しい車検記録の登録",
  confirmText = "登録",
  currentExpiryDate = null,
}) => {
  const crExpiryDate = currentExpiryDate
    ? DateTime.fromISO(currentExpiryDate)
    : null;
  const trusteeCompany = useOpener();
  const location = useOpener();
  const comfirmDialog = useOpener();
  const { trigger, reset, getValues, formState, control, watch, setValue } =
    useForm({
      mode: "onBlur",
      resolver: yupResolver(basicSchema),
      defaultValues: {
        ...defaultValues,
        ...value,
      },
    });

  const expiredOn = watch("expiredOn");

  useEffect(() => {
    if (open) {
      reset({
        ...defaultValues,
        ...value,
      });
    }
  }, [reset, value, open]);

  const handlePositive = () => {
    onSubmit({
      ...getValues(),
      ignoreSynchronizeCurrentExpiryDate: false,
    });

    comfirmDialog.toggle(true);
  };

  const handleNegative = () => {
    onSubmit({
      ...getValues(),
      ignoreSynchronizeCurrentExpiryDate: true,
    });

    comfirmDialog.toggle(true);
  };

  const onConfirm = async () => {
    const result = await trigger();

    if (result) {
      const values = getValues();

      if (!!currentExpiryDate) {
        if (crExpiryDate > DateTime.fromJSDate(expiredOn)) {
          comfirmDialog.toggle(true);
        } else {
          onSubmit(values);
        }
      } else {
        onSubmit(values);
      }
    } else {
      onError(formState.errors);
    }
  };

  return (
    <>
      <FullScreenDialog
        open={open}
        onClose={onClose}
        title={title}
        textConfirm={confirmText}
        onClickSubmit={onConfirm}
        isSubmit={disabled}
        disabled={disabled}
      >
        <Container>
          <Paper
            sx={{
              p: 3,
            }}
          >
            <Stack spacing={2}>
              <Box>
                <Controller
                  name={`inspectionCompany`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <InputFieldPopppSelect
                          label={"受託会社"}
                          value={value}
                          required={true}
                          placeholder={"受託会社を選択します。"}
                          error={error}
                          onOpenModal={() => trusteeCompany.toggle(true)}
                          textConfirm="選択"
                          disableButton={disabled}
                        />
                        <ModalPopup
                          open={trusteeCompany.open}
                          onClose={() => trusteeCompany.toggle(false)}
                        >
                          <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                            <ClientSelector
                              value={value}
                              onSelected={(data) => {
                                trusteeCompany.toggle(false);
                                onChange(data[0]);
                                setValue("inspectionWorkplace", null);
                              }}
                              add={false}
                              selectedOwn={true}
                            />
                          </Box>
                        </ModalPopup>
                      </>
                    );
                  }}
                />
              </Box>
              <Box>
                <Controller
                  name={`inspectionWorkplace`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <InputFieldPopppSelect
                          label={"実施場所"}
                          value={value}
                          required={true}
                          placeholder={"実施場所を選択します。"}
                          error={error}
                          onOpenModal={() => location.toggle(true)}
                          textConfirm="選択"
                          disabledButtom={
                            disabled || !watch("inspectionCompany")
                          }
                        />
                        <ModalPopup
                          open={location.open}
                          onClose={() => location.toggle(false)}
                        >
                          <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                            <ClientWorkplaceSelector
                              visibleCompanyName={false}
                              value={value}
                              onSelected={(items) => {
                                onChange(items?.[0]);
                                location.toggle(false);
                              }}
                              fixedOptions={{
                                filter: {
                                  and: [
                                    {
                                      belongInCompanyId: {
                                        eq: watch("inspectionCompany")
                                          ?.companyId,
                                      },
                                    },
                                  ],
                                },
                              }}
                            />
                          </Box>
                        </ModalPopup>
                      </>
                    );
                  }}
                />
              </Box>
              <Box>
                <Controller
                  name={`receivedOn`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <DatePicker
                      label="車輛引渡し日"
                      required={true}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message}
                      disabled={disabled}
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name={`completedOn`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <DatePicker
                      label="車検完了日"
                      required={true}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message}
                      disabled={disabled}
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  control={control}
                  name="result"
                  render={({ field: { value, onChange } }) => (
                    <RadioGroup
                      label="車検条件"
                      required={true}
                      value={value}
                      onChange={(event) => {
                        const newValue = event.target.value;
                        onChange(event);
                        if (newValue === "Fail") {
                          setValue("expiredOn", null);
                        }
                      }}
                      row={true}
                      options={[
                        { value: "Pass", label: "合格" },
                        { value: "Fail", label: "不合格" },
                      ]}
                    />
                  )}
                />
              </Box>
              {watch("result") === "Pass" && (
                <Box>
                  <Controller
                    name={`expiredOn`}
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        label="車検満了日"
                        required={true}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                        disabled={disabled}
                      />
                    )}
                  />
                </Box>
              )}
              <Box>
                <Controller
                  name={`price`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="車検代金"
                      required={true}
                      placeholder="例) 11900"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message}
                      disabled={disabled}
                      InputProps={{
                        endAdornment: "円",
                      }}
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name={`remarks`}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="備考"
                      required={false}
                      placeholder="特記事項があれば入力します。"
                      value={value}
                      onChange={onChange}
                      error={error}
                      rows={3}
                      multiline={true}
                      disabled={disabled}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="files"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <Uploader
                        disabled={disabled}
                        fullWidth={true}
                        maxSize={26214400}
                        onChange={(files) => {
                          onChange([...(value ?? []), ...files]);
                        }}
                        dropzoneText="最大ファイルサイズ:25MB、ファイル形式:JPEG、PDF"
                        accept={[".pdf", ".jpg", ".jpeg"]}
                      />
                      <FileGallery
                        disabled={disabled}
                        value={value}
                        onChange={onChange}
                      />
                    </>
                  )}
                />
              </Box>
            </Stack>
          </Paper>
        </Container>
        <ConfirmDialog
          title="更新確認"
          message={`現在の車検満了日を${crExpiryDate?.toFormat(
            "yyyy/MM/dd"
          )}から${DateTime.fromJSDate(expiredOn)?.toFormat(
            "yyyy/MM/dd"
          )}に変更しますか？`}
          onPositive={() => handlePositive()}
          onNegative={() => handleNegative()}
          open={comfirmDialog.open}
          onClose={() => comfirmDialog.toggle(false)}
          positiveText={"更新します"}
          negativeText={"更新しません"}
        />
      </FullScreenDialog>
    </>
  );
};
