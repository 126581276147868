import { Box, Button, Popover, TextField } from "@mui/material";
import React from "react";

/**
 * 備考を編集するテキストの表示を行うプレゼンテーションコンポーネントです。
 * @param {JSX} handleRef ref
 * @param {string} id 備考のId
 * @param {Boolean} open テキストポップアップをオーブンする
 * @param {JSX} anchorEl JSX　Element
 * @param {func} handleClose テキストを閉じるイベント
 * @param {string} value 備考の値
 * @param {func} handleChange 値を更新するイベント
 * @param {func} handleSubmit Okボタンを押すイベント
 * @returns {JSX.Element} JSX 要素
 */
export const Presententer = (props) => {
  return (
    <Box ref={props.handleRef}>
      <Popover
        id={props.id}
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box sx={{ minWidth: 300, padding: (theme) => theme.spacing(2) }}>
          <TextField
            id="multiline-remarks"
            multiline
            autoFocus
            label="備考"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={props.value}
            onChange={props.handleChange}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", pt: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={props.handleClose}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={props.handleSubmit}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
