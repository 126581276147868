import { Box, Button, Radio, Typography } from "@mui/material";
import { InformationItem } from "views/atoms";
import { FlowDetails } from "../FlowDetails";
import { ModalPopup } from "views/molecules";

/**
 * 処理フローの選択を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box
      p={2}
      ml={2}
      mr={2}
      mb={2}
      sx={{
        border: 1,
        borderColor: (theme) => theme.palette.grey[400],
      }}
    >
      <Typography sx={{ marginLeft: "-9px" }}>
        {!props.display && (
          <Radio
            checked={props.value?.wasteFlowId === props.option?.wasteFlowId}
            onChange={props.onChange}
            value={props?.option?.wasteFlowId}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 28,
              },
            }}
          />
        )}
        {props?.option?.targetWasteType?.smallClass?.name ||
          props?.option?.targetWasteType?.middleClass?.name ||
          props?.option?.targetWasteType?.largeClass?.name ||
          ""}
      </Typography>
      <InformationItem label="処分事業場">
        {`${props?.option?.disposalProcess?.disposalCompany?.name} ${props?.option?.disposalProcess?.disposalWorkplace?.name}`}
      </InformationItem>
      <InformationItem label="処分方法">
        {`${
          props?.option?.disposalProcess?.disposalMethod?.smallClassName ||
          props?.option?.disposalProcess?.disposalMethod?.middleClassName ||
          props?.option?.disposalProcess?.disposalMethod?.largeClassName
        } (${props?.option?.disposalProcess?.disposalMethod?.code})`}
      </InformationItem>
      <Button fullWidth variant="outlined" onClick={props.onOpen}>
        詳細を表示する
      </Button>
      <ModalPopup open={props.open} onClose={props.onClose}>
        <Box
          sx={{ bgcolor: "#fff", p: 3, maxHeight: "450px", overflow: "auto" }}
        >
          <FlowDetails value={props.option} />
        </Box>
      </ModalPopup>
    </Box>
  );
};
