import reducer from "./slice";
import {
  selector,
  workplaceCategoriesSelector,
  isLoadingSelector,
  errorSelector,
} from "./slice";
import { fetchWorkplaceCategories } from "./api";

export default reducer;
export {
  fetchWorkplaceCategories,
  selector,
  workplaceCategoriesSelector,
  isLoadingSelector,
  errorSelector,
};
