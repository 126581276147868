import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

/**
 * 同意ダイアログを表示するコンポーネント。
 * @param {object} props プロパティ
 * @param {boolean} props.open 開閉状態
 * @param {string} props.title タイトル
 * @param {string} props.description 説明
 * @param {JSX.Element} props.children 子要素
 * @param {boolean} props.disabled 無効設定
 * @param {string} props.positiveText 肯定的テキスト
 * @param {string} props.negativeText 否定的テキスト
 * @fires Dialog#onClose 閉じる時
 * @fires Dialog#onAgree 同意した時
 * @returns {JSX.Element}
 */
export const Dialog = ({
  open = true,
  onClose = () => {},
  onAgree = () => {},
  title = "",
  description = "",
  children = <></>,
  disabled = false,
  positiveText = "",
  negativeText = "",
}) => {
  return (
    <MuiDialog
      open={open}
      onClose={disabled === true ? null : onClose}
      PaperProps={{
        sx: {
          width: "fit-content",
          maxWidth: "95vw"
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-around",
        }}
      >
        <Button disabled={disabled} color="error" onClick={onClose}>
          {negativeText}
        </Button>
        <Button disabled={disabled} onClick={onAgree}>
          {positiveText}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};
