import { useWindowHeight } from "@react-hook/window-size";
import { useState, useEffect } from "react";
import { debugLog } from "utils/log";
import { useStyles } from "./styles";

/**
 * 処理フローを選択するコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 選択値
 * @param {boolean} open 開閉状態
 * @fires Container#onClose 閉じるとき
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  open = false,
  onClose = () => debugLog("閉じられた"),
  onChange = (data) => debugLog("フロー選択: ", data),
  options,
  wasteType,
  ...props
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(null);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const screenHeight = useWindowHeight();

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleConfirm = () => {
    onChange(selected);
    onClose();
  };

  const handleClickDetails = (data) => {
    setOpenDetails(true);
    setSelectedDetails(data);
  };

  const handleChangeFlow = (data) => {
    setSelected(data);
  };

  return render({
    classes: classes,
    onConfirm: handleConfirm,
    open: open,
    onClose: onClose,
    openDetails: openDetails,
    onOpenDetails: () => setOpenDetails(true),
    onCloseDetails: () => setOpenDetails(false),
    screenHeight: screenHeight,
    options: options,
    selected: selected,
    onChangeFlow: handleChangeFlow,
    onClickDetails: handleClickDetails,
    onChange: onChange,
    value: value,
    selectedDetails: selectedDetails,
    wasteType: wasteType,
    ...props,
  });
};
