import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { ConfirmDialog, FullScreenDialog } from "views/molecules";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { EditForm } from "views/organisms/Masters/ContainerDetails";
import {
  Basic,
  MovementHistory,
  SetupLocation,
  StopUsingForm,
} from "views/organisms/Masters/ContainerDetails";
import { If } from "views/atoms";

const StopUsingFormfR = React.forwardRef((props, ref) => (
  <StopUsingForm {...props} _ref={ref} />
));

const EditFormfR = React.forwardRef((props, ref) => (
  <EditForm {...props} _ref={ref} />
));

/**
 * コンテンツ種類詳細を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 */
export const Presententer = (props) => {
  const isActive = ({ state = null } = {}) => {
    return state === "active";
  };

  const getDaysElapsed = (installedOn) => {
    if (!installedOn) {
      return "";
    }

    return `経過日数：${Math.floor(
      -1 * DateTime.fromISO(installedOn).diffNow("days").days
    )}日`;
  };

  return (
    <>
      <DetailsFrame
        caption={
          <Box display="flex" flexDirection={"row"}>
            <Box>
              <Avatar
                sx={{
                  marginRight: 3,
                  width: 58,
                  height: 58,
                }}
              >
                <InsertPhotoIcon sx={{ fontSize: "2.5rem" }} />
              </Avatar>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "bold",
                }}
              >
                {props.value?.number ?? ""}
              </Typography>
              <Box display="flex" flexDirection={"row"} alignItems="center">
                <Typography
                  sx={{
                    marginRight: 2,
                    color: "gray",
                  }}
                >
                  {props.value?.product?.name ?? ""}
                </Typography>
                {isActive(props.value) && (
                  <Chip
                    avatar={<AccessTimeIcon />}
                    label={getDaysElapsed(props.value?.installedOn)}
                  />
                )}
              </Box>
            </Box>
          </Box>
        }
        actions={
          <If condition={props.value?.state !== "removed"}>
            <If condition={props.value?.state === "active"}>
              <Button
                variant="contained"
                color="error"
                onClick={() => props.stopDialog.toggle(true)}
                disabled={props.isSubmit}
              >
                {"コンテナの使用を停止する"}
              </Button>
            </If>
            <If condition={props.value?.state === "disused"}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => props.removeDialog.toggle(true)}
                  disabled={props.isSubmit}
                >
                  {"コンテナの使用を削除する"}
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => props.resumeConfirmDialog.toggle(true)}
                  disabled={props.isSubmit}
                >
                  {"コンテナの使用を再開する"}
                </Button>
              </Stack>
            </If>
          </If>
        }
      >
        <Stack spacing={2}>
          <Basic
            value={{ ...props.value, files: props.formValue?.files }}
            onClick={() => props.editDialog.toggle(true)}
          />
          <SetupLocation
            value={props.value}
            onClick={() => props.historyDialog.toggle(true)}
          />
          {/* {(!isActive(props)) && <Disuse value={props.value} />} */}
        </Stack>
      </DetailsFrame>
      <FullScreenDialog
        open={props.stopDialog.open}
        title="コンテナの使用を停止する"
        textConfirm="登録"
        onClickSubmit={() => {
          props.stopUsingFormRef.current.submit();
        }}
        onClose={() => props.stopDialog.toggle(false)}
      >
        <StopUsingFormfR
          loading={props.isSubmit}
          onSubmit={() => props.stopConfirmDialog.toggle(true)}
          ref={props.stopUsingFormRef}
        />
        <ConfirmDialog
          title="使用を停止します。よろしいですか？"
          open={props.stopConfirmDialog.open}
          positiveText={"停止"}
          negativeText={"キャンセル"}
          onPositive={props.handleStopUsing}
          onNegative={() => props.stopConfirmDialog.toggle(false)}
          onClose={() => props.stopConfirmDialog.toggle(false)}
          disabled={props.isSubmit}
        />
      </FullScreenDialog>
      <FullScreenDialog
        open={props.editDialog.open}
        title="基本情報の編集"
        textConfirm="保存"
        onClose={() => props.editDialog.toggle(false)}
        isSubmit={props.isSubmit}
        disabled={props.isSubmit}
        onClickSubmit={() => {
          props.editFormRef.current.submit();
        }}
      >
        <EditFormfR
          value={props.value}
          onSubmit={props.handleUpdate}
          ref={props.editFormRef}
          disabled={props.isSubmit}
        />
      </FullScreenDialog>
      <MovementHistory
        containerId={props.value?.id}
        open={props.historyDialog.open}
        onClose={() => props.historyDialog.toggle(false)}
      />
      <ConfirmDialog
        title="再開します。よろしいですか？"
        open={props.resumeConfirmDialog.open}
        positiveText={"再開"}
        negativeText={"キャンセル"}
        onPositive={props.handleResumeUse}
        onNegative={() => props.resumeConfirmDialog.toggle(false)}
        onClose={() => props.resumeConfirmDialog.toggle(false)}
        disabled={props.isSubmit}
      />
      <ConfirmDialog
        title="削除します。よろしいですか？"
        open={props.removeDialog.open}
        positiveText={"削除"}
        negativeText={"キャンセル"}
        onPositive={props.onRemove}
        onNegative={() => props.removeDialog.toggle(false)}
        onClose={() => props.removeDialog.toggle(false)}
        disabled={props.isSubmit}
      />
    </>
  );
};

export default Presententer;
