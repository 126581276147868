export const useStyles = (theme) => ({
  box_menu_profile: {
    marginLeft: theme.spacing(4),
    "& .MuiTypography-root ": {
      marginLeft: theme.spacing(2),
    },
  },
  avatar: {
    "& .avatar_text": {
      fontSize: "13px",
      backgroundColor: theme.palette.icon.toolbarUser,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 !important",
      minWidth: "auto !important",
    },
    "& p": {
      color: theme.palette.grey[800],
    },
  },
});
