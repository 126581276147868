import { useXGridComponents } from "utils/useXGridComponents";
import { searchClientsByOffset } from "api/graphql/queries";
import { useSelector } from "react-redux";
import { companySelector } from "ducks/Company";
import { debugLog } from "utils/log";

const columns = [
  {
    field: "name",
    headerName: "名称",
    width: 350,
  },
  {
    field: "headOfficeWorkplacePostalCode",
    headerName: "本社郵便番号",
    valueGetter: (params) => params.row.headOfficeWorkplace?.postalCode,
    width: 100,
  },
  {
    field: "headOfficeWorkplacePrefecturesName",
    headerName: "本社都道府県",
    valueGetter: (params) => params.row.headOfficeWorkplace?.prefectures?.name,
    width: 100,
  },
  {
    field: "headOfficeWorkplaceCity",
    headerName: "本社市区町村",
    valueGetter: (params) => params.row.headOfficeWorkplace?.city,
    width: 130,
  },
  {
    field: "headOfficeWorkplaceStreetAddress",
    headerName: "本社町名",
    valueGetter: (params) => params.row.headOfficeWorkplace?.streetAddress,
    width: 100,
  },
  {
    field: "headOfficeWorkplaceOtherAddress",
    headerName: "本社町名以降",
    valueGetter: (params) => params.row.headOfficeWorkplace?.otherAddress,
    width: 200,
  },
];

/**
 * 契約者を選択するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {} value 値
 * @fires Container#fires 選択されたとき
 * @param {boolean} multiple 複数選択可能
 * @param {boolean} download ダウンロード可能
 * @param {string} title タイトル
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onSelected = (data) => debugLog(data),
  multiple = false,
  download = false,
  title = "契約者の選択",
  autoHeight = false,
  ...props
}) => {
  // todo: 契約者取得api待ち
  //  getCompanyとsearchClientsByOffsetを合わせたようなもの
  const company = useSelector(companySelector);
  const xGridObject = useXGridComponents(
    columns,
    searchClientsByOffset,
    {
      other: {
        supplierCompanyId: company.id,
      },
    },
    {
      sort: {
        direction: "desc",
        field: "createdAt",
      },
    }
  );

  const handleSelected = (params) => {
    const result = xGridObject.params.rows.filter((row) =>
      params.includes(row.id)
    );
    onSelected(multiple ? result : result?.[0]);
  };

  const onSelectedOwnCompany = () => {
    // todo: 仮作成の処理
    onSelected(company);
  };

  return render({
    value: value,
    multiple: multiple,
    download: download,
    title: title,
    onSelected: handleSelected,
    // todo: 仮作成の処理
    onSelectedOwnCompany: onSelectedOwnCompany,
    xGridParams: xGridObject.params,
    autoHeight: autoHeight,
    ...props,
  });
};
