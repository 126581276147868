import { useMemo } from "react";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Box,
  Checkbox,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { OdometerRecordForm } from "features/Vehicles/Instruments/OdometerRecordForm";
import { DateTime } from "luxon";
import { Item } from "./Item";
import { If } from "views/atoms";

/**
 * モバイルの回収一覧を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.date 日付
 * @param {array} props.items 項目
 * @fires List#onBack 前の日付へ
 * @fires List#onNext 次の日付へ
 * @param {boolean} props.hasBack 戻れるか
 * @param {boolean} props.hasNext 次はあるか
 * @param {boolean} props.loading 読み込み中であるか
 * @returns {JSX.Element}
 */
export const List = ({
  date = DateTime.now().toISODate(),
  items = null,
  onBack = () => {},
  onNext = () => {},
  hasBack = false,
  hasNext = false,
  loading = false,
  odometer = 0,
  odoMeterForm = null,
  onResume,
  journalId,
  onCompletedRecordCarryWorkJournalInstrument,
  showZeroPoints,
  onCheckBoxChange = () => {},
}) => {
  const formatDisplay = (value) => {
    if (!value) {
      return "";
    }

    return DateTime.fromISO(date).toLocaleString(
      DateTime.DATE_MED_WITH_WEEKDAY
    );
  };

  const filteredItems = useMemo(() => {
    if (showZeroPoints) {
      return items;
    }
    return items?.filter((item) => item?.pointTotal > 0);
  }, [items, showZeroPoints]);

  return (
    <>
      <Stack
        spacing={2}
        sx={{
          p: 2,
          "@media print": {
            width: 375,
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <IconButton onClick={onBack} disabled={!hasBack}>
            <ChevronLeftOutlinedIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
            }}
          >
            {formatDisplay(date)}
          </Typography>
          <IconButton onClick={onNext} disabled={!hasNext}>
            <ChevronRightOutlinedIcon />
          </IconButton>
        </Stack>
        <If condition={!loading && items?.length > 0}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop:"0px !important"
            }}
          >
            <Checkbox
              size="small"
              checked={showZeroPoints}
              onChange={onCheckBoxChange}
            />
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                fontWeight: 500,
                fontSize: "1em",
              }}
            >
              0 件のリストも表示する。
            </Typography>
          </Box>
          <Stack spacing={2}>
            {filteredItems?.map((item, index) => (
              <Item key={index} value={item} onResume={onResume(item)} />
            ))}
          </Stack>
        </If>
        <If condition={!loading && items !== null && items.length <= 0}>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "58vh",
            }}
          >
            <WarningIcon color="warning" sx={{ fontSize: 100 }} />
            <Typography
              variant="h6"
              component="div"
              sx={{ color: "warning.main" }}
              align="center"
            >
              割り当てられたルートがありません。
            </Typography>
          </Box>
        </If>
        <If condition={loading === true}>
          <Skeleton variant="rectangular" height={200} />
          <Skeleton variant="rectangular" height={200} />
          <Skeleton variant="rectangular" height={200} />
        </If>
      </Stack>
      <OdometerRecordForm
        journalId={journalId}
        type={odoMeterForm.options?.isDone === true ? "Start" : "Resume"}
        value={odometer}
        open={odoMeterForm?.open}
        onClose={() => odoMeterForm?.toggle(false)}
        onCompleted={onCompletedRecordCarryWorkJournalInstrument}
        onSkiped={onCompletedRecordCarryWorkJournalInstrument}
      />
    </>
  );
};
