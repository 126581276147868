import {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  useMemo,
} from "react";
import { debugLog } from "utils/log";

/**
 * 取引先事業場廃棄物情報を新規登録するコンテナコンポーネントです。
 * @callback render
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  managedCompanyId,
  onSubmit = (data) => debugLog("取引先事業場廃棄物新規登録.成功: ", data),
  onError = (err) => debugLog("取引先事業場廃棄物新規登録.失敗: ", err),
  ...props
}) => {
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const [wasteType, setWasteType] = useState(null);
  const formRef = useRef(null);
  const obj = useMemo(
    () => {
      return !!wasteType?.id
      ? ({and: [{ targetWasteTypeId: { eq: wasteType?.id } }] })
      : undefined;
    },
    [wasteType]
  );

  useEffect(() => {
    setValue(null);
  }, []);

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      setError(null);
      formRef.current.submit();
    },
  }));

  const handleSubmit = (data) => {
    if ((value?.length ?? 0) > 0) {
      onSubmit({
        ...data,
        flows: value,
      });
    } else {
      setError({ message: "処理フローを選択してください。" });
      onError("処理フローが選択されてない。");
    }
  };

  const handleError = (err) => {
    onError(err);
  };

  const handleWasteFlowSelected = (data) => {
    setError(null);
    setValue(data);
  };

  const handleNotificationChanged = (data) => {
    setWasteType(data?.type);
  };

  return render({
    ...props,
    value: value,
    error: error,
    formRef: formRef,
    wasteType: wasteType,
    onWasteFlowSelected: handleWasteFlowSelected,
    onSubmit: handleSubmit,
    onError: handleError,
    managedCompanyId: managedCompanyId,
    onNotificationChanged: handleNotificationChanged,
    fixedFilter: obj,
  });
};
