import { v4 as uuid } from "uuid";
import * as _ from "lodash";
import { debugLog } from "utils/log";

/**
 * The `toDate` function converts a string in the format "hours:minutes:seconds" to a JavaScript `Date`
 * object.
 * @param value - The `value` parameter is a string representing a time in the format "HH:MM:SS", where
 * HH represents hours, MM represents minutes, and SS represents seconds.
 * @returns a Date object.
 */
export const toDate = (value) => {
  if (!value) {
    return value;
  }

  let result = new Date();
  let [hours, minutes, seconds] = value.split(":");

  result.setHours(+hours);
  result.setMinutes(minutes);
  result.setSeconds(seconds);

  return result;
};

/**
 * The `ordersByTimeRange` function takes an input value and index, sorts the assigned waste collection
 * schedules based on start time and duration, and returns the updated value.
 * @param value - The `value` parameter is an object that contains the data for the orders. It has a
 * property called `courses`, which is an array of courses. Each course has a property called `points`,
 * which is an array of points. Each point has a property called `assignedWasteCollectionSchedules
 * @param index - The `index` parameter in the `ordersByTimeRange` function represents the index of the
 * course within the `courses` array in the `value` object. It is used to access the specific course
 * and perform operations on it.
 * @returns The function `ordersByTimeRange` returns the modified `value` object with the
 * `courses[index].points` array sorted based on the assigned waste collection schedules' time range.
 */
export const ordersByTimeRange = (value, index) => {
  let result = _.cloneDeep(value);
  const course = result.courses[index];
  const schedules = course.points
    ?.flatMap((point) => point.assignedWasteCollectionSchedules)
    .sort((a, b) => {
      // 重み
      let weight = 0;

      const aStartDate = toDate(a.scheduleTimeRangeStart);
      const aEndDate = toDate(a.scheduleTimeRangeEnd);
      const bStartDate = toDate(b.scheduleTimeRangeStart);
      const bEndDate = toDate(a.scheduleTimeRangeEnd);

      // 到着時間
      if (aStartDate.getTime() < bStartDate.getTime()) {
        weight -= 10;
      } else if (aStartDate.getTime() > bStartDate.getTime()) {
        weight += 10;
      }

      // 作業時間
      if (
        aEndDate.getTime() - aStartDate.getTime() <
        bEndDate.getTime() - bStartDate.getTime()
      ) {
        weight -= 1;
      } else if (
        aEndDate.getTime() - aStartDate.getTime() >
        bEndDate.getTime() - bStartDate.getTime()
      ) {
        weight += 1;
      }

      return weight;
    });

  let orderedPoints = [];
  let before = null;
  let pointIndex = 0;
  for (let i = 0; i < schedules.length; i++) {
    let target = schedules[i];
    if (before === null) {
      orderedPoints.push({
        id: uuid(),
        assignedWasteCollectionSchedules: [target],
        workplace: target.wasteCollectionWorkplace,
      });

      before = target;
      continue;
    }

    if (
      before.wasteCollectionWorkplace.id === target.wasteCollectionWorkplace.id
    ) {
      orderedPoints[pointIndex].assignedWasteCollectionSchedules.push(target);
      before = target;
      continue;
    }

    if (
      before.wasteCollectionWorkplace.id !== target.wasteCollectionWorkplace.id
    ) {
      orderedPoints.push({
        id: uuid(),
        assignedWasteCollectionSchedules: [target],
        workplace: target.wasteCollectionWorkplace,
      });

      before = target;
      pointIndex++;
      continue;
    }
  }

  course.points = orderedPoints;

  debugLog("時間ソート結果:", result);
  return result;
};

/**
 * The function `moveToUnassign` removes a course from a given value object and adds its assigned waste
 * collection schedules to the `still` property of the object.
 * @param value - The `value` parameter is an object that contains the data structure you want to
 * modify. It likely has properties such as `done` and `still`, which contain arrays of courses and
 * schedules respectively.
 * @param index - The `index` parameter represents the index of the course in the `courses` array that
 * needs to be moved to the `still` object.
 * @returns The function `moveToUnassign` returns an object with the following properties:
 */
export const moveToUnassign = (value, index) => {
  let clonedValue = _.cloneDeep(value);
  const course = clonedValue.done.courses[index];
  const schedules = course.points.flatMap(
    (point) => point.assignedWasteCollectionSchedules
  );
  let result = {
    ...clonedValue,
    done: {
      ...clonedValue.done,
      courses: clonedValue.done.courses.map((v, i) => {
        if (i === index) {
          return {
            ...v,
            points: [],
          };
        }
        return v;
      }),
    },
    still: [...clonedValue.still, ...schedules],
  };

  debugLog("時間ソート結果:", result);
  return result;
};
