import { requiredField } from "utils/styles";

export const useStyles = (theme) => ({
  //...style_inputField(theme),
  label: {
    paddingBottom: "5px",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  required: {
    ...requiredField(theme),
  },
  error_message: {
    fontSize: "0.7rem",
    whiteSpace: "nowrap",
  },
});
