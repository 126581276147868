import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { Box, Button, Stack } from "@mui/material";
import { XGridComponent } from "views/molecules";
import { FullScreenDialog } from "views/molecules";

/**
 * 車輛管理一覧を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Stack spacing={2}>
      {props.remindListParams.rows.length > 0 && (
        <Box
          display="flex"
          flexDirection={"row"}
          justifyContent="space-between"
          backgroundColor="#ffac33"
          color="#fff"
          p={1}
          borderRadius={1}
        >
          <Box display="flex" flexDirection={"row"} alignItems="center">
            <NotificationsNoneOutlinedIcon />
            {"リマインド通知が発生してる車輛があります。"}
          </Box>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => props.remindList.toggle(true)}
          >
            {"確認"}
          </Button>
        </Box>
      )}
      <XGridComponent
        onChangeSelection={props.onSelected}
        checkboxSelection={false}
        multipleSelection={false}
        onDownload={true}
        {...props.xGridParams}
      />
      <FullScreenDialog
        open={props.remindList.open}
        onClose={() => props.remindList.toggle(false)}
        title={`車輛管理リマインダー`}
        visibilityConfirm={false}
      >
        <XGridComponent
          onChangeSelection={props.onRemindListItem}
          checkboxSelection={false}
          multipleSelection={false}
          onDownload={true}
          {...props.remindListParams}
          rows={props.remindListParams.rows.map((remind) => ({
            id: remind.vehicleRemindId,
            ...remind,
          }))}
        />
      </FullScreenDialog>
    </Stack>
  );
};
