import { useEffect, useState } from "react";
import { debugLog } from "utils/log";
import { styles } from "./styles";
import useClasses from "utils/useClasses";

/**
 * コース名を表示・入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string} value コース名
 * @fires Container#onChange 変更時
 * @param {string} props その他プロパティ
 * @returns {func}
 */
export const Container = ({
  render,
  value = "新しい配車リスト",
  onChange = (data) => debugLog(data),
  courseRef,
  ...props
}) => {
  const classes = useClasses(styles);
  const [name, setName] = useState(value);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setName(value);
  }, [value]);

  const handleEdit = () => {
    setEdit(!edit);
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
    courseRef.current = e.target.value;
  };

  const handleMouseDown = (e) => {
    onChange(name);
    courseRef.current = null
    setEdit(false);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClickAway = (e) => {
    onChange(name);
    courseRef.current = null
    setEdit(false);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === "Escape") {
      onChange(name);
      courseRef.current = null
      setEdit(false);
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return render({
    classes: classes,
    onChangeName: handleChangeName,
    handleMouseDown: handleMouseDown,
    handleClickAway: handleClickAway,
    name: name,
    handleKeyDown: handleKeyDown,
    onEdit: handleEdit,
    edit: edit,
    ...props,
  });
};
