import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * コンテナを選択するコンポーネントです。
 * @param {object} value 選択値
 * @fires ContainerSelector#onSelected 選択時
 * @param {boolean} multiline 複数選択設定
 * @param {boolean} download ダウンロード可能性設定
 * @param {string} title タイトル
 * @param {object} options 検索オプション
 */
export const ContainerSelector = containerPresententer(Container, Presententer);
