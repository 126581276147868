import { makeStyles } from "@mui/styles";
import { overideStyleScrollbarHorizontal } from "utils/styles";

export const useStyles = makeStyles((theme) => ({
  allocation_schedule: {
    position: "relative",
    "& .override_srcoll": {
      width: `100% !important`,
      position: `absolute !important`,
      ...overideStyleScrollbarHorizontal(theme),
    },
  },
}));
