import { SpeakerNotesOutlined, TimelineOutlined } from "@mui/icons-material";
import { FullScreenDialogMobile, TabsMobile } from "views/molecules";
import { Basic, Flow } from "./Child";

/**
 * 処理フローの詳細を表示を行うコンテナプレゼンテーションです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <FullScreenDialogMobile
      open={props.open}
      onClose={props.onClose}
      showAppbar={true}
      avatarLabel={`${
        props.value?.waste?.name ||
        props.value?.waste?.type?.smallClass?.name ||
        props.value?.waste?.type?.middleClass?.name ||
        props.value?.waste?.type?.largeClass?.name ||
        ""
      }`}
      title={`${
        props.value?.waste?.name ||
        props.value?.waste?.type?.smallClass?.name ||
        props.value?.waste?.type?.middleClass?.name ||
        props.value?.waste?.type?.largeClass?.name ||
        ""
      }`}
    >
      <TabsMobile
        tabs={[
          {
            name: "基本情報",
            icon: <SpeakerNotesOutlined fontSize="small" />,
            tabContent: <Basic value={props.value} />,
          },
          {
            name: "処理フロー",
            icon: <TimelineOutlined fontSize="small" />,
            tabContent: <Flow value={props.value} />,
          },
        ]}
      />
    </FullScreenDialogMobile>
  );
};
