/**
 * object shape
 *   value:[number]
 *   unit: [{ code, name }]
 */

const Units = [
  {
    code: 1,
    name: "t",
  },
  {
    code: 2,
    name: "㎥",
  },
  {
    code: 3,
    name: "kg",
  },
  {
    code: 4,
    name: "リットル",
  },
  {
    code: 5,
    name: "個・台",
  },
];

/**
 * 数量を入力するコンテナコンポーネントです
 * @param {string} label ラベル
 * @param {object} value 数量オブジェクト(数量、単位)
 * @fires Container#onChange 変更時
 * @param {boolean} error エラー
 * @param {string} helperText 補助的テキスト
 * @param {array} units 単位オブジェクト配列([{ code, name }])
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  label,
  value,
  onChange,
  error,
  helperText,
  units = Units,
  disabledValue = false,
  disabledUnit = false,
  ...props
}) => {
  const handleChangeQuantity = (e) => {
    onChange({
      ...value,
      value: e.target.value,
    });
  };

  const handleChangeUnit = (e) => {
    onChange({
      ...value,
      unit: Units.find((unit) => unit.code === e.target.value),
    });
  };

  return render({
    label: label,
    value: value,
    Units: units,
    onChangeQuantity: handleChangeQuantity,
    onChangeUnit: handleChangeUnit,
    error: error,
    helperText: helperText,
    disabledValue: disabledValue,
    disabledUnit: disabledUnit,
    ...props,
  });
};
