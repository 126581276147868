const LESS_THAN = -1;
const EQUAL_TO = 0;
const GREATER_THAN = 1;

/**
 * versionを比較します。
 * @param {string | number} a 対象
 * @param {string | number} b 対象
 * @returns {number} aの方が大きい時 1
 * @returns {number} 同じ時 0
 * @returns {number} aの方が小さい時 -1
 */
export const versionCompare = (a, b) => {
  const aVersions = String(a.trim()).split(".");
  const bVersions = String(b.trim()).split(".");
  const length = Math.min(aVersions.length, bVersions.length);

  for (let depth = 0; depth < length; depth++) {
    const aNumber = Number(aVersions[depth]);
    const bNumber = Number(bVersions[depth]);
    if (aNumber > bNumber) {
      return GREATER_THAN;
    }

    if (bNumber > aNumber) {
      return LESS_THAN;
    }

    if (!isNaN(aNumber) && isNaN(bNumber)) {
      return GREATER_THAN;
    }

    if (isNaN(aNumber) && !isNaN(bNumber)) {
      return LESS_THAN;
    }
  }

  return EQUAL_TO;
};

export { GREATER_THAN, LESS_THAN, EQUAL_TO };
