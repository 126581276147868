import { signIn as userSignIn, fetchUserAttributes } from "aws-amplify/auth";
import { ConsoleLogger as Logger } from "@aws-amplify/core";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selector, set, AUTHSTATES } from "ducks/Auth";

const logger = new Logger("SignIn");

const isTruthyString = (value) => {
  return (
    value &&
    typeof value.toLowerCase === "function" &&
    value.toLowerCase() === "true"
  );
};

/**
 * 有効な認証状態
 */
const VALID_AUTO_STATES = [
  AUTHSTATES.IDLE,
  AUTHSTATES.SETUP,
  AUTHSTATES.SIGN_OUT,
  AUTHSTATES.SETUP_TOTP,
  AUTHSTATES.SIGN_IN,
  AUTHSTATES.SIGN_UP,
  AUTHSTATES.TRANSITION,
];

/**
 * ログイン画面を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @returns {func}
 */
export const Container = ({ render, ...props }) => {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { authState } = useSelector(selector);
  const dispatch = useDispatch();

  const onStateChange = (state) => {
    dispatch(set(state));
  };
  const signIn = async (data) => {
    setError("");
    setLoading(true);
    const { username, password } = data;
    try {
      const { isSignedIn, nextStep } = await userSignIn({ username, password });
      logger.debug({
        isSignedIn,
        nextStep,
      });

      if (isSignedIn) {
        fetchUserAttributes().then((data) => {
          if (isTruthyString(data.email_verified)) {
            onStateChange(AUTHSTATES.AUTHENTICATED);
            return;
          }
          onStateChange(AUTHSTATES.CONFIRM_VERIFY_USER);
        });
      }

      switch (nextStep.signInStep) {
        case "CONFIRM_SIGN_IN_WITH_TOTP_CODE":
        case "CONFIRM_SIGN_IN_WITH_SMS_CODE":
        case "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE":
        case "CONTINUE_SIGN_IN_WITH_TOTP_SETUP":
        case "CONTINUE_SIGN_IN_WITH_MFA_SELECTION": {
          onStateChange(AUTHSTATES.CONFIRM_SIGN_IN);
          break;
        }
        case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED": {
          onStateChange(AUTHSTATES.FORCE_NEW_PASSWORD);
          break;
        }
        case "DONE":
        default:
          onStateChange(AUTHSTATES.AUTHENTICATED);
          break;
      }
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        logger.debug("the user is not confirmed");
        onStateChange(AUTHSTATES.CONFIRM_SIGN_UP);
      } else if (err.code === "PasswordResetRequiredException") {
        logger.debug("the user requires a new password");
        onStateChange(AUTHSTATES.FORCE_NEW_PASSWORD);
      } else if (
        err.code === "NotAuthorizedException" ||
        err.code === "UserNotFoundException"
      ) {
        setError("ログインに失敗しました。");
      } else {
        logger.debug(err);
        setError("ログイン処理中にエラーが発生しました。");
      }
    } finally {
      setLoading(false);
    }
  };

  return render({
    onSubmit: signIn,
    onClickForgotPassword: () => onStateChange(AUTHSTATES.FORGOT_PASSWORD),
    isLoading: isLoading,
    error: error,
    isValid: VALID_AUTO_STATES.includes(authState),
    ...props,
  });
};
