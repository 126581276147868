import { add as addAlert } from "ducks/Alert";
import { setVisilityBeginnersMark } from "ducks/GuiderSteps/slice";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteRegularWasteCollectionOrder } from "api/graphql/mutations";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { debugLog } from "utils/log";

/**
 * 定期回収の詳細情報を表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @param {Promise} submit 送信処理
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onNotificationChange = () =>
    debugLog("RegularlyDetails.onNotificationChange"),
  submit,
  ...props
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [isSubmit, setIsSubmit] = useState(null);
  const formRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setVisilityBeginnersMark());
  }, [dispatch]);

  const handleSubmit = (data) => {
    submit(data)
      .then((result) => {
        setIsSubmit(false);
        dispatch(
          addAlert({
            value: "保存しました。",
            severity: "success",
          })
        );

        onNotificationChange();
        setOpen(false);
      })
      .catch((err) => {
        debugLog("Order.RegularlyDetails.submit.error: ", err);
        setIsSubmit(false);
        dispatch(
          addAlert({
            value: "エラーが発生したため、保存できませんでした。",
            severity: "error",
          })
        );
      });
  };

  const handleConfirm = () => {
    formRef.current.submit();
  };

  const handleDelete = () => {
    setIsSubmit(true);
    API.graphql(
      graphqlOperation(deleteRegularWasteCollectionOrder, {
        input: {
          id: value.id,
        },
      })
    )
      .then(() => {
        setOpenDeleteConfirm(false);
        navigate("/order/regularly");
      })
      .catch(() => {
        dispatch(
          addAlert({
            value: "エラーが出て削除できません。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return render({
    ...props,
    open: open,
    onCloseDialog: () => setOpen(false),
    onOpenDialog: () => setOpen(true),
    value: value,
    onSubmit: handleSubmit,
    isSubmit: isSubmit,
    onConfirm: handleConfirm,
    formRef: formRef,
    onNotificationChange: onNotificationChange,
    openDeleteConfirm: openDeleteConfirm,
    onCloseDeleteConfirm: () => setOpenDeleteConfirm(false),
    onOpenDeleteConfirm: () => setOpenDeleteConfirm(true),
    onDelete: handleDelete,
  });
};
