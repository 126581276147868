import { ChevronRight } from "@mui/icons-material";
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";

/**
 * コースリストの項目を表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <ListItem disableGutters={true}>
      <ListItemText
        primary={
          <>
            <span className={props.classes.title}>
              {`${props?.belongInCompanyName ?? ""} ${props?.name ?? ""}`}
            </span>
          </>
        }
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="next" onClick={props.onClick}>
          <ChevronRight />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
