import { GoogleMap } from "@react-google-maps/api";
import { useCallback, useRef } from "react";
import { debugLog } from "utils/log";
import { Markers } from "../Marker";
import { Direction } from "../Direction";

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};

const mapControlStyle = {
  DROPDOWN_MENU: 1,
  HORIZONTAL_BAR: 2,
};

const getMapOptions = (param) => {
  const { style } = param;
  return {
    // デフォルトUI（衛星写真オプションなど）をキャンセルします。
    disableDefaultUI: true,
    mapTypeControl: true,
    mapTypeControlOptions: {
      position: 7.0,
      style: style || mapControlStyle.DROPDOWN_MENU,
      mapTypeIds: ["roadmap", "satellite", "hybrid"],
    },
  };
};

/**
 * グーグルマップを表示するコンテナコンポーネントです。
 * @param {JSX.Element} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {array} markerPoints 回収ポイントの配列 ポイント情報：経度、緯度、index
 * @param {object} markerCenter マップで中心にするポイント {lat: "", lng: ""}
 * @param {object} nextDestination　次の目的地
 * @param {func} onClickMarker ポイントのマーカーをクリックするイベント
 * @returns {JSX.Element}
 */
export const GoogleMapApi = ({
  markerPoints = [],
  center,
  current,
  nextDestination,
  onClickMarker = (data) => debugLog("マーカーがクリックされた: ", data),
  onClickCurrent = (data) => debugLog("現在地マーカーがクリックされた: ", data),
  loadError,
  isLoaded,
  mapStyleParams,
}) => {
  const mapRef = useRef();

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";
  return (
    <GoogleMap
      id="map"
      mapContainerStyle={mapContainerStyle}
      zoom={center?.zoom ?? 13}
      center={{
        lat: center?.lat,
        lng: center?.lng,
      }}
      options={getMapOptions(mapStyleParams)}
      onLoad={onMapLoad}
    >
      <Markers
        value={markerPoints}
        onClickMarker={onClickMarker}
        current={current}
        nextDestination={nextDestination}
        onClickCurrent={onClickCurrent}
        animation={window?.google?.maps?.Animation?.DROP}
      />
      {nextDestination?.lat && nextDestination?.lng && (
        <Direction
          origin={current}
          lat={nextDestination.lat}
          lng={nextDestination.lng}
        />
      )}
    </GoogleMap>
  );
};
