import { makeStyles } from '@mui/styles';

export const styles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: `${theme.zIndex.modal + 100} !important`,
    "& input, & select": {
      boxSizing: "content-box",
    },
    "& .modal_container": {
      "&:focus-visible": {
        outline: "none",
      },
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "transparent",
      },
      backgroundColor: theme.palette.background.paper,
    },
  },
}));
