import { createRegularlyWasteCollectionOrder } from "api/graphql/mutations";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { companySelector } from "ducks/Company";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { toQueryString, fromQueryString } from "utils/useXGridComponents";

/**
 * 定期回収一覧を表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const company = useSelector(companySelector);

  const handleTransite = (e) => {
    navigate(
      {
        pathname: location.pathname,
        search: toQueryString(e.info.search),
      },
      {
        replace: true,
      }
    );
    navigate(`${location.pathname}/${e.value}`);
  };

  const exeCreateApi = (data) => {
    return API.graphql(
      graphqlOperation(createRegularlyWasteCollectionOrder, {
        input: {
          ownerCompanyId: company.id,
          wasteGeneratorCompanyId: data.dischargesWorkplace?.belongInCompanyId,
          wasteCollectionWorkplaceId: data.dischargesWorkplace?.id,
          wasteCarrierCompanyId: company.id,
          wasteId: data.wastes?.id,
          remarks: data.remarks,
          cycles: data.cycles.map((item) => {
            return {
              categoryId: item?.frequency?.category?.id,
              months: item?.frequency?.months?.map((month) => month.code),
              weeks: item?.frequency?.weeks?.map((week) => week.code),
              weekDays: item?.frequency?.weekDays?.map(
                (weekDay) => weekDay.code
              ),
              numberOfDays: item?.frequency?.numberOfDays
                ? Number(item?.frequency?.numberOfDays)
                : undefined,
              scheduleTimeRangeStart: item.times.start + ":00",
              scheduleTimeRangeEnd: item.times.end + ":00",
              cycleStartDate: DateTime.fromJSDate(
                new Date(item.cycleStartDate)
              ).toISODate(),
              remarks: item.remarks,
            };
          }),
        },
      })
    );
  };

  return render({
    onTransite: handleTransite,
    submit: exeCreateApi,
    initialState: {
      sort: {
        direction: "desc",
        field: "cycleStartDate",
      },
      ...fromQueryString(location.search),
    },
    ...props,
  });
};
