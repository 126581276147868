import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { styles } from "./styles";
import { basicScheme } from "./Validations";

const defaultValues = {
  dischargesWorkplace: undefined,
  transpoter: null,
  date: undefined,
  times: { start: null, end: null },
  remarks: "",
  wastes: [],
};

/**
 * スポット回収のフォームを入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @fires Container#onSubmit 送信時
 * @fires Container#onError エラー時
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onSubmit = (data) => debugLog(data),
  onError = (err) => debugLog(err),
  ...props
}) => {
  const classes = useClasses(styles);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      ...value,
      transpoter: "getCompanyのidを入れる",
    },
    resolver: yupResolver(basicScheme),
  });

  useEffect(() => {
    methods.reset({
      ...defaultValues,
      ...value,
    });
  }, [methods, value]);

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      methods.trigger().then((res) => {
        if (res) {
          onSubmit && onSubmit(methods.getValues());
        } else {
          onError(methods.formState?.errors);
        }
      });
    },
  }));

  return render({
    classes: classes,
    methods: methods,
    ...props,
  });
};
