import React, { useState, useEffect, useRef } from "react";
import { Button, Box, Typography, TextField } from "@mui/material";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { GoogleMap, Marker } from "@react-google-maps/api";
import useClasses from "utils/useClasses";
import { debugLog } from "utils/log";
import { useJsApiLoader } from "@react-google-maps/api";
import { useSelector } from "react-redux";

import { fetchLatLng, getLatLng } from "views/templates/Mobile/Collect/Utils";
import { selector } from "ducks/Prefectures";

import { styles } from "./styles";
import { useWatch } from "react-hook-form";
import { Stack } from "@mui/material";

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};

function isNotEmpty(obj) {
  return Object.values(obj).some((value) => value);
}

/**
 * It picks the location including lat and lng from google map api and set the value to the current form
 * @param {func} setValue
 * @param {string} suffix
 * @param {func} clearErrors
 * @param {object} control
 * @returns {JSX.Element}
 */

export const GoogleMapLocationPicker = ({
  setValue,
  suffix,
  clearErrors,
  control,
}) => {
  const [open, setOpen] = useState(false);
  const [marker, setMarker] = useState(null);
  const [addressText, setAddressText] = useState("");
  const prefectures = useSelector(selector).prefectures;
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_LICENSE_KEY,
    region: "JP",
    language: "ja",
  });
  const [address, setAddress] = useState({
    postalCode: "",
    prefecture: "",
    city: "",
    wardName: "",
    street: "",
    building: "",
  });
  const [center, setCenter] = useState({
    lat: 35.681236,
    lng: 139.767125,
    zoom: 15,
  });
  const classes = useClasses(styles);
  const { inputType, position, ...other } = useWatch({
    control,
  });
  const [map, setMap] = useState(null);
  const mapRef = useRef();

  useEffect(() => {
    if (position && inputType === "from-map") {
      setMarker(position);
      setCenter(position);
      setAddress({
        postalCode: other.postalCode ?? "",
        prefecture: other.prefectures?.name ?? "",
        city: other.city ?? "",
        street: other.streetAddress ?? "",
        building: position.otherAddress ?? "",
      });
      return;
    }
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }, [
    position,
    inputType,
    other.postalCode,
    other.prefectures?.name,
    other.city,
    other.streetAddress,
  ]);

  const successCallback = (position) => {
    const { latitude, longitude } = position.coords;
    setCenter({
      lat: latitude,
      lng: longitude,
      zoom: 15,
    });
  };

  const errorCallback = (error) => {
    debugLog(error);
  };

  const handleClose = () => {
    setOpen(false);
    setMarker();
    setAddress({});
    setCenter({ lat: center?.lat, lng: center?.lng });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClickMap = (event) => {
    var lat = event.latLng.lat();
    var lng = event.latLng.lng();

    // Perform reverse geocoding to get the full address
    var geocoder = new window.google.maps.Geocoder();
    var latLng = { lat: lat, lng: lng };

    const center = map?.getCenter() || { lat, lng };
    const zoom = map?.getZoom() || 16;
    setCenter({
      lat: center.lat(),
      lng: center.lng(),
      zoom: zoom,
    });
    setMarker({
      lat: lat,
      lng: lng,
    });
    geocoder.geocode({ location: latLng }, function (results, status) {
      if (status === "OK") {
        if (results[0]) {
          const addressComponents = results[0].address_components;
          const postalCode = addressComponents.find((component) =>
            component.types.includes("postal_code")
          );
          const prefecture = addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          );
          const city = addressComponents.find((component) =>
            component.types.includes("locality")
          );
          const wardName = addressComponents.find((component) =>
            component.types.includes("sublocality_level_1")
          );
          const subLocality2 = addressComponents.find((component) =>
            component.types.includes("sublocality_level_2")
          );
          const subLocality3 = addressComponents.find((component) =>
            component.types.includes("sublocality_level_3")
          );
          const subLocality4 = addressComponents.find((component) =>
            component.types.includes("sublocality_level_4")
          );

          const premises = addressComponents
            .filter((component) => component.types.includes("premise"))
            .map((component) => component.long_name);
          const subLocalities = [
            subLocality2?.long_name,
            subLocality3?.long_name,
            subLocality4?.long_name,
          ].filter(Boolean);
          const subLocalityString = subLocalities.join("");
          const building = premises.join(" - ");

          setAddress({
            postalCode: postalCode?.long_name ?? "",
            prefecture: prefecture?.long_name ?? "",
            city: city?.long_name ?? "",
            wardName: wardName?.long_name ?? "",
            street: subLocalityString,
            building: building ?? "",
          });
        } else {
          debugLog("not found");
        }
      }
    });
  };

  const handleSetAddress = () => {
    const cityValue = `${address.wardName ? `${address.wardName}` : ""}${
      address.city ?? ""
    }`;
    const prefecture = prefectures.find((v) => v.name === address?.prefecture);
    setValue(`${suffix}prefectures`, prefecture);
    setValue(`${suffix}city`, cityValue ?? "");
    setValue(`${suffix}streetAddress`, address.street ?? "");
    setValue(`${suffix}otherAddress`, address.building ?? "");
    setValue(`${suffix}postalCode`, address.postalCode ?? "");
    setValue(
      `${suffix}position`,
      {
        lat: marker.lat,
        lng: marker.lng,
      } ?? ""
    );
    clearErrors();
    handleClose();
  };

  const onChange = (e) => {
    setAddressText(e.target.value);
  };

  const handleAddressSearch = () => {
    if (addressText.length) {
      getLatLng(addressText).then((res) => {
        const latLng = fetchLatLng(res);
        setMarker(latLng);
        setCenter({
          lat: latLng.lat,
          lng: latLng.lng,
          zoom: 15,
        });
      });
    }
  };

  const handleMapLoad = (map) => {
    setMap(map);
  };
  
  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";
  return (
    <>
      <Box>
        <Typography className={classes.label}>
          住所を選択してください
        </Typography>
        <Button variant="outlined" onClick={handleOpen}>
          <img src="/google-map-icon.png" height="30" alt="google map icon" />
        </Button>
      </Box>
      {open && (
        <Dialog maxWidth="lg" open={open} onClose={handleClose}>
          <DialogTitle
            sx={{
              fontSize: 14,
            }}
            id="scroll-dialog-title"
          >
            クリックして地図から場所を選択してください。
          </DialogTitle>
          <DialogContent
            sx={{
              height: "80vh",
              maxHeight: "600px",
              maxWidth: "900px",
              width: "60vw",
              padding: 0,
            }}
            dividers
          >
            <GoogleMap
              id="map"
              mapContainerStyle={mapContainerStyle}
              zoom={center?.zoom ?? 15}
              center={{
                lat: center?.lat,
                lng: center?.lng,
              }}
              onLoad={handleMapLoad}
              ref={mapRef}
              options={{
                disableDefaultUI: true,
                mapTypeControl: true,
                mapTypeControlOptions: {
                  position: 7.0,
                  mapTypeIds: ["roadmap", "satellite", "hybrid"],
                },
              }}
              onClick={handleClickMap}
            >
              <>
                <Stack
                  sx={{
                    position: "absolute",
                    top: 3,
                    left: 5,
                    zIndex: 9999,
                    borderRadius: 1,
                    bgcolor: "#fff",
                  }}
                  direction="row"
                  alignItems="center"
                >
                  <TextField
                    variant="outlined"
                    placeholder="住所を入力してください"
                    size="small"
                    onChange={onChange}
                    InputProps={{
                      endAdornment: (
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          disabled={!addressText.length}
                          onClick={handleAddressSearch}
                        >
                          検索
                        </Button>
                      ),
                    }}
                  />
                </Stack>
                {/* Render the single marker */}
                {marker && (
                  <Marker
                    position={marker}
                    draggable={true} // Make the marker draggable
                    onDragEnd={(event) => {
                      handleClickMap(event);
                    }}
                  />
                )}
              </>
            </GoogleMap>
          </DialogContent>
          <DialogActions>
            <Typography
              style={{
                flexGrow: 1,
                marginLeft: "0",
                maxWidth: "43vw",
              }}
            >
              {address.postalCode ? `〒${address.postalCode}` : ""}
              {address.prefecture ?? ""}
              {address.city ?? ""}
              {address.wardName ?? ""}
              {address.street ?? ""}
              {address.building ?? ""}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSetAddress}
              disabled={!isNotEmpty(address)}
            >
              はい
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              キャンセル
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

GoogleMapLocationPicker.propTypes = {
  suffix: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
};
