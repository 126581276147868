import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  getChecksheetTemplate,
  searchChecksheetTemplatesByOffset,
} from "api/graphql/queries";
import { companySelector } from "ducks/Company";
import { useSelector } from "react-redux";
import { useQuery } from "utils/useQuery";

/**
 * 選択する項目を表示するコンポーネントです。
 * @fires Item#onClick クリック時
 * @param {object} value 値
 * @returns {JSX.Element}
 */
const Item = ({ onClick, value, disabled }) => {
  return (
    <ListItem
      button
      onClick={() => onClick(value)}
      secondaryAction={
        <IconButton>
          <ChevronRightIcon />
        </IconButton>
      }
      disabled={disabled}
    >
      <ListItemText primary={value?.name} />
    </ListItem>
  );
};

/**
 * チェックシートテンプレートを選択するコンポーネントです。
 * @param {boolean} open 開閉状態
 * @param {boolean} loading 読み込み中
 * @fires CheckSheetSelector#onClick クリック時
 * @fires CheckSheetSelector#onClose 閉じる時
 * @returns {JSX.Element}
 */
export const ChecksheetsTemplateSelector = ({
  open = false,
  onClick = () => {},
  onClose = () => {},
}) => {
  const company = useSelector(companySelector);
  const checksheetTemplate = useQuery({
    query: searchChecksheetTemplatesByOffset,
    variables: {
      ownerCompanyId: company.id,
    },
  });

  const get = useQuery({
    query: getChecksheetTemplate,
    skip: true,
    onCompleted: (result) => {
      onClick(result?.data?.getChecksheetTemplate);
      onClose();
    },
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{"点検を行うチェックシートを選択する"}</DialogTitle>
      {checksheetTemplate?.loading === true && <CircularProgress />}
      {checksheetTemplate?.loading === false && (
        <List>
          {checksheetTemplate?.data?.searchChecksheetTemplatesByOffset?.items?.map(
            (item, index) => (
              <Item
                key={index}
                onClick={() =>
                  get.refetch({
                    id: item.id,
                  })
                }
                value={item}
                disabled={get.loading || checksheetTemplate.loading}
              />
            )
          ) ?? <Alert color="warning">{"チェックシートはありません。"}</Alert>}
        </List>
      )}
    </Dialog>
  );
};
