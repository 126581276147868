import React from "react";
import { SpotTable } from "views/organisms/Order";

/**
 * スポット回収一覧の表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <SpotTable
    submit={props.submit}
    onSelected={props.onTransite}
    initialState={props.initialState}
  />
);
