import { companySelector } from "ducks/Company";
import { useSelector } from "react-redux";
import { debugLog } from "utils/log";
import { useXGridComponents } from "utils/useXGridComponents";
import { searchContainersByOffset } from "api/graphql/queries";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

const columns = [
  {
    field: "installationWorkplaceName",
    headerName: "設置場所の名称",
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "productName",
    headerName: "コンテナ種類",
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "number",
    headerName: "コンテナ管理番号",
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
];

/**
 * コンテナを選択するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  value,
  onSelected = (data) => debugLog(data),
  multiple = false,
  download = false,
  title = "",
  fixedOptions,
  autoHeight = false,
  ...props
}) => {
  const company = useSelector(companySelector);
  const xGridObject = useXGridComponents(columns, searchContainersByOffset, {
    fixedOptions: fixedOptions,
    other: {
      ownerCompanyId: company.id,
    },
  });

  const handleSelected = (params) => {
    const result = xGridObject.params.rows.filter((row) =>
      params.includes(row.id)
    );
    onSelected(multiple ? result : result?.[0]);
  };

  return render({
    ...props,
    value: value,
    multiple: multiple,
    download: download,
    title: title,
    onSelected: handleSelected,
    xGridParams: xGridObject.params,
    autoHeight: autoHeight,
  });
};
