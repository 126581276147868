import { removeVehicle } from "api/graphql/mutations";
import { useMutation } from "utils/useMutation";
import { useNavigate, useParams } from "react-router-dom";

/**
 * マスタ管理の車輛詳細ページの画面を表示するコンテナコンポーネントです。
 * @param {props} props
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [removeMutation, removeMutationState] = useMutation(removeVehicle, {
    onCompleted: () => {
      navigate(
        {
          pathname: `/master/vehicle`,
        },
        {
          replace: true,
        }
      );
    },
    succeedMessage: "削除しました。",
    errorMessage: "エラーが発生したため、削除できませんでした。",
  });

  const handleRemove = () => {
    removeMutation({
      input: {
        id: id,
      },
    });
  };

  return render({
    loading: removeMutationState?.loading,
    onRemove: handleRemove,
    ...props,
  });
};
