import React from "react";
import { InformationItem } from "views/atoms";

/**
 * 処理フローの基本情報を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <div>
      <InformationItem label="管理番号">
        {props.value?.number ?? ""}
      </InformationItem>
      <InformationItem label="廃棄物の種類">
        {`${
          props.value?.waste?.type?.smallClass?.name ||
          props.value?.waste?.type?.middleClass?.name ||
          props.value?.waste?.type?.largeClass?.name ||
          props.value?.waste?.type?.group?.name ||
          ""
        }`}
      </InformationItem>
      <InformationItem label="廃棄物の詳細種類">
        {props.value?.waste?.kind || ""}
      </InformationItem>
      <InformationItem label="廃棄物の名称">
        {props.value?.waste?.name ?? ""}
      </InformationItem>
      <InformationItem label="廃棄物の荷姿">
        {props.value?.waste?.packing?.name ?? ""}
      </InformationItem>
      <InformationItem label="廃棄物の荷姿詳細">
        {props.value?.waste?.packingDetails ?? ""}
      </InformationItem>
      <InformationItem label="廃棄物の数量入力単位">
        {`${props.value?.waste?.quantityUnit?.name || ""}${
          props.value?.waste?.weightConvertedValue
            ? props.value?.waste?.quantityUnit?.name !== "kg" &&
              props.value?.waste?.quantityUnit?.name !== "t"
              ? `（重量換算係数 ${props.value?.waste?.weightConvertedValue}kg/${
                  props.value?.waste?.weightConvertedValueUnit?.name ||
                  props.value?.waste?.quantityUnit?.name
                }）`
              : ""
            : ""
        }`}
      </InformationItem>
      <InformationItem label="排出頻度">
        {props.value?.waste?.dischargeFrequency
          ? `${props.value?.waste?.dischargeFrequency.interval.name} ${props.value?.waste?.dischargeFrequency?.numberOfTimes}回`
          : ""}
      </InformationItem>
      <InformationItem label="一回の排出量">
        {props.value?.waste?.discharweightConvertedValueUnitgeAmountPerOneTime
          ? `${props.value?.waste?.dischargeAmountPerOneTime ?? ""}${
              props.value?.waste?.quantityUnit?.name ?? ""
            }`
          : ""}
      </InformationItem>
      <InformationItem label="輸入廃棄物">
        {props.value?.waste?.isImportedWaste
          ? "輸入廃棄物である"
          : "輸入廃棄物ではない"}
      </InformationItem>
    </div>
  );
};
