import React, { useEffect, useState, useMemo, useRef } from "react";
import { Box, Skeleton } from "@mui/material";
import { toggle } from "ducks/Loading";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Button from "@mui/material/Button";
import { add as addAlert } from "ducks/Alert";
import { FullScreenDialog } from "views/molecules";
import {
  automationsSliceSelector,
  fetchAllData,
  removeNewCourse,
  updateAutomationCourses,
  createAutomationSettingsThunk,
  resetToInitialState,
} from "ducks/Automation";
import NotDispatchVehicles from "views/organisms/Allocation/NotDispatchVehicles";
import { Setting } from "views/templates/Allocation";
import { CourseList } from "views/templates/Allocation";

export const Settings = () => {
  const dispatch = useDispatch();
  const childRef = useRef(null);
  const { data, isLoading } = useSelector(automationsSliceSelector);
  const { isPointLoading } = useSelector((state) => state.Automation);
  const [page, setPage] = useState(1);
  const [resetPage, setResetPage] = useState(false);
  const [activeCourse, setActiveCourse] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetToInitialState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!activeCourse) {
      const isNewCourseExists = data?.done?.courses?.some(
        (course) => course.isNewCourse
      );
      if (isNewCourseExists) {
        dispatch(removeNewCourse());
      }
    }
  }, [data?.done?.courses, activeCourse, dispatch]);

  useEffect(() => {
    dispatch(fetchAllData());
  }, [dispatch, resetPage]);

  useEffect(() => {
    dispatch(toggle(isLoading));
    return () => {
      dispatch(toggle(false));
    };
  }, [dispatch, isLoading]);

  const course = useMemo(() => {
    if (activeCourse) {
      return (
        data.done?.courses.find((course) => course.id === activeCourse) || null
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCourse]);

  const openedDialogueTitle = useMemo(() => {
    if (activeCourse) {
      return (
        data.done?.courses.find((course) => course.id === activeCourse)?.name ||
        null
      );
    }
    return null;
  }, [data?.done?.courses, activeCourse]);

  const handlePageChange = (_, pageValue) => {
    const course = data?.done?.courses?.[pageValue - 1];
    setPage(pageValue);
    setActiveCourse(course?.id);
  };

  const handleCloseDialog = () => {
    dispatch(removeNewCourse());
    setActiveCourse(null);
    setPage(1);
    if (isSaveClicked) {
      setIsSaveClicked(false);
      dispatch(fetchAllData());
    }
  };

  const onSelectCourse = (course) => {
    let courseIndex = data.done?.courses?.findIndex((c) => c.id === course.id);
    if (courseIndex === null || courseIndex === undefined) {
      courseIndex = -1;
    }
    const page = courseIndex + 1;
    setPage(page > 0 ? page : Number(data?.done?.courses?.length || 0) + 1);
    setActiveCourse(course.id);
  };

  const handleUpdateCoursesFromDialogue = async () => {
    setIsSubmitting(true);
    const currentCourse = data.done.courses.find(
      (item) => item.id === activeCourse
    );
    try {
      if (childRef.current) {
        const result = await childRef.current.handleUpdateData();
        if (result?.loadError) {
          handleCloseDialog();
        }
        if (result.success) {
          dispatch(
            addAlert({
              value: "保存しました。",
              severity: "success",
            })
          );

          if (result?.data) {
            dispatch(updateAutomationCourses(result.data));
            if (currentCourse?.isNewCourse) {
              const lastAddedCourseId = result.data?.courses.slice(-1)[0]?.id;
              setActiveCourse(lastAddedCourseId);
            }
          }

          setIsSubmitting(false);
          setIsSaveClicked(true);
          return;
        } else {
          dispatch(
            addAlert({
              value: "エラーが発生しました。",
              severity: "error",
            })
          );
          setIsSubmitting(false);
        }
      }
    } catch (error) {
      dispatch(
        addAlert({
          value: "エラーが発生しました。",
          severity: "error",
        })
      );
      setIsSubmitting(false);
    }
  };

  const handleCreateAllocationSetting = async () => {
    const response = await dispatch(createAutomationSettingsThunk());
    if (response?.type === "data/createAutomationSettings/fulfilled") {
      dispatch(
        addAlert({
          value: "配車計画を作成しました。",
          severity: "success",
        })
      );
    }

    if (response?.type === "data/createAutomationSettings/rejected") {
      dispatch(
        addAlert({
          value: "エラーが発生しました。",
          severity: "error",
        })
      );
    }
  };

  return (
    <Box
      sx={{ padding: (theme) => theme.spacing(2, 3), backgroundColor: "#fff" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 3,
        }}
      >
        <div
          style={{
            paddingBottom: "16px",
          }}
        >
          {isLoading && (
            <Skeleton variant="rectangular" width={180} height={40} />
          )}
        </div>
        {!data?.done && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateAllocationSetting}
            disabled={isLoading}
          >
            配車計画を作成する
          </Button>
        )}
      </Box>

      {data.still?.length || data.trashed?.length ? (
        <NotDispatchVehicles />
      ) : null}

      {data?.done && !activeCourse && !isLoading && (
        <CourseList
          onSelectCourse={onSelectCourse}
          setResetPage={setResetPage}
        />
      )}

      <FullScreenDialog
        open={activeCourse}
        textConfirm="保存"
        action={
          <Pagination
            sx={{
              width: "50%",
              "& .MuiPaginationItem-root": {
                color: "#fff",
                border: "1px solid #5F76B9",
              },
            }}
            disabled={isSubmitting || isPointLoading}
            page={page}
            onChange={handlePageChange}
            count={data.done?.courses?.length}
            variant="outlined"
            color="white"
          />
        }
        title={openedDialogueTitle}
        onClickSubmit={handleUpdateCoursesFromDialogue}
        onClose={handleCloseDialog}
        container={false}
        isSubmit={isSubmitting}
        disabled={isSubmitting || isPointLoading}
        closeDisabled={isSubmitting || isPointLoading}
      >
        {course?.id && <Setting courseId={course.id} ref={childRef} />}
      </FullScreenDialog>
    </Box>
  );
};
