import React from "react";
import { FlowRadioGroup } from "../../../FlowDialog/Child";

/**
 * 基本情報のタブの表示を行うプレゼンテーションコンポーネントです
 * @param {object} classes クラス名
 * @param {object} value 事業場情報
 */
export const Presententer = (props) => {
  return <FlowRadioGroup options={props.options} display={true} />;
};
