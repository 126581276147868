import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { TextField } from "views/atoms";

/**
 * 整備内容を入力するプレゼンテーションコンポーネントです。
 */
export const Presententer = ({
  control,
  disabled,
  open,
  onClose,
  onSubmit,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{"整備内容"}</DialogTitle>
      <DialogContent>
        <Stack
          spacing={2}
          sx={{
            p: 2,
          }}
        >
          <Box>
            <Controller
              name={`method`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  label="整備方法"
                  required={true}
                  placeholder="整備方法を入力します。"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  disabled={disabled}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={`part`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  label="整備箇所"
                  required={true}
                  placeholder="整備箇所を入力します。"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  disabled={disabled}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={`content`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  label="作業内容"
                  required={true}
                  placeholder="作業内容を入力します。"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  disabled={disabled}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={`quantityValue`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  label="個数"
                  required={true}
                  placeholder="作業内容を入力します。"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  disabled={disabled}
                  type="number"
                />
              )}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{"キャンセル"}</Button>
        <Button onClick={onSubmit}>{"保存"}</Button>
      </DialogActions>
    </Dialog>
  );
};
