import { Autocomplete, TextField } from "@mui/material";
import React from "react";

/**
 * 自由入力可能なオートコンプリートプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({ label, placeholder, error, helperText, onChange, ...props }) => (
  <Autocomplete
    {...props}
    onChange={onChange}
    onInputChange={onChange}
    freeSolo
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        placeholder={placeholder}
        error={error}
        helperText={helperText}
      />
    )}
  />
);
