import yup from "utils/yup";

const numberOfDigits = (value) => {
  return (
    Number(value)
      .toString()
      .match(new RegExp(/^([1-9][0-9]{0,4}|0)(\.[0-9]{1,3})?$/)) !== null
  );
};

export const basicSchema = yup.object().shape({
  tasks: yup
    .array()
    .test("quantitiesRequired", "必須入力項目です。", function (value) {
      if (this.parent.emptyWhenZero === true) {
        return true;
      }

      return (
        value?.filter((item) => item?.record?.quantity == null)?.length <= 0
      );
    })
    .test("typeOfNumber", "A", function (value) {
      let result = value?.filter((item) => item?.record?.quantity != null);

      if (result.length <= 0) {
        return true;
      }

      return (
        result?.filter((item) => isNaN(item?.record?.quantity))?.length <= 0
      );
    })
    .test("numberOfDigits", "B", function (value) {
      let result = value?.filter((item) => item?.record?.quantity != null);

      if (result.length <= 0) {
        return true;
      }

      return (
        result?.filter((item) => numberOfDigits(item.record.quantity) === false)
          ?.length <= 0
      );
    })
    .test("flowsValidation", "フローが選択されていません", function (value) {
      return value?.filter((item) => !item?.record?.wasteFlowId)?.length <= 0;
    }),
  emptyWhenZero: yup.boolean(),
});
