import { useEffect, useState } from "react";
import useClasses from "utils/useClasses";
import { styles } from "./styles";

export const Container = ({
  render,
  open = false,
  onClose,
  title = "",
  tab,
  tabs = [],
  ...props
}) => {
  const classes = useClasses(styles);
  const [tabNumber, setTab] = useState(0);
  useEffect(() => {
    if (tab) {
      setTab(tab);
    }
  }, [tab]);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleCloseDialog = () => {
    onClose && onClose();
    setTab(0);
  };

  return render({
    classes: classes,
    open,
    onClose: handleCloseDialog,
    title: title,
    tabs: tabs,
    tabNumber: tabNumber,
    onChangeTab: handleChangeTab,

    ...props,
  });
};
