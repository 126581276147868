import {
  KeyboardArrowLeft,
  Schedule as ScheduleIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import {
  includesTasksNotEnterd,
  isAllTaskCompleted,
} from "views/templates/Mobile/Collect/Utils";
import { TaskField } from "../TaskField";
import { If } from "views/atoms";

const Header = (props) => {
  const { classes, onClose, message, number, value } = props;
  return (
    <Box className={classes.collect_header} sx={{ position: "relative" }}>
      <IconButton onClick={onClose}>
        <KeyboardArrowLeft />
      </IconButton>
      {message && <Box className={classes.message}>{message}</Box>}
      <Box textAlign="center" mt={2}>
        <span
          className={clsx(
            classes.number,
            isAllTaskCompleted(value.tasks) && "completed",
            includesTasksNotEnterd(value.tasks) && "incompleted"
          )}
        >
          {number + 1}
        </span>
        {`${value?.workplace?.belongInCompanyName ?? ""}  ${
          value?.workplace?.name ?? ""
        }`}
      </Box>
      <Box className={classes.time}>
        <ScheduleIcon /> {value?.times ?? ""}
      </Box>
    </Box>
  );
};

/**
 * 回収詳細フォームを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <>
      <Box className={props.classes.collect_detail}>
        <Header {...props} message={props?.isInvalid && "入力してください"} />
        <Box className={props.classes.collect_content}>
          <Box
            sx={(theme) => ({
              padding: theme.spacing(0, 5),
            })}
          >
            <FormControlLabel
              checked={props.emptyWhenZero}
              onChange={props.onChangeEmptyWhenZero}
              control={<Checkbox defaultChecked />}
              label="未入力分は0で登録する"
            />
          </Box>
          <If condition={(props.value?.tasks?.length ?? 0) > 0}>
            <Controller
              name={"tasks"}
              control={props.control}
              render={({ field: { value }, fieldState: { error } }) =>
                props.value?.tasks?.map((task, index) => {
                  return (
                    <TaskField
                      key={index}
                      value={value?.[index]}
                      task={task}
                      onChange={(data) => props.onChange(index, data)}
                      error={error}
                      helperText={error?.message}
                    />
                  );
                })
              }
            />
          </If>
        </Box>
        <Box className={props.classes.collect_footer}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            type="submit"
            onClick={props.onConfirmed}
            disabled={props.loading || props.isInvalid}
          >
            {props.loading ? <CircularProgress size={25} /> : "OK"}
          </Button>
        </Box>
      </Box>
    </>
  );
};
