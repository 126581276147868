import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";

import { companySelector } from "ducks/Company";
import Checkbox from "@mui/material/Checkbox";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";

import { debugLog } from "utils/log";
import { If } from "views/atoms";
import { ConfirmDialog } from "views/molecules";
import { listWasteCollectionSchedulesCount } from "api/graphql/queries";
import {
  deleteLogisticsWorkAllocation,
  createLogisticsWorkAllocationWithSettings,
  publishCreateWorkAllocationWithSettingsStart,
} from "api/graphql/mutations";
import { DateStepper } from "views/molecules";
import { formatDisplayDate } from "utils/format";
import { useOpener } from "utils/useOpener";

/**
 * Generates a list of data for a specific month, filling in missing dates with hyphens ('-').
 * @param {Array} apiData - The API response data containing schedule information.
 * @param {number} year - The year for which data should be generated.
 * @param {number} month - The month (1-12) for which data should be generated.
 * @returns {Array} - An array of objects representing schedule data for the specified month.
 */
const generateDataWithHyphens = (apiData, year, month) => {
  // Calculate the start and end dates of the specified month
  const startDate = new Date(year, month - 1, 1); // Month is 0-indexed, so subtract 1
  const endDate = new Date(year, month, 0); // Get the last day of the specified month

  // Initialize an array to store the result data
  const resultData = [];

  // Initialize a currentDate variable to iterate through dates
  let currentDate = startDate;

  // Iterate through dates in the specified month
  while (currentDate <= endDate) {
    const dayOfMonth = currentDate.getDate();

    // Format the date as "YYYY-MM-DD"
    const dateString = `${year}-${month
      .toString()
      .padStart(2, "0")}-${dayOfMonth.toString().padStart(2, "0")}`;

    const apiDataForDate = apiData.find(
      (item) => item.scheduleDate === dateString
    );
    if (apiDataForDate) {
      resultData.push(apiDataForDate);
    } else {
      resultData.push({
        scheduleDate: dateString,
        unassignedCount: null,
        assignedCount: null,
        lastUpdatedOn: null,
      });
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return resultData;
};

/**
 * Formats a given number of seconds into a human-readable time string.
 *
 * @param {number} seconds - The number of seconds to format.
 * @returns {string} A formatted time string in the format "X minutes and Y seconds".
 */
const formatTime = (seconds) => {
  // Calculate the number of minutes and remaining seconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Create a formatted time string based on the calculated values
  let timeString = minutes ? `${minutes} 分 と` : "";
  timeString += ` ${remainingSeconds} 秒`;

  return timeString;
};

/**
 * A Component to list the monthly allocation table
 * @param {string} date: The date
 * @param {func} onClose: This close the window
 * @param {func} onCloseModel: Function to close the model if it was called from the model
 * */
const MonthlyAllocationView = ({ date, onClose, onChangeDate }) => {
  const [loading, setLoading] = useState(false);
  const [allocations, setAllocations] = useState([]);
  const [monthDate, setMonthDate] = useState(date);
  const [selectedRows, setSelectedRows] = useState([]);
  const { open, toggle } = useOpener();
  const [refresh, setRefresh] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [remainingTime, setRemainingTime] = useState(null);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const { id: ownerCompanyId } = useSelector(companySelector);

  const handleCheckeBox = (event, id) => {
    const value = event.target.checked;
    if (value) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter((row) => row !== id));
    }
  };

  const handleAllCheckedToggle = () => {
    if (isAllChecked) {
      setSelectedRows([]);
    } else {
      setSelectedRows(allocations.map((_, i) => i));
    }
    setIsAllChecked((prev) => !prev);
  };

  const columns = [
    {
      field: "id",
      width: 80,
      sortable: false,
      disableColumnMenu: !selectedRows.length,
      renderHeader: () => (
        <Checkbox
          size="medium"
          checked={isAllChecked}
          onChange={handleAllCheckedToggle}
        />
      ),
      renderCell: (params) => {
        return (
          <Checkbox
            size="medium"
            checked={selectedRows.includes(params.id)}
            onChange={(event) => handleCheckeBox(event, params.id)}
          />
        );
      },
    },
    {
      field: "scheduleDate",
      headerName: "回収日",
      width: 170,
      disableColumnMenu: true,
      renderCell: (params) =>
        formatDisplayDate({
          source: params.value,
          destFormat: "yyyy/MM/dd (EEE)",
        }),
    },
    {
      field: "unassignedCount",
      headerName: "未配車数",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.value === null) {
          return "-";
        }
        return params.value;
      },
    },
    {
      field: "assignedCount",
      headerName: "配車数合計",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.value === null) {
          return "-";
        }
        return params.value;
      },
    },
    {
      field: "lastUpdatedOn",
      headerName: "最終更新日時",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.value === null) {
          return "-";
        }
        const luxonDate = DateTime.fromISO(params.value, { zone: "utc" });
        return luxonDate.toFormat("yyyy/MM/dd HH:mm");
      },
    },
  ];

  useEffect(() => {
    setSelectedRows([]);
    setIsAllChecked(false);
    const controller = new AbortController();
    const { signal } = controller;
    setLoading(true);
    const listWasteCollectionPromise = API.graphql(
      graphqlOperation(listWasteCollectionSchedulesCount, {
        year: monthDate.year,
        month: monthDate.month,
      }),
      {
        signal,
      }
    );

    listWasteCollectionPromise
      .then((response) => {
        const result = response.data.listWasteCollectionSchedulesCount.items;
        const data = generateDataWithHyphens(
          result,
          monthDate.year,
          monthDate.month
        );
        setAllocations(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        debugLog("listWasteCollectionSchdeulesCount: ", err);
      });

    return () => {
      controller.abort();
      API.cancel(listWasteCollectionPromise);
    };
  }, [monthDate, refresh]);

  useEffect(() => {
    if (openBackDrop) {
      const timerInterval = 1000; //1 second
      const totalTime = selectedRows.length * 28;

      let currentIndex = 0;
      const intervalId = setInterval(() => {
        if (currentIndex === totalTime) {
          setRefresh((prev) => !prev);
          setOpenBackDrop(false);
          setShowAlert(false);
          setRemainingTime(null);
          clearInterval(intervalId);
          return;
        }
        setRemainingTime(totalTime - currentIndex);
        currentIndex += 1;
      }, timerInterval);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openBackDrop]);

  const onDateChange = (date) => {
    setMonthDate(date);
  };

  const handleDeleteSelectedAllocation = async (_, refresh = true) => {
    try {
      toggle(false);
      setLoading(true);
      const indexSet = new Set(selectedRows);
      const newAllocations = allocations.filter((_, i) => indexSet.has(i));

      if (newAllocations.length) {
        await API.graphql(
          graphqlOperation(deleteLogisticsWorkAllocation, {
            input: {
              scheduleDates: newAllocations.map(
                (allocation) => allocation.scheduleDate
              ),
            },
          })
        );

        refresh && setRefresh((prev) => !prev);
        setIsAllChecked(false);
      }
    } catch (err) {
      debugLog("DeleteLogisticsWorkAllocation: ", err);
    }
  };

  const handleCreateBulk = async () => {
    try {
      setOpenBackDrop(true);

      // Wait for delete to complete
      await handleDeleteSelectedAllocation(undefined, false);

      const publishPromise = API.graphql(
        graphqlOperation(publishCreateWorkAllocationWithSettingsStart, {
          result: {
            message: "Bulk Creation started !",
            ownerCompanyId,
          },
        })
      );

      const createPromise = API.graphql(
        graphqlOperation(createLogisticsWorkAllocationWithSettings, {
          input: {
            scheduleDates: selectedRows.map(
              (index) => allocations[index].scheduleDate
            ),
            ownerCompanyId,
          },
        })
      );

      // Use Promise.all to wait for both promises to resolve
      await Promise.all([publishPromise, createPromise]);

      setIsAllChecked(false);
      setShowAlert(true);
    } catch (err) {
      debugLog("Error: ", err);
      setOpenBackDrop(false);
      setShowAlert(false);
    }
  };

  const handleRowSelect = (param, event) => {
    if (param.field === "id") {
      event.defaultMuiPrevented = true;
      return;
    }
    const row = allocations[param.id];
    const selectedDate = DateTime.fromISO(row.scheduleDate);
    onChangeDate(selectedDate);
    onClose();
  };

  const action = (
    <Stack direction="row" spacing={2}>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleCreateBulk}
      >
        {"配車計画を反映"}
      </Button>
      <Button
        variant="outlined"
        color="error"
        size="small"
        onClick={() => {
          toggle(true);
        }}
      >
        {"一括削除"}
      </Button>
    </Stack>
  );

  return (
    <Box
      sx={{
        marginTop: "-30px",
        marginBottom: "-40px",
      }}
    >
      <DateStepper value={date} mode="month" onChange={onDateChange}>
        {() => (
          <Box
            sx={{
              height: selectedRows?.length
                ? "calc(100vh - 190px)"
                : "calc(100vh - 150px)",
            }}
          >
            <>
              {selectedRows.length ? (
                <Box
                  sx={{
                    backgroundColor: "rgba(241, 243, 244, 0.87)",
                    borderRadius: 10,
                    px: 5,
                    mb: 1,
                    py: "2px",
                    color: "#6200EE",
                    fontSize: 15,
                    fontWeight: 500,
                    boxShadow: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {action}
                  <Typography ml={2}>
                    <b>({selectedRows.length}件)</b>{" "}
                  </Typography>
                </Box>
              ) : null}
              <DataGridPro
                columns={columns}
                rows={allocations.map((r, i) => ({
                  id: i,
                  ...r,
                }))}
                onCellClick={handleRowSelect}
                isRowSelectable={(params) => params.row.id}
                loading={loading}
                rowHeight={38}
              />
            </>
          </Box>
        )}
      </DateStepper>
      <If condition={open}>
        <ConfirmDialog
          disabled={loading}
          open={open}
          title="削除確認"
          message={`選択された ${selectedRows.length} 個のアイテム 削除します。よろしいですか？`}
          positiveText="はい"
          negativeText="いいえ"
          onPositive={handleDeleteSelectedAllocation}
          onNegative={() => toggle(false)}
        />
      </If>

      {openBackDrop && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackDrop}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: 600,
              borderRadius: 2,
              bgcolor: "#fff",
              padding: 5,
              textAlign: "center",
            }}
          >
            <CircularProgress color="info" />
            {showAlert && (
              <>
                <Typography
                  sx={{
                    padding: 3,
                    borderRadius: 2,
                    color: "info.main",
                  }}
                >
                  リクエストを受け付けました。 <br></br>最大で{" "}
                  {formatTime(selectedRows.length * 28)}　かかる場合があります。
                  <br></br>
                  {remainingTime && formatTime(remainingTime)}
                  後にご確認ください。
                </Typography>
                <br />
                <Button
                  sx={{
                    marginTop: 5,
                  }}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => navigate("/allocation")}
                >
                  はい
                </Button>
              </>
            )}
          </Box>
        </Backdrop>
      )}
    </Box>
  );
};

MonthlyAllocationView.propTypes = {
  date: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default MonthlyAllocationView;
