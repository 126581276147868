import { Warning } from "@mui/icons-material";
import MessageIcon from "@mui/icons-material/Message";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { InputField, ModalPopup } from "views/molecules";
import { FlowDialog } from "../../FlowDialog";
import { WasteFlowDetailDialog } from "../../WasteFlowDetailDialog";
import { If } from "views/atoms";

const getWasteFullName = (waste) => {
  if (waste == null) {
    return "";
  }
  const format = (wasteClassName) =>
    !waste.name ? wasteClassName : `[${wasteClassName}] ${waste.name}`.trim();
  const { largeClass, middleClass, smallClass } = waste.type;
  return smallClass.name !== ""
    ? format(smallClass.name)
    : middleClass.name !== ""
    ? format(middleClass.name)
    : format(largeClass.name);
};

/**
 * タスクを入力するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  if (props.loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "85vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box className={props.classes.collect_content}>
        <InputField
          value={props.value?.record?.quantity}
          onChange={props.onChangeQuantity}
          error={props.error}
          label={getWasteFullName(props.task?.waste)}
          type="number"
          inputProps={{
            inputMode: "decimal",
          }}
          heplIcon={true}
          classOverride={props.classes.input_style_mobile}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {props.task?.waste?.quantityUnit?.name}
              </InputAdornment>
            ),
          }}
          onClickHeplIcon={props.wasteFlowDetailsOpener.toggle}
          otherActions={
            <Badge
              color={props.value?.record?.remarks ? "secondary" : undefined}
              variant="dot"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                marginRight: "20px",
              }}
            >
              <IconButton color="primary" onClick={props.remarksOpener.toggle}>
                <MessageIcon />
              </IconButton>
            </Badge>
          }
        />
        {props.task?.weightConvertedValue && (
          <Typography>{`重量換算係数 ${props.task?.weightConvertedValue} ${props.task?.weightConvertedValueUnit.name}/${props.task?.waste?.packing?.name}`}</Typography>
        )}
      </Box>
      <Box pl={5} pr={5}>
        <If condition={!props.value?.record?.wasteFlowId}>
          <Typography
            color="secondary"
            sx={{ display: "flex", alignItems: "center", mb: 1 }}
          >
            <Warning sx={{ mr: 1 }} />
              処理フローが選択されてません。
            </Typography>
        </If>
        <If condition={(props.options?.length ?? 0) > 1}>
          <Button fullWidth variant="outlined" onClick={props.flowOpener.toggle}>
            処理フローを選択する
          </Button>
        </If>
      </Box>
      {props.remarksOpener.open && (
        <ModalPopup
          open={props.remarksOpener.open}
          onClose={() => props.remarksOpener.toggle()}
        >
          <Box className={props.classes.remarks_modal_root}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5">{"備考"}</Typography>
              </Grid>
              <Grid item xs={12}>
                <InputField
                  value={props.value?.record?.remarks}
                  onChange={props.onChangeRemarks}
                  placeholder="特記事項があれば記述入力します。"
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </Box>
        </ModalPopup>
      )}
      {props.flowOpener.open && (
        <FlowDialog
          value={props.value?.record}
          open={props.flowOpener.open}
          onClose={() => props.flowOpener.toggle()}
          onChange={props.onSelectedFlow}
          options={props.options}
          wasteType={`${
            props.task?.waste?.name ||
            props.task?.waste?.type?.smallClass?.name ||
            props.task?.waste?.type?.middleClass?.name ||
            props.task?.waste?.type?.largeClass?.name
          }`}
        />
      )}
      {props.wasteFlowDetailsOpener.open && (
        <WasteFlowDetailDialog
          value={props.task}
          open={props.wasteFlowDetailsOpener.open}
          onClose={() => props.wasteFlowDetailsOpener.toggle()}
        />
      )}
    </>
  );
};
