import React from "react";
import { RenderButtonToolBar } from "utils/XgridExpand";
import { XGridComponent, FullScreenDialog } from "views/molecules";
import { PrecautionRegistrationForm } from "views/organisms/Forms/PrecautionRegistrationForm";

/**
 * 回収注意点タブの表示を行うプレゼンテーションコンポーネントです
 */
export const Presententer = (props) => (
  <div>
    <XGridComponent
      onChangeSelection={props.handleChangeGridSelection}
      checkboxSelection={false}
      multipleSelection={false}
      onDownload={true}
      actionButton={
        (
          props.workplace?.state ? props.workplace?.state !== "removed" : false
        ) ? (
          <RenderButtonToolBar
            label="新しい回収注意点を追加"
            onClick={props.handleOpenRegisterDialog}
          />
        ) : (
          <></>
        )
      }
      {...props.xGridParams}
    />
    <FullScreenDialog
      formId={"collection-precaution-form"}
      open={props.isOpenRegisterDialog}
      title={`新しい回収注意点の追加`}
      textConfirm={"登録"}
      onClose={props.handleCloseRegisterDialog}
      isSubmit={props.isSubmit}
      disabled={props.isSubmit}
    >
      <PrecautionRegistrationForm
        id={"collection-precaution-form"}
        onSubmit={props.handleSubmit}
      />
    </FullScreenDialog>
  </div>
);
