import React from "react";
import { LicenseTable } from "views/organisms/Masters/License";

/**
 * 許可証の情報を表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return <LicenseTable onSelected={props.onTransite} {...props} />;
};
