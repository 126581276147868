import {
  removeWasteLicense,
  updateIndustrialWasteLicense,
} from "api/graphql/mutations";
import { getIndustrialWasteLicense } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { companySelector } from "ducks/Company";
import { toggle } from "ducks/Loading";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toApiValue, toFormValue } from "utils/ImageFunctions";
import { debugLog } from "utils/log";
import { useMutation } from "utils/useMutation";

/**
 * 許可証の情報を詳しく表示するコンテナコンポーネントです。
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const company = useSelector(companySelector);
  const [license, setLicense] = useState(null);
  const [selectedPDF, setSelectedPDF] = useState(null);
  const [open, setOpen] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const dispatch = useDispatch();
  const queries = new URLSearchParams(useLocation().search);
  const fromAlert = queries.get("alert") !== null;

  const [runRemoveWasteLicense, runRemoveWasteLicenseState] = useMutation(
    removeWasteLicense,
    {
      onCompleted: (data) => {
        if (fromAlert) {
          navigate(
            {
              pathname: "/alert-licenses",
            },
            {
              replace: true,
            }
          );
        } else {
          navigate(`/master/license/waste`);
        }
      },
      succeedMessage: "削除しました。",
      errorMessage: "エラーが発生したため、削除できませんでした。",
    }
  );

  const loadLicense = useCallback(() => {
    dispatch(toggle(true));
    if (company) {
      API.graphql(
        graphqlOperation(getIndustrialWasteLicense, {
          companyId: company.id,
          id: id,
        })
      )
        .then((result) => {
          debugLog("industrialwastelicense.onLoad.succeeded", result);
          toFormValue({
            files: result.data.getIndustrialWasteLicense?.files,
          }).then((files) => {
            setLicense({
              ...result.data.getIndustrialWasteLicense,
              files: files,
            });
          });
        })
        .catch((error) => {
          debugLog("industrialwastelicense.onLoad.failed", error);
        })
        .finally(() => {
          dispatch(toggle(false));
        });
    }
  }, [dispatch, company, id]);

  useEffect(() => {
    loadLicense();
  }, [loadLicense]);

  const [runUpdateIndustrialWasteLicense, updateIndustrialWasteLicenseStatus] =
    useMutation(updateIndustrialWasteLicense, {
      onCompleted: (data) => {
        if (fromAlert) {
          navigate(
            {
              pathname: `/alert-licenses`,
            },
            {
              replace: true,
            }
          );
        } else {
          loadLicense();
          setOpen(false);
        }
      },
      succeedMessage: "更新しました。",
      errorMessage: "エラーが発生したため、更新できませんでした。",
    });

  const updateLicense = async (params) => {
    const {
      category,
      categoryName,
      licenseExpirationOn,
      licensedOn,
      japaneseLocalGovernment,
      certifiedAsExcellentOn,
      createdAt,
      updatedAt,
      version,
      state,
      prefectures,
      files,
      licensedCompanyId,
      inputType,
      ...other
    } = params;

    runUpdateIndustrialWasteLicense({
      input: {
        ...other,
        industrialWasteLicenseBusinessCategoryCode: category.code,
        japaneseLocalGovernmentId: japaneseLocalGovernment?.id,
        licenseExpirationOn:
          licenseExpirationOn &&
          DateTime.fromJSDate(new Date(licenseExpirationOn)).toFormat(
            "yyyy-MM-dd"
          ),
        licensedOn:
          licensedOn &&
          DateTime.fromJSDate(new Date(licensedOn)).toFormat("yyyy-MM-dd"),
        certifiedAsExcellentOn:
          certifiedAsExcellentOn &&
          DateTime.fromJSDate(new Date(certifiedAsExcellentOn)).toFormat(
            "yyyy-MM-dd"
          ),
        expectedVersion: version,
        prefecturesCode: prefectures.code,
        files: await toApiValue({ files: files }),
        isMapInput: inputType === "from-map"
      },
    });
  };

  const handelClickPDF = (index) => () => {
    setSelectedPDF(license?.files?.[index]?.url);
  };

  return render({
    value: license,
    open: open,
    isSubmit:
      updateIndustrialWasteLicenseStatus?.loading ||
      runRemoveWasteLicenseState?.loading,
    onOpenDialog: () => setOpen(true),
    onCloseDialog: () => setOpen(false),
    onSubmit: updateLicense,
    onDelete: () =>
      runRemoveWasteLicense({
        input: {
          id: id,
        },
      }),
    onClickPDF: handelClickPDF,
    selectedPDF: selectedPDF,
    onClosePDF: () => setSelectedPDF(null),
    isOpenDeleteDialog: isOpenDeleteDialog,
    toggleDeleteDialog: (v) => setIsOpenDeleteDialog(v),
    ...props,
  });
};
