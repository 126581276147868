import { useEffect } from "react";
import { debugLog } from "utils/log";
import { basicSchema } from "./Validations/basic";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const defaultValues = {
  code: undefined,
  industrySector: undefined,
  name: undefined,
  phone: undefined,
  fax: undefined,
  email: undefined,
};

/**
 * 取引先事業場を入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, onSubmit, onError = err => debugLog(err), ...props }) => {
  const { control, handleSubmit, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    reset({
      ...defaultValues,
      ...value,
    });
  }, [reset, value]);

  return render({
    submit: handleSubmit(onSubmit, onError),
    control: control,
    ...props,
  });
};
