import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 到着予定時間の通知を表示するコンポーネントです。
 * @param {string} value 到着予定
 * @returns {JSX.Element}
 */
export const ArrivalScheduleAlert = containerPresententer(
  Container,
  Presententer
);
