import { Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import {
  InputField,
  FieldDecorateFrame,
  DisposalMethodsSelector,
} from "views/molecules";

/**
 * 処分情報を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          control={props.control}
          name="disposalProcess.client"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="処分事業場"
              placeholder="最終区間の運搬先の事業場が設定されます。"
              value={value?.name ?? ""}
              onChange={onChange}
              error={error}
              required={true}
              InputProps={{
                readOnly: true
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={props.control}
          name="disposalProcess.method"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FieldDecorateFrame label="処分方法" required={true}>
              {(params) => (
                <DisposalMethodsSelector
                  value={value}
                  onChange={onChange}
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
            </FieldDecorateFrame>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={props.control}
          name="disposalProcess.details"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="処分方法の詳細"
              placeholder="選択した処分方法を詳しく分ける場合に入力します。"
              value={value}
              onChange={onChange}
              error={error}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
