import { useState } from "react";
import { useFormContext } from "react-hook-form";
import useClasses from "utils/useClasses";
import { styles } from "./styles";

/**
 * 基本情報を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, companyId, ...props }) => {
  const classes = useClasses(styles);
  const [open, setOpen] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);

  const { control, setValue, clearErrors, watch } = useFormContext();

  const handleSelectedClientWorkplace = (data) => {
    setOpen(false);
    setValue("dischargesWorkplace", data[0]);
    setValue("wastes", null);
  };

  const handleSelectedClient = (data) => {
    setSelectedValue(data[0]);
    setOpen(false);
    setValue("transporter", data[0]);
  };

  const handleChangeWastes = (e) => {
    setValue("wastes", e?.value?.[0]);
    clearErrors("wastes");
    setOpen(false);
  };

  return render({
    classes: classes,
    control: control,
    open: open,
    onOpenModal: (name) => () => setOpen(name),
    onCloseModal: () => setOpen(null),
    onSelectedClientWorkplace: handleSelectedClientWorkplace,
    onSelectedClient: handleSelectedClient,
    onChangeWastes: handleChangeWastes,
    selectedValue: selectedValue,
    companyId: companyId,
    dischargeSourceWorkplaceId: watch("dischargesWorkplace")?.id,
    ...props,
  });
};
