import { useImperativeHandle, useRef } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import useClasses from "utils/useClasses";
import { fromQueryString, toQueryString } from "utils/useXGridComponents";
import { styles } from "./styles";

/**
 * 取引先事業場廃棄物情報を表示するコンテナコンポーネントです。
 * @param {object} value
 * @fires Container#onChangeTab タブのナンバーを取得する
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onChangeTab,
  companyId,
  wasteId,
  ...props
}) => {
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const location = useLocation();
  const flowRef = useRef(null);
  const qs = new URLSearchParams(location.search);

  const handleChangeTab = (newTabNumber) => {
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams({ tab: newTabNumber })}`,
    });
    onChangeTab && onChangeTab(newTabNumber);
  };

  const handleSelectedFlow = (e) => {
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({
          ...Object.fromEntries(new URLSearchParams(location.search)),
          ...Object.fromEntries(
            new URLSearchParams(toQueryString(e.info.search))
          ),
        }).toString()}`,
      },
      {
        replace: true,
      }
    );

    navigate(`${location.pathname}/flow/${e?.value?.id}`);
  };

  useImperativeHandle(props._ref, () => ({
    refresh: () => {
      flowRef.current.refetch();
    },
  }));

  return render({
    ...props,
    tab: qs.get("tab"),
    value: value,
    classes: classes,
    onChangeTab: handleChangeTab,
    companyId: companyId,
    wasteId: wasteId,
    onSelectedFlow: handleSelectedFlow,
    flowRef: flowRef,
    initialState: fromQueryString(location.search),
  });
};
