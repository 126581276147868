import React from "react";
import { XGridComponent } from "views/molecules";
import { Button } from "@mui/material";

/**
 * 契約者を選択するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <XGridComponent
    value={props.value}
    autoHeight={props.autoHeight}
    onChangeSelection={props.onSelected}
    checkboxSelection={props.multiple}
    multipleSelection={props.multiple}
    onDownload={props.download}
    titleTable={props.title}
    actionButton={
      <Button variant="contained" onClick={props.onSelectedOwnCompany}>
        {"自社を選択する"}
      </Button>
    }
    {...props.xGridParams}
  />
);
