import { useRef } from "react";
import { useOpener } from "utils/useOpener";

/**
 * コンテンツ種類詳細を表示するコンテナコンポーネントです。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({
  render,
  value,
  isSubmit,
  onStopUsing,
  onResumeUse,
  onUpdate,
  onRemove,
  ...props
}) => {
  const stopUsingFormRef = useRef(null);
  const editFormRef = useRef(null);
  const stopDialog = useOpener();
  const stopConfirmDialog = useOpener();
  const resumeConfirmDialog = useOpener();
  const editDialog = useOpener();
  const historyDialog = useOpener();
  const removeDialog = useOpener();

  const handleUpdate = (data) => {
    onUpdate && onUpdate(data);
    editDialog.toggle(false);
  };

  const handleStopUsing = () => {
    onStopUsing && onStopUsing(stopUsingFormRef.current.get());
    stopDialog.toggle(false);
    stopConfirmDialog.toggle(false);
  };

  const handleResumeUse = () => {
    onResumeUse && onResumeUse();
    resumeConfirmDialog.toggle(false);
  };

  return render({
    ...props,
    value: value,
    handleUpdate: handleUpdate,
    handleStopUsing: handleStopUsing,
    handleResumeUse: handleResumeUse,
    historyDialog: historyDialog,
    editDialog: editDialog,
    resumeConfirmDialog: resumeConfirmDialog,
    stopConfirmDialog: stopConfirmDialog,
    stopDialog: stopDialog,
    editFormRef: editFormRef,
    isSubmit: isSubmit,
    stopUsingFormRef: stopUsingFormRef,
    removeDialog: removeDialog,
    onRemove: onRemove,
  });
};
