import React from "react";
import { Link as RouterLink, matchPath } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";

const LinkRoute = (props) => <Link {...props} component={RouterLink} />;

/**
 * パンくずリストを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 */
export const Presententer = (props) => {
  return props.matchRoutes.length > 0 ? (
    <Breadcrumbs aria-label="breadcrumb" className={props.classes.breadcrumbs}>
      {props.matchRoutes.map((route, index) =>
        matchPath(route.path, props.pathname) ? (
          <Typography key={index}  className={props.classes.link} >
            {route.icon}
            {route.title}
          </Typography>
        ) : (
          <LinkRoute key={index} to={route.to} color="inherit" className={props.classes.link} >
            {route.icon}
            {route.title}
          </LinkRoute>
        )
      )}
    </Breadcrumbs>
  ) : (
    <></>
  );
};
