import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 事業場情報(基本情報・ギャラリー・車両)を表示するコンポーネントです。
 * @returns {JSX.Element}
 */
export const WorkplaceDetailsTabs = containerPresententer(
  Container,
  Presententer
);
