import { containerPresententer } from "utils/HoC";
import {Container} from "./Container";
import {Presententer} from "./Presententer";

/**
 *　テキストフィールドを表示するコンポーネントです
 *  inputの周りにボーダーします。
 * @param {string} label テキストのレベル
 * @param {funct} onChange データ更新を処理する
 * @param {boolean} required 必須かどうか設定
 * @param {string} value テキストフィールドのバリュー
 * @param {object} error エラー
 * 
 * @param {boolean} heplIcon 感嘆符アイコン 例：true
 * @param {string} classOverride 編集したい時クラス名
 * @param {string} onClickHeplIcon スマホのアイコンをクリックするときイベント
 * @returns {JSX.Elemnt} JSX 要素
 */
export const InputField = containerPresententer(Container, Presententer);
