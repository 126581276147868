import { Container, Grid, Paper } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Controller } from "react-hook-form";
import { FileGallery, InputField, Uploader } from "views/molecules";

/**
 * コンテナ種類のフォームを表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 */
export const Presententer = (props) => {
  return (
    <Container maxWidth="md">
      <Paper
        elevation={3}
        sx={{
          p: 5,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Controller
              name="number"
              control={props.control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <InputField
                  label="管理番号"
                  required={false}
                  placeholder="管理番号を入力します。"
                  value={value}
                  onChange={onChange}
                  error={error}
                  disabled={props.loading}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={props.control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <InputField
                    label="名称"
                    placeholder="名称を入力します。"
                    required={true}
                    value={value}
                    onChange={onChange}
                    error={error}
                    disabled={props.loading}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="manufacturerName"
              control={props.control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <InputField
                    label="メーカー"
                    placeholder="メーカーの名称を入力します。"
                    required={true}
                    value={value}
                    onChange={onChange}
                    error={error}
                    disabled={props.loading}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="modelNumber"
              control={props.control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <InputField
                    label="品番"
                    placeholder="品番を入力します。"
                    required={false}
                    value={value}
                    onChange={onChange}
                    error={error}
                    disabled={props.loading}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="width"
              control={props.control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <InputField
                    label="幅(mm)"
                    placeholder="幅(mm)を入力します。"
                    required={false}
                    value={value}
                    onChange={onChange}
                    error={error}
                    endAdornment={
                      <InputAdornment position="end">mm</InputAdornment>
                    }
                    disabled={props.loading}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="height"
              control={props.control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <InputField
                    label="高さ(mm)"
                    placeholder="高さ(mm)を入力します。"
                    required={false}
                    value={value}
                    onChange={onChange}
                    error={error}
                    endAdornment={
                      <InputAdornment position="end">mm</InputAdornment>
                    }
                    disabled={props.loading}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="depth"
              control={props.control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <InputField
                    label="奥行(mm)"
                    placeholder="奥行(mm)を入力します。"
                    required={false}
                    value={value}
                    onChange={onChange}
                    error={error}
                    endAdornment={
                      <InputAdornment position="end">mm</InputAdornment>
                    }
                    disabled={props.loading}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="volume"
              control={props.control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <InputField
                    label="容積(㎥)"
                    placeholder="容積(㎥)を入力します。"
                    required={false}
                    value={value}
                    onChange={onChange}
                    error={error}
                    endAdornment={
                      <InputAdornment position="end">㎥</InputAdornment>
                    }
                    disabled={props.loading}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="remarks"
              control={props.control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <InputField
                  label="備考"
                  value={value}
                  onChange={onChange}
                  error={error}
                  multiline={true}
                  rows={3}
                  placeholder="特記事項があれば記載します。"
                  disabled={props.loading}
                />
              )}
            />
          </Grid>
          <Grid item sx={12}>
            <Controller
              name="files"
              control={props.control}
              render={({ field: { value, onChange } }) => (
                <>
                  <Uploader
                    disabled={props.loading}
                    fullWidth={true}
                    maxSize={26214400}
                    onChange={(files) => {
                      onChange([...(value ?? []), ...files]);
                    }}
                    dropzoneText="最大ファイルサイズ:25MB、ファイル形式:JPEG、PDF"
                    accept={[".pdf", ".jpg", ".jpeg"]}
                  />
                  <FileGallery
                    disabled={props.loading}
                    value={value}
                    onChange={onChange}
                  />
                </>
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
