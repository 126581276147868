import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { minutesToMilliseconds } from "utils/useInterval";
import {
  versionCompare,
  EQUAL_TO,
  LESS_THAN,
  GREATER_THAN,
} from "./VersionCompare";
import packageJson from "../../../../package.json";
import { useServiceWorker } from "utils/useServiceWorker";

/**
 * キャッシュを破壊するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.url キャッシュを破壊する基準とするリソースのURL
 * @returns {JSX.Element}
 */
export const CacheBuster = ({ url }) => {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (showReload && waitingWorker) {
      setShow(true);
    } else setShow(false);
  }, [waitingWorker, showReload, reloadPage]);

  console.info(`システムバージョン: ${packageJson.version}`);

  const fetchSource = () => {
    return fetch(`${url}?${DateTime.now().toFormat("yyyymmddhhmmss")}`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("offline");
        }
        return response.text();
      })
      .then((version) => {
        switch (versionCompare(packageJson.version, version)) {
          case EQUAL_TO:
            break;
          case GREATER_THAN:
          case LESS_THAN:
            setShow(true);
            return;
          default:
            break;
        }
      });
  };

  useEffect(() => {
    if (!("serviceWorker" in navigator)) {
      fetchSource();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reload = (e) => {
    if ("serviceWorker" in navigator) {
      reloadPage();
    } else {
      window.location.reload();
      e?.preventDefault?.();
    }
  };

  if (!("serviceWorker" in navigator)) {
    setInterval(() => {
      fetchSource();
    }, [minutesToMilliseconds(1)]);
  }

  if (!show) {
    return <></>;
  }

  return (
    <Dialog
      open={show}
      aria-labelledby="update-notification-title"
      aria-describedby="update-notification-description"
    >
      <DialogTitle id="update-notification-title">
        {"システム更新通知"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="update-notification-description">
          {"システムが最新版に更新されました。"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={reload} autoFocus>
          {"再読み込み"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
