import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  collect_timeline: {
    margin: 0,
    padding: "0",
    "& .MuiTimelineItem-missingOppositeContent:before": {
      display: "none",
      flex: 0,
    },
    "& .MuiTimelineItem-root": {
      minHeight: theme.spacing(6),
      padding: "0 16px",
      borderTop: "1px solid",
      borderTopColor: theme.palette.text.disabled,
      overflow: "hidden",
    },
    "& .MuiTimelineContent-root": {
      paddingLeft: theme.spacing(1),
    },
  },
  itemClone: {
    boxShadow : theme.shadows[4]
  },
}));
