import yup, { textSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  number: textSchema.nullable(),
  name: textSchema.nullable(),
  targetWaste: yup.object().required(),
  targetWasteKind: textSchema.nullable(),
  transportProcesses: yup
    .array(
      yup
        .object()
        .shape({
          section: yup.number().required(),
          trustee: yup.object().required(),
          method: yup.object().required(),
          destination: yup.object().required(),
        })
        .required()
    )
    .required(),
  disposalProcess: yup
    .object()
    .shape({
      client: yup.object().required(),
      method: textSchema.required(),
      details: textSchema.nullable(),
    })
    .required(),
  lastDisposalWorkplaces: yup
    .array()
    .nullable(),
});
