import React from "react";
import { InformationItem } from "views/atoms";
import { FileGallery } from "views/molecules";

/**
 * コンテナ管理の種類詳細を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @return {JSX.Element}
 */
export const Presententer = (props) => {
  const { value } = props;

  return (
    <>
      <InformationItem label="管理番号">{value?.number ?? ""}</InformationItem>
      <InformationItem label="メーカー">
        {value?.manufacturerName ?? ""}
      </InformationItem>
      <InformationItem label="品番">{value?.modelNumber ?? ""}</InformationItem>
      <InformationItem label="備考">{value?.remarks ?? ""}</InformationItem>
      <InformationItem label="添付ファイル">
        <FileGallery value={value?.files} disabled={true} />
      </InformationItem>
    </>
  );
};
