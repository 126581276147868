import { companySelector } from "ducks/Company";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { fromQueryString, toQueryString } from "utils/useXGridComponents";

/**
 * 引数を受けて、JSX.Elementを返します
 * @param {props} props
 * @callback render
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const company = useSelector(companySelector);
  const handleTransite = (e) => {
    navigate(
      {
        pathname: location.pathname,
        search: toQueryString(e?.info?.search),
      },
      {
        replace: true,
      }
    );
    navigate(`${location.pathname}/${e?.value?.[0].id}`);
  };

  return render({
    companyId: company.id,
    onSelected: handleTransite,
    initialState: {
      sort: {
        direction: "asc",
        field: "modelName",
      },
      ...fromQueryString(location.search),
    },
    ...props,
  });
};
