import { debugLog } from "utils/log";

/**
 * 処理フローの詳細を表示するコンテナコンポーネントです。
 * @param {object} props プロパティ
 * @returns {func} 描画関数
 */
export const Container = ({
  render,
  value,
  open = false,
  onClose = debugLog,
  ...props
}) => {
  return render({
    value: value,
    open: open,
    onClose: onClose,
    ...props,
  });
};
