import yup, { textSchema, remarksSchema, dateSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  number: textSchema.required(),
  modelName: textSchema.required(),
  parkingCertificateOwnerWorkplace: yup.object().nullable().required(),
  loadCapacity: yup
    .number()
    .integer()
    .positive()
    .max(999999)
    .required()
    .nullable(),
  totalWeight: yup
    .number()
    .integer()
    .positive()
    .max(999999)
    .required()
    .nullable(),
  ridingCapacity: yup
    .number()
    .integer()
    .max(999)
    .positive()
    .required()
    .nullable(),
  purchasedOn: dateSchema,
  firstYearRegistrationOn: dateSchema,
  // driversLicense: yup.object().required().nullable(),
  remarks: remarksSchema,
});
