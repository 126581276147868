import clsx from "clsx";

/**
 * 詳細ページの表題を表示するプレゼンテーションコンポーネントです。
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <div className={clsx(props.classes.caption, props.className)}>
      <div className="title-area">
        <span className="title">{props.title}</span>
      </div>
      {props.subTitle && (
        <div className="subTitle-area">
          <span className="subTitle">{props.subTitle}</span>
        </div>
      )}
    </div>
  );
};
