import QueryBuilder from "@mui/icons-material/QueryBuilder";
import { Box, Button } from "@mui/material";
import React from "react";
import { isBefore, parseISO } from "date-fns";
import { DayOfWeek, Week } from "utils/Context";

/**
 * The function checks if a given date is expired or not.
 * @param inputDate - The inputDate parameter is a string representing a date in ISO format (e.g.
 * "2022-01-01T00:00:00.000Z").
 * @returns The function `isDateExpire` is returning a boolean value indicating whether the input date
 * is before the current date and time (`now`). If the input date is before the current date and time,
 * the function will return `true`, indicating that the date has expired. If the input date is on or
 * after the current date and time, the function will return `false`, indicating that the date has not
 */
const isDateExpire = (inputDate) => {
  const now = new Date();
  const parsedInputDate = parseISO(inputDate);
  return isBefore(parsedInputDate, now);
};

const getCollectCycleTypeNameFromCycle = (cycle) => {
  if (!cycle) {
    return <></>;
  }
  switch (cycle.cycleCategoryId) {
    case "everyDay":
      return cycle.cycleCategoryName;
    case "everyWeek":
      return `${cycle.cycleCategoryName} ${cycle.weekDays
        .map(
          (weekday) => DayOfWeek.find((item) => item.code === weekday).shortName
        )
        .join("・")}`;
    case "everyOtherWeek":
      return `${cycle.cycleCategoryName} ${cycle.weekDays
        .map(
          (weekday) => DayOfWeek.find((item) => item.code === weekday).shortName
        )
        .join("・")} ${cycle.weeks
        .map((week) => Week.find((item) => item.code === week).name)
        .join("・")}`;
    case "everyMonth":
      return `${cycle.cycleCategoryName} ${cycle.weeks
        .map((week) => Week.find((item) => item.code === week).name)
        .join("・")}`;
    case "customize":
      return ``;
    default:
      return ``;
  }
};

/**
 * 1回収場所を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box
      boxShadow={2}
      bgcolor="#fff"
      borderRadius={1}
      p={1}
      mt={2}
      mr={1}
      fontSize="14px"
    >
      {props.value?.order && (
        <Box bgcolor="text.primary" className={props.classes.box__number}>
          {props.value.order ?? ""}
        </Box>
      )}
      <Box display="flex" alignItems="center">
        <Box
          fontWeight="fontWeightBold"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {props?.value?.wasteGeneratorCompany?.name ?? ""}
        </Box>
      </Box>
      <Box
        fontWeight="fontWeightBold"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {props?.value?.wasteCollectionWorkplace?.name ?? ""}
      </Box>
      <Box
        color="text.secondary"
        sx={{
          fontSize: "12px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {`${
          props?.value?.waste?.type?.smallClass?.name ||
          props?.value?.waste?.type?.middleClass?.name ||
          props?.value?.waste?.type?.largeClass?.name ||
          ""
        }`}
      </Box>
      <Box
        color="text.secondary"
        sx={{
          fontSize: "12px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {props?.value?.waste?.name
          ? `${props?.value?.waste?.name}(${props?.value?.waste?.dischargeAmountPerOneTime}${props?.value?.waste?.quantityUnit?.name})`
          : `${props?.value?.waste?.dischargeAmountPerOneTime}${props?.value?.waste?.quantityUnit?.name}`}
      </Box>
      <Box
        color="text.secondary"
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <QueryBuilder
          fontSize="small"
          sx={{ marginRight: (theme) => theme.spacing(1) }}
        />
        <Box>
          <Box
            color="text.secondary"
            sx={{
              fontSize: "11px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >{`${getCollectCycleTypeNameFromCycle(props.value)} `}</Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              fontSize: "11px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <span>{`${
              props.value?.scheduleTimeRangeStart
                ? props.value?.scheduleTimeRangeStart.substring(0, 5)
                : ""
            } ～ ${
              props.value?.scheduleTimeRangeEnd
                ? props.value?.scheduleTimeRangeEnd.substring(0, 5)
                : ""
            }`}</span>
          </Box>
        </Box>
      </Box>
      {isDateExpire(props.value.cycleEndDate) && (
        <Button
          sx={{
            cursor: "default",
            position: "absolute",
            height: "23px",
            bottom: "5px",
            right: "19px",
          }}
          size="small"
          variant="outlined"
          color="error"
        >
          終了分
        </Button>
      )}
    </Box>
  );
};
