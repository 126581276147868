import { getAuthenticatedUserAccount } from "api/graphql/queries";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { signOut } from "aws-amplify/auth";

/**
 * ユーザー情報を取得します。
 * @returns {object}
 */
const fetch = async () => {
  try {
    const fetchedData = await API.graphql(
      graphqlOperation(getAuthenticatedUserAccount)
    );
    return fetchedData.data.getAuthenticatedUserAccount;
  } catch (error) {
    signOut();
  }
};

export const fetchUser = createAsyncThunk("user/fetch", async (_, thunkApi) => {
  return fetch();
});
