import { WarningOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import { FixedSizeList } from "react-window";
import { RenderColumnCourse } from "../Courses";

const ScreenHeight = 550;
/**
 * 回収コース一覧の表示を行うコンテナプレゼンテーションです
 * @param {object} classes クラス名
 * @param {object} value　回収コース
 * @param {func} onClickbutton　回収を開始するときイベント
 * @returns
 */

export const Presententer = (props) => {
  return (
    <Box
      className={props.classes.allocation_schedule}
      sx={{
        height:
          props?.value && props?.value?.length > 0 ? ScreenHeight : "auto",
      }}
    >
      {props?.value && props?.value?.length > 0 ? (
        <FixedSizeList
          height={ScreenHeight}
          itemCount={props.value?.length}
          itemData={{
            items: props.value,
            displayNumber: true,
          }}
          itemSize={270}
          width={900}
          layout="horizontal"
          className={"override_srcoll"}
        >
          {RenderColumnCourse(props.onClickCourses)}
        </FixedSizeList>
      ) : (
        <Box boxShadow={3} p={3} borderRadius={1}>
          <Typography
            color="secondary"
            sx={{ display: "flex", alignItems: "center", mb: 2 }}
          >
            <WarningOutlined
              fontSize="large"
              color="secondary"
              sx={{ mr: 1 }}
            />
            未配車の回収スケジュールがあります。
          </Typography>
        </Box>
      )}
    </Box>
  );
};
