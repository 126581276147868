import { useState, useEffect } from "react";
import { useStyles } from "./styles";

/**
 * 引数を受けて、JSX Elementを返します
 * @param {object} value 事業場情報
 * @param {array} tabs タブの内容
 * @param {object} tabs.name タブの内容
 * @param {object} tabs.tabContent タブの内容
 * @callback render
 * @returns JSX.Element
 */
export const Container = ({
  render,
  value,
  onChangeTab,
  tabs = [],
  ...props
}) => {
  const classes = useStyles();
  const [tabNumber, setTabNumber] = useState(0);

  useEffect(() => {
    setTabNumber(value ? parseInt(value) : 0);
  }, [value]);

  const handleChangeTab = (event, newTabNumber) => {
    event.preventDefault();
    setTabNumber(newTabNumber);
    if (onChangeTab && typeof onChangeTab == "function")
      onChangeTab(newTabNumber, tabs[newTabNumber]);
  };

  return render({
    ...props,
    classes: classes,
    tabNumber: tabNumber,
    onChangeTab: handleChangeTab,
    tabs: tabs,
  });
};
