import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { Avatar, Button, Stack } from "@mui/material";
import React from "react";
import { FullScreenDialog, TabsDetails, ConfirmDialog } from "views/molecules";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { Caption } from "views/organisms/DetailsFrame/ChildComponents";
import { VehicleForm } from "views/organisms/Masters/Vehicle";
import { TabSimpleInformation } from "views/organisms/Masters/Vehicle/VehicleDetailTabs/ChildComponents";
import { If } from "views/atoms";

/**
 * 事業場情報を詳しく表示するプレゼンテーションコンポーネントです。
 * @param {object} props
 * @param {objetc} value 事業場情報
 * @param {array} galleries ギャラリーデフォルト値
 * @param {boolean} open 事業所。事業場情報を編集するダイアログを有無に設定する
 * @param {func} onOpenDialog ダイアログを開くイベント
 * @param {func} onCloseDialog ダイアログを閉じるイベント
 * @param {number} tab タブ＝０の場合ボータンが表示する
 * @param {func} getTab タブのナンパ―を取得する
 * @returns　JSX.Element
 */
export const Presententer = (props) => {
  const { value, open, isSubmit, onOpenDialog, onCloseDialog, onSubmit } =
    props;
  return (
    <div>
      <DetailsFrame
        caption={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                marginRight: 3,
                width: 58,
                height: 58,
              }}
            >
              <DirectionsCarIcon sx={{ fontSize: "2.5rem" }} />
            </Avatar>
            <div>
              <Caption title={value?.number} subTitle={value?.modelName} />
            </div>
          </div>
        }
        actions={
          <If condition={props.value?.state === "active"}>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="error"
                onClick={() => props.confirm.toggle(true)}
                disabled={!props.value || props.isSubmit}
              >
                削除する
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onOpenDialog}
                disabled={!props.value || props.isSubmit}
              >
                編集する
              </Button>
            </Stack>
          </If>
        }
      >
        <TabsDetails
          tabs={[
            {
              name: "基本情報",
              tabContent: <TabSimpleInformation value={value} />,
            },
            // UNDONE: 12月リリースでは隠す
            // {
            //   name: "ギャラリー",
            //   tabContent: <TabGallery galleries={[]} />,
            // },
          ]}
        />
        <FullScreenDialog
          open={open}
          formId="vehicle-form"
          title="車輛情報の編集"
          textConfirm="保存"
          onClose={onCloseDialog}
          isSubmit={isSubmit}
          disabled={isSubmit}
        >
          <VehicleForm
            id="vehicle-form"
            value={value}
            onSubmit={onSubmit}
            companyId={props.company?.id}
          />
        </FullScreenDialog>
      </DetailsFrame>
      <ConfirmDialog
        title="削除します。よろしいですか？"
        open={props.confirm.open}
        positiveText={"削除"}
        negativeText={"キャンセル"}
        onPositive={props.onRemove}
        onNegative={() => props.confirm.toggle(false)}
        onClose={() => props.confirm.toggle(false)}
        disabled={props.isSubmit}
      />
    </div>
  );
};
