import { Box, IconButton } from "@mui/material";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import React from "react";
import { Mark } from "views/atoms";

/**
 * フィールドコンポーネントの装飾を表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = ({
  theme,
  label,
  required,
  help,
  onClickHelp,
  other,
  classes,
  ...props
}) => {
  return (
    <Box display="flex" flexDirection="column">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {((label !== "" && label !== null) || required === true || other !== null) && (
          <div>
            <span className={classes.label}>{label}</span>
            {required && (
              <Mark
                backgroundColor={theme.palette?.required?.main}
                text="必須"
              />
            )}
            {other}
          </div>
        )}
        {help && (
          <IconButton
            onClick={onClickHelp}
            className={classes.iconButton}
            color="primary"
          >
            <ErrorOutlineOutlined />
          </IconButton>
        )}
      </div>
      <div>
        {props.children({
          label: label,
          required: required,
        })}
      </div>
    </Box>
  );
};
