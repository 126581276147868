import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * モバイルのタブデフォルトを表示するコンテナプレゼンテーションです
 * @param {func} getTab タブのナンバーを取得する
 * @param {array} tabs タブの内容
 * @param {object} tabs.name タブの内容
 * @param {JSX} tabs.icon タブのアイコン
 * @param {object} tabs.tabContent タブの内容
 */
export const TabsMobile = containerPresententer(Container, Presententer);
