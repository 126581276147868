import { updateWorkplace, removeWorkplace } from "api/graphql/mutations";
import { getWorkplace } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { companySelector } from "ducks/Company";
import { toggle } from "ducks/Loading";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { PhoneNumberFormatUtil } from "utils/format";
import { debugLog } from "utils/log";
import { useMutation } from "utils/useMutation";
import { useNavigate } from "react-router";

/**
 * 事業場詳細を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const { id } = useParams();
  const company = useSelector(companySelector);
  const [workplace, setWorkplace] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const load = useCallback(() => {
    dispatch(toggle(true));
    API.graphql(
      graphqlOperation(getWorkplace, {
        id: id,
      })
    )
      .then((result) => {
        debugLog("Workplace.onLoad.succeeded", result.data.getWorkplace);
        const { phone, fax, ...other } = result.data.getWorkplace;
        setWorkplace({
          ...other,
          phone: PhoneNumberFormatUtil.formatNational(phone),
          fax: PhoneNumberFormatUtil.formatNational(fax),
        });
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(toggle(false));
      });
  }, [dispatch, id]);

  useEffect(() => {
    if (company && id) {
      load();
    }
  }, [load, company, id]);

  const isHeadOffice = () => {
    if (!company?.headOfficeWorkplace?.id) {
      return false;
    }

    return workplace && workplace.id === company.headOfficeWorkplace.id;
  }

  const executeUpdateWorkplace = (data, successCallback, failureCallback) => {
    const {
      belongInCompanyId,
      categories,
      industrySector,
      prefectures,
      phone,
      fax,
      createdAt,
      updatedAt,
      version,
      profileVersion,
      state,
      addressVersion,
      contactDetailsVersion,
      email,
      ...other
    } = data;

    API.graphql(
      graphqlOperation(updateWorkplace, {
        input: {
          ...other,
          categoryIds: categories?.map((category) => category.id),
          email: email ? email : null,
          industrySectorId: industrySector?.id,
          prefecturesCode: prefectures.code,
          phone: phone
            ? PhoneNumberFormatUtil.formatInternational(phone)
            : null,
          fax: fax ? PhoneNumberFormatUtil.formatInternational(fax) : null,
          expectedVersion: version,
          expectedProfileVersion: profileVersion,
          expectedAddressVersion: addressVersion,
          expectedContactDetailsVersion: contactDetailsVersion,
        },
      })
    )
      .then((result) => {
        debugLog("Workplace.onUpdate.succeeded", result.data.updateWorkplace);
        API.graphql(
          graphqlOperation(getWorkplace, {
            id: id,
          })
        )
          .then((result2) => {
            const { phone, fax, ...other } = result2.data.getWorkplace;
            setWorkplace({
              ...other,
              phone: PhoneNumberFormatUtil.formatNational(phone),
              fax: PhoneNumberFormatUtil.formatNational(fax),
            });
          })
          .catch((error) => {});
        if (successCallback && typeof successCallback == "function")
          successCallback(result);
      })
      .catch((error) => {
        debugLog("Workplace.onUpdate.failed", error);
        if (failureCallback && typeof failureCallback == "function")
          failureCallback(error);
      })
      .finally(() => {
        dispatch(toggle(false));
      });
  };

  const [removeMutation, removeMutationStatus] = useMutation(removeWorkplace, {
    onCompleted: (data) => {
      navigate(`/master/workplace`);
    },
    succeedMessage: "削除しました。",
    errorMessage: "エラーが発生したため、削除できませんでした。",
  });

  return render({
    value: workplace,
    isHeadOffice: isHeadOffice(),
    updateWorkplace: executeUpdateWorkplace,
    load: load,
    loading: removeMutationStatus.loading,
    removeMutation: removeMutation,
    ...props,
  });
};
