import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
} from "@mui/material";

/**
 * ラジオグループを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.name 名前
 * @param {string} props.label ラベル
 * @param {string} props.value 値
 * @fires RadioGroup#onChange 変更時
 * @param {array} props.options 選択項目
 * @param {boolean} props.error エラー
 * @param {string} props.helperText 補助的テキスト
 * @param {boolean} props.row 横並びにするかどうか
 * @returns {JSX.Element}
 */
export const RadioGroup = ({
  name = "",
  label = "",
  value,
  onChange,
  options = [],
  error = false,
  helperText = "",
  row = false,
  required = false,
  disabled = false,
}) => {
  return (
    <FormControl disabled={disabled}>
      <FormLabel
        id="radio-group-label"
        sx={{
          fontSize: "14px",
          ...(required === true
            ? {
                "&:after": {
                  color: "#fff",
                  content: `"必須"`,
                  backgroundColor: "#f44336",
                  fontSize: "12px",
                  marginLeft: "8px",
                  padding: "3px 6px",
                  borderRadius: "4px",
                },
              }
            : null),
        }}
      >
        {label}
      </FormLabel>
      <MuiRadioGroup
        aria-labelledby="radio-group-label"
        name={name}
        value={value}
        onChange={onChange}
        row={row}
      >
        {options?.map((option, index) => {
          return (
            <FormControlLabel key={index} control={<Radio />} {...option} />
          );
        })}
      </MuiRadioGroup>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};
