import yup, { textSchema } from "utils/yup";

export const licenseSchema = yup.object().shape({
  number: yup
    .string()
    .required()
    .transform((value) =>
      value && value.replace(/[^0-9]/g, "") !== "" ? value : ""
    )
    .test("len", "11桁で入力してください。", (value) => {
      if (value) {
        return value.replace(/[^0-9]/g, "").length === 11;
      }
      return true;
    }),
  japaneseLocalGovernment: yup.object().required(),
  category: yup
    .object()
    .shape({
      code: yup.string().required(),
      name: yup.string().required(),
    })
    .test("required", "許可番号を入力してください。", function (value) {
      return this.parent.number.replace(/[^0-9]/g, "").length >= 4;
    })
    .test("notFound", "許可番号の4桁目に誤りがあります。", function (value) {
      return this.parent.number.replace(/[^0-9]/g, "").length >= 4 && value;
    }),
  licensedOn: yup
    .date()
    .required()
    .nullable()
    .default(null)
    .transform((value) =>
      value instanceof Date && !isNaN(value) ? value : null
    )
    .test("futureDate", "未来日が指定されています。", (value) => {
      if (value) {
        return value <= new Date();
      }
      return true;
    }),
  licenseExpirationOn: yup
    .date()
    .required()
    .nullable()
    .default(null)
    .transform((value) =>
      value instanceof Date && !isNaN(value) ? value : null
    )
    .test(
      "dateRange",
      "許可の年月日以前の日付が指定されています。",
      function (value) {
        if (value && this.parent.permittedOn) {
          return value > Date.parse(this.parent.permittedOn);
        }
        return true;
      }
    ),
  certifiedAsExcellentOn: yup
    .date()
    .nullable()
    .default(null)
    .transform((value) =>
      value instanceof Date && !isNaN(value) ? value : null
    )
    .test("futureDate", "未来日が指定されています。", (value) => {
      if (value) {
        return value <= new Date();
      }
      return true;
    }),
  companyName: yup.string().required(),
  postalCode: yup.string().postalCode().required(),
  prefectures: yup.object().required(),
  city: textSchema.required(),
  streetAddress: textSchema.required(),
  otherAddress: textSchema.required(),
  remarks: yup.string(),
  files: yup.array().nullable(),
});
