import {
  CommuteOutlined,
  DescriptionOutlined,
  DomainOutlined,
  DoubleArrowOutlined,
  PeopleOutlineOutlined,
  PersonOutlined,
  TakeoutDiningOutlined,
} from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import React from "react";
import { RenderMenuSelectItem, StepGuider } from "views/atoms";
import RuleIcon from "@mui/icons-material/Rule";

/**
 * マスタ管理画面を表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  if (props.error) {
    <Typography>{"エラーが発生しました。"}</Typography>;
  }

  if (props.loading) {
    return <></>;
  }

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: 0 }}>
        <Grid item>
          <RenderMenuSelectItem
            icon={PersonOutlined}
            label="自社事業者"
            className="corporation"
            onClick={() => props.onClick("corporation")}
          />
        </Grid>
        <Grid item>
          <RenderMenuSelectItem
            icon={DomainOutlined}
            label="自社事業場"
            className="workplace"
            onClick={() => props.onClick("workplace")}
          />
        </Grid>
        <Grid item>
          <RenderMenuSelectItem
            icon={DescriptionOutlined}
            label="許可証"
            className="license"
            onClick={() => props.onClick("license/waste")}
          />
        </Grid>
        <Grid item>
          <RenderMenuSelectItem
            icon={CommuteOutlined}
            label="車輌"
            className="vehicle"
            onClick={() => props.onClick("vehicle")}
          />
        </Grid>
        <Grid item>
          <RenderMenuSelectItem
            icon={DoubleArrowOutlined}
            label="処理フロー"
            className="flow"
            onClick={() => props.onClick("flow")}
          />
        </Grid>
        <Grid item>
          <RenderMenuSelectItem
            icon={PeopleOutlineOutlined}
            label="取引先"
            className="partner"
            onClick={() => props.onClick("partner")}
          />
        </Grid>
        {props.isGeneralWastesPlan === false && (
          <Grid item>
            <RenderMenuSelectItem
              icon={TakeoutDiningOutlined}
              label="コンテナ"
              className="container/category/list"
              onClick={() => props.onClick("container/category/list")}
            />
          </Grid>
        )}
        <Grid item>
          <RenderMenuSelectItem
            icon={RuleIcon}
            label="チェックシート"
            className="checksheets"
            onClick={() => props.onClick("checksheets")}
          />
        </Grid>
      </Grid>
      <StepGuider value={props.GuiderSteps} />
    </>
  );
};
