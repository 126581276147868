import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  main_title: {
    fontSize: "16px",
    fontWeight: "bold",
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
  },
  sub_title: { fontSize: "14px", color: theme.palette.text.secondary },
}));
