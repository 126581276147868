import { minutesToMilliseconds } from "date-fns";
import { useInterval } from "./useInterval";
import {
  getAuthenticatedUserAccount,
  getPlanSubscriber,
} from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { useEffect, useState } from "react";
import { isGeneralWastesPlan } from "./Restricts";
import { useSignOut } from "utils/useSignOut";

/**
 * システムは解約済みで閉じられているか
 * @param {object} value プラン
 * @returns {boolean}
 */
const isClosedSystem = (value) => {
  if (!value) {
    return false;
  }

  return !!value?.endedUsingAt;
};

/**
 * プランが変更されたか
 * @param {object} current 今のプラン
 * @param {object} latest 最新のプラン
 * @returns {boolean}
 */
const isChangedPlan = (current, latest) => {
  return current?.plan?.name !== latest?.plan?.name;
};

/**
 * システムのプランを監視するフックです。
 */
export const usePlanMonitoring = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentPlanSubscriber, setCurrentPlanSubscriber] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { signOut } = useSignOut();

  /**
   * 現在のユーザを読み込みます。
   * @returns {Promise}
   */
  const loadCurrentUser = () => {
    return API.graphql(graphqlOperation(getAuthenticatedUserAccount))
      .then((response) => response.data.getAuthenticatedUserAccount)
      .catch((error) => error);
  };

  /**
   * プランの加入者を読み込みます。
   * @returns {Promise}
   */
  const loadPlanSubscriber = (planSubscriberId) => {
    if (!planSubscriberId) {
      return null;
    }

    return API.graphql(
      graphqlOperation(getPlanSubscriber, {
        id: planSubscriberId,
      })
    )
      .then((response) => response.data.getPlanSubscriber)
      .catch((error) => error);
  };

  const forceSignOutThenSystemClose = (plan) => {
    if (isClosedSystem(plan)) {
      signOut();
      return;
    }
  };

  /**
   * 初回ロード処理です。
   */
  const firstLoad = async () => {
    setLoading(true);
    try {
      const user = await loadCurrentUser();
      setCurrentUser(user);
      const planSubscriber = await loadPlanSubscriber(user.planSubscriberId);
      forceSignOutThenSystemClose(planSubscriber);
      setCurrentPlanSubscriber(planSubscriber);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * モニタリングします。
   */
  const monitoring = async () => {
    const latestPlanSubscriber = await loadPlanSubscriber(
      currentUser.planSubscriberId
    );

    forceSignOutThenSystemClose(latestPlanSubscriber);

    if (isChangedPlan(currentPlanSubscriber, latestPlanSubscriber)) {
      setCurrentPlanSubscriber(latestPlanSubscriber);
      return;
    }
  };

  useEffect(() => {
    firstLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(() => {
    monitoring();
  }, minutesToMilliseconds(10));

  return {
    plan: currentPlanSubscriber,
    loading: loading,
    error: error,
    isGeneralWastesPlan: isGeneralWastesPlan(currentPlanSubscriber),
  };
};
