/**
 * 車輛詳細点検詳細を表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  value,
  form,
  onUpdate,
  loading,
  confirmDialog,
  onRemove,
  ...props
}) => {
  return render({
    ...props,
    form: form,
    value: value,
    onUpdate: onUpdate,
    loading: loading,
    onRemove: onRemove,
  });
};
