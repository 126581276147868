import { Skeleton, Stack } from "@mui/material";
import { Item } from "./Item";

/**
 * フローを選択するコンポーネントです。
 * @param {object} props プロパティ
 * @param {array} props.options 選択するフロー配列
 * @param {object} props.value フローid
 * @param {boolean} props.loading 読み込み中であるか
 * @fires Layout#onChange 変更時
 * @returns {JSX.Element}
 */
export const Items = ({
  options = [],
  value,
  onChange = () => {},
  loading = false,
}) => {
  if (!options || options.length <= 0) {
    return <></>;
  }

  if (loading === true) {
    return (
      <Stack spacing={2}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      {options.map((option, index) => (
        <Item value={value} option={option} key={index} onChange={onChange} />
      ))}
    </Stack>
  );
};
