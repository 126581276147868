import React from "react";
import { WasteSelector } from "../../../WasteSelector";

/**
 * 事業者の廃棄物タブの表示を行うプレゼンテーションコンポーネントです
 */
export const Presententer = (props) => (
  <WasteSelector
    managedCompanyId={props.companyId}
    dischargeSourceWorkplaceId={props.workplaceId}
    onSelected={props.onSelected}
    initialState={props.initialState}
    visibleActions={
      props.workplace?.state ? props.workplace?.state !== "removed" : false
    }
    onRegistered={props.onRegistered}
  />
);
