/**
 * 引数を受けて、JSX.Elementを返します
 * @param {props} props
 * @callback render
 */
export const Container = ({ render, ...props }) => {
  return render({
    autoHeight: true,
    ...props,
  });
};
