import React from "react";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PersonIcon from "@mui/icons-material/Person";
import WarningIcon from "@mui/icons-material/Warning";
import { If, StrictIf } from "views/atoms";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  CircularProgress,
  LinearProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

/**
 * 読み込み中であること表示するコンポーネントです。
 * @returns {JSX.Element}
 */
const Loading = () => {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CircularProgress color="info" size="10rem" />
    </Box>
  );
};

const Header = (props) => {
  const { onBeforeDate, onNextDate, date, isLoading } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      mb={1}
    >
      <IconButton
        aria-label="before"
        onClick={onBeforeDate}
        disabled={isLoading}
      >
        <NavigateBeforeIcon fontSize="inherit" />
      </IconButton>
      <Typography variant="h5" component="div" align="center">
        {date}
      </Typography>
      <IconButton
        aria-label="next"
        onClick={onNextDate}
        disabled={isLoading}
      >
        <NavigateNextIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );
};

const UnsetMessage = () => {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "58vh",
      }}
    >
      <WarningIcon color="warning" sx={{ fontSize: 100 }} />
      <Typography
        variant="h6"
        component="div"
        sx={{ color: "warning.main" }}
        align="center"
      >
        割り当てられたルートがありません。
      </Typography>
    </Box>
  );
};

/**
 * モバイルの回収一覧の表示を行うコンテナプレゼンテーションです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  const { journals, isLoading, onStartCollect } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "95%",
      }}
    >
      <div>
        <Header {...props} />
        <If condition={isLoading}><Loading /></If>
        <StrictIf
          target={journals}
          condition={(journals?.length ?? 0) > 0}
          whenTrueComponent={
            <Stack spacing={2}>
              {journals.map((journal, index) => {
                const percent =
                  Math.round((journal.taskDoneCount / journal.taskTotal) * 100) || 0;

                return (
                  <Card key={index}>
                    <CardActionArea onClick={() => onStartCollect(journal.id)}>
                      <CardContent>
                        <Stack spacing={1}>
                          <Typography variant="h6" fontWeight={600}>
                            {journal?.name}
                          </Typography>
                          <Box
                            direction="row"
                            spacing={1}
                          >
                            {journal?.assignedUsers &&
                              journal.assignedUsers?.map((user, index) => (
                                <Chip
                                  key={index}
                                  icon={<PersonIcon />}
                                  label={user.name}
                                  color="success"
                                  sx={{
                                    mr: 1,
                                    mb: 1
                                  }}
                                />
                              ))}
                            {journal?.assignedVehicle && (
                              <Chip
                                icon={<DirectionsCarIcon />}
                                label={journal?.assignedVehicle?.number}
                                color="info"
                                sx={{
                                  mb: 1
                                }}
                              />
                            )}
                          </Box>
                          <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={1}
                            style={{
                              marginTop: "-4px"
                            }}
                          >
                            <Box
                              sx={{
                                flexGrow: 1,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box sx={{ width: "100%", mr: 1 }}>
                                <LinearProgress
                                  variant="determinate"
                                  value={percent}
                                />
                              </Box>
                              <Box sx={{ minWidth: 35 }}>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {`${percent}%`}
                                </Typography>
                              </Box>
                            </Box>
                            <Typography variant="body2" color="text.secondary">
                              {`${journal.taskDoneCount}／${journal.taskTotal}`}
                            </Typography>
                          </Stack>
                          <Button variant="outlined" component="div">
                            回収を開始する
                          </Button>
                        </Stack>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                );
              })}
            </Stack>
          }
          whenFalseComponent={<UnsetMessage />}
        />
      </div>
    </div>
  );
};
