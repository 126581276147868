import { useState, useEffect } from "react";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { useStyles } from "./styles";
import { companyCategoriesSelector } from "ducks/CompanyCategories";
import { useSelector, useDispatch } from "react-redux";
import { add as addAlert } from "ducks/Alert";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import { useOpener } from "utils/useOpener";

/**
 * 取引先詳細を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  submit = Promise.resolve(),
  onSubmit = (data) => debugLog("PartnerDetail.onSubmit", data),
  onError = (err) => debugLog("PartnerDetails.submit.error", err),
  load,
  onDelete,
  ...props
}) => {
  const companyCategories = useSelector(companyCategoriesSelector);
  const classes = useClasses(useStyles);
  const [tab, setTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const confirmRemoveDialog = useOpener();

  useEffect(() => {
    const qs = new URLSearchParams(location.search);
    setTab(qs.get("tab") ? parseInt(qs.get("tab")) : 0);
  }, [location.search]);

  const handleGetTabNumber = (newTab) => {
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({ tab: newTab })}`,
      },
      {
        replace: true,
      }
    );

    setTab(newTab);
  };

  const handleSubmit = (data) => {
    setIsSubmit(true);
    submit(data)
      .then((res) => {
        dispatch(
          addAlert({
            value: "保存しました。",
            severity: "success",
          })
        );
        setOpen(false);
        onSubmit(res);
        load();
      })
      .catch((err) => {
        dispatch(
          addAlert({
            value: "エラーが発生したため、保存できませんでした。",
            severity: "error",
          })
        );
        onError(err);
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return render({
    classes: classes,
    value: value,
    tab: tab,
    onChangeTab: handleGetTabNumber,
    open: open,
    onOpenDialog: () => setOpen(true),
    onCloseDialog: () => setOpen(false),
    onChangeDataSubmit: handleSubmit,
    companyCategories: companyCategories,
    isSubmit: isSubmit,
    confirmRemoveDialog: confirmRemoveDialog,
    onDelete: onDelete,
    ...props,
  });
};
