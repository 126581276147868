import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";
/**
 * 基本情報のタブを表示するコンテナプレゼンテーションです
 * @param {object} value 事業場情報
 */
export const TabSimpleInformation = containerPresententer(
  Container,
  Presententer
);
