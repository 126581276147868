import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";
import { createPlanSubscriber } from "api/graphql/mutations";
import { DateTime } from "luxon";
import queryString from "query-string";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIAMAuthorizeMutation } from "utils/useIAMAuthorizeMutation";
import { useOpener } from "utils/useOpener";
import { Stepper } from "../Stepper";
import { AccoutForm } from "../AccountForm";
import { CompanyForm } from "../CompanyForm";
import { Completed } from "../Completed";
import { ConfirmingForm } from "../ConfirmingForm";

/**
 * 申請フォームを表示するコンポーネント。
 * @returns {JSX.Element}
 */
export const ApplicationForm = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const queries = queryString.parse(search);
  const [activeStep, setActiveStep] = useState(0);
  const errorSnackbar = useOpener({
    options: { messsage: "エラーが発生したため、申請できませんでした。" },
  });
  const [value, setValue] = useState(null);

  const [applyMutation, applyMutationStatus] = useIAMAuthorizeMutation(
    createPlanSubscriber,
    {
      onCompleted: () => {
        setActiveStep((prevState) => prevState + 1);
      },
      onError: ({ errors }) => {
        for (let error of errors) {
          if (error.errorType === "ExpiredException") {
            // todo: 有効期限切れだった場合
            navigate(
              {
                pathname: "/invite_confirming/forbidden",
              },
              {
                place: true,
              }
            );
          } else if (error.errorType === "UsernameExistsException") {
            // メールアドレスの不正
            errorSnackbar.changeOptions({
              message:
                "入力されたメールアドレスは既に使用しているため、登録できません。",
            });
            errorSnackbar.toggle(true);
          } else {
            errorSnackbar.toggle(true);
          }
        }
      },
      succeedMessage: "申請しました。",
      errorMessage: "エラーが発生したため、申請できませんでした。",
    },
    false,
    null,
    null
  );

  const handleCompanyOnNext = (params) => {
    setValue((prevState) => ({
      ...prevState,
      company: params,
    }));
    setActiveStep((prevState) => prevState + 1);
  };

  const handleAccountOnBack = (params) => {
    setValue((prevState) => ({
      ...prevState,
      account: null,
    }));
    setActiveStep((prevState) => prevState - 1);
  };

  const handleAccountOnNext = (params) => {
    setValue((prevState) => ({
      ...prevState,
      account: params,
    }));
    setActiveStep((prevState) => prevState + 1);
  };

  const handleConfirmingOnBack = (params) => {
    setValue((prevState) => ({
      ...prevState,
      agreedOfTermsOfService: null,
      agreedOfPrivacyPolicy: null,
    }));
    setActiveStep((prevState) => prevState - 1);
  };

  const handleApplied = (params) => {
    applyMutation({
      input: {
        planSubscribeProcedureCode: queries["code"],
        ...value?.company,
        establishmentedOn: value?.company.establishmentedOn
          ? DateTime.fromJSDate(value?.company.establishmentedOn).toISODate()
          : null,
        ...value?.account,
        ...params,
        agreedOfTermsOfService: undefined,
        agreedOfPrivacyPolicy: undefined,
      },
    });
  };

  return (
    <>
      <Stepper
        activeStep={activeStep}
        options={[
          {
            id: 1,
            label: "会社情報",
            content: (
              <CompanyForm
                value={value?.company}
                onNext={handleCompanyOnNext}
                disabled={applyMutationStatus.loading}
              />
            ),
          },
          {
            id: 2,
            label: "アカウント情報",
            content: (
              <AccoutForm
                value={value?.account}
                onBack={handleAccountOnBack}
                onNext={handleAccountOnNext}
                disabled={applyMutationStatus.loading}
              />
            ),
          },
          {
            id: 3,
            label: "確認",
            content: (
              <ConfirmingForm
                value={value}
                onBack={handleConfirmingOnBack}
                onApplied={handleApplied}
                disabled={applyMutationStatus.loading}
              />
            ),
          },
          {
            id: 4,
            label: "完了",
            content: <Completed />,
          },
        ]}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={applyMutationStatus.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={errorSnackbar.open}
        autoHideDuration={6000}
        onClose={() => errorSnackbar.toggle(false)}
      >
        <Alert
          onClose={() => errorSnackbar.toggle(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorSnackbar?.options?.message ?? ""}
        </Alert>
      </Snackbar>
    </>
  );
};
