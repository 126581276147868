import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 車輛情報の詳細ページを表示するコンポーネントです。
 * @param {object} value 配車詳細情報
 * @param {array} galleries ギャラリーデフォルト値
 * @param {func} submit フォーム編集をサブミットするイベント
 * @param {func} onSubmitted フォームデータを取得します。
 */
export const VehicleDetails = containerPresententer(Container, Presententer);
