import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";

/**
 *  フリーワードの表示を行うプレゼンテーションコンポーネントです。
 * @param {string} value 検索値
 * @param {func} handleKeyDown マウスをクリックダウンするイベントです
 * @param {func} onClickButtonSearch 検索ボータンを押す時のイベントです
 * @param {object} classes　クラス名
 * @returns
 */
export const Presententer = (props) => {
  return (
    <TextField
      fullWidth
      placeholder="フリーワード検索"
      type="text"
      variant="outlined"
      value={props.value}
      size={props.fieldSize || "medium"}
      onChange={(e) => props.onChange(e)}
      onKeyDown={props.handleKeyDown}
      className={props.classes.box_TextSearcher}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              aria-label="search"
              size={props.iconSize || "medium"}
              onClick={props.onClickButtonSearch}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
