import { useMediaQuery, useTheme } from "@mui/material";
import { styles } from "./styles";
import useClasses from "utils/useClasses";

/**
 * テキストフィルドの表示を行うコンテナコンポーネントです。
 * @callback render
 */
export const Container = ({ render, otherActions, ...props }) => {
  const classes = useClasses(styles);
  const theme = useTheme();
  const isMatches = useMediaQuery(theme.breakpoints.down("sm"));

  return render({
    classes: classes,
    isMatches: isMatches,
    otherActions: otherActions,
    ...props,
  });
};
