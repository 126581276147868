import { useNavigate, useLocation } from "react-router-dom"; 
import { useSelector } from "react-redux";
import { companySelector } from "ducks/Company";

/**
 * モバイルの車輛点検一覧を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const company = useSelector(companySelector);

  const handleClick = (id) => () => {
    navigate(`${location.pathname}/${id}`);
  }

  return render({
    ...props,
    onClick: handleClick,
    company: company,
  });
};
