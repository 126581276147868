import {
  Box,
  ClickAwayListener,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

/**
 * ドライバーを表示・入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  if (props.edit) {
    return (
      <ClickAwayListener onClickAway={props.handleClickAway}>
        <TextField
          select
          onChange={props.onChange}
          fullWidth
          size={props.size ||"medium"}
          SelectProps={{
            MenuProps: { disablePortal: true },
            multiple: true,
            displayEmpty: true,
            value: props.value?.map((i) => i.id) ?? [],
            renderValue: (selected) => {
              if (selected?.length === 0) {
                return <em>{props?.placeholder ?? ""}</em>;
              }

              return selected
                ?.map(
                  (s) => props.drivers.find((option) => option.id === s)?.name
                )
                .join("、");
            },
          }}
        >
          {props.drivers.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              disabled={props.disabledDrivers
                ?.map((disabled) => disabled.id)
                .includes(option.id)}
            >
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </ClickAwayListener>
    );
  } else {
    return (
      <Box
        onClick={props.onEdit}
        sx={{
          marginBottom: "8px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {props.value?.length > 0 ? (
          props.value?.map((item) => item.name)?.join()
        ) : (
          <Typography style={{ color: "red" }}>{"ドライバー未設定"}</Typography>
        )}
      </Box>
    );
  }
};
