import { createSlice } from "@reduxjs/toolkit";
import { v4 } from "uuid";
const INIT_STATE = {
  SCHEDULED: [
    {
      id: v4(),
      field: "FM",
      name: "八幡郵便局4",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 32",
        createdAt: 1623675112632,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "若松郵便局1",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 27",
        createdAt: 1623675112627,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "西小倉郵便局5",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 31",
        createdAt: 1623675112631,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "八幡郵便局4",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 32",
        createdAt: 1623675112632,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "若松郵便局1",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 27",
        createdAt: 1623675112627,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "西小倉郵便局5",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 31",
        createdAt: 1623675112631,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "八幡郵便局4",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 32",
        createdAt: 1623675112632,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "若松郵便局1",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 27",
        createdAt: 1623675112627,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "西小倉郵便局5",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 31",
        createdAt: 1623675112631,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "八幡郵便局4",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 32",
        createdAt: 1623675112632,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "若松郵便局1",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 27",
        createdAt: 1623675112627,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "西小倉郵便局5",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 31",
        createdAt: 1623675112631,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "八幡郵便局4",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 32",
        createdAt: 1623675112632,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "若松郵便局1",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 27",
        createdAt: 1623675112627,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "西小倉郵便局5",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 31",
        createdAt: 1623675112631,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "八幡郵便局4",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 32",
        createdAt: 1623675112632,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "若松郵便局1",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 27",
        createdAt: 1623675112627,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "西小倉郵便局5",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 31",
        createdAt: 1623675112631,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "八幡郵便局4",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 32",
        createdAt: 1623675112632,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "若松郵便局1",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 27",
        createdAt: 1623675112627,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "西小倉郵便局5",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 31",
        createdAt: 1623675112631,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "八幡郵便局4",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 32",
        createdAt: 1623675112632,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "若松郵便局1",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 27",
        createdAt: 1623675112627,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "西小倉郵便局5",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 31",
        createdAt: 1623675112631,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "八幡郵便局4",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 32",
        createdAt: 1623675112632,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "若松郵便局1",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 27",
        createdAt: 1623675112627,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "西小倉郵便局5",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 31",
        createdAt: 1623675112631,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "八幡郵便局4",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 32",
        createdAt: 1623675112632,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "若松郵便局1",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 27",
        createdAt: 1623675112627,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "西小倉郵便局5",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 31",
        createdAt: 1623675112631,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "八幡郵便局4",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 32",
        createdAt: 1623675112632,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "若松郵便局1",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 27",
        createdAt: 1623675112627,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "西小倉郵便局5",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 31",
        createdAt: 1623675112631,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "八幡郵便局4",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 32",
        createdAt: 1623675112632,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "若松郵便局1",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 27",
        createdAt: 1623675112627,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "西小倉郵便局5",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 31",
        createdAt: 1623675112631,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "八幡郵便局4",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 32",
        createdAt: 1623675112632,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "若松郵便局1",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 27",
        createdAt: 1623675112627,
      },
    },
    {
      id: v4(),
      field: "FM",
      name: "西小倉郵便局5",
      productField: "不燃ごみ（3袋～5袋）",
      times: {
        date: "毎日",
        time: "07 : 00 ~ 16 : 31",
        createdAt: 1623675112631,
      },
    },
  ],
  ListCources: [
    {
      name: "若戸コース",
      id: v4(),
      cards: [
        {
          id: v4(),
          field: "FM",
          name: "北九州郵便局2",
          productField: "不燃ごみ（3袋～5袋）",
          times: {
            date: "毎日",
            time: "07 : 00 ~ 16 : 29",
            createdAt: 1623675112629,
          },
        },
        {
          id: v4(),
          field: "FM",
          name: "下関郵便局3",
          productField: "不燃ごみ（3袋～5袋）",
          times: {
            date: "毎日",
            time: "07 : 00 ~ 16 : 30",
            createdAt: 1623675112630,
          },
        },
      ],
    },
  ],
};
const ScheduleSlice = createSlice({
  name: "schedules",
  initialState: INIT_STATE,
  reducers: {
    actMoveCard: (state, action) => {
      const {
        DroppableIdStart,
        DroppableIdEnd,
        DroppableIndexStart,
        DroppableIndexEnd,
        //draggableId,
      } = action.payload;
      //ドラッグされたところと行き先が同じ個所です
      if (DroppableIdStart === DroppableIdEnd) {
        //配車コース全体ドラッグする
        if (DroppableIdStart === "all-list" && DroppableIdEnd === "all-list") {
          const listMoveStart = state.ListCources.splice(
            DroppableIndexStart,
            1
          );
          state.ListCources.splice(DroppableIndexEnd, 0, ...listMoveStart);
        }
        //定期回収リストの中にドラッグする => SCHEDULED
        if (
          DroppableIdStart === "schedules" &&
          DroppableIdEnd === "schedules"
        ) {
          const schedule = state.SCHEDULED.splice(DroppableIndexStart, 1);
          state.SCHEDULED.splice(DroppableIndexEnd, 0, ...schedule);
        }

        //配車リストの中にドラッグする=> ListCard
        const cardFind = state.ListCources.find(
          (list) => list.id === DroppableIdEnd
        );
        if (cardFind) {
          const courceMoved = cardFind.cards.splice(DroppableIndexStart, 1);
          cardFind.cards.splice(DroppableIndexEnd, 0, ...courceMoved);
        }
      }

      //ドラッグされたところと行き先が違い個所です
      if (DroppableIdStart !== DroppableIdEnd) {
        //ドラッグれた箇所は定期回収リストです。
        //行き先箇所は配車リストです。
        const listStart = state.ListCources.find(
          (list) => list.id === DroppableIdEnd
        );
        //ドラッグされたアイテムを追加する
        if (listStart && DroppableIdStart === "schedules") {
          //ドラッグさてたアイテムが配車リストに消える
          const scheduleMoveList = state.SCHEDULED.splice(
            DroppableIndexStart,
            1
          );

          // 配車コースからドラッグして配車リストまでドロップする
          if (scheduleMoveList) {
            listStart.cards.splice(DroppableIndexEnd, 0, ...scheduleMoveList);
          }
        }

        //ドラッグされた箇所は配車リストです。
        //行き先箇所は別の配車リストです。
        const listsScheduleStart = state.ListCources.find(
          (list) => list.id === DroppableIdStart
        );
        // ドラッグされたアイテムが元のリストに消える
        if (listsScheduleStart && DroppableIdEnd !== "schedules") {
          const scheduleMoveItemStart = listsScheduleStart.cards.splice(
            DroppableIndexStart,
            1
          );

          //配車リストを探す
          const listsScheduleEnd = state.ListCources.find(
            (list) => list.id === DroppableIdEnd
          );
          // ドロップされたアイテムを行先のリストに追加する
          listsScheduleEnd.cards.splice(
            DroppableIndexEnd,
            0,
            ...scheduleMoveItemStart
          );
        }

        //ドラッグされた箇所は配車コースです。
        //行き先箇所は定期回収リストです。
        if (DroppableIdEnd === "schedules") {
          //ドラッグされたアイテムが消える
          const listStart = state.ListCources.find(
            (list) => list.id === DroppableIdStart
          );
          const cardMoved = listStart.cards.splice(DroppableIndexStart, 1);
          //ドロップされたアイテムを追加する
          state.SCHEDULED.splice(DroppableIndexEnd, 0, ...cardMoved);
        }
      }
    },
    //新しい配車リストを追加する
    actAddList: (state, action) => {
      const newList = action.payload;
      return { ...state, ListCources: [...state.ListCources, newList] };
    },

    //名称を変換する
    actEditTitleList: (state, action) => {
      const { newTitle, listId } = action.payload;
      const newState = state.ListCources.map((list) => {
        if (list.id === listId) {
          return {
            ...list,
            name: newTitle,
          };
        }
        return list;
      });
      state.ListCources = newState;
    },

    //配車リストを左へ移動
    actMoveListLeft: (state, action) => {
      const index = action.payload;
      [state.ListCources[index - 1], state.ListCources[index]] = [
        state.ListCources[index],
        state.ListCources[index - 1],
      ];
    },

    //配車リストを右へ移動
    actMoveListRight: (state, action) => {
      const index = action.payload;
      [state.ListCources[index], state.ListCources[index + 1]] = [
        state.ListCources[index + 1],
        state.ListCources[index],
      ];
    },

    //回収時間で並び替え
    actSortInList: (state, action) => {
      const listId = action.payload;
      const newList = state.ListCources.map((list) => {
        if (list.id === listId) {
          return {
            ...list,
            cards: list.cards.sort((a, b) => {
              const time_1 = a.times.createdAt;
              const time_2 = b.times.createdAt;
              return time_2 - time_1;
            }),
          };
        }
        return list;
      });
      state.ListCources = newList;
    },
    //すべての割り当てを解除
    actDeleteInList: (state, action) => {
      const listId = action.payload;
      let newCards = [];
      const newLists = state.ListCources.map((list) => {
        if (list.id === listId) {
          newCards = list.cards;
          return {
            ...list,
            cards: [],
          };
        }
        return list;
      });
      state.SCHEDULED = [...state.SCHEDULED, ...newCards];
      state.ListCources = newLists;
    },
    //配車リストを解除
    actDeleteList: (state, action) => {
      const listId = action.payload;
      let newCards = [];
      const newLists = state.ListCources.filter((list) => {
        if (list.id === listId) {
          newCards = list.cards;
          return list.id !== listId;
        }
        return list;
      });
      state.SCHEDULED = [...state.SCHEDULED, ...newCards];
      state.ListCources = newLists;
    },
    //会社カードを検索する
    actSearchCard: (state, action) => {
      const { value, searchFrom } = action.payload;
      if (searchFrom === "schedules") {
        const newSchedules = state.SCHEDULED.filter((card) => {
          let flag;
          for (let index in card) {
            flag = false;
            flag = card[index].toString().indexOf(value) > -1;
            if (flag) {
              break;
            }
          }
          return flag;
        });

        state.SCHEDULED = newSchedules;
      }
    },
  },
});
export const {
  actMoveCard,
  actAddList,
  actEditTitleList,
  actMoveListLeft,
  actMoveListRight,
  actSortInList,
  actDeleteInList,
  actDeleteList,
  actSearchCard,
} = ScheduleSlice.actions;

export default ScheduleSlice.reducer;
