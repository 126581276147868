export const useStyles = (theme) => {
  return {
    container: {
      display: "flex",
      alignItems: "stretch",
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.text.hint,
      marginBottom: theme.spacing(2),
      "&:last-child": {
        marginBottom: 0,
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    label: {
      minWidth: "230px",
      maxWidth: "230px",
      backgroundColor: theme.palette.text.hint,
      textAlign: "center",
      padding: "10px 24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "15px",
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "transparent",
        textAlign: "left",
        display: "block",
        padding: 0,
        fontSize: "13px",
        color: theme.palette.grey[600],
      },
    },
    item: {
      flexGrow: 1,
      padding: "8px 40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      wordBreak: "break-all",
      whiteSpace: "pre-line",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        textAlign: "left",
        padding: "5px 10px",
        color: theme.palette.grey[800],
        minHeight: "34px",
      },
    },
  };
};
