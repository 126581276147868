import { getWasteFlow } from "api/graphql/queries";
import { useQuery } from "utils/useQuery";
import { Layout } from "./Layout";

/**
 * フロー詳細を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.value フロー固有番号
 * @returns {JSX.Element}
 */
export const Details = ({ value }) => {
  const wasteFlow = useQuery({
    query: getWasteFlow,
    variables: {
      id: value,
    },
    onCompleted: () => {},
  });

  return (
    <Layout value={wasteFlow?.data?.getWasteFlow} loading={wasteFlow.loading} />
  );
};
