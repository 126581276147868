import { XGridComponent } from "views/molecules";

/**
 * コンテナを選択するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => (
  <XGridComponent
    autoHeight={props.autoHeight}
    value={props.value}
    onChangeSelection={props.onSelected}
    checkboxSelection={props.multiple}
    multipleSelection={props.multiple}
    onDownload={props.download}
    titleTable={props.title}
    {...props.xGridParams}
  />
);
