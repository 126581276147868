import { Button } from "@mui/material";
import React from "react";
import { ModalPopup, XGridComponent } from "views/molecules";
import { DocumentForm } from "./ChildComponents";

const Form = React.forwardRef((props, ref) => (
  <DocumentForm {...props} _ref={ref} />
));

/**
 * 廃棄物の詳細情報の表示を行うプレゼンテーションコンポーネントです
 * @param {object} classes クラス名
 * @param {object} value 事業場情報
 */
export const Presententer = (props) => {
  return (
    <div>
      <XGridComponent
        rows={[]}
        columns={props.columns}
        onChangeSelection={props.onChangeSelection}
        checkboxSelection={false}
        multipleSelection={false}
        onDownload={true}
        actionButton={
          <Button onClick={props.onOpenDialog} variant="contained">
            {"新しい資料を追加"}
          </Button>
        }
        {...props.xGridParams}
      />
      <ModalPopup open={props.open} onClose={props.onCloseDialog}>
        <div className={props.classes.dialog_title}>{"資料を追加"}</div>
        <div className={props.classes.dialog_context}>
          <Form ref={props.formRef} />
        </div>
        <div className={props.classes.dialog_actions}>
          <Button
            variant="outlined"
            color="primary"
            onClick={props.onCloseDialog}
            sx={{
              marginRight: "10px",
            }}
            disabled={props.isSubmit}
          >
            {"キャンセル"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={props.onDecided}
            disabled={props.isSubmit}
          >
            {"決定"}
          </Button>
        </div>
      </ModalPopup>
    </div>
  );
};
