import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { debugLog } from "utils/log";
import { DatePicker } from "views/atoms";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";

/**
 * 車検満了日を更新するコンポーネントです。
 * @param {boolean} open 開閉状態
 * @param {object} value 現在の車検満了日
 * @param {boolean} disabled 無効設定
 * @fires InspectionExpirationDateForm#onClose 閉じる時
 * @fires InspectionExpirationDateForm#onSubmit 送信時
 * @fires InspectionExpirationDateForm#onError エラー時
 * @returns {JSX.Element}
 */
export const InspectionExpirationDateForm = ({
  open = false,
  onClose = () => {},
  onSubmit = () => {},
  onError = debugLog,
  value = null,
  disabled = false,
}) => {
  const { trigger, watch, control, formState, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      certificateExpiredOn: null,
      ...value,
    },
  });

  useEffect(() => {
    if (open) {
      reset({
        date: null,
        ...value,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, JSON.stringify(value)]);

  const handleUpdate = async () => {
    const result = await trigger();
    if (result) {
      onSubmit(watch());
    } else {
      onError(formState.errors);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{"車検満了日を更新する"}</DialogTitle>
      <DialogContent>
        <Controller
          name={`certificateExpiredOn`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <DatePicker
              label="車検満了日"
              required={true}
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
              disabled={disabled}
            />
          )}
          rules={{
            required: true,
            valueAsDate: true
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={disabled} color="error">
          {"キャンセル"}
        </Button>
        <Button onClick={handleUpdate} disabled={disabled}>
          {"更新"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
