/**
 * 引数を受けて、JSX.Elementを返します。
 * @param {object} props プロパティ
 * @callback render
 * @returns
 */
export const Container = ({ render, value, ...props }) => {
  return render({
    value: value,
    ...props,
  });
};
