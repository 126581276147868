import useClasses from "utils/useClasses";
import { useStyles } from "./styles";

const columns = [
  {
    field: "wasteType",
    headerName: "廃棄物の種類",
    minWidth: 150,
    flex: 1,
    valueGetter: (params) => {
      return `${
        params.row.waste.type.smallClass.name ||
        params.row.waste.type.middleClass.name ||
        params.row.waste.type.largeClass.name ||
        ""
      }`;
    },
  },
  {
    field: "wasteName",
    headerName: "廃棄物の名称",
    width: 150,
    flex: 1,
    valueGetter: (params) => params.row.waste.name,
  },
  {
    field: "quantity",
    headerName: "排出量",
    width: 120,
    flex: 1,
    valueGetter: (params) => {
      return `${params.row.quantity} ${params.row.waste.quantityUnit.name}`;
    },
  },
];

/**
 * スポット回収詳細ページを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  const classes = useClasses(useStyles);

  return render({
    classes: classes,
    value: value,
    columns: columns,
  });
};
