import { Switcher } from "utils/ImageFunctions";
import { PDFViewer } from "./PDFViewer";
import { CircularProgress } from "@mui/material";
import { Image } from "views/atoms/Image";
import { OtherFile } from "./OtherFile";

/**
 * ファイルを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.src ファイルソース
 * @param {boolean} props.loading 読み込み中であるか
 * @returns {JSX.Element}
 */
export const FileView = ({ src, loading = false }) => {
  if (!src) {
    return <></>;
  }

  return (
    <>
      {loading === true && (
        <div
          style={{
            height: 60,
          }}
        >
          <CircularProgress color="info" />
        </div>
      )}
      {loading === false && (
        <Switcher src={src}>
          {({ isPDF, isImage, isOther }) => (
            <>
              {isPDF === true && <PDFViewer src={src} />}
              {isImage === true && <Image src={src} width="100%" />}
              {isOther === true && <OtherFile />}
            </>
          )}
        </Switcher>
      )}
    </>
  );
};
