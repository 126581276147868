/**
 * 車輛詳細点検を表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  form,
  value,
  maintenanceForm,
  onUpdate,
  onCreateMaintenance,
  loading,
  onSelected,
  safetiesId,
  safetiesDetailsRef,
  onRemove,
  confirmDialog,
  managementVehicle,
  ...props
}) => {
  return render({
    ...props,
    value: value,
    form: form,
    maintenanceForm: maintenanceForm,
    loading: loading,
    onUpdate: onUpdate,
    onCreateMaintenance: onCreateMaintenance,
    onSelected: onSelected,
    safetiesId: safetiesId,
    safetiesDetailsRef: safetiesDetailsRef,
    onRemove: onRemove,
    confirmDialog: confirmDialog,
    managementVehicle: managementVehicle,
  });
};
