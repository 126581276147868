import {
  AppBar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import clsx from "clsx";
import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { RenderButton } from "views/atoms";
import { MoreHorizOutlined } from "@mui/icons-material";

export const Presententer = (props) => {
  const { routes, classes } = props;
  return (
    <AppBar
      position="fixed"
      color="primary"
      className={clsx(classes.appBarMobile)}
    >
      <Toolbar className={clsx(classes.toolbarMobile)}>
        <List className="list_menu" >
          {routes.map((route, index) => (
            <SideMenuItem
              path={route.path}
              text={route.title}
              icon={route.icon}
              key={index}
              classes={"buttonMenuMobile"}
            />
          ))}
        </List>
        <RenderButton
          className={"buttonMenuMobile more"}
          icon={<MoreHorizOutlined />}
          label="more"
          disableElevation={true}
        />
      </Toolbar>
    </AppBar>
  );
};

/**
 * サイドメニューのアイテムを表すプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 */
const SideMenuItem = ({ path, text, icon, classes }) => {
  const renderLink = useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <NavLink
          activeClassName="selected"
          to={path}
          ref={ref}
          {...itemProps}
        />
      )),
    [path]
  );
  return (
    <li>
      <ListItem button component={renderLink} className={classes} >
        {icon ? (
          <ListItemIcon className={classes.linkIcon}>{icon}</ListItemIcon>
        ) : null}
        <ListItemText primary={text} />
      </ListItem>
    </li>
  );
};
