import React from "react";
import { XGridComponent } from "views/molecules";

/**
 * 電子マニフェストの一覧を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <XGridComponent
    onChangeSelection={props.onClick}
    checkboxSelection={false}
    multipleSelection={false}
    onDownload={true}
    titleTable={""}
    {...props.xGridParams}
  />
);
