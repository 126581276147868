import { companySelector } from "ducks/Company";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import useClasses from "utils/useClasses";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { toQueryString, fromQueryString } from "utils/useXGridComponents";
import { getWorkplace } from "api/graphql/queries";
import { useParams } from "react-router-dom";
import { useQuery } from "utils/useQuery";

/**
 * 事業者の廃棄物タブの表示を行うコンテナコンポーネントです
 * @param {object} value 廃棄物情報
 */
export const Container = ({ render, value, ...props }) => {
  const classes = useClasses(useStyles);
  const navigate = useNavigate();
  const location = useLocation();
  const company = useSelector(companySelector);
  const { workplaceId } = useParams();
  const workplace = useQuery({
    query: getWorkplace,
    variables: {
      id: workplaceId,
    },
  });

  const handleSelected = (e) => {
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({
          ...Object.fromEntries(new URLSearchParams(location.search)),
          ...Object.fromEntries(
            new URLSearchParams(toQueryString(e.info.search))
          ),
        }).toString()}`,
      },
      {
        replace: true,
      }
    );

    navigate(`${location.pathname}/waste/${e.value.id}`);
  };

  const handleRegistered = (data) => {
    navigate({
      pathname: `${location.pathname}/waste/${data.id}`,
    });
  };

  return render({
    ...props,
    companyId: company.id,
    workplaceId: value.id,
    classes: classes,
    value: value,
    onSelected: handleSelected,
    initialState: {
      sort: {
        direction: "asc",
        field: "wasteTypeOrder",
      },
      ...fromQueryString(location.search),
    },
    workplace: workplace?.data?.getWorkplace,
    onRegistered: handleRegistered,
  });
};
