import { Container } from "@mui/material";
import React from "react";
import { RenderButtonToolBar } from "utils/XgridExpand";
import { FullScreenDialog, XGridComponent } from "views/molecules";
import { WasteRegistrationForm } from "../PartnerDetailTabs/WasteRegistrationForm";

const Form = React.forwardRef((props, ref) => (
  <WasteRegistrationForm {...props} _ref={ref} />
));

/**
 *廃棄物を選択するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns
 */
export const Presententer = (props) => {
  return (
    <div>
      <XGridComponent
        value={props.value}
        onChangeSelection={props.onChangeSelection}
        checkboxSelection={props.checkbox}
        multipleSelection={props.multiple}
        onDownload={true}
        actionButton={
          props.visibleActions === true ? (
            <RenderButtonToolBar
              label="新しい廃棄物を追加"
              onClick={props.onOpenDialog}
            />
          ) : (
            <></>
          )
        }
        {...props.xGridParams}
      />
      <FullScreenDialog
        open={props.open}
        onClose={props.onCloseDialog}
        title="新しい廃棄物の追加"
        textConfirm="追加"
        onClickSubmit={props.onClickRegister}
        isSubmit={props.isSubmit}
        disabled={props.isSubmit}
      >
        <Container maxWidth="md">
          <Form
            onSubmit={props.getDataSubmit}
            ref={props.formRef}
            managedCompanyId={props.managedCompanyId}
            managedWasteId={props.managedWasteId}
          />
        </Container>
      </FullScreenDialog>
    </div>
  );
};
