import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { recordCarryWorkJournalInstrument } from "api/graphql/mutations";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { debugLog } from "utils/log";
import { useMutation } from "utils/useMutation";
import { useOpener } from "utils/useOpener";
import { TextField } from "views/atoms";
import { ConfirmDialog } from "views/molecules";

const titles = {
  Start: "回収開始時のODOメーターを記録する",
  Resume: "回収再開時のODOメーターを記録する",
  Finish: "回収終了時のODOメーターを記録する",
  Interruption: "回収中断時のODOメーターを記録する",
};

const defaultValues = {
  odometer: null,
};

/**
 * ODOメーターを記録するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.journalId 固有番号
 * @param {string} props.type 種類 {Start | Finish | Interruption | Resume}
 * @param {object} props.value odoメータの値
 * @param {boolean} props.open 開閉状態
 * @param {boolean} props.disabled 無効設定
 * @fires OdometerForm#onCompleted 完了時
 * @fires OdometerForm#onError エラー時
 * @fires OdometerForm#onClose 閉じる時
 * @returns {JSX.Element}
 */
export const OdometerRecordForm = ({
  journalId,
  type = "Start",
  value = null,
  onError = (err) => debugLog(err),
  open = false,
  onClose = () => {},
  onCompleted = () => {},
  onSkiped = () => {},
}) => {
  const dialog = useOpener();
  const [mutation, status] = useMutation(recordCarryWorkJournalInstrument, {
    onCompleted: (params) => {
      onCompleted && onCompleted();
    },
  });

  const { trigger, watch, formState, control, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      odometer: value,
    },
  });

  const { odometer } = watch();

  useEffect(() => {
    if (open) {
      reset({
        ...defaultValues,
        odometer: value,
      });

      dialog.toggle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, value, open]);

  const onPositive = () => {
    mutation({
      input: {
        journalId: journalId,
        type:
          type === "Interruption"
            ? "Finish"
            : type === "Resume"
            ? "Start"
            : type,
        odometer: odometer,
        ignoreSynchronizeVehicleCurrentOdometer: false,
      },
    });
  };

  const onNegative = () => {
    mutation({
      input: {
        journalId: journalId,
        type:
          type === "Interruption"
            ? "Finish"
            : type === "Resume"
            ? "Start"
            : type,
        odometer: odometer,
        ignoreSynchronizeVehicleCurrentOdometer: true,
      },
    });
  };

  const onUpdate = (value) => {
    const { odometer } = value ?? {};

    if (value > odometer && type !== "Finish") {
      dialog.toggle(true);
    } else {
      mutation({
        input: {
          journalId: journalId,
          type:
            type === "Interruption"
              ? "Finish"
              : type === "Resume"
              ? "Start"
              : type,
          odometer: odometer,
          ignoreSynchronizeVehicleCurrentOdometer: false,
        },
      });
    }
  };

  const onRegist = async () => {
    const result = await trigger();

    if (result) {
      onUpdate(watch());
    } else {
      onError(formState.errors);
    }
  };

  return (
    <>
      {!dialog.open && (
        <Dialog
          onClose={onClose}
          open={open}
          disableEscapeKeyDown={status.loading}
        >
          <DialogTitle>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
              }}
            >
              {type === "Start"
                ? titles.Start
                : type === "Finish"
                ? titles.Finish
                : type === "Resume"
                ? titles.Resume
                : titles.Interruption}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Controller
                name={"odometer"}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      label="ODOメーターの値"
                      required={true}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message}
                      disabled={status.loading}
                      type={"number"}
                    />
                  );
                }}
                rules={{
                  required: true,
                  pattern: /^[0-9]/,
                }}
              />
              <Button
                variant="contained"
                onClick={onRegist}
                disabled={status.loading}
              >
                {"登録"}
              </Button>
              {(type === "Interruption" || type === "Resume") && (
                <Button
                  variant="contained"
                  onClick={onSkiped}
                  color="info"
                  disabled={status.loading}
                >
                  {"スキップ"}
                </Button>
              )}
            </Stack>
          </DialogContent>
        </Dialog>
      )}
      {dialog.open && (
        <ConfirmDialog
          title="更新確認"
          message={`現在のODOメーターを${value}から${odometer}に更新しますか？`}
          onPositive={() => onPositive()}
          onNegative={() => onNegative()}
          open={dialog.open}
          onClose={() => dialog.toggle(false)}
          positiveText={"更新します"}
          negativeText={"更新しません"}
        />
      )}
    </>
  );
};
