import { removeWasteFlow, updateWasteFlow } from "api/graphql/mutations";
import { getWasteFlow } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { companySelector } from "ducks/Company";
import { toggle } from "ducks/Loading";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { debugLog } from "utils/log";
import { useMutation } from "utils/useMutation";

/**
 * 処理フロー詳細を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const company = useSelector(companySelector);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(toggle(true));
    if (company) {
      API.graphql(graphqlOperation(getWasteFlow, { id: id }))
        .then((res) => {
          setValue(res.data.getWasteFlow);
        })
        .catch((err) => debugLog(err))
        .finally(() => {
          dispatch(toggle(false));
        });
    }
  }, [company, id, dispatch]);

  const submit = (data) => {
    const {
      targetWaste,
      wasteKind,
      transportProcesses,
      disposalProcess,
      disposalMethod,
      disposalDetails,
      lastDisposalWorkplaces,
      version,
      createdAt,
      updatedAt,
      state,
      managedCompanyId,
      ...other
    } = data;

    return API.graphql(
      graphqlOperation(updateWasteFlow, {
        input: {
          targetWasteTypeId: targetWaste.id,
          targetWasteKind: wasteKind,
          transportProcesses: transportProcesses.map((item) => {
            return {
              /* 複数に戻したとき、item.trustee.companyIdにすること */
              carrierCompanyId: item.trustee.id,
              wasteTransportMethodCode: item.method.code,
              transportDestinationWorkplaceId: item.destination.id,
            };
          }),
          disposalProcess: {
            disposalCompanyId: disposalProcess.client.belongInCompanyId,
            disposalWorkplaceId: disposalProcess.client.id,
            wasteDisposalMethodCode: disposalProcess.method,
            disposalMethodDetails: disposalProcess.details,
          },
          lastDisposalProcesses:
            lastDisposalWorkplaces.length > 0
              ? lastDisposalWorkplaces.map((item) => ({
                  disposalWorkplaceId: item.id,
                }))
              : null,
          expectedVersion: version,
          ...other,
        },
      })
    ).then((res) => {
      setValue(res.data.updateWasteFlow);
      return res;
    });
  };

  const [removeMutation, removeMutationStatus] = useMutation(removeWasteFlow, {
    onCompleted: () => {
      navigate(
        {
          pathname: `/master/flow`,
        },
        {
          replace: true,
        }
      );
    },
    succeedMessage: "削除しました。",
    errorMessage: "エラーが発生したため、削除できませんでした。",
  });

  const handleRemove = () => {
    removeMutation({
      input: {
        id: id,
      },
    });
  };

  return render({
    value: value,
    submit: submit,
    onRemove: handleRemove,
    loading: removeMutationStatus.loading,
    ...props,
  });
};
