import useClasses from "utils/useClasses";
import { useStyles } from "./styles";
import { useState, useRef, useEffect, useCallback } from "react";
import { debugLog } from "utils/log";
import { getJwnetMember } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { toggle } from "ducks/Loading";
import { useDispatch } from "react-redux";

/**
 * Jwnetの設定を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, petition, isSubmit, ...props }) => {
  const classes = useClasses(useStyles);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const get = useCallback(() => {
    dispatch(toggle(true));
    return API.graphql(graphqlOperation(getJwnetMember))
      .then((res) => {
        debugLog("getJwnetMember成功: ", res.data.getJwnetMember);
        setValue(res.data.getJwnetMember);
      })
      .catch((err) => {
        debugLog("getJwnetMember失敗: ", err);
      })
      .finally(() => {
        dispatch(toggle(false));
      });
  }, [dispatch]);

  useEffect(() => {
    get();
  }, [get]);

  const handleConfirm = () => {
    formRef?.current?.submit();
  };

  const handleSubmit = (data) => {
    petition(data);
  };

  const handleNotificationChange = () => {
    get();
    // todo: ステータスのapiの呼びなおし
  };

  return render({
    ...props,
    value: value,
    open: open,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    onConfirm: handleConfirm,
    onSubmit: handleSubmit,
    classes: classes,
    formRef: formRef,
    isSubmit: isSubmit,
    onNotificationChange: handleNotificationChange,
  });
};
