import { createSlice } from "@reduxjs/toolkit";

export const AUTHSTATES = {
  AUTHENTICATED: "authenticated",
  CONFIRM_RESET_PASSWORD: "confirmResetPassword",
  CONFIRM_SIGN_IN: "confirmSignIn",
  CONFIRM_SIGN_UP: "confirmSignUp",
  CONFIRM_VERIFY_USER: "confirmVerifyUser",
  FORCE_NEW_PASSWORD: "forceNewPassword",
  IDLE: "idle",
  FORGOT_PASSWORD: "forgotPassword",
  SETUP: "setup",
  SIGN_OUT: "signOut",
  SETUP_TOTP: "setupTotp",
  SIGN_IN: "signIn",
  SIGN_UP: "signUp",
  TRANSITION: "transition",
  VERIFY_USER: "verifyUser",
};

const initialState = {
  authState: AUTHSTATES.SIGN_IN,
};

const AuthState = createSlice({
  name: "authState",
  initialState,
  reducers: {
    set: (state, action) => {
      state.authState = action.payload;
    },
  },
});

export const { set } = AuthState.actions;

export const selector = (store) => store.AuthState;

export default AuthState.reducer;
