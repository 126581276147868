import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 事業場の情報を詳しく表示する。
 * @param {object} value  事業所・事業場の値
 * @param {object} submit  フォームをサブミットする
 * @param {object} onSubmitted  フォームの値を取得する
 * @param {func} galleries ギャラリー情報デフォルト値
 * @param {func} vehicleDefault 車輛情報デフォルト値
 */
export const WorkplaceDetails = containerPresententer(Container, Presententer);
