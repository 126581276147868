import { Button } from "@mui/material";

/**
 * ボタンを表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Button
      variant={props.variant}
      color={props.color}
      className={props.className ? props.className : props.classes.root}
      onClick={props.onClick}
      disabled={props.disabled}
      startIcon={props.icon ? props.icon : null}
      {...props}
    >
      {props.label}
    </Button>
  );
};
