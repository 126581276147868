import { styles } from "./styles";
import useClasses from "utils/useClasses";

/**
 *ポップアップセレクションでデータを選択するコンポーネントです。
 *引数をうけてJSX Elemntを返します
 *@param {string} label セレクションのラベル
 *@param {string} placeholder プレースホルダー
 *@param {boolean} required 必須かどうかを設定する
 *@param {object} value 選択されたアイテムです
 *@param {func} onChange 選択するアイテムが更新するイベントです
 *@param {array} options アイテム配列
 *@param {string} error エラーが出る
 *@param {boolean} disabled 無効にする
 *@param {boolean} multiple 複数選択するかどうかを設定する
 *@param {string} optionValueField オプションの値のフィールド名
 *@param {string} optionNameField オプションの名前のフィールド名
 *@param {object} sx スタイル
 */
export const Container = ({
  render,
  label = "タイトル",
  hideLabel = false,
  required = true,
  value,
  onChange,
  options = [],
  optionValueField,
  optionNameField,
  placeholder = "未選択",
  error,
  disabled = false,
  multiple = false,
  sx,
  ...props
}) => {
  const classes = useClasses(styles);

  const handleChange = (e) => {
    e.target.value = multiple
      ? e.target.value.map((v) =>
          options.find((option) => option[optionValueField] === v)
        ) ?? null
      : options.find((option) => option[optionValueField] === e.target.value) ??
        null;
    onChange && onChange(e);
  };

  return render({
    classes: classes,
    label: label,
    hideLabel: hideLabel,
    placeholder: placeholder,
    required: required,
    value: value,
    onChange: handleChange,
    options: options,
    optionValueField: optionValueField,
    optionNameField: optionNameField,
    error: error,
    disabled: disabled,
    multiple: multiple,
    sx,
    ...props,
  });
};
