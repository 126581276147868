import { createContainerInBulk } from "api/graphql/mutations";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "utils/useMutation";

/**
 * コンテナ管理の種類詳細を表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  name,
  open,
  onClose = () => {},
  onNotification = () => {},
  totalOfOwned = 0,
  ...props
}) => {
  const [step, setStep] = useState("step1");
  const step1FormRef = useRef(null);
  const step2FormRef = useRef(null);
  const [step1formValue, setStep1FormValue] = useState(null);
  const [createMutation, createMutationStatus] = useMutation(
    createContainerInBulk,
    {
      onCompleted: (data) => {
        onNotification();
        onClose();
      },
      succeedMessage: "登録しました。",
      errorMessage: "エラーが発生したため、登録できませんでした。",
    }
  );

  const register = (data) => {
    const { manageWorkplace, additionalTypes, addedOn } = step1formValue;
    createMutation({
      input: {
        productId: props.productId,
        manageWorkplaceId: manageWorkplace.id,
        additionalTypeId: additionalTypes.id,
        addedOn: DateTime.fromJSDate(new Date(addedOn)).toFormat("yyyy-MM-dd"),
        profiles: data.configs.map((item) => {
          return {
            number: item.number,
            installationLocationCategory: item.installationLocationCategory,
            installationWorkplaceId: item.installationWorkplace.id,
            installedOn: DateTime.fromJSDate(
              new Date(item.installedOn)
            ).toFormat("yyyy-MM-dd"),
          };
        }),
      },
    });
  };

  useEffect(() => {
    if (open) {
      setStep("step1");
      setStep1FormValue(null);
    }
  }, [open]);

  return render({
    ...props,
    step1formValue: step1formValue,
    onSubmitStep1: (data) => {
      setStep1FormValue(data);
      setStep("step2");
    },
    isSubmit: createMutationStatus.loading,
    step: step,
    onNext: () => {
      step1FormRef.current.submit();
    },
    onPrevious: () => {
      setStep("step1");
    },
    open: open,
    onClose: onClose,
    onRegister: register,
    onSubmitForm2: () => {
      step2FormRef.current.submit();
    },
    step1FormRef: step1FormRef,
    step2FormRef: step2FormRef,
    name: name,
    totalOfOwned: totalOfOwned,
  });
};
