import {
  Button,
  Container,
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  Skeleton,
  Tabs,
  Tab,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { HaulersReportForm } from "../HaulersReportForm";
import { FullScreenDialog } from "views/molecules";
import { DateTime } from "luxon";

/**
 * 日付を表示する形で取得します。
 * @param {Date} value 日付
 * @returns {String}
 */
const getDate = (value) => {
  if (!value) {
    return "";
  }

  if (DateTime.fromFormat(value, "yyyy-MM-dd").isValid) {
    return DateTime.fromFormat(value, "yyyy-MM-dd").toFormat("yyyy/MM/dd");
  } else if (DateTime.fromFormat(value, "yyyy/MM/dd").isValid) {
    return DateTime.fromFormat(value, "yyyy/MM/dd").toFormat("yyyy/MM/dd");
  } else {
    return DateTime.fromJSDate(value).toFormat("yyyy/MM/dd");
  }
};

const Form = React.forwardRef((props, ref) => {
  return <HaulersReportForm {...props} _ref={ref} />;
});

const GridItemBase = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  border: "1px solid #707070",
  //marginRight: "-1px",
  //marginBottom: "-1px",
  display: "flex",
  alignItems: "center",
}));

const GridItemLabel = styled(GridItemBase)({
  backgroundColor: "#D7D7D7",
  justifyContent: "center",
  textAlign: "center",
  fontSize: "14px",
});

const GridItemValue = styled(GridItemBase)({
  fontSize: "14px",
});

const Section1 = ({
  manifestNumber,
  passedOverOn,
  wasteDischarge,
  waste,
  primaryManifests,
  bookedManifestChangePermission,
  manifestInfoType,
  manifestApprovalPendingStatus,
  contractNumbers,
}) => (
  <Box component="section" sx={{ mt: 2, mb: 2 }}>
    <Grid container>
      <GridItemLabel item xs={2}>
        マニフェスト番号
      </GridItemLabel>
      <GridItemValue item xs={2}>
        {manifestNumber}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        引渡し日
      </GridItemLabel>
      <GridItemValue item xs={2}>
        {getDate(passedOverOn)}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        引渡し担当者
      </GridItemLabel>
      <GridItemValue item xs={2}>
        {wasteDischarge?.passedPerson ?? ""}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        登録担当者
      </GridItemLabel>
      <GridItemValue item xs={2}>
        {wasteDischarge?.registeredPerson ?? ""}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        数量確定者
      </GridItemLabel>
      <GridItemValue item xs={2}>
        {waste?.jwnetManifestQuantityDeterminer?.name ?? ""}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        1次／2次区分
      </GridItemLabel>
      <GridItemValue item xs={2}>
        {!primaryManifests || primaryManifests?.length <= 0 ? "1次" : "2次"}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        登録の状態
      </GridItemLabel>
      <GridItemValue item xs={2}>
        {manifestInfoType?.name ?? ""}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        修正許可
      </GridItemLabel>
      <GridItemValue item xs={2}>
        {bookedManifestChangePermission?.name ?? ""}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        承認待ち
      </GridItemLabel>
      <GridItemValue item xs={2}>
        {manifestApprovalPendingStatus?.name ?? ""}
      </GridItemValue>
      {[...Array(3)].map((_, index) => {
        return (
          <React.Fragment key={index}>
            <GridItemLabel item xs={2}>
              {`連絡番号${index + 1}`}
            </GridItemLabel>
            <GridItemValue
              item
              xs={2}
              sx={{
                wordBreak: "break-all",
              }}
            >
              {contractNumbers?.find(
                (contractNumber) => contractNumber.number === index + 1
              )?.value ?? ""}
            </GridItemValue>
          </React.Fragment>
        );
      })}
    </Grid>
  </Box>
);

const Section2 = ({ wasteDischarge }) => (
  <Box component="section" sx={{ mt: 2, mb: 2 }}>
    <Grid container>
      <GridItemLabel item xs={2}>
        排出事業者
      </GridItemLabel>
      <Grid item xs={10}>
        <Grid container columns={10}>
          <GridItemLabel item xs={2}>
            氏名または名称
          </GridItemLabel>
          <GridItemValue item xs={8}>
            {wasteDischarge?.companyName ?? ""}
          </GridItemValue>
          <GridItemLabel item xs={2}>
            住所
          </GridItemLabel>
          <GridItemValue item xs={8}>
            {`〒 ${wasteDischarge?.companyPostalCode ?? ""}　${
              wasteDischarge?.companyPrefecturesName ?? ""
            }${wasteDischarge?.companyCity ?? ""}${
              wasteDischarge?.companyStreetAddress ?? ""
            }${wasteDischarge?.companyOtherAddress ?? ""}`}
          </GridItemValue>
          <GridItemLabel item xs={2}>
            電話／FAX番号
          </GridItemLabel>
          <GridItemValue item xs={4}>
            {`${wasteDischarge?.companyPhone ?? ""} ／ ${
              wasteDischarge?.companyFax ?? ""
            }`}
          </GridItemValue>
          <GridItemLabel item xs={2}>
            加入者番号
          </GridItemLabel>
          <GridItemValue item xs={2}>
            {wasteDischarge?.companyMemberNumber ?? ""}
          </GridItemValue>
        </Grid>
      </Grid>
      <GridItemLabel item xs={2}>
        排出事業場
      </GridItemLabel>
      <Grid item xs={10}>
        <Grid container columns={10}>
          <GridItemLabel item xs={2}>
            名称
          </GridItemLabel>
          <GridItemValue item xs={8}>
            {wasteDischarge?.workplaceName ?? ""}
          </GridItemValue>
          <GridItemLabel item xs={2}>
            所在地
          </GridItemLabel>
          <GridItemValue item xs={8}>
            {`〒 ${wasteDischarge?.workplacePostalCode ?? ""}　${
              wasteDischarge?.workplacePrefecturesName ?? ""
            }${wasteDischarge?.workplaceCity ?? ""}${
              wasteDischarge?.workplaceStreetAddress ?? ""
            }${wasteDischarge?.workplaceOtherAddress ?? ""}`}
          </GridItemValue>
          <GridItemLabel item xs={2}>
            電話番号
          </GridItemLabel>
          <GridItemValue item xs={8}>
            {wasteDischarge?.workplacePhone ?? ""}
          </GridItemValue>
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

const Section3 = ({ waste }) => (
  <Box component="section" sx={{ mt: 2, mb: 2 }}>
    <Grid container>
      <GridItemLabel item xs={2}>
        種類
      </GridItemLabel>
      <GridItemValue item xs={4}>
        {`${waste?.wasteTypeLargeClassCode ?? ""}${
          waste?.wasteTypeMiddleClassCode ?? ""
        }${waste?.wasteTypeSmallClassCode ?? ""}${
          waste?.wasteTypeSubClassCode ?? ""
        } ： ${waste?.kind ?? ""}`}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        大分類名称
      </GridItemLabel>
      <GridItemValue item xs={4}>
        {waste?.categoryName ?? ""}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        名称
      </GridItemLabel>
      <GridItemValue item xs={6}>
        {waste?.name ?? ""}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        荷姿
      </GridItemLabel>
      <GridItemValue item xs={2}>
        {waste?.packingName ?? ""}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        数量
      </GridItemLabel>
      <GridItemValue item xs={2}>
        {`${waste?.quantity ?? ""}${waste?.wasteQuantityUnit?.name ?? ""}`}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        確定数量
      </GridItemLabel>
      <GridItemValue item xs={2}>
        {`${waste?.confirmedQuantity ?? ""}${
          waste?.confirmedWasteQuantityUnit?.name ?? ""
        }`}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        荷姿の数量
      </GridItemLabel>
      <GridItemValue item xs={2}>
        {waste?.confirmedQuantity ?? ""}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        有害物質
      </GridItemLabel>
      <GridItemValue item xs={10}>
        {waste?.hazardousSubstances
          ?.map((substance) => substance?.name ?? "")
          ?.join() ?? ""}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        放射性物質
      </GridItemLabel>
      <GridItemValue item xs={10}>
        {waste?.radioactiveSubstances
          ?.map((substance) => substance?.name ?? "")
          ?.join() ?? ""}
      </GridItemValue>
    </Grid>
  </Box>
);

const Section4 = () => (
  <Box component="section" sx={{ mt: 2, mb: 2 }}>
    <Grid container>
      <GridItemLabel item xs={2}>
        最終処分の場所
      </GridItemLabel>
      <GridItemValue item xs={10}>
        委託契約書記載のとおり
      </GridItemValue>
    </Grid>
  </Box>
);

const Section5 = ({ wasteTransportSections }) => (
  <Box component="section" sx={{ mt: 2, mb: 2 }}>
    {[...Array(5)].map((_, index) => {
      const section = wasteTransportSections?.[index];
      if (section) {
        return (
          <Grid container key={index}>
            <GridItemLabel item xs={2}>
              {`区間${index + 1}`}
            </GridItemLabel>
            <GridItemLabel item xs={1}>
              運搬方法
            </GridItemLabel>
            <GridItemValue item xs={2}>
              {section?.carrier?.wasteTransportMethod?.name ?? ""}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              運搬担当者
            </GridItemLabel>
            <GridItemValue item xs={2}>
              {section?.carrier?.transportPersonInCharge ?? ""}
            </GridItemValue>
            <GridItemLabel item xs={1}>
              車両番号
            </GridItemLabel>
            <GridItemValue item xs={2}>
              {section?.carrier?.vehicleNumber ?? ""}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              収集運搬業者
            </GridItemLabel>
            <Grid item xs={10}>
              <Grid container columns={10}>
                <GridItemLabel item xs={2}>
                  氏名または名称
                </GridItemLabel>
                <GridItemValue item xs={8}>
                  {section?.carrier?.name ?? ""}
                </GridItemValue>
                <GridItemLabel item xs={2}>
                  住所
                </GridItemLabel>
                <GridItemValue item xs={8}>
                  {`〒 ${section?.carrier?.postalCode ?? ""}　${
                    section?.carrier?.prefecturesName ?? ""
                  }${section?.carrier?.city ?? ""}${
                    section?.carrier?.streetAddress ?? ""
                  }${section?.carrier?.otherAddress ?? ""}`}
                </GridItemValue>
                <GridItemLabel item xs={2}>
                  電話／FAX番号
                </GridItemLabel>
                <GridItemValue item xs={8}>
                  {`${section?.carrier?.phone ?? ""} ／ ${
                    section?.carrier?.fax ?? ""
                  }`}
                </GridItemValue>
                <GridItemLabel item xs={2}>
                  許可番号
                </GridItemLabel>
                <GridItemValue item xs={4}>
                  {section?.carrier?.unificationPermitNumber ?? ""}
                </GridItemValue>
                <GridItemLabel item xs={2}>
                  加入者番号
                </GridItemLabel>
                <GridItemValue item xs={2}>
                  {section?.carrier?.memberNumber ?? ""}
                </GridItemValue>
              </Grid>
            </Grid>
            <GridItemLabel item xs={2}>
              再委託先
              <br />
              収集運搬業者
            </GridItemLabel>
            <Grid item xs={10}>
              <Grid container columns={10}>
                <GridItemLabel item xs={2}>
                  氏名または名称
                </GridItemLabel>
                <GridItemValue item xs={8}>
                  {section?.carrier?.subcontractorName ?? ""}
                </GridItemValue>
                <GridItemLabel item xs={2}>
                  住所
                </GridItemLabel>
                <GridItemValue item xs={8}>
                  {`〒 ${section?.carrier?.subcontractorPostalCode ?? ""}　${
                    section?.carrier?.subcontractorPrefecturesName ?? ""
                  }${section?.carrier?.subcontractorCity ?? ""}${
                    section?.carrier?.subcontractorStreetAddress ?? ""
                  }${section?.carrier?.subcontractorOtherAddress ?? ""}`}
                </GridItemValue>
                <GridItemLabel item xs={2}>
                  電話／FAX番号
                </GridItemLabel>
                <GridItemValue item xs={8}>
                  {`${section?.carrier?.subcontractorPhone ?? ""} ／ ${
                    section?.carrier?.subcontractorFax ?? ""
                  }`}
                </GridItemValue>
                <GridItemLabel item xs={2}>
                  許可番号
                </GridItemLabel>
                <GridItemValue item xs={4}>
                  {section?.carrier?.subcontractorUnificationPermitNumber ?? ""}
                </GridItemValue>
                <GridItemLabel item xs={2}>
                  加入者番号
                </GridItemLabel>
                <GridItemValue item xs={2}>
                  {section?.carrier?.subcontractorMemberNumber ?? ""}
                </GridItemValue>
              </Grid>
            </Grid>
            <GridItemLabel item xs={2}>
              運搬先事業場
            </GridItemLabel>
            <Grid item xs={10}>
              <Grid container columns={10}>
                <GridItemLabel item xs={2}>
                  名称
                </GridItemLabel>
                <GridItemValue item xs={8}>
                  {section?.destination?.workplaceName ?? ""}
                </GridItemValue>
                <GridItemLabel item xs={2}>
                  所在地
                </GridItemLabel>
                <GridItemValue item xs={8}>
                  {`〒 ${section?.destination?.workplacePostalCode}　${
                    section?.destination?.workplacePrefecturesName ?? ""
                  }${section?.destination?.workplaceCity ?? ""}${
                    section?.destination?.workplaceStreetAddress ?? ""
                  }${section?.destination?.workplaceOtherAddress ?? ""}`}
                </GridItemValue>
                <GridItemLabel item xs={2}>
                  電話番号
                </GridItemLabel>
                <GridItemValue item xs={4}>
                  {section?.destination?.workplacePhone ?? ""}
                </GridItemValue>
                <GridItemLabel item xs={2}>
                  加入者番号
                </GridItemLabel>
                <GridItemValue item xs={2}>
                  {section.destination.memberNumber}
                </GridItemValue>
              </Grid>
            </Grid>
            <GridItemLabel item xs={2}>
              収集運搬業者
              <br />
              報告内容
            </GridItemLabel>
            <Grid item xs={10}>
              <Grid container columns={10}>
                <GridItemLabel item xs={1}>
                  運搬終了日
                </GridItemLabel>
                <GridItemValue item xs={2}>
                  {getDate(section?.report?.transportationEndedOn)}
                </GridItemValue>
                <GridItemLabel item xs={1}>
                  運搬担当者
                </GridItemLabel>
                <GridItemValue item xs={2}>
                  {section?.report?.personInChargeListed ?? ""}
                </GridItemValue>
                <GridItemLabel item xs={2}>
                  運搬量
                </GridItemLabel>
                <GridItemValue item xs={2}>
                  {`${section.report?.transportQuantity ?? ""}${
                    section.report?.transportWasteQuantityUnit?.name ?? ""
                  }`}
                </GridItemValue>
                <GridItemLabel item xs={1}>
                  報告担当者
                </GridItemLabel>
                <GridItemValue item xs={2}>
                  {section?.report?.reporter ?? ""}
                </GridItemValue>
                <GridItemLabel item xs={2}>
                  有価物収集量
                </GridItemLabel>
                <GridItemValue item xs={2}>
                  {`${section.report?.valuablesQuantity ?? ""}${
                    section.report?.valuablesQuantityUnit?.name ?? ""
                  }`}
                </GridItemValue>
                <GridItemLabel item xs={1}>
                  車両番号
                </GridItemLabel>
                <GridItemValue item xs={2}>
                  {section?.report?.vehicleNumberListed ?? ""}
                </GridItemValue>
                <GridItemLabel item xs={2}>
                  備考欄
                </GridItemLabel>
                <GridItemValue item xs={5}>
                  {section?.report?.remarks ?? ""}
                </GridItemValue>
                <Grid item xs={3} />
              </Grid>
            </Grid>
          </Grid>
        );
      } else {
        return <></>;
      }
    })}
  </Box>
);

const Section6 = ({
  wasteDisposal,
  wasteTransportSections,
  lastDisposalEndedOn,
  lastDisposalReportedOn,
}) => {
  const lastSection = wasteTransportSections?.slice(-1)?.[0];
  return (
    <Box component="section" sx={{ mt: 2, mb: 2 }}>
      <Grid container>
        <GridItemLabel item xs={2}>
          処分業者
        </GridItemLabel>
        <Grid item xs={10}>
          <Grid container columns={10}>
            <GridItemLabel item xs={2}>
              氏名または名称
            </GridItemLabel>
            <GridItemValue item xs={8}>
              {wasteDisposal?.name ?? ""}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              住所
            </GridItemLabel>
            <GridItemValue item xs={8}>
              {`〒 ${wasteDisposal?.postalCode ?? ""}　${
                wasteDisposal?.prefecturesName ?? ""
              }${wasteDisposal?.city ?? ""}${
                wasteDisposal?.streetAddress ?? ""
              }${wasteDisposal?.otherAddress ?? ""}`}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              電話／FAX番号
            </GridItemLabel>
            <GridItemValue item xs={8}>
              {`${wasteDisposal?.phone ?? ""} ／ ${wasteDisposal?.fax ?? ""}`}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              許可番号
            </GridItemLabel>
            <GridItemValue item xs={4}>
              {wasteDisposal?.unificationPermitNumber ?? ""}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              加入者番号
            </GridItemLabel>
            <GridItemValue item xs={2}>
              {wasteDisposal?.memberNumber ?? ""}
            </GridItemValue>
          </Grid>
        </Grid>
        <GridItemLabel item xs={2}>
          再委託先
          <br />
          処分業者
        </GridItemLabel>
        <Grid item xs={10}>
          <Grid container columns={10}>
            <GridItemLabel item xs={2}>
              氏名または名称
            </GridItemLabel>
            <GridItemValue item xs={8}>
              {wasteDisposal?.subcontractorName ?? ""}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              住所
            </GridItemLabel>
            <GridItemValue item xs={8}>
              {`〒 ${wasteDisposal?.subcontractorPostalCode ?? ""}　${
                wasteDisposal?.subcontractorPrefecturesName ?? ""
              }${wasteDisposal?.subcontractorCity ?? ""}${
                wasteDisposal?.subcontractorStreetAddress ?? ""
              }${wasteDisposal?.subcontractorOtherAddress ?? ""}`}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              電話／FAX番号
            </GridItemLabel>
            <GridItemValue item xs={8}>
              {`${wasteDisposal?.subcontractorPhone ?? ""} ／ ${
                wasteDisposal?.subcontractorFax ?? ""
              }`}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              許可番号
            </GridItemLabel>
            <GridItemValue item xs={4}>
              {wasteDisposal?.subcontractorUnificationPermitNumber ?? ""}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              加入者番号
            </GridItemLabel>
            <GridItemValue item xs={2}>
              {wasteDisposal?.subcontractorMemberNumber ?? ""}
            </GridItemValue>
          </Grid>
        </Grid>
        <GridItemLabel item xs={2}>
          処分事業場
        </GridItemLabel>
        <Grid item xs={10}>
          <Grid container columns={10}>
            <GridItemLabel item xs={2}>
              名称
            </GridItemLabel>
            <GridItemValue item xs={8}>
              {lastSection?.destination?.workplaceName ?? ""}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              所在地
            </GridItemLabel>
            <GridItemValue item xs={8}>
              {`〒 ${lastSection?.workplacePostalCode ?? ""}　${
                lastSection?.workplacePrefecturesName ?? ""
              }${lastSection?.workplaceCity ?? ""}${
                lastSection?.workplaceStreetAddress ?? ""
              }${lastSection?.workplaceOtherAddress ?? ""}`}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              電話番号
            </GridItemLabel>
            <GridItemValue item xs={4}>
              {lastSection?.workplacePhone ?? ""}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              処分方法
            </GridItemLabel>
            <GridItemValue item xs={2}>
              {(wasteDisposal?.wasteDisposalMethod?.smallClassName ||
                wasteDisposal?.wasteDisposalMethod?.middelClassName ||
                wasteDisposal?.wasteDisposalMethod?.largeClassName) ??
                ""}
            </GridItemValue>
          </Grid>
        </Grid>
        <GridItemLabel item xs={2}>
          処分業者
          <br />
          報告内容
        </GridItemLabel>
        <Grid item xs={10}>
          <Grid container columns={10}>
            <GridItemLabel item xs={1}>
              報告区分
            </GridItemLabel>
            <GridItemValue item xs={2}>
              {wasteDisposal?.report?.manifestApprovalPendingStatus?.name ?? ""}
            </GridItemValue>
            <GridItemLabel item xs={1}>
              処分担当者
            </GridItemLabel>
            <GridItemValue item xs={2}>
              {wasteDisposal?.report?.disposalPersonInCharge ?? ""}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              処分終了日
            </GridItemLabel>
            <GridItemValue item xs={2}>
              {getDate(wasteDisposal?.report?.disposalEndedOn)}
            </GridItemValue>
            <GridItemLabel item xs={1}>
              受入量
            </GridItemLabel>
            <GridItemValue item xs={2}>
              {`${wasteDisposal?.report?.receivingQuantity ?? ""}${
                wasteDisposal?.report?.receivingWasteQuantityUnit?.name ?? ""
              }`}
            </GridItemValue>
            <GridItemLabel item xs={1}>
              報告担当者
            </GridItemLabel>
            <GridItemValue item xs={2}>
              {wasteDisposal?.report?.reporter ?? ""}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              処分終了報告日
            </GridItemLabel>
            <GridItemValue item xs={2}>
              {getDate(wasteDisposal?.report?.reportedOn)}
            </GridItemValue>
            <GridItemLabel item xs={1}>
              車両番号
            </GridItemLabel>
            <GridItemValue item xs={2}>
              {wasteDisposal?.report?.vehicleNumber ?? ""}
            </GridItemValue>
            <GridItemLabel item xs={1}>
              運搬担当者
            </GridItemLabel>
            <GridItemValue item xs={2}>
              {wasteDisposal?.report?.transportPersonInCharge ?? ""}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              廃棄物受領日
            </GridItemLabel>
            <GridItemValue item xs={2}>
              {getDate(wasteDisposal?.report?.wasteReceiptedOn)}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              最終処分終了日
            </GridItemLabel>
            <GridItemValue item xs={3}>
              {getDate(lastDisposalEndedOn)}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              最終処分終了報告日時
            </GridItemLabel>
            <GridItemValue item xs={3}>
              {getDate(lastDisposalReportedOn)}
            </GridItemValue>
            <GridItemLabel item xs={2}>
              処分業者備考欄
            </GridItemLabel>
            <GridItemValue item xs={8}>
              {wasteDisposal?.report?.remarks}
            </GridItemValue>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const Section7 = ({ wasteLastDisposals }) => (
  <Box component="section" sx={{ mt: 2, mb: 2 }}>
    <Grid container>
      {wasteLastDisposals?.map((wasteLastDisposal, index) => {
        return (
          <React.Fragment key={index}>
            <GridItemLabel item xs={1}>
              No
            </GridItemLabel>
            <GridItemLabel item xs={2}>
              最終処分終了日
            </GridItemLabel>
            <GridItemLabel item xs={4}>
              名称
            </GridItemLabel>
            <GridItemLabel item xs={4}>
              所在地
            </GridItemLabel>
            <GridItemLabel item xs={1}>
              電話番号
            </GridItemLabel>
            <GridItemValue item xs={1}>
              {index + 1}
            </GridItemValue>
            <GridItemValue item xs={2}>
              {getDate(wasteLastDisposal?.lastDisposalEndedOn)}
            </GridItemValue>
            <GridItemValue item xs={4}>
              {wasteLastDisposal?.disposalPlantName ?? ""}
            </GridItemValue>
            <GridItemValue item xs={4}>
              {`〒${wasteLastDisposal?.disposalPlantPostalCode ?? ""}　${
                wasteLastDisposal?.disposalPlantPrefecturesName ?? ""
              }${wasteLastDisposal?.disposalPlantCity ?? ""}${
                wasteLastDisposal?.disposalPlantStreetAddress ?? ""
              }${wasteLastDisposal?.disposalPlantOtherAddress ?? ""}`}
            </GridItemValue>
            <GridItemValue item xs={1}>
              {wasteLastDisposal?.disposalPlantPhone ?? ""}
            </GridItemValue>
          </React.Fragment>
        );
      }) ?? <></>}
    </Grid>
  </Box>
);

const Section8 = ({ remarks }) => (
  <Box component="section" sx={{ mt: 2, mb: 2 }}>
    <Grid container>
      {[...Array(5)].map((_, index) => {
        return (
          <React.Fragment key={index}>
            <GridItemLabel item xs={1}>
              {`備考${index + 1}`}
            </GridItemLabel>
            <GridItemValue item xs={3}>
              {remarks?.find((remark) => remark.number === index + 1)?.value ??
                ""}
            </GridItemValue>
          </React.Fragment>
        );
      })}
      <GridItemLabel item xs={4}></GridItemLabel>
    </Grid>
  </Box>
);

const Section9 = ({ registeredOn, billedOn, lastUpdatedOn, canceledOn }) => (
  <Box component="section" sx={{ mt: 2, mb: 2 }}>
    <Grid container>
      <GridItemLabel item xs={2}>
        登録日時
      </GridItemLabel>
      <GridItemValue item xs={2}>
        {getDate(registeredOn)}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        予約登録日時
      </GridItemLabel>
      <GridItemValue item xs={2}>
        {""}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        最終更新日時
      </GridItemLabel>
      <GridItemValue item xs={2}>
        {getDate(lastUpdatedOn)}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        取消日時
      </GridItemLabel>
      <GridItemValue item xs={2}>
        {getDate(canceledOn)}
      </GridItemValue>
      <GridItemLabel item xs={2}>
        課金日
      </GridItemLabel>
      <GridItemValue item xs={2}>
        {getDate(billedOn)}
      </GridItemValue>
      <GridItemLabel item xs={4}></GridItemLabel>
    </Grid>
  </Box>
);

/**
 *  電子マニフェストの詳細画面の表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props
 * @returns JSX 要素
 */
export const Presententer = ({
  value,
  open,
  onOpen,
  onClose,
  isSubmit,
  onReport,
  onEdit,
  onCancel,
  formRef,
  loading,
  tab,
  onChangeTab,
  requestingSections,
  existsRequesting,
  allRequesting,
}) => {
  return (
    <Container sx={{ pt: 2 }}>
      <>
        <Paper sx={{ p: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Stack direction={"row"} spacing={2}>
              {!loading && existsRequesting && (
                <Button variant="outlined" disabled>
                  処理中
                </Button>
              )}
              {!loading &&
                !value?.isLock &&
                !allRequesting &&
                !value?.canceledOn &&
                value?.manifestInfoType?.code === "2" && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onOpen}
                  >
                    運搬終了報告
                  </Button>
                )}
              {loading && <Skeleton width={179} height={42} />}
            </Stack>
          </Box>
          {!loading && (
            <>
              <Section1 {...value} />
              <Typography variant="h6">排出事業者情報</Typography>
              <Section2 {...value} />
              <Typography variant="h6">産業廃棄物情報</Typography>
              <Section3 {...value} />
              <Typography variant="h6">最終処分の場所（予定）</Typography>
              <Section4 {...value} />
              <Typography variant="h6">収集運搬情報</Typography>
              <Section5 {...value} />
              <Typography variant="h6">処分情報</Typography>
              <Section6 {...value} />
              <Typography variant="h6">最終処分事業場（実績）</Typography>
              <Section7 {...value} />
              <Typography variant="h6">排出事業者備考欄</Typography>
              <Section8 {...value} />
              <Typography variant="h6">日時情報</Typography>
              <Section9 {...value} />
            </>
          )}
          {loading && (
            <>
              <Skeleton width={800} height={42} />
              <Skeleton width={800} height={42} />
              <Skeleton width={800} height={42} />
            </>
          )}
        </Paper>
        <FullScreenDialog
          open={open}
          title="電子マニフェスト運搬終了報告"
          textConfirm="登録"
          onClose={onClose}
          isSubmit={isSubmit}
          visibilityConfirm={false}
        >
          <Container maxWidth="md">
            <Paper
              elevation={3}
              sx={{
                padding: 5,
              }}
            >
              <Tabs value={tab} onChange={onChangeTab} centered>
                {value?.wasteTransportSections
                  ?.filter((section) => section.reportable)
                  ?.map((section, index) => (
                    <Tab
                      key={index}
                      value={section.sectionNumber}
                      label={`区間${section.sectionNumber}`}
                    />
                  ))}
              </Tabs>
              <Form
                value={value?.wasteTransportSections?.find(
                  (section) => section.sectionNumber === tab
                )}
                requesting={
                  requestingSections?.findIndex(
                    (section) => section.sectionNumber === tab
                  ) !== -1
                }
                ref={formRef}
                onReport={onReport}
                onEdit={onEdit}
                onCancel={onCancel}
              />
            </Paper>
          </Container>
        </FullScreenDialog>
      </>
    </Container>
  );
};
