export const styles = (theme) => ({
  root: {
    fontSize: "14px",
    color: theme.palette.grey[600],
    "& .course_name": {
      color: "black",
      marginTop: theme.spacing(1),
    },
    "& .drivers": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      "& .avatar": {
        fontSize: "12px",
        backgroundColor: theme.palette.icon.company,
        width: 24,
        height: 24,
        marginRight: theme.spacing(1),
      },
    },
    "& .vehicle": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& .avatar": {
        fontSize: "12px",
        backgroundColor: theme.palette.icon.company,
        width: 24,
        height: 24,
        marginRight: theme.spacing(1),
      },
      "& .number": {
        color: theme.palette.grey[600],
        fontSize: "12px",
      },
      "& .type": {
        fontWeight: "500",
        fontSize: "12px",
      },
    },
  },
});
