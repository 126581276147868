import { useEffect, useState } from "react";
import { styles } from "./styles";
import useClasses from "utils/useClasses";

/**
 * 郵便番号を入力するコンテナコンポーネントです。
 * @param {string} label テキストのレベル
 * @param {funct} onChange データ更新を処理する
 * @param {boolean} required 必須かどうか設定
 * @param {string} value テキストフィールドのバリュー
 * @param {object} onGetFullAddress 全体の住所を取得します。
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onChange,
  label,
  required,
  onGetFullAddress,
  error,
  ...props
}) => {
  const [state, setState] = useState(value);
  const [errors, setErrors] = useState(error?.message ?? "");
  const classes = useClasses(styles);

  useEffect(() => {
    setState(value);
  }, [value]);

  useEffect(() => {
    setErrors(error?.message || "");
  }, [error?.message]);

  const handleOnchange = (e) => {
    const postCode = e.target.value;
    setState(postCode);
    onChange(postCode);
    if (e.target.value.length === 8) {
      setErrors("");
    }
  };

  const handleSearch = () => {
    const zipcode = state.replace(/\D/g, "");

    fetch(`https://api.zipaddress.net/?zipcode=${zipcode}`, {
      mode: "cors",
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        if (result.code === 200) {
          onGetFullAddress && onGetFullAddress(result?.data);
          setErrors("");
        } else if (result.code === 404) {
          setErrors("住所が見つかりませんでした");
          onGetFullAddress && onGetFullAddress("");
        }
      });
  };

  return render({
    classes: classes,
    state: state,
    errors: errors,
    label: label,
    required: required,
    onChange: handleOnchange,
    onSearchShow: state?.length === 8,
    onSearch: handleSearch,
    ...props,
  });
};
