import { Schedule as ScheduleIcon } from "@mui/icons-material";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Link,
  Button,
  Skeleton,
} from "@mui/material";
import { RenderButton } from "views/atoms";
import { FullScreenDialog, ReactBottomSheet } from "views/molecules";
import { NaviModeDetails } from "../NaviModeDetails";
import {
  Title as CautionsTitle,
  ListItem as CautionsListItem,
  Item as CautionsItem,
} from "../Cautions";

/**
 * 回収を開始するリスト表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <ReactBottomSheet
      isOpen={props.open}
      onClose={props.onClose}
      defaultsHeight={137}
      subtractedHeight={133}
    >
      <>
        <Box className={props.classes.header}>
          <List dense={true} disablePadding={true} className={"collect_item"}>
            <ListItem disableGutters={true}>
              <ListItemAvatar>
                <RenderButton
                  variant="outlined"
                  color="primary"
                  className={"number"}
                  label={props.number}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <span className={props.classes.collect_title}>
                    {props?.value?.workplace?.name}
                  </span>
                }
                secondary={
                  <span className={props.classes.collect_Subtitle}>
                    <span className={"time"}>
                      <ScheduleIcon /> {props?.scheduledTime}
                    </span>
                  </span>
                }
              />
            </ListItem>
          </List>
          <Box width="100%" pl={2} pr={2} textAlign="right">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              component={Link}
              target="_blank"
              disabled={!props.destination || !props.value}
              href={`https://www.google.com/maps/dir/?api=1${
                props.position
                  ? `&origin=${props.position?.coords?.latitude},${props.position?.coords?.longitude}`
                  : ""
              }&destination=${props.destination?.lat},${
                props.destination?.lng
              }&travelmode=driving&dir_action=navigate&hl=ja`}
            >
              <span>ナビモード</span>
            </Button>
            <CautionsTitle />
            {!!props.isLoadingSearchedCollectionPrecautions ? (
              <Skeleton variant="rectangular" height={100} />
            ) : (
              <>
                {props.searchedCollectionPrecautions.length > 0 ? (
                  props.searchedCollectionPrecautions.map((v, index) => (
                    <CautionsListItem
                      key={index}
                      value={v}
                      onClick={props.onClickCollectionPrecautionListItem}
                    />
                  ))
                ) : (
                  <div>登録されていません。</div>
                )}
              </>
            )}
            <FullScreenDialog
              open={props.isOpenCollectionPrecautionDialog}
              title="回収注意点"
              onClose={props.onCloseCollectionPrecautionDialog}
              visibilityConfirm={false}
            >
              <CautionsItem
                value={props.selectedCollectionPrecaution}
                loading={props.isLoadingCollectionPrecaution}
              />
            </FullScreenDialog>
          </Box>
        </Box>
        <NaviModeDetails value={props.value} />
      </>
    </ReactBottomSheet>
  );
};
