import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { styles } from "./styles";

/**
 * 紙マニフェスト詳細を表示するコンテナコンポーネントです
 * @callback render
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onClick = (event) => {
    debugLog("セクションが押された: ", event);
  },
  disabled = true,
  ...props
}) => {
  const classes = useClasses(styles);
  
  return render({
    classes: classes,
    value: value,
    onClick: onClick,
    disabled: disabled,
    ...props,
  });
};
