import { Dialog } from "../Dialog";
import { PrivacyPolicyPDF } from "../PrivacyPolicyPDF";

/**
 * プライバシーポリシーダイアログを表示するコンポーネント。
 * @param {object} props プロパティ
 * @param {boolean} props.open 開閉状態
 * @param {boolean} props.disabed 無効設定
 * @fires PrivacyPolicyAgreeDialog#onClose 閉じる時
 * @fires PrivacyPolicyAgreeDialog#onAgree 同意する時
 * @returns {JSX.Element}
 */
export const PrivacyPolicyAgreeDialog = ({
  open = false,
  disabled,
  onClose = () => {},
  onAgree = () => {},
}) => {
  return (
    <Dialog
      onClose={onClose}
      onAgree={onAgree}
      open={open}
      title={"プライバシーポリシー"}
      description={""}
      disabled={disabled}
      positiveText={"同意します"}
      negativeText={"同意しません"}
    >
      <PrivacyPolicyPDF />
    </Dialog>
  );
};
