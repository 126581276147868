import React from "react";
import { Grid } from "@mui/material";
import { Controller } from "react-hook-form";
import { AddressFromPostCode, InputField, SelectPopup } from "views/molecules";

/**
 * 取引先事業場を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <form id={props.id} onSubmit={props.submit}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          control={props.control}
          name="code"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="固有コード"
              placeholder="事業場を識別するコードを入力します。"
              value={value}
              onChange={onChange}
              error={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={props.control}
          name="industrySector"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SelectPopup
              label="業種"
              required={false}
              value={value}
              onChange={onChange}
              options={props.industrySectors}
              error={error}
              optionValueField="id"
              optionNameField="name"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="name"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="名称"
              required={true}
              placeholder="名称を入力します。省略した場合は「本社」となります。"
              value={value}
              onChange={onChange}
              error={error}
            />
          )}
        />
      </Grid>
      <AddressFromPostCode suffix="" />
      <Grid item xs={12}>
        <Controller
          control={props.control}
          name="phone"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="電話番号"
              placeholder="例）000-0000-0000"
              value={value}
              onChange={onChange}
              error={error}
              inputProps={{
                maxLength: 15,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={props.control}
          name="fax"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="FAX番号"
              placeholder="例）000-0000-0000"
              value={value}
              onChange={onChange}
              error={error}
              inputProps={{
                maxLength: 15,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={props.control}
          name="email"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="メールアドレス"
              placeholder="例）info@ecope-pro.co.jp"
              value={value}
              onChange={onChange}
              error={error}
            />
          )}
        />
      </Grid>
    </Grid>
  </form>
);
