/**
 * コピーライトを表示するコンテナコンポーネントです。
 * @returns {func} 描画関数
 */
const Container = ({
  render,
  systemUrl = process.env.REACT_APP_SYSTEM_PROVIDER_URL,
  providerName = process.env.REACT_APP_SYSTEM_PROVIDER_NAME,
  ...props
}) => {
  return render({
    providerName: providerName,
    systemUrl: systemUrl,
    ...props,
  });
};

export default Container;
