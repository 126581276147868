import { useStyles } from "./styles";
import { useForm } from "react-hook-form";

/**
 * 連絡先確認画面を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @fires Container#onTransiteLogin ログイン画面遷移時
 * @fires Container#onVerify 確認時
 * @fires Container#onSubmit 送信時
 * @fires Container#onError エラー時
 * @param {boolean} delivery 認証コードが送信されたか
 * @param {boolean} loading ロード中か
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  onTransiteLogin,
  onVerify,
  onSubmit,
  onError,
  delivery,
  loading,
  ...props
}) => {
  const classes = useStyles();
  const { handleSubmit, control } = useForm({
    mode: "onBlur",
    defaultValues: {
      code: "",
    },
  });

  return render({
    classes: classes,
    onTransiteLogin,
    onVerify: onVerify,
    submit: handleSubmit(onSubmit, onError),
    delivery: delivery,
    loading: loading,
    control: control,
    ...props,
  });
};
