import { createSelector, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  deleteCourseThunk,
  fetchAllData,
  fetchUserAccountsByOffset,
  fetchVehiclesByOffset,
  fetchVirtualCoursePointsByOffset,
  fetchUnassignedOrders,
  fetchTrashedOrders,
  createAutomationSettingsThunk,
} from "./api";
const limit = 10;
const defaultPagination = {
  offset: 0,
  limit,
  total: 0,
};

const initialState = {
  data: {
    trashed: [],
    still: [],
    done: {},
  },
  isLoading: false,
  isDeleting: false,
  isPointLoading: false,
  isUnassignedLoading: false,
  error: null,
  drivers: null,
  vehicles: null,
  unAssignedPagination: defaultPagination,
  trashedPagination: defaultPagination,
};

const automationSlice = createSlice({
  name: "automations",
  initialState,
  reducers: {
    addNewCourse(state, action) {
      const course = action.payload;
      if (!state.data?.done?.courses) {
        state.data.done.courses = [course];
        return;
      }
      const courses = [...state.data.done.courses, course];
      state.data.done.courses = courses;
    },
    updateCoursesOrder(state, action) {
      const { courses } = action.payload;
      state.data.done.courses = courses;
    },
    removeNewCourse(state, action) {
      const courses = state.data.done.courses.filter((course) =>
        action.payload ? action.payload !== course.id : !course.isNewCourse
      );
      state.data.done.courses = courses;
    },
    updateAutomationCourses(state, action) {
      const done = action.payload;
      state.data.done = done;
    },
    resetToInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllData.fulfilled, (state, action) => {
        state.data = {
          still: action.payload.still.items,
          done: action.payload.done,
          trashed: action.payload.trashed.items,
        };
        state.unAssignedPagination = {
          ...state.unAssignedPagination,
          offset: state.unAssignedPagination.limit,
          total: action.payload.still.total,
        };
        state.trashedPagination = {
          ...state.trashedPagination,
          offset: state.trashedPagination.limit,
          total: action.payload.trashed.total,
        };
        state.isLoading = false;
      })
      .addCase(fetchAllData.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      })
      .addCase(deleteCourseThunk.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(deleteCourseThunk.fulfilled, (state, action) => {
        state.isDeleting = false;
        state.data.done.courses = state.data.done.courses.filter(
          (course) => course.id !== action.payload
        );
      })
      .addCase(deleteCourseThunk.rejected, (state, action) => {
        state.error = action.error;
        state.isDeleting = false;
      })
      .addCase(fetchVehiclesByOffset.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchVehiclesByOffset.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vehicles = action.payload.items;
      })
      .addCase(fetchVehiclesByOffset.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(fetchUserAccountsByOffset.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserAccountsByOffset.fulfilled, (state, action) => {
        state.isLoading = false;
        state.drivers = action.payload.items;
      })
      .addCase(fetchUserAccountsByOffset.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })
      .addCase(fetchVirtualCoursePointsByOffset.pending, (state) => {
        state.isPointLoading = true;
      })
      .addCase(fetchVirtualCoursePointsByOffset.fulfilled, (state, action) => {
        state.isPointLoading = false;
        state.data.done.courses = state.data.done.courses.map((item) => {
          if (item.id === action.payload.virtualCourseId) {
            item.points = action.payload.points;
          }
          return item;
        });
      })
      .addCase(fetchVirtualCoursePointsByOffset.rejected, (state, action) => {
        state.isPointLoading = false;
        state.error = action.error;
      })
      .addCase(fetchUnassignedOrders.pending, (state) => {
        state.isUnassignedLoading = true;
      })
      .addCase(fetchUnassignedOrders.fulfilled, (state, action) => {
        state.isUnassignedLoading = false;
        state.data.still = _.uniqBy(
          [...state.data.still, ...action.payload.items],
          "cycleId"
        );
        state.unAssignedPagination = {
          ...state.unAssignedPagination,
          offset:
            state.unAssignedPagination.offset +
            state.unAssignedPagination.limit,
          total: action.payload.total,
        };
      })
      .addCase(fetchUnassignedOrders.rejected, (state, action) => {
        state.isUnassignedLoading = false;
        state.error = action.error;
      })
      .addCase(fetchTrashedOrders.pending, (state, action) => {
        state.isUnassignedLoading = true;
      })
      .addCase(fetchTrashedOrders.fulfilled, (state, action) => {
        state.isUnassignedLoading = false;
        state.data.trashed = _.uniqBy(
          [...state.data.trashed, ...action.payload.items],
          "cycleId"
        );
        state.trashedPagination = {
          ...state.trashedPagination,
          offset:
            state.trashedPagination.offset + state.trashedPagination.limit,
          total: action.payload.total,
        };
      })
      .addCase(fetchTrashedOrders.rejected, (state, action) => {
        state.isUnassignedLoading = false;
        state.error = action.error;
      })
      .addCase(createAutomationSettingsThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAutomationSettingsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.done = action.payload;
      })
      .addCase(createAutomationSettingsThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export const selector = (store) => store.Automation;

export const automationsSliceSelector = createSelector(
  selector,
  (automationsSlice) => automationsSlice
);

export const {
  addNewCourse,
  updateCoursesOrder,
  removeNewCourse,
  updateAutomationCourses,
  resetToInitialState,
} = automationSlice.actions;

export default automationSlice.reducer;
