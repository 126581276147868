import DateFnsUtils from "@date-io/date-fns";
import { InsertInvitationOutlined } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box, TextField } from "@mui/material";
import clsx from "clsx";
import jaLocale from "date-fns/locale/ja";
import React from "react";

/**
 * 日付の選択の表示を行うプレゼンテーションコンポーネントです
 * @param {string} value 日付
 * @param {func} onChange 日付を更新するイベントです。
 * @param {string} label 日付テキストのラベル
 * @param {boolean} hideLabel 日付テキストのラベル
 */
export const Presententer = (props) => {
  return (
    <Box display="flex" flexDirection="column" fontSize="14px" width={180}>
      {props?.label && (
        <div
          className={clsx(
            props.styleClass.label,
            props.hideLabel && props.styleClass.hideLabel
          )}
        >
          {props.label}
          {props.required ? (
            <span className={props.styleClass.required}>必須</span>
          ) : null}
        </div>
      )}
      <LocalizationProvider
        utils={DateFnsUtils}
        adapterLocale={jaLocale}
        dateAdapter={AdapterDateFns}
      >
        <DatePicker
          autoOk
          InputLabelProps={{ shrink: true }}
          inputVariant="outlined"
          variant="inline"
          format="yyyy/MM/dd"
          fullWidth
          value={props?.date ?? ""}
          InputAdornmentProps={{ position: "end" }}
          keyboardIcon={<InsertInvitationOutlined fontSize="small" />}
          onChange={(date) => {
            props.datechange(date);
          }}
          PopoverProps={{
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }}
          mask={"____/__/__"}
          renderInput={(parmas) => {
            return (
              <TextField
                {...parmas}
                size={props.size || "large"}
                fullWidth
                helperText={props?.error?.message}
                error={Boolean(props.error)}
              />
            );
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};
