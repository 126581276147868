import { ConfirmDialog } from "views/molecules";
import { useMutation } from "utils/useMutation";
import { resumeUserAccount } from "api/graphql/mutations";

/**
 * 利用再開するダイアログを表示するコンポーネント。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {boolean} props.open 開閉状態
 * @fires StopDialog#onClose 閉じる時
 * @returns {JSX.Element}
 */
export const ResumeDialog = ({
  value = null,
  open = false,
  onCompleted = () => {},
  onClose = () => {},
}) => {
  const [resumeMutation, resumeMutationStatus] = useMutation(
    resumeUserAccount,
    {
      onCompleted: () => {
        onCompleted();
        onClose();
      },
      succeedMessage: "利用再開しました。",
      errorMessage: "エラーが発生したため、利用再開できませんでした。",
    }
  );

  return (
    <ConfirmDialog
      disabled={resumeMutationStatus.loading}
      open={open}
      title="利用再開確認"
      message="利用再開します。よろしいですか？"
      positiveText="はい"
      negativeText="いいえ"
      onPositive={() => {
        resumeMutation({
          input: {
            id: value.id,
          },
        });
      }}
      onNegative={onClose}
    />
  );
};
