import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 許可証の情報を表示するコンポーネントです
 * @param {array} dataTable テーブルの値
 * @param {func} onTransite　詳細ページに移動させる
 */
export const LicenseList = containerPresententer(Container, Presententer);
