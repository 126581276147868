import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { styles } from "./styles";
import { basicSchema } from "./Validations/basic";

/**
 * デフォルトで設定される値
 */
const defaultValues = {
  name: "",
  corporateNumber: null,
  representativeName: "",
  establishmentedOn: null,
  url: null,
  headOffice: null,
};

/**
 * 事業者情報のフォームを行うコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string} id 固有番号
 * @param {object} value 値
 * @fires Container#onSubmit 送信時
 * @fires Container#onError エラー時
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  id,
  value,
  onSubmit,
  onError = (errors) => {
    debugLog("CorporationForm.onError", errors);
  },
  ...props
}) => {
  const classes = useClasses(styles);
  const { control, setValue, getValues, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(basicSchema),
  });
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangeHeadOffice = (data) => {
    debugLog("CorporationForm.onChangeHeadOffice", data);
    setValue("headOffice", data?.value?.[0], { shouldValidate: true });
  };

  return render({
    id: id,
    classes: classes,
    control: control,
    openModal: openModal,
    onOpenModal: handleOpenModal,
    onCloseModal: handleCloseModal,
    onChangeHeadOffice: handleChangeHeadOffice,
    getValues: getValues,
    submit: handleSubmit(onSubmit, onError),
    ...props,
  });
};
