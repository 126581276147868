import {
  createIndustrialWasteLicense,
  createMunicipalSolidWasteLicense,
} from "api/graphql/mutations";
import { searchWasteLicensesByOffset } from "api/graphql/queries";
import { DateTime } from "luxon";
import { useState } from "react";
import { useMutation } from "utils/useMutation";
import { useXGridComponents } from "utils/useXGridComponents";
import { toApiValue } from "utils/ImageFunctions";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

const formatDate = (params) =>
  params.value ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd") : "";

const columns = [
  {
    field: "number",
    headerName: "許可番号",
    minWidth: 130,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "japaneseLocalGovernmentId",
    headerName: "許可主体",
    minWidth: 170,
    flex: 1,
    renderCell: (params) =>
      `${params.row?.japaneseLocalGovernment.prefectures?.name}${
        params.row?.japaneseLocalGovernment.cityName ?? ""
      }`,
    filterable: false,
  },
  {
    field: "categoryName",
    headerName: "業の区分",
    minWidth: 350,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "licensedOn",
    headerName: "許可の年月日",
    minWidth: 170,
    flex: 1,
    valueFormatter: formatDate,
    hide: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "licenseExpirationOn",
    headerName: "許可の有効年月日",
    minWidth: 200,
    flex: 1,
    valueFormatter: formatDate,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "certifiedAsExcellentOn",
    headerName: "優良認定日",
    minWidth: 170,
    flex: 1,
    valueFormatter: formatDate,
    hide: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
];

/**
 * 許可証情報を表示するコンテナコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onSelected = (data) => console.log(data),
  onSubmitted = (data) => console.log(data),
  initialState,
  ...props
}) => {
  const xGridObject = useXGridComponents(
    columns,
    searchWasteLicensesByOffset,
    null,
    initialState
  );
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openDialogGeneralWaste, setOpenDialogGeneralWaste] = useState(false);
  const [openDialogIndustrialWaste, setOpenDialogIndustrialWaste] =
    useState(false);
  const [runCreateIndustrialWasteLicense, createIndustrialWasteLicenseStatus] =
    useMutation(createIndustrialWasteLicense, {
      onCompleted: (data) => {
        onSubmitted && onSubmitted(data);
        xGridObject.functions.refetch();
        setOpenDialogIndustrialWaste(false);
      },
      succeedMessage: "登録しました。",
      errorMessage: "エラーが発生したため、登録できませんでした。",
    });

  const [
    runCreateMunicipalSolidWasteLicense,
    createMunicipalSolidWasteLicenseStatus,
  ] = useMutation(createMunicipalSolidWasteLicense, {
    onCompleted: (data) => {
      onSubmitted && onSubmitted(data);
      xGridObject.functions.refetch();
      setOpenDialogGeneralWaste(false);
    },
    succeedMessage: "登録しました。",
    errorMessage: "エラーが発生したため、登録できませんでした。",
  });

  const handleOnChangeSeletion = (items) => {
    onSelected({
      value: xGridObject.params.rows.find((row) => row.id === items[0]),
      info: {
        search: xGridObject.search,
      },
    });
  };

  const handleSubmitIndustrialWaste = async (params) => {
    const {
      category,
      licenseExpirationOn,
      licensedOn,
      certifiedAsExcellentOn,
      japaneseLocalGovernment,
      files,
      categoryName,
      prefectures,
      inputType,
      position,
      ...other
    } = params;

    runCreateIndustrialWasteLicense({
      input: {
        ...other,
        industrialWasteLicenseBusinessCategoryCode: category.code,
        japaneseLocalGovernmentId: japaneseLocalGovernment?.id,
        licenseExpirationOn:
          licenseExpirationOn &&
          DateTime.fromJSDate(new Date(licenseExpirationOn)).toFormat(
            "yyyy-MM-dd"
          ),
        licensedOn:
          licensedOn &&
          DateTime.fromJSDate(new Date(licensedOn)).toFormat("yyyy-MM-dd"),
        certifiedAsExcellentOn:
          certifiedAsExcellentOn &&
          DateTime.fromJSDate(new Date(certifiedAsExcellentOn)).toFormat(
            "yyyy-MM-dd"
          ),
        prefecturesCode: prefectures.code,
        files: await toApiValue({ files: files }),
        isMapInput: inputType === "from-map" ? true : false,
      },
    });
  };

  const handleSubmitGeneralWaste = async (params) => {
    const {
      category,
      licenseExpirationOn,
      licensedOn,
      prefectures,
      japaneseLocalGovernment,
      files,
      inputType,
      position,
      ...other
    } = params;

    runCreateMunicipalSolidWasteLicense({
      input: {
        ...other,
        municipalSolidWasteLicenseBusinessCategoryCode: category.code,
        japaneseLocalGovernmentId: japaneseLocalGovernment.id,
        licenseExpirationOn:
          licenseExpirationOn &&
          DateTime.fromJSDate(new Date(licenseExpirationOn)).toFormat(
            "yyyy-MM-dd"
          ),
        licensedOn:
          licensedOn &&
          DateTime.fromJSDate(new Date(licensedOn)).toFormat("yyyy-MM-dd"),
        prefecturesCode: prefectures.code,
        files: await toApiValue({ files: files }),
        isMapInput: inputType === "from-map" ? true : false,
      },
    });
  };

  return render({
    ...props,
    openModal: openSelectModal,
    onOpenSelectModal: () => setOpenSelectModal(true),
    onCloseSelectModal: () => setOpenSelectModal(false),
    openDialogGeneral: openDialogGeneralWaste,
    onOpenDialogGeneral: () => {
      setOpenDialogGeneralWaste(true);
      setOpenSelectModal(false);
    },
    onCloseDialogGeneral: () => setOpenDialogGeneralWaste(false),
    onSubmitGeneralWaste: handleSubmitGeneralWaste,
    openDialogIndustrial: openDialogIndustrialWaste,
    onOpenDialogIndustrial: () => {
      setOpenDialogIndustrialWaste(true);
      setOpenSelectModal(false);
    },
    onCloseDialogIndustrial: () => setOpenDialogIndustrialWaste(false),
    onSubmitIndustrialWaste: handleSubmitIndustrialWaste,
    value: value,
    onChangeSelection: handleOnChangeSeletion,
    xGridParams: xGridObject.params,
    loading:
      createIndustrialWasteLicenseStatus.loading ||
      createMunicipalSolidWasteLicenseStatus.loading,
  });
};
