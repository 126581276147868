import yup from "utils/yup";

export const schema = yup.object().shape({
  number: yup
    .string()
    .halfWidthAlphanumeric()
    .maxByteLength(7)
    .required()
    .nullable(),
    ediUseConfirmationKey: yup
    .string()
    .halfWidthAlphanumeric()
    .maxByteLength(8)
    .required()
    .nullable(),
});
