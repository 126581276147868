import { requiredField, style_inputField } from "utils/styles";

export const styles = ((theme) => ({
  ...style_inputField(theme),
  label: {
    color: theme.palette.text.secondary,
  },
  required: {
    ...requiredField(theme),
  },
  error_message: {
    fontSize: "0.7rem",
    whiteSpace: "nowrap",
  },
  icon_hepl: {
    padding: 0,
    float: "right",
    "& svg": {
      width: "20px",
      height: "20px",
    },
  },
}));
