import yup, { dateSchema, remarksSchema, textSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  manageWorkplace: yup.object().required(),
  additionalTypes: yup.object().required(),
  addedOn: dateSchema.required(),
  number: textSchema.required(),
  remarks: remarksSchema,
  files: yup.array(),
});
