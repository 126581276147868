import React from "react";
import { RenderButtonToolBar } from "utils/XgridExpand";
import { FullScreenDialog, XGridComponent } from "views/molecules";
import { PartnerForm } from "views/organisms/Masters/Partner";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

/**
 * 取引先を選択するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <>
    <XGridComponent
      autoHeight={props.autoHeight}
      onChangeSelection={props.onSelected}
      checkboxSelection={false}
      multipleSelection={false}
      onDownload={true}
      actionButton={
        <>
          {props.add && props.supplierCompanyId && (
            <RenderButtonToolBar
              label="新しい取引先を追加する"
              onClick={props.onOpenDialog}
            />
          )}
          {props.selectableOwn && (
            <RenderButtonToolBar
              label="自社を選択する"
              onClick={() => props.onOwnSelected(props.company)}
            />
          )}
        </>
      }
      {...props.xGridParams}
    />
    <Dialog open={props.isOpenConfirmDialog} onClose={props.closeConfirmDialog}>
      <DialogTitle>確認</DialogTitle>
      <DialogContent>
        <DialogContentText>
          入力された住所から位置情報が取得できません。
          このまま続行すると位置情報を利用する一部サービスで使用できない場合があります。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeConfirmDialog}>キャンセル</Button>
        <Button onClick={props.forceSubmit} autoFocus>
          続行
        </Button>
      </DialogActions>
    </Dialog>
    <FullScreenDialog
      onClose={props.onCloseDialog}
      title={"新しい取引先の登録"}
      textConfirm={"登録"}
      open={props.open}
      formId="partner-form"
    >
      <PartnerForm id="partner-form" onSubmit={props.onSubmit} />
    </FullScreenDialog>
  </>
);
