import { Menu } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  LinearProgress,
  styled,
  Toolbar,
} from "@mui/material";
import clsx from "clsx";
import {
  Alert,
  // BeginnerMark,
  // FeedbackButton,
  // Notifications,
  RouterBreadcrumbs,
} from "views/molecules";
// import { FeedbackModal } from "../Feedback";
import "utils/scrollbar.css";
import { drawerWidth } from "./styles";
import { If } from "views/atoms";
import { UserAccountButton } from "features/User";
import { SideMenuList } from "./SideMenuList";
import { SystemAlert } from "views/molecules/SystemAlert";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),

      marginLeft: `-${drawerWidth}px`,
    }),
  })
);
/**
 * 認証後のページテンプレートを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export function Presententer(props) {
  const {
    classes,
    open,
    children,
    // notifications,
    user,
    loading,
    onToggleMenu,
  } = props;

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        color="inherit"
        sx={{
          boxShadow: 0,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar className={classes.toolbar_mobile}>
          {props.isDemo && (
            <div
              style={{
                position: "absolute",
                top: 10,
                right: "20vw",
              }}
            >
              <span
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  color: "grey",
                }}
              >
                {"DEMO"}
              </span>
            </div>
          )}
          <Box
            style={{
              marginLeft: 5,
              marginRight: 50,
            }}
            open={open}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onToggleMenu}
              edge="start"
            >
              <Menu />
            </IconButton>
          </Box>
          <Box flexGrow={1}>
            <Box className={classes.toolbar_logo} />
          </Box>
          <Box>
            {/* 12月リリースでは隠す */}
            {/* {isMobileSize === false && (
              <>
                <BeginnerMark />
                <Notifications
                  className={classes.notifications}
                  value={notifications}
                />
              </>
            )} */}
            <UserAccountButton />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        PaperProps={{
          sx: {
            width: open === true ? 260 : 76,
            height: "100vh",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <SideMenuList open={open} user={user} />
        </Box>
      </Drawer>
      <Main
        style={{
          height: `auto`,
        }}
      >
        <div className={classes.toolbar} />
        <SystemAlert />
        <If condition={loading}>
          <LinearProgress
            sx={{ position: "absolute", width: "calc(100% - 260px)" }}
          />
        </If>
        <Box p={3} className={"main"}>
          <Box>
            <RouterBreadcrumbs />
          </Box>
          {children}
        </Box>
      </Main>
      {/* {isMobileSize && (
        <footer className={classes.footerMobileMenu}>
          <MobileMainMenu routes={toSideMenu(routesFooterMobile)} />
        </footer>
      )} */}
      <Alert />

      {/*
        // UNDONE: 12月リリースでは隠す 
        <FeedbackModal /> 
      */}
    </div>
  );
}
