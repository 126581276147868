import React from "react";
import { TabsDetails } from "views/molecules";
import {
  // TabDetailedInformation,
  TabFlowMethod,
  TabSimpleInformation,
  // TabDocuments,
} from "./ChildComponents";

const FlowMethod = React.forwardRef((props, ref) => {
  return <TabFlowMethod _ref={ref} {...props} />;
});

/**
 * 取引先事業場廃棄物情報を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <TabsDetails
    value={props.tab}
    onChangeTab={props.onChangeTab}
    tabs={[
      {
        name: "処理フロー",
        tabContent: (
          <FlowMethod
            value={props.value}
            managedCompanyId={props.companyId}
            managedWasteId={props.wasteId}
            onSelected={props.onSelectedFlow}
            ref={props.flowRef}
            initialState={props.initialState}
          />
        ),
      },
      {
        name: "基本情報",
        tabContent: <TabSimpleInformation value={props.value} />,
      },
      // {
      //   name: "詳細情報",
      //   tabContent: <TabDetailedInformation value={props.value} />,
      // },
      // {
      //   name: "報告方法",
      //   tabContent: <TabDetailedInformation value={props.value} />,
      // },
      // UNDONE: 12月リリースでは隠す
      // {
      //   name: "資料",
      //   tabContent: <TabDetailedInformation value={props.value} />,
      // },
    ]}
  />
);
