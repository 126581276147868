import { requiredField, style_inputField } from "utils/styles";

export const styles = (theme) => ({
  ...style_inputField(theme),
  style_inputField02: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: theme.palette.text.primary,
    },
  },
  style_inputField_error: {
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.main,
    },
  },
  label: {
    color: theme.palette.text.secondary,
  },
  required: {
    ...requiredField(theme),
  },
  icon_corporation: {
    backgroundColor: theme.palette.icon.company,
  },
  iconSize: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
});
