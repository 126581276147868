import { createSlice } from "@reduxjs/toolkit";
import { fetchContractTypes } from "./api";
import { createSelector } from "reselect";

const contractTypesSlice = createSlice({
  name: "contractTypes",
  initialState: {
    data: null,
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContractTypes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchContractTypes.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchContractTypes.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

/**
 * ストアから契約形態を取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.ContractTypes;

/**
 * ストアから契約形態を取得します。
 */
export const contractTypesSelector = createSelector(
  selector,
  (contractTypes) => contractTypes.data
);

/**
 * ストアから読み込みを取得します。
 */
export const isLoadingSelector = createSelector(
  selector,
  (contractTypes) => contractTypes.isLoading
);

/**
 * ストアからエラーを取得します。
 */
export const errorSelector = createSelector(
  selector,
  (contractTypes) => contractTypes.error
);

export default contractTypesSlice.reducer;
