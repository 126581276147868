import { LicenseInfo } from "@mui/x-license-pro";
import "animate.css";
import { Amplify } from "aws-amplify";
import { useSignOut } from "utils/useSignOut";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import routes, { routesFooterMobile as mobileRoutes } from "routes";
import useSize from "utils/useSize";
import {
  useMediaQuery,
  Backdrop,
  CircularProgress,
  Snackbar,
  IconButton,
  Button,
} from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import CloseIcon from "@mui/icons-material/Close";
import { useWindowWidth } from "@react-hook/window-size";
import { CacheBuster } from "views/atoms/CacheBuster";
import { MaintenanceMode } from "views/atoms/MaintenanceMode";
import { Error } from "views/molecules";
import {
  AuthenticatedFoundation,
  AuthenticatedTemplate,
} from "views/organisms";
import { AppBar as MobileAppBar } from "views/organisms/Mobile/Common/AppBar";
import { NotFound } from "views/pages";
import {
  ConfirmSignIn,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  VerifyContact,
} from "views/pages/Auth";
import { awsAmplifyConfig } from "./api/aws-config";
import { restrictRoutes } from "utils/Restricts";
import { usePlanMonitoring } from "utils/usePlanMonitoring";
import { createContext, useEffect, useState } from "react";
import { If } from "views/atoms";
import { InviteSubApplication } from "features/Invite";
import { useMaintenanceListner } from "utils/useMaintenanceListner";

const maintenanceUrl = `${process.env.PUBLIC_URL}/maintenance-schedule.json`;

export const Monitoring = createContext();

LicenseInfo.setLicenseKey(process.env.REACT_APP_XGRID_LICENSE_KEY);

Amplify.configure(awsAmplifyConfig);

const MILLISECONDINAMIN = 60 * 1000;

const MobileRoutes = () => {
  const monitorning = usePlanMonitoring();
  const { signOut } = useSignOut();

  useEffect(() => {
    if (monitorning.isClosedSystem) {
      signOut();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitorning.isClosedSystem]);

  return (
    <Routes>
      {mobileRoutes.map((route, index) => (
        <Route key={index} {...route} />
      ))}
      <Route
        path="*"
        element={
          <>
            <MobileAppBar />
            <NotFound />
          </>
        }
      />
    </Routes>
  );
};

const PcRoutes = () => {
  const monitorning = usePlanMonitoring();
  const { signOut } = useSignOut();

  useEffect(() => {
    if (monitorning.isClosedSystem) {
      signOut();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitorning.isClosedSystem]);

  return (
    <Monitoring.Provider value={monitorning}>
      <AuthenticatedTemplate>
        <If
          condition={monitorning.loading === false}
          elseComponent={
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          }
        >
          <Routes>
            {restrictRoutes({
              routes: routes,
              isGeneralWastesPlan: monitorning.isGeneralWastesPlan,
            }).map((route, index) => (
              <Route key={index} {...route} />
            ))}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </If>
      </AuthenticatedTemplate>
    </Monitoring.Provider>
  );
};

const AuthenticatedRoutes = () => {
  const { isMobileSize } = useSize();
  const isPrint = useMediaQuery("print");
  const windowWidth = useWindowWidth();

  if (isPrint) {
    return windowWidth < 900 ? <MobileRoutes /> : <PcRoutes />;
  }

  return isMobileSize ? <MobileRoutes /> : <PcRoutes />;
};

const AuthenticatedComponent = () => {
  const [lastCallAt, setLastCallAt] = useState(new Date());
  const [isOffline, setIsOffline] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.addEventListener("offline", () => {
      setIsOffline(true);
    });

    if ("serviceWorker" in navigator) {
      const currentTime = new Date();
      const diffInMillis = currentTime - lastCallAt;
      if (diffInMillis > MILLISECONDINAMIN) {
        setLastCallAt(currentTime);
        navigator.serviceWorker.controller?.postMessage({
          type: "ROUTE_CHANGE",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <Snackbar
        open={isOffline}
        autoHideDuration={6000}
        onClose={(_, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setIsOffline(true);
        }}
        message="オフラインです"
        action={
          <>
            <Button
              color="secondary"
              size="small"
              onClick={() => window.location.reload()}
            >
              閉じる
            </Button>
            <IconButton onClick={() => setIsOffline(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      <AuthenticatedFoundation>
        <AuthenticatedRoutes />
      </AuthenticatedFoundation>
    </>
  );
};

const Contents = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  switch (authStatus) {
    case "configuring":
      return;
    case "authenticated":
      return <AuthenticatedComponent />;
    default:
      return (
        <>
          <SignIn />
          <ConfirmSignIn />
          <VerifyContact />
          <ForgotPassword />
          <RequireNewPassword />
        </>
      );
  }
};

const App = () => {
  const { maintenanceMode } = useMaintenanceListner(maintenanceUrl);

  if (maintenanceMode) {
    return <MaintenanceMode url={maintenanceUrl} />;
  } else if (window.location.href.includes("invite_confirming")) {
    return <InviteSubApplication />;
  } else {
    return (
      <ErrorBoundary FallbackComponent={Error}>
        <CacheBuster url={`${process.env.PUBLIC_URL}/cache-busting.txt`} />
        <BrowserRouter>
          <Contents />
        </BrowserRouter>
      </ErrorBoundary>
    );
  }
};

export default App;
