import { Box, IconButton, TextField } from "@mui/material";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import clsx from "clsx";

/**
 * テキストフィルドの表示を行うプレゼンテーションコンポーネントです。
 * @param {string} label テキストのレベル
 * @param {object} classes クラス名
 * @param {funct} onChange データ更新を処理する
 * @param {boolean} required 必須かどうか設定
 * @param {string} value テキストフィールドのバリュー
 * @param {string} error エラー
 * @param {string} isMatches スマホの画面をチェックする
 * @param {string} classOverride 編集したい時クラス名
 * @param {string} heplIcon スマホのアイコン
 * @param {string} onClickHeplIcon スマホのアイコンをクリックするときイベント
 */
export const Presententer = ({
  label,
  onChange,
  value,
  classes,
  required = false,
  error,
  helperText,
  isMatches,
  heplIcon,
  classOverride,
  onClickHeplIcon,
  otherActions,
  ...props
}) => {
  return (
    <Box display="flex" flexDirection="column" fontSize="14px">
      <div className={classes.label}>
        {label} {required ? <span className={classes.required}>必須</span> : ""}
        <div
          style={{
            display: "inline",
            float: "right",
          }}
        >
          {otherActions}
          {heplIcon && (
            <IconButton
              onClick={onClickHeplIcon}
              className={classes.icon_hepl}
              color="primary"
            >
              <ErrorOutlineOutlined />
            </IconButton>
          )}
        </div>
      </div>
      <TextField
        size="medium"
        variant={isMatches ? "standard" : "outlined"}
        value={value ?? ""}
        onChange={onChange}
        className={clsx(classes.style_inputField, classOverride)}
        required={required}
        error={Boolean(props.isError) || Boolean(error?.message)}
        helperText={Boolean(error?.message) ? error?.message : helperText}
        {...props}
      />
    </Box>
  );
};
