import { useNavigate, useLocation } from "react-router";
import { toQueryString, fromQueryString } from "utils/useXGridComponents";

/**
 * 処理フロー情報を表示するコンテナコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleTransite = (e) => {
    navigate(
      {
        pathname: location.pathname,
        search: toQueryString(e.info.search),
      },
      {
        replace: true,
      }
    );
    navigate(`/master/flow/${e?.value?.id}`, { replace: false });
  };

  return render({
    onSelected: handleTransite,
    initialState: { 
      sort: {
        direction: "asc",
        field: "targetWasteTypeOrder"
      },
      ...fromQueryString(location.search) },
    ...props,
  });
};
