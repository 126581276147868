import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  DialogActions,
} from "@mui/material";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "views/atoms";
import { basicSchema } from "./Validation";

const defaultValues = {
  certificateExpiredOn: DateTime.now(),
};

/**
 * 車検満了日を更新するフォームコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {boolean} props.open 開閉状態
 * @fires VehicleInspectionDateForm#onClose 閉じるとき
 * @param {boolean} disabled 無効設定
 * @fires VehicleInspectionDateForm#onSubmit 送信時
 * @fires VehicleInspectionDateForm#onError エラー時
 * @returns {JSX.Element}
 */
export const VehicleInspectionDateForm = ({
  value,
  open = false,
  onClose = () => {},
  disabled = false,
  onSubmit = () => {},
  onError = () => {},
}) => {
  const { control, getValues, trigger, formState, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    reset({
      ...defaultValues,
      ...value,
    });
  }, [reset, value]);

  const handleClick = () => {
    trigger().then((res) => {
      if (res) {
        onSubmit && onSubmit(getValues());
      } else {
        onError(formState.errors);
      }
    });
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{"次回車検日を更新する"}</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Controller
            name={`certificateExpiredOn`}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <DatePicker
                required={true}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
                disabled={disabled}
              />
            )}
          />
          <DialogActions>
            <Button
              color="secondary"
              onClick={onClose}
              disabled={disabled}
            >
              {"キャンセル"}
            </Button>
            <Button onClick={handleClick} disabled={disabled}>
              {"更新"}
            </Button>
          </DialogActions>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
