import React from "react";
import { Panel } from "../Panel";
import { TextField } from "../TextField";

/**
 * セキュリティの表示を行うプレゼンテーションコンポーネントです
 * @param {string} value ユーザー情報
 */
export const Presententer = (props) => {
  return (
    <>
      <Panel title="ログイン">
        <TextField label="パスワード" value="前回の更新：2021/09/12" />
        <TextField label="２段階認証プロセス" value="オフ" />
      </Panel>
      <Panel
        title="本人確認の方法"
        subTitle={
          "ログインしているのが本人であることを確認する場合や、アカウントで不審なアクティビティがあった場合に使用します。"
        }
      >
        <TextField
          label="再設定用のメールアドレス"
          value="test@greener.co.jp"
        />
        <TextField
          label="再設定用の電話番号"
          value="携帯電話番号を追加してください。"
        />
      </Panel>
    </>
  );
};
