export const styles = (theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 12,
  },
  caption_area: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  action_area: {
    display: "flex",
    alignItems: "center",
    flexShrink: 0,
  },
});
