import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { styles } from "./styles";

/**
 * 事業場の選択を表示するコンテナコンポーネントです。
 * @param {object} value 現在の事業場のデータです。
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  companyId,
  value,
  open,
  onClose,
  onChange,
  ...props
}) => {
  const classes = useClasses(styles);

  const handleClose = () => {
    debugLog("Masters.Corporation.WorkplaceTable.onClose");
    onClose && onClose();
  };

  const handleChange = (e) => {
    debugLog("Masters.Corporation.WorkplaceTable.onChange", e);
    onChange && onChange(e);
    handleClose();
  };

  return render({
    companyId: companyId,
    classes: classes,
    open: open,
    onClose: handleClose,
    onChange: handleChange,
    value: value,
    ...props,
  });
};
