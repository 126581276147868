import { searchWasteTypesByOffset } from "api/graphql/queries";
import { debugLog } from "utils/log";
import { useXGridComponents } from "utils/useXGridComponents";

/**
 * カラム
 */
const columns = [
  {
    field: "groupName",
    headerName: "グループ名",
    width: 350,
    renderCell: (params) => {
      return `${params.row.group.name}`;
    },
  },
  {
    field: "largeClassName",
    headerName: "大分類",
    flex: 1,
    renderCell: (params) => {
      return `${params.row.largeClass.name}`;
    },
  },
  {
    field: "middleClassName",
    headerName: "中分類",
    width: 170,
    renderCell: (params) => {
      return `${params.row.middleClass.name}`;
    },
  },
  {
    field: "smallClassName",
    headerName: "小分類",
    width: 170,
    renderCell: (params) => {
      return `${params.row.smallClass.name}`;
    },
  },
];

/**
 * 廃棄物種類を選択するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {boolean} multiple 複数選択設定
 * @param {object} value 値
 * @fires Container#onSelected 選択時
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  multiple = false,
  checkbox = false,
  value,
  onSelected = (data) => debugLog(data),
  onClose,
  title = "廃棄物の種類を選択",
  fixedOptions,
  ...props
}) => {
  const xGridObject = useXGridComponents(
    columns,
    searchWasteTypesByOffset,
    fixedOptions,
    { pageSize: 100 }
  );

  const handleSelected = (params) => {
    onSelected(
      xGridObject.params.rows.filter((row) => params.includes(row.id))
    );
  };

  return render({
    value: value,
    multiple: multiple,
    checkbox: checkbox,
    onSelected: handleSelected,
    onClose: onClose,
    xGridParams: xGridObject.params,
    title: title,
    ...props,
  });
};
