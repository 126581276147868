import React from "react";
import { Controller } from "react-hook-form";
import { Button, CircularProgress, Link } from "@mui/material";
import { AuthenticationTemplate } from "views/organisms";
import { InputField } from "views/molecules";

/**
 * 連絡先確認画面を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  classes,
  onTransiteLogin,
  onVerify,
  submit,
  delivery,
  loading,
  control,
}) => (
  <AuthenticationTemplate>
    <form className={classes.form} noValidate onSubmit={submit}>
      {delivery ? (
        <>
          <Controller
            name="code"
            rules={{ required: "確認コードを入力してください" }}
            control={control}
            render={({
              field: { value, onChange, ref },
              fieldState: { error },
            }) => (
              <InputField
                value={value}
                onChange={onChange}
                label="確認コード"
                error={Boolean(error?.message)}
                helperText={error?.message}
                inputRef={ref}
              />
            )}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            className={classes.button}
            disabled={loading}
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : "確認"}
          </Button>
        </>
      ) : (
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          onClick={onVerify}
          fullWidth
        >
          メールアドレスの確認メールを送る
        </Button>
      )}
      <Link variant="body2" component="button" onClick={onTransiteLogin}>
        ログインに戻る
      </Link>
    </form>
  </AuthenticationTemplate>
);
