import { Autocomplete, Chip } from "@mui/material";
import { useState } from "react";
import { TextField } from "../TextField";

/**
 * メールアドレスを複数入力するフォームコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} label ラベル
 * @param {boolean} required 必須設定
 * @param {string} placeholder プレースホルダ
 * @param {boolean} error エラーであるか
 * @param {string} helperText 補助的テキスト
 * @param {array} value メールアドレス
 * @param {boolean} disabled 無効設定
 * @fires EmailChipsField#onChange 変更時
 * @fires EmailChipsField#onError エラー時
 * @returns {JSX.Element}
 */
export const EmailChipsField = ({
  label,
  required,
  placeholder = "メールアドレスを入力します。(複数設定可能)",
  error,
  helperText,
  value = [],
  onChange = () => {},
  onError = () => {},
  disabled = false,
}) => {
  const [innerError, setInnerError] = useState(null);
  const [innerValue, setInnerValue] = useState(null);

  const handleKeyDown = (event) => {
    if (["Enter", "Tab", ","].includes(event.key)) {
      event.preventDefault();

      if (innerValue && innerValue !== "" && validation(innerValue)) {
        let result = [...value, innerValue];
        setInnerValue("");
        onChange(result);
      }
    }
  };

  const handleDelete = (index) => () => {
    const result = [...value];
    result.splice(index, 1);
    onChange(result);
  };

  const handlePaste = (event) => {
    event.preventDefault();

    let paste = event.clipboardData.getData("text")?.trim?.();
    let emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    if (emails) {
      let toBeAdded = emails.filter((email) => !exists(email));
      onChange([...value, ...toBeAdded]);
    }
  };

  const validation = (newEmail) => {
    let result = null;

    if (exists(newEmail)) {
      result = "入力したメールアドレスは既にあります。";
    }

    if (!isEmail(newEmail)) {
      result = "メールアドレスを入力してください。";
    }

    setInnerError(result);
    onError(result);
    return !result;
  };

  const exists = (newEmail) => {
    return value.includes(newEmail);
  };

  const isEmail = (newEmail) => {
    return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(newEmail);
  };

  return (
    <Autocomplete
      value={value}
      multiple
      id="tags-filled"
      options={[]}
      freeSolo
      renderTags={(value, getTagProps) => {
        return value?.map((item, index) => (
          <Chip
            key={index}
            label={item}
            disabled={disabled}
            sx={{
              margin: "4px",
            }}
            {...getTagProps({ index })}
            onDelete={handleDelete(index)}
          />
        ));
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            value={innerValue}
            onChange={(event) => setInnerValue(event.target.value?.trim?.())}
            label={label}
            fullWidth
            required={required}
            placeholder={placeholder}
            multiline
            error={error || !!innerError}
            helperText={helperText || innerError || (!error && "※入力後Enterキーを押してください。")}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            disabled={disabled}
          />
        );
      }}
    />
  );
};
