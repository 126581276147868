import { PhoneNumberFormatUtil } from "utils/format";

const columns = [
  {
    field: "name",
    headerName: "名称",
    width: 350,
  },
  {
    field: "address",
    headerName: "所在地",
    flex: 1,
    valueGetter: (params) =>
      `〒${params?.row?.postalCode} ${params.row?.prefectures?.name}${params.row?.streetAddress}${params.row?.otherAddress}`,
  },
  {
    field: "phone",
    headerName: "電話番号",
    width: 170,
    valueFormatter: (params) =>
      PhoneNumberFormatUtil.formatNational(params.value),
  },
];

/**
 * フロー詳細を表示するコンテナコンポーネントです。
 * @param {object} value 事業場情報
 * @param {func} onChangeTab タブ変更時
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  return render({
    ...props,
    columns: columns,
    value: value,
  });
};
