export const styles = (theme) => ({
  dialog_title: {
    fontSize: "18px",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  dialog_context: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    minHeight: "350px",
    maxHeight: "350px",
    overflowY: "auto",
  },
  dialog_actions: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    "& button": {
      marginRight: theme.spacing(2),
    },
  },
});
