import reducer from "./slice";
import {
  selector,
  companySelector,
  isLoadingSelector,
  errorSelector,
} from "./slice";
import { fetchCompany, updateCompany } from "./api";

export default reducer;
export {
  companySelector,
  selector,
  fetchCompany,
  isLoadingSelector,
  errorSelector,
  updateCompany,
};
