import { Stack, Typography, Grow, Paper } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { blue } from "@mui/material/colors";

/**
 * 申し込み完了画面を表示するコンポーネント。
 * @returns {JSX.Element}
 */
export const Completed = () => {
  return (
    <Grow in={true}>
      <Paper
        sx={{
          height: "70vh",
        }}
      >
        <Stack
          spacing={2}
          alignItems={"center"}
          justifyContent="center"
          height={"100%"}
        >
          <Stack alignItems={"center"}>
            <CheckCircleOutlineOutlinedIcon
              sx={{
                fontSize: "7rem",
                color: blue[700],
              }}
            />
          </Stack>
          <Stack alignItems={"center"}>
            <Typography
              variant="h5"
              sx={{
                color: blue[700],
              }}
            >
              {"お申込が完了しました。"}
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </Grow>
  );
};
