import { useState } from "react";

/**
 * 時間の選択の表示を行うコンテナコンポーネントです。
 * @param {string} value 値
 * @fires Container#onChange 変更時
 * @param {string} helperText 補助的説明文
 * @param {boolean} error エラーであるか
 * @param {string} label ラベル
 * @param {number | null} minutesStep 分刻み
 * @param {string} variant デザイン("filled" | "standard" | "outlined")
 * @param {boolean} disabled 無効設定
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value = null,
  onChange = () => {},
  disabled = false,
  minutesStep = null,
  error = false,
  helperText = "",
  variant = "standard",
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [innerValue, setInnerValue] = useState(null);

  const handleOpen = () => {
    if (disabled === true) {
      return;
    }

    if (!!value) {
      const words = value.split(":");
      setInnerValue({
        hour: words[0],
        minute: words[1],
      });
    } else {
      setInnerValue({
        hour: "00",
        minute: "00",
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    if (`${innerValue?.hour}:${innerValue?.minute}` === value) {
      setOpen(false);
      return;
    }

    if (
      !!innerValue?.hour &&
      !!innerValue?.minute &&
      `${innerValue?.hour}:${innerValue?.minute}` !== value
    ) {
      onChange && onChange(`${innerValue?.hour}:${innerValue?.minute}`);
    } else {
      onChange && onChange(null);
    }

    setOpen(false);
  };

  const handleChangeHour = (event) => {
    setInnerValue((prevState) => ({
      ...prevState,
      hour: event.target.value,
      minute: prevState?.minute ? prevState?.minute : "00",
    }));
  };

  const handleChangeMinute = (event) => {
    setInnerValue((prevState) => ({
      ...prevState,
      minute: event.target.value,
    }));
  };

  return render({
    ...props,
    value: value,
    open: open,
    onClose: handleClose,
    onOpen: handleOpen,
    onChangeHour: handleChangeHour,
    onChangeMinute: handleChangeMinute,
    disabled: disabled,
    minutesStep: minutesStep,
    error: error,
    helperText: helperText,
    variant: variant,
    innerValue: innerValue,
  });
};
