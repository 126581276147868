import { useStyles } from "./styles";
/**
 * 引数を受けて、JSX.Elementを返します。
 * @returns　JSX Element
 */
export const Container = ({ render, ...props }) => {
  const classes = useStyles();

  return render({
    classes: classes,
    ...props,
  });
};
