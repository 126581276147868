import { Box, Button, Typography } from "@mui/material";
import { CloudUploadOutlined } from "@mui/icons-material";
import React from "react";

/**
 * ファイルアップローダーを表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <>
      {props.label && (
        <Box fontSize="14px" color=" #616161" mb={1}>
          {props.label}
        </Box>
      )}
      <Box className={props.classes.box_image_upload}>
        <div {...props.getRootProps({ className: "dropzone" })}>
          <input {...props.getInputProps()} />
          <label htmlFor="contained-button-file">
            <CloudUploadOutlined
              className={props.classes.iconUpload}
              size="large"
            />
            <Typography variant="subtitle2">{props.getSubtitle()}</Typography>
          </label>
          <Button
            variant="contained"
            color="primary"
            className={props.classes.button_upload}
          >
            ファイルを選択
          </Button>
          {props.error && (
            <Box color="error.main" mt={2}>
              {props.error}
            </Box>
          )}
        </div>
      </Box>
    </>
  );
};
