import { Close, EditOutlined } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import useClasses from "utils/useClasses";
import { v4 } from "uuid";
import { styles } from "./styles";

/**
 * 回収サイクル情報を入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const classes = useClasses(styles);
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const { control, clearErrors, setValue, watch } = useFormContext();
  const formRef = useRef(null);

  const handleClickDelete = (index) => {
    const result = [...watch("cycles")];
    result.splice(index, 1);
    setValue("cycles", result);
  };

  const handleClickEdit = (data) => {
    setSelectedValue(data);
    setOpen(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "",
      width: 120,
      renderCell: (params) => {
        const apiRef = params.api;
        const index = apiRef.getRowIndex(params.row.id);
        const data = params.row;
        return (
          <Box>
            <IconButton
              onClick={() =>
                handleClickEdit({
                  index: index,
                  data: data,
                })
              }
            >
              <EditOutlined />
            </IconButton>
            <IconButton onClick={() => handleClickDelete(index)}>
              <Close />
            </IconButton>
          </Box>
        );
      },
      renderHeader: () => " ",
    },
    {
      field: "frequency",
      headerName: "回収周期",
      width: 200,
      renderCell: (params) => {
        let result = "";

        result = params.row?.frequency?.category?.name ?? "";

        if (params.row?.frequency?.weeks) {
          result += ` ${params.row.frequency?.weeks.map((week) => week.name)}`;
        }

        if (params.row?.frequency?.weekDays) {
          result += ` ${params.row?.frequency?.weekDays.map(
            (weekDay) => weekDay.name
          )}`;
        }

        if (params.row?.frequency?.numberOfDays) {
          result = ` ${params.row?.frequency?.numberOfDays} 日${params.row.frequency?.category?.name}`;
        }

        return result;
      },
    },
    {
      field: "times",
      headerName: "回収時間",
      width: 200,
      renderCell: (params) => {
        return `${params.row?.times?.start} ～ ${params.row?.times?.end}`;
      },
    },
    {
      field: "cycleStartDate",
      headerName: "回収開始日",
      width: 120,
      renderCell: (params) => {
        return params?.value;
      },
    },
    {
      field: "remarks",
      headerName: "備考",
      width: 300,
      valueFormatter: (params) => {
        return params.value ? params.value?.split("\n").join(" ") : "";
      },
    },
  ];

  const handleSubmit = (data) => {
    const result = watch("cycles") ? [...watch("cycles")] : [];
    if (selectedValue) {
      result.splice(selectedValue.index, 1, {
        id: v4(),
        ...data,
      });
    } else {
      result.push({
        id: v4(),
        ...data,
      });
    }
    setValue("cycles", result);
    clearErrors("cycles");
    setOpen(false);
    setSelectedValue(null);
  };

  const handleDecided = () => {
    formRef.current.submit();
  };

  return render({
    classes: classes,
    control: control,
    columns: columns,
    selectedValue: selectedValue,
    open: open,
    onOpenModal: () => setOpen(true),
    onCloseModal: () => setOpen(false),
    onSubmit: handleSubmit,
    onDecided: handleDecided,
    formRef: formRef,
    ...props,
  });
};
