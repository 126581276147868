import React, { useRef, useMemo, useCallback } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { styles } from "./styles";
import { useWindowHeight } from "@react-hook/window-size";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

/**
 * ReactBottomSheet Component
 *
 * A React component that implements bottom sheet behavior for mobile devices.
 *
 * @component
 * @param {Object} props - The properties of the ReactBottomSheet component.
 * @param {JSX.Element} props.children - The content to be rendered within the bottom sheet.
 * @param {boolean} props.isOpen - A boolean indicating whether the bottom sheet is open or closed.
 * @param {function} props.onClose - A callback function to be called when the bottom sheet is closed.
 * @param {number} [props.defaultsHeight] - The default height of the bottom sheet.
 * @param {number} [props.defaultsMaxHeight] - The default maximum height of the bottom sheet.
 * @param {number} [props.subtractedHeight=54] - The height to be subtracted from the maximum height of the bottom sheet.
 * @param {string} [props.className] - The class name to be applied to the bottom sheet.
 *
 * @example
 * // Example usage of ReactBottomSheet component
 * <ReactBottomSheet isOpen={true} onClose={() => console.log('Closed')}>
 *  Content goes here
 * </ReactBottomSheet>
 */
export const ReactBottomSheet = React.memo(
  ({
    children,
    isOpen,
    defaultsHeight,
    defaultsMaxHeight,
    subtractedHeight = 54,
    onClose,
    className,
    blocking = false,
    expandOnContentDrag = false,
  }) => {
    const classes = styles();
    const maxHeight = useWindowHeight();
    const sheetRef = useRef();

    const maxHeightDefault = useMemo(
      () =>
        defaultsMaxHeight
          ? defaultsMaxHeight > maxHeight
            ? maxHeight - subtractedHeight
            : defaultsMaxHeight - subtractedHeight
          : maxHeight - subtractedHeight,
      [subtractedHeight, maxHeight, defaultsMaxHeight]
    );

    const defaultSnap = useMemo(
      () => [defaultsHeight ? defaultsHeight : maxHeight * 0.5],
      [defaultsHeight, maxHeight]
    );

    const snapPoints = useMemo(
      () => [
        maxHeightDefault,
        maxHeight * 0.7,
        maxHeight * 0.5,
        maxHeight * 0.3,
        maxHeight * 0.2,
        defaultsHeight ? defaultsHeight : "",
      ],
      [maxHeightDefault, maxHeight, defaultsHeight]
    );

    const handleSpringStart = useCallback((event) => event.type === "SNAP", []);

    return (
      <BottomSheet
        open={isOpen}
        blocking={blocking}
        expandOnContentDrag={expandOnContentDrag}
        scrollLocking={false}
        skipInitialTransition
        ref={sheetRef}
        onDismiss={onClose}
        defaultSnap={() => defaultSnap}
        snapPoints={() => snapPoints}
        className={clsx(classes.override, className)}
        onSpringStart={handleSpringStart}
      >
        {children}
      </BottomSheet>
    );
  }
);

ReactBottomSheet.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultsHeight: PropTypes.number,
  maxHeight: PropTypes.number,
  defaultsMaxHeight: PropTypes.number,
  subtractedHeight: PropTypes.number,
  className: PropTypes.string,
  blocking: PropTypes.bool,
  expandOnContentDrag: PropTypes.bool,
};
