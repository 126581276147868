import { Paper, Stack } from "@mui/material";
import { formatDisplayAddress, formatDisplayDate } from "utils/format";
import { InformationItem } from "views/atoms";
import { FileGallery, XGridComponent } from "views/molecules";

const columns = [
  {
    field: "method",
    headerName: "整備方法",
    flex: 1,
  },
  {
    field: "part",
    headerName: "整備箇所",
    flex: 1,
  },
  {
    field: "content",
    headerName: "作業内容",
    flex: 1,
  },
  {
    field: "quantityValue",
    headerName: "個数",
    flex: 1,
  },
];

/**
 * 整備詳細を表示するコンポーネントです。
 * @param {object} value 値
 * @fires MaintenancesDetails#onSelected 選択時
 * @returns {JSX.Element}
 */
export const MaintenancesDetails = ({ value, onSelected }) => {
  return (
    <Stack spacing={2}>
      <Paper
        sx={{
          p: 2,
        }}
      >
        <InformationItem label="管理番号">
          {value?.number ?? ""}
        </InformationItem>
        <InformationItem label="種別">{value?.category ?? ""}</InformationItem>
        <InformationItem label="整備会社">
          {value?.maintenanceWorkplace?.belongInCompanyName ?? ""}
        </InformationItem>
        <InformationItem label="整備場所">
          {formatDisplayAddress(value?.maintenanceWorkplace)}
        </InformationItem>
        <InformationItem label="整備年月日">
          {formatDisplayDate({ source: value?.maintenancedOn })}
        </InformationItem>
        <InformationItem label="整備代金">
          {value?.price ? `${value?.price?.toLocaleString()}円` : ""}
        </InformationItem>
        <InformationItem label="車種">
          {value?.vehicleModelName ?? ""}
        </InformationItem>
        <InformationItem label="車輛ナンバー">
          {value?.vehicleNumber ?? ""}
        </InformationItem>
        <InformationItem label="備考">{value?.remarks ?? ""}</InformationItem>
        <InformationItem label="添付ファイル">
          <FileGallery value={value?.files} disabled={true} />
        </InformationItem>
      </Paper>
      <Paper
        sx={{
          p: 2,
        }}
      >
        <XGridComponent
          onChangeSelection={onSelected}
          checkboxSelection={false}
          multipleSelection={false}
          onDownload={false}
          onFilter={false}
          columns={columns}
          onColumns={false}
          initialState={{
            sorting: {
              sortModel: [{ field: "part", sort: "asc" }],
            },
          }}
          rows={value?.details?.map((detail) => ({
            ...detail,
            id: detail.order,
          })) ?? []}
        />
      </Paper>
    </Stack>
  );
};
