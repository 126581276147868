import { setVisilityBeginnersMark } from "ducks/GuiderSteps/slice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

/**
 * マスタ管理の取引先を表示するコンテナコンポーネントです。
 * @param {props} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onTransiteDetails,
  submit,
  xGridObject,
  isSubmit,
  open,
  onOpenDialog,
  onCloseDialog,
  ...props
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setVisilityBeginnersMark());
  }, [dispatch]);

  return render({
    ...props,
    isSubmit: isSubmit,
    onChangeSelection: onTransiteDetails,
    open: open,
    onOpenDialog: onOpenDialog,
    onCloseDialog: onCloseDialog,
    submit: submit,
    xGridParams: xGridObject.params,
  });
};
