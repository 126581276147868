import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";
/**
 * レンダーるのテキストの表示を行うコンテナコンポーネントです
 * @param {string} mainTitle 主なタイトル
 * @param {array} subTitle 字幕 例）["subtitle 01", "subtitle 02"]
 * @returns　JSX Element
 */
export const RenderTitleInPopupOrModal = containerPresententer(
  Container,
  Presententer
);
