import PropTypes from "prop-types";

/**
 * 引数を受けてJSX Elemntを返します。
 * @callback render
 * @param {arrayOf} points ルート
 * @param {func} setDistance
 * @returns
 */
export const Container = ({ render, points, setDistance }) => {
  const origins = [{ lat: points[0].lat, lng: points[0].lng }];
  const destinations = [{ lat: points[points.length - 1].lat, lng: points[0].lng }];
  const directionsServiceOptions = {
    origins,
    destinations,
    travelMode: window.google.maps.TravelMode.DRIVING,
    avoidTolls: true,
    avoidHighways: true,
  };
  const calculateDistanceCallback = (response) => {
    if (response !== null) {
      if (response.status === "OK") {
        console.log({ response });
      } else {
        console.log("response: ", response);
      }
    }
  };

  return render({
    calculateDistanceCallback,
    directionsServiceOptions,
  });
};

Container.propTypes = {
  render: PropTypes.func.isRequired,
  points: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    })
  ).isRequired,
  setDistance: PropTypes.func.isRequired,
};
