import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showMain: true,
};

const MainPage = createSlice({
  name: "MainPage",
  initialState,
  reducers: {
    show: (state, action) => {
      state.showMain = true;
    },
    hidden: (state, action) => {
      state.showMain = false;
    },
  },
});

export const { show, hidden } = MainPage.actions;

export const pageSelector = (store) => store.MainPage;

export default MainPage.reducer;
