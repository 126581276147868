import { placeholderTextSize, requiredField } from "utils/styles";

export const styles = ((theme) => ({
  style_inputField: {
    marginTop: theme.spacing(1),
    "& .MuiOutlinedInput-input": {
      color: theme.palette.grey[700],
    },
    "& .MuiOutlinedInput-adornedEnd": {
      //paddingRight: 0,
    },
    "& textarea": {
      ...placeholderTextSize(),
    },

    "& input": {
      ...placeholderTextSize(),
    },
    "& button ": {
      padding: 0,
    },
  },
  label: {
    color: theme.palette.grey[700],
  },
  required: {
    ...requiredField(theme),
  },
}));
