import { Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import {
  DatePicker,
  FieldDecorateFrame,
  InputField,
  TimeRangePicker,
} from "views/molecules";
import { CollectionFrequencyField } from "../CollectionFrequencyField";

/**
 * 回収サイクルを追加するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          name="frequency"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <FieldDecorateFrame label="回収頻度" required={true}>
                {(params) => (
                  <CollectionFrequencyField
                    value={value}
                    onChange={(e) => {
                      props.clearErrors("frequency");
                      onChange(e);
                    }}
                    error={Boolean(error)}
                    helperText={error?.message}
                  />
                )}
              </FieldDecorateFrame>
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="times"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <FieldDecorateFrame label="回収時間" required={true}>
                {(params) => (
                  <TimeRangePicker
                    value={value}
                    onChange={onChange}
                    error={Boolean(error)}
                    minutesStep={5}
                    minu
                    helperText={
                      error?.message ||
                      error?.start?.message ||
                      error?.end?.message
                        ? "必須入力項目です"
                        : ""
                    }
                  />
                )}
              </FieldDecorateFrame>
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="cycleStartDate"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <DatePicker
              label="回収開始日"
              value={value}
              onChange={onChange}
              required={true}
              error={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="remarks"
          control={props.control}
          render={({ field: { value, onChange } }) => (
            <InputField
              label="備考"
              placeholder="特記事項があれば入力します。"
              multiline
              rows={3}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
