import { TabsDetails } from "views/molecules";
import {
  Basic,
  Transporters,
  Disposals,
  LastDisposals,
} from "./ChildComponents";

/**
 * 処理フローをタブ形式で表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <TabsDetails
      tabs={[
        {
          name: "基本情報",
          tabContent: <Basic value={props.value} />,
        },
        {
          name: "運搬区間情報",
          tabContent: <Transporters value={props.value} />,
        },
        {
          name: "処分情報",
          tabContent: <Disposals value={props.value} />,
        },
        {
          name: "最終処分事業場",
          tabContent: <LastDisposals value={props.value} />,
        },
      ]}
    />
  );
};
