import { FullScreenDialogMobile } from "views/molecules";
import { CollectDetailsForm } from "./CollectDetailsForm";
import { SwiperContainer, SwiperSlide } from "utils/SwiperWrapper";
import React from "react";

const Form = React.memo(
  React.forwardRef((props, ref) => <CollectDetailsForm {...props} _ref={ref} />)
);

/**
 * 回収詳細の入力を行うプレゼンテーションコンポーネントです。
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <FullScreenDialogMobile
      open={props.open}
      onClose={props.onClose}
      showAppbar={false}
    >
      <SwiperContainer
        virtual="true"
        initialSlide={props.index}
        enabled={!props.loading}
        onSlideChange={(swiper) => {
          props.onNotificationChangeIndex(swiper.detail[0].activeIndex);
          props.handleSwiped(swiper.detail[0].previousIndex);
        }}
        onSlideChangeTransitionStart={props.handleSlideChangeTransitionStart}
      >
        {props?.values?.map((value, i) => (
          <SwiperSlide key={i}>
            <Form
              onClose={props.onClose}
              onSubmit={props.onSubmit}
              value={value}
              loading={props.loading}
              number={i}
              ref={(el) => (props.formRefs.current[i] = el)}
            />
          </SwiperSlide>
        ))}
      </SwiperContainer>
    </FullScreenDialogMobile>
  );
};
