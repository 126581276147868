export const styles = (theme) => ({
  container: {
    position: "relative",
    cursor: "pointer",
    margin: "10px",
  },
  title: {
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    lineHeight: "30px",
    padding: "0 5px",
    textAlign: "center",
    backgroundColor: " rgba(0,0,0,.4)",
    color: theme.palette.background.paper,
    fontSize: "12px",
  },
});
