import { Alert, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { SelectPopup } from "views/molecules";
import { selector } from "../index";

/**
 * コンテナ停止種別を選択するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} label ラベル
 * @param {boolean} required 必須設定
 * @param {object} value 値
 * @fires Selector#onChange 変更時
 * @param {boolean} error エラーオブジェクト
 * @param {boolean} disabled 無効設定
 * @returns {JSX.Element}
 */
export const Selector = ({
  label = "停止種別",
  required = true,
  value,
  onChange,
  error,
  disabled = false,
}) => {
  const containerAdditionalTypes = useSelector(selector);

  if (containerAdditionalTypes.loading) {
    return <Skeleton />;
  }

  if (containerAdditionalTypes.error) {
    return (
      <Alert severity="error">
        {"エラーが発生したため、表示することができませんでした。"}
      </Alert>
    );
  }

  return (
    <SelectPopup
      label={label}
      required={required}
      value={value}
      onChange={onChange}
      error={error}
      options={containerAdditionalTypes?.data ?? []}
      optionValueField="id"
      optionNameField="name"
      disabled={disabled}
    />
  );
};
