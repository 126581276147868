import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { debugLog } from "utils/log";
import { basicSchema } from "./Validations/basic";

const defaultValues = {
  name: null,
  message: null,
  tags: [],
  files: [],
};

/**
 * 注意点情報を登録するフォームのコンテナコンポーネントです。
 * @callback render
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  id,
  value,
  onSubmit = (data) => debugLog("注意点情報登録送信.成功: ", data),
  onError = (err) => debugLog("注意点情報登録送信.失敗: ", err),
}) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    methods.reset({
      ...defaultValues,
      ...value,
    });
  }, [methods, value]);

  return render({
    id: id,
    methods: methods,
    selectedFileUrl: selectedFileUrl,
    handleSubmit: methods.handleSubmit(onSubmit, onError),
    handleSelectedFileUrlChanged: (url) => setSelectedFileUrl(url),
  });
};
