import { Grid, Paper } from "@mui/material";
import { Controller } from "react-hook-form";
import { DatePicker, InputField, SelectPopup } from "views/molecules";

/**
 * 事業場を入力を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Elemnt}
 */
export const Presententer = (props) => {
  return (
    <Paper
      sx={{
        p: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="categories"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <SelectPopup
                  label="事業者区分"
                  required={true}
                  value={value}
                  onChange={onChange}
                  options={props.ComanyCategories}
                  error={error}
                  multiple={true}
                  optionValueField="id"
                  optionNameField="name"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="name"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputField
                label="名称"
                required={true}
                placeholder="事業者の名称を入力します。"
                value={value}
                onChange={onChange}
                error={error}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="corporateNumber"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputField
                label="法人番号"
                placeholder="法人番号を入力します。"
                value={value}
                onChange={(e) => {
                  const result = e.target.value;
                  const pattern = new RegExp(/^\d*$/);
                  if ((result && pattern.test(result)) || !result) {
                    onChange(e.target.value);
                  }
                }}
                classOverride={props.classes.corporationNumber}
                error={error}
                inputProps={{
                  maxLength: 13,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="representativeName"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputField
                label="代表者"
                required={false}
                value={value}
                onChange={onChange}
                error={error}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="establishmentedOn"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <DatePicker
                  label="設立日"
                  placeholder="例）2021/06/06"
                  value={value}
                  onChange={onChange}
                  error={error}
                  required={false}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="url"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputField
                label="企業ホームページ"
                placeholder="例）https://ecpope-pro.co.jp"
                value={value}
                onChange={onChange}
                error={error}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="remarks"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <InputField
                label="企業概要"
                placeholder="企業概要を入力します。"
                multiline
                rows={4}
                value={value}
                onChange={onChange}
                error={error}
              />
            )}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
