import {
  useCallback, useEffect, useRef, useState
} from "react";
import { debugLog } from "utils/log";
import { convertFromDetails, convertToDetails } from "../Utils";

/**
 * マニフェスト番号の変更
 * 排出場所の変更
 * 中間処理場の入力など
 * 最終処分事業場の変更
 */

/**
 * 紙マニフェストを入力するコンテナコンポーネントです
 * @callback render
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  title = "新しい紙マニフェストを登録",
  open = false,
  onClose = () => debugLog("紙マニフェストフォームが閉じられた"),
  onSubmit = (data) => debugLog("紙マニフェスト.送信成功: ", data),
  onError = (err) => debugLog("紙マニフェスト.送信失敗: ", err),
  ...props
}) => {
  const [state, setState] = useState("simple");
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState(value);
  const simpleFormRef = useRef(null);
  const detailFormRef = useRef(null);

  const load = useCallback(() => {
    setIsLoading(true);
    convertFromDetails(value)
      .then((res) => {
        setFormValue(res);
      })
      .catch((err) => debugLog("値変換エラー: ", err))
      .finally(() => {
        setIsLoading(false);
      });
  }, [value]);

  useEffect(() => {
    setState("simple");
    load();
  }, [load]);

  useEffect(() => {
    if (!open) {
      setFormValue(null);
      setState("simple");
    } else {
      load();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const setDetail = async (data) => {
    try {
      setIsLoading(true);
      setFormValue(await convertToDetails(data));
      setState("detail");
    } catch {
      debugLog("詳細画面遷移失敗");
    } finally {
      setIsLoading(false);
    }
  };

  const handleNext = () => {
    simpleFormRef.current.next();
  };

  const receivedFormSimple = (data) => {
    debugLog("簡易フォームから値が流れてきた: ", data);
    setDetail(data);
  };

  const handleBefore = () => {
    setIsLoading(true);
    convertFromDetails(formValue)
      .then((res) => {
        setFormValue(res);
        setState("simple");
      })
      .catch((err) => debugLog("値変換エラー: ", err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSave = () => {
    detailFormRef.current.submit();
  };

  return render({
    isLoading: isLoading,
    value: formValue,
    simpleFormRef: simpleFormRef,
    detailFormRef: detailFormRef,
    title: title,
    state: state,
    open: open,
    onClose: onClose,
    onNext: handleNext,
    receivedFormSimple: receivedFormSimple,
    onBefore: handleBefore,
    onSave: handleSave,
    onSubmit: onSubmit,
    onError: onError,
    ...props,
  });
};
