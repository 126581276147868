import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { FullScreenDialog, XGridComponent } from "views/molecules";
import { CollectForm } from "../CollectForm";

const Form = React.forwardRef((props, ref) => (
  <CollectForm {...props} _ref={ref} />
));

/**
 * 定期回収一覧の表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <div>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <IconButton color="default" aria-label="左" onClick={props.onClickLeft}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        {props.month}
        <IconButton
          color="default"
          aria-label="右"
          onClick={props.onClickRight}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
        <Button
          color="primary"
          variant="outlined"
          onClick={props.onClickThisMonth}
        >
          {"今月"}
        </Button>
      </div>
      <div>
        {props.selectedRows.length ? (
          <Button
            color="error"
            variant="contained"
            sx={{
              marginRight: 2,
            }}
            onClick={props.handleBulkReduce}
          >
            {props.selectedRows.length}{" "}
            {"行 減便（削除）"}
          </Button>
        ) : null}
        <Button
          color="primary"
          variant="contained"
          onClick={props.onClickIncrease}
          disabled={!props.rows}
        >
          {"定期回収を増便する"}
        </Button>
      </div>
    </div>
    <XGridComponent
      rows={
        props.rows?.schedules?.map((schedule, index) => ({
          id: index,
          ...schedule,
        })) ?? []
      }
      columns={props.columns}
      checkboxSelection={false}
      multipleSelection={false}
      onToolbar={false}
      onDownload={true}
      pageSize={10}
      rowCount={100}
      loading={props.isSubmit}
    />
    <Menu
      id="item-menu"
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(props.anchorEl)}
      onClose={props.onCloseMenu}
    >
      {/* <MenuItem onClick={props.onClickChangeTimes}>時間変更</MenuItem> */}
      <MenuItem
        disabled={props.selectedValue?.scheduleState === "reduce"}
        onClick={props.onClickDelete}
      >
        減便（削除）
      </MenuItem>
    </Menu>
    <FullScreenDialog
      open={props.open}
      onClose={props.onCloseDialog}
      title={props.selectedValue ? "定期回収を編集" : "定期回収を増便"}
      textConfirm={props.selectedValue ? "保存" : "登録"}
      onClickSubmit={props.onConfirm}
      isSubmit={props.isSubmit}
      disabled={props.isSubmit}
    >
      <Form
        onSubmit={props.onSubmit}
        ref={props.formRef}
        value={props.selectedValue}
      />
    </FullScreenDialog>
  </div>
);
