import yup, { remarksSchema, dateSchema, textSchema } from "utils/yup";

export const basicScheme = yup.object().shape({
  frequency: yup
    .object()
    .test("propertyRequired", "必須入力項目です", function (params) {
      if (!params || !params?.category) {
        return false;
      }

      if (params.category.id === "everyMonth") {
        return (
          (params.weeks?.length ?? 0) > 0 && (params.weekDays?.length ?? 0) > 0
        );
      }

      if (
        params.category.id === "everyWeek" ||
        params.category.id === "first" ||
        params.category.id === "second" ||
        params.category.id === "third" ||
        params.category.id === "fourth" ||
        params.category.id === "fifth"
      ) {
        return (params.weekDays?.length ?? 0) > 0;
      }

      if (params.category.id === "interval") {
        return params.numberOfDays > 0;
      }
      
      return params?.category;
    })
    .required(),
  times: yup
    .object()
    .shape({
      start: textSchema.required(),
      end: textSchema.required(),
    })
    .required(),
  cycleStartDate: dateSchema.required(),
  remarks: remarksSchema,
});

/*
object shape
  指定週
    interval: {}
    weeks: []
    dayOfWeeks: []
   
  毎週
    interval: {}
    dayOfWeeks: []

  毎日
    interval: {}
*/
