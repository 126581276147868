import { Box, Button, Slide, Stack } from "@mui/material";
import { FullScreenDialog } from "views/molecules";
import { Step1Form } from "./Step1Form";
import { Step2Form } from "./Step2Form";

/**
 * チェックシートのテンプレートを作成するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 */
export const Presententer = (props) => {
  return (
    <FullScreenDialog
      open={props.open}
      onClose={props.onClose}
      title={props.title}
      action={
        <Stack direction={"row"} spacing={3}>
          {props.step === "step1" && (
            <Button
              variant="contained"
              onClick={props.onNext}
              disabled={props.loading}
              color="info"
              sx={{
                mr: 2,
              }}
            >
              {"次へ"}
            </Button>
          )}
          {props.step === "step2" && (
            <Button
              variant="contained"
              onClick={props.onPrevious}
              disabled={props.loading}
              color="warning"
              sx={{
                mr: 2,
              }}
            >
              {"前へ"}
            </Button>
          )}
        </Stack>
      }
      textConfirm={props.confirmButtonText}
      onClickSubmit={props.onRegister}
      isSubmit={props.isSubmit}
      disabled={props.loading || props.step === "step1"}
    >
      <Box>
        <Slide
          direction="right"
          in={props.step === "step1"}
          mountOnEnter
          unmountOnExit
        >
          <div>
            <Step1Form
              value={props.form1Value}
              onSubmit={props.onSubmitStep1Form}
              mode={props.mode}
              disabled={props.loading}
              _ref={props.form1Ref}
            />
          </div>
        </Slide>
        <Slide
          direction="left"
          in={props.step === "step2"}
          mountOnEnter
          unmountOnExit
        >
          <div>
            <Step2Form
              value={props.form2Value}
              onSubmit={props.onSubmitStep2Form}
              disabled={props.loading}
              _ref={props.form2Ref}
            />
          </div>
        </Slide>
      </Box>
    </FullScreenDialog>
  );
};
