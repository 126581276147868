import yup, { textSchema } from "utils/yup";

export const schema = yup.object().shape({
  companyName: textSchema.required().nullable(),
  corporateNumber: yup
    .string()
    .corporateNumberCheckDigits()
    .length(13)
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  representativeName: textSchema.nullable(),
  establishmentedOn: yup.date().nullable(),
  url: yup.string().url().nullable(),
});
