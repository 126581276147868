import {
  createVehicleSafetyInspectionMaintenanceRecord,
  updateVehicleSafetyInspectionRecord,
  removeVehicleSafetyInspectionRecord,
} from "api/graphql/mutations";
import {
  getManagementVehicle,
  getVehicleSafetyInspectionRecord,
} from "api/graphql/queries";
import { useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatJSDateToISODate } from "utils/format";
import { toApiValue, toFormValue } from "utils/ImageFunctions";
import { useMutation } from "utils/useMutation";
import { useOpener } from "utils/useOpener";
import { useQuery } from "utils/useQuery";

/**
 * 車検記録簿詳細を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const safetiesDetailsRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [formValue, setFormValue] = useState(null);
  const { id, safetyId } = useParams();
  const form = useOpener();
  const maintenanceForm = useOpener();
  const confirmDialog = useOpener();

  const managementVehicle = useQuery({
    query: getManagementVehicle,
    variables: {
      id: id,
    },
  });

  const safeties = useQuery({
    query: getVehicleSafetyInspectionRecord,
    variables: {
      id: safetyId,
    },
    onCompleted: (data) => {
      toFormValue({
        files: data?.data?.getVehicleSafetyInspectionRecord?.files,
      }).then((files) => {
        setFormValue({
          ...data?.data?.getVehicleSafetyInspectionRecord,
          inspectionCompany: {
            companyId:
              data?.data?.getVehicleSafetyInspectionRecord?.inspectionWorkplace
                ?.belongInCompanyId,
            name: data?.data?.getVehicleSafetyInspectionRecord
              ?.inspectionWorkplace?.belongInCompanyName,
          },
          details: data?.data?.getVehicleSafetyInspectionRecord?.details?.map(
            (detail) => ({
              ...detail,
              id: detail.order,
            })
          ),
          files: files,
        });
      });
    },
  });

  const [updateMutation, updateMutationState] = useMutation(
    updateVehicleSafetyInspectionRecord,
    {
      onCompleted: (data) => {
        safeties.refetch({
          id: safetyId,
        });

        safetiesDetailsRef.current.refresh();

        form.toggle(false);
      },
      succeedMessage: "保存しました。",
      errorMessage: "エラーが発生したため、保存できませんでした。",
    }
  );

  const [
    createVehicleMaintenanceRecordOfSafetyInspectionMutation,
    createVehicleMaintenanceRecordOfSafetyInspectionMutationState,
  ] = useMutation(createVehicleSafetyInspectionMaintenanceRecord, {
    onCompleted: (data) => {
      safetiesDetailsRef.current.refresh();

      maintenanceForm.toggle(false);
    },
    succeedMessage: "登録しました。",
    errorMessage: "エラーが発生したため、登録できませんでした。",
  });

  const [removeMutation, removeMutationStatus] = useMutation(
    removeVehicleSafetyInspectionRecord,
    {
      onCompleted: (params) => {
        navigate(`/vehicles-menu/safeties`);
      },
      succeedMessage: "削除しました。",
      errorMessage: "エラーが発生したため、削除できませんでした。",
    }
  );

  const handleCreateMaintenance = async (data) => {
    const {
      number,
      category,
      maintenanceWorkplace,
      maintenancedOn,
      price,
      remarks,
      files,
      details,
    } = data ?? {};

    createVehicleMaintenanceRecordOfSafetyInspectionMutation({
      input: {
        safetyInspectionId: safetyId,
        number: number,
        category: category,
        maintenanceWorkplaceId: maintenanceWorkplace?.id,
        maintenancedOn: formatJSDateToISODate(maintenancedOn),
        price: price,
        remarks: remarks,
        files: await toApiValue({ files: files }),
        details: details.map((detail) => ({
          ...detail,
          id: undefined,
          order: undefined,
        })),
      },
    });
  };

  const handleUpdate = async (data) => {
    const {
      inspectionWorkplace,
      receivedOn,
      completedOn,
      result,
      expiredOn,
      price,
      remarks,
      files,
      ignoreSynchronizeCurrentExpiryDate,
    } = data ?? {};

    updateMutation({
      input: {
        id: safetyId,
        inspectionWorkplaceId: inspectionWorkplace?.id,
        receivedOn: formatJSDateToISODate(receivedOn),
        completedOn: formatJSDateToISODate(completedOn),
        result: result,
        expiredOn: formatJSDateToISODate(expiredOn),
        price: price,
        remarks: remarks,
        files: await toApiValue({ files: files }),
        ignoreSynchronizeCurrentExpiryDate: ignoreSynchronizeCurrentExpiryDate,
        expectedVersion:
          safeties?.data?.getVehicleSafetyInspectionRecord?.version,
      },
    });
  };

  const handleSelected = (params) => {
    navigate(`${location.pathname}/maintenances/${params[0]}`);
  };

  const onRemove = () => {
    removeMutation({
      input: {
        id: safetyId,
      },
    });
  };

  return render({
    ...props,
    form: form,
    maintenanceForm: maintenanceForm,
    value: formValue,
    loading:
      safeties?.loading ||
      updateMutationState?.loading ||
      createVehicleMaintenanceRecordOfSafetyInspectionMutationState?.loading ||
      managementVehicle?.loading ||
      removeMutationStatus.loading,
    onUpdate: handleUpdate,
    onCreateMaintenance: handleCreateMaintenance,
    onSelected: handleSelected,
    safetiesDetailsRef: safetiesDetailsRef,
    safetyId: safetyId,
    managementVehicle: managementVehicle?.data?.getManagementVehicle,
    confirmDialog: confirmDialog,
    onRemove: onRemove,
  });
};
