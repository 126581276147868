import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * フリーワード検索を表示するコンポーネントです。
 * @param {func} onChangeSearch 検索値を渡すイベントです。
 * @param {object} props プロパティ
 */
export const TextSearcher = containerPresententer(Container, Presententer);
