import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  collect_start_wrap: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    width: "100%",
  },
  //ヘッダー
  appBar: {
    padding: theme.spacing(1, 2),
    minHeight: "68px",
  },
  header_name: {
    flexDirection: "column",
    width: "100%",
  },
  search: { width: "100%" },
  toolbar: { minHeight: "68px" },
  input: {
    backgroundColor: " #fff",
    border: "none",
    borderRadius: "20px",

    "& input": {
      color: theme.palette.grey[800],
      padding: "5px 8px",
      textAlign: "center",
      fontSize: "12px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
  // コンテナ
  content: {
    position: "relative",
    "& .alertAndNotifications": {
      position: "absolute",
      zIndex: "2",
      width: "fit-content",
      alignItems: "center",
      top: 0,
      left: 0,
    },
    "& .arrival_schedule": {
      marginLeft: theme.spacing(1),
      display: "inline-flex",
      marginTop: "8px",
      "& > div": {
        padding: 0,
      },
    },
  },

  //フッター
  appBar_footer: {
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: `${theme.palette.text.hint} !important`,
    bottom: 0,
    top: "auto !important",
    flexDirection: "row !important",
    padding: 0,
    minHeight: "58px",
  },
  toolbar_footer: { minHeight: "58px" },
  footer: {
    "& .buttonMenuMobile": {
      minWidth: "auto ",
      padding: "10px 16px 6px",
      flexDirection: "column",
      justifyContent: "center",
      textTransform: "none",
      fontSize: "13px",

      "& .MuiButton-startIcon": {
        marginLeft: "0",
        marginRight: " 0",
      },
    },
  },
}));
