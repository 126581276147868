import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 事業場情報タブを表示するコンポーネントです
 * @param {object} value 事業場情報
 * @param {number} tab タブ番号
 * @param {func} getTab タブのナンバーを取得する
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const WorkplaceDetailTabs = containerPresententer(
  Container,
  Presententer
);
