import { Box, Stack, Typography } from "@mui/material";
import { Monitoring } from "App";
import { userSelector } from "ducks/User";
import { useContext } from "react";
import { useSelector } from "react-redux";
import {
  routesBusiness,
  routesDashboard,
  routesManagement,
  toSideMenu,
} from "routes";
import { restrictRoutes } from "utils/Restricts";
import "utils/scrollbar.css";
import { Copyright, If } from "views/atoms";
import { SideMenu } from "./SideMenu";

/**
 * メニュー一覧を表示するコンポーネントです。
 * @param {object} props - プロパティ
 * @param {boolean} props.open 開閉状態
 * @returns {JSX.Element}
 */
export const SideMenuList = ({ open = true }) => {
  const currentUser = useSelector(userSelector);
  const monitoring = useContext(Monitoring);

  if (monitoring.error) {
    return (
      <Typography color="error.main">{"エラーが発生しました。"}</Typography>
    );
  }

  if (monitoring.loading) return <></>;

  return (
    <Stack
      sx={{
        backgroundColor: "#fff",
        flex: 1,
      }}
    >
      <>
        <DashboardList open={open} />
        <BusinessList
          open={open}
          isGeneralWastesPlan={monitoring.isGeneralWastesPlan}
        />
        <ManagementList
          open={open}
          user={currentUser}
          isGeneralWastesPlan={monitoring.isGeneralWastesPlan}
        />
      </>
      <If condition={open}>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            bottom: 0,
            width: 230,
            height: 20,
          }}
        >
          <Copyright />
        </Box>
      </If>
    </Stack>
  );
};

/**
 * ダッシュボードリストを表示するコンポーネントです。
 * @param {boolean} props.open - 開閉状態
 * @returns {JSX.Element}
 */
const DashboardList = ({ open = false }) => {
  return (
    <If condition={open}>
      <SideMenu routes={toSideMenu(routesDashboard)} open={open} exact={true} />
    </If>
  );
};

/**
 * 日常業務のメニューリストを表示するコンポーネントです。
 * @param {object} props - プロパティ
 * @param {boolean} props.open - 開閉状態
 * @param {boolean} props.isGeneralWastesPlan - 一般廃棄物管理プランであるか
 * @returns {JSX.Element}
 */
const BusinessList = ({ open = false, isGeneralWastesPlan = false }) => {
  return (
    <SideMenu
      subheader={"日常業務"}
      routes={restrictRoutes({
        routes: toSideMenu(routesBusiness),
        isGeneralWastesPlan: isGeneralWastesPlan,
      })}
      open={open}
    />
  );
};

/**
 * 管理機能のメニューリストを表示するコンポーネントです。
 * @param {object} props - プロパティ
 * @param {boolean} props.open - 開閉状態
 * @param {object} props.user - ユーザー情報
 * @param {boolean} props.isGeneralWastesPlan - 一般廃棄物管理プランであるか
 * @returns {JSX.Element}
 */
const ManagementList = ({
  open = false,
  user,
  isGeneralWastesPlan = false,
}) => {
  return (
    <If condition={open}>
      <If
        condition={user?.roleName === "オーナー" || user?.roleName === "管理者"}
      >
        <SideMenu
          subheader={!open ? "" : "管理機能"}
          routes={restrictRoutes({
            routes: toSideMenu(routesManagement),
            isGeneralWastesPlan: isGeneralWastesPlan,
          })}
          open={open}
        />
      </If>
    </If>
  );
};
