import { yupResolver } from "@hookform/resolvers/yup";
import { useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { debugLog } from "utils/log";
import { basicSchema } from "./Validations";

/**
 * デフォルトで設定される値
 */
const defaultValues = {
  number: null,
  name: null,
  manufactuerName: null,
  modelNumber: null,
  width: null,
  height: null,
  depth: null,
  volume: null,
  remarks: null,
  files: [],
};

/**
 * コンテナ種類のフォームを表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @fires Container#onSubmit 送信時
 * @fires Container#onError エラー時
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  loading = false,
  onSubmit = (data) => {
    debugLog("コンテンツ種類フォームの送信：", data);
  },
  onError = (errors) => {
    debugLog("コンテンツ種類フォームのエラー：", errors);
  },
  ...props
}) => {
  const { control, getValues, trigger, formState } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(basicSchema),
  });

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      trigger().then((res) => {
        if (res) {
          onSubmit && onSubmit(getValues());
        } else {
          debugLog("コンテナ種類登録コンポーネントエラー:", formState.errors);
          onError(formState.errors);
        }
      });
    },
    get: () => getValues(),
  }));

  return render({
    ...props,
    loading: loading,
    control: control,
  });
};
