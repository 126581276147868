import React from "react";
import { Dialog, IconButton } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import { InformationItem, Thumbnail, PDFViewer } from "views/atoms";
import { HeavenlyStems } from "utils/Context";

/**
 * 契約情報の詳細を表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @param {object} props.value 契約情報
 * @param {string} props.selectedFileUrl 選択中のファイルDataURL
 * @param {func} props.handleSelectedFileUrlChanged ファイルDataURL変更時の処理
 * @param {func} props.onChangeTab タブ変更時の処理
 * @returns
 */
export const Presententer = (props) => {
  const { value, selectedFileUrl, handleSelectedFileUrlChanged } = props;
  return (
    <div
      style={{
        padding: " 16px 32px",
        backgroundColor: " #fff",
      }}
    >
      <InformationItem label="管理番号">{value?.number}</InformationItem>
      <InformationItem label="契約形態">{value?.type?.name}</InformationItem>
      {value?.partyCompanies?.map((company, index) => (
        <InformationItem
          label={`契約者（${HeavenlyStems[index].name}）`}
          key={index}
        >
          {company?.name}
        </InformationItem>
      ))}
      <InformationItem label="契約日">{value?.executedOn}</InformationItem>
      <InformationItem label="契約期間">{`${
        value?.termOfAgreementStartedOn ?? ""
      } ～ ${value?.termOfAgreementExpirationOn ?? ""}`}</InformationItem>
      <InformationItem label="契約期間終了後の自動更新">
        {!value ? "" : value.isAutomaticallyRenewed ? "あり" : "なし"}
      </InformationItem>
      <InformationItem label="備考">
        <div
          style={{
            whiteSpace: "pre",
          }}
        >
          {value?.remarks}
        </div>
      </InformationItem>
      <InformationItem label="添付ファイル">
        {value?.files?.map((file, index) => (
          <Thumbnail
            key={index}
            base64={file.context}
            deletable={false}
            title={file.name}
            width={200}
            height={144}
            onClick={() => handleSelectedFileUrlChanged(file.context)}
          />
        ))}
      </InformationItem>
      <Dialog
        open={Boolean(selectedFileUrl)}
        onClose={() => handleSelectedFileUrlChanged(null)}
      >
        <PDFViewer files={selectedFileUrl} />
        <IconButton component={"a"} download href={selectedFileUrl}>
          <GetAppIcon />
        </IconButton>
      </Dialog>
    </div>
  );
};
