import React from "react";
import { RenderButtonToolBar } from "utils/XgridExpand";
import { XGridComponent, FullScreenDialog } from "views/molecules";
import { FlowForm } from "views/organisms/Masters/Flow";

const Form = React.forwardRef((props, ref) => (
  <FlowForm {...props} _ref={ref} />
))

// UNDONE: apiは手間がかかるため保留状態。区間数や処分場、処分方法

/**
 * 事業者・事業場の表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns
 */
export const Presententer = (props) => {
  return (
    <div>
      <XGridComponent
        rows={props.rows}
        columns={props.columns}
        onChangeSelection={props.onChangeSelection}
        checkboxSelection={false}
        multipleSelection={false}
        onDownload={true}
        actionButton={
          <RenderButtonToolBar
            label="新しい処理フローを追加"
            onClick={props.onOpenDialog}
          />
        }
        {...props.xGridParams}
      />
      <FullScreenDialog
        open={props.open}
        onClose={props.onCloseDialog}
        title="新しい処理フローの追加"
        textConfirm="追加"
        onClickSubmit={props.onClickRegister}
        isSubmit={props.isSubmit}
        disabled={props.isSubmit}
      >
        <Form onSubmit={props.getDataSubmit} ref={props.formRef} />
      </FullScreenDialog>
    </div>
  );
};
