/**
 * 処理フローの選択するコンテナコンポーネントです。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({
  render,
  value,
  options,
  onChange,
  onClickDetails,
  display = false,
  ...props
}) => {
  const handleChange = (e) => {
    onChange &&
      onChange(options.find((option) => option.wasteFlowId === e.target.value));
  };

  return render({
    value: value,
    options: options,
    onChange: handleChange,
    onClickDetails: onClickDetails,
    display: display,
    ...props,
  });
};
