import { useJsApiLoader } from "@react-google-maps/api";
import { createClient } from "api/graphql/mutations";
import { searchClientsByOffset } from "api/graphql/queries";
import { add as addAlert } from "ducks/Alert";
import { companySelector } from "ducks/Company";
import { toggle } from "ducks/Loading";
import { DateTime } from "luxon";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PhoneNumberFormatUtil } from "utils/format";
import { debugLog } from "utils/log";
import { useXGridComponents } from "utils/useXGridComponents";
import { fetchLatLng, getLatLng } from "views/templates/Mobile/Collect/Utils";
import { getGridStringOperators } from "@mui/x-data-grid-pro";
import { API, graphqlOperation } from "utils/graphqlOperation";

const columns = [
  {
    field: "name",
    headerName: "名称",
    width: 350,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "headOfficeWorkplacePostalCode",
    headerName: "本社郵便番号",
    valueGetter: (params) => params.row.headOfficeWorkplace?.postalCode,
    width: 100,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "headOfficeWorkplacePrefecturesName",
    headerName: "本社都道府県",
    valueGetter: (params) => params.row?.headOfficeWorkplace?.prefectures?.name,
    width: 100,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "headOfficeWorkplaceCity",
    headerName: "本社市区町村",
    valueGetter: (params) => params.row?.headOfficeWorkplace?.city,
    width: 130,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "headOfficeWorkplaceStreetAddress",
    headerName: "本社町名",
    valueGetter: (params) => params.row?.headOfficeWorkplace?.streetAddress,
    width: 100,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "headOfficeWorkplaceOtherAddress",
    headerName: "本社町名以降",
    valueGetter: (params) => params.row?.headOfficeWorkplace?.otherAddress,
    width: 200,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "headOfficeWorkplacePhone",
    headerName: "本社電話番号",
    width: 170,
    valueGetter: (params) => params.row?.headOfficeWorkplace?.phone,
    valueFormatter: (params) =>
      PhoneNumberFormatUtil.formatNational(params.value),
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
];

/**
 * 取引先を選択するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @fires Container#onSelected 選択時
 * @param {object} fixedOptions 固定の検索オプション
 * @param {boolean} add 追加アクションを行うか
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  supplierCompanyId,
  value,
  onSelected = (data) => debugLog(data),
  fixedOptions,
  add = true,
  selectableOwn = false,
  autoHeight = false,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const company = useSelector(companySelector);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const xGridObject = useXGridComponents(
    columns,
    searchClientsByOffset,
    {
      ...fixedOptions,
      other: {
        supplierCompanyId: company.id,
      },
    },
    {
      sort: {
        direction: "desc",
        field: "createdAt",
      },
    }
  );
  useJsApiLoader({
    id: "google-map",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_LICENSE_KEY,
    region: "JP",
    language: "ja",
  });

  const handleSelected = (params) => {
    onSelected(
      xGridObject.params.rows.filter((row) => params.includes(row.id))
    );
  };

  const handleOwnSelected = (own) => {
    onSelected(own ? [own] : null);
  };

  const beforeSubmit = (data) => {
    const {
      headOfficeWorkplace: { prefectures, city, streetAddress, otherAddress },
    } = data;

    getLatLng(`${prefectures.name}${city}${streetAddress}${otherAddress}`)
      .then((res) => {
        create({
          ...value,
          headOfficeWorkplace: {
            ...data.headOfficeWorkplace,
            position: fetchLatLng(res),
          },
        });
      })
      .catch((err) => {
        debugLog(err);
        setSubmittedData(submittedData);
        setIsOpenConfirmDialog(true);
      });
  };

  const handleForceSubmit = () => {
    create(submittedData);
    setIsOpenConfirmDialog(false);
  };

  const create = (data) => {
    const { categories, headOfficeWorkplace, establishmentedOn, ...other } =
      data;
    const {
      prefectures,
      phone,
      fax,
      industrySector,
      ...headOfficeWorkplaceOther
    } = headOfficeWorkplace;

    dispatch(toggle(true));
    setIsSubmit(true);
    API.graphql(
      graphqlOperation(createClient, {
        input: {
          categories: categories.map((category) => category.code),
          establishmentedOn: DateTime.fromJSDate(
            new Date(establishmentedOn)
          ).toFormat("yyyy-MM-dd"),
          headOfficeWorkplace: {
            prefecturesCode: prefectures.code,
            industrySectorId: industrySector?.id,
            phone: PhoneNumberFormatUtil.formatInternational(phone),
            fax: PhoneNumberFormatUtil.formatInternational(fax),
            ...headOfficeWorkplaceOther,
          },
          ...other,
        },
      })
    )
      .then((res) => {
        setIsSubmit(false);
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );

        xGridObject.functions.refetch();
        setOpen(false);
      })
      .catch((err) => {
        setIsSubmit(false);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        dispatch(toggle(false));
      });
  };

  return render({
    isSubmit: isSubmit,
    open: open,
    onSelected: handleSelected,
    onOpenDialog: () => setOpen(true),
    onCloseDialog: () => setOpen(false),
    onSubmit: beforeSubmit,
    forceSubmit: handleForceSubmit,
    closeConfirmDialog: () => setIsOpenConfirmDialog(false),
    isOpenConfirmDialog: isOpenConfirmDialog,
    xGridParams: xGridObject.params,
    add: add,
    supplierCompanyId: supplierCompanyId,
    selectableOwn: selectableOwn,
    company: company,
    onOwnSelected: handleOwnSelected,
    autoHeight: autoHeight,
    ...props,
  });
};
