/**
 * 事業場情報タブを表示するコンテナコンポーネントです
 */
export const Container = ({ render, value, tab = 0, getTab, ...props }) => {
  return render({
    ...props,
    tab: tab,
    value: value,
    getTab: getTab,
  });
};
