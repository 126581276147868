import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "13px",
    color: theme.palette.grey[800],
    marginBottom: "5px",
    display: "block",
    fontWeight: "600",
    userSelect: "none",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
  },
  subTitle: {
    fontSize: "11px",
    color: theme.palette.grey[500],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& .time": {
      display: "flex",
      alignItems: "center",
      "& svg": {
        fontSize: "18px",
        marginRight: "5px",
      },
    },
    "& span": {
      width: "100%",
      whiteSpace: "nowrap",
    },
    "@media (max-width: 321px)": {
      flexDirection: "column",
      justifyContent: "flex-start",
      "& .time": {
        marginBottom: "5px",
      },
    },
  },
}));
