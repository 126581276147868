import { Container, Grid, Dialog } from "@mui/material";
import React from "react";
import { Controller, FormProvider } from "react-hook-form";
import {
  InputField,
  InputFieldPopppSelect,
  RadioGroup,
  SelectPopup,
  StepFrame,
  FileUploader,
  FieldDecorateFrame,
  DatePicker,
  DateRangePicker,
  ContractorSelector,
  ModalPopup,
} from "views/molecules";
import { Thumbnail, PDFViewer, FreeSolo } from "views/atoms";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import GetAppIcon from "@mui/icons-material/GetApp";
import { HeavenlyStems } from "utils/Context";

/**
 * 契約情報を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Elemnt} JSX 要素
 */
export const Presententer = (props) => {
  return (
    <FormProvider {...props.methods}>
      <form id={props.id} onSubmit={props.submit} noValidate>
        <Container maxWidth="md">
          <StepFrame>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="number"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <InputField
                      label="管理番号"
                      value={value}
                      placeholder="管理番号を入力します。"
                      onChange={onChange}
                      required={false}
                      error={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <FieldDecorateFrame label="契約書タイトル" required={true}>
                      {(params) => (
                        <FreeSolo
                          value={value}
                          onChange={onChange}
                          error={Boolean(error)}
                          helperText={error?.message}
                          options={props.listContractTitle}
                          required={params.required}
                        />
                      )}
                    </FieldDecorateFrame>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="contractType"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) =>
                    !props.readOnlyControls?.contractType ? (
                      <SelectPopup
                        label="契約形態"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          props.onChangeContractType(e);
                        }}
                        required={true}
                        error={error}
                        options={props.contractTypes}
                        optionValueField={"id"}
                        optionNameField={"name"}
                      />
                    ) : (
                      <InputField
                        label="契約形態"
                        value={value?.name}
                        error={error}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    )
                  }
                />
              </Grid>
              {props.parties.map((_, index) => (
                <Grid item xs={12} key={index}>
                  <Controller
                    name={`parties[${index}]`}
                    control={props.control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) =>
                      !props.readOnlyControls?.parties ? (
                        <InputFieldPopppSelect
                          label={`契約者（${HeavenlyStems[index].name}）`}
                          value={value}
                          onChange={onChange}
                          required={true}
                          placeholder={`契約者（${HeavenlyStems[index].name}）を選択します。`}
                          showIcon={false}
                          onOpenModal={() => props.onOpenModal(index)}
                          textConfirm="選択"
                          error={
                            Boolean(props.methods.formState.errors?.parties) ||
                            Boolean(error)
                          }
                          helperText={
                            props.methods.formState.errors?.parties?.message ||
                            error?.message ||
                            error?.id?.message
                          }
                        />
                      ) : (
                        <InputField
                          label={`契約者（${HeavenlyStems[index].name}）`}
                          value={value?.name}
                          error={error}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      )
                    }
                  />
                </Grid>
              ))}
              <ModalPopup
                onClose={props.onCloseModal}
                open={props?.contractorSelectorIndex !== null}
              >
                <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                  <ContractorSelector
                    title={`契約者（${
                      HeavenlyStems[props?.contractorSelectorIndex]?.name ?? ""
                    }）の選択`}
                    onSelected={props.onChangeContractor}
                  />
                </Box>
              </ModalPopup>
              <Grid item xs={12}>
                <Controller
                  control={props.control}
                  name="executedOn"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <DatePicker
                      label="契約日"
                      required={true}
                      value={value}
                      onChange={onChange}
                      error={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldDecorateFrame label="契約期間" required={true}>
                  {(params) => (
                    <Controller
                      name="contractOn"
                      control={props.control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <DateRangePicker
                            id="contractOn"
                            value={value}
                            onChange={onChange}
                            error={Boolean(error)}
                            helperText={
                              error?.message ||
                              error?.start?.message ||
                              error?.end?.message
                            }
                          />
                        );
                      }}
                    />
                  )}
                </FieldDecorateFrame>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={props.control}
                  name="isAutomaticallyRenewed"
                  render={({ field: { value, onChange } }) => (
                    <RadioGroup
                      label="契約期間終了後の自動更新の有無"
                      required={true}
                      value={value}
                      onChange={onChange}
                      row={true}
                      sizeLarge={false}
                      options={[
                        { value: true, mainTitle: "あり" },
                        { value: false, mainTitle: "なし" },
                      ]}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={props.control}
                  name="remarks"
                  render={({ field: { value, onChange } }) => (
                    <InputField
                      label="備考"
                      value={value}
                      onChange={onChange}
                      placeholder="特記事項があれば入力します。"
                      multiline
                      minRows={4}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="files"
                  control={props.control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <FileUploader
                        label="添付ファイル"
                        maxSize={26214400}
                        accept={[".pdf"]}
                        onChange={(file) => onChange([...value, file])}
                      />
                      <div
                        style={{
                          display: "flex",
                          direction: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {value?.map((file, index) => (
                          <Thumbnail
                            key={index}
                            base64={file.context}
                            deletable={true}
                            onClick={() =>
                              props.handleSelectedFileUrlChanged(file.context)
                            }
                            onDelete={() =>
                              onChange(value.filter((_, i) => i !== index))
                            }
                            title={file.name}
                            width={200}
                            height={144}
                          />
                        )) ?? <></>}
                      </div>
                    </>
                  )}
                />
                <Dialog
                  open={Boolean(props.selectedFileUrl)}
                  onClose={() => props.handleSelectedFileUrlChanged(null)}
                >
                  <PDFViewer files={props.selectedFileUrl} />
                  <IconButton
                    component={"a"}
                    download
                    href={props.selectedFileUrl}
                  >
                    <GetAppIcon />
                  </IconButton>
                </Dialog>
              </Grid>
            </Grid>
          </StepFrame>
        </Container>
      </form>
    </FormProvider>
  );
};
