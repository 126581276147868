import { wasteReportingMediaSelector } from "ducks/WasteReportingMedia";
import { useSelector } from "react-redux";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { styles } from "./styles";

const options = [
  {
    value: "electronicManifest",
    title: "電子マニフェスト",
    description:
      "日本産業廃物処理振興センターのEDI接続によるマニフェストの報告を行います。",
  },
  {
    value: "paperManifest",
    title: "紙マニフェスト",
    description:
      "紙マニフェストの報告を行います。年間報告書の提出義務が発生します。",
  },
  {
    value: "originalSlip",
    title: "オリジナル伝票",
    description:
      "オリジナル伝票による報告を行います。マニフェストの交代が必要なものには使用しないでください。",
  },
];

const defaultValues = [];

/**
 * 報告方法を選択するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @fires Container#onChange 変更時
 * @param {boolean} error エラーであるか
 * @param {string} helperText 補助的テキスト
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value = defaultValues,
  onChange = (data) => debugLog("報告方法変更: ", data),
  error,
  helperText,
  ...props
}) => {
  const classes = useClasses(styles);
  const wasteReportingMedia = useSelector(wasteReportingMediaSelector);

  const handleChange = (event) => {
    let result = [...value];
    const isIncludes = result?.includes(event.target.name);

    if (isIncludes) {
      result = result.filter((item) => item !== event.target.name);
    } else {
      result.push(event.target.name);
    }

    onChange(result);
  };

  return render({
    ...props,
    classes: classes,
    value: value,
    error: error,
    helperText: helperText,
    wasteReportingMedia: wasteReportingMedia,
    options: options.filter((option) =>
      wasteReportingMedia?.map((media) => media.id).includes(option.value)
    ),
    onChange: handleChange,
  });
};
