import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 取引先詳細ページでの事業場詳細の画面を表示するコンポーネントです。
 */
export const WorkplaceDetailsInPartner = containerPresententer(
  Container,
  Presententer
);
