import { Avatar, Box, Grid, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";

const isDate = DateTime.fromJSDate(new Date())
  .setLocale("ja")
  .toFormat("yyyy/MM/dd (ccc)");

/**
 *  回収記録ヘッダーの表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props
 * @returns JSX 要素
 */
export const Presententer = (props) => (
  <Grid container spacing={2}>
    <Grid item md={7} xs={12}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography sx={{ fontWeight: 600, mr: 3, fontSize: "18px" }}>
          {props?.value?.name ?? ""}
        </Typography>
        <Typography
          sx={{ fontSize: "13px", color: (theme) => theme.palette.grey[500] }}
        >
          {isDate}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src={props?.value?.driveInfo?.icon ?? ""}
          alt=""
          sx={{
            mr: 1,
            width: 32,
            height: 32,
          }}
        />
        <Typography
          sx={{ fontSize: "14px", color: (theme) => theme.palette.grey[600] }}
        >
          {props?.value?.driveInfo?.number ?? ""}
        </Typography>
        <Avatar
          sx={{
            ml: 3,
            mr: 1,
            width: 32,
            height: 32,
            fontSize: "12px",
            backgroundColor: (theme) => theme.palette.icon.company,
          }}
        >
          {props.value?.driverName?.lastName ?? ""}
        </Avatar>
        <Typography
          sx={{ fontSize: "14px", color: (theme) => theme.palette.grey[600] }}
        >
          {props.value?.driverName?.fullName ?? ""}
        </Typography>
      </Box>
    </Grid>
  </Grid>
);
