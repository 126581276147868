import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * マークを表示するコンポーネントです。
 * @param {string} backgroundColor 背景色
 * @param {string} text テキスト
 * @param {object} props その他プロパティ
 */
export const Mark = containerPresententer(Container, Presententer);
