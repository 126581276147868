import { Dialog } from "../Dialog";
import { TermsOfServicePDF } from "../TermsOfServicePDF";

/**
 * 利用規約ダイアログを表示するコンポーネント。
 * @param {object} props プロパティ
 * @param {boolean} props.open 開閉状態
 * @param {boolean} props.disabed 無効設定
 * @fires TermsOfServiceAgreeDialog#onClose 閉じる時
 * @fires TermsOfServiceAgreeDialog#onAgree 同意する時
 * @returns {JSX.Element}
 */
export const TermsOfServiceAgreeDialog = ({
  open = false,
  disabled,
  onClose = () => {},
  onAgree = () => {},
}) => {
  return (
    <Dialog
      onClose={onClose}
      onAgree={onAgree}
      open={open}
      title={"利用規約"}
      description={""}
      disabled={disabled}
      positiveText={"同意します"}
      negativeText={"同意しません"}
    >
      <TermsOfServicePDF />
    </Dialog>
  );
};
