import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { RadioGroup } from "views/molecules";
import { getAddress, getPhone, getDate } from "../Utils";

const GridItemBase = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  border: "1px solid #707070",
  display: "flex",
  alignItems: "center",
}));

const GridItemLabel = styled(GridItemBase)({
  backgroundColor: "#D7D7D7",
  justifyContent: "center",
  textAlign: "center",
  fontSize: "14px",
});

const GridItemValue = styled(GridItemBase)({
  fontSize: "14px",
});

const GridItem = (props) => {
  return (
    <>
      <GridItemLabel item xs={props.xs?.label} sx={props.sx?.label}>
        {props.label}
      </GridItemLabel>
      <GridItemValue item xs={props.xs?.value} sx={props.sx?.value}>
        {props.value}
      </GridItemValue>
    </>
  );
};

const IssueSection = (props) => {
  return (
    <Grid
      container
      // className={props.classes.section}
      onClick={() => {
        props?.onClick?.({
          name: "issue",
          value: {
            issueDate: props?.value?.issueDate,
            issueNumber: props?.value?.issueNumber,
            referenceNumber: props?.value?.referenceNumber,
            issuePersonInCharge: props?.value?.issuePersonInCharge,
          },
        });
      }}
    >
      <GridItem
        xs={{
          label: 1,
          value: 2,
        }}
        label={"交付年月日"}
        value={getDate(props?.value?.issueDate) ?? ""}
      />
      <GridItem
        xs={{
          label: 1,
          value: 2,
        }}
        label={"交付番号"}
        value={props?.value?.issueNumber ?? ""}
      />
      <GridItem
        xs={{
          label: 1,
          value: 2,
        }}
        label={"整理番号"}
        value={props?.value?.referenceNumber ?? ""}
      />
      <GridItem
        xs={{
          label: 1,
          value: 2,
        }}
        label={"交付担当者"}
        value={props?.value?.issuePersonInCharge ?? ""}
      />
    </Grid>
  );
};

const WasteGeneratorSection = (props) => (
  <Grid container sx={{ mt: 0, mb: 0 }}>
    <GridItemLabel item xs={1}>
      事業者(排出者)
    </GridItemLabel>
    <Grid
      item
      xs={5}
      // className={props.classes.section}
      onClick={() => {
        props?.onClick?.({
          name: "wasteGeneratorCompany",
          value: {
            wasteGeneratorCompany: props?.value?.wasteGeneratorCompany,
          },
        });
      }}
    >
      <Grid
        container
        item
        columns={5}
        sx={{
          height: "100%",
        }}
      >
        <GridItem
          xs={{
            label: 1,
            value: 4,
          }}
          label={"氏名又は名称"}
          value={props?.value?.wasteGeneratorCompany?.name ?? ""}
        />
        <GridItem
          xs={{
            label: 1,
            value: 4,
          }}
          label={"電話番号"}
          value={getPhone(props?.value?.wasteGeneratorCompany?.phone) ?? ""}
        />
        <GridItem
          xs={{
            label: 1,
            value: 4,
          }}
          label={"住所"}
          value={`${getAddress(props?.value?.wasteGeneratorCompany)}`}
        />
      </Grid>
    </Grid>
    <GridItemLabel item xs={1}>
      {"事業者"}
      <br />
      {"(排出事業場)"}
    </GridItemLabel>
    <Grid
      item
      xs={5}
      // className={props.classes.section}
      onClick={() => {
        props?.onClick?.({
          name: "wasteGeneratorWorkplace",
          value: {
            wasteGeneratorWorkplace: props?.value?.wasteGeneratorWorkplace,
          },
        });
      }}
    >
      <Grid
        container
        item
        columns={5}
        sx={{
          height: "100%",
        }}
      >
        <GridItem
          xs={{
            label: 1,
            value: 4,
          }}
          label={"名称"}
          value={props?.value?.wasteGeneratorWorkplace?.name ?? ""}
        />
        <GridItem
          xs={{
            label: 1,
            value: 4,
          }}
          label={"電話番号"}
          value={
            getPhone(
              props?.value?.wasteGeneratorWorkplace?.dischargesWorkplace?.phone
            ) ?? ""
          }
        />
        <GridItem
          xs={{
            label: 1,
            value: 4,
          }}
          label={"所在地"}
          value={`${getAddress(props?.value?.wasteGeneratorWorkplace)}`}
        />
      </Grid>
    </Grid>
  </Grid>
);

const Waste = (props) => (
  <Grid
    // className={props.classes.section}
    container
    sx={{ mt: 0, mb: 0 }}
    onClick={() => {
      props?.onClick?.({
        name: "waste",
        value: {
          ...props?.value?.waste,
        },
      });
    }}
  >
    <GridItemLabel item xs={1}>
      産業廃棄物
    </GridItemLabel>
    <Grid item xs={11}>
      <Grid container columns={11}>
        <GridItem
          xs={{
            label: 1,
            value: 4,
          }}
          label={"廃棄物の種類"}
          value={
            props?.value?.waste?.types
              ?.map((type) => {
                return `${
                  type?.wasteType?.largeClass?.code +
                  type?.wasteType?.middleClass?.code +
                  type?.wasteType?.smallClass?.code
                } ${type?.wasteTypeName || ""}`;
              })
              ?.join() ?? ""
          }
        />
        <GridItem
          xs={{
            label: 1,
            value: 2,
          }}
          label={"数量"}
          value={`${props?.value?.waste?.quantityValue ?? ""}${
            props?.value?.waste?.quantityUnitName ?? ""
          }`}
        />
        <GridItem
          xs={{
            label: 1,
            value: 2,
          }}
          label={"荷姿"}
          value={props?.value?.waste?.packingName ?? ""}
        />
        <GridItem
          xs={{
            label: 1,
            value: 4,
          }}
          label={"廃棄物の名称"}
          value={props?.value?.waste?.name ?? ""}
        />
        <GridItem
          xs={{
            label: 1,
            value: 2,
          }}
          label={"有害物質等"}
          value={props?.value?.waste?.hazardousSubstanceName ?? ""}
        />
        <GridItem
          xs={{
            label: 1,
            value: 2,
          }}
          label={"処分方法"}
          value={props?.value?.waste?.disposalMethodName ?? ""}
        />
        <GridItem
          xs={{
            label: 1,
            value: 10,
          }}
          label={"備考・通信欄"}
          value={props?.value?.waste?.remarks}
        />
      </Grid>
    </Grid>
  </Grid>
);

const MiddleDisposalProcess = (props) => (
  // todo: apiがどう返すかによってradioの値を変える
  <Grid
    container
    // className={props.classes.section}
    onClick={() => {
      props?.onClick?.({
        name: "intermediateProcessingManagementType",
        value: {
          intermediateProcessingManagementType:
            props?.value?.intermediateProcessingManagementType,
          primaryManifestRecord: props?.value?.primaryManifestRecord,
        },
      });
    }}
  >
    <GridItemLabel item xs={1}>
      {"中間処理"}
      <br />
      {"産業廃棄物"}
    </GridItemLabel>
    <Grid item xs={11}>
      <Grid
        container
        sx={{
          height: "100%",
        }}
      >
        <GridItemValue item xs={12}>
          {
            "管理票交付者(処分委託者)の氏名又は名称及び管理票の交付番号(登録番号)"
          }
        </GridItemValue>
        <GridItemValue item xs={5}>
          <RadioGroup
            value={props?.value?.intermediateProcessingManagementType?.code}
            disabled={true}
            row={true}
            sizeLarge={false}
            options={[
              { value: 1, mainTitle: "帳簿記載のとおり" },
              { value: 2, mainTitle: "当欄記載のとおり" },
            ]}
          />
        </GridItemValue>
        <GridItemValue item xs={7}>
          {`${
            props?.value?.primaryManifestRecord?.primaryManifestIssuerName ?? ""
          } ${
            props?.value?.primaryManifestRecord?.primaryManifestIssueNumber ??
            ""
          }`}
        </GridItemValue>
      </Grid>
    </Grid>
  </Grid>
);

const LastDisposalPlace = (props) => (
  <Grid
    container
    // className={props.classes.section}
    onClick={() => {
      props?.onClick?.({
        name: "lastDisposalPlantDescribeType",
        value: {
          lastDisposalPlantDescribeType:
            props?.value?.lastDisposalPlantDescribeType,
          plannedLastDisposalPlant: props?.value?.plannedLastDisposalPlant,
        },
      });
    }}
  >
    <GridItemLabel item xs={1}>
      最終処分の場所
    </GridItemLabel>
    <Grid item xs={11}>
      <Grid
        container
        sx={{
          height: "100%",
        }}
      >
        <GridItemValue item xs={12}>
          {"名称／所在地／電話番号"}
        </GridItemValue>
        <GridItemValue item xs={5}>
          <RadioGroup
            value={props?.value?.lastDisposalPlantDescribeType?.code}
            row={true}
            sizeLarge={false}
            disabled={true}
            options={[
              { value: 1, mainTitle: "委託契約書記載のとおり" },
              { value: 2, mainTitle: "当欄記載のとおり" },
            ]}
          />
        </GridItemValue>
        <GridItemValue item xs={7}>
          {props?.value?.plannedLastDisposalPlant?.name ?? ""}
          {getPhone(props?.value?.plannedLastDisposalPlant?.phone) ?? ""}
          {getAddress(props?.value?.lastDisposalPlace)}
        </GridItemValue>
      </Grid>
    </Grid>
  </Grid>
);

const TransportationTrustee = (props) => (
  <Grid
    container
    // className={props.classes.section}
    sx={{ mt: 0, mb: 0 }}
    onClick={() => {
      props?.onClick?.({
        name: "wasteCarrierCompany",
        value: {
          wasteCarrierCompany: props?.value?.wasteCarrierCompany,
        },
      });
    }}
  >
    <GridItemLabel item xs={2}>
      運搬受託者
    </GridItemLabel>
    <Grid item xs={10}>
      <Grid
        container
        columns={5}
        sx={{
          height: "100%",
        }}
      >
        <GridItem
          xs={{
            label: 1,
            value: 4,
          }}
          label={"氏名又は名称"}
          value={props?.value?.wasteCarrierCompany?.name ?? ""}
        />
        <GridItem
          xs={{
            label: 1,
            value: 4,
          }}
          label={"電話番号"}
          value={getPhone(props?.value?.wasteCarrierCompany?.phone) ?? ""}
        />
        <GridItem
          xs={{
            label: 1,
            value: 4,
          }}
          label={"住所"}
          value={`${getAddress(props?.value?.wasteCarrierCompany)}`}
        />
      </Grid>
    </Grid>
  </Grid>
);

const TransportationPlace = (props) => (
  <Grid
    container
    // className={props.classes.section}
    sx={{ mt: 0, mb: 0, height: "100%" }}
    onClick={() => {
      props?.onClick?.({
        name: "wasteTransportDestination",
        value: {
          wasteTransportDestination: props?.value?.wasteTransportDestination,
        },
      });
    }}
  >
    <GridItemLabel item xs={2}>
      運搬先の事業場
    </GridItemLabel>
    <Grid item xs={10}>
      <Grid
        container
        item
        columns={5}
        sx={{
          height: "100%",
        }}
      >
        <GridItem
          xs={{
            label: 1,
            value: 4,
          }}
          label={"名称"}
          value={props?.value?.wasteTransportDestination?.name ?? ""}
        />
        <GridItem
          xs={{
            label: 1,
            value: 4,
          }}
          label={"電話番号"}
          value={getPhone(props?.value?.wasteTransportDestination?.phone) ?? ""}
        />
        <GridItem
          xs={{
            label: 1,
            value: 4,
          }}
          label={"所在地"}
          value={`${getAddress(props?.value?.wasteTransportDestination)}`}
        />
      </Grid>
    </Grid>
  </Grid>
);

const DisposalTrustee = (props) => (
  <Grid
    container
    // className={props.classes.section}
    sx={{ mt: 0, mb: 0 }}
    onClick={() => {
      props?.onClick?.({
        name: "wasteDisposalCompany",
        value: {
          wasteDisposalCompany: props?.value?.wasteDisposalCompany,
        },
      });
    }}
  >
    <GridItemLabel item xs={2}>
      処分受託者
    </GridItemLabel>
    <Grid item xs={10}>
      <Grid container columns={5}>
        <GridItem
          xs={{
            label: 1,
            value: 4,
          }}
          label={"名称"}
          value={props?.value?.wasteDisposalCompany?.name ?? ""}
        />
        <GridItem
          xs={{
            label: 1,
            value: 4,
          }}
          label={"電話番号"}
          value={getPhone(props?.value?.wasteDisposalCompany?.phone) ?? ""}
        />
        <GridItem
          xs={{
            label: 1,
            value: 4,
          }}
          label={"所在地"}
          value={`${getAddress(props?.value?.wasteDisposalCompany)}`}
        />
      </Grid>
    </Grid>
  </Grid>
);

const TransshipmentPlace = (props) => (
  <div
    style={{
      marginTop: "0px",
    }}
  >
    <Grid
      container
      // className={props.classes.section}
      sx={{
        mt: 0,
        mb: 0,
      }}
      onClick={() => {
        props?.onClick?.({
          name: "wasteTransshipmentStorage",
          value: {
            wasteTransshipmentStorage: props?.value?.wasteTransshipmentStorage,
          },
        });
      }}
    >
      <div
        style={{
          position: "absolute",
          borderTop: "solid gray 1px",
          transform: "matrix(1, -0.20, 0, 1, 0, 60)",
        }}
      />
      <div
        style={{
          borderTop: "solid gray 1px",
          transform: "matrix(1, 0.20, 0, 1, 0, 60)",
        }}
      />
      <GridItemLabel item xs={2}>
        {`積替え又は保管`}
      </GridItemLabel>
      <Grid xs={10}>
        <Grid container columns={5}>
          <GridItem
            xs={{
              label: 1,
              value: 4,
            }}
            label={"名称"}
            value={props?.value?.wasteTransshipmentStorage?.name ?? ""}
          />
          <GridItem
            xs={{
              label: 1,
              value: 4,
            }}
            label={"電話番号"}
            value={
              getPhone(props?.value?.wasteTransshipmentStorage?.phone) ?? ""
            }
          />
          <GridItem
            xs={{
              label: 1,
              value: 4,
            }}
            label={"所在地"}
            value={`${getAddress(props?.value?.wasteTransshipmentStorage)}`}
          />
        </Grid>
      </Grid>
    </Grid>
  </div>
);

const TransportationContract = (props) => (
  <Grid
    container
    // className={props.classes.section}
    sx={{ mt: 0, mb: 0 }}
    onClick={() => {
      props?.onClick?.({
        name: "wasteTransportReport",
        value: {
          wasteTransportReport: props?.value?.wasteTransportReport,
        },
      });
    }}
  >
    <GridItemLabel item xs={1}>
      運搬の受託
    </GridItemLabel>
    <Grid xs={5}>
      <Grid
        container
        sx={{
          height: "100%",
        }}
      >
        <GridItem
          xs={{
            label: 5,
            value: 7,
          }}
          label={"受託者の氏名又は名称"}
          value={
            props?.value?.wasteTransportReport?.wasteCarrierCompanyName ?? ""
          }
        />
        <GridItem
          xs={{
            label: 5,
            value: 7,
          }}
          label={"運搬担当者の氏名"}
          value={
            props?.value?.wasteTransportReport?.wasteCarrierPersonInCharge ?? ""
          }
        />
      </Grid>
    </Grid>
    <GridItem
      xs={{
        label: 1,
        value: 2,
      }}
      label={"運搬終了年月日"}
      value={
        getDate(props?.value?.wasteTransportReport?.endOfTransportationDate) ??
        ""
      }
    />
    <GridItem
      xs={{
        label: 1,
        value: 2,
      }}
      label={"有価物収拾量"}
      value={`${
        props?.value?.wasteTransportReport?.valuablesQuantityValue ?? ""
      }${props?.value?.wasteTransportReport?.valuablesQuantityUnitName ?? ""}`}
    />
  </Grid>
);

const DisposalContract = (props) => (
  <Grid
    container
    // className={props.classes.section}
    sx={{ mt: 0, mb: 0 }}
    onClick={() => {
      props?.onClick?.({
        name: "wasteDisposalReport",
        value: {
          wasteDisposalReport: props?.value?.wasteDisposalReport,
        },
      });
    }}
  >
    <GridItemLabel item xs={1}>
      処分の受託
    </GridItemLabel>
    <Grid xs={5}>
      <Grid
        container
        sx={{
          height: "100%",
        }}
      >
        <GridItem
          xs={{
            label: 5,
            value: 7,
          }}
          label={"受託者の氏名又は名称"}
          value={
            props?.value?.wasteDisposalReport?.wasteDisposalCompanyName ?? ""
          }
        />
        <GridItem
          xs={{
            label: 5,
            value: 7,
          }}
          label={"処分担当者の氏名"}
          value={
            props?.value?.wasteDisposalReport?.wasteDisposalPersonInCharge ?? ""
          }
        />
      </Grid>
    </Grid>
    <GridItem
      xs={{
        label: 1,
        value: 2,
      }}
      label={"処分終了年月日"}
      value={
        getDate(props?.value?.wasteDisposalReport?.endOfDisposalDate) ?? ""
      }
    />
    <GridItem
      xs={{
        label: 1,
        value: 2,
      }}
      label={
        <>
          {"最終処分"} <br /> {"終了年月日"}
        </>
      }
      value={
        getDate(props?.value?.wasteDisposalReport?.endOfLastDisposalDate) ?? ""
      }
    />
  </Grid>
);

const DisposedLastPlace = (props) => (
  <Grid
    container
    // className={props.classes.section}
    sx={{ mt: 0, mb: 0 }}
    onClick={() => {
      props?.onClick?.({
        name: "wasteLastDisposalPlant",
        value: {
          wasteLastDisposalPlant: props?.value?.wasteLastDisposalPlant,
        },
      });
    }}
  >
    <GridItemLabel item xs={1}>
      {`最終処分を`}
      <br />
      {`行った場所`}
    </GridItemLabel>
    <GridItem
      xs={{
        label: 1,
        value: 3,
      }}
      label={"名称"}
      value={
        props?.value?.wasteLastDisposalPlant?.name ??
        props?.value?.wasteLastDisposalPlant?.contractNumber ??
        ""
      }
    />
    <GridItem
      xs={{
        label: 1,
        value: 1,
      }}
      label={"電話番号"}
      value={getPhone(props?.value?.wasteLastDisposalPlant?.phone) ?? ""}
    />
    <GridItem
      xs={{
        label: 1,
        value: 4,
      }}
      label={"所在地"}
      value={`${getAddress(props?.value?.wasteLastDisposalPlant)}`}
    />
  </Grid>
);

/**
 * 紙マニフェスト詳細を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Elemnt}
 */
export const Presententer = (props) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <IssueSection {...props} />
      </Grid>
      <Grid item xs={12}>
        <WasteGeneratorSection {...props} />
      </Grid>
      <Grid item xs={12}>
        <Waste {...props} />
      </Grid>
      <Grid item xs={12}>
        <MiddleDisposalProcess {...props} />
      </Grid>
      <Grid item xs={12}>
        <LastDisposalPlace {...props} />
      </Grid>
      <Grid item xs={6}>
        <TransportationTrustee {...props} />
      </Grid>
      <Grid item xs={6}>
        <TransportationPlace {...props} />
      </Grid>
      <Grid item xs={6}>
        <DisposalTrustee {...props} />
      </Grid>
      <Grid item xs={6}>
        <TransshipmentPlace {...props} />
      </Grid>
      <Grid item xs={12}>
        <TransportationContract {...props} />
      </Grid>
      <Grid item xs={12}>
        <DisposalContract {...props} />
      </Grid>
      <Grid item xs={12}>
        <DisposedLastPlace {...props} />
      </Grid>
    </Grid>
  );
};
