/**
 * 自社事業場情報を表示するコンポーネントです
 */
export const Container = ({ render, onTransite, id, ...props }) => {
  return render({
    id: id,
    onTransite: onTransite,
    ...props,
  });
};
