import React from "react";
import { DomainOutlined } from "@mui/icons-material";
import { Button, Avatar, Stack, Typography } from "@mui/material";
import { Mark } from "views/atoms";
import { FullScreenDialog, TabsDetails, ConfirmDialog } from "views/molecules";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { WorkplaceForm } from "views/organisms/Masters/Workplace";
import {
  TabSimpleInformation,
} from "views/organisms/Masters/Workplace/WorkplaceDetailsTabs/ChildComponents";
import { WorkplaceCategoryColorMap } from "utils/Context";

/**
 * 事業場情報を詳しく表示するプレゼンテーションコンポーネントです。
 * @param {object} props
 * @param {object} workplaceDetails 事業場情報
 * @param {boolean} isOpenWorkplaceEditDialog 事業場編集ダイアログが開いているかどうか
 * @param {boolean} isSubmitWorkplaceEditDialog 事業場編集ダイアログが送信中かどうか
 * @param {func} onOpenWorkplaceEditDialog 事業場編集ダイアログを開くイベント
 * @param {func} onCloseWorkplaceEditDialog 事業場編集ダイアログを閉じるイベント
 * @param {func} onSubmitUpdateWorkplace 事業場の更新を送信するイベント
 * @returns　JSX.Element
 */
export const Presententer = (props) => {
  const {
    value,
    loading,
    isOpenWorkplaceEditDialog,
    isSubmitWorkplaceEditDialog,
    onOpenWorkplaceEditDialog,
    onCloseWorkplaceEditDialog,
    onSubmitUpdateWorkplace,
    removeMutation,
    removeConfirmDialog,
  } = props;
  return (
    <div>
      <DetailsFrame
        caption={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                marginRight: 3,
                width: 58,
                height: 58,
              }}
            >
              <DomainOutlined sx={{ fontSize: "2.5rem" }} />
            </Avatar>
            <div>
              <Stack direction="row" spacing={1}>
                {props.isHeadOffice && <Mark text="本社" />}
                {value?.categories?.map((category, index) => (
                  <Mark
                    text={category.name}
                    backgroundColor={WorkplaceCategoryColorMap[category.id]}
                    key={index}
                  />
                ))}
              </Stack>
              <Typography
                variant="h5"
                component="div"
                sx={{ wordBreak: "break-all" }}
              >
                {props?.value?.name}
              </Typography>
            </div>
          </div>
        }
        actions={
          (props.value?.state ? props.value?.state === "active" : false) ? (
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="error"
                onClick={() => removeConfirmDialog.toggle(true)}
                disabled={!props.value || loading}
              >
                削除する
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onOpenWorkplaceEditDialog}
                disabled={!props.value || loading}
              >
                編集する
              </Button>
            </Stack>
          ) : (
            <></>
          )
        }
      >
        <TabsDetails
          tabs={[
            {
              name: "基本情報",
              tabContent: <TabSimpleInformation value={value} />,
            },
            // UNDONE: 12月リリースでは隠す
            // {
            //   name: "ギャラリー",
            //   tabContent: <TabGallery galleries={[]} />,
            // },
          ]}
        />
        <FullScreenDialog
          formId={"workplace-form"}
          open={isOpenWorkplaceEditDialog}
          title="事業場情報の編集"
          textConfirm="保存"
          onClose={onCloseWorkplaceEditDialog}
          isSubmit={isSubmitWorkplaceEditDialog}
          disabled={isSubmitWorkplaceEditDialog}
        >
          <WorkplaceForm
            id={"workplace-form"}
            onSubmit={onSubmitUpdateWorkplace}
            value={value}
          />
        </FullScreenDialog>
      </DetailsFrame>
      <ConfirmDialog
        title="削除確認"
        message="削除します。よろしいですか？"
        open={removeConfirmDialog.open}
        positiveText={"削除"}
        negativeText={"キャンセル"}
        onPositive={() => removeMutation({ input: { id: value.id } })}
        onNegative={() => removeConfirmDialog.toggle(false)}
      />
    </div>
  );
};
