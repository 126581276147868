import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { fetchWasteReportingMedia } from "./api";

/*
object shape
  []
    code
    name
*/

const wasteReportingMediaSlice = createSlice({
  name: "wasteReportingMedia",
  initialState: {
    wasteReportingMedia: null,
    isLoading: false,
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWasteReportingMedia.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchWasteReportingMedia.fulfilled, (state, action) => {
        state.wasteReportingMedia = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchWasteReportingMedia.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

/**
 * ストアから報告媒体全体を取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.WasteReportingMedia;

/**
 * ストアから報告媒体を取得します。
 */
export const wasteReportingMediaSelector = createSelector(
  selector,
  (wasteReportingMedia) => wasteReportingMedia.wasteReportingMedia
);

/**
 * ストアから読み込みを取得します。
 */
export const isLoadingSelector = createSelector(
  selector,
  (wasteReportingMedia) => wasteReportingMedia.isLoading
);

/**
 * ストアからエラーを取得します。
 */
export const errorSelector = createSelector(
  selector,
  (wasteReportingMedia) => wasteReportingMedia.error
);

export default wasteReportingMediaSlice.reducer;
