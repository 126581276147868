import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 処分方法を選択するコンポーネントです。
 */
export const DisposalMethodsSelector = containerPresententer(
  Container,
  Presententer
);
