import {
  Avatar,
  Box,
  Checkbox,
  Divider,
  Stack,
  Typography,
  Paper,
} from "@mui/material";

export const Group = ({
  index,
  value = [],
  onChange = () => {},
  title = "",
  options = [],
  disabled = false,
}) => {
  return (
    <Paper>
      <Stack>
        <Box>
          <Box display="flex" flexDirection="row">
            <Box display="flex" justifyContent={"center"} alignItems="center">
              <Avatar
                sx={{
                  backgroundColor: "black",
                  width: "34px",
                  height: "34px",
                  ml: 2,
                }}
              >
                {`${index + 1}`}
              </Avatar>
            </Box>
            <Box
              display="flex"
              justifyContent={"center"}
              alignItems="center"
              p={2}
            >
              <Typography variant="h6">{title ?? ""}</Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        {options?.map((option, index) => (
          <Box key={index}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="row">
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                  sx={{
                    width: "100px",
                  }}
                >
                  <Typography>{`${option?.order + 1}`}</Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                >
                  <Typography>{option?.name ?? ""}</Typography>
                </Box>
              </Box>
              <Box>
                <Checkbox checked={option?.checked} disabled={disabled} />
              </Box>
            </Box>
            <Divider />
          </Box>
        ))}
      </Stack>
    </Paper>
  );
};
