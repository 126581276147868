import { styles } from "./styles";
import { useState } from "react";
import PrivacyPolicyPDF from "resources/pdf/PrivacyPolicy.pdf";
import TermsServicePDF from "resources/pdf/TermsOfService.pdf";

/**
 * 認証ページのテンプレートを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const classes = styles();
  const [pdf, setPDF] = useState(null);

  const onClickPrivacyPolicy = () => {
    setPDF(PrivacyPolicyPDF);
  };

  const onTermsService = () => {
    setPDF(TermsServicePDF);
  };

  return render({
    classes: classes,
    pdf: pdf,
    onClose: () => setPDF(null),
    onClickPrivacyPolicy: onClickPrivacyPolicy,
    onTermsService: onTermsService,
    ...props,
  });
};
