import { Paper, Stack } from "@mui/material";
import { XGridComponent } from "views/molecules";
import { DetailsFrame } from "views/organisms/DetailsFrame";

/**
 * 整備記録簿を表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <>
      <DetailsFrame caption={<></>}>
        <Stack spacing={2}>
          <Paper
            sx={{
              p: 2,
            }}
          >
            <XGridComponent
              onChangeSelection={props.onSelected}
              checkboxSelection={false}
              multipleSelection={false}
              onDownload={true}
              onFilter={true}
              onColumns={true}
              {...props.obj.params}
            />
          </Paper>
        </Stack>
      </DetailsFrame>
    </>
  );
};
