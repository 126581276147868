export const drawerWidth = 260;

export const stylesCreator = (theme) => ({
  root: {
    display: "flex",
    "@media print": {
      width: 1280,
    },
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    overflowY: "hidden",
    width: drawerWidth,
  },
  drawerClose: {
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  toolbar_mobile: {
    justifyContent: "space-between",
  },
  toolbar_logo: {
    width: "130px",
    height: "100%",
    backgroundImage: `url(${window.location.origin}/logo.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    marginRight: 55,
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      backgroundPosition: "left",
    },
  },
});
