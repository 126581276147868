import { containerPresententer } from "utils/HoC";
import { Panel, TextField } from "./Child";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * アカウント管理を表示するコンポーネントです。
 * @param {boolean} open ダイヤログをオープンかオフにする
 * @param {func} onClose ダイヤログを閉じる
 * @param {object} value ユーザー情報
 */
export const AccountSetting = containerPresententer(Container, Presententer);
export { Panel, TextField };
