import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { fetchUser } from "./api";

/*
object shape
  createAt
  department
  email
  id
  name
  phone
  position
  tenantId
  updateAt
*/

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      createAt: "",
      department: "",
      email: "",
      id: "",
      name: "",
      phone: "",
      position: null,
      tenantId: "",
      updateAt: "",
    },
    isLoading: false,
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

/**
 * ストアからユーザー情報全体を取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.User;

/**
 * ストアから通知情報を取得します。
 */
export const userSelector = createSelector(selector, (user) => user.user);

/**
 * ストアから読み込みを取得します。
 */
export const isLoadingSelector = createSelector(
  selector,
  (user) => user.isLoading
);

/**
 * ストアからエラーを取得します。
 */
export const errorSelector = createSelector(selector, (user) => user.error);

export default userSlice.reducer;
