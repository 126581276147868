import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { DateTime } from "luxon";
import { updateCompany } from "api/graphql/mutations";
import { getCompany } from "api/graphql/queries";
import { debugLog } from "utils/log";
import { PhoneNumberFormatUtil } from "utils/format";
import { toggle } from "ducks/Loading";

/**
 * 事業者情報画面を表示するコンテナコンポーネントです。
 * @return {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const [value, setValue] = useState(null);
  const dispatch = useDispatch();

  const load = useCallback(() => {
    dispatch(toggle(true));
    API.graphql(graphqlOperation(getCompany))
      .then((result) => {
        debugLog("Corporation.onLoad.succeeded", result.data.getCompany);
        const { headOfficeWorkplace, ...other } = result.data.getCompany;
        setValue({
          ...other,
          headOffice: {
            ...headOfficeWorkplace,
            phone: headOfficeWorkplace?.phone
              ? PhoneNumberFormatUtil.formatNational(headOfficeWorkplace?.phone)
              : null,
            fax: headOfficeWorkplace?.fax
              ? PhoneNumberFormatUtil.formatNational(headOfficeWorkplace?.fax)
              : null,
          },
        });
      })
      .catch((error) => {
        debugLog("Corporation.onLoad.error: ", error);
      })
      .finally(() => {
        dispatch(toggle(false));
      });
  }, [dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const handleSubmitted = (result) => {
    debugLog("Corporation.onUpdate.succeeded", result.data.updateCompany);
    // UNDONE:APIの変更待ち。一時的にgetで取得しておく。
    //setValue(result.data.updateCompany);
    API.graphql(graphqlOperation(getCompany))
      .then((result2) => {
        const { headOffice, ...other } = result2.data.getCompany;
        const { phone, fax } = headOffice;
        setValue({
          ...other,
          headOfficeWorkplace: {
            ...headOffice,
            phone: phone ? PhoneNumberFormatUtil.formatNational(phone) : null,
            fax: fax ? PhoneNumberFormatUtil.formatNational(fax) : null,
          },
        });
      })
      .catch((err) => {});
  };

  const update = (data) => {
    const {
      establishmentedOn,
      headOffice,
      subscriberId,
      createdAt,
      updatedAt,
      version,
      profileVersion,
      categories,
      ...otherData
    } = data;
    return API.graphql(
      graphqlOperation(updateCompany, {
        input: {
          headOfficeWorkplaceId: headOffice?.id,
          establishmentedOn: establishmentedOn
            ? DateTime.fromJSDate(establishmentedOn).toFormat("yyyy-MM-dd")
            : null,
          expectedVersion: version,
          expectedProfileVersion: profileVersion,
          ...otherData,
        },
      })
    )
      .then((res) => {
        load();
        return res;
      })
      .catch((err) => err);
  };

  return render({
    value: value,
    submit: update,
    onSubmitted: handleSubmitted,
    ...props,
  });
};
