
export const styles = ((theme) => ({
  noData: {
    minHeight: theme.spacing(12),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box_list_cards: {
    overflowY: "auto",
    overflowX: "hidden",
    cursor: "pointer",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": " inset 0 0 6px rgba(0,0,0,0.3)",
      backgroundColor: " #F5F5F5",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:vertical ": {
      backgroundColor: "#555",
      borderRadius: "4px",
    },

    "&::-webkit-scrollbar-thumb:horizontal ": {
      display: "none",
    },
    "&::-webkit-scrollbar-thumb:vertical": {
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));
