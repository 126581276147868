import { makeStyles } from '@mui/styles';
import { overideStyleScrollbarHorizontal } from "utils/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    "& .MuiDataGrid-toolbarContainer": {
      padding: "0 !important",
      justifyContent: " space-between",
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
  },
  toolbar_container: {},
  header_left: {
    display: "flex",
    alignItems: "center",
  },
  header_search: {
    marginRight: theme.spacing(2),
    backgroundColor: "#fff",
    "& .MuiInputBase-root": {
      //minHeight: "59px",
      minWidth: "350px",
      "& .MuiInputBase-input": {
        fontSize: "20px",
        paddingTop: "10px",
        paddingBottom: "14.3px",
        boxSizing: "content-box",
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: "28px",
    },
  },
  header_button_filter: {
    "& .MuiButton-label": {
      letterSpacing: "-2px",
    },
  },
  header_button: {
    minWidth: "56px",
    minHeight: "56px",
    padding: "3px 3px",
    marginRight: theme.spacing(1),
    borderColor: "rgba(0, 0, 0, 0.23)",
    backgroundColor: "#fff",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "9px",
    letterSpacing: "-2px",
    color: theme.palette.text.secondary,
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    "&:last-child": {
      marginRight: 0,
    },
    "& > span:first-child": {
      margin: 0,
      "& >svg": {
        fontSize: "28px",
        color: theme.palette.text.disabled,
        marginBottom: "-5px",
      },
      "& >span": {
        margin: 0,
        color: theme.palette.text.disabled,
        "& >svg": {
          fontSize: "28px",
          marginBottom: "-5px",
        },
      },
    },
  },
  content_table: {
    "&.MuiDataGrid-root": {
      border: "none",
      // cursor: "pointer"
    },

    "& .MuiDataGrid-main": {
      border: "1px solid rgba(224, 224, 224, 1)",
      borderBottom: 0,
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      backgroundColor: "#fff",
      "& .MuiDataGrid-columnHeaderWrapper": {
        color: theme.palette.text.secondary,
      },
      "& .MuiDataGrid-window": {
        ...overideStyleScrollbarHorizontal(theme),
      },
    },

    "& .MuiDataGrid-footerContainer": {
      border: "1px solid rgba(224, 224, 224, 1)",
      borderTop: "0",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
      color: theme.palette.text.secondary,
      minHeight: theme.spacing(6),
      backgroundColor: "#fff",
      "& .MuiTablePagination-root": {
        color: theme.palette.text.secondary,
        "& .MuiTablePagination-toolbar": {
          minHeight: theme.spacing(6),
        },
      },
    },
  },
  hidenCheckBoxHeader: {
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        display: "none",
      },
  },
  footer_button: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
    "& button": {
      marginLeft: theme.spacing(1),
    },
  },

  noRows_root: {
    flexDirection: "column",
  },
  noRows_label: {
    //marginTop: theme.spacing(1),
  },
  onCursor: {
    cursor: "pointer",
  },
}));
