import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    border: "1px solid #bdbdbd",
    borderRadius: "4px",
    overflow: "auto",
  },
  progress: { padding: "30px", textAlign: "center" },
}));
