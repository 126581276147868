import { DomainOutlined } from "@mui/icons-material";
import { Avatar, Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { DateTime } from "luxon";
import React from "react";
import { FullScreenDialog } from "views/molecules/FullScreenDialog";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { Caption } from "views/organisms/DetailsFrame/ChildComponents";
import { SpotDetails } from "views/organisms/Order/SpotDetails";
import { SpotForm } from "views/organisms/Order/SpotForm";

const Form = React.forwardRef((props, ref) => (
  <SpotForm {...props} _ref={requestAnimationFrame} />
));

/**
 * スポット回収詳細情報を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  classes,
  value,
  open,
  onCloseDialog,
  onOpenDialog,
  onSubmit,
  onRegister,
  formRef,
  isSubmit,
  onDelete,
}) => {
  return (
    <div>
      <DetailsFrame
        caption={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Avatar
              sx={{
                marginRight: 3,
              }}
            >
              <DomainOutlined />
            </Avatar>
            <div>
              <Caption
                title={`${value?.wasteGeneratorCompany?.name ?? ""}${
                  value?.wasteCollectionWorkplace?.name ?? ""
                }`}
                subTitle={`〒${
                  value?.wasteCollectionWorkplace?.postalCode ?? ""
                } ${value?.wasteCollectionWorkplace?.prefectures?.name ?? ""}${
                  value?.wasteCollectionWorkplace?.city ?? ""
                }${value?.wasteCollectionWorkplace?.streetAddress ?? ""}${
                  value?.wasteCollectionWorkplace?.otherAddress ?? ""
                }`}
              />
              <Typography
                variant="subtitle2"
                sx={{
                  color: grey[500],
                  textDecoration: "underline",
                }}
              >{`${
                value?.scheduleDate
                  ? DateTime.fromISO(value.scheduleDate).toFormat("yyyy/MM/dd")
                  : ""
              } ${
                value?.scheduleTimeRangeStart
                  ? value?.scheduleTimeRangeStart.substring(0, 5)
                  : ""
              } ~ ${
                value?.scheduleTimeRangeEnd
                  ? value?.scheduleTimeRangeEnd.substring(0, 5)
                  : ""
              }`}</Typography>
            </div>
          </div>
        }
        actions={
          // <Button
          //   variant="contained"
          //   color="primary"
          //   onClick={onOpenDialog}
          // >
          //   {"スポット回収を編集する"}
          // </Button>
          <Button
            variant="contained"
            color="error"
            onClick={onDelete}
            disabled={!value}
          >
            {"スポット回収を削除する"}
          </Button>
        }
      >
        <div className={classes.content}>
          <SpotDetails value={value} />
        </div>
        <FullScreenDialog
          open={open}
          onClose={onCloseDialog}
          title={"スポット回収を編集"}
          textConfirm={"編集"}
          onClickSubmit={onRegister}
          isSubmit={isSubmit}
          disabled={isSubmit}
        >
          <Form value={value} onSubmit={onSubmit} _ref={formRef} />
        </FullScreenDialog>
      </DetailsFrame>
    </div>
  );
};
