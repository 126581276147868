import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * サイドメニューを表示するコンポーネントです。
 * @param {object[]} props.routes - ルート情報配列
 * @param {boolean} props.open - 開閉状態
 * @param {boolean} props.exact
 * @param {JSX.Element} props.subheader
 */
export const SideMenu = containerPresententer(Container, Presententer);
