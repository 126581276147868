import { SinglePDF } from "views/atoms/SinglePDF";
import PrivacyPolicy from "resources/pdf/PrivacyPolicy.pdf";

/**
 * プライバシーポリシーを表示するコンポーネント。
 * @param {number} props.width 横幅
 * @returns {JSX.Element}
 */
export const PrivacyPolicyPDF = ({ width }) => {
  return <SinglePDF file={PrivacyPolicy} width={width} />;
};
