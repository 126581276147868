import yup, { remarksSchema, dateSchema, textSchema } from "utils/yup";

export const basicScheme = yup.object().shape({
  dischargesWorkplace: yup.object().required(),
  // transporter: yup.object().required(),
  date: dateSchema.required(),
  times: yup
    .object()
    .shape({
      start: textSchema.required(),
      end: textSchema.required(),
    })
    .required(),
  remark: remarksSchema,
  wastes: yup.array().required().min(1),
});
