import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import { getRegularlyWasteCollectionOrder } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { debugLog } from "utils/log";
import { toggle } from "ducks/Loading";
import { useDispatch } from "react-redux";

/**
 * 定期回収の詳細を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const { id } = useParams();
  const [value, setValue] = useState(null);
  const dispatch = useDispatch();

  const fetch = useCallback(() => {
    dispatch(toggle(true));
    API.graphql(graphqlOperation(getRegularlyWasteCollectionOrder, { id: id }))
      .then((res) => {
        setValue(res.data.getRegularlyWasteCollectionOrder);
      })
      .catch((err) => {
        debugLog("定期回収詳細", err);
      }).finally(() => {
        dispatch(toggle(false));
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetch();
  }, [fetch, id]);

  const handleNotificationChange = async () => {
    await fetch();
  };

  return render({
    value: value,
    submit: (data) => Promise.resolve(data),
    onNotificationChange: handleNotificationChange,
    ...props,
  });
};
