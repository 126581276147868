import { useParams } from "react-router-dom";
import { useQuery } from "utils/useQuery";
import { getContainer, searchContainersByOffset } from "api/graphql/queries";
import { useSelector } from "react-redux";
import { companySelector } from "ducks/Company";
import { useEffect, useState } from "react";
import { toFormValue } from "utils/ImageFunctions";

/**
 * コンテナ管理の詳細を表示するコンテナコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const { id } = useParams();
  const [value, setValue] = useState(null);
  useEffect(() => {
    setValue(null);
  }, []);
  const company = useSelector(companySelector);
  const details = useQuery({
    query: getContainer,
    variables: {
      id: id,
    },
    onCompleted: ({ data }) => {
      toFormValue({
        files: data?.getContainer?.files ?? [],
      }).then((files) => {
        setValue({
          ...data?.getContainer,
          files: files,
        });
      });
    },
  });

  const containers = useQuery({
    query: searchContainersByOffset,
    variables: {
      ownerCompanyId: company?.id,
      filter: {
        and: [{ id: { eq: id } }],
      },
    },
  });

  const handleNotification = () => {
    details?.refetch({
      id: id,
    });
  };

  return render({
    ...props,
    value: value,
    details: containers?.data?.searchContainersByOffset?.items?.[0],
    loading:
      details?.loading || containers?.data?.searchContainersByOffset?.loading,
    onNotification: handleNotification,
  });
};
