import ClearIcon from "@mui/icons-material/Clear";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Box,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Draggable } from "react-beautiful-dnd";

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  ...(isDragging && {
    background: "rgb(235,235,235)",
  }),
});

/**
 * グループのアイテムを入力・表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {integer} props.number 番号
 * @param {string} props.title チェック内容
 * @param {boolean} props.disabled 無効設定
 * @param {boolean} props.error エラーであるか
 * @param {string} props.helperText 補助的テキスト
 * @fires Item#onChange 変更時
 * @fires Item#onDelete 削除時
 * @returns {JSX.Element}
 */
export const Item = ({
  id,
  number = 1,
  value = null,
  onChange = () => {},
  onDelete = () => {},
  disabled = false,
  readOnly = false,
  error = false,
  helperText = "",
}) => {
  const handleChange = (event) => {
    onChange({ name: event.target.value });
  };

  return (
    <Draggable
      key={number}
      draggableId={`${id}-${number}`}
      index={number - 1}
      isDragDisabled={disabled || readOnly}
      type={`${number - 1}`}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <ListItem
            component={"div"}
            disablePadding
            secondaryAction={
              <>
                {readOnly === true ? (
                  <></>
                ) : (
                  <IconButton
                    edge="end"
                    aria-label="clear"
                    onClick={onDelete}
                    disabled={disabled}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </>
            }
          >
            {readOnly === false && (
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <DragIndicatorIcon />
              </ListItemIcon>
            )}
            <ListItemText
              primary={
                <Stack spacing={2} direction="row" p={2} alignItems="center">
                  <Box pr={3}>
                    <Typography>{number}</Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    {readOnly === false && (
                      <TextField
                        value={value?.name ?? ""}
                        fullWidth
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CreateOutlinedIcon />
                            </InputAdornment>
                          ),
                          disableUnderline: true,
                        }}
                        variant="standard"
                        placeholder="ここにチェック内容を入力します。"
                        error={error}
                        helperText={helperText}
                        disabled={disabled}
                      />
                    )}
                    {readOnly === true && (
                      <Typography>{value?.name}</Typography>
                    )}
                  </Box>
                </Stack>
              }
            />
          </ListItem>
        </div>
      )}
    </Draggable>
  );
};
