import reducer from "./slice";
import {
  selector,
  notificationsSelector,
  isLoadingSelector,
  errorSelector,
  subscribe
} from "./slice";
import { fetchSystemAlert, markAsReadSystemAlert } from "./api";

export default reducer;
export {
  subscribe,
  selector,
  notificationsSelector,
  isLoadingSelector,
  errorSelector,
  fetchSystemAlert,
  markAsReadSystemAlert,
};
