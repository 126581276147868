import React from "react";
import { XGridComponent } from "views/molecules";
import { Button } from "@mui/material";
import { Form } from "views/organisms/Slip/PaperManifest";

/**
 * 紙マニフェストの一覧を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <>
    <XGridComponent
      onChangeSelection={props.onClick}
      checkboxSelection={false}
      multipleSelection={false}
      onDownload={true}
      titleTable={""}
      rows={props.rows}
      loading={props.isLoading}
      actionButton={
        <Button variant="contained" color="primary" onClick={props.onOpen}>
          {"新しい紙マニフェストを登録"}
        </Button>
      }
      {...props.xGridParams}
    />
    <Form open={props.open} onClose={props.onClose} onSubmit={props.onSubmit} />
  </>
);
