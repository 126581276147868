import { makeStyles } from '@mui/styles';

export const styles = makeStyles((theme) => ({
  form_addNew: {
    padding: theme.spacing(5),
  },
  corporationNumber: {
    width: 170,
  },
}));
