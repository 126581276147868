import { updateVehicle } from "api/graphql/mutations";
import { getVehicle } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { companySelector } from "ducks/Company";
import { toggle } from "ducks/Loading";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { useOpener } from "utils/useOpener";
import { useStyles } from "./styles";

/**
 * 車輛情報の詳細ページを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @param {func} submit 更新処理
 * @fires Container#onSubmitted 送信処理後
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const classes = useClasses(useStyles);
  const { id } = useParams();
  const company = useSelector(companySelector);
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const dispatch = useDispatch();
  const confirm = useOpener();

  const loadVehicle = useCallback(() => {
    dispatch(toggle(true));
    if (company) {
      API.graphql(
        graphqlOperation(getVehicle, {
          companyId: company.id,
          id: id,
        })
      )
        .then((result) => {
          debugLog("vehicle.onLoad.succeeded", result);
          setVehicle({
            ...result.data.getVehicle,
          });
        })
        .catch((error) => {
          debugLog("vehicle.onLoad.failed", error);
        })
        .finally(() => {
          dispatch(toggle(false));
        });
    }
  }, [dispatch, company, id]);

  useEffect(() => {
    loadVehicle();
  }, [loadVehicle]);

  const handleSubmit = (data) => {
    debugLog("vehicle.onUpdate.submit", data);
    const {
      id,
      number,
      parkingCertificateOwnerWorkplace,
      modelName,
      purchasedOn,
      firstYearRegistrationOn,
      loadCapacity,
      totalWeight,
      ridingCapacity,
      remarks,
      version,
    } = data;
    setIsSubmit(true);
    API.graphql(
      graphqlOperation(updateVehicle, {
        input: {
          id: id,
          number: number,
          modelName: modelName,
          parkingCertificateOwnerWorkplaceId:
            parkingCertificateOwnerWorkplace?.id ?? null,
          purchasedOn:
            purchasedOn &&
            DateTime.fromJSDate(new Date(purchasedOn)).toFormat("yyyy-MM-dd"),
          firstYearRegistrationOn:
            firstYearRegistrationOn &&
            DateTime.fromJSDate(new Date(firstYearRegistrationOn)).toFormat(
              "yyyy-MM-dd"
            ),
          loadCapacity: loadCapacity,
          totalWeight: totalWeight,
          ridingCapacity: ridingCapacity,
          remarks: remarks,
          expectedVersion: version,
        },
      })
    )
      .then((result) => {
        debugLog("vehicle.onUpdate.succeeded", result);
        loadVehicle();
        dispatch(
          addAlert({
            value: "保存しました。",
            severity: "success",
          })
        );
        setOpen(false);
      })
      .catch((error) => {
        debugLog("vehicle.onUpdate.failed", error);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return render({
    ...props,
    classes: classes,
    value: vehicle,
    open: open,
    isSubmit: isSubmit || props.loading,
    onOpenDialog: () => setOpen(true),
    onCloseDialog: () => setOpen(false),
    onSubmit: handleSubmit,
    company: company,
    confirm: confirm,
  });
};
