import { CloudUploadOutlined } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

const UploadButton = ({ title = "ファイルを選択", getInputProps }) => {
  return (
    <>
      <input {...getInputProps()} />
      <Button variant="contained" component="span">
        {title}
      </Button>
    </>
  );
};

/**
 * ファイルアップローダーを表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <FormControl fullWidth={props.fullWidth} sx={props.sx}>
      <FormLabel
        required={props.required}
        sx={{
          fontSize: "14px",
          marginBottom: "8px",
        }}
      >
        {props.label}
      </FormLabel>
      <div
        style={{
          border: `1px solid ${props.error ? "#f44336" : "darkgrey"}`,
          display: "flex",
          justifyContent: "center",
          borderRadius: "4px",
          padding: "16px",
        }}
        {...props.getRootProps()}
      >
        <Stack spacing={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              color: "darkgrey",
            }}
          >
            <CloudUploadOutlined
              sx={(theme) => ({
                fontSize: "4rem",
                color: theme.palette.text.secondary,
              })}
            />
          </div>
          <Typography sx={{ color: "darkgrey" }}>
            {props.dropzoneText}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              color: "darkgrey",
            }}
          >
            <UploadButton
              title={props.buttonTitle}
              getInputProps={props.getInputProps}
            />
          </div>
        </Stack>
      </div>
      <FormHelperText required={props.required} error={props.error}>
        {props.helperText}
      </FormHelperText>
      {props.message?.map?.((item, index) => (
        <FormHelperText key={index} error={true}>
          {item}
        </FormHelperText>
      )) ?? <></>}
    </FormControl>
  );
};
