import { searchVehiclePeriodicInspectionsByOffset } from "api/graphql/queries";
import { companySelector } from "ducks/Company";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { formatDisplayDate } from "utils/format";
import { useOpener } from "utils/useOpener";
import { useXGridComponents } from "utils/useXGridComponents";
import { useQuery } from "utils/useQuery";
import { getVehiclePeriodicInspection } from "api/graphql/queries";
import { useNavigate, useLocation } from "react-router-dom";
import { toQueryString, fromQueryString } from "utils/useXGridComponents";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

const columns = [
  {
    field: "checkedOn",
    headerName: "点検日",
    minWidth: 100,
    valueFormatter: (params) => {
      return formatDisplayDate({ source: params.value });
    },
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "vehicleModelName",
    headerName: "車種",
    flex: 1,
  },
  {
    field: "vehicleNumber",
    headerName: "車両ナンバー",
    flex: 1,
  },
  {
    field: "personInChargeName",
    headerName: "点検者",
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "checksheetName",
    headerName: "点検の名称",
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "numberOfChecks",
    headerName: "チェック数",
    flex: 1,
    renderCell: (params) => {
      return `${params.row.numberOfChecks} / ${params.row.totalOfItems}`;
    },
    filterable: false,
  },
  {
    field: "updatedAt",
    headerName: "最終更新日時",
    minWidth: 170,
    valueFormatter: (params) => {
      return params.value
        ? DateTime.fromISO(params.value, { setZone: "Asia/Tokyo" }).toFormat(
            "yyyy/MM/dd HH:mm:ss"
          )
        : "";
    },
    filterable: false,
  },
];

/**
 * 定期点検一覧を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dialog = useOpener();
  const company = useSelector(companySelector);
  const xGridObject = useXGridComponents(
    columns,
    searchVehiclePeriodicInspectionsByOffset,
    {
      other: {
        ownerCompanyId: company?.id,
      },
    },
    {
      sort: {
        direction: "desc",
        field: "checkedOn",
      },
      ...fromQueryString(location.search),
      filter: {
        defaultColumnField: get(columns, "[2].field"),
      },
    }
  );
  const vehiclePeriodicInspection = useQuery({
    query: getVehiclePeriodicInspection,
    variables: null,
    onCompleted: (data) => {},
    skip: true,
  });

  const onSelected = (params) => {
    navigate(
      {
        pathname: location.pathname,
        search: toQueryString(xGridObject.search),
      },
      {
        repalce: true,
      }
    );

    navigate(`${location.pathname}/${params[0]}`);
  };

  return render({
    ...props,
    dialog: dialog,
    xGridParams: xGridObject.params,
    onSelected: onSelected,
    loading: vehiclePeriodicInspection.loading,
    value: vehiclePeriodicInspection?.data?.getVehiclePeriodicInspection,
  });
};
