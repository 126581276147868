import { confirmContainerLocation } from "api/graphql/mutations";
import { formatJSDateToISODate } from "utils/format";
import { useMutation } from "utils/useMutation";
import { useOpener } from "utils/useOpener";

/**
 * コンテナ管理の詳細を表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  value,
  details,
  onNotification = () => {},
  loading,
  ...props
}) => {
  const confirmDialog = useOpener();

  const [confirmMutation, confirmMutationStatus] = useMutation(
    confirmContainerLocation,
    {
      onCompleted: (data) => {
        onNotification();
        confirmDialog.toggle(false);
      },
      succeedMessage: "登録しました。",
      errorMessage: "エラーが発生したため、登録できませんでした。",
    }
  );

  const handleUpdateLastConfirm = async (data) => {
    const { confirmedOn, personInChargeName } = data;

    confirmMutation({
      input: {
        containerId: details.id,
        installationLocationCategory: details.installationLocationCategory,
        installationWorkplaceId: details.installationWorkplaceId,
        confirmedOn: formatJSDateToISODate(confirmedOn),
        personInChargeName: personInChargeName,
        remarks: null, // todo: 備考の項目が追加されたら突っ込む
      },
    });
  };

  return render({
    ...props,
    value: value,
    details: details,
    onUpdateLastConfirm: handleUpdateLastConfirm,
    confirmDialog: confirmDialog,
    loading: confirmMutationStatus.loading || loading,
  });
};
