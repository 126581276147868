import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 車輛点検を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} value 値
 * @returns {JSX.Element}
 */
export const Inspection = containerPresententer(Container, Presententer);
