import { Container, Grid, Typography } from "@mui/material";

export const Presententer = () => {
  return (
    <Container>
      <Grid container alignItems="center" justify="center" spacing={3}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h1" color="error">
            404
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h1" variant="h3" color="inherit">
            {"ページが見つかりませんでした"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color="inherit">
            {
              "お探しのページは見つかりませんでした。一時的にアクセスできない状況にあるか、移動または削除された可能性があります。お手数ですが、メニューから他のページをご覧ください。"
            }
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
