import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { fetchRoles } from "./api";

/*
object shape
  []
    id
    name
    description
*/

const rolesSlice = createSlice({
  name: "roles",
  initialState: {
    roles: null,
    isLoading: false,
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.roles = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

/**
 * ストアから権限全体を取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.Roles;

/**
 * ストアから権限を取得します。
 */
export const rolesSelector = createSelector(selector, (roles) => roles.roles);

/**
 * ストアから読み込みを取得します。
 */
export const isLoadingSelector = createSelector(
  selector,
  (roles) => roles.isLoading
);

/**
 * ストアからエラーを取得します。
 */
export const errorSelector = createSelector(selector, (roles) => roles.error);

export default rolesSlice.reducer;
