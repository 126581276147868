import { useState } from "react";
import { debugLog } from "utils/log";
import { createJwnetMember } from "api/graphql/mutations";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { DateTime } from "luxon";
import { add as addAlert } from "ducks/Alert";
import { useDispatch } from "react-redux";

/**
 * Jwnetの設定を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();

  const petition = (data) => {
    setIsSubmit(true);
    return API.graphql(
      graphqlOperation(createJwnetMember, {
        input: {
          number: data.number,
          ediUseConfirmationKey: data.usageConfirmationKey,
          publicConfirmationNumber: "",
          whenManifestChanged: "doNothing",
          whenManifestCanceled: "doNothing",
        },
        inquiryCriteria: {
          manifestExtractDay: data.extractClass.id,
          extractDateFrom: DateTime.fromJSDate(data.extractStartOn).toISODate(),
          extractDateTo: DateTime.fromJSDate(data.extractStartOn)
            .plus({ years: 1 })
            .toISODate(),
          isIncludingCancellation: true,
        },
      })
    )
      .then((res) => {
        debugLog("申請に成功: ", res);
        dispatch(
          addAlert({
            value: "申請しました。",
            severity: "success",
          })
        );
      })
      .catch((err) => {
        debugLog("申請に失敗: ", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、申請できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return render({
    isSubmit: isSubmit,
    petition: petition,
    ...props,
  });
};
