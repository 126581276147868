import React from "react";
import { Button, CircularProgress, Link, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { AuthenticationTemplate } from "views/organisms";
import { InputField } from "views/molecules";

/**
 * 確認コードを入力する画面を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <AuthenticationTemplate>
    <form className={props.classes.form} noValidate onSubmit={props.submit}>
      <Controller
        name="code"
        rules={{ required: "確認コードを入力してください" }}
        control={props.control}
        render={({
          field: { value, onChange, ref },
          fieldState: { error },
        }) => (
          <InputField
            value={value}
            onChange={onChange}
            label="確認コード"
            error={Boolean(error?.message)}
            helperText={error?.message}
            inputRef={ref}
          />
        )}
      />
      <Button
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        className={props.classes.button}
        disabled={props.loading || props.locked}
        fullWidth
      >
        {props.loading ? <CircularProgress size={24} /> : "確認"}
      </Button>
      {props.error && (
        <Typography color="error" paragraph>
          {props.error}
        </Typography>
      )}
      <Link variant="body2" component="button" onClick={props.onTransiteLogin}>
        ログインに戻る
      </Link>
    </form>
  </AuthenticationTemplate>
);
