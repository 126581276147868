import {
  ReceiptLong,
  FactCheck,
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import React from "react";
import { RenderMenuSelectItem } from "views/atoms";

/**
 * 伝票入力のメニュー選択を行う画面を表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: 0 }}>
        <Grid item>
          <RenderMenuSelectItem
            icon={ReceiptLong}
            label="紙マニフェスト"
            onClick={() => props.onClick("paper")}
          />
        </Grid>
        <Grid item>
          <RenderMenuSelectItem
            icon={FactCheck}
            label="電子マニフェスト"
            onClick={() => props.onClick("electronic")}
          />
        </Grid>
      </Grid>
    </>
  );
};
