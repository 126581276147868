import { useStyles } from "./styles";

/**
 * ナビモードの詳細を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({ render, value, ...props }) => {
  const classes = useStyles();

  return render({
    classes: classes,
    value: value,
    ...props,
  });
};
