import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 回収詳細フォームを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 回収詳細
 * @param {func} props.onClose ダイアログを閉じる関数
 * @param {func} props.onSubmit 回収詳細を送信する関数
 * @param {func} props.onError エラー時のコールバック
 * @param {number} props.number 回収詳細の番号
 * @param {boolean} props.loading 通信中かどうか
 */
export const CollectDetailsForm = containerPresententer(
  Container,
  Presententer
);
