import PropTypes from "prop-types";
/**
 * マークを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string} title Tooltip title
 * @param {JSX.Element} children what to display on the tooltip
 * @param {string} size size of tooltip button
 * @param {string} variant button variant
 * @param {string} color button color
 * @param {object} sx button styole
 * @param {string} placement tooltip placement
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  title,
  size,
  variant,
  color,
  sx,
  children,
  placement,
}) => {
  return render({
    title,
    children,
    size,
    variant,
    color,
    sx,
    placement,
  });
};

Container.propTypes = {
  render: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  sx: PropTypes.object,
  placement: PropTypes.string,
};
