import { forwardRef } from "react";
import { If } from "views/atoms";
import {
  Button,
  Chip,
  Container,
  Stack,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BarChartIcon from "@mui/icons-material/BarChart";
import CalculateIcon from "@mui/icons-material/Calculate";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { FullScreenDialog, XGridComponent } from "views/molecules";
import { Form as TemplateForm } from "./Components/Form";
import TableChartIcon from "@mui/icons-material/TableChart";
import CsvExporter from "views/organisms/CsvExporter";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import LoadingButton from "@mui/lab/LoadingButton";

const Form = forwardRef((props, ref) => {
  return <TemplateForm {...props} _ref={ref} />;
});

const ChangeGraphButton = (props) => {
  return (
    <Button
      onClick={props.onClick}
      variant="outlined"
      sx={{
        minWidth: "56px",
        minHeight: "56px",
        borderColor: "darkgrey",
        color: "darkgrey",
        padding: "3px 3px",
        letterSpacing: "-2px",
      }}
    >
      <span
        style={{
          display: "flex",
          fontSize: "9px",
          flexDirection: "column",
        }}
      >
        <span style={{ marginBottom: -8 }}>
          <BarChartIcon />
        </span>
        {"グラフ表示"}
      </span>
    </Button>
  );
};

const ChangeTextButton = (props) => {
  return (
    <Button
      onClick={props.onClick}
      variant="outlined"
      sx={{
        minWidth: "56px",
        minHeight: "56px",
        borderColor: "darkgrey",
        color: "darkgrey",
        padding: "3px 3px",
        letterSpacing: "-2px",
      }}
    >
      <span
        style={{
          display: "flex",
          fontSize: "9px",
          flexDirection: "column",
        }}
      >
        <span style={{ marginBottom: -8 }}>
          <TableChartIcon />
        </span>
        {"テキスト表示"}
      </span>
    </Button>
  );
};

export const Presententer = (props) => {
  return (
    <>
      <Container disableGutters sx={{ pt: 2 }} maxWidth={false}>
        <Paper
          elevation={3}
          sx={{
            p: 5,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <div>
              {props.config && (
                <Typography variant="h5">
                  {props.modeList.find((i) => i.id === props.config.mode)?.name}
                </Typography>
              )}
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={props.onOpenDialog}
              disabled={!!props.isLoading}
            >
              集計条件を設定する
            </Button>
          </Stack>
          {props.config && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ mb: 2 }}
            >
              <Chip
                icon={<DateRangeIcon />}
                label={`${props.config.aggregateOn.start.toLocaleDateString()}～${props.config.aggregateOn.end.toLocaleDateString()}`}
              />
              <Chip
                icon={<CalculateIcon />}
                label={`集計方法 : ${
                  props.methodList.find((i) => i.id === props.config.method)
                    ?.name
                }`}
              />
              <Chip
                icon={<BarChartIcon />}
                label={`集計値 : ${
                  props.datasetList.find((i) => i.id === props.config.dataset)
                    ?.name
                }`}
              />
              <Chip
                icon={<AccessTimeIcon />}
                label={`集計対象日 : ${
                  props.dateTypeList.find((i) => i.id === props.config.dateType)
                    ?.name
                }`}
              />
            </Stack>
          )}

          {!props.isLoading && !props.config && (
            <Paper elevation={1}>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  height: 400,
                }}
              >
                <Typography variant="h5">
                  集計条件が設定されていません。
                </Typography>
              </Stack>
            </Paper>
          )}
          {!props.isLoading && props.config && (
            /* ここに分析データを表示 */
            <Box position={"relative"}>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  zIndex: 1,
                }}
              >
                <If condition={!props.graphMode && props.total > 0}>
                  <CsvExporter
                    filename={props.getFileName()}
                    fetchData={props.handleDownloadAll}
                    columns={props.dataTable.columns}
                  >
                    <LoadingButton
                      variant="outlined"
                      sx={{
                        height: 35,
                      }}
                      endIcon={<FileDownloadOutlinedIcon color="inherit" />}
                    >
                      エクスポート
                    </LoadingButton>
                  </CsvExporter>
                </If>
              </Box>
              <XGridComponent
                rowHeight={props.graphMode ? 150 : undefined}
                checkboxSelection={false}
                multipleSelection={false}
                paginationMode={"server"}
                onSearchByText={props.onSearch}
                onSearcher={true}
                pagination
                sortingMode="server"
                rowCount={props.rowCount}
                onPageSizeChange={props.onPageSizeChange}
                onPageChange={props.onPageChange}
                loading={props.isSubmit}
                page={props.page}
                pageSize={props.pageSize}
                onFilterModelChange={props.onFilterModelChange}
                apiRef={props.gridApiRef}
                {...props.dataTable}
                customTools={
                  <>
                    {props.graphMode && (
                      <ChangeTextButton
                        onClick={() => props.onChangeMode("text")}
                      />
                    )}
                    {!props.graphMode && (
                      <ChangeGraphButton
                        onClick={() => props.onChangeMode("graph")}
                      />
                    )}
                  </>
                }
              />
            </Box>
          )}
        </Paper>
      </Container>
      <FullScreenDialog
        open={props.open}
        title="集計条件を設定"
        textConfirm="設定"
        onClose={props.onCloseDialog}
        onClickSubmit={props.onRegister}
        isSubmit={props.isSubmit}
        disabled={props.isSubmit}
      >
        <Container maxWidth="md">
          <Paper
            elevation={3}
            sx={{
              p: 5,
            }}
          >
            <Form
              ref={props.formRef}
              onSubmit={props.onSubmit}
              value={props.config}
              datasetList={props.datasetList}
              dateTypeList={props.dateTypeList}
              methodList={props.methodList}
              modeList={props.modeList}
            />
          </Paper>
        </Container>
      </FullScreenDialog>
    </>
  );
};
