import React from "react";
import { InformationItem } from "views/atoms";

/**
 * 基本情報のタブの表示を行うプレゼンテーションコンポーネントです
 * @param {object} classes クラス名
 * @param {object} value 事業場情報
 */
export const Presententer = (props) => {
  const { value } = props;
  return (
    <div>
      <InformationItem label="管理番号">{value?.number ?? ""}</InformationItem>
      <InformationItem label="廃棄物の種類">
        {value?.kind
          ? `${value?.kind}(${
              value?.type?.smallClass?.name ||
              value?.type?.middleClass?.name ||
              value?.type?.largeClass?.name ||
              value?.type?.group?.name ||
              ""
            })`
          : value?.type?.smallClass?.name ||
            value?.type?.middleClass?.name ||
            value?.type?.largeClass?.name ||
            value?.type?.group?.name ||
            ""}
      </InformationItem>
      <InformationItem label="廃棄物の詳細種類">
        {value?.kind || ""}
      </InformationItem>
      <InformationItem label="廃棄物の名称">
        {value?.name ?? ""}
      </InformationItem>
      <InformationItem label="廃棄物の荷姿">
        {value?.packing?.name ?? ""}
      </InformationItem>
      <InformationItem label="廃棄物の荷姿詳細">
        {value?.packingDetails ?? ""}
      </InformationItem>
      <InformationItem label="廃棄物の数量入力単位">
        {`${value?.quantityUnit?.name || ""}${
          value?.weightConvertedValue
            ? value?.quantityUnit?.name !== "kg" &&
              value?.quantityUnit?.name !== "t"
              ? `（重量換算係数 ${value?.weightConvertedValue}kg/${
                  value?.weightConvertedValueUnit?.name ||
                  value?.quantityUnit?.name
                }）`
              : ""
            : ""
        }`}
      </InformationItem>
      <InformationItem label="排出頻度">
        {value?.dischargeFrequency
          ? `${value?.dischargeFrequency.interval.name} ${value?.dischargeFrequency?.numberOfTimes}回`
          : ""}
      </InformationItem>
      <InformationItem label="一回の排出量">
        {value?.dischargeAmountPerOneTime
          ? `${value?.dischargeAmountPerOneTime ?? ""}${
              value?.quantityUnit?.name ?? ""
            }`
          : ""}
      </InformationItem>
      <InformationItem label="輸入廃棄物">
        {value?.isImportedWaste ? "輸入廃棄物である" : "輸入廃棄物ではない"}
      </InformationItem>
    </div>
  );
};
