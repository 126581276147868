import { API, graphqlOperation } from "utils/graphqlOperation";
import { useCallback, useEffect, useReducer } from "react";
import { debugLog } from "./log";

const InitialState = {
  data: [],
  loading: false,
  error: null,
  hasNext: true,
  page: 0,
};

const ActionTypes = {
  init: "useShowMore/init",
  beforeFetch: "useShowMore/beforeFetch",
  succeededFetch: "useShowMore/succeededFetch",
  failedFetch: "useShowMore/failedFetch",
  afterFetch: "useShowMore/afterFetch",
  next: "useShowMore/next",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.init:
      return InitialState;
    case ActionTypes.beforeFetch:
      debugLog("========================================");
      debugLog(" - クエリの実行：");
      debugLog("   - クエリ：", action.payload.query);
      debugLog("   - 引数：", action.payload.variables);
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.succeededFetch:
      debugLog(" - クエリの成功：", action.payload.items);
      return {
        ...state,
        data: state?.data?.concat(action.payload.items) ?? [],
        hasNext:
          (action?.payload?.total ?? 0) >
          (state?.data?.length ?? 0) + (action?.payload?.items?.length ?? 0),
      };
    case ActionTypes.failedFetch:
      debugLog(" - クエリの失敗：", action.payload.error);
      return {
        ...state,
        data: null,
        error: action.payload.error,
        hasNext: false,
      };
    case ActionTypes.afterFetch:
      debugLog(" - クエリ実行終了");
      debugLog("========================================");
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.next:
      return {
        ...state,
        page: state.page + 1,
      };
    default:
      return { ...state };
  }
};

/**
 * 「さらに表示する」処理を提供するフックです。
 * @param {object} props プロパティ
 * @param {string} props.query クエリ
 * @param {object} props.variables クエリの引数(offset, limitを除く)
 * @param {integer} props.loadCount アイテム読み込み数
 * @returns {array} data データ
 * @returns {boolean} loading 読み込み中であるか
 * @returns {object} error エラー
 * @returns {boolean} hasNext 次読み込めるか
 * @returns {function} onNext さらに表示する処理
 */
export const useShowMore = ({
  query = null,
  variables = null,
  loadCount = 5,
}) => {
  const [options, dispatch] = useReducer(reducer, InitialState);

  const fetchItems = (response) => {
    return response.data[Object.keys(response.data)[0]].items;
  };

  const fetchTotal = (response) => {
    return response.data[Object.keys(response.data)[0]].total ?? 0;
  };

  const runQuery = useCallback(
    ({ offset, limit }) => {
      if (!query || !variables) {
        return null;
      }

      return API.graphql(
        graphqlOperation(query, { ...variables, offset: offset, limit: limit })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query, JSON.stringify(variables)]
  );

  const fetch = () => {
    dispatch({
      type: ActionTypes.beforeFetch,
      payload: {
        query: query,
        variables: variables,
      },
    });

    runQuery({
      offset: loadCount * options.page,
      limit: loadCount,
    })
      ?.then((res) => {
        dispatch({
          type: ActionTypes.succeededFetch,
          payload: {
            items: fetchItems(res),
            total: fetchTotal(res),
          },
        });
      })
      ?.catch((err) => {
        dispatch({
          type: ActionTypes.failedFetch,
          payload: {
            error: err,
          },
        });
      })
      ?.finally(() => {
        dispatch({
          type: ActionTypes.afterFetch,
        });
      });
  };

  useEffect(() => {
    dispatch({ type: ActionTypes.init });
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runQuery]);

  useEffect(() => {
    if (options.page > 0) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.page]);

  const onNext = () => {
    dispatch({ type: ActionTypes.next });
  };

  return {
    onNext: onNext,
    ...options,
  };
};

/**
 * 「さらに表示する」処理を提供するフックの結果を返すコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.query クエリ
 * @param {object} props.variables クエリの引数(offset, limitを除く)
 * @param {integer} props.loadCount アイテム読み込み数
 * @returns {JSX.Element} JSX.Element
 */
export const ShowMore = ({ query, variables, loadCount = 5, children }) => {
  const showMoreParams = useShowMore({
    query: query,
    variables: variables,
    loadCount: loadCount,
  });

  return children({
    ...showMoreParams,
  });
};
