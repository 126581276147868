import React from "react";
import { Details } from "../../Details";

/**
 * 紙マニフェストの詳細入力するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Elemnt}
 */
export const Presententer = (props) => {
  return (
    <>
      <Details {...props} disabled={false} />
      {/* todo: 各セクションが押されたときのモーダルコンポーネントの配置 */}
    </>
  );
};
