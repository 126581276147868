import { containerPresententer, withForwardRef } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 時間の選択するコンポーネントです。
 */
export const TimePicker = withForwardRef(
  containerPresententer(Container, Presententer)
);
