import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 注意点情報を登録するフォームのコンポーネントです。
 */
export const PrecautionRegistrationForm = containerPresententer(
  Container,
  Presententer
);
