import {
  resumeUseContainer,
  removeContainer,
  stopUsingContainer,
  updateContainer as updateContainerApi,
} from "api/graphql/mutations";
import { useParams, useNavigate } from "react-router-dom";
import { toApiValue, toFormValue } from "utils/ImageFunctions";
import { useQuery } from "utils/useQuery";
import { useMutation } from "utils/useMutation";
import { DateTime } from "luxon";
import { getContainer } from "api/graphql/queries";
import { useState } from "react";

/**
 * コンテナ管理の詳細画面を表示するコンテナコンポーネントです。
 * @return {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const { id, productId } = useParams();
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState(null);

  const container = useQuery({
    query: getContainer,
    variables: {
      id: productId,
    },
    onCompleted: ({ data }) => {
      toFormValue({ files: data?.getContainer?.files ?? [] })
        .then((files) => {
          setFormValue({
            ...data?.getContainer,
            files: files,
          });
        })
        .finally(() => {});
    },
  });

  const [stopUsing, stopUsingStatus] = useMutation(stopUsingContainer, {
    onCompleted: (data) => {
      container?.refetch();
    },
    succeedMessage: "コンテナの使用を停止しました。",
    errorMessage:
      "エラーが発生したため、コンテナの使用を停止できませんでした。",
  });

  const [resumeUse, resumeUseStatus] = useMutation(resumeUseContainer, {
    onCompleted: (data) => {
      container?.refetch();
    },
    succeedMessage: "コンテナの使用を再開しました。",
    errorMessage:
      "エラーが発生したため、コンテナの使用を再開できませんでした。",
  });

  const [removeMutation, removeMutationStatus] = useMutation(removeContainer, {
    onCompleted: (data) => {
      navigate(
        {
          pathname: `/master/container/category/list/${id}`,
        },
        {
          replace: true,
        }
      );
    },
    succeedMessage: "削除しました。",
    errorMessage: "エラーが発生したため、削除できませんでした。",
  });

  const [updateContainer, updateContainerStatus] = useMutation(
    updateContainerApi,
    {
      onCompleted: (data) => {
        container?.refetch();
      },
      succeedMessage: "保存しました。",
      errorMessage: "エラーが発生したため、保存できませんでした。",
    }
  );

  const handleStopUsing = async (data) => {
    const { disuseType, disusedOn, remarks, files } = data;

    stopUsing({
      input: {
        id: productId,
        disuseTypeId: disuseType.id,
        disusedOn: DateTime.fromJSDate(new Date(disusedOn)).toFormat(
          "yyyy-MM-dd"
        ),
        remarks: remarks,
        files: await toApiValue({ files: files }),
      },
    });
  };

  const handleResumeUse = async (data) => {
    resumeUse({
      input: {
        id: productId,
      },
    });
  };

  const handleRemove = () => {
    removeMutation({
      input: {
        id: productId,
      },
    });
  };

  const handleUpdate = async (data) => {
    const {
      manageWorkplace,
      additionalTypes,
      addedOn,
      number,
      remarks,
      files,
      version,
    } = data;

    updateContainer({
      input: {
        id: productId,
        manageWorkplaceId: manageWorkplace.id,
        additionalTypeId: additionalTypes.id,
        addedOn: DateTime.fromJSDate(new Date(addedOn)).toFormat("yyyy-MM-dd"),
        number: number,
        remarks: remarks,
        files: await toApiValue({ files: files }),
        expectedVersion: version,
      },
    });
  };

  return render({
    ...props,
    value: container?.data?.getContainer,
    id: id,
    isSubmit:
      stopUsingStatus.loading ||
      resumeUseStatus.loading ||
      updateContainerStatus.loading ||
      container.loading ||
      removeMutationStatus.loading,
    onStopUsing: handleStopUsing,
    onResumeUse: handleResumeUse,
    onUpdate: handleUpdate,
    onRemove: handleRemove,
    formValue: formValue,
  });
};
