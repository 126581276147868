import { PhoneNumberFormatUtil } from "utils/format";
import { DateTime } from "luxon";
import { getCompany, getWasteFlow } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { debugLog } from "utils/log";

export const PaperManifestType = {
  code: 1,
  name: "事業系",
};

export const ManifestInfoType = {
  code: 2,
  name: "マニフェスト情報",
};

export const ManifestCardinalNumberType = {
  code: 1,
  name: "1次",
};

/**
 * オブジェクトから郵便番号・住所のテキストを取得します。
 * @param {object} value オブジェクト
 * @returns {String}
 */
export const getAddress = (value) => {
  if (value && value?.postalCode) {
    return `〒${value?.postalCode} ${value?.prefectures?.name}${value?.city}${value?.streetAddress}${value?.otherAddress}`;
  } else {
    return ``;
  }
};

/**
 * 電話番号を表示する形で取得します。
 * @param {String} value 電話番号
 * @returns {String}
 */
export const getPhone = (value) => {
  return PhoneNumberFormatUtil.formatNational(value) ?? "";
};

/**
 * 日付を表示する形で取得します。
 * @param {Date} value 日付
 * @returns {String}
 */
export const getDate = (value) => {
  if (!value) {
    return "";
  }

  if (DateTime.fromFormat(value, "yyyy-MM-dd").isValid) {
    return DateTime.fromFormat(value, "yyyy-MM-dd").toFormat("yyyy/MM/dd");
  } else if (DateTime.fromFormat(value, "yyyy/MM/dd").isValid) {
    return DateTime.fromFormat(value, "yyyy/MM/dd").toFormat("yyyy/MM/dd");
  } else {
    return DateTime.fromJSDate(value).toFormat("yyyy/MM/dd");
  }
};

/**
 * 企業情報を取得します。
 * @param {String} id 固有番号
 * @returns {object}
 */
const fetchCompany = (id) => {
  return API.graphql(
    graphqlOperation(getCompany, {
      id: id,
    })
  ).then((res) => {
    const result = res.data.getCompany;
    const { headOfficeWorkplace, ...other } = result;

    return {
      id: id,
      name: other?.name,
      phone: headOfficeWorkplace?.phone,
      postalCode: headOfficeWorkplace?.postalCode,
      prefectures: headOfficeWorkplace?.prefectures,
      city: headOfficeWorkplace?.city,
      streetAddress: headOfficeWorkplace?.streetAddress,
      otherAddress: headOfficeWorkplace?.otherAddress,
    };
  });
};

/**
 * 簡易フォームからフロー情報を取得します。
 * @param {object} data
 * @returns {object}
 */
const fetchWasteFlow = (data) => {
  return API.graphql(
    graphqlOperation(getWasteFlow, {
      id: data.flow.id,
    })
  ).then((res) => {
    return res.data.getWasteFlow;
  });
};

/**
 * 簡易フォームの排出事業場を詳細フォームの形に変換します。
 * @param {object} data フォーム値
 * @returns {object}
 */
const convertToDetailsOfDischargesWorkplace = (data) => {
  const { dischargesWorkplace } = data;
  const {
    name,
    phone,
    postalCode,
    prefectures,
    city,
    streetAddress,
    otherAddress,
  } = dischargesWorkplace;
  return {
    wasteGeneratorWorkplace: {
      name: name,
      phone: phone,
      postalCode: postalCode,
      prefectures: prefectures,
      city: city,
      streetAddress: streetAddress,
      otherAddress: otherAddress,
    },
  };
};

/**
 * 簡易フォームの廃棄物を詳細フォームの形に変換します。
 */
const convertToDetailsOfWaste = (data) => {
  const { flow, wastes, quantity, remarks } = data;
  const { name, packing, packingDetails, type } = wastes;
  const { disposalProcess } = flow;
  const { disposalMethod, disposalMethodDetails } = disposalProcess;

  return {
    waste: {
      name: name,
      types: [
        {
          wasteType: { ...type },
          wasteTypeName:
            type?.smallClass?.name ||
            type?.middleClass?.name ||
            type?.largeClass?.name ||
            "",
        },
      ],
      quantityValue: quantity?.value ?? null,
      quantityUnit: quantity?.unit ?? null,
      quantityUnitName: quantity?.unit?.name ?? "",
      packingName: packingDetails !== "" ? packingDetails : packing?.name ?? "",
      disposalMethodName:
        disposalMethodDetails !== ""
          ? disposalMethodDetails
          : disposalMethod?.name,
      hazardousSubstanceName: "なし",
      remarks: remarks,
    },
  };
};

/**
 * 処理フローから運搬受託者を引き出し、詳細フォームの形に変換します。
 * @param {object} wasteFlow 処理フロー情報
 * @returns {object}
 */
const convertToDetailsOfTransportation = async (wasteFlow) => {
  if ((wasteFlow.transportProcesses?.length ?? 0) <= 0) {
    throw new Error("処理フローに運搬情報が含まれていませんでした。");
  }

  // 積み替え先が無い前提
  const transportProcess = wasteFlow.transportProcesses[0];

  const transportCompany = await fetchCompany(
    transportProcess.carrierCompany.id
  );

  const { transportDestinationWorkplace } = transportProcess;
  const {
    name,
    phone,
    postalCode,
    prefectures,
    city,
    streetAddress,
    otherAddress,
  } = transportDestinationWorkplace;

  return {
    wasteCarrierCompany: {
      ...transportCompany,
    },
    wasteTransportDestination: {
      name,
      phone: phone,
      postalCode,
      prefectures,
      city,
      streetAddress,
      otherAddress,
    },
  };
};

/**
 * 処理フローから処分受託者を引き出し、詳細フォームの形に変換します。
 * @param {object} wasteFlow 処理フロー情報
 * @returns {object}
 */
const convertToDetailsOfDisposal = async (wasteFlow) => {
  const disposalCompany = await fetchCompany(
    wasteFlow.disposalProcess.disposalCompany.id
  );

  const {
    name,
    phone,
    postalCode,
    prefectures,
    city,
    streetAddress,
    otherAddress,
  } = disposalCompany;

  return {
    wasteDisposalCompany: {
      name: name,
      phone: phone,
      postalCode: postalCode,
      prefectures: prefectures,
      city: city,
      streetAddress: streetAddress,
      otherAddress: otherAddress,
    },
  };
};

/**
 * 簡易フォームの値から運搬の受託を引き出し、詳細フォームの形に変換します。
 * @param {object} wasteCarrierCompany 運搬受託者
 * @param {object} data 簡易フォーム値
 * @returns {object}
 */
const convertToDetailsOfTransportationContracts = (
  wasteCarrierCompany,
  data
) => {
  const { wasteCarrierPersonInCharge, endOfTransportationDate, valuables } =
    data;
  return {
    wasteTransportReport: {
      wasteCarrierCompanyName: wasteCarrierCompany?.name ?? "",
      wasteCarrierPersonInCharge: wasteCarrierPersonInCharge,
      endOfTransportationDate: endOfTransportationDate,
      valuablesQuantityValue: valuables?.value,
      valuablesQuantityUnit: valuables?.unit,
      valuablesQuantityUnitName: valuables?.unit?.name ?? "",
    },
  };
};

/**
 * 簡易フォームの値から処分の受託を引き出し、詳細フォームの形に変換します。
 * @param {object} wasteCarrierCompany 運搬受託者
 * @param {object} data 簡易フォーム値
 * @returns {object}
 */
const convertToDetailsOfDisposalContracts = (disposal) => {
  return {
    wasteDisposalReport: {
      wasteDisposalCompanyName: disposal?.wasteDisposalCompany?.name ?? "",
      wasteDisposalPersonInCharge: "",
      endOfDisposalDate: null,
      endOfLastDisposalDate: null,
    },
  };
};

/**
 * 簡易フォームからrelationを取得します。
 */
const fetchRelation = (
  dischargesCompanyId,
  dischargesWorkplace,
  waste,
  wasteFlow
) => {
  return {
    relation: {
      wasteGeneratorCompanyId: dischargesCompanyId,
      wasteGeneratorWorkplaceId: dischargesWorkplace.id,
      wasteId: waste?.id,
      wasteFlowId: wasteFlow.id,
    },
  };
};

/**
 * 簡易フォームの値を詳細フォームの値に変換します。
 * @param {object} value
 */
export const convertToDetails = async (value) => {
  const {
    issueDate,
    issueNumber,
    dischargesWorkplace,
    wastes,
    flow,
    quantity,
    remarks,
    endOfTransportationDate,
    wasteCarrierPersonInCharge,
    valuables,
    relation,
    ...other
  } = value;

  let discharges;
  let workplace;
  if (
    other?.relation?.wasteGeneratorWorkplaceId &&
    other?.relation?.wasteGeneratorWorkplaceId === dischargesWorkplace?.id
  ) {
    discharges = other.wasteGeneratorCompany;
    workplace = other.wasteGeneratorWorkplace;
  } else {
    discharges = await fetchCompany(dischargesWorkplace.belongInCompanyId);
    workplace = convertToDetailsOfDischargesWorkplace({
      dischargesWorkplace: dischargesWorkplace,
    });
  }

  const wasteFlow = await fetchWasteFlow(value);
  let waste;
  let transportation;
  let transportationContracts;
  let disposal;
  let disposalContracts;
  if (
    other?.relation?.wasteFlowId &&
    other?.relation?.wasteFlowId === value.flow.id
  ) {
    waste = {
      waste: {
        ...other.waste,
        quantityValue: quantity?.value,
        quantityUnit: quantity?.unit,
        quantityUnitName: quantity?.unit?.name,
        remarks: remarks,
      },
    };
    transportation = {
      wasteCarrierCompany: {
        ...other.wasteCarrierCompany,
      },
      wasteTransportDestination: {
        ...other.wasteTransportDestination,
      },
      wasteTransportReport: {
        ...other.wasteTransportReport,
        valuablesQuantityUnit: valuables?.unit,
        valuablesQuantityUnitName: valuables?.unit?.name,
        valuablesQuantityValue: valuables?.value,
        endOfTransportationDate: endOfTransportationDate,
        wasteCarrierPersonInCharge: wasteCarrierPersonInCharge,
      },
    };
    disposal = {
      wasteDisposalCompany: {
        ...other.wasteDisposalCompany,
      },
    };
    disposalContracts = {
      wasteDisposalReport: {
        ...other.wasteDisposalReport,
      },
    };
  } else {
    waste = convertToDetailsOfWaste(value);
    transportation = await convertToDetailsOfTransportation(wasteFlow);
    disposal = await convertToDetailsOfDisposal(wasteFlow);
    transportationContracts = convertToDetailsOfTransportationContracts(
      transportation.wasteCarrierCompany,
      value
    );
    disposalContracts = convertToDetailsOfDisposalContracts(disposal);
  }

  let r;

  if (
    other?.relation?.wasteGeneratorWorkplaceId === dischargesWorkplace?.id &&
    other?.relation?.wasteId === wastes.id &&
    other?.relation?.wasteFlowId === flow.id
  ) {
    r = other.relation;
  } else {
    r = fetchRelation(
      discharges?.id || dischargesWorkplace.belongInCompanyId,
      dischargesWorkplace,
      wastes,
      wasteFlow
    );
  }

  const result = {
    id: other?.id,
    version: other?.version,
    issueDate: issueDate,
    issueNumber: issueNumber,
    paperManifestType: PaperManifestType,
    manifestInfoType: ManifestInfoType,
    manifestCardinalNumberType: ManifestCardinalNumberType,
    wasteGeneratorCompany: {
      ...discharges,
    },
    ...workplace,
    ...waste,
    ...transportation,
    ...disposal,
    ...transportationContracts,
    ...disposalContracts,
    ...r,
  };

  debugLog("簡易フォーム → 詳細フォーム その他: ", other);
  debugLog("簡易フォーム → 詳細フォーム 変換: ", result);

  return result;
};

/**
 * 詳細フォームの値から排出事業場を取得します。
 * @param {object} value 詳細フォームの値
 * @returns {object}
 */
const convertFromDetailsOfDischargesWorkplace = (value) => {
  const { wasteGeneratorWorkplace, relation } = value;
  return {
    dischargesWorkplace: {
      ...wasteGeneratorWorkplace,
      belongInCompanyId: relation.wasteGeneratorCompanyId,
      id: relation.wasteGeneratorWorkplaceId,
    },
  };
};

/**
 * 詳細フォームの廃棄物を簡易フォームの形に変換します。
 */
const convertFromDetailsOfWaste = (value) => {
  const { waste, relation } = value;
  return {
    wastes: {
      id: relation.wasteId,
      type: {
        id: waste.types[0].wasteType.id,
        name: waste.types[0].wasteTypeName,
        group: waste.types[0].group,
        largeClass: waste.types[0].wasteType.largeClass,
        middleClass: waste.types[0].wasteType.middleClass,
        smallClass: waste.types[0].wasteType.smallClass,
      },
    },
    remarks: waste.remarks,
  };
};

/**
 * 詳細フォームの値から処理フローを取得します。
 * @param {object} value 詳細フォームの値
 * @returns {object}
 */
const convertFromDetailsOfFlow = async (value) => {
  const { relation } = value;

  // apiを読んで復帰していいかどうか

  return {
    flow: await fetchWasteFlow({
      flow: {
        id: relation.wasteFlowId,
      },
    }),
  };
};

/**
 * 詳細フォームの値から数量を取得します。
 * @param {object} value 詳細フォームの値
 * @returns {object}
 */
const convertFromDetailsOfQuantity = (value) => {
  const { waste } = value;
  return {
    quantity: {
      value: waste.quantityValue,
      unit: waste.quantityUnit,
    },
  };
};

/**
 * 詳細フォームの値から運搬情報を取得します。
 * @param {object} value 詳細フォームの値
 * @returns {object}
 */
const convertFromDetailsOfTransportation = (value) => {
  const { wasteTransportReport } = value;

  return {
    endOfTransportationDate: wasteTransportReport.endOfTransportationDate,
    wasteCarrierPersonInCharge: wasteTransportReport.wasteCarrierPersonInCharge,
    valuables: {
      value: wasteTransportReport.valuablesQuantityValue,
      unit: wasteTransportReport.valuablesQuantityUnit,
    },
  };
};

/**
 * 詳細フォームの値から簡易フォームの値に変換します。
 * @param {object} value 詳細フォームの値
 * @returns {object}
 */
export const convertFromDetails = async (value) => {
  const { issueDate, issueNumber, relation, ...other } = value;
  const result = {
    id: other?.id,
    ...convertFromDetailsOfDischargesWorkplace(value),
    ...convertFromDetailsOfWaste(value),
    ...(await convertFromDetailsOfFlow(value)),
    ...convertFromDetailsOfQuantity(value),
    ...convertFromDetailsOfTransportation(value),
    issueDate: issueDate,
    issueNumber: issueNumber,
    version: value?.version,
    relation,
  };

  debugLog("詳細フォーム → 簡易フォーム 変換: ", result);

  return result;
};

/**
 * 簡易入力のデフォルト値です。
 */
export const SimpleFormDefaultValue = {
  dischargesWorkplace: null,
  wastes: null,
  flow: null,
  issueNumber: "",
  issueDate: null,
  quantity: null,
  remarks: null,
  endOfTransportationDate: null,
  wasteCarrierPersonInCharge: "",
  valuables: null,
  // file: null,
};

/**
 * マニフェストの交付に関する初期値です。
 */
export const IssueSectionDefaultValue = {
  issueDate: null,
  issueNumber: "",
  issuePersonInCharge: "",
  referenceNumber: "",
};

/**
 * 会社や場所に関する基本となる情報の初期値です。
 */
const CompanyBaseDefaultValue = {
  name: null,
  phone: null,
  postalCode: null,
  prefectures: {
    code: null,
    name: null,
  },
  city: null,
  streetAddress: null,
  otherAddress: null,
};

/**
 * 事業者に関する初期値です。
 */
export const WasteGeneratorCompanyDefaultValue = {
  wasteGeneratorCompany: {
    ...CompanyBaseDefaultValue,
  },
};

/**
 * 事業場に関する初期値です。
 */
export const WasteGeneratorWorkplaceDefaultValue = {
  wasteGeneratorWorkplace: {
    ...CompanyBaseDefaultValue,
  },
};

/**
 * 廃棄物に関する初期値です。
 */
export const WasteDefaultValue = {
  waste: {
    types: [],
    name: "",
    quantityValue: null,
    quantityUnit: null,
    quantityUnitName: "",
    packingName: "",
    disposalMethodName: "",
    hazardousSubstanceName: "なし",
    remarks: "",
  },
};

/**
 * 中間処理産業廃棄物欄に関する初期値です。
 */
export const IntermediateProcessingManagementTypeDefaultValue = {
  intermediateProcessingManagementType: {
    code: "1",
    name: "帳簿欄記載のとおり",
  },
  primaryManifestRecord: {
    // 1次マニフェストの交付者の氏名又は名称
    primaryManifestIssuerName: "",
    // 1次マニフェストの交付番号
    primaryManifestIssueNumber: "",
    // 1次マニフェストの種類コード（紙or電子）
    // todo: 何が入るのか不明
    primaryManifestTypeCode: "",
  },
};

/**
 * 最終処分の場所欄に関する初期値です。
 */
export const LastDisposalPlantDescribeTypeDefaultValue = {
  lastDisposalPlantDescribeType: {
    code: "1",
    name: "委託契約書記載のとおり",
  },
  plannedLastDisposalPlant: {
    name: null,
    phone: null,
    postalCode: null,
    prefectures: {
      code: null,
      name: null,
    },
    city: null,
    streetAddress: null,
    otherAddress: null,
  },
};

/**
 * 運搬事業者に関する初期値です。
 */
export const WasteCarrierCompanyDefaultValue = {
  wasteCarrierCompany: {
    ...CompanyBaseDefaultValue,
  },
};

/**
 * 運搬先事業場に関する初期値です。
 */
export const WasteTransportDestinationDefaultValue = {
  wasteTransportDestination: {
    ...CompanyBaseDefaultValue,
  },
};

/**
 * 処分受託者に関する初期値です。
 */
export const WasteDisposalCompanyDefaultValue = {
  wasteDisposalCompany: {
    ...CompanyBaseDefaultValue,
  },
};

/**
 * 運搬の受託に関する初期値です。
 */
export const WasteTransportReportDefaultValue = {
  wasteTransportReport: {
    wasteCarrierCompanyName: "",
    wasteCarrierPersonInCharge: "",
    endOfTransportationDate: "",
    valuablesQuantityValue: null,
    valuablesQuantityUnit: null,
    valuablesQuantityUnitName: "",
  },
};

/**
 * 処分の受託に関する初期値です。
 */
export const WasteDisposalReportDefaultValue = {
  wasteDisposalReport: {
    wasteDisposalCompanyName: "",
    wasteDisposalPersonInCharge: "",
    endOfDisposalDate: "",
    endOfLastDisposalDate: "",
  },
};

/**
 * 最終処分を行った場所に関する初期値です。
 */
export const WasteLastDisposalPlantDefaultValue = {
  wasteLastDisposalPlant: {
    contractNumber: "",
    ...CompanyBaseDefaultValue,
  },
};

/**
 * 詳細入力のデフォルト値です。
 */
export const DetailsFormDefaultValue = {
  ...IssueSectionDefaultValue,
  ...IntermediateProcessingManagementTypeDefaultValue,
  ...LastDisposalPlantDescribeTypeDefaultValue,
  isTransshipmentStorageUsing: false,
  ...WasteGeneratorCompanyDefaultValue,
  ...WasteGeneratorWorkplaceDefaultValue,
  ...WasteDefaultValue,
  ...WasteCarrierCompanyDefaultValue,
  ...WasteTransportDestinationDefaultValue,
  ...WasteDisposalCompanyDefaultValue,
  wasteTransshipmentStorage: null,
  ...WasteTransportReportDefaultValue,
  ...WasteDisposalReportDefaultValue,
  ...WasteLastDisposalPlantDefaultValue,
  relation: {
    wasteGeneratorCompanyId: "",
    wasteGeneratorWorkplaceId: "",
    wasteId: "",
    wasteFlowId: "",
  },
};
