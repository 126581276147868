import { Stack, Button, Slide } from "@mui/material";
import React from "react";
import { FullScreenDialog } from "views/molecules";
import { Step1Form } from "./Step1Form";
import { Step2Form } from "./Step2Form";

const Step1FormfR = React.forwardRef((props, ref) => {
  return <Step1Form {...props} _ref={ref} />;
});

const Step2FormfR = React.forwardRef((props, ref) => {
  return <Step2Form {...props} _ref={ref} />;
});

/**
 * コンテナ管理の種類詳細を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @return {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <FullScreenDialog
      open={props.open}
      onClose={props.onClose}
      title={`${props?.name}の追加`}
      action={
        <Stack direction={"row"} spacing={3}>
          {props.step === "step1" && (
            <Button
              variant="contained"
              onClick={props.onNext}
              disabled={props.isSubmit}
              color="info"
              sx={{
                mr: 2,
              }}
            >
              {"次へ"}
            </Button>
          )}
          {props.step === "step2" && (
            <Button
              variant="contained"
              onClick={props.onPrevious}
              disabled={props.isSubmit}
              color="warning"
              sx={{
                mr: 2,
              }}
            >
              {"前へ"}
            </Button>
          )}
        </Stack>
      }
      textConfirm="登録"
      onClickSubmit={props.onSubmitForm2}
      isSubmit={props.isSubmit}
      disabled={props.isSubmit || props.step === "step1"}
    >
      <Slide
        direction="right"
        in={props.step === "step1"}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <Step1FormfR
            value={props.step1formValue}
            onSubmit={props.onSubmitStep1}
            ref={props.step1FormRef}
          />
        </div>
      </Slide>
      <Slide
        direction="left"
        in={props.step === "step2"}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <Step2FormfR
            value={props.step1formValue}
            onSubmit={props.onRegister}
            ref={props.step2FormRef}
            totalOfOwned={props.totalOfOwned}
          />
        </div>
      </Slide>
    </FullScreenDialog>
  );
};
