import { Grid, Box, Button } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import {
  InputField,
  InputFieldPopppSelect,
  WasteTypeSelector,
  ModalPopup,
} from "views/molecules";

/**
 * 基本情報を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Elemnt}
 */
export const Presententer = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          name="number"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="管理番号"
              value={value}
              onChange={onChange}
              placeholder="管理番号を入力します。"
              error={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="name"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="名称"
              value={value}
              onChange={onChange}
              placeholder="廃棄物の名称を入力します。"
              error={error}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="targetWaste"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <InputFieldPopppSelect
                label="廃棄物の種類"
                value={
                  value
                    ? {
                        id: value.id,
                        name:
                          value.smallClass?.name ||
                          value.middleClass?.name ||
                          value.largeClass?.name,
                      }
                    : ""
                }
                onChange={onChange}
                placeholder="廃棄物の種類を選択します。"
                required={true}
                showIcon={false}
                textConfirm="選択"
                onOpenModal={props.onOpenModal}
                error={error}
                disabledButtom={props.isEditMode}
              />
              <ModalPopup open={props.open} onClose={props.onCloseModal}>
                <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                  <Box sx={{ height: "70vh" }}>
                    <WasteTypeSelector
                      value={props.selectedValue}
                      onSelected={props.onSelected}
                      checkbox={true}
                      multiple={false}
                    />
                  </Box>
                  <Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={props.onCloseModal}
                      sx={{
                        marginRight: "10px",
                      }}
                    >
                      {"キャンセル"}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={props.onDecided}
                    >
                      {"決定"}
                    </Button>
                  </Box>
                </Box>
              </ModalPopup>
            </>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="targetWasteKind"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="廃棄物の詳細種類"
              value={value}
              onChange={onChange}
              placeholder="選択した廃棄物の種類を細かく分ける場合に入力します。"
              error={error}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
