import React, { useState } from "react";
import { ConsoleLogger as Logger } from "@aws-amplify/core";
import { confirmSignIn, fetchUserAttributes } from "aws-amplify/auth";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import { selector, set, AUTHSTATES } from "ducks/Auth";
import {
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from "@mui/material";

import { AuthenticationTemplate } from "../../organisms";

const logger = new Logger("RequireNewPassword");

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const initialState = {
  password: "",
};

const isTruthyString = (value) => {
  return (
    value &&
    typeof value.toLowerCase === "function" &&
    value.toLowerCase() === "true"
  );
};
const _validAuthStates = [AUTHSTATES.FORCE_NEW_PASSWORD];
export const RequireNewPassword = (props) => {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState(initialState);
  const { authState } = useSelector(selector);
  const dispatch = useDispatch();

  const onStateChange = (state) => {
    dispatch(set(state));
  };

  const setInput = (key, value) => setFormState({ ...formState, [key]: value });

  const submit = () => {
    setError("");
    setLoading(true);
    const { password } = formState;
    confirmSignIn({
      challengeResponse: password,
    })
      .then((response) => {
        const { isSignedIn, nextStep } = response;
        logger.debug("complete new password");

        if (isSignedIn) {
          fetchUserAttributes().then((data) => {
            if (isTruthyString(data.email_verified)) {
              onStateChange(AUTHSTATES.AUTHENTICATED);
              return;
            }
            onStateChange(AUTHSTATES.CONFIRM_VERIFY_USER);
          });
        }
        switch (nextStep.signInStep) {
          case "CONFIRM_SIGN_IN_WITH_TOTP_CODE":
          case "CONFIRM_SIGN_IN_WITH_SMS_CODE":
          case "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE":
          case "CONTINUE_SIGN_IN_WITH_TOTP_SETUP":
          case "CONTINUE_SIGN_IN_WITH_MFA_SELECTION": {
            onStateChange(AUTHSTATES.CONFIRM_SIGN_IN);
            break;
          }
          case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED": {
            onStateChange(AUTHSTATES.FORCE_NEW_PASSWORD);
            break;
          }
          case "DONE":
          default:
            onStateChange(AUTHSTATES.AUTHENTICATED);
            break;
        }
      })
      .catch((err) => {
        logger.debug(err);
        setError("エラーが発生しました。");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    _validAuthStates.includes(authState) && (
      <AuthenticationTemplate>
        <Typography component="h2" variant="h5">
          新しいパスワードの設定
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            value={formState.password}
            onChange={(event) => setInput("password", event.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            disabled={loading}
            onClick={submit}
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : "設定"}
          </Button>
          {error && (
            <Typography color="error" paragraph>
              {error}
            </Typography>
          )}
          <Link
            variant="body2"
            component="button"
            onClick={() => onStateChange(AUTHSTATES.SIGN_IN)}
          >
            ログインに戻る
          </Link>
        </form>
      </AuthenticationTemplate>
    )
  );
};

export default RequireNewPassword;
