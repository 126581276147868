import React from "react";
import { FlowsTable } from "views/organisms/Masters/Flow";

/**
 * 処理フロー情報を表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <FlowsTable onSelected={props.onSelected} {...props} />
);
