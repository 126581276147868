import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 通知を表示するコンポーネントです
 * @param {string} message 通知のメッセージ
 * @param {func} onClick 通知をクリックするイベント
 * @param {boolean} open 通知を表示か非表示にする
 * @param {string} request 調整依頼された
 */
export const MobileAlert = containerPresententer(Container, Presententer);
