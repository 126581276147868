import { styles } from "./styles";
import useClasses from "utils/useClasses";

/**
 * 回収コースを表示するコンテナコンポーネントです。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({ render, value, onClickCourses, ...props }) => {
  const classes = useClasses(styles);

  return render({
    classes: classes,

    value: value,
    onClick: onClickCourses,
    ...props,
  });
};
