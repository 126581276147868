import { useState, useEffect } from "react";

/**
 * 開閉状態のカスタムフックです。
 * @param {object} props プロパティ
 * @param {boolean} props.status 開閉状態
 * @param {object} props.options オプション
 * @fires useOpener#onChange 開閉状態変更時
 * @returns {boolean} open 開閉状態
 * @returns {object} options オプション
 * @returns {function} toggle 開閉状態切り替え
 * @returns {function} changeOptions オプション変更
 * @example
 * const { open, toggle, options, changeOptions } = useOpener({}); // 初期値
 */
export const useOpener = ({
  status = false,
  options = null,
  onChange = () => {},
} = {}) => {
  const [open, setOpen] = useState(status ?? false);
  const [other, setOther] = useState(options);

  useEffect(() => {
    setOpen(status ?? false);
  }, [status]);

  useEffect(() => {
    setOther(options);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(options)]);

  const handleToggle = (newStatus) => {
    const result = newStatus !== null ? newStatus : !open;

    setOpen(result);
    if (result !== open) {
      onChange(result);
    }
  };

  return {
    open: open,
    toggle: handleToggle,
    options: other,
    changeOptions: (newOptions) => setOther(newOptions),
  };
};
