import { Box } from "@mui/material";

/**
 * タイトルの表示を行うプレゼンテーションコンポーネントです
 * @param {object} classes クラス名
 * @param {string} label ラベル
 * @param {string} value 値
 * @returns　JSX Element
 */
export const Presententer = (props) => {
  return (
    <>
      <Box className={props.classes.main_title}>{props.mainTitle}</Box>
      {props.subTitle.map((sub, index) => (
        <Box key={index} className={props.classes.sub_title}>
          {sub}
        </Box>
      ))}
    </>
  );
};
