import { listWeightConvertedValueUnits } from "api/graphql/queries";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "utils/graphqlOperation";

/**
 * 重量換算係数を取得します。
 * @returns {object}
 */
const fetch = async () => {
  try {
    const fetchedData = await API.graphql(
      graphqlOperation(listWeightConvertedValueUnits)
    );
    return fetchedData.data.listWeightConvertedValueUnits.items;
  } catch (error) {
    throw error;
  }
};

export const fetchWeightConvertedValueUnits = createAsyncThunk(
  "weightConvertedValueUnits/fetch",
  async (_, thunkApi) => {
    return fetch();
  }
);
