import { Box, FormHelperText } from "@mui/material";
import React from "react";
import { ClientWorkplaceSelector } from "views/molecules";
import { Controller } from "react-hook-form";

/**
 * 最終処分情報を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box
      pb={3}
      sx={{
        height: "550px",
      }}
    >
      <Controller
        name="lastDisposalWorkplaces"
        control={props.control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <ClientWorkplaceSelector
              value={value}
              onSelected={onChange}
              multiple={true}
              checkbox={true}
              fixedOptions={{
                filter: {
                  and: [
                    {
                      categoryId: {
                        eq: "disposalPlant",
                      },
                    },
                  ],
                },
              }}
            />
            <FormHelperText error>{error?.message}</FormHelperText>
          </>
        )}
      />
    </Box>
  );
};
