import { useStyles } from "./styles";
import { useForm } from 'react-hook-form';

/**
 * フォームの初期値を表します。
 */
const defaultValues = {
  username: '',
  password: '',
};

/**
 * ログイン画面を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @fires Container#onSubmit 送信時
 * @fires Container#onClickForgotPassword パスワード忘れクリック時
 * @param {boolean} isLoading 読み込み時中であるか
 * @param {string} error エラー
 * @returns {func}
 */
export const Container = ({
  render,
  onSubmit,
  onError,
  onClickForgotPassword,
  isLoading = false,
  error = null,
  ...props
}) => {
  const { handleSubmit, control } = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
  });

  const handleClickForgotPassword = () => {
    onClickForgotPassword && onClickForgotPassword();
  };

  const classes = useStyles();

  return render({
    classes: classes,
    handleSubmit: handleSubmit(onSubmit, onError),
    handleClickForgotPassword: handleClickForgotPassword,
    control: control,
    isLoading: isLoading,
    error: error,
    ...props,
  });
};
