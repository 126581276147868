import { add as addAlert } from "ducks/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useOpener } from "utils/useOpener";

const tab = 0;
/**
 * 事業場の情報を詳しく表示する。
 * @param {object} props プロパティ
 * @callback render
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  isHeadOffice,
  updateWorkplace,
  load,
  loading,
  removeMutation,
  ...props
}) => {
  const dispatch = useDispatch();
  const [isSubmitWorkplaceEditDialog, setIsSubmitWorkplaceEditDialog] =
    useState(false);
  const [isOpenWorkplaceEditDialog, setIsOpenWorkplaceEditDialog] =
    useState(false);

  const removeConfirmDialog = useOpener();

  const handleOpenWorkplaceEditDialog = () => {
    setIsOpenWorkplaceEditDialog(true);
  };

  const handleCloseWorkplaceEditDialog = () => {
    setIsOpenWorkplaceEditDialog(false);
  };

  const handleSubmitUpdateWorkplace = (data) => {
    setIsSubmitWorkplaceEditDialog(true);
    updateWorkplace(
      data,
      () => {
        handleCloseWorkplaceEditDialog();
        dispatch(
          addAlert({
            value: "保存しました。",
            severity: "success",
          })
        );
        load();
        setIsSubmitWorkplaceEditDialog(false);
      },
      () => {
        dispatch(
          addAlert({
            value: "エラーが発生したため、保存できませんでした。",
            severity: "error",
          })
        );
        setIsSubmitWorkplaceEditDialog(false);
      }
    );
  };

  return render({
    ...props,
    value: value,
    tab: tab,
    isHeadOffice: isHeadOffice,
    isOpenWorkplaceEditDialog: isOpenWorkplaceEditDialog,
    isSubmitWorkplaceEditDialog: isSubmitWorkplaceEditDialog,
    onOpenWorkplaceEditDialog: handleOpenWorkplaceEditDialog,
    onCloseWorkplaceEditDialog: handleCloseWorkplaceEditDialog,
    onSubmitUpdateWorkplace: handleSubmitUpdateWorkplace,
    loading: loading,
    removeConfirmDialog: removeConfirmDialog,
    removeMutation: removeMutation,
  });
};
