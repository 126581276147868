/**
 * react-beautiful-dndで使用する関数群
 */

 /**
  * オーダーし直します。
  * @param {object[]} source 対象のリスト
  * @param {number} fromIndex 移動元のインデックス
  * @param {number} toIndex 移動先のインデックス
  * @returns {object[]} オーダーし直したリスト
  */
 export const reorder = (
     source,
     fromIndex,
     toIndex) => {
         const result = Array.from(source);
         const [removed] = result.splice(fromIndex, 1);
         result.splice(toIndex, 0, removed);
         return result;
 }
 
 /**
  * オーダーし直します。
  * @param {object[]} source 対象のリスト
  * @param {DropResult} dropResult DropResultオブジェクト
  * @returns {object[]} オーダーし直したリスト
  */
 export const reorderFromDropResult = (
     source,
     dropResult) => {
         return reorder(
             source,
             dropResult.source.index,
             dropResult.destination.index);
 };
 
 /**
  * リスト間でアイテムを移動します。
  * @param {object[]} source 移動元リスト
  * @param {object[]} destination 移動先リスト
  * @param {object} droppableSource 
  * @param {object} droppableDestination 
  * @returns {object} 移動結果
  */
 export const move = (
     source,
     destination,
     droppableSource,
     droppableDestination) => {
     const sourceClone = Array.from(source);
     const destClone = Array.from(destination);
     const [removed] = sourceClone.splice(droppableSource.index, 1);
     destClone.splice(droppableDestination.index, 0, removed);
     return {
         source: {
             id: droppableSource.droppableId,
             value: sourceClone
         },
         destination: {
             id: droppableDestination.droppableId,
             value: destClone
         }
     };
 };
 
 /**
  * リストのアイテムを交換します。idプロパティを持っていることが前提です。
  * @param {object[]} list 交換対象のリスト
  * @param {object} item 置き換えたいアイテム
  * @returns {object[]} 交換後リスト
  */
 export const swap = (
     list,
     item) => {
         let result = Array.from(list);
         result.splice(
             result.findIndex(listItem => listItem.id === item.id),
             1,
             item
         );
         return result;
 };
 
 /**
  * 対象のリストからアイテムを除外します。idプロパティを持っていることが前提です。
  * @param {object[]} list 交換対象のリスト
  * @param {object} item 削除したいアイテム
  * @returns {object[]} 削除後リスト
  */
 export const remove = (
     list,
     item) => {
         let result = Array.from(list);
         result.splice(
             result.findIndex(listItem => listItem.id === item.id),
             1
         );
         return result;
 };
 
 /**
  * ドラッグ終了イベントにフックして並び替え・移動の感知する関数です。
  * @param {object} result onDragEndの結果オブジェクト
  * @returns {func} ドラッグ終了イベント監視関数
  */
 export const handleOnDragEnd = (onReorder = null, onMove = null) => result => {
     const {
         source,
         destination
     } = result;
 
     if (!destination) {
         // リストは移動しなかった
         return;
     }
 
     if (source.droppableId === destination.droppableId) {
         if (source.index === destination.index) {
             // 変化がなかった
             return;
         }
 
         // 並び替えられた
         onReorder && onReorder(result);
     } else {
         // 移動した
         onMove && onMove(result);
     }
 };