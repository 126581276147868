import { recordWasteCollectionTasks } from "api/graphql/mutations";
import { useMutation } from "utils/useMutation";
import { Layout } from "./Layout";

export const Form = ({
  open = false,
  onCompleted = () => {},
  onClose = () => {},
  item,
  task,
  disabled = false,
}) => {
  const [mutation, status] = useMutation(recordWasteCollectionTasks, {
    onCompleted: () => {
      onCompleted();
      onClose();
    },
    succeedMessage: "保存しました。",
    errorMessage: "エラーが発生したため、保存に失敗しました。",
  });

  return (
    <Layout
      open={open}
      onClose={onClose}
      onSave={mutation}
      item={item}
      record={task?.record}
      task={task}
      loading={status.loading}
      disabled={status.loading || disabled}
    />
  );
};
