import { add as addAlert } from "ducks/Alert";
import { companyCategoriesSelector } from "ducks/CompanyCategories";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { useStyles } from "./styles";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import { useOpener } from "utils/useOpener";

/**
 * 取引先詳細ページでの事業場詳細を表示するコンテナコンポーネントです。
 * @param {object} props プロパティ
 * @callback render
 * @returns JSX.Element
 */
export const Container = ({
  render,
  value,
  submit = (data) => Promise.resolve(),
  onSubmit = (data) => debugLog(data),
  onError = (err) => debugLog(err),
  client,
  loading,
  removeMutation,
  ...props
}) => {
  const classes = useClasses(useStyles);
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const companyCategories = useSelector(companyCategoriesSelector);
  const qs = new URLSearchParams(location.search);
  const [tab, setTab] = useState(qs.get("tab") ? parseInt(qs.get("tab")) : 0);
  const removeConfirmDialog = useOpener();

  const handleGetTabNumber = (newTab) => {
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({ tab: newTab })}`,
      },
      {
        replace: true,
      }
    );

    setTab(newTab);
  };

  const handleSubmit = (data) => {
    setIsSubmit(true);
    submit(data)
      .then((res) => {
        debugLog("WorkplaceDetails.onSubmit.succeess", res);
        onSubmit(res);
        dispatch(
          addAlert({
            value: "保存しました。",
            severity: "success",
          })
        );
        setOpen(false);
      })
      .catch((err) => {
        debugLog("WorkplaceDetails.onSubmit.error", err);
        onError(err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、保存できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return render({
    classes: classes,
    value: value,
    tab: tab,
    getTab: handleGetTabNumber,
    open: open,
    onOpenDialog: () => setOpen(true),
    onCloseDialog: () => setOpen(false),
    submit: handleSubmit,
    client: client,
    isSubmit: isSubmit,
    companyCategories: companyCategories,
    removeConfirmDialog: removeConfirmDialog,
    loading: loading,
    removeMutation: removeMutation,
    ...props,
  });
};
