import yup, { remarksSchema, textSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  number: textSchema.nullable(),
  name: textSchema.required(),
  manufacturerName: textSchema.required(),
  modelNumber: textSchema.nullable(),
  width: yup
    .number()
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  height: yup
    .number()
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  depth: yup
    .number()
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  volume: yup
    .number()
    .transform((value) => (value === "" ? null : value))
    .nullable(),
  remarks: remarksSchema,
  files: yup.array().nullable(),
});
