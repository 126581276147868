import reducer from "./slice";
import {
  selector,
  containerDisuseTypesSelector,
  isLoadingSelector,
  errorSelector,
} from "./slice";
import { fetchContainerDisuseTypes } from "./api";

export default reducer;
export {
  fetchContainerDisuseTypes,
  selector,
  containerDisuseTypesSelector,
  isLoadingSelector,
  errorSelector,
};
