import { useForm } from "react-hook-form";
import { useStyles } from "./styles";

const initialState = {
  code: "",
};

/**
 * 確認コードを入力する画面を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @fires Container#onTransiteLogin ログインに遷移するとき
 * @fires Container#onSubmit 送信時処理
 * @fires Container#onError エラー時
 * @param {boolean} loading ローディング中か
 * @param {boolean} locked ロックされているか
 * @param {string} error エラー文字列
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  onTransiteLogin,
  onSubmit,
  onError,
  loading,
  locked,
  error,
  ...props
}) => {
  const classes = useStyles();
  const { handleSubmit, control } = useForm({
    mode: "onBlur",
    defaultValues: initialState,
  });

  return render({
    submit: handleSubmit(onSubmit, onError),
    onTransiteLogin: onTransiteLogin,
    classes: classes,
    locked: locked,
    loading: loading,
    control: control,
    error: error,
    ...props,
  });
};
