export const styles = (theme) => ({
  title: {
    color: theme.palette.text.secondary,
    fontSize: "15px",
  },
  description: {
    color: theme.palette.text.disabled,
    fontSize: "12px",
  },
});
