import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { Box, Card, CardActionArea, Stack, Typography } from "@mui/material";

/**
 * 箱型のコンフィグを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} title タイトル
 * @fires BoxConfig#onClick クリック時
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 */
export const BoxConfig = ({ title, onClick, children }) => {
  return (
    <Card onClick={onClick}>
      <CardActionArea>
        <Stack
          spacing={0.5}
          sx={{
            padding: "8px 16px",
          }}
        >
          <Box
            display="flex"
            flexDirection={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            color="gray"
          >
            <Typography
              sx={{
                fontSize: "14px",
              }}
            >
              {title}
            </Typography>
            <ModeEditOutlinedIcon />
          </Box>
          <Box
            display="flex"
            flexDirection={"row"}
            sx={{
              borderBottom: "2px solid #2979ff",
            }}
          >
            {children}
          </Box>
        </Stack>
      </CardActionArea>
    </Card>
  );
};
