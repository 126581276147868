import { yupResolver } from "@hookform/resolvers/yup";
import {
  Avatar,
  Box,
  Checkbox,
  Container,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { DatePicker, TextField } from "views/atoms";
import { FileGallery, FullScreenDialog, Uploader } from "views/molecules";
import { basicSchema } from "./Validation";

/**
 * チェック内容を入力するコンポーネントです。
 * @param {object} props プロパティ
 * @param {array} value 値
 * @fires Inspection#onChange 変更時
 * @returns {JSX.Element}
 */
const Inspection = ({ value, onChange = () => {} }) => {
  const handleChange = (item, index) => () => {
    let result = [...(value?.items ?? [])];
    result.splice(index, 1, {
      ...item,
      checked: !result?.[index]?.checked,
    });
    onChange({
      ...value,
      items: result,
    });
  };

  return (
    <Paper
      sx={{
        p: 3,
      }}
    >
      <Box
        display="flex"
        flexDirection={"row"}
        sx={{
          p: 1,
        }}
      >
        <Box
          sx={{
            pr: 5,
          }}
        >
          <Avatar
            sx={{
              backgroundColor: "black",
              width: "32px",
              height: "32px",
            }}
          >
            {value?.order + 1}
          </Avatar>
        </Box>
        <Box>{value?.name ?? ""}</Box>
      </Box>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
        }}
      >
        {value?.items?.map((item, itemIndex) => {
          return (
            <React.Fragment key={itemIndex}>
              <Divider />
              <ListItem
                onClick={handleChange(item, itemIndex)}
                secondaryAction={
                  <Checkbox edge="end" checked={item?.checked ?? false} />
                }
              >
                <Box display="flex" flexDirection={"row"}>
                  <Box
                    sx={{
                      pr: 5,
                    }}
                  >
                    {item?.order + 1}
                  </Box>
                  <Box>
                    <ListItemText id={itemIndex} primary={item.name} />
                  </Box>
                </Box>
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>
    </Paper>
  );
};

/**
 * チェック内容を入力するコンポーネントです。
 * @param {object} props プロパティ
 * @param {array} value 値
 * @fires Inspection#onChange 変更時
 * @returns {JSX.Element}
 */
const Inspections = ({ value, onChange = () => {} }) => {
  const handleChange = (index) => (v) => {
    let result = [...value];
    result.splice(index, 1, v);
    onChange(result);
  };

  return (
    <>
      {value.map((section, index) => {
        return (
          <Inspection
            value={section}
            key={index}
            onChange={handleChange(index)}
          />
        );
      })}
    </>
  );
};

const defaultValues = {
  personInChargeName: null,
  checkedOn: null,
  remarks: null,
  files: [],
  sections: [],
};

/**
 * 車輛点検を入力するフォームコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} value 値
 * @param {string} title タイトル
 * @param {boolean} open 開閉状態
 * @param {boolean} disabled 無効設定
 * @fires VehicleInspectionForm#onSubmit 送信時
 * @fires VehicleInspectionForm#onClose 閉じる時
 * @fires VehicleInspectionForm#onError エラー時
 * @returns {JSX.Element}
 */
export const VehicleInspectionEditForm = ({
  value,
  title = "",
  open = false,
  onClose = () => {},
  disabled = false,
  onSubmit = () => {},
  onError = () => {},
}) => {
  const { trigger, reset, watch, formState, control } = useForm({
    mode: "onBlur",
    resolver: yupResolver(basicSchema),
    defaultValues: {
      ...defaultValues,
      ...value,
    },
  });

  useEffect(() => {
    if (open) {
      reset({
        ...defaultValues,
        ...value,
        sections:
          value?.sections?.map((section) => ({
            ...section,
            items:
              section?.items?.map((item) => ({
                ...item,
              })) ?? [],
          })) ?? [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, open, JSON.stringify(value)]);

  const handleClickRegister = async () => {
    const result = await trigger();
    if (result) {
      onSubmit &&
        onSubmit({
          ...watch(),
        });
    } else {
      onError && onError(formState.errors);
    }
  };

  return (
    <>
      <FullScreenDialog
        title={title}
        textConfirm={"保存"}
        open={open}
        onClose={onClose}
        onClickSubmit={handleClickRegister}
        disabled={disabled}
      >
        <Container>
          <Stack spacing={2}>
            <Paper
              sx={{
                p: 3,
              }}
            >
              <Stack spacing={2}>
                <Box>
                  <Controller
                    name={`personInChargeName`}
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <TextField
                        label="点検者"
                        required={true}
                        placeholder="点検者を入力します。"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                        disabled={disabled}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    name={`checkedOn`}
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        label="点検日"
                        required={true}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                        disabled={disabled}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    name={`remarks`}
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <TextField
                        label="備考"
                        required={false}
                        placeholder="特記事項があれば入力します。"
                        value={value}
                        onChange={onChange}
                        error={error}
                        rows={3}
                        multiline={true}
                        disabled={disabled}
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    name="files"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <Uploader
                          disabled={disabled}
                          fullWidth={true}
                          maxSize={26214400}
                          onChange={(files) => {
                            onChange([...(value ?? []), ...files]);
                          }}
                          dropzoneText="最大ファイルサイズ:25MB、ファイル形式:JPEG、PDF"
                          accept={[".pdf", ".jpg", ".jpeg"]}
                        />
                        <FileGallery
                          disabled={disabled}
                          value={value}
                          onChange={onChange}
                        />
                      </>
                    )}
                  />
                </Box>
              </Stack>
            </Paper>
            <Controller
              name={`sections`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => <Inspections value={value} onChange={onChange} />}
            />
          </Stack>
        </Container>
      </FullScreenDialog>
    </>
  );
};
