import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 事業場の選択を表示するコンポーネントです。
 * @param {object} value 現在の事業場のデータです。
 * @param {func} onGetSelected 選択された事業場のデータが更新する
 */
export const WorkplaceTable = containerPresententer(Container, Presententer);
