import { containerPresententer, withForwardRef } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * コンテナ設置フォームを表示するコンポーネントです。
 */
export const InstallationForm = withForwardRef(
  containerPresententer(Container, Presententer)
);
