import {
  removeClient,
  removeWorkplace,
  updateClient,
} from "api/graphql/mutations";
import {
  getClient,
  searchWorkplaceIncludingClientsByOffset,
} from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { toggle } from "ducks/Loading";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { debugLog } from "utils/log";
import { useMutation } from "utils/useMutation";

/**
 * マスタ管理の取引先の表示を行うコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {props} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const [value, setValue] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const removeWorkplaces = async (belongInCompanyId) => {
    if (!belongInCompanyId) {
      return;
    }

    const workplaces = (
      await API.graphql(
        graphqlOperation(searchWorkplaceIncludingClientsByOffset, {
          belongInCompanyId: belongInCompanyId,
        })
      )
    )?.data?.searchWorkplaceIncludingClientsByOffset?.items;

    for (const workplace of workplaces.filter(
      (workplace) => workplace.state !== "removed"
    )) {
      await API.graphql(
        graphqlOperation(removeWorkplace, { input: { id: workplace.id } })
      );
    }
  };

  const [removeMutation, removeMutationStatus] = useMutation(removeClient, {
    onCompleted: () => {
      removeWorkplaces(value?.companyId).then(() => {
        navigate(
          {
            pathname: `/master/partner`,
          },
          {
            replace: true,
          }
        );
      });
    },
    succeedMessage: "削除しました。",
    errorMessage: "エラーが発生したため、削除できませんでした。",
  });

  const load = useCallback(() => {
    dispatch(toggle(true));
    API.graphql(
      graphqlOperation(getClient, {
        id: id,
      })
    )
      .then((res) => {
        if (!!res.data.getClient) {
          const { establishmentedOn, ...other } = res.data.getClient;
          setValue({
            establishmentedOn: establishmentedOn
              ? establishmentedOn
              : undefined,
            ...other,
          });
        } else {
          navigate(
            {
              pathname: `/NotFound`,
            },
            {
              replace: true,
            }
          );
        }
      })
      .catch((err) => {
        debugLog(err);
        navigate(
          {
            pathname: `/NotFound`,
          },
          {
            replace: true,
          }
        );
      })
      .finally(() => {
        dispatch(toggle(false));
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    load();
  }, [load]);

  const callUpdateClient = (data) => {
    const {
      companyId,
      categories,
      establishmentedOn,
      headOffice,
      headOfficeWorkplace,
      createdAt,
      updatedAt,
      version,
      profileVersion,
      state,
      url,
      ...other
    } = data;

    return API.graphql(
      graphqlOperation(updateClient, {
        input: {
          categoryIds: categories.map((category) => category.id),
          establishmentedOn: establishmentedOn
            ? DateTime.fromJSDate(establishmentedOn).toFormat("yyyy-MM-dd")
            : null,
          headOfficeWorkplaceId: headOfficeWorkplace?.id ?? headOfficeWorkplace?.[0]?.id,
          url: url ? url : null,
          expectedVersion: version,
          expectedProfileVersion: profileVersion,
          ...other,
        },
      })
    ).then((res) => {
      load();
    });
  };

  const handleDelete = () => {
    removeMutation({
      input: {
        id: id,
      },
    });
  };

  return render({
    value: value,
    submit: callUpdateClient,
    load: load,
    onDelete: handleDelete,
    loading: removeMutationStatus.loading,
    ...props,
  });
};
