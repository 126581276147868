import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { debugLog } from "utils/log";
import { emptyStringWhenNull, parseNumber } from "utils/format";
import { useStyles } from "./styles";
import { basicSchema } from "./Validations";

// todo: 処理フローのidが取れるか？
// todo: 値がnullになっている原因がここにあるかもしれない

/**
 * 送信データを作成します。
 * @param {object} props プロパティ
 * @param {string} props.locationId 場所ID
 * @param {object} props.passedRecord 送信データ
 * @param {object[]} props.newTasks 新しいタスク
 * @returns {object} 送信データ
 */
const createSubmitData = ({ locationId, passedRecord, newTasks }) => {
  return {
    locationId: locationId,
    recordTasks: newTasks?.map((newTask) => ({
      taskId: newTask?.id,
      wasteFlowId: newTask?.record?.wasteFlowId,
      quantity:
        parseNumber(emptyStringWhenNull(newTask?.record?.quantity)) ?? 0,
      quantityUnitCode: newTask?.waste?.quantityUnit?.code,
      weightOfKg: newTask?.record?.weightOfKg,
      remarks: newTask?.record?.remarks,
      recordVersion: newTask?.record?.version,
    })),
    passedRecord: passedRecord,
  };
};

/**
 * 回収詳細フォームを表示するコンテナコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onClose = debugLog,
  onSubmit = debugLog,
  onError = (err) => debugLog(err),
  number = 0,
  loading = false,
  ...props
}) => {
  const classes = useStyles();
  const [emptyWhenZero, setEmptyWhenZero] = useState(true);
  const {
    control,
    setValue,
    getValues,
    watch,
    reset,
    trigger,
    formState,
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      tasks: value?.tasks?.map((task) => task) ?? [],
      emptyWhenZero: emptyWhenZero,
    },
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    reset({
      tasks: value?.tasks?.map((task) => task) ?? [],
    });
  }, [reset, value]);

  useEffect(() => {
    setValue("emptyWhenZero", emptyWhenZero, { shouldDirty: true });
  }, [setValue, emptyWhenZero]);

  const isInvalid = useMemo(() => {
    let result = watch()?.tasks?.filter(
      (task) => !task || !task?.record?.wasteFlowId
    );

    if (emptyWhenZero) {
      return result.length > 0;
    } else {
      return result.filter((task) => !task?.record?.quantity).length > 0;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()?.tasks, emptyWhenZero]);

  useImperativeHandle(props._ref, () => ({
    submit: handleConfirmed,
    get: async () => {
      // バリデーションを実行した後エラーを消す。
      const result = await trigger();
      clearErrors();
      if (result) {
        const formData = getValues();
        return createSubmitData({
          locationId: value?.id,
          passedRecord: value?.passedRecord,
          newTasks: formData?.tasks,
        });
      } else {
        return null;
      }
    },
  }));

  const handleChangeEmptyWhenZero = () => {
    setEmptyWhenZero(!emptyWhenZero);
  };

  const handleChange = (index, data) => {
    const result = getValues("tasks") ? [...getValues("tasks")] : [];
    result.splice(index, 1, data);
    setValue("tasks", result);
  };

  const handleConfirmed = async () => {
    const result = await trigger();

    if (result) {
      const formData = getValues();

      if (!formData) {
        return;
      }
      onSubmit &&
        onSubmit(
          createSubmitData({
            locationId: value.id,
            passedRecord: value.passedRecord,
            newTasks: formData.tasks,
          }),
          number
        );
    } else {
      onError && onError(formState.errors);
    }
  };

  return render({
    classes: classes,
    value: value,
    emptyWhenZero: emptyWhenZero,
    onChangeEmptyWhenZero: handleChangeEmptyWhenZero,
    number: number,
    onClose: onClose,
    control: control,
    onChange: handleChange,
    onConfirmed: handleConfirmed,
    loading: loading,
    isInvalid: isInvalid,
    ...props,
  });
};
