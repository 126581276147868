import { debugLog } from "utils/log";
import { cycleCategoriesSelector } from "ducks/CycleCategories";
import { useSelector } from "react-redux";
import { Week, DayOfWeek } from "utils/Context";

/*
object shape
  指定週0
    interval: {}
    weeks: []
    dayOfWeeks: []
   
  毎週1
    interval: {}
    dayOfWeeks: []

  毎日2
    interval: {}
*/

/**
 * 回収頻度を入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @fires Container#onChange 変更時
 * @param {bool} error エラーであるか
 * @param {string} helperText 補助的テキスト
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onChange = (data) => debugLog(data),
  error,
  helperText,
  ...props
}) => {
  const cycleCategories = useSelector(cycleCategoriesSelector);
  const handleChangeInterval = (e) => {
    let result = { ...value };

    result.category = e.target.value;

    // 毎月
    if (e.target.value?.id === "everyMonth") {
      result.weeks = undefined;
      result.weekDays = undefined;
    }

    // 毎週
    if (e.target.value?.id === "everyWeek") {
      result.weekDays = undefined;
    }

    if (!e.target.value?.id) {
      result.weeks = undefined;
      result.weekDays = undefined;
    }

    onChange(result);
  };

  const handleChangeWeeks = (e) => {
    onChange({
      ...value,
      weeks: e.target.value,
    });
  };

  const handleChangeDayOfWeeks = (e) => {
    onChange({
      ...value,
      weekDays: e.target.value,
    });
  };

  const handleChangeCollectionDaysInverval = (e) => {
    let noOfDays = e.target.value;
    if (noOfDays < 0) {
      e.target.value = "";
      return;
    }
    onChange({
      ...value,
      numberOfDays: noOfDays,
    });
  };

  return render({
    value: value,
    onChangeInterval: handleChangeInterval,
    onChangeWeeks: handleChangeWeeks,
    onChangeDayOfWeeks: handleChangeDayOfWeeks,
    onChnageCollectionDaysInverval: handleChangeCollectionDaysInverval,
    cycleCategories: cycleCategories,
    week: Week,
    dayOfWeek: DayOfWeek.map((item) => {
      return {
        code: item.code,
        name: item.shortName,
      };
    }),
    error: error,
    helperText: helperText,
    ...props,
  });
};
