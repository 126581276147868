import { ReportProblem } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";

/**
 * 配車計画が無いことを知らせるプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box sx={{ textAlign: "center", paddingTop: 4, paddingBottom: 6 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: (theme) => theme.palette.secondary.main,
          marginBottom: 4,
        }}
      >
        <ReportProblem sx={{ marginRight: 2 }} fontSize="large" />
        配車計画が作成されていません。
      </Box>
      <span>
        配車計画を作成するとルールに従って自動で配車表が作成されます。
      </span>
    </Box>
  );
};
