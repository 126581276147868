import { Box, Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import {
  ClientWorkplaceSelector,
  InputField,
  InputFieldPopppSelect,
  ModalPopup,
} from "views/molecules";
import { WasteSelector } from "../../../WasteSelector";

/**
 * 基本情報を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Elemnt}
 */
export const Presententer = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          name={"dischargesWorkplace"}
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <InputFieldPopppSelect
                label={"排出事業場"}
                value={
                  value
                    ? {
                        id: value.id,
                        name: `${value.belongInCompanyName} ${value.name}`,
                      }
                    : null
                }
                onChange={onChange}
                required={true}
                placeholder={"排出事業場を選択します。"}
                error={error}
                onOpenModal={props.onOpenModal("clientWorkplace")}
                textConfirm="選択"
              />
              <ModalPopup
                open={props.open === "clientWorkplace"}
                onClose={props.onCloseModal}
              >
                <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                  <ClientWorkplaceSelector
                    autoHeight={false}
                    value={props.selecedValue}
                    onSelected={props.onSelectedClientWorkplace}
                    fixedOptions={{
                      filter: {
                        and: [
                          {
                            categoryId: {
                              eq: "dischargeSite",
                            },
                          },
                        ],
                      },
                    }}
                  />
                </Box>
              </ModalPopup>
            </>
          )}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <Controller
          name={"transporter"}
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <InputFieldPopppSelect
                label={"収集運搬業者"}
                value={value}
                onChange={onChange}
                required={true}
                placeholder={"収集運搬業者を選択します。"}
                error={error}
                onOpenModal={props.onOpenModal("client")}
                textConfirm="選択"
              />
              <ModalPopup
                open={props.open === "client"}
                onClose={props.onCloseModal}
              >
                <Box pt={3} pb={3}>
                  <ClientSelector
                    value={props.selectedValue}
                    onSelected={props.onSelectedClient}
                    add={false}
                    // UNDONE: apiに事業場区分が追加されたら対応する
                    // fixedOptions={{
                    //   filter: {
                    //     categories: { eq: ComanyCategories.filter(item => item.name === "収集運搬業者").map(item => item.code) }
                    //   }
                    // }}
                  />
                </Box>
              </ModalPopup>
            </>
          )}
        />
      </Grid> */}
      <Grid item xs={12}>
        <Controller
          name={"wastes"}
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <InputFieldPopppSelect
                label={"廃棄物"}
                value={
                  value
                    ? {
                        id: value.id,
                        name:
                          (value?.type?.smallClass?.name ||
                            value?.type?.middleClass?.name ||
                            value?.type?.largeClass?.name ||
                            "") +
                          (value?.name !== "" ? `(${value?.name})` : ""),
                      }
                    : ""
                }
                onChange={onChange}
                required={true}
                placeholder={"事業場から排出される廃棄物を選択します。"}
                error={error}
                onOpenModal={props.onOpenModal("wastes")}
                textConfirm="選択"
                disabledButtom={!props.dischargeSourceWorkplaceId}
              />
              <ModalPopup
                open={props.open === "wastes"}
                onClose={props.onCloseModal}
              >
                <Box pt={3} pb={3} sx={{ maxHeight: "85vh" }}>
                  <WasteSelector
                    dischargeSourceWorkplaceId={
                      props.dischargeSourceWorkplaceId
                    }
                    onSelected={props.onChangeWastes}
                    value={value}
                  />
                </Box>
              </ModalPopup>
            </>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="remark"
          control={props.control}
          render={({ field: { value, onChange } }) => (
            <InputField
              label="備考"
              placeholder="特記事項があれば入力します。"
              multiline
              rows={4}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
