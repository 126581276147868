import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { styles } from "./styles";
import { basicScheme } from "./Validations";

const defaultValues = {
  waste: undefined,
  quantity: undefined,
  remark: "",
  files: undefined,
};

/**
 * 廃棄物を追加するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @fires Container#onSubmit 送信時
 * @fires Container#onError エラー時
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  dischargeSourceWorkplaceId,
  value,
  onSubmit = (data) => debugLog(data),
  onError = (err) => debugLog(err),
  ...props
}) => {
  const classes = useClasses(styles);
  const [open, setOpen] = useState(false);

  const {
    control,
    setValue,
    getValues,
    watch,
    clearErrors,
    reset,
    trigger,
    formState,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(basicScheme),
  });

  useEffect(() => {
    reset({
      ...defaultValues,
      ...value,
    });
  }, [reset, value]);

  const handleChangeItemSelect = (e) => {
    setOpen(false);
    setValue("waste", e?.value?.[0]);
    clearErrors("waste");
  };

  const handleAddImages = (files) => {
    const result = [...(getValues("files") ?? []), ...files];
    setValue("files", result);
  };

  const handleDeleteImage = (index) => {
    const result = [...getValues("files")];
    result.splice(index, 1);
    setValue("files", result);
  };

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      trigger().then((res) => {
        if (res) {
          onSubmit && onSubmit(getValues());
        } else {
          onError(formState.errors);
        }
      });
    },
  }));

  return render({
    ...props,
    dischargeSourceWorkplaceId: dischargeSourceWorkplaceId,
    classes: classes,
    control: control,
    getSelected: handleChangeItemSelect,
    open: open,
    onCloseModal: () => setOpen(false),
    onOpenModal: () => setOpen(true),
    onAddImages: handleAddImages,
    onDeleteImage: handleDeleteImage,
    waste: watch("waste")
  });
};
