import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { fetchIndustrySectors } from "./api";

/*
object shape
  array
    id
    middleClassCode
    name
*/

const industrySectorsSlice = createSlice({
  name: "industrySectors",
  initialState: {
    industrySectors: [],
    isLoading: false,
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIndustrySectors.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchIndustrySectors.fulfilled, (state, action) => {
        state.industrySectors = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchIndustrySectors.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

/**
 * ストアから業種全体を取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.IndustrySectors;

/**
 * ストアから通知情報を取得します。
 */
export const industrySectorsSelector = createSelector(
  selector,
  (industrySectors) => industrySectors.industrySectors
);

/**
 * ストアから読み込みを取得します。
 */
export const isLoadingSelector = createSelector(
  selector,
  (industrySectors) => industrySectors.isLoading
);

/**
 * ストアからエラーを取得します。
 */
export const errorSelector = createSelector(
  selector,
  (industrySectors) => industrySectors.error
);

export default industrySectorsSlice.reducer;
