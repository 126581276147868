import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  onlineIndicatorContainer: {
    position: "relative",
  },
  onlineIndicator: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "12px",
    height: "12px",
    backgroundColor: "red",
    borderRadius: "50%",
  },
}));
