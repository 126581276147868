import React, { useEffect, useRef } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { AutoSizer } from "react-virtualized";
import { Skeleton } from "@mui/material";
import InfiniteLoader from "react-window-infinite-loader";
import { areEqual, FixedSizeList } from "react-window";
import { CollectPoint } from "../CollectPoint";

/**
 * ドラッグされている時の行を表示するメソッドです。
 * @param {object[]} items 行リスト
 * @param {number} displayNumber 番号を表示するか
 * @returns {func}
 */
const RowClone = (items, loading) => (provided, snapshot, rubric) => {
  if (!items || !items[rubric.source.index]) {
    return null;
  }

  let item = items[rubric.source.index];
  item?.order && (item.order = null);

  return (
    <div
      style={provided.draggableProps.style}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
    >
      {!item && loading && (
        <Skeleton
          variant="rectangular"
          height={142}
          sx={{
            marginTop: 2,
            marginRight: 1,
            borderRadius: 1,
          }}
          animation="wave"
        />
      )}
      {item && <CollectPoint value={items[rubric.source.index]} />}
    </div>
  );
};

/**
 * 行を表示するメソッドです。
 * @returns {func}
 */
const Row = React.memo(({ data, index, style }) => {
  let item = data.items[index];
  item?.order && (item.order = null);

  return (
    <Draggable
      draggableId={item?.cycleId || index.toString()}
      index={index}
      key={item?.cycleId || index}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            ...style,
          }}
        >
          {!item && data.loading && (
            <Skeleton
              variant="rectangular"
              height={142}
              sx={{
                marginTop: 2,
                marginRight: 1,
                borderRadius: 1,
              }}
              animation="wave"
            />
          )}
          {item && <CollectPoint value={item} />}
        </div>
      )}
    </Draggable>
  );
}, areEqual);

/**
 * 未配車の回収スケジュールを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  const infiniteLoaderRef = useRef(null);
  const hasMountedRef = useRef(false);

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index) => {
    if (props.loading) {
      return false;
    }
    return !props.hasNextPage || index < props.value.length;
  };

  const itemCount = props.hasNextPage
    ? props.value.length + 1
    : props.value.length;

  useEffect(() => {
    if (hasMountedRef.current && infiniteLoaderRef.current) {
      infiniteLoaderRef.current.resetloadMoreItemsCache(true);
    }
    hasMountedRef.current = true;
  }, [props.query]);

  return (
    <Droppable
      droppableId={props.droppableId}
      renderClone={RowClone(props.value, props.loading)}
      mode="virtual"
    >
      {(provided, snapshot) => (
        <AutoSizer>
          {({ width, height }) => (
            <InfiniteLoader
              ref={infiniteLoaderRef}
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={props.loading ? () => {} : props.handleLoadMore}
              threshold={10}
            >
              {({ onItemsRendered, ref }) => (
                <FixedSizeList
                  height={height - (props.subtractHeight || 0)}
                  width={width}
                  itemCount={itemCount}
                  itemData={{
                    items: props.value,
                    loading: props.loading,
                  }}
                  ref={ref}
                  itemKey={(columnIndex, rows) => {
                    const row = rows.items[columnIndex];
                    if (!row) {
                      return columnIndex;
                    }
                    const id = row.acceptingOrderId + row.id || columnIndex;
                    return id;
                  }}
                  itemSize={145}
                  outerRef={provided.innerRef}
                  className={props.classes.lists}
                  onItemsRendered={onItemsRendered}
                >
                  {Row}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      )}
    </Droppable>
  );
};
