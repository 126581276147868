import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
} from "@mui/material";
import { Controller, FormProvider } from "react-hook-form";
import {
  AddressFromPostCode,
  FieldDecorateFrame,
  IndustrySelector,
  InputField,
  SelectPopup,
} from "views/molecules";

/**
 * 新しい事業場を登録するフォームを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Elemnt}
 */
export const Presententer = (props) => {
  return (
    <FormProvider {...props.methods}>
      <form id={props.id} onSubmit={props.submit} noValidate>
        <Container maxWidth="md">
          <Paper elevation={3}>
            <div className={props.classes.form_addNew}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="code"
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <InputField
                        label="固有コード"
                        placeholder="事業場を識別するコードを入力します。"
                        value={value}
                        onChange={onChange}
                        error={error}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="categories"
                    control={props.control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <SelectPopup
                          label="事業場区分"
                          required={true}
                          value={value}
                          onChange={onChange}
                          options={props.workplaceCategories}
                          error={error}
                          multiple={true}
                          optionValueField="id"
                          optionNameField="name"
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="industrySector"
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <FieldDecorateFrame label="業種" required={false}>
                          {(params) => (
                            <IndustrySelector
                              label=""
                              value={value ?? ""}
                              onChange={onChange}
                              error={Boolean(error)}
                              helperText={error?.message}
                            />
                          )}
                        </FieldDecorateFrame>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="name"
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <InputField
                        label="名称"
                        required={true}
                        placeholder="事業場の名称を入力します。"
                        value={value}
                        onChange={onChange}
                        error={error}
                      />
                    )}
                  />
                </Grid>
                <AddressFromPostCode />
                <Grid item xs={12}>
                  <Controller
                    name="phone"
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <InputField
                        label="電話番号"
                        placeholder="例）000-0000-0000"
                        value={value}
                        onChange={onChange}
                        classOverride={props.classes.phone}
                        error={error}
                        inputProps={{
                          maxLength: 15,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="fax"
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <InputField
                        label="FAX番号"
                        placeholder="例）000-0000-0000"
                        value={value}
                        onChange={onChange}
                        classOverride={props.classes.phone}
                        error={error}
                        inputProps={{
                          maxLength: 15,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <InputField
                        label="メールアドレス"
                        placeholder="例）info@ecope-pro.co.jp"
                        value={value}
                        onChange={onChange}
                        error={error}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="remarks"
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <InputField
                        label="特記事項"
                        placeholder="特記事項を入力します。"
                        multiline
                        rows={4}
                        value={value}
                        onChange={onChange}
                        error={error}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Container>
        <Dialog
          open={props.isOpenConfirmDialog}
          onClose={props.closeConfirmDialog}
        >
          <DialogTitle>確認</DialogTitle>
          <DialogContent>
            <DialogContentText>
              入力された住所から位置情報が取得できません。
              このまま続行すると位置情報を利用する一部サービスで使用できない場合があります。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.closeConfirmDialog}>キャンセル</Button>
            <Button onClick={props.forceSubmit} autoFocus>
              続行
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  );
};
