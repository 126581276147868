import { Container, Grid, InputAdornment, Paper, Box } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { FreeSolo } from "views/atoms";
import {
  DatePicker,
  FieldDecorateFrame,
  InputField,
  InputFieldPopppSelect,
  ModalPopup,
  WorkplaceSelector,
} from "views/molecules";

/**
 * 新しい事業場を登録するフォームを表示するコンポーネントです。
 * @param {object} props ポロパティ
 * @param {object} control　reactのcontrol
 * @param {array} truckType 車種
 * @param {boolean} open モーダルオーブン
 * @param {func} onOpenModal モーダルを開く
 * @param {func} onCloseModal モーダルを閉じる
 * @returns {JSX.Elemnt} JSX 要素
 */
export const Presententer = (props) => {
  return (
    <>
      <form id={props.id} onSubmit={props.submit} noValidate>
        <Container maxWidth="md">
          <Paper elevation={3}>
            <div className={props.classes.form_addNew}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="number"
                    control={props.control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <InputField
                        label="車輛ナンバー"
                        placeholder="例）福岡999た2325"
                        value={value}
                        required={true}
                        onChange={onChange}
                        error={error}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="modelName"
                    control={props.control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <FieldDecorateFrame label="車種" required={true}>
                        {(params) => (
                          <FreeSolo
                            value={value}
                            placeholder="例）塵芥車"
                            onChange={onChange}
                            error={Boolean(error)}
                            helperText={error?.message}
                            options={props.modelOfVehicles.map(
                              (option) => option.name
                            )}
                            required={params.required}
                          />
                        )}
                      </FieldDecorateFrame>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="parkingCertificateOwnerWorkplace"
                    control={props.control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <InputFieldPopppSelect
                        label="保管場所"
                        required={true}
                        value={value}
                        onChange={onChange}
                        onOpenModal={props.onOpenModal}
                        disabledButtom={props.disableSelectOffice}
                        placeholder="未選択"
                        error={Boolean(error?.message)}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="loadCapacity"
                    control={props.control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <InputField
                        label="最大積載量"
                        required={true}
                        placeholder="例）3250"
                        value={value}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">kg</InputAdornment>
                          ),
                        }}
                        error={error}
                        classOverride={props.classes.maxWeightField}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="totalWeight"
                    control={props.control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <InputField
                        label="車輛総重量"
                        required={true}
                        placeholder="例）7985"
                        value={value}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">kg</InputAdornment>
                          ),
                        }}
                        error={error}
                        classOverride={props.classes.weightField}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="ridingCapacity"
                    control={props.control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <InputField
                        label="乗車定員"
                        required={true}
                        placeholder="例）2"
                        value={value}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">人</InputAdornment>
                          ),
                        }}
                        error={error}
                        classOverride={props.classes.ridingField}
                      />
                    )}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <Controller
                    name="driversLicense"
                    control={props.control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <SelectPopup
                          label="運転免許区分"
                          required={true}
                          value={value}
                          onChange={onChange}
                          options={props.driversLicenses}
                          error={error}
                          optionValueField="code"
                          optionNameField="name"
                        />
                      );
                    }}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <Controller
                    name="purchasedOn"
                    control={props.control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        label="車輛購入年月日"
                        placeholder="例）2021/06/06"
                        value={value}
                        required={false}
                        onChange={onChange}
                        error={error}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="firstYearRegistrationOn"
                    control={props.control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        label="車輛初年度登録日"
                        placeholder="例）2021/06/06"
                        value={value}
                        required={false}
                        onChange={onChange}
                        error={error}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="remarks"
                    control={props.control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <InputField
                        label="備考"
                        placeholder="備考を入力します。"
                        multiline
                        rows={4}
                        value={value}
                        onChange={onChange}
                        error={error}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Container>
      </form>
      <ModalPopup open={props.open} onClose={props.onCloseModal}>
        <Box pt={3} pb={3} sx={{ height: "85vh" }}>
          <WorkplaceSelector
            companyId={props.companyId}
            value={props.value?.parkingCertificateOwnerWorkplace}
            title="車輛証明事業場の選択"
            onChange={props.getCertificationOffice}
            visibleCompanyName={false}
          />
        </Box>
      </ModalPopup>
    </>
  );
};
