import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
export const AlertDialog = ({
  children,
  open,
  handleClose,
  sx,
  maxWidth,
  fullWidth,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={sx}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby=""
      aria-describedby=""
    >
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

AlertDialog.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  sx: PropTypes.object,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
};
