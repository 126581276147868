import {
  DepartureBoardOutlined,
  PermDeviceInformationOutlined
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import React from "react";
import { RenderMenuSelectItem, StepGuider } from "views/atoms";

/**
 * 受注メニューを選択するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: 0 }}>
        <Grid item>
          <RenderMenuSelectItem
            icon={PermDeviceInformationOutlined}
            label="スポット回収"
            className="orderSpot"
            onClick={() => props.onClick("spot")}
          />
        </Grid>
        <Grid item>
          <RenderMenuSelectItem
            icon={DepartureBoardOutlined}
            label="定期回収"
            className="orderRegularly"
            onClick={() => props.onClick("regularly")}
          />
        </Grid>
      </Grid>
      <StepGuider value={props.GuiderSteps} />
    </>
  );
};
