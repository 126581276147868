import { yupResolver } from "@hookform/resolvers/yup";
import { useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { styles } from "./styles";
import { basicScheme } from "./Validations/basic";

const defaultValues = {
  frequency: undefined,
  times: { start: null, end: null },
  cycleStartDate: undefined,
  remarks: "",
};

/**
 * 回収サイクルを追加するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @fires Container#onSubmit 送信時
 * @fires Container#onError エラー時
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onSubmit = (data) => debugLog(data),
  onError = (err) => debugLog(err),
  ...props
}) => {
  const classes = useClasses(styles);
  const { control, getValues, trigger, formState, clearErrors } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(basicScheme),
    defaultValues: {
      ...defaultValues,
      ...value,
    },
  });

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      trigger().then((res) => {
        if (res) {
          onSubmit && onSubmit(getValues());
        } else {
          onError(formState.errors);
        }
      });
    },
  }));

  return render({
    classes: classes,
    control: control,
    clearErrors: clearErrors,
    ...props,
  });
};
