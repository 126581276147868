import { useNavigate } from "react-router-dom";
import { useState } from "react";

/**
 * コンテナ管理の種類詳細 - 保管情報を表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  id,
  numberOfInstallations,
  numberOfStorage,
  totalOfOwned,
  value,
  onClick,
  ...props
}) => {
  const [open, setOpen] = useState();
  const navigate = useNavigate();

  const handleSelected = (params) => {
    navigate(`/master/container/category/list/${id}/product/${params[0]}`);
  };

  return render({
    ...props,
    onSelected: handleSelected,
    value: value,
    toggleDialog: (v) => setOpen(v),
    open: open,
    onClick: onClick,
    numberOfInstallations: numberOfInstallations,
    numberOfStorage: numberOfStorage,
    totalOfOwned: totalOfOwned,
  });
};
