import { XGridComponent } from "views/molecules";

/**
 * 定期点検一覧を表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = ({ xGridParams, onSelected }) => {
  return (
    <XGridComponent
      onChangeSelection={onSelected}
      checkboxSelection={false}
      multipleSelection={false}
      onDownload={true}
      {...xGridParams}
    />
  );
};
