import { updateVehicle } from "api/graphql/mutations";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { DateTime } from "luxon";
import { useState } from "react";
import { useDispatch } from "react-redux";

/**
 * 配車タブ詳細の表示を行うコンテナコンポーネントです。
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleSelected = (e) => {
    setSelectedItem(e?.value?.[0]);
    setOpen(true);
  };

  const handleSubmit = (data) => {
    const {
      certificationOffice,
      modelOfVehicles,
      purchasedOn,
      firstYearRegistrationOn,
      createdAt,
      updatedAt,
      version,
      ...other
    } = data;
    setIsLoading(true);
    API.graphql(
      graphqlOperation(updateVehicle, {
        input: {
          ...other,
          modelOfVehicleId: modelOfVehicles.id,
          parkingCertificateOwnerWorkplaceId: certificationOffice?.id ?? null,
          purchasedOn: DateTime.fromJSDate(new Date(purchasedOn)).toFormat(
            "yyyy-MM-dd"
          ),
          firstYearRegistrationOn: DateTime.fromJSDate(
            new Date(firstYearRegistrationOn)
          ).toFormat("yyyy-MM-dd"),
          expectedVersion: version,
        },
      })
    )
      .then((res) => {
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        setOpen(false);
      })
      .catch((err) => {
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return render({
    value: value,
    onSelected: handleSelected,
    open: open,
    onCloseDialog: () => setOpen(false),
    selectedItem: selectedItem,
    submit: handleSubmit,
    isLoading: isLoading,
    ...props,
  });
};
