import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { DateTime } from "luxon";
import { searchCarryWorkJournalsByOffset } from "api/graphql/queries";
import { toggle } from "ducks/Loading";
import { selector, set } from "ducks/Date";
import { debugLog } from "utils/log";

const getWasteFullName = (waste) => {
  if (waste == null) {
    return "";
  }
  const format = (wasteClassName) =>
    !waste.name ? wasteClassName : `[${wasteClassName}] ${waste.name}`.trim();
  const { largeClass, middleClass, smallClass } = waste.type;
  return smallClass.name !== ""
    ? format(smallClass.name)
    : middleClass.name !== ""
    ? format(middleClass.name)
    : format(largeClass.name);
};

const getOdometers = ({ instruments }, isHidden = false) => {
  if (
    !!instruments &&
    !!instruments.find((instrument) => instrument.type === "Start")?.odometer &&
    !!instruments.find((instrument) => instrument.type === "Finish")?.odometer
  ) {
    if (isHidden) {
      return `${
        instruments.find((instrument) => instrument.type === "Finish")
          .odometer -
        instruments.find((instrument) => instrument.type === "Start").odometer
      }km`;
    }
    return `  [走行距離：${
      instruments.find((instrument) => instrument.type === "Finish").odometer -
      instruments.find((instrument) => instrument.type === "Start").odometer
    }Km]`;
  }

  return "";
};

const gridColumns = [
  { field: "courseName", headerName: "コース名", width: 90, hide: true },
  { field: "departureDate", headerName: "日付", width: 90, hide: true },
  { field: "assignedUsers", headerName: "ドライバー名", width: 90, hide: true },
  {
    field: "vehicleNo",
    headerName: "車輌 ",
    width: 90,
    hide: true,
  },
  {
    field: "pointNo",
    headerName: "回収番号",
    width: 100,
  },
  {
    field: "discharger",
    headerName: "排出事業者",
    width: 200,
  },
  {
    field: "dischargeSite",
    headerName: "排出事業場",
    width: 200,
  },
  {
    field: "waste",
    headerName: "廃棄物",
    width: 200,
  },
  {
    field: "recordQuantity",
    headerName: "回収記録数量",
    width: 110,
  },
  {
    field: "quantityInKg",
    headerName: "回収量(kg)",
    width: 110,
  },
  {
    field: "recordRemarks",
    headerName: "回収記録備考",
    width: 110,
    valueFormatter: (params) => {
      return params.value ? params.value?.split("\n").join(" ") : "";
    },
  },
  {
    field: "recordedAt",
    headerName: "回収記録日時",
    width: 140,
    valueFormatter: (params) => {
      return params.value
        ? DateTime.fromISO(params.value, { setZone: "Asia/Tokyo" }).toFormat(
            "yyyy/MM/dd HH:mm"
          )
        : "";
    },
  },
  {
    field: "updatedAt",
    headerName: "最終更新日時",
    width: 140,
    valueFormatter: (params) => {
      return params.value
        ? DateTime.fromISO(params.value, { setZone: "Asia/Tokyo" }).toFormat(
            "yyyy/MM/dd HH:mm"
          )
        : "";
    },
  },
  {
    field: "mileage",
    headerName: "走行距離",
    width: 140,
  },
  {
    field: "address",
    headerName: "住所",
    width: 500,
  },
];

/**
 * 回収記録表一覧を表示するコンテナコンポーネントです。
 * @param {object} props プロパティ
 * @callback render
 * @returns
 */
export const Container = ({ render, ...props }) => {
  const [journals, setJournals] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mobileDate, setMobileDate] = useState(DateTime.now());
  const [noteId, setNoteId] = useState(null);
  const dateObj = useSelector(selector);
  const [date, setLocalDate] = useState(
    DateTime.fromISO(dateObj.date, { zone: "Asia/Tokyo" })
  );
  const [isLoading, setIsLoading] = useState(false);

  const setDate = (date) => {
    setLocalDate(date);
    dispatch(set(date.toFormat("yyyy-MM-dd")));
  };

  const load = useCallback(
    (date) => {
      dispatch(toggle(true));
      setJournals(null);
      API.graphql(
        graphqlOperation(searchCarryWorkJournalsByOffset, {
          filter: {
            date: { eq: date.toISODate() },
          },
        })
      )
        .then((res) => {
          setJournals(res.data.searchCarryWorkJournalsByOffset.items);
        })
        .catch((err) => {
          debugLog("searchCarryWorkJournalsByOffset.fetch.error", err);
        })
        .finally(() => {
          dispatch(toggle(false));
        });
    },
    [dispatch]
  );

  useEffect(() => {
    load(date);

    // The function inside the `return` statement is a cleanup function.
    // This function will be called when the component unmounts or when `load` or `date` changes.
    return () => {
      const path = window.location.pathname;
      // Create a regular expression to match paths of the form "/collect/:id".
      const pattern = new RegExp("^/collect/[^/]+$");
      // Test if the current path matches the pattern.
      const doesMatch = pattern.test(path);
      // If the current path is not "/collect" and does not match the pattern "/collect/:id",
      // set `date` to the current date and time
      if (path !== "/collect" && !doesMatch) {
        setDate(DateTime.now());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, date]);

  const onNoteClick = (id) => {
    setNoteId(id);
  };

  const onClose = () => {
    setNoteId(null);
  };

  const fetchJounals = (ids) => {
    let queryString = `query getJournals{`;
    ids.forEach((id, index) => {
      queryString += `journal_${index}:getCarryWorkJournal(id:"${id}"){
        id,
        name,
        departureDate,
        assignedUsers{
          name
        },
        assignedVehicle{
          number
        }, 
        instruments{
          type,
          odometer
        },
        items{
          id,
          passedRecord{
            arrivedAt,
            departedAt
          }
          workplace{
            belongInCompanyName,
            postalCode,
            prefectures{
              name
            },
            city,
            streetAddress,
            otherAddress,
            name
          },
          tasks{
            id,
            waste{
              name,
              type{
                largeClass{
                  name
                },
                middleClass{
                  name
                },
                smallClass{
                  name
                }
              }
            },
            record{
              quantity,
              quantityUnit{
                name,
              },
              weightOfKg,
              remarks,
              createdAt,
              updatedAt
            }
          }
      }
    }`;
    });
    queryString += `}`;
    return API.graphql(graphqlOperation(queryString));
  };

  const fetchData = async () => {
    setIsLoading(true);
    const journalIds = journals.map((item) => item.id);
    try {
      const response = await fetchJounals(journalIds);
      const data = response.data;
      if (data) {
        const csvData = Object.values(data).map((item) => {
          return item.items
            .flatMap((data) =>
              data.tasks.map((task) => ({
                courseName: item.name,
                departureDate: DateTime.fromFormat(
                  item.departureDate,
                  "yyyy-MM-dd"
                )
                  .setLocale("jp-JP")
                  .toFormat("yyyy/MM/dd"),
                assignedUsers:
                  item.assignedUsers?.map((item) => item.name)?.join(",") || "",
                vehicleNo: item.assignedVehicle?.number,
                discharger: data.workplace.belongInCompanyName,
                dischargeSite: data.workplace.name,
                waste: getWasteFullName(task.waste),
                recordQuantity:
                  task.record == null
                    ? ""
                    : `${task.record?.quantity}${task.record?.quantityUnit?.name}`,
                quantityInKg: task.record
                  ? task.record?.weightOfKg
                    ? task.record?.weightOfKg + "kg"
                    : null
                  : null,
                recordRemarks: task.record?.remarks ?? "",
                recordedAt: task.record?.createdAt ?? null,
                updatedAt: task.record?.updatedAt ?? null,
                mileage: getOdometers(item, true),
                address: `〒${
                  data.workplace?.postalCode +
                  " " +
                  data.workplace?.prefectures.name +
                  data.workplace?.city +
                  data.workplace?.streetAddress +
                  data.workplace?.otherAddress
                }`,
              }))
            )
            .map((item, index) => ({
              ...item,
              pointNo: index + 1,
            }));
        });
        return csvData.flat().map((item, index) => {
          return { ...item, id: index };
        });
      }
    } catch (error) {
      debugLog("getJournals.fetch.error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return render({
    journals: journals,
    date: date,
    mobileDate: mobileDate,
    onAccept: (date) => setDate(date),
    onChangeDate: (date) => setMobileDate(date),
    onNextDate: () => setDate(date.plus({ days: 1 })),
    onBeforeDate: () => setDate(date.minus({ days: 1 })),
    onSetToday: () => setDate(DateTime.now()),
    onNavigateDetails: (id) => navigate(`/collect/${id}`),
    onNoteClick,
    onClose,
    noteId,
    fetchData,
    gridColumns,
    isLoading,
    ...props,
  });
};
