import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  Stack,
  Typography,
  Chip,
  CardContent,
  IconButton,
} from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import MessageIcon from "@mui/icons-material/Message";
import PersonIcon from "@mui/icons-material/Person";
import { ProgressBar } from "views/molecules";
import { isNull } from "lodash";

/**
 * 配車計画が無いことを知らせるプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */

export const Presententer = (props) => {
  const {
    onClick,
    assignedVehicle,
    assignedUsers,
    total,
    done,
    name,
    notes,
    onNoteClick,
    showProgressBar = false,
    clickDisabled = false,
  } = props;

  const getCardContent = () => (
    <CardContent>
      <Stack spacing={1} flexGrow={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <Typography variant="h6" fontWeight={600}>
            {name}
          </Typography>
          {showProgressBar && (
            <Box
              sx={{
                display: "flex",
              }}
            >
              {notes.length ? (
                <>
                  <IconButton
                    sx={{
                      mt: "-11px",
                      p: "5px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onNoteClick();
                    }}
                  >
                    <MessageIcon color="primary" />
                  </IconButton>
                  <Typography
                    sx={{
                      mr: 1,
                      mt: "-5px",
                      fontWeight: 500,
                    }}
                    color="primary"
                  >
                    {notes.length}
                  </Typography>
                </>
              ) : null}
              <Box sx={{ width: "20vw" }}>
                <ProgressBar total={total} done={done} />
              </Box>
            </Box>
          )}
        </Stack>
        <Box direction="row" display="flex" flexWrap="wrap" gap={1} spacing={1}>
          {!showProgressBar && !assignedUsers.length && (
            <Chip
              icon={<PersonIcon />}
              label="未設定"
              color="error"
              sx={{
                mr: 1,
              }}
            />
          )}
          {assignedUsers &&
            assignedUsers?.map((user, index) => (
              <Chip
                key={index}
                icon={<PersonIcon />}
                label={user.name}
                color="success"
                sx={{
                  mr: 1,
                }}
              />
            ))}
          {assignedVehicle && (
            <Chip
              icon={<DirectionsCarIcon />}
              label={assignedVehicle?.number}
              color="info"
            />
          )}
          {!showProgressBar && isNull(assignedVehicle) && (
            <Chip icon={<DirectionsCarIcon />} label="未設定" color="error" />
          )}
        </Box>
      </Stack>
    </CardContent>
  );

  return (
    <Card>
      {clickDisabled ? (
        getCardContent()
      ) : (
        <CardActionArea onClick={onClick}>{getCardContent()}</CardActionArea>
      )}
    </Card>
  );
};
