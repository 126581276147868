import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";
/**
 * SVGの表示を行うプレゼンテーションコンポーネントです
 * @param {number} width svgの大きさ
 * @param {number} height svgの高さ
 * @param {string} viewBox 例）"0 0 184 152"
 * @returns　JSX Element
 */
export const RenderSVG = containerPresententer(Container, Presententer);
