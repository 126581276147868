import reducer from "./slice";
import {
  selector,
  contractTypesSelector,
  isLoadingSelector,
  errorSelector,
} from "./slice";
import { fetchContractTypes } from "./api";

export default reducer;
export {
  fetchContractTypes,
  selector,
  contractTypesSelector,
  isLoadingSelector,
  errorSelector,
};
