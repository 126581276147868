import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 基本情報を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 基本情報
 */
export const Basic = containerPresententer(Container, Presententer);
