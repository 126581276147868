import yup, { remarksSchema, dateSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  dischargesWorkplace: yup.object().required(),
  wastes: yup.object().required(),
  flow: yup.object().required(),
  issueNumber: yup.string().max(11).required(),
  issueDate: dateSchema,
  quantity: yup
    .object()
    .nullable()
    .shape({
      value: yup.number().numberOfDigits(5, 3).nullable(),
      // .test("requredValue", "数量を入力してください", function (value) {
      //   if (this.parent.unit?.name && this.parent?.unit?.code) {
      //     return value;
      //   }

      //   return true;
      // }),
      unit: yup
        .object()
        .nullable()
        .shape({
          code: yup.number(),
          name: yup.string(),
        })
        .test("requredUnit", "単位を入力してください", function (value) {
          if (this.parent.value) {
            return value?.name && value?.code;
          }

          return true;
        }),
    }),
  remarks: remarksSchema,
  endOfTransportationDate: dateSchema,
  valuables: yup
    .object()
    .nullable()
    .shape({
      value: yup
        .number()
        .max(999999)
        .nullable()
        .test("requredValue", "数量を入力してください", function (value) {
          if (this.parent.unit?.name && this.parent?.unit?.code) {
            return value;
          }

          return true;
        }),
      unit: yup
        .object()
        .nullable()
        .shape({
          code: yup.number(),
          name: yup.string(),
        })
        .test("requredUnit", "単位を入力してください", function (value) {
          if (this.parent.value) {
            return value?.name && value?.code;
          }

          return true;
        }),
    }),
  // file: yup.object().nullable(),
});
