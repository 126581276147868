export const styles = (theme) => ({
  section: {
    transition: "all .3s ease 0s",
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
      background: "#7fbfff",
    },
  },
});
