import { useState } from "react";
import { useFormContext } from "react-hook-form";
import useClasses from "utils/useClasses";
import { styles } from "./styles";

/**
 * 基本情報を入力するコンテナコンポーネントです。
 * @callback render
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const classes = useClasses(styles);
  const [open, setOpen] = useState(null);
  const { control, setValue } = useFormContext();
  const [selectValue, setSelectedValue] = useState(null);

  const handleSelectClientWorkplace = (data) => {
    setOpen(false);
    setValue("dischargesWorkplace", data[0]);
    setValue("wastes", []);
  };

  const handleSelectClient = (data) => {
    setSelectedValue(data[0]);
    setOpen(false);
    setValue("transporter", data[0]);
  };

  return render({
    classes: classes,
    control: control,
    open: open,
    onOpenModal: (name) => () => setOpen(name),
    onCloseModal: () => setOpen(null),
    onSelectedClientWorkplace: handleSelectClientWorkplace,
    onSelectedClient: handleSelectClient,
    selectValue: selectValue,
    ...props,
  });
};
