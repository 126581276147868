import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  box_image_upload: {
    border: "1px solid",
    borderColor: theme.palette.text.disabled,
    borderRadius: "4px",
    width: "50%",
    textAlign: "center",
    padding: "16px 0",
  },

  button_upload: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  iconUpload: {
    fontSize: "4rem",
    color: theme.palette.text.secondary,
  },

  box_viewPDF: {
    "& .react-pdf__Page": {
      "& .react-pdf__Page__canvas": {
        width: "100% !important",
      },
    },
  },
  file_view: {
    border: "1px solid",
    borderColor: theme.palette.text.disabled,
    borderRadius: "4px",
  },
  file_infor: {
    marginBottom: theme.spacing(3),
    zIndex: 1,
  },
  file_name: {
    "& .MuiInputBase-root": {
      display: "flex",
      alignItems: "stretch",
    },
  },
  box_delete: {
    minWidth: "170px",
    maxWidth: "170px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  file_size: {
    display: "flex",
    flexDirection: "column",
    fontSize: "12px",
    color: theme.palette.text.secondary,
    position: "relative",
    "&::before": {
      content: '""',
      display: "block",
      height: "20px",
      width: "2px",
      backgroundColor: theme.palette.text.hint,
      position: "absolute",
      right: "-25px",
      top: "50%",
      transform: "translate(0,-50%)",
    },
  },
  file_delete: {},
}));
