import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";
import { withForwardRef } from "utils/HoC";

/**
 * コンテナ再設置フォームを表示するコンポーネントです。
 */
export const ReinstallationForm = withForwardRef(
  containerPresententer(Container, Presententer)
);
