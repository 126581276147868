import {
  AppBar,
  IconButton,
  Toolbar,
  SwipeableDrawer,
  List,
  ListItem as MuiListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Divider,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CommuteIcon from "@mui/icons-material/Commute";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { UserProfileButton } from "views/molecules";

/**
 * リストの項目を表示するコンポーネントです。
 * @param {JSX.Element} Icon アイコンコンポーネント
 * @param {string} title タイトル
 * @param {string} url URL
 * @param {boolean} disabled 無効設定
 * @fires ListItem#onClick クリック時
 * @returns {JSX.Element}
 */
const ListItem = ({
  Icon = null,
  title = "",
  onClick = () => {},
  url = null,
  disabled = false,
}) => {
  const handleClick = () => {
    onClick(url);
  };

  return (
    <MuiListItem>
      <ListItemButton onClick={handleClick} disabled={disabled}>
        {Icon && <ListItemIcon>{Icon}</ListItemIcon>}
        <ListItemText
          primary={title}
          sx={{
            color: "gray",
          }}
        />
      </ListItemButton>
    </MuiListItem>
  );
};

/**
 * 日常業務のメニュー
 */
const DailyWorksMenu = [
  {
    Icon: <DescriptionOutlinedIcon />,
    title: "回収記録",
    url: "/collect-mobile",
  },
  {
    Icon: <CommuteIcon />,
    title: "車輛点検",
    url: "/vehicle-inspection-list",
  },
];

/**
 * アプリケーション上部に常に表示するツールバーコンポーネントです
 */
export const Presententer = (props) => {
  return (
    <>
      <AppBar color="inherit" elevation={0}>
        <Toolbar>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            width="100%"
            sx={{
              "@media print": {
                width: 375,
                height: 62
              }
            }}
          >
            <Stack direction={"row"}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => props.menu.toggle(true)}
              >
                <MenuIcon />
              </IconButton>
              <Box display="flex" justifyContent={"center"} alignItems="center">
                <img
                  loading="lazy"
                  src={`${window.location.origin}/logo.png`}
                  alt="ロゴ"
                  style={{
                    height: "auto",
                    width: "100px",
                    margin: "8px",
                  }}
                />
              </Box>
            </Stack>
            <Box>
              <UserProfileButton value={props.user} onSignOut={props.signOut} />
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <SwipeableDrawer
        anchor={"left"}
        open={props.menu.open}
        onOpen={() => {}}
        onClose={() => props.menu.toggle(false)}
      >
        <Box
          display="flex"
          justifyContent={"right"}
          alignItems="center"
          onClick={() => props.menu.toggle(false)}
          sx={{
            width: "80vw",
          }}
        >
          <IconButton>
            <ChevronLeftIcon />
          </IconButton>
        </Box>
        <List>
          <ListItem
            Icon={<DashboardOutlinedIcon />}
            title="ダッシュボード"
            url="/"
            onClick={props.onClickMenu}
          />
        </List>
        <Stack>
          <Typography
            variant="caption"
            sx={{
              pl: 1,
              color: "gray",
            }}
          >
            {"日常業務"}
          </Typography>
          <Divider />
        </Stack>
        <List>
          {DailyWorksMenu.map((item, index) => (
            <ListItem {...item} key={index} onClick={props.onClickMenu} />
          ))}
        </List>
      </SwipeableDrawer>
    </>
  );
};
