import { setVisilityBeginnersMark } from "ducks/GuiderSteps/slice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

/**
 * 定期回収一覧の情報を表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @fires Container#onTransite 遷移時
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, onTransite, submit, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setVisilityBeginnersMark());
  }, [dispatch]);

  return render({
    onSelected: onTransite,
    submit: submit,
    ...props,
  });
};
