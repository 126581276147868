import { styles } from "./styles";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { basicSchema } from "./Validations/basic";
import { debugLog } from "utils/log";

const defaultValues = {
  categories: [],
  name: null,
  corporateNumber: null,
  representativeName: null,
  establishmentedOn: undefined,
  url: null,
  remarks: null,
};

/**
 * 取引先の登録・編集を行うのフォームを表示するコンテナコンポーネントです。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({
  render,
  id,
  onSubmit,
  onError = (err) => debugLog(err),
  value,
  ...props
}) => {
  const classes = styles();
  const methods = useForm({
    mode: "onSubmit",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    methods.reset({
      ...defaultValues,
      ...value,
    });
  }, [methods, value]);

  return render({
    id: id,
    classes: classes,
    methods: methods,
    submit: methods.handleSubmit(onSubmit, onError),
    ...props,
  });
};
