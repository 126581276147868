import { Avatar, Box, Button, Paper, Popper, TextField } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { DomainOutlined } from "@mui/icons-material";
import React from "react";
const Styles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    lineHeight: "24px",
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    "& .cellValue": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  support_table: {},
  text_content: {
    width: "100%",
    padding: theme.spacing(1),
    "& .Mui-disabled": {
      color: "rgba(0,0,0,.87)",
      width: "100%",
      height: "100%",
      padding: 0,
      lineHeight: "23px",
      "&::before": {
        borderBottomStyle: "none",
      },
    },
  },
}));

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}
/**
 * 文字が溢れて、見えなくなる場合に使う
 * @param {number} width param.colDef.width
 * @param {string} value 表示内容 　例）params.value ? params.value.toString() : ""
 *
 */

export const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const classes = Styles();
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <div
      ref={wrapper}
      className={classes.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={cellDiv}
        style={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <div ref={cellValue} className="cellValue">
        {value}
      </div>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <TextField
              multiline
              disabled
              value={value}
              className={classes.text_content}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
});

const XgridStyles = makeStyles((theme) => {
  return {
    icon: {
      backgroundColor: theme.palette.icon.company,
    },
  };
});
/**
 *
 * @param {string} imageUrl イメージのURL
 * @param {JSX} icon 表示したいアイコン 例）<DomainOutlined/>
 * @param {string} label ラベル
 * @returns
 */
export const RenderTextWithIcon = (props) => {
  const classes = XgridStyles();
  return (
    <Box display="flex" alignItems="center">
      {props?.imageUrl !== "" ? (
        <Avatar src={props?.imageUrl} alt="corporation-icon" />
      ) : (
        <Avatar className={classes.icon}>
          {props.icon ? props.icon : <DomainOutlined />}
        </Avatar>
      )}
      <Box ml={2} fontSize={13}>
        {props?.label}
      </Box>
    </Box>
  );
};

/**
 * XGridのToolBarのボータンを表示するコンポーネントです。
 * @param {string} label ボータンのラベル
 * @param {string} className クラス名
 * @param {func} onClick ボータンのイベント
 * @param {string} buttonColor button-color 例）primary・secondary
 * @param {string} buttonVariant button-color 例）contained・outlined・text
 */
export const RenderButtonToolBar = (props) => {
  const StyledButton = withStyles({
    root: {},
    label: {},
  })(Button);
  return (
    <StyledButton
      variant={props?.buttonVariant ?? "contained"}
      color={props?.buttonColor ?? "primary"}
      className={props.className}
      onClick={props?.onClick}
      {...props}
    >
      {props.label}
    </StyledButton>
  );
};

const styleAvatar = makeStyles((theme) => ({
  avatar: {
    
    "& .avatar_image": {
      backgroundColor: theme.palette.icon.mainMenu,
      color: theme.palette.background.paper,
      fontSize: "14px",
    },
  },
}));
/**
 * アバターを表示するコンポーネントです。
 * @param {string} url
 * @param {JSX} icon
 * @param {string} label
 */
export const RenderAvatar = (props) => {
  const classes = styleAvatar();
  return (
    <Box className={classes.avatar}>
      {props.url ? (
        <Avatar src={props.url} className={"avatar_image"} />
      ) : (
        <Avatar className={"avatar_image"}>
          {props.icon ? props.icon : props.label}
        </Avatar>
      )}
    </Box>
  );
};
