import DescriptionIcon from "@mui/icons-material/Description";
import { Box } from "@mui/material";

/**
 * その他ファイルを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const OtherFile = (props) => {
  return (
    <Box
      sx={{
        width: 400,
        height: 400,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DescriptionIcon
        {...props}
        sx={{
          fontSize: 120,
        }}
      />
    </Box>
  );
};
