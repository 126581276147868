import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 整備記録簿詳細を表示するコンポーネントです。
 */
export const MaintenancesDetails = containerPresententer(
  Container,
  Presententer
);
