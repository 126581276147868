import yup, { dateSchema, remarksSchema, textSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  number: textSchema.nullable(),
  category: yup.string().required(),
  maintenanceCompany: yup.object().required(),
  maintenanceWorkplace: yup.object().required(),
  maintenancedOn: dateSchema.required(),
  price: yup.number().nullable().required(),
  remarks: remarksSchema.nullable(),
  files: yup.array().nullable(),
  details: yup.array().nullable(),
});
