import React from "react";

/**
 * マークを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <span className={props.classes.container} style={props.style}>
      <span
        style={{
          backgroundColor: props?.backgroundColor,
        }}
      >
        {props.text}
      </span>
    </span>
  );
};
