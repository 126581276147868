import { useState } from "react";
import { useStyles } from "./styles";
/**
 * 引数を受けて、JSX.Elementを返します。
 * @callback render
 */
export const Container = ({ render, filePDF, ...props }) => {
  const classes = useStyles();
  const [viewFile, setViewFile] = useState(null);

  const [numPages, setNumPages] = useState(null);

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleClickFile = (file) => {
    setViewFile(file);
  };

  return render({
    classes: classes,
    files: filePDF,
    viewFile: viewFile,
    onClickFile: handleClickFile,
    open: Boolean(viewFile),
    onClose: () => setViewFile(null),
    numPages: numPages,
    onDocumentLoadSuccess: handleDocumentLoadSuccess,

    ...props,
  });
};
