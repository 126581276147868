import { useStyles } from "./styles";
import { useState } from "react";
import useClasses from "utils/useClasses";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { onCloseDialog, onOpenDialog } from "ducks/Dialog/slice";

/**
 * ユーザーボタンを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, onSignOut, ...props }) => {
  const classes = useClasses(useStyles);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.Dialog);
  const isHidden = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickUserButton = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const open = isOpen.some((v) => v === "userSettingDialog");
  const handleOpenDialog = () => {
    dispatch(onOpenDialog("userSettingDialog"));
    handleCloseMenu();
  };

  const handleCloseDialog = () => {
    dispatch(onCloseDialog("userSettingDialog"));
  };

  return render({
    anchorEl: anchorEl,
    classes: classes,
    value: value,
    onSignOut: onSignOut,

    onClickUserButton: handleClickUserButton,
    onCloseMenu: handleCloseMenu,
    isHidden: isHidden,

    //ユーザー管理ダイアログ
    open: open,
    onOpenDialog: handleOpenDialog,
    onCloseDialog: handleCloseDialog,
    ...props,
  });
};
