import { TabsDetails } from "views/molecules";
import { TabBussinessMan, TabContract, TabWorkplace } from "./ChildComponents";

/**
 * 取引先情報タブの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @param {object} value 事業場情報
 * @returns
 */
export const Presententer = (props) => {
  if (!props.value) {
    return <></>;
  }

  return (
    <TabsDetails
      value={props.tabNumber}
      onChangeTab={props.onChangeTab}
      tabs={[
        {
          name: "事業場",
          tabContent: <TabWorkplace value={props.value} />,
        },
        { name: "契約", tabContent: <TabContract value={props.value} /> },
        {
          name: "事業者",
          tabContent: <TabBussinessMan value={props.value} />,
        },
      ]}
    />
  );
};

/* todo: 収集運搬業者・処分業者の時許可証タブが出現するようにする */
