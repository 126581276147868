import {
  updateVehicleMaintenanceRecord,
  removeVehicleMaintenanceRecord,
} from "api/graphql/mutations";
import { getVehicleMaintenanceRecord } from "api/graphql/queries";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { formatJSDateToISODate } from "utils/format";
import { toApiValue, toFormValue } from "utils/ImageFunctions";
import { useMutation } from "utils/useMutation";
import { useOpener } from "utils/useOpener";
import { useQuery } from "utils/useQuery";

/**
 * 整備記録簿詳細を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const [formValue, setFormValue] = useState(null);
  const { maintenanceId } = useParams();
  const confirmDialog = useOpener();
  const navigate = useNavigate();

  const maintenance = useQuery({
    query: getVehicleMaintenanceRecord,
    variables: {
      id: maintenanceId,
    },
    onCompleted: (data) => {
      toFormValue({
        files: data?.data?.getVehicleMaintenanceRecord?.files ?? [],
      }).then((files) => {
        setFormValue({
          ...data?.data?.getVehicleMaintenanceRecord,
          maintenanceCompany: {
            id: data?.data?.getVehicleMaintenanceRecord?.maintenanceWorkplace
              ?.belongInCompanyId,
            name: data?.data?.getVehicleMaintenanceRecord?.maintenanceWorkplace
              ?.belongInCompanyName,
          },
          details: data?.data?.getVehicleMaintenanceRecord?.details?.map(
            (detail) => ({
              ...detail,
              id: detail.order,
            })
          ),
          files: files,
        });
      });
    },
  });

  const form = useOpener();
  const [updateMutation, updateMutationState] = useMutation(
    updateVehicleMaintenanceRecord,
    {
      onCompleted: (data) => {
        maintenance.refetch({
          id: maintenanceId,
        });
        form.toggle(false);
      },
      succeedMessage: "保存しました。",
      errorMessage: "エラーが発生したため、保存できませんでした。",
    }
  );

  const handleUpdate = async (data) => {
    const {
      number,
      category,
      maintenanceWorkplace,
      maintenancedOn,
      price,
      remarks,
      files,
      details,
    } = data;

    updateMutation({
      input: {
        id: maintenanceId,
        number: number,
        category: category,
        maintenanceWorkplaceId: maintenanceWorkplace?.id,
        maintenancedOn: formatJSDateToISODate(maintenancedOn),
        price: price,
        remarks: remarks,
        files: await toApiValue({ files: files }),
        details: details?.map((detail) => ({
          ...detail,
          id: undefined,
          order: undefined,
        })),
        expectedVersion:
          maintenance?.data?.getVehicleMaintenanceRecord?.version,
      },
    });
  };

  const [removeMutation, removeMutationStatus] = useMutation(
    removeVehicleMaintenanceRecord,
    {
      onCompleted: (params) => {
        navigate(`/vehicles-menu/maintenances`);
      },
      succeedMessage: "削除しました。",
      errorMessage: "エラーが発生したため、削除できませんでした。",
    }
  );

  const onRemove = () => {
    removeMutation({
      input: {
        id: maintenanceId,
      },
    });
  };

  return render({
    ...props,
    updateMutation: updateMutation,
    form: form,
    value: formValue,
    loading:
      maintenance?.loading ||
      updateMutationState?.loading ||
      removeMutationStatus?.loading,
    onUpdate: handleUpdate,
    confirmDialog: confirmDialog,
    onRemove: onRemove,
  });
};
