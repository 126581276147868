import { XGridComponent } from "views/molecules";
import { useXGridComponents } from "utils/useXGridComponents";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { searchWasteLicensesByOffset } from "api/graphql/queries";

const isoDateToText = (value) => {
  if (!value) {
    return "";
  }

  const result = DateTime.fromISO(value);

  if (result.isValid) {
    return result.toFormat("yyyy/MM/dd");
  }

  return "";
};

const formatDate = (params) => isoDateToText(params.value);

const columns = [
  {
    field: "number",
    headerName: "許可番号",
    minWidth: 130,
    flex: 1,
  },
  {
    field: "japaneseLocalGovernmentName",
    headerName: "許可主体",
    minWidth: 170,
    flex: 1,
    valueGetter: (params) =>
      `${params.row?.japaneseLocalGovernment.prefectures?.name}${
        params.row?.japaneseLocalGovernment.cityName ?? ""
      }`,
  },
  {
    field: "categoryName",
    headerName: "業の区分",
    minWidth: 350,
    flex: 1,
  },
  {
    field: "licensedOn",
    headerName: "許可の年月日",
    minWidth: 170,
    flex: 1,
    valueFormatter: formatDate,
    hide: true,
  },
  {
    field: "licenseExpirationOn",
    headerName: "許可の有効年月日",
    minWidth: 200,
    flex: 1,
    valueFormatter: formatDate,
  },
  {
    field: "certifiedAsExcellentOn",
    headerName: "優良認定日",
    minWidth: 170,
    flex: 1,
    valueFormatter: formatDate,
    hide: true,
  },
];

/**
 * 契約書アラート一覧を表示するコンポーネントです。
 * @returns {JSX.Element}
 */
export const List = () => {
  const navigate = useNavigate();
  const obj = useXGridComponents(
    columns,
    searchWasteLicensesByOffset,
    {
      other: {
        isFilteredForAlerts: true,
      },
    },
    null
  );

  const handleSelected = (params) => {
    const row = obj.params.rows.find((row) => row.id === params[0]);
    if (row.categoryName.indexOf("産業廃棄物") !== -1) {
      navigate({
        pathname: `/master/license/waste/industrial/${row.id}`,
        search:"?alert"
      });
    } else {
      navigate({
        pathname: `/master/license/waste/general/${row.id}`,
        search:"?alert"
      });
    }
  };

  return (
    <XGridComponent
      onChangeSelection={handleSelected}
      checkboxSelection={false}
      multipleSelection={false}
      onDownload={false}
      onFilter={false}
      onColumns={false}
      {...obj?.params}
    />
  );
};
