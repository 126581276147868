import { Box, Paper, Button } from "@mui/material";
import React from "react";
import { InformationItem } from "views/atoms";
import { FullScreenDialog } from "views/molecules";
import { ModalPopup, XGridComponent } from "views/molecules";
import { EndDateForm, ScheduledCollects } from "./ChildComponents/";
import { AddCycle } from "./ChildComponents/CycleAddForm";

/**
 * 定期回収詳細を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  value,
  classes,
  columns,
  onChangeCollects,
  onConfirmEndDate,
  onCancelEndDate,
  open,
  formRef,
  selectedValue,
  refetchWasteOrder,
  etRefetchWasteOrder,
  setIsAddCycleOpen,
  isAddCycleOpen,
  handleSubmitCycle,
  isSubmitting,
  handleSubmitSuccess,
  handleSubmitError,
}) => (
  <>
    <Paper
      sx={{
        padding: 2,
      }}
    >
      <div>
        <Box className={classes.title}>基本情報</Box>
        <InformationItem label="排出事業場">
          {`${value?.wasteGeneratorCompany?.name ?? ""}${
            value?.wasteCollectionWorkplace?.name ?? ""
          }`}
        </InformationItem>
        {/* <InformationItem label="収集運搬業者">
        {value?.collection?.name ?? ""}
      </InformationItem> */}
        <InformationItem label="廃棄物">
          {value?.waste?.smallClass?.name ||
            value?.waste?.middleClass?.name ||
            value?.waste?.largeClass?.name ||
            value?.waste?.name ||
            ""}
        </InformationItem>
        <InformationItem label="備考">{value?.remarks ?? ""}</InformationItem>
      </div>
      <Box mt={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box className={classes.title}>回収サイクル</Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setIsAddCycleOpen(true);
            }}
          >
            {"回収サイクルを追加する"}
          </Button>
        </Box>
        <XGridComponent
          rows={value?.cycles ?? []}
          columns={columns}
          onToolbar={false}
          onSearcher={false}
          onDownload={false}
          onColumns={false}
          onFilter={false}
          multipleSelection={false}
          checkboxSelection={false}
          disableSelectionOnClick={true}
        />
      </Box>
    </Paper>
    <Box mt={6}>
      <ScheduledCollects
        value={value?.schedules}
        refetchWasteOrder={refetchWasteOrder}
        etRefetchWasteOrder={etRefetchWasteOrder}
        onChange={onChangeCollects}
      />
    </Box>
    {open && (
      <ModalPopup open={open} onClose={onCancelEndDate}>
        <EndDateForm
          value={selectedValue?.cycleEndDate}
          onConfirm={onConfirmEndDate}
          onCancel={onCancelEndDate}
          minDate={selectedValue?.cycleStartDate}
        />
      </ModalPopup>
    )}

    <FullScreenDialog
      open={isAddCycleOpen}
      onClose={() => {
        setIsAddCycleOpen(false);
      }}
      title={"新しい定期回収を登録"}
      textConfirm={"保存"}
      onClickSubmit={handleSubmitCycle}
      isSubmit={isSubmitting}
      disabled={isSubmitting}
    >
      {value && (
        <AddCycle
          _ref={formRef}
          handleSubmitError={handleSubmitError}
          handleSubmitSuccess={handleSubmitSuccess}
          id={value.id}
        />
      )}
    </FullScreenDialog>
  </>
);
