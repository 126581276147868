import yup, { textSchema, remarksSchema, dateSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  transportationEndedOn: dateSchema.required(),
  transportPersonInCharge: textSchema.enableJWNET().required(),
  reporter: textSchema.enableJWNET(),
  quantity: yup.object().shape({
    value: yup
      .number()
      .test("requredValue", "数量を入力してください", function (value) {
        if (this.parent.unit?.name && this.parent?.unit?.code) {
          return value;
        }

        return true;
      })
      .numberOfDigits(5, 3)
      .nullable(),
    unit: yup
      .object()
      .shape({
        code: yup.number(),
        name: yup.string(),
      })
      .test("requredUnit", "単位を入力してください", function (value) {
        if (this.parent.value) {
          return value?.name && value?.code;
        }

        return true;
      })
      .nullable(),
  }),
  valuablesQuantity: yup.object().shape({
    value: yup
      .number()
      .test("requredValue", "数量を入力してください", function (value) {
        if (this.parent.unit?.name && this.parent?.unit?.code) {
          return value;
        }

        return true;
      })
      .nullable(),
    unit: yup
      .object()
      .shape({
        code: yup.number(),
        name: yup.string(),
      })
      .test("requredUnit", "単位を入力してください", function (value) {
        if (this.parent.value) {
          return value?.name && value?.code;
        }

        return true;
      })
      .nullable(),
  }),
  vehicleNumber: textSchema.enableJWNET(),
  remarks: remarksSchema.enableJWNET(),
});
