import { Paper, Stack } from "@mui/material";
import { formatDisplayDate } from "utils/format";
import { InformationItem } from "views/atoms";
import { FileGallery } from "views/molecules";
import { Group } from "views/organisms/Vehicles/Inspection/Group";

/**
 * 点検詳細を表示するコンポーネントです。
 * @param {object} value 値
 * @returns {JSX.Element}
 */
export const InspectionsDetails = ({ value }) => {
  return (
    <Stack spacing={2}>
      <Paper
        sx={{
          p: 2,
        }}
      >
        <Stack>
          <InformationItem label="点検者">
            {value?.personInChargeName}
          </InformationItem>
          <InformationItem label="点検日">
            {formatDisplayDate({ source: value?.checkedOn })}
          </InformationItem>
          <InformationItem label="車種">
            {value?.vehicleModelName}
          </InformationItem>
          <InformationItem label="車両ナンバー">
            {value?.vehicleNumber}
          </InformationItem>
          <InformationItem label="備考">{value?.remarks}</InformationItem>
          <InformationItem label="添付ファイル">
            <FileGallery value={value?.files} disabled={true} />
          </InformationItem>
        </Stack>
      </Paper>
      {value?.sections?.map((section, index) => {
        return (
          <Group
            key={index}
            index={index}
            value={section}
            title={section?.name ?? ""}
            options={section?.items ?? []}
          />
        );
      })}
    </Stack>
  );
};
