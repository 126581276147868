import { setVisilityBeginnersMark } from "ducks/GuiderSteps/slice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { OrderGuiderSteps } from "utils/GuiderSteps";
import useClasses from "utils/useClasses";
import { styles } from "./styles";

/**
 * 受注メニューを選択するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleClick = (param) => {
    navigate(`${location.pathname}/${param}`);
  };

  useEffect(() => {
    dispatch(setVisilityBeginnersMark(OrderGuiderSteps?.length > 0));
  }, [dispatch]);

  return render({
    classes: classes,
    onClick: handleClick,
    GuiderSteps: OrderGuiderSteps,
    ...props,
  });
};
