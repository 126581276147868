import { PDFViewer } from "..";
import GetAppIcon from "@mui/icons-material/GetApp";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { IconButton } from "@mui/material";

/**
 * このコンポーネントのデフォルトの行動を表示するコンポーネントです。
 * @param {JSX.Element} props プロパティ
 * @returns {JSX.Element}
 */
const DefaultActions = (props) => {
  return (
    <>
      {props.download && (
        <IconButton
          component={"a"}
          download={props.fileName}
          href={props.base64}
          size={"small"}
        >
          <GetAppIcon />
        </IconButton>
      )}
      {props.deletable && (
        <IconButton onClick={props.onDelete} size={"small"}>
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      )}
    </>
  );
};

/**
 * PDF・画像を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <div className={props.classes.container} onClick={props.onClick}>
      <div className={props.classes.header}>
        {props.fileName && (
          <div className={props.classes.fileName}>{props.fileName}</div>
        )}
        <div className={props.classes.actions}>
          {props.actions}
          {DefaultActions(props)}
        </div>
      </div>
      <div className={props.classes.context}>
        {props.isPDF && <PDFViewer files={props.base64} />}
        {props.isImage && <img src={props.base64} alt={props.fileName} />}
      </div>
      <div className={props.classes.title}>{props.title}</div>
    </div>
  );
};
