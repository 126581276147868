import { makeStyles } from '@mui/styles';

export const styles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
  },
  number: {
    borderRadius: "50%",
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.paper,
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
    height: "28px",
    minWidth: "28px",
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
