import { useJsApiLoader } from "@react-google-maps/api";
import { useWindowHeight } from "@react-hook/window-size";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { debugLog } from "utils/log";
import { useOpener } from "utils/useOpener";
import { getEstimatedArrivalTime } from "views/templates/Mobile/Collect/Utils";
import { includesTasksNotEnterd } from "../Utils";
import { useStyles } from "./styles";

/**
 * 回収を開始する画面を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @param {func} load 読み込み処理
 * @param {func} recordTasks タスクを記録する処理
 * @param {func} sortTasks タスクを並び替える処理
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  load,
  recordTasks,
  sortTasks,
  managementVehicle,
  ...props
}) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_LICENSE_KEY,
    region: "JP",
    language: "ja",
  });
  const [open, setOpen] = useState(false);
  const [openNavi, setOpenNavi] = useState(false);
  const [nextPoint, setNextPoint] = useState(null);
  const [message, setMessage] = useState(null);
  const [current, setCurrent] = useState(null);
  const [timeArrival, setTimeArrival] = useState(null);
  const navigate = useNavigate();
  const classes = useStyles();
  const screenHeight = useWindowHeight();
  const [naviModeItem, setNaviModeItem] = useState();
  const odometerForm = useOpener();

  const onCompletedUpdateOdometer = () => {
    navigate("/");
  };

  const onSkiped = () => {
    navigate("/");
  };

  //マップで中心にするポイント
  const [markerCenter, setMarkerCenter] = useState(null);
  /**
   * 現在地を取得[v]
   */
  const handleCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrent({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });

        setMarkerCenter({
          zoom: 13,
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (err) => {
        debugLog("現在値の取得に失敗: ", err);
      }
    );
  };

  useEffect(() => {
    handleCurrentLocation();
  }, []);

  useEffect(() => {
    if (value) {
      const index = value.items.findIndex((item) =>
        includesTasksNotEnterd(item?.tasks)
      );

      if (index === -1) {
        setNaviModeItem({
          ...value?.items[0],
          index: index,
        });

        setNextPoint({
          ...value?.items[0],
          lat: value?.items[0]?.lat,
          lng: value?.items[0]?.lng,
        });

        setMessage({
          value: ``,
          color: "primary",
        });
        return;
      }

      setNaviModeItem({
        ...value?.items[index],
        index: index,
      });

      setMessage({
        value: `次は、${value?.items[index]?.workplace?.name}`,
        color: "primary",
      });

      setNextPoint({
        ...value?.items[index],
        lat: value?.items[index]?.lat,
        lng: value?.items[index]?.lng,
      });
    }
  }, [value]);

  useEffect(() => {
    if (!current || !nextPoint) {
      return;
    } else {
      getEstimatedArrivalTime(current, nextPoint, (t) => {
        const time = DateTime.now().plus({ minutes: t / 60 });
        setTimeArrival(time.toFormat("HH:mm"));
      });
    }
  }, [current, nextPoint]);

  //コースボタン
  const handleToggle = () => {
    setOpenNavi(false);
    setOpen(true);
    //ナビモードのシートをチェックして、開けたまま場合は閉じる
    // let isValid = isOpen.some((v) => v === "naviMode");
    // if (isValid) {
    //   dispatch(onCloseDialog("naviMode"));
    // }
  };

  const handleCloseSheet = () => {
    setOpen(false);
  };

  /**
   * 中断ボタンクリック時 [v]
   */
  const handleBack = () => {
    if (!!value?.assignedVehicle) {
      odometerForm.toggle(true);
    } else {
      navigate("/");
    }
  };

  /**
   * マーカークリック時処理 [v]
   * @param {string} id
   * @remarks コースポイントの詳細を表示する
   */
  const handleClickPoint = (id) => {
    const index = value.items.findIndex((item) => item.id === id);

    // ポイントのIDを取得します。
    setNaviModeItem({
      ...value?.items[index],
      index: index,
    });

    setNextPoint({
      ...value.items[index],
      lat: value.items[index].lat,
      lng: value.items[index].lng,
    });

    setMessage({
      value: `次は、${value.items[index].workplace.name}`,
      color: "primary",
    });

    // 画面中央に移動
    setMarkerCenter({
      zoom: 13,
      lat: value?.items[index].lat,
      lng: value?.items[index].lng,
    });

    setOpen(false);
    setOpenNavi(true);
  };

  const handleRecordTask = (tasks) => {
    recordTasks(tasks);
  };

  const handleSortTasks = (tasks) => {
    sortTasks(tasks);
  };

  return render({
    classes: classes,
    onBack: handleBack,
    onCurrentLocation: handleCurrentLocation,
    onClickCourse: () => handleToggle(),
    value: value,
    open: open,
    onClose: handleCloseSheet,
    openNavi: openNavi,
    naviModeItem: naviModeItem,
    onCloseNaviMode: () => setOpenNavi(false),
    message: message,
    timeArrival: timeArrival,
    markerCenter: markerCenter,
    nextPoint: nextPoint,
    onClickGetPoint: handleClickPoint,
    screenHeight: screenHeight,
    load: load,
    onRecordTask: handleRecordTask,
    onSortTasks: handleSortTasks,
    current: current,
    odometerForm: odometerForm,
    onCompletedUpdateOdometer: onCompletedUpdateOdometer,
    managementVehicle: managementVehicle,
    onSkiped: onSkiped,
    isLoaded,
    loadError,
    ...props,
  });
};
