import { Button } from "@mui/material";
import { XGridComponent } from "views/molecules";
import { TemplateForm } from "views/organisms/Masters/CheckSheet/TemplateForm";

/**
 * チェックシート一覧の表示を行うプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <>
      <TemplateForm
        open={props.formOpener.open}
        onClose={() => props.formOpener.toggle(false)}
        onSubmit={props.onSubmit}
        loading={props.isSubmit}
      />
      <XGridComponent
        onChangeSelection={props.onSelected}
        checkboxSelection={false}
        multipleSelection={false}
        onDownload={true}
        actionButton={
          <Button
            variant="contained"
            onClick={() => props.formOpener.toggle(true)}
          >
            {"新しいチェックシートを追加"}
          </Button>
        }
        {...props.xGridParams}
      />
    </>
  );
};
