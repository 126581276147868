import React from "react";
import { StepFrame } from "views/molecules";
import {
  BasicInfomation,
  Disposal,
  FinalDisposal,
  Transportations,
} from "./ChildComponents";
import { FormProvider } from "react-hook-form";

/**
 * 処理フローを入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Elemnt}
 */
export const Presententer = (props) => {
  return (
    <div>
      <FormProvider {...props.methods}>
        <StepFrame number={1} title="基本情報">
          <BasicInfomation isEditMode={props.isEditMode} />
        </StepFrame>
        <StepFrame number={2} title="運搬区間情報">
          <Transportations />
        </StepFrame>
        <StepFrame number={3} title="処分情報">
          <Disposal />
        </StepFrame>
        <StepFrame number={4} title="最終処分情報">
          <FinalDisposal />
        </StepFrame>
      </FormProvider>
    </div>
  );
};
