import { Card, CardContent, Skeleton, Typography } from "@mui/material";
export const Presententer = (props) => {
  return props.value ? (
    <Card sx={{ minWidth: 275, width: 275, height: 130 }}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          今月の回収量
        </Typography>
        <Typography variant="h3" component="h2" align="right">
          {props.value}
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <Skeleton variant="rectangular" width={275} height={130} />
  );
};
