import { getCarryWorkJournal } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { debugLog } from "utils/log";
import { sortCarryWorkLocations } from "api/graphql/mutations";
import { useQuery } from "utils/useQuery";

export const getManagementVehicle = /* GraphQL */ `
  query GetManagementVehicle($id: ID!) {
    getManagementVehicle(id: $id) {
      id
      odometer
    }
  }
`;

/**
 * 回収を開始する画面を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const [value, setValue] = useState(null);
  const { id } = useParams();
  const managementVehicle = useQuery({
    query: getManagementVehicle,
    skip: true,
  });

  const load = useCallback(() => {
    API.graphql(
      graphqlOperation(getCarryWorkJournal, {
        id: id,
      })
    )
      .then((res) => {
        const journal = res.data.getCarryWorkJournal;
        managementVehicle?.refetch({
          id: journal?.assignedVehicle?.id,
        });
        setValue({
          ...journal,
          // UNDONE：既存のコンポーネントを利用するため座標情報をコピーする
          items: journal.items.map((item) => ({
            ...item,
            lat: item.workplace?.position?.lat,
            lng: item.workplace?.position?.lng,
          })),
        });
      })
      .catch((err) => {
        debugLog("情報の取得に失敗: ", err);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const recordTasks = (items) => {
    setValue({
      ...value,
      items: items,
    });
  };

  const sortTasks = useCallback(
    (items) => {
      const temp = { ...value };
      setValue({
        ...value,
        items: items,
      });
      API.graphql(
        graphqlOperation(sortCarryWorkLocations, {
          input: {
            journalId: value.id,
            locations: items.map((task) => {
              return {
                id: task.id,
                version: task.version,
              };
            }),
            journalVersion: value.version,
          },
        })
      )
        .then((res) => {
          setValue(res.data.sortCarryWorkLocations);
        })
        .catch((err) => {
          debugLog("並び替え時にエラー: ", err);
          setValue(temp);
        });
    },
    [setValue, value]
  );

  useEffect(() => {
    load();
  }, [load]);

  return render({
    value: value,
    load: load,
    recordTasks: recordTasks,
    sortTasks: sortTasks,
    managementVehicle: managementVehicle?.data?.getManagementVehicle,
    ...props,
  });
};
