import React from "react";
import PropTypes from "prop-types";
import { GoogleMap } from "@react-google-maps/api";

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};

/**
 * グーグルマップの表示を行うコンテナプレゼンテーションです
 * @param {bool} props.loadError
 * @param {bool} props.isLoaded
 * @param {object} props.center
 * @param {object} props.options
 * @param {func} props.onMapLoad
 * @param {JSX.Element} props.children
 * @param {func} props.onUnmount
 * @param {func} props.onClick
 * @returns {JSX.Element}
 */
export const Presententer = ({
  loadError,
  isLoaded,
  center,
  options,
  onMapLoad,
  children,
  onClick,
  onUnmount,
}) => {
  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return (
    <GoogleMap
      id="map"
      mapContainerStyle={mapContainerStyle}
      zoom={center?.zoom ?? 13}
      center={{
        lat: center?.lat,
        lng: center?.lng,
      }}
      options={options}
      onLoad={onMapLoad}
      onUnmount={onUnmount}
      onClick={onClick}
    >
      {children}
    </GoogleMap>
  );
};

Presententer.propTypes = {
  loadError: PropTypes.bool,
  isLoaded: PropTypes.bool,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    zoom: PropTypes.number,
  }),
  options: PropTypes.object,
  onMapLoad: PropTypes.func,
  children: PropTypes.node,
  onUnmount: PropTypes.func,
  onClick: PropTypes.func,
};
