import useClasses from "utils/useClasses";
import { styles } from "./styles";

/**
 * 1コースの回収情報を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, height, width, ...props }) => {
  const classes = useClasses(styles);

  return render({
    classes: classes,
    value: value.map((item, index) =>
    item.assignedRegularlyWasteCollectionCycles.map((cycle) => ({
      ...cycle,
      order: index + 1,
    }))
  ).flat(),
    height: height,
    width: width,
    ...props,
  });
};
