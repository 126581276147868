import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { styles } from "./styles";
import useClasses from "utils/useClasses";

const sectionNumberOptions = [
  {
    code: 1,
    name: "1区間（直行）",
  },
  {
    code: 2,
    name: "2区間",
  },
  {
    code: 3,
    name: "3区間",
  },
  {
    code: 4,
    name: "4区間",
  },
  {
    code: 5,
    name: "5区間",
  },
];

/**
 * 運搬区間情報を表示するコンテナコンポーネントです。
 * @param {JSX.Element} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const classes = useClasses(styles);
  const [sectionNumber, setSectionNumber] = useState(1);
  const { control, watch, setValue } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: "transportProcesses",
  });

  const handleChangeTransportation = (index) => (data) => {
    const currentSections = [...(watch("transportProcesses") ?? [])];
    if (currentSections.length - 1 === index) {
      setValue("disposalProcess.client", data);
    }
  };

  const handleChangeSectionNumber = (e) => {
    const section = e.target.value;

    const currentSections = [...(watch("transportProcesses") ?? [])];
    let newSections = [...Array(section?.code)].map((_, index) => {
      return {
        section: index + 1,
        trustee: undefined,
        method: undefined,
        destination: undefined,
      };
    });
    newSections.splice(
      0,
      currentSections.length,
      ...currentSections.slice(0, section?.code)
    );
    setValue("transportProcesses", newSections);
    setSectionNumber(section);
  };

  return render({
    classes: classes,
    control: control,
    sectionfields: fields,
    sectionNumber: sectionNumber,
    sectionNumberOptions: sectionNumberOptions,
    onChangeSectionNumber: handleChangeSectionNumber,
    onChangeTransportation: handleChangeTransportation,
    ...props,
  });
};
