import { Container, Grid } from "@mui/material";
import React from "react";
import { Controller, FormProvider } from "react-hook-form";
import { DatePicker } from "views/atoms";
import {
  FieldDecorateFrame,
  InputField,
  TimeRangePicker,
} from "views/molecules";

/**
 * 定期回収の回収情報を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <FormProvider {...props.methods}>
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="date"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FieldDecorateFrame label="回収日" required={true}>
                  {(params) => (
                    <DatePicker
                      value={value}
                      onChange={onChange}
                      placeholder="例）2021/06/06"
                      error={Boolean(error)}
                      helperText={error?.message}
                    />
                  )}
                </FieldDecorateFrame>
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="times"
            control={props.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FieldDecorateFrame label="回収時間" required={true}>
                {(params) => (
                  <TimeRangePicker
                    value={value}
                    onChange={onChange}
                    error={Boolean(error)}
                    helperText={error?.start?.message || error?.end?.message}
                    minutesStep={5}
                  />
                )}
              </FieldDecorateFrame>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="remarks"
            control={props.control}
            render={({ field: { value, onChange } }) => (
              <InputField
                label="備考"
                multiline
                rows={4}
                value={value}
                onChange={onChange}
                placeholder="特記事項があれば入力します。"
                required={false}
              />
            )}
          />
        </Grid>
      </Grid>
    </Container>
  </FormProvider>
);
