import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 報告方法を選択するコンポーネントです。
 */
export const ReportingMethodsField = containerPresententer(
  Container,
  Presententer
);
