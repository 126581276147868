import { ChevronRightOutlined } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React from "react";
import { TextFieldEditDialog } from "../TextFieldEditDialog";

/**
 * テキストの表示を行うプレゼンテーションです。
 * @param {string} value 値
 * @param {string} label ラベル
 */
export const Presententer = (props) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderBottom: 1,
          padding: (theme) => theme.spacing(1, 2),
          borderColor: (theme) => theme.palette.grey[300],
          color: (theme) => theme.palette.grey[600],
        }}
      >
        <Box sx={{ minWidth: "300px" }}>{props.label}</Box>
        <Box sx={{ flex: 1 }}>{props.textValue}</Box>
        <IconButton onClick={props.onOpen}>
          {<ChevronRightOutlined />}
        </IconButton>
      </Box>
      <TextFieldEditDialog
        open={props.open}
        onClose={props.onClose}
        value={props.textValue}
        label={props.label}
        getOnchange={props.getOnchange}
      />
    </>
  );
};
