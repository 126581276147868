import { useState } from "react";
import { debugLog } from "utils/log";
import { styles } from "./styles";
import useClasses from "utils/useClasses";

/**
 * ドライバーを表示・入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value ドライバー
 * @param {array} drivers 選択できるドライバー
 * @fires Container#onChange 変更時
 * @param {string} props その他プロパティ
 * @returns {func}
 */
export const Container = ({
  render,
  value = null,
  drivers = [],
  disabledDrivers = [],
  onChange = (data) => debugLog(data),
  ...props
}) => {
  const classes = useClasses(styles);
  const [edit, setEdit] = useState(false);
  const handleEdit = () => {
    setEdit(!edit);
  };

  const handleClose = (e) => {
    setEdit(false);
  };

  const handleClickAway = (e) => {
    setEdit(false);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === "Escape") {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleChange = (e) => {
    onChange(drivers.filter((d) => e.target.value.includes(d.id)));
  };

  return render({
    classes: classes,
    onChange: handleChange,
    handleClickAway: handleClickAway,
    value: value ?? [],
    handleKeyDown: handleKeyDown,
    onEdit: handleEdit,
    edit: edit,
    drivers: drivers,
    disabledDrivers: disabledDrivers,
    onClose: handleClose,
    ...props,
  });
};
