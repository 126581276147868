import FormHelperText from "@mui/material/FormHelperText";
import React from "react";
import { SelectPopup, InputField } from "views/molecules";

/**
 * 回収頻度を入力するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  const categoryId = props.value?.category?.id;
  return (
    <>
      <div
        style={{
          display: "flex",
          direction: "row",
          gap: "0 10px",
        }}
      >
        <SelectPopup
          hideLabel={true}
          value={props.value?.category}
          options={props.cycleCategories}
          onChange={props.onChangeInterval}
          optionValueField={"id"}
          optionNameField={"name"}
          error={props.error}
        />
        {props.value?.category?.id === "everyMonth" && (
          <SelectPopup
            hideLabel={true}
            value={props.value?.weeks ?? []}
            options={props.week}
            onChange={props.onChangeWeeks}
            multiple={true}
            optionValueField={"code"}
            optionNameField={"name"}
            error={props.error}
          />
        )}
        {(categoryId === "everyMonth" ||
          categoryId === "everyWeek" ||
          categoryId === "first" ||
          categoryId === "second" ||
          categoryId === "third" ||
          categoryId === "fourth" ||
          categoryId === "fifth") && (
          <SelectPopup
            hideLabel={true}
            value={props.value?.weekDays}
            options={props.dayOfWeek}
            onChange={props.onChangeDayOfWeeks}
            multiple={true}
            optionValueField={"code"}
            optionNameField={"name"}
            error={props.error}
          />
        )}
        {categoryId === "interval" && (
          <InputField
            hideLabel={true}
            value={props.value?.numberOfDays}
            onChange={props.onChnageCollectionDaysInverval}
            type="number"
            sx={{
              width: 100,
            }}
            inputMode="numeric"
            placeholder="日数"
            isError={props.error}
          />
        )}
      </div>
      <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
    </>
  );
};
