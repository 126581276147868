/**
 * 情報の項目を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <div className={props.classes.container}>
      <div className={props.classes.label}>{props.label}</div>
      <div className={props.classes.item}>{props.children}</div>
    </div>
  );
};
