import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { fetchContainerDisuseTypes } from "./api";

const containerDisuseTypesSlice = createSlice({
  name: "containerDisuseTypes",
  initialState: {
    data: null,
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContainerDisuseTypes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchContainerDisuseTypes.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchContainerDisuseTypes.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

/**
 * ストアからコンテナ停止種類を取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.ContainerDisuseTypes;

/**
 * ストアからコンテナ停止種類を取得します。
 */
export const containerDisuseTypesSelector = createSelector(
  selector,
  (containerDisuseTypes) => containerDisuseTypes.data
);

/**
 * ストアから読み込みを取得します。
 */
export const isLoadingSelector = createSelector(
  selector,
  (containerDisuseTypes) => containerDisuseTypes.isLoading
);

/**
 * ストアからエラーを取得します。
 */
export const errorSelector = createSelector(
  selector,
  (containerDisuseTypes) => containerDisuseTypes.error
);

export default containerDisuseTypesSlice.reducer;
