import { useFormContext } from "react-hook-form";
import { styles } from "./styles";
import { useSelector } from "react-redux";
import {
  disposalMethodsSelector,
  isLoadingSelector,
} from "ducks/DisposalMethods";
import useClasses from "utils/useClasses";

/**
 * 処分情報を入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const disposalMethods = useSelector(disposalMethodsSelector);
  const isLoading = useSelector(isLoadingSelector);
  const classes = useClasses(styles);
  const { control } = useFormContext();

  return render({
    classes: classes,
    control: control,
    disposalMethods: disposalMethods,
    isLoading: isLoading,
    ...props,
  });
};
