import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    zIndex: `${theme.zIndex.speedDial} !important`,
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%) !important",
  },
  drawer: {
    ...theme.mixins.toolbar,
  },
  drawerHeader: {
    padding: theme.spacing(0, 1),
  },

  BoxSticky: {
    marginLeft: "-8px",
    marginRight: "-8px",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingBottom: "16px",
    backgroundColor: "rgb(255, 255, 255)",
  },
  lists: {},
}));
