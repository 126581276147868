import yup, { remarksSchema, dateSchema, textSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  date: dateSchema.required(),
  times: yup.object().shape({
    start: textSchema.required(),
    end: textSchema.required(),
  }),
  remarks: remarksSchema,
});
