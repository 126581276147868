import React from "react";
import { IconButton } from "@mui/material";
import beginner from "./beginner.png";
import clsx from "clsx";

/**
 * 初心者マークの表示を行うプレゼンテーションコンポーネントです
 *
 * @param {object} classes　クラス名
 * @param {object} onClickBeginner 初心者マークを押す時のイベントです
 * @returns
 */
export const Presententer = (props) => (
  <IconButton
    onClick={props.onClickBeginner}
    disabled={props.visibility}
    className={clsx(props.visibility ? props.classes.visibilityIcon : "")}
  >
    <img className={props.classes.beginner_mark} src={beginner} alt="初心者" />
  </IconButton>
);
