import { DomainOutlined } from "@mui/icons-material";
import React from "react";
import { FullScreenDialog, ConfirmDialog } from "views/molecules";
import { WorkplaceDetailTabs } from "views/organisms/Masters/Partner/PartnerDetailTabs/ChildComponents";
import { WorkplaceForm } from "views/organisms/Masters/Workplace";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { Caption } from "views/organisms/DetailsFrame/ChildComponents";
import { Avatar, Button, Stack } from "@mui/material";
import { Mark } from "views/atoms";
import { WorkplaceCategoryColorMap } from "utils/Context";
import { If } from "views/atoms";

/**
 * 取引先詳細ページでの事業場詳細を表示するプレゼンテーションコンポーネントです。
 * @param {object} props
 * @param {objetc} workplaceDetails 事業場情報
 * @param {boolean} open 事業所。事業場情報を編集するダイアログを有無に設定する
 * @param {func} onOpenDialog ダイアログを開くイベント
 * @param {func} onCloseDialog ダイアログを閉じるイベント
 * @param {number} tab タブ＝０の場合ボータンが表示する
 * @param {func} getTab タブのナンパ―を取得する
 * @returns　JSX.Element
 */
export const Presententer = (props) => {
  const {
    tab = 0,
    getTab,
    value,
    open,
    onOpenDialog,
    onCloseDialog,
    client,
    isSubmit,
    removeConfirmDialog,
    loading,
    removeMutation,
  } = props;
  return (
    <div>
      <DetailsFrame
        caption={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                marginRight: 3,
                width: 58,
                height: 58,
              }}
            >
              <DomainOutlined sx={{ fontSize: "2.5rem" }} />
            </Avatar>
            <div>
              {value && client && (
                <Stack direction="row" spacing={1}>
                  {value.id === client.headOfficeWorkplace?.id && (
                    <Mark text="本社" />
                  )}
                  {value?.categories?.map((category, index) => (
                    <Mark
                      text={category.name}
                      backgroundColor={WorkplaceCategoryColorMap[category.id]}
                      key={index}
                    />
                  ))}
                </Stack>
              )}
              <Caption title={value?.name} subTitle={client?.name} />
            </div>
          </div>
        }
        actions={
          <If condition={value?.state ? value?.state !== "removed" : false}>
            <If
              condition={
                (value?.categories?.some(
                  (category) => category.id === "dischargeSite"
                ) &&
                  tab === 2) ||
                (value?.categories?.every(
                  (category) => category.id !== "dischargeSite"
                ) &&
                  tab === 0)
              }
            >
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => removeConfirmDialog.toggle(true)}
                  disabled={!value || loading}
                >
                  {"削除する"}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onOpenDialog}
                  disabled={!value || loading}
                >
                  {"編集する"}
                </Button>
              </Stack>
            </If>
          </If>
        }
      >
        <WorkplaceDetailTabs tab={tab} value={value} getTab={getTab} />
        <FullScreenDialog
          formId={"workplace-form"}
          open={open}
          title={`${value?.name}の事業場情報の編集`}
          textConfirm={"保存"}
          onClose={onCloseDialog}
          isSubmit={isSubmit}
          disabled={isSubmit}
        >
          <WorkplaceForm
            id={"workplace-form"}
            onSubmit={props.submit}
            value={value}
          />
        </FullScreenDialog>
      </DetailsFrame>
      <ConfirmDialog
        title="削除確認"
        message="削除します。よろしいですか？"
        open={removeConfirmDialog.open}
        positiveText={"削除"}
        negativeText={"キャンセル"}
        onPositive={() => removeMutation({ input: { id: value.id } })}
        onNegative={() => removeConfirmDialog.toggle(false)}
      />
    </div>
  );
};
