import PropTypes from "prop-types";

import { useStyles } from "./styles";

/**
 * コンテナーコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  style,
  children,
  showDot,
  isAnimated = true,
  ...props
}) => {
  const classes = useStyles({
    isAnimated,
  });
  return render({
    classes,
    style,
    children,
    showDot,
    ...props,
  });
};

Container.propTypes = {
  render: PropTypes.func.isRequired,
  style: PropTypes.object,
  children: PropTypes.node,
  showDot: PropTypes.bool,
  isAnimated: PropTypes.bool,
};
