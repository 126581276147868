import useClasses from "utils/useClasses";
import { styles } from "./styles";

/**
 * 詳細ページの表題を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string} className クラス名
 * @param {string} title タイトル
 * @param {string} subTitle サブタイトル
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, className, title, subTitle, ...props }) => {
  const classes = useClasses(styles);
  return render({
    classes: classes,
    className: className,
    title: title,
    subTitle: subTitle,
    ...props,
  });
};
