import { useFormContext } from "react-hook-form";
import { styles } from "./styles";
import { companyCategoriesSelector } from "ducks/CompanyCategories";
import { useSelector } from "react-redux";

/**
 * 事業場を入力を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const classes = styles();
  const { control } = useFormContext();
  const companyCategories = useSelector(companyCategoriesSelector);

  return render({
    classes: classes,
    control: control,
    ComanyCategories: companyCategories,
    ...props,
  });
};
