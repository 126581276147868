import React from "react";
import { XGridComponent } from "views/molecules";

/**
 * 処理フロー最終処分事業場情報をタブ形式で表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <XGridComponent
      rows={
        props.value?.lastDisposalProcesses?.map(
          (item) => item?.disposalWorkplace
        ) ?? []
      }
      columns={props.columns}
      disableSelectionOnClick={true}
      onDownload={false}
      onFilter={false}
      onSearcher={false}
      onColumns={false}
      titleTable={""}
    />
  );
};
