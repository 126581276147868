import PersonIcon from "@mui/icons-material/Person";
import {
  Avatar,
  Button as MuiButton,
  Skeleton,
  Typography,
} from "@mui/material";

/**
 * ユーザーアカウントボタンを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value ユーザー情報
 * @param {boolean} props.loading 読み込み中
 * @fires Button#onClick クリック時
 * @returns {JSX.Element}
 */
export const Button = ({
  value = null,
  loading = false,
  onClick = () => {},
}) => {
  if (loading === true) {
    return <Skeleton variant="rectangular" width={160} height={40} />;
  }

  return (
    <MuiButton onClick={onClick}>
      <Avatar
        src={value?.icon}
        sx={{
          mr: 1,
          fontSize: "12px",
        }}
      >
        <PersonIcon />
      </Avatar>
      <Typography
        sx={{
          color: "DimGray",
        }}
      >
        {value?.name ?? ""}
      </Typography>
    </MuiButton>
  );
};
