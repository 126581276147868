import reducer from "./slice";
import {
  selector,
  wasteDischargeIntervalsSelector,
  isLoadingSelector,
  errorSelector,
} from "./slice";
import { fetchWasteDischageIntervals } from "./api";

export default reducer;

export {
  wasteDischargeIntervalsSelector,
  selector,
  isLoadingSelector,
  errorSelector,
  fetchWasteDischageIntervals,
};
