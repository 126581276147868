import PropTypes from 'prop-types';

/**
 * 厳格に条件分岐を行い表示するコンポーネントです。
 * @param {object} params パラメータ
 * @param {any} params.target 対象の値
 * @param {boolean} params.condition 条件
 * @param {JSX.Element} params.whenTrueComponent 条件がtrueの時のコンポーネント
 * @param {JSX.Element} params.whenFalseComponent 条件がfalseの時のコンポーネント
 * @param {JSX.Element} params.whenNullComponent 対象がnullの時のコンポーネント
 * @returns {JSX.Element} コンポーネント
 * @example
 * <StrictIf
 *   target={target}
 *   condition={condition}
 *   whenTrueComponent={<WhenTrueComponent />} // targetが存在し、conditionがtrueの時に表示するコンポーネント
 *   whenFalseComponent={<WhenFalseComponent />} // targetが存在し、conditionがfalseの時に表示するコンポーネント
 *   whenNullComponent={<WhenNullComponent />} // targetがnullの時に表示するコンポーネント
 * />
 */
export const StrictIf = ({
    target = null,
    condition = false,
    whenTrueComponent = <></>,
    whenFalseComponent = <></>,
    whenNullComponent = <></> }) => {
        if (target === null) {
            return whenNullComponent;
        }

        return condition ? whenTrueComponent : whenFalseComponent;
}

StrictIf.propTypes = {
    target: PropTypes.any,
    condition: PropTypes.bool,
    whenTrueComponent: PropTypes.element,
    whenFalseComponent: PropTypes.element,
    whenNullComponent: PropTypes.element,
}