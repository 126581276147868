import { Box, Grid, InputAdornment } from "@mui/material";
import React from "react";
import { Controller, useWatch } from "react-hook-form";
import { InputField, InputFieldPopppSelect, ModalPopup } from "views/molecules";
import { WasteSelector } from "../../../WasteSelector";

function QuantityUnitNameWatched({ control }) {
  const quantityUnitName = useWatch({
    control,
    name: "waste.quantityUnit.name",
    defaultValue: "",
  });

  return <InputAdornment position="end">{quantityUnitName}</InputAdornment>;
}

/**
 * 廃棄物を追加するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element} JSX 要素
 */
export const Presententer = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          name={"waste"}
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <>
                <InputFieldPopppSelect
                  label={"廃棄物"}
                  value={
                    value
                      ? {
                          id: value.id,
                          name:
                            (value.type.smallClass.name ||
                              value.type.middleClass.name ||
                              value.type.largeClass.name ||
                              "") +
                            (value?.name !== "" ? `(${value?.name})` : ""),
                        }
                      : ""
                  }
                  required={true}
                  placeholder={"廃棄物を選択します。"}
                  error={error}
                  onOpenModal={props.onOpenModal}
                  textConfirm="選択"
                />
                <ModalPopup onClose={props.onCloseModal} open={props.open}>
                  <Box pt={3} pb={3} sx={{ maxHeight: "85vh" }}>
                    <WasteSelector
                      dischargeSourceWorkplaceId={
                        props.dischargeSourceWorkplaceId
                      }
                      onSelected={props.getSelected}
                      value={value}
                    />
                  </Box>
                </ModalPopup>
              </>
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={"quantity"}
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label={"排出量"}
              value={value}
              onChange={onChange}
              required={true}
              placeholder={"例）2"}
              InputProps={{
                endAdornment: (
                  <QuantityUnitNameWatched control={props.control} />
                ),
              }}
              type="number"
              error={error}
            />
          )}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <Controller
          name="remark"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputField
              label="備考"
              placeholder="特記事項があれば入力します。"
              multiline
              rows={3}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="files"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) =>
            value?.map((image, index) => (
              <Thumbnail
                title={image.name}
                deletable={true}
                onDelete={props.onDeleteImage(index)}
                base64={image.base64}
              />
            )) ?? <></>
          }
        />
        <FileUploader
          label="廃棄物の写真"
          maxSize={5242880}
          accept={[".png", ".jpeg", ".jpg", ".gif"]}
          onChange={props.onAddImages}
        />
      </Grid> */}
    </Grid>
  );
};
