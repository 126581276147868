import { useNavigate, useLocation } from "react-router-dom";

/**
 * 伝票入力のメニュー選択を行う画面を表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (param) => {
    navigate(`${location.pathname}/${param}`);
  };

  return render({
    onClick: handleClick,
    ...props,
  });
};
