import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 処理フローを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 処理フロー
 */
export const Flow = containerPresententer(Container, Presententer);
