import React, { useImperativeHandle } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "utils/yup";
import { DateTime } from "luxon";
import { graphqlOperation, API } from "utils/graphqlOperation";
import { StepFrame } from "views/molecules";

import { createRegularlyWasteCollectionCycles } from "api/graphql/mutations";

import { CollectCycleForm } from "../../../RegularlyForm/ChildComponents";

const defaultValues = {
  cycles: [],
};

const cyclesSchema = yup.object().shape({
  cycles: yup.array().min(1).required(),
});

/**
 * This components add the cycles 
 * @param {object} props
 * @returns
 */

export const AddCycle = ({
  _ref,
  handleSubmitError,
  handleSubmitSuccess,
  id,
}) => {
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
    },
    resolver: yupResolver(cyclesSchema),
  });

  const handleSubmit = (data) => {
    const input = {
      id: id,
      cycles: data.cycles.map((item) => {
        return {
          categoryId: item?.frequency?.category?.id,
          months: item?.frequency?.months?.map((month) => month.code),
          weeks: item?.frequency?.weeks?.map((week) => week.code),
          weekDays: item?.frequency?.weekDays?.map((weekDay) => weekDay.code),
          numberOfDays: item?.frequency?.numberOfDays
            ? Number(item?.frequency?.numberOfDays)
            : undefined,
          scheduleTimeRangeStart: item.times.start + ":00",
          scheduleTimeRangeEnd: item.times.end + ":00",
          cycleStartDate: DateTime.fromJSDate(
            new Date(item.cycleStartDate)
          ).toISODate(),
          remarks: item.remarks,
        };
      }),
    };
    API.graphql(
      graphqlOperation(createRegularlyWasteCollectionCycles, {
        input,
      })
    )
      .then((res) => {
        handleSubmitSuccess(res);
      })
      .catch((err) => {
        handleSubmitError(err);
      });
  };

  useImperativeHandle(_ref, () => ({
    submit: () => {
      methods.trigger().then((res) => {
        if (res) {
          handleSubmit(methods.getValues());
        } else {
          handleSubmitError(methods.formState.errors);
        }
      });
    },
  }));
  return (
    <FormProvider {...methods}>
      <StepFrame title="回収サイクル">
        <CollectCycleForm />
      </StepFrame>
    </FormProvider>
  );
};
