import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { formatDisplayAddress, formatDisplayDate } from "utils/format";
import { InformationItem } from "views/atoms";

/**
 * 現在の設置・保管場所を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value あたい
 * @fires SetupLocation#onClick クリック時
 * @returns {JSX.Element}
 */
export const SetupLocation = ({ value, onClick = () => {} }) => {
  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Box
          display={"flex"}
          flexDirection="row"
          justifyContent={"space-between"}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "17px",
                textDecoration: "underline",
              }}
            >
              {value?.state === "active"
                ? `現在の設置・保管場所`
                : `最終設置・保管場所`}
            </Typography>
          </Box>
          <Box>
            <Button variant="contained" onClick={onClick} disabled={!value}>
              {"移動履歴を表示する"}
            </Button>
          </Box>
        </Box>
        <Box>
          <InformationItem label={"設置・保管日"}>
            {formatDisplayDate({ source: value?.installedOn }) ?? ""}
          </InformationItem>
        </Box>
        <Box>
          <InformationItem label={"事業者"}>
            {value?.installationWorkplace?.belongInCompanyName ?? ""}
          </InformationItem>
        </Box>
        <Box>
          <InformationItem label={"事業場"}>
            {value?.installationWorkplace?.name ?? ""}
          </InformationItem>
        </Box>
        <Box>
          <InformationItem label={"住所"}>
            {formatDisplayAddress(value?.installationWorkplace) ?? ""}
          </InformationItem>
        </Box>
      </Stack>
    </Paper>
  );
};
