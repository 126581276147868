import { Paper, Skeleton, Typography } from "@mui/material";
import {
  Bar,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export const Presententer = (props) => {
  return !props.loading ? (
    <Paper sx={{ p: 2 }}>
      <Typography color="textSecondary" gutterBottom>
        1週間の回収量
      </Typography>
      <ResponsiveContainer height={400}>
        <ComposedChart data={props.value?.items}>
          <XAxis dataKey="date" padding={{ right: 50, left: 50 }} />
          <YAxis yAxisId={1} label={{ value: "回収量", angle: -90, dx: -20 }} />
          <Tooltip />
          <CartesianGrid strokeDasharray="3 3" />
          {props.value?.header?.kg === true && (
            <Bar
              yAxisId={1}
              dataKey="kg"
              name="kg"
              barSize={50}
              fill="#8884d8"
            />
          )}
          {props.value?.header?.t === true && (
            <Bar yAxisId={1} dataKey="t" name="t" barSize={50} fill="#D984D5" />
          )}
          {props.value?.header?.m3 === true && (
            <Bar
              yAxisId={1}
              dataKey="m3"
              name="m3"
              barSize={50}
              fill="#D98884"
            />
          )}
          {props.value?.header?.["リットル"] === true && (
            <Bar
              yAxisId={1}
              dataKey="リットル"
              name="リットル"
              barSize={50}
              fill="#D5D984"
            />
          )}
          {props.value?.header?.["個・台"] === true && (
            <Bar
              yAxisId={1}
              dataKey="個・台"
              name="個・台"
              barSize={50}
              fill="#84D988"
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Paper>
  ) : (
    <Skeleton variant="rectangular" height={400} />
  );
};
