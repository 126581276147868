import { companySelector, fetchCompany } from "ducks/Company";
import { fetchCompanyCategories } from "ducks/CompanyCategories";
import { fetchContractTypes } from "ducks/ContractTypes";
import { fetchCycleCategories } from "ducks/CycleCategories";
import { fetchDisposalMethods } from "ducks/DisposalMethods";
import { fetchIndustrySectors } from "ducks/IndustrySectors";
import { fetchModelOfVehicles } from "ducks/ModelOfVehicles";
import { fetchPrefectures } from "ducks/Prefectures";
import { fetchTransportMethods } from "ducks/TransportMethods";
import { fetchUser, userSelector } from "ducks/User";
import { fetchWasteDischageIntervals } from "ducks/WasteDischargeIntervals";
import { fetchWastePackings } from "ducks/WastePackings";
import { fetchWasteQuantityUnits } from "ducks/WasteQuantityUnits";
import { fetchWasteReportingMedia } from "ducks/WasteReportingMedia";
import { fetchWeightConvertedValueUnits } from "ducks/WeightConvertedValueUnits";
import { fetchWorkplaceCategories } from "ducks/WorkplaceCategories";
import { fetchContainerAdditionalTypes } from "ducks/ContainerAdditionalTypes";
import { fetchContainerDisuseTypes } from "ducks/ContainerDisuseTypes";
import { fetchChecksheetCategories } from "ducks/ChecksheetCategories";
import { fetchRoles } from "ducks/Roles";
import { fetchPlans } from "ducks/Plans";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

/**
 * 認証後のページ基盤のコンテナコンポーネントです。
 * @param {object} props プロパティオブジェクト
 * @param {func} props.render 引数を受けて、JSX.Elementを返すメソッド
 * @param {JSX.Element} props.children 子コンポーネント
 * @returns {JSX.Element}
 */
export function Container(props) {
  const { render, children } = props;
  const dispatch = useDispatch();
  const company = useSelector(companySelector);
  const user = useSelector(userSelector);

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchPrefectures());
    dispatch(fetchIndustrySectors());
    dispatch(fetchModelOfVehicles());
    dispatch(fetchTransportMethods());
    dispatch(fetchDisposalMethods());
    dispatch(fetchWastePackings());
    dispatch(fetchWasteQuantityUnits());
    dispatch(fetchCompany());
    dispatch(fetchCycleCategories());
    dispatch(fetchCompanyCategories());
    dispatch(fetchWasteReportingMedia());
    dispatch(fetchWasteDischageIntervals());
    dispatch(fetchWeightConvertedValueUnits());
    dispatch(fetchContractTypes());
    dispatch(fetchWorkplaceCategories());
    dispatch(fetchContainerAdditionalTypes());
    dispatch(fetchContainerDisuseTypes());
    dispatch(fetchChecksheetCategories());
    dispatch(fetchRoles());
    dispatch(fetchPlans());
  }, [dispatch]);

  return render({
    company: company,
    user: user,
    children: children,
  });
}
