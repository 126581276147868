import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import {
  Dialog,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";

const paddingZero = (value) => {
  if (!!value || value === 0) {
    return ("00" + value).slice(-2);
  }

  return value;
};

const Hours = [...Array(24)].map((_, i) => paddingZero(i));

const Minutes = [...Array(60)].map((_, i) => paddingZero(i));

const filteringMinutesStep = (minutesStep) => {
  if (!minutesStep) {
    return Minutes;
  }

  if (typeof minutesStep !== "number") {
    throw new Error("数字を指定してください。");
  }

  return Minutes.filter((minute) => parseInt(minute) % minutesStep === 0);
};

/**
 * 時間の選択の表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  value,
  variant,
  error,
  helperText,
  onOpen,
  onClose,
  onChangeHour,
  onChangeMinute,
  disabled,
  open,
  innerValue,
  minutesStep,
  ...props
}) => {
  return (
    <>
      <FormControl sx={{ width: 100 }} error={error}>
        <TextField
          variant={variant}
          value={value ?? ""}
          onClick={onOpen}
          InputProps={{
            endAdornment: disabled === false && <AccessTimeOutlinedIcon />,
          }}
          disabled={disabled}
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
      <Dialog onClose={onClose} open={open}>
        <Stack spacing={2} direction="row" p={2}>
          <Select
            size="medium"
            variant="standard"
            value={innerValue?.hour ?? null}
            onChange={onChangeHour}
            sx={{
              fontSize: 30,
            }}
          >
            <MenuItem value="">
              <em>未選択</em>
            </MenuItem>
            {Hours.map((hour, index) => (
              <MenuItem key={index} value={hour}>
                {hour}
              </MenuItem>
            ))}
          </Select>
          <div>
            <span
              style={{
                fontSize: 30,
              }}
            >
              :
            </span>
          </div>
          <Select
            size="medium"
            variant="standard"
            value={innerValue?.minute ?? null}
            onChange={onChangeMinute}
            sx={{
              fontSize: 30,
            }}
          >
            <MenuItem value="">
              <em>未選択</em>
            </MenuItem>
            {filteringMinutesStep(minutesStep).map((minute, index) => (
              <MenuItem key={index} value={minute}>
                {minute}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Dialog>
    </>
  );
};
