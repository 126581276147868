import { createSlice } from "@reduxjs/toolkit";
const defaultValue = {
  requested: [],
};
const CollectsRequested = createSlice({
  name: "collectsRequested",
  initialState: defaultValue,
  reducers: {
    onCollectRequested: (state, action) => {
      state.requested.push(action.payload);
    },
    onRemoveCollectRequested: (state, action) => {
      const newState = state.requested.filter((v) => v.id !== action.payload);
      state.requested = newState;
    },
  },
});

export const {
  onCollectRequested,
  onRemoveCollectRequested,
} = CollectsRequested.actions;
export default CollectsRequested.reducer;
