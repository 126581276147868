/**
 * VehicleAllocationFormで使用するユーティリティです
 */

import { debugLog } from "utils/log";
import { v4 } from "uuid";
import __ from "lodash";

/**
 * 割り当て済みコース配列を取得します。
 * @param {object} value 値
 * @returns {array}
 */
const findAssignedCourses = (value) => {
  return value.done.courses;
};

/**
 * 未割当の回収情報を取得します。
 * @param {object} value 値
 * @returns {array}
 */
const findUnassignedSchedules = (value) => {
  return value.still;
};

/**
 * コースを検索します。
 * @param {array} courses
 * @param {string} id 対象のコースid
 * @returns {object}
 */
const findCourse = (courses, id) => {
  return courses.find((course) => course.id === id);
};

/**
 * コース情報から地点オブジェクトのインデックス値を取得します。
 * @param {array} course コース情報
 * @param {object} point 回収情報
 * @returns {number}
 */
const findPointIndex = (course, schedule) => {
  return course.points.findIndex(
    (points) =>
      points.assignedWasteCollectionSchedules.find(
        (o) => o.id === schedule.id
      ) !== undefined
  );
};

/**
 * 回収情報全体から回収情報インデックスを取得します。
 * @param {array} schedules 回収情報全体
 * @param {object} schedule 回収情報
 * @returns {number}
 */
const findScheduleIndex = (schedules, schedule) => {
  return schedules.findIndex((o) => o.id === schedule.id);
};

/**
 * コース情報から地点情報位置とスケジュール情報位置を取得します。
 * ない場合nullが設定されます。
 * @param {object} course コース情報
 * @param {object} schedule 検索対象の回収情報
 * @returns {object}
 */
const findNormalizeIndexFromSchedule = (course, schedule) => {
  const pointIndex = findPointIndex(course, schedule);
  const scheduleIndex =
    pointIndex !== -1
      ? findScheduleIndex(
          course.points[pointIndex].assignedWasteCollectionSchedules,
          schedule
        )
      : -1;
  return {
    point: pointIndex !== -1 ? pointIndex : null,
    schedule: scheduleIndex !== -1 ? scheduleIndex : null,
  };
};

/**
 * 回収先情報配列から回収情報を取り出します。
 * @param {array} points 回収先情報配列
 * @returns {array} 回収情報配列
 */
const flatSchedules = (points) => {
  return points.map((point) => point.assignedWasteCollectionSchedules).flat();
};

/**
 * スケジュールを結合します。
 * @param {object} course コース
 * @param {object} schedule 対象のスケジュール
 * @param {object} afterSchedule 対象の次のスケジュール
 * @remarks 同じ地点情報でscheduleとafterScheduleの事業場が同じ場合の結合処理です。
 */
const joinBeforeAfterSchedule = (course, schedule, afterSchedule) => {
  if (
    schedule?.wasteCollectionWorkplace?.id ===
    afterSchedule?.wasteCollectionWorkplace?.id
  ) {
    let indexes = findNormalizeIndexFromSchedule(course, schedule);
    let afterIndexes = findNormalizeIndexFromSchedule(course, afterSchedule);

    if (
      course.points[indexes.point]?.id !== course.points[afterIndexes.point]?.id
    ) {
      const remove = course.points.splice(indexes.point, 1);
      afterIndexes = findNormalizeIndexFromSchedule(course, afterSchedule);
      course.points[afterIndexes.point].assignedWasteCollectionSchedules.splice(
        afterIndexes.schedule,
        0,
        ...remove[0].assignedWasteCollectionSchedules
      );
    }
  }
};

/**
 * スケジュールをコースに入れ込みます。
 * @param {object} course コース
 * @param {object} schedule スケジュール
 * @param {object} beforeSchedule 入れ込むスケジュールの前スケジュール
 * @param {object} afterSchedule 入れ込むスケジュールの後スケジュール
 */
const insertSchedule = (
  course,
  schedule,
  beforeDestSchedule,
  afterDestSchedule
) => {
  checkWasteCollectionWorkplace(
    schedule,
    beforeDestSchedule,
    afterDestSchedule,
    () => {
      // 前と同じだった
      const destIndexes = findNormalizeIndexFromSchedule(
        course,
        beforeDestSchedule
      );

      course.points[destIndexes.point].assignedWasteCollectionSchedules.splice(
        destIndexes.schedule + 1,
        0,
        schedule
      );
    },
    () => {
      // 後と同じだった
      const destIndexes = findNormalizeIndexFromSchedule(
        course,
        afterDestSchedule
      );

      course.points[destIndexes.point].assignedWasteCollectionSchedules.splice(
        destIndexes.schedule,
        0,
        schedule
      );
    },
    () => {
      // 前後と違った
      if (beforeDestSchedule) {
        const destIndexes = findNormalizeIndexFromSchedule(
          course,
          beforeDestSchedule
        );

        course.points.splice(
          destIndexes.point + 1,
          0,
          createCoursePoint(undefined, schedule.wasteCollectionWorkplace, [
            schedule,
          ])
        );
      } else if (afterDestSchedule) {
        const destIndexes = findNormalizeIndexFromSchedule(
          course,
          afterDestSchedule
        );

        course.points.splice(
          destIndexes.point,
          0,
          createCoursePoint(undefined, schedule.wasteCollectionWorkplace, [
            schedule,
          ])
        );
      } else {
        throw new Error("不明なエラー");
      }
    },
    () => {
      // 前後と違っており、跨いでいる
      const destIndexes = findNormalizeIndexFromSchedule(
        course,
        beforeDestSchedule
      );

      const removeDestSchedules = course.points[
        destIndexes.point
      ].assignedWasteCollectionSchedules.splice(destIndexes.schedule + 1);

      course.points.splice(
        destIndexes.point + 1,
        0,
        createCoursePoint(undefined, schedule.wasteCollectionWorkplace, [
          schedule,
        ])
      );

      course.points.splice(
        destIndexes.point + 2,
        0,
        createCoursePoint(
          undefined,
          removeDestSchedules[0].wasteCollectionWorkplace,
          removeDestSchedules
        )
      );
    },
    () => {
      course.points.push(
        createCoursePoint(undefined, schedule.wasteCollectionWorkplace, [
          schedule,
        ])
      );
    }
  );
};

/**
 * 地点情報を挿入します。
 * @param {object} course コース
 * @param {object} point 対象の地点
 * @param {object} beforeDestSchedule 対象の地点の前のスケジュール
 * @param {object} afterDestSchedule 対象の地点の後のスケジュール
 */
const insertPoint = (course, point, beforeDestSchedule, afterDestSchedule) => {
  checkWasteCollectionWorkplace(
    point.assignedWasteCollectionSchedules[0],
    beforeDestSchedule,
    afterDestSchedule,
    () => {
      // ：移動先の前後に同一の事業場が存在する場合、対象の地点に割り当てる v
      // 前と同じだった
      const destIndexes = findNormalizeIndexFromSchedule(
        course,
        beforeDestSchedule
      );

      course.points[destIndexes.point].assignedWasteCollectionSchedules.splice(
        destIndexes.schedule + 1,
        0,
        ...point.assignedWasteCollectionSchedules
      );
    },
    () => {
      // ：移動先の前後に同一の事業場が存在する場合、対象の地点に割り当てる v
      // 後と同じだった
      const destIndexes = findNormalizeIndexFromSchedule(
        course,
        afterDestSchedule
      );

      course.points[destIndexes.point].assignedWasteCollectionSchedules.splice(
        destIndexes.schedule,
        0,
        ...point.assignedWasteCollectionSchedules
      );
    },
    () => {
      // 　　　　新しい地点としてコースに設定する
      // 　　　　・設定先が一つの地点を跨ぐか？
      //           移動先の前後に同一の事業場が存在しない場合
      // 　　　　　　残ったスケジュールの数が多い方を既存の地点（同値の場合は並び順が上の地点）とし、もう一方を新しい地点とする
      // 前後と違った
      if (beforeDestSchedule) {
        let destIndexes = findNormalizeIndexFromSchedule(
          course,
          beforeDestSchedule
        );

        course.points.splice(destIndexes.point + 1, 0, point);
      } else if (afterDestSchedule) {
        let destIndexes = findNormalizeIndexFromSchedule(
          course,
          afterDestSchedule
        );

        course.points.splice(destIndexes.point, 0, point);
      } else {
        throw new Error("不明なエラー");
      }
    },
    () => {
      // 　　　：移動先の前後に同一の事業場が存在しない場合、そのままの地点でコースに設定する
      // 　　　　・設定先が一つの地点を跨ぐか？
      // 　　　　　：一つの地点を跨ぐ場合
      // 　　　　　　残ったスケジュールの数が多い方を既存の地点（同値の場合は並び順が上の地点）とし、もう一方を新しい地点とする
      // 前後の情報と事業場が違う・跨ぐ
      const destIndexes = findNormalizeIndexFromSchedule(
        course,
        beforeDestSchedule
      );

      const removeDestSchedules = course.points[
        destIndexes.point
      ].assignedWasteCollectionSchedules.splice(destIndexes.schedule + 1);

      course.points.splice(destIndexes.point + 1, 0, point);

      course.points.splice(
        destIndexes.point + 2,
        0,
        createCoursePoint(
          undefined,
          removeDestSchedules[0].wasteCollectionWorkplace,
          removeDestSchedules
        )
      );
    },
    () => {
      course.points.push(point);
    }
  );
};

/**
 * スケジュールの回収場所が同じか確認します。
 * @param {object} targetSchedule 対象のスケジュール
 * @param {object} beforeSchedule 前のスケジュール
 * @param {object} afterSchedule 後のスケジュール
 * @fires sameWasteCollectionWorkplace#onBefore 前と同じだった
 * @fires sameWasteCollectionWorkplace#onAfter 後と同じだった
 * @fires sameWasteCollectionWorkplace#onDifferent 前後違った
 * @fires sameWasteCollectionWorkplace#onDifferentStraddling 前後違っておりかつ事業場が同じ(またぎチェック)
 * @fires sameWasteCollectionWorkplace#onNothing 前後のスケジュールは無かった
 */
const checkWasteCollectionWorkplace = (
  targetSchedule,
  beforeSchedule,
  afterSchedule,
  onBefore,
  onAfter,
  onDifferent,
  onDifferentStraddling,
  onNothing
) => {
  debugLog(`対象: ${targetSchedule?.wasteCollectionWorkplace?.name}
  前: ${beforeSchedule?.wasteCollectionWorkplace?.name}
  後: ${afterSchedule?.wasteCollectionWorkplace?.name}
  `);

  if (!beforeSchedule && !afterSchedule) {
    onNothing && onNothing();
    return;
  }

  if (
    beforeSchedule?.wasteCollectionWorkplace?.id ===
    targetSchedule?.wasteCollectionWorkplace?.id
  ) {
    debugLog("前と同じだった");
    onBefore && onBefore();
    return;
  }

  if (
    afterSchedule?.wasteCollectionWorkplace?.id ===
    targetSchedule?.wasteCollectionWorkplace?.id
  ) {
    debugLog("後と同じだった");
    onAfter && onAfter();
    return;
  }

  if (
    beforeSchedule?.wasteCollectionWorkplace?.id ===
    afterSchedule?.wasteCollectionWorkplace?.id
  ) {
    debugLog("前後と違っており、跨いでいる");
    onDifferentStraddling && onDifferentStraddling();
    return;
  } else {
    debugLog("前後と違った");
    onDifferent && onDifferent();
    return;
  }
};

/**
 * オーダーし直します。
 * @param {object[]} source 対象のリスト
 * @param {number} fromIndex 移動元のインデックス
 * @param {number} toIndex 移動先のインデックス
 * @returns {object[]} オーダーし直したリスト
 */
export const reorder = (source, fromIndex, toIndex) => {
  const result = Array.from(source);
  const [removed] = result.splice(fromIndex, 1);
  result.splice(toIndex, 0, removed);
  return result;
};

/**
 * CourseDestinationを生成します。
 * @param {object} workplace 事業場情報
 * @param {array} orders 回収情報
 * @returns {object} CourseDestination
 */
export const createCoursePoint = (id = v4(), workplace, orders = []) => {
  return {
    id: id,
    workplace: workplace,
    assignedWasteCollectionSchedules: orders,
  };
};

/**
 * リストアイテムを移動させます。
 * @param {object} src 移動元リスト
 * @param {object} dest 移動先リスト
 * @param {number} srcIndex 対象のインデックス
 * @param {number} destIndex 移動先インデックス
 * @returns {object} 移動結果
 */
export const moveListItem = (src, dest, srcIndex, destIndex) => {
  let _src = __.cloneDeep(src);
  let _dest = __.cloneDeep(dest);
  let removed = _src.splice(srcIndex, 1);
  _dest.splice(destIndex, 0, removed[0]);
  return {
    src: _src,
    dest: _dest,
  };
};

/**
 * 未配車へ移動する処理です。
 * @param {object} value 値
 * @param {object} droppedResult ドロップ結果オブジェクト
 * @returns {object}
 */
export const moveToUnassigned = (droppedResult, value) => {
  debugLog("未配車へ移動");
  const result = __.cloneDeep(value);
  const course = findCourse(
    findAssignedCourses(result),
    droppedResult.source.droppableId
  );
  let schedulesOfFlat = flatSchedules(course.points);
  const beforeSchedule = __.cloneDeep(
    schedulesOfFlat[droppedResult.source.index - 1]
  );

  const afterSchedule = __.cloneDeep(
    schedulesOfFlat[droppedResult.source.index + 1]
  );

  const indexes = findNormalizeIndexFromSchedule(
    course,
    schedulesOfFlat[droppedResult.source.index]
  );

  if (
    course.points[indexes.point].assignedWasteCollectionSchedules.length > 1
  ) {
    // スケジュールを抜き出す
    const removed = course.points[
      indexes.point
    ].assignedWasteCollectionSchedules.splice(indexes.schedule, 1)[0];

    result.still.splice(droppedResult.destination.index, 0, removed);
  } else {
    // 地点を抜き出す
    const removed = course.points.splice(indexes.point, 1)[0];

    result.still.splice(
      droppedResult.destination.index,
      0,
      removed.assignedWasteCollectionSchedules[0]
    );
  }

  schedulesOfFlat = flatSchedules(course.points);

  joinBeforeAfterSchedule(course, beforeSchedule, afterSchedule);

  debugLog("結果: ", result);
  return result;
};

/**
 * 同一コースの回収スケジュールを並び替えます。
 * @param {object} value コース情報全体
 * @param {DroppedResult} droppedResult ドラッグ情報
 * @returns {object} 結果
 */
export const reorderOfSchedule = (value, droppedResult) => {
  // 結果
  const result = __.cloneDeep(value);

  // 対象コース
  const course = findCourse(
    findAssignedCourses(result),
    droppedResult.source.droppableId
  );

  // コース内全スケジュール
  let schedulesOfFlat = flatSchedules(course.points);

  // 移動元・移動先スケジュール情報(コピー)
  const srcSchedule = __.cloneDeep(schedulesOfFlat[droppedResult.source.index]);

  // 移動元・移動先インデックス情報
  const srcIndexes = findNormalizeIndexFromSchedule(course, srcSchedule);

  //   ・動かされたスケジュールが割り当てられていた地点は複数のスケジュールを持っていたか？ v
  if (
    course.points[srcIndexes.point].assignedWasteCollectionSchedules.length > 1
  ) {
    debugLog(
      "地点は複数のスケジュールを持っている: ",
      course.points[srcIndexes.point].assignedWasteCollectionSchedules
    );

    // 　：複数のスケジュールを持っていた場合、スケジュールだけ取り出して処理する v
    const removeSchedule = course.points[
      srcIndexes.point
    ].assignedWasteCollectionSchedules.splice(srcIndexes.schedule, 1)[0];

    schedulesOfFlat = flatSchedules(course.points);

    const beforeDestSchedule = __.cloneDeep(
      schedulesOfFlat[droppedResult.destination.index - 1]
    );

    const afterDestSchedule = __.cloneDeep(
      schedulesOfFlat[droppedResult.destination.index]
    );

    insertSchedule(
      course,
      removeSchedule,
      beforeDestSchedule,
      afterDestSchedule
    );
  } else {
    debugLog(
      "地点は複数のスケジュールを持っていない: ",
      course.points[srcIndexes.point].assignedWasteCollectionSchedules
    );

    // 　：複数のスケジュールを持っていなかった場合、地点ごと処理する v
    const removePoint = course.points.splice(srcIndexes.point, 1)[0];

    schedulesOfFlat = flatSchedules(course.points);

    const beforeSrcSchedule = __.cloneDeep(
      schedulesOfFlat[droppedResult.source.index - 1]
    );

    const afterSrcSchedule = __.cloneDeep(
      schedulesOfFlat[droppedResult.source.index]
    );

    const beforeDestSchedule = __.cloneDeep(
      schedulesOfFlat[droppedResult.destination.index - 1]
    );

    const afterDestSchedule = __.cloneDeep(
      schedulesOfFlat[droppedResult.destination.index]
    );

    //         ・動かしたスケジュールの元の位置の前後に同一の事業場が存在するか？
    // 　：元の位置の前後が同一の事業場が存在する場合
    // 　　・既に登録されている（バージョンが設定されている）地点は存在するか？
    // 　　　：既に登録されている地点が存在する場合、バージョン値の高い地点に統合する（同値の場合は並び順が上の地点）
    // 　　　：既に登録されている地点が存在しない場合、並び順が上の地点に統合する
    joinBeforeAfterSchedule(course, beforeSrcSchedule, afterSrcSchedule);

    checkWasteCollectionWorkplace(
      removePoint.assignedWasteCollectionSchedules[0],
      beforeDestSchedule,
      afterDestSchedule,
      () => {
        // ：移動先の前後に同一の事業場が存在する場合、対象の地点に割り当てる v
        // 前と同じだった
        const destIndexes = findNormalizeIndexFromSchedule(
          course,
          beforeDestSchedule
        );

        course.points[
          destIndexes.point
        ].assignedWasteCollectionSchedules.splice(
          destIndexes.schedule + 1,
          0,
          removePoint.assignedWasteCollectionSchedules[0]
        );
      },
      () => {
        // ：移動先の前後に同一の事業場が存在する場合、対象の地点に割り当てる v
        // 後と同じだった
        const destIndexes = findNormalizeIndexFromSchedule(
          course,
          afterDestSchedule
        );

        course.points[
          destIndexes.point
        ].assignedWasteCollectionSchedules.splice(
          destIndexes.schedule,
          0,
          removePoint.assignedWasteCollectionSchedules[0]
        );
      },
      () => {
        // 　　　　新しい地点としてコースに設定する
        // 　　　　・設定先が一つの地点を跨ぐか？
        //           移動先の前後に同一の事業場が存在しない場合
        // 　　　　　　残ったスケジュールの数が多い方を既存の地点（同値の場合は並び順が上の地点）とし、もう一方を新しい地点とする
        // 前後と違った
        if (beforeDestSchedule) {
          let destIndexes = findNormalizeIndexFromSchedule(
            course,
            beforeDestSchedule
          );

          course.points.splice(destIndexes.point + 1, 0, removePoint);
        } else if (afterDestSchedule) {
          let destIndexes = findNormalizeIndexFromSchedule(
            course,
            afterDestSchedule
          );

          course.points.splice(destIndexes.point, 0, removePoint);
        } else {
          throw new Error("不明なエラー");
        }
      },
      () => {
        // 　　　：移動先の前後に同一の事業場が存在しない場合、そのままの地点でコースに設定する
        // 　　　　・設定先が一つの地点を跨ぐか？
        // 　　　　　：一つの地点を跨ぐ場合
        // 　　　　　　残ったスケジュールの数が多い方を既存の地点（同値の場合は並び順が上の地点）とし、もう一方を新しい地点とする
        // 前後の情報と事業場が違う・跨ぐ
        const destIndexes = findNormalizeIndexFromSchedule(
          course,
          afterDestSchedule
        );

        const removeDestSchedules = course.points[
          destIndexes.point
        ].assignedWasteCollectionSchedules.splice(
          destIndexes.schedule
        );

        course.points.splice(destIndexes.point + 1, 0, removePoint);

        course.points.splice(
          destIndexes.point + 2,
          0,
          createCoursePoint(
            undefined,
            removeDestSchedules[0].wasteCollectionWorkplace,
            removeDestSchedules
          )
        );
      }
    );
  }

  debugLog("結果: ", result);

  return result;
};

/**
 * オーダーし直します。
 * @param {object[]} source 対象のリスト
 * @param {DropResult} dropResult DropResultオブジェクト
 * @returns {object[]} オーダーし直したリスト
 */
export const reorderFromDropResult = (source, dropResult) => {
  return reorder(source, dropResult.source.index, dropResult.destination.index);
};

/**
 * 未割当からコースに移動させたときの結果を取得します。
 * @param {object} droppedResult ドロップ結果オブジェクト
 * @param {object} value 値
 * @returns {object}
 */
export const moveToCourse = (droppedResult, value) => {
  debugLog("未割当からコースに移動: ", droppedResult, value);
  const result = __.cloneDeep(value);
  const unassignedSchedules = findUnassignedSchedules(result) ?? [];
  const unassignedSchedule = unassignedSchedules.splice(
    unassignedSchedules.findIndex(
      (item) => item.id === droppedResult.draggableId,
      1
    ),
    1
  )[0];

  const assignCourse = findAssignedCourses(result).find(
    (item) => item.id === droppedResult.destination.droppableId
  );

  const schedulesOfFlat = flatSchedules(assignCourse.points);

  insertSchedule(
    assignCourse,
    unassignedSchedule,
    schedulesOfFlat[droppedResult.destination.index - 1],
    schedulesOfFlat[droppedResult.destination.index]
  );

  debugLog("結果: ", result);

  return result;
};

/**
 * 回収情報を別のコースに移動させます。
 * @param {object} droppedResult ドロップ結果オブジェクト
 * @param {object} value 値
 * @returns {object}
 */
export const changeCourse = (droppedResult, value) => {
  debugLog("回収情報を別コースに移動: ", droppedResult, value);
  // 結果
  const result = __.cloneDeep(value);

  // 全コース情報
  const courses = findAssignedCourses(result);

  // 移動元コース
  const srcCourse = findCourse(courses, droppedResult.source.droppableId);

  // 移動元スケジュール(フラット)
  let srcSchedulesOfFlat = flatSchedules(srcCourse.points);

  const srcBeforeSchedule = __.cloneDeep(
    srcSchedulesOfFlat[droppedResult.source.index - 1]
  );
  const srcAfterSchedule = __.cloneDeep(
    srcSchedulesOfFlat[droppedResult.source.index + 1]
  );

  // 移動元インデックス情報
  let srcIndexes = findNormalizeIndexFromSchedule(
    srcCourse,
    srcSchedulesOfFlat[droppedResult.source.index]
  );

  // 移動先コース
  const destCourse = findCourse(courses, droppedResult.destination.droppableId);

  // 移動先スケジュール(フラット)
  let destSchedulesOfFlat = flatSchedules(destCourse.points);

  // 移動元の地点 | スケジュールを引き抜く
  if (
    srcCourse.points[srcIndexes.point].assignedWasteCollectionSchedules.length >
    1
  ) {
    // スケジュールを引き抜く
    const remove = srcCourse.points[
      srcIndexes.point
    ].assignedWasteCollectionSchedules.splice(srcIndexes.schedule, 1)[0];

    // 結合処理
    joinBeforeAfterSchedule(srcCourse, srcBeforeSchedule, srcAfterSchedule);

    // // 入れ込む
    insertSchedule(
      destCourse,
      remove,
      destSchedulesOfFlat[droppedResult.destination.index - 1],
      destSchedulesOfFlat[droppedResult.destination.index]
    );
  } else {
    // 地点を引き抜く
    const remove = srcCourse.points.splice(srcIndexes.point, 1)[0];

    // 結合処理
    joinBeforeAfterSchedule(srcCourse, srcBeforeSchedule, srcAfterSchedule);

    // 入れ込む
    insertPoint(
      destCourse,
      remove,
      destSchedulesOfFlat[droppedResult.destination.index - 1],
      destSchedulesOfFlat[droppedResult.destination.index]
    );
  }

  debugLog("結果: ", result);

  return result;
};
