import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * チェックシートのテンプレートを作成するコンポーネントです
 * @param {object} value 値
 * @fires Container#onSubmit 送信時
 * @fires Container#onError エラー時
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const TemplateForm = containerPresententer(Container, Presententer);
