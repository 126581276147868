import yup, {
  textSchema,
  remarksSchema,
  emailSchema,
  phoneSchema,
} from "utils/yup";

export const basicSchema = yup.object().shape({
  code: textSchema.nullable(),
  categories: yup.array().of(
    yup.object().shape({
      id: yup.string().required(),
    })
  ).min(1).required(),
  industrySector: yup.object().nullable(),
  name: textSchema.required(),
  phone: phoneSchema.nullable(),
  fax: phoneSchema.nullable(),
  email: emailSchema.nullable(),
  postalCode: yup.string().postalCode().required(),
  prefectures: yup.object().required(),
  city: textSchema.required(),
  streetAddress: textSchema.required(),
  otherAddress: textSchema.required(),
  remarks: remarksSchema.nullable(),
});
