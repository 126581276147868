import { styles } from "./styles";
import useClasses from "utils/useClasses";
import { debugLog } from "utils/log";
import { useForm } from "react-hook-form";
import { basicSchema } from "./Validations/basic";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useImperativeHandle } from "react";
import { companySelector } from "ducks/Company";
import { useSelector } from "react-redux";

/**
 * 処理フローを入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @fires Container#onSubmit 送信時
 * @fires Container#onError エラー時
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  isEditMode = false,
  onSubmit = (data) => debugLog(data),
  onError = (err) => debugLog(err),
  ...props
}) => {
  const classes = useClasses(styles);
  const company = useSelector(companySelector);
  const defaultValues = {
    number: "",
    name: "",
    targetWaste: undefined,
    targetWasteKind: "",
    transportProcesses: [
      {
        section: 1,
        trustee: company,
        method: { code: "1", name: "車両" },
        destination: undefined,
      },
    ],
    disposalProcess: undefined,
    lastDisposalWorkplaces: undefined,
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(basicSchema),
  });

  const { trigger, getValues, reset, formState } = methods;

  useEffect(() => {
    if (value) {
      reset({
        ...defaultValues,
        ...value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      trigger().then((res) => {
        if (res) {
          onSubmit && onSubmit(getValues());
        } else {
          onError(formState.errors);
        }
      });
    },
  }));

  return render({
    classes: classes,
    methods: methods,
    isEditMode: isEditMode,
  });
};
