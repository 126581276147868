import { useOpener } from "utils/useOpener";

/**
 * 車輛管理一覧を表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  xGridParams,
  remindListParams,
  onSelectedVehicle = () => {},
  onSelectedRemind = () => {},
  ...props
}) => {
  const remindList = useOpener();

  const handleSelected = (e) => {
    onSelectedVehicle && onSelectedVehicle(e);
  };

  const handleSelectedRemindListItem = (e) => {
    onSelectedRemind && onSelectedRemind(e);
  };

  return render({
    ...props,
    xGridParams: xGridParams,
    remindListParams: remindListParams,
    remindList: remindList,
    onSelected: handleSelected,
    onRemindListItem: handleSelectedRemindListItem,
  });
};
