import { useEffect, useState } from "react";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { styles } from "./styles";

/**
 * フリーワード検索を表示するコンポーネントです。
 * @param {func} onChangeSearch 検索値を渡すイベントです。
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value = "",
  onChangeSearch = (data) => debugLog(data),
  ...props
}) => {
  const classes = useClasses(styles);
  const [val, setVal] = useState("");

  useEffect(() => {
    setVal(value);
  }, [value]);

  const handleChange = (e) => {
    setVal(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      onChangeSearch(val);
      event.preventDefault();
    }
  };

  const handleClickButtonSearch = () => {
    onChangeSearch(val);
  };

  return render({
    classes: classes,
    value: val,
    onChange: handleChange,
    handleKeyDown: handleKeyDown,
    onClickButtonSearch: handleClickButtonSearch,
    ...props,
  });
};
