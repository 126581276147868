import { styles } from "./styles";
/**
 *ポップアップセレクションでデータを選択するコンポーネントです。
 *引数をうけてJSX Elemntを返します
 *@param {string} label セレクションのラベル
 *@param {string} placeholder プレースホルダー
 *@param {boolean} required 必須かどうかを設定する
 *@param {object} value 選択されたアイテムです
 *@param {func} onChange 選択するアイテムが更新するイベントです
 *@param {array} options アイテム配列
 */
export const Container = ({
  render,
  label = "タイトル",
  hideLabel = false,
  required = true,
  value,
  onChange,
  options = [],
  placeholder = "未選択",
  error,
  disabled = false,
  ...props
}) => {
  const classes = styles();

  return render({
    classes: classes,
    label: label,
    hideLabel: hideLabel,
    placeholder: placeholder,
    required: required,
    value: value,
    onChange: onChange,
    options: options,
    error: error,
    disabled: disabled,
    ...props,
  });
};
