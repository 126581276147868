import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import { grey, green } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * アイコンと名称の表示を行うプレゼンテーションコンポーネントです
 * @param {object} classes クラス
 * @param {JSX} icon icon
 * @param {string} className 初心者をするときにカラス名が必要です。
 * @param {func} onClick ボタンを押すイベント
 * @param {string} label ボタンのラベル
 * @returns　JSX Element
 */
export const Presententer = (props) => {
  const IconElement = props.icon;
  return (
    <Card>
      <CardActionArea onClick={props.onClick}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: 2,
              marginRight: 2,
              color: grey[600],
            }}
          >
            <Box sx={{ m: 1, position: "relative" }}>
              <IconElement sx={{ fontSize: "6rem" }} />
              {props.loading && (
                <CircularProgress
                  size={100}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: -3,
                    left: -3,
                    zIndex: 1,
                  }}
                />
              )}
            </Box>
            <Typography variant="button">{props.label}</Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
