import React from "react";
import { Box, Skeleton } from "@mui/material";
import { AutoSizer } from "react-virtualized";
import { SideMenu } from "views/organisms/Allocation/Schedule/SideMenu";
import { useSelector } from "react-redux";

import { Drivers, Vehicle, Name } from "views/organisms/Allocation";
import { CollectPoints } from "views/organisms/Allocation";
import {
  moveToUnassign,
  ordersByTimeRange,
} from "views/organisms/Allocation/utils";

export const menuList = [
  {
    id: "sortByCollectionTime",
    title: "回収時間で並び替え",
  },
  {
    id: "unAssignAll",
    title: "すべての割り当てを解除",
  },
];

const CourseBox = ({
  course,
  courseNameRef,
  listRef,
  setItems,
  items,
  onChangeDrivers,
  onChangeVehicle,
  handleOnChangeName,
}) => {
  const { vehicles, drivers, isPointLoading } = useSelector(
    (state) => state.Automation
  );

  const handleMenuItemClick = (itemId) => {
    const currentCourseIndex = items?.done?.courses.findIndex(
      (item) => item.id === course.id
    );
    switch (itemId) {
      case menuList[0].id: {
        if (currentCourseIndex !== -1) {
          setItems((prevObj) => ({
            ...prevObj,
            done: ordersByTimeRange(items.done, currentCourseIndex),
          }));
        }
        break;
      }
      case menuList[1].id: {
        setItems(moveToUnassign(items, currentCourseIndex));
        break;
      }
      default:
        break;
    }
  };

  if (!course) return null;

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        p={1}
        backgroundColor="rgba(0, 0, 0, 0.06)"
      >
        <Box className="horizontalScroll" flexGrow={1} ml={1}>
          <Name
            size="small"
            value={course?.name}
            onChange={handleOnChangeName}
            courseRef={courseNameRef}
          />
        </Box>
        <Box className="horizontalScroll">
          <SideMenu
            id={course?.id}
            title="配車リスト"
            menuList={menuList.map((item) => {
              return {
                ...item,
                disabled: !course.points?.length||isPointLoading,
              };
            })}
            value={course}
            handleMenuItemClick={handleMenuItemClick}
          />
        </Box>
      </Box>
      <Box
        className="horizontalScroll"
        p={1}
        backgroundColor="rgba(0, 0, 0, 0.06)"
      >
        <Drivers
          value={course?.assignedUsers}
          onChange={onChangeDrivers}
          drivers={drivers}
          size="small"
        />
      </Box>
      <Box
        className="horizontalScroll"
        p={1}
        backgroundColor="rgba(0, 0, 0, 0.06)"
      >
        <Vehicle
          value={course?.assignedVehicle}
          onChange={onChangeVehicle}
          vehicles={vehicles}
          size="small"
        />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          height: "calc(100% - 150px)",
          overflow: "hidden",
        }}
      >
        {!isPointLoading && (
          <AutoSizer>
            {({ width, height }) => (
              <CollectPoints
                maxHeight={height - 10}
                maxWidth={width}
                value={course?.points ?? []}
                droppableId="course"
                listRef={listRef}
                showMissingMessage
              />
            )}
          </AutoSizer>
        )}
        {isPointLoading &&
          Array.from({ length: 3 }).map((_, index) => (
            <Skeleton
              key={index}
              sx={{
                borderRadius: 1,
                marginBottom: 1,
                marginTop: 1,
                marginRight: 2,
                marginLeft: 1,
              }}
              variant="rectangular"
              height="30%"
              animation="wave"
            />
          ))}
      </Box>
    </>
  );
};

export default CourseBox;
