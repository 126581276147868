import { createSlice } from "@reduxjs/toolkit";
import { fetchCompany, updateCompany } from "./api";
import { createSelector } from "reselect";

/*
  object shape
    corporateNumber
    createdAt
    establishmentedOn
    id
    name
    phoneticGuidesOfName
    representativeName
    subscriberId
    updatedAt
    url
    headOffice
      city
      code
      companyId
      createdAt
      email
      fax
      id
      industrySector
        id
        middleClassCode
        name
      name
      otherAddress
      phone
      postalCode
      prefectures
        code
        name
      remarks
      streetAddress
      updatedAt
*/

const companySlice = createSlice({
  name: "company",
  initialState: {
    data: null,
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompany.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchCompany.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchCompany.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      })
      .addCase(updateCompany.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

/**
 * ストアから事業者を取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.Company;

/**
 * ストアから事業者情報を取得します。
 */
export const companySelector = createSelector(
  selector,
  (company) => company.data
);

/**
 * ストアから読み込みを取得します。
 */
export const isLoadingSelector = createSelector(
  selector,
  (company) => company.isLoading
);

/**
 * ストアからエラーを取得します。
 */
export const errorSelector = createSelector(
  selector,
  (company) => company.error
);

export default companySlice.reducer;
