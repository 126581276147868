import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * テキストを表示するコンポーネントです
 * @param {string} value 値
 * @param {string} label ラベル
 */
export const TextField = containerPresententer(Container, Presententer);
