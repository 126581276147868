/**
 * 指定されたオブジェクトから廃棄物名を取得します。
 * @param {object} value オブジェクト
 * @returns {string} 廃棄物名
 */
export const getWasteFullName = (value) => {
  if (!value) {
    return "";
  }

  const format = (wasteClassName) =>
    !value.name ? wasteClassName : `[${wasteClassName}] ${value.name}`.trim();

  const { largeClass, middleClass, smallClass } = value.type;

  return smallClass?.name !== ""
    ? format(smallClass?.name)
    : middleClass?.name !== ""
    ? format(middleClass?.name)
    : format(largeClass?.name);
};
