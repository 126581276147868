import reducer from "./slice";
import {
  selector,
  userSelector,
  isLoadingSelector,
  errorSelector,
} from "./slice";
import { fetchUser } from "./api";

export default reducer;

export { userSelector, selector, isLoadingSelector, errorSelector, fetchUser };
