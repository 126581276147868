import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { styles } from "./styles";
import { basicSchema } from "./Validations/basic";

const defaultValues = {
  file: null,
  category: null,
  receivedAt: null,
  issuer: null,
  remarks: null,
};

// UNDONE: 資料のカテゴリとは何か？
const DocumentCategories = [
  {
    code: "1",
    name: "test1",
  },
  {
    code: "2",
    name: "test2",
  },
];

/**
 * 資料を入力するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onSubmit = (data) => debugLog(data),
  onError = (err) => debugLog(err),
  ...props
}) => {
  const classes = useClasses(styles);
  const [open, setOpen] = useState(false);
  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    methods?.reset({
      ...defaultValues,
      ...value,
    });
  }, [methods, value]);

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      methods.trigger().then((res) => {
        if (res) {
          onSubmit(methods.getValues());
        } else {
          onError(methods.formState.errors);
        }
      });
    },
  }));

  const handleDeleteFile = () => {
    methods.setValue("file", null);
    setOpen(false);
  };

  return render({
    classes: classes,
    value: value,
    control: methods.control,
    onDeleteFile: handleDeleteFile,
    open: open,
    onOpenFile: () => setOpen(true),
    onCloseFile: () => setOpen(false),
    DocumentCategories: DocumentCategories,
    ...props,
  });
};
