import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";
/**
 * ドラワーを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} childer
 * @param {String} titleDrawer プロパティ
 * @param {number} width
 */
export const DrawerOnRight = containerPresententer(Container, Presententer);
