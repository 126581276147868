import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { useSelector } from "react-redux";
import { getQueingTasks } from "api/graphql/queries";
import { companySelector } from "ducks/Company";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { styles } from "./styles";

/**
 * 配車のメニュー選択を行う画面を表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [queingCount, setQueingCount] = useState(0);
  const [error, setError] = useState(null);
  const classes = useClasses(styles);
  const navigate = useNavigate();
  const location = useLocation();
  const { id: companyId } = useSelector(companySelector);

  if(error){
    throw new Error(error);
  }

  const fetchQueueTasks = () => {
    setLoading(true);
    API.graphql(
      graphqlOperation(getQueingTasks, {
        companyId: companyId,
      })
    )
      .then((response) => {
        const result = response.data.getQueingTasks;
        if (result.length) {
          setShowAlert(true);
          setQueingCount(result.length);
        } else {
          navigate(`${location.pathname}/setting`);
        }
      })
      .catch((err) => {
        setLoading(false);
        debugLog("templates/setting/container.js error:", err);
        setError(err)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClick = (param) => {
    switch (param) {
      case "setting":
        fetchQueueTasks();
        break;
      case "schedule":
        navigate(`${location.pathname}/${param}`);
        break;

      default:
        break;
    }
  };

  return render({
    classes: classes,
    onClick: handleClick,
    showAlert: showAlert,
    queingCount: queingCount,
    closeAlert: () => setShowAlert(false),
    loading: loading,
    ...props,
  });
};
