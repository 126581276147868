import { Button, FormHelperText, Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { RenderButtonToolBar } from "utils/XgridExpand";
import { ModalPopup, XGridComponent } from "views/molecules";
import { CollectForm } from "../CollectForm";

const Form = React.forwardRef((props, ref) => (
  <CollectForm {...props} _ref={ref} />
));

/**
 * 回収サイクル情報を入力するプレゼンテーションコンポーネントです。
 * @param {object} props その他プロパティ
 * @returns {JSX.Elemnt}
 */
export const Presententer = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Controller
          name={"cycles"}
          control={props.control}
          render={({ field: { value }, fieldState: { error } }) => (
            <>
              <XGridComponent
                rows={value ?? []}
                columns={props.columns}
                onSearcher={false}
                onDownload={false}
                onColumns={false}
                onFilter={false}
                multipleSelection={false}
                checkboxSelection={false}
                disableSelectionOnClick={true}
                actionButton={
                  <RenderButtonToolBar
                    label={"回収サイクルを追加"}
                    onClick={props.onOpenModal}
                  />
                }
              />
              <FormHelperText error={Boolean(error)}>
                {error?.message}
              </FormHelperText>
            </>
          )}
        />
        <ModalPopup open={props.open} onClose={props.onClose}>
          <div className={props.classes.title}>
            {props.selectedValue ? "回収サイクルを編集" : "回収サイクルを追加"}
          </div>
          <div className={props.classes.context}>
            <Form
              value={props.selectedValue?.data}
              onSubmit={props.onSubmit}
              ref={props.formRef}
            />
          </div>
          <div className={props.classes.actions}>
            <Button
              variant="outlined"
              color="primary"
              onClick={props.onCloseModal}
              sx={{
                marginRight: "10px",
              }}
            >
              {"キャンセル"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={props.onDecided}
            >
              {"決定"}
            </Button>
          </div>
        </ModalPopup>
      </Grid>
    </Grid>
  );
};
