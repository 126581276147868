import { createAsyncThunk } from "@reduxjs/toolkit";
import { listContainerDisuseTypes } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";

/**
 * 取得します。
 * @returns {object}
 */
const fetch = async () => {
  let result = null;
  try {
    const fetchedData = await API.graphql(
      graphqlOperation(listContainerDisuseTypes)
    );
    result = fetchedData.data.listContainerDisuseTypes.items;
  } catch (error) {
    throw error;
  }
  return result;
};

export const fetchContainerDisuseTypes = createAsyncThunk(
  "containerDisuseTypes/fetch",
  async (_, thunkApi) => {
    return fetch();
  }
);
