import { Box } from "@mui/material";
import React from "react";
import { FixedSizeList } from "react-window";
import { NoPlanWarning } from "../NoPlanWarning";
import { PlanCourse } from "../PlanCourse";
import { AutoSizer } from "react-virtualized";
import clsx from "clsx";

const RenderPlanCourse = (props) => {
  const { data, index, style, height, width } = props;

  return (
    <div style={style}>
      <PlanCourse value={data.items[index]} height={height} width={width} />
    </div>
  );
};

/**
 * 配車計画を表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <>
      {props?.value?.done?.courses?.length <= 0 && <NoPlanWarning />}
      {props?.value?.done?.courses?.length > 0 && (
        <Box>
          <div style={{ height: "calc(100vh - 250px)" }}>
            <AutoSizer>
              {({ height, width }) => {
                const visibleScrollbar =
                  props.value?.done?.courses?.length * 270 > width;
                return (
                  <FixedSizeList
                    className={clsx("scrollTarget", "allocation_list")}
                    height={height}
                    itemCount={props?.value?.done?.courses?.length}
                    itemData={{
                      items: props.value?.done?.courses,
                    }}
                    itemSize={270}
                    width={width}
                    layout="horizontal"
                  >
                    {(props) =>
                      RenderPlanCourse({
                        ...props,
                        width,
                        height: height - (visibleScrollbar ? 17 : 0),
                      })
                    }
                  </FixedSizeList>
                );
              }}
            </AutoSizer>
          </div>
        </Box>
      )}
    </>
  );
};
