import { updateVehiclePeriodicInspection } from "api/graphql/mutations";
import { getVehiclePeriodicInspection } from "api/graphql/queries";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toApiValue, toFormValue } from "utils/ImageFunctions";
import { useMutation } from "utils/useMutation";
import { useOpener } from "utils/useOpener";
import { useQuery } from "utils/useQuery";

/**
 * モバイルの車輛点検詳細を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const [formValue, setFormValue] = useState(null);
  const { id, inspectionId } = useParams();
  const vehicleInspectionForm = useOpener();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/vehicle-inspection-list/${id}`);
  };

  const checksheet = useQuery({
    query: getVehiclePeriodicInspection,
    variables: {
      id: inspectionId,
    },
    onCompleted: (data) => {
      toFormValue({
        files: data?.data?.getVehiclePeriodicInspection?.files,
      }).then((files) => {
        setFormValue({
          ...data?.data?.getVehiclePeriodicInspection,
          files: files,
        });
      });
    },
  });

  const [updateMutation, updateMutationStatus] = useMutation(
    updateVehiclePeriodicInspection,
    {
      onCompleted: (params) => {
        checksheet.refetch({
          id: inspectionId,
        });
        vehicleInspectionForm.toggle(false);
      },
      succeedMessage: "保存しました。",
      errorMessage: "エラーが発生したため、保存できませんでした。",
    }
  );

  const onUpdate = async (data) => {
    const { personInChargeName, checkedOn, remarks, files, sections } = data;

    updateMutation({
      input: {
        id: checksheet?.data?.getVehiclePeriodicInspection?.id,
        personInChargeName: personInChargeName,
        checkedOn: checkedOn,
        remarks: remarks,
        files: await toApiValue({ files: files }),
        sections: sections?.map((section) => ({
          ...section,
          items: section?.items?.map((item) => ({
            ...item,
            name: undefined,
          })),
          name: undefined,
        })),
        expectedVersion:
          checksheet?.data?.getVehiclePeriodicInspection?.version,
      },
    });
  };

  return render({
    ...props,
    vehicleInspectionForm: vehicleInspectionForm,
    loading: checksheet.loading || updateMutationStatus.loading,
    onBack: handleBack,
    value: formValue,
    onUpdate: onUpdate,
  });
};
