import { Button, Chip, DialogContentText, Stack } from "@mui/material";
import React from "react";
import { ConfirmDialog, FullScreenDialog } from "views/molecules";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { Caption } from "views/organisms/DetailsFrame/ChildComponents";
import { PrecautionRegistrationForm } from "views/organisms/Forms";
import { CollectionPrecautionDetailsTabs } from "views/organisms/Masters/Partner";

/**
 * 回収注意点詳細画面を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  classes,
  tab = 0,
  getTab,
  value,
  isOpenEditDialog,
  isOpenDeleteDialog,
  onOpenEditDialog,
  onOpenDeleteDialog,
  onCloseEditDialog,
  onCloseDeleteDialog,
  onUpdate,
  onDelete,
  isSubmit,
  workplace,
  client,
  ...props
}) => (
  <div>
    <DetailsFrame
      caption={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Caption title={value?.name} />
          {value?.tags && (
            <Stack direction="row" spacing={1}>
              {value?.tags.map((tag, index) => (
                <Chip label={tag} key={index} />
              ))}
            </Stack>
          )}
        </div>
      }
      actions={
        tab === 0 &&
        (client?.state
          ? client?.state !== "removed"
          : false || workplace?.state
          ? workplace?.state !== "removed"
          : false) ? (
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="error"
              onClick={onOpenDeleteDialog}
              disabled={!value}
            >
              {"回収注意点を削除する"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onOpenEditDialog}
              disabled={!value}
            >
              {"回収注意点を編集する"}
            </Button>
          </Stack>
        ) : (
          <></>
        )
      }
    >
      <CollectionPrecautionDetailsTabs value={value} onChangeTab={getTab} />
      <FullScreenDialog
        isSubmit={isSubmit}
        disabled={isSubmit}
        open={isOpenEditDialog}
        onClose={onCloseEditDialog}
        title={`回収注意点の編集`}
        textConfirm="保存"
        formId="EditPrecaution"
      >
        <PrecautionRegistrationForm
          id={"EditPrecaution"}
          onSubmit={onUpdate}
          value={value}
        />
      </FullScreenDialog>
      <ConfirmDialog
        title="削除します。よろしいですか？"
        message={
          <DialogContentText>
            削除されたデータを元に戻すことはできません。
          </DialogContentText>
        }
        open={isOpenDeleteDialog}
        positiveText={"削除"}
        negativeText={"キャンセル"}
        onPositive={onDelete}
        onNegative={onCloseDeleteDialog}
        onClose={onCloseDeleteDialog}
      />
    </DetailsFrame>
  </div>
);
