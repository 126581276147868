/*
 * object shape
 *   start: [javascript date]
 *   end: [javascript date]
 */

/**
 * 時間の期間を入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string} label ラベル
 * @param {boolean} error エラーであるか
 * @param {string} helperText 補助的テキスト
 * @param {object} value 値
 * @fires Container#onChange 変更時
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  label,
  error,
  helperText,
  value,
  onChange,
  minutesStep,
  ...props
}) => {
  const handleChange = (id) => (newValue) => {
    onChange &&
      onChange({
        ...value,
        [id]: newValue,
      });
  };

  return render({
    label: label,
    error: error,
    helperText: helperText,
    value: value,
    onChange: handleChange,
    minutesStep: minutesStep,
    ...props,
  });
};
