import { Box, Button, InputAdornment, TextField } from "@mui/material";
import clsx from "clsx";

/**
 * テキストフィルドの表示を行うコンテナプレゼンテーションです
 * @param {string} label テキストのレベル
 * @param {boolean} showIcon テキストの頭にアイコンを表示します　デフォルト：true
 * @param {object} classes クラス名
 * @param {funct} onChange データ更新を処理する
 * @param {boolean} required 必須かどうか設定
 * @param {string} value テキストフィールドのバリュー
 * @param {boolena} error エラー
 * @param {func} onOpenModal モーダルを開くイベント
 * @param {boolean} disabledButtom ボータンを無効にする
 * @param {string} textConfirm ボータンの文字
 * @returns
 */
export const Presententer = ({
  label,
  onChange,
  showIcon = true,
  value,
  classes,
  required = false,
  textConfirm = "変更",
  onOpenModal,
  error,
  disabledButtom = false,
  ...props
}) => {
  const inputValue = Array.isArray(value)
    ? value?.map((v) => v.name)?.join("、") ?? []
    : value?.name ?? "";
  return (
    <Box display="flex" flexDirection="column" fontSize="14px">
      <div className={classes.label}>
        {label} {required ? <span className={classes.required}>必須</span> : ""}
      </div>
      <TextField
        size="medium"
        variant="outlined"
        value={inputValue}
        onChange={onChange}
        className={clsx(
          classes.style_inputField,
          classes.style_inputField02,
          error && classes.style_inputField_error
        )}
        required={required}
        inputProps={{ readOnly: true }}
        error={Boolean(error)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={onOpenModal}
                disabled={disabledButtom}
              >
                {textConfirm}
              </Button>
            </InputAdornment>
          ),
        }}
        helperText={error?.message ?? ""}
        {...props}
      />
    </Box>
  );
};
