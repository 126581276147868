import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Button, IconButton, Typography } from "@mui/material";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

/**
 * 期間を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  label,
  error,
  helperText,
  id,
  value,
  onChange,
  ...props
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingBottom: "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <IconButton
            color="default"
            size="large"
            aria-label="左"
            onClick={props.onClickLeft}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <LocalizationProvider
            dateAdapter={AdapterLuxon}
            adapterLocale={"jp-JP"}
          >
            <MobileDatePicker
              inputFormat="yyyy/MM/dd"
              showToolbar={false}
              value={props?.date}
              onChange={props?.onChangeDate}
              onAccept={props?.onAccept}
              renderInput={({ inputProps }) => {
                return (
                  <Button
                    variant="text"
                    color="inherit"
                    onClick={inputProps.onClick}
                  >
                    <Typography variant="h6">
                      {props.text}
                    </Typography>
                  </Button>
                );
              }}
            />
          </LocalizationProvider>
          <IconButton
            color="default"
            size="large"
            aria-label="右"
            onClick={props.onClickRight}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
          <Button
            color="primary"
            variant="outlined"
            onClick={props.onClickThis}
          >
            {props.mode === "day"
              ? "今日"
              : props.mode === "month"
              ? "今月"
              : ""}
          </Button>
        </div>
        <div>{props.actions}</div>
      </div>
      {props.children(value)}
    </>
  );
};
