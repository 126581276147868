import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { styles } from "./styles";
import { transportMethodsSelector } from "ducks/TransportMethods";
import useClasses from "utils/useClasses";

/**
 * 運搬区間情報を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {number} index インデックス
 * @fires Container#onChange 変更時
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, index, onChange, ...props }) => {
  const classes = useClasses(styles);
  const { control, setValue, watch } = useFormContext();
  const [open, setOpen] = useState();
  const [selectedValue, setSelectedValue] = useState(null);
  const transportMethods = useSelector(transportMethodsSelector);

  const handleSelectedClient = (params) => {
    setSelectedValue(params);
  };

  const handleDecidedClient = () => {
    setValue(`transportProcesses[${index}].trustee`, selectedValue?.[0]);
    setOpen(false);
    onChange(selectedValue?.[0]);
  };

  const handleSelectedClientWorkplace = (params) => {
    setSelectedValue(params);
  };

  const handleDecidedClientWorkplace = () => {
    if (selectedValue) {
      setValue(`transportProcesses[${index}].destination`, selectedValue[0]);
      setOpen(false);
      onChange(selectedValue[0]);
    }
  };

  return render({
    classes: classes,
    index: index,
    control: control,
    open: open,
    onOpenModal: (name, value) => (e) => {
      setOpen(name);
      setSelectedValue(value);
    },
    onCloseModal: () => {
      setOpen(null);
      setSelectedValue(null);
    },
    selectedValue: selectedValue,
    transportationMethodOptions: transportMethods ?? [],
    onSelectedClient: handleSelectedClient,
    onDecidedClient: handleDecidedClient,
    onSelectedClientWorkplace: handleSelectedClientWorkplace,
    onDecidedClientWorkplace: handleDecidedClientWorkplace,
    current: watch(),
    ...props,
  });
};
