import { searchWasteManagementMethodsByOffset } from "api/graphql/queries";
import { useQuery } from "utils/useQuery";
import { Layout } from "./Layout";
import { useEffect } from "react";

/**
 * フローを選択するコンポーネントです。
 * @param {object} props プロパティ
 * @param {string} props.id フローid
 * @param {object} props.value 値
 * @fires Selector#onChange 変更時
 * @fires Selector#onClose 閉じる時
 * @fires Selector#onNotificationLoadingChange 読み込み状態変更時
 * @returns {JSX.Element}
 */
export const Selector = ({
  id,
  value,
  onChange = () => {},
  disabled = false,
  onNotificationLoadingChange = () => {},
}) => {
  const wasteManagementMethods = useQuery({
    query: searchWasteManagementMethodsByOffset,
    variables: {
      managedWasteId: id,
      filter: {
        and: [
          {
            state: { eq: "active" },
          },
        ],
      },
    },
  });

  useEffect(() => {
    onNotificationLoadingChange(wasteManagementMethods.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasteManagementMethods.loading]);

  return (
    <Layout
      id={id}
      value={value}
      loading={wasteManagementMethods?.loading}
      disabled={disabled}
      options={
        wasteManagementMethods?.data?.searchWasteManagementMethodsByOffset
          ?.items
      }
      onChange={onChange}
    />
  );
};
