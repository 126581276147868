import { Alert, Skeleton } from "@mui/material";
import { listMunicipalSolidWasteLicenseBusinessCategories } from "api/graphql/queries";
import { debugLog } from "utils/log";
import { useQuery } from "utils/useQuery";
import { SelectPopup } from "views/molecules";

/**
 * 業の区分を選択するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {boolean} props.error エラー
 * @param {boolean} props.disabled 無効設定
 * @returns JSX
 */
export const CategorySelector = ({
  value,
  onChange = (data) => debugLog(data),
  error = false,
  disabled = false,
}) => {
  const {
    data,
    error: apiError,
    loading,
  } = useQuery({
    query: listMunicipalSolidWasteLicenseBusinessCategories,
  });

  if (loading) {
    return <Skeleton width={210} height={50} />;
  }

  if (apiError) {
    return <Alert severity="error">{apiError}</Alert>;
  }

  return (
    <SelectPopup
      label="業の区分"
      value={value}
      onChange={onChange}
      required={true}
      error={error}
      disabled={disabled}
      options={
        data?.listMunicipalSolidWasteLicenseBusinessCategories?.items ?? []
      }
      optionValueField="code"
      optionNameField="name"
    />
  );
};
