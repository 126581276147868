import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * テキストを変更するダイアログを表示するコンポーネントです。
 * @param {boolean} open ダイヤログをオープンかオフにする
 * @param {func} onClose ダイヤログを閉じる
 * @param {string} value テキスト値
 * @param {string} label ラベル
 * @param {func} getOnchange 更新される値を取得します
 */
export const TextFieldEditDialog = containerPresententer(
  Container,
  Presententer
);
