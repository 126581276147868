/**
 * 条件で表示するコンポーネントを切り替えるコンポーネントです。
 * @param {object} porps プロパティ
 * @param {JSX.Element} props.children true時に表示するコンポーネント
 * @param {boolean} props.condition 表示条件
 * @param {JSX.Element} props.elseComponent false時に表示するコンポーネント
 * @returns {JSX.Element}
 */
export const If = ({ children, condition = false, elseComponent = null }) => {
  return condition ? children : elseComponent;
};
