import React from "react";
import { Simple } from "./Simple";
import { Detail } from "./Detail";
import { FullScreenDialog } from "views/molecules";
import { Button } from "@mui/material";

const SimpleForm = React.forwardRef((props, ref) => (
  <Simple {...props} _ref={ref} />
));

const DetailForm = React.forwardRef((props, ref) => (
  <Detail {...props} _ref={ref} />
));

/**
 * 紙マニフェストの入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <FullScreenDialog
    title={props.title}
    textConfirm={"保存"}
    open={props.open}
    onClose={props.onClose}
    container={false}
    disabled={props.state !== "detail" || props.isLoading}
    isSubmit={props.isLoading}
    action={
      <>
        <Button
          sx={{
            marginRight: 2,
          }}
          variant={"contained"}
          color="info"
          onClick={props.onBefore}
          disabled={props.state !== "detail" || props.isLoading}
        >
          {"前へ"}
        </Button>
        <Button
          sx={{
            marginRight: 2,
          }}
          variant={"contained"}
          color="success"
          onClick={props.onNext}
          disabled={props.state !== "simple" || props.isLoading}
        >
          {"次へ"}
        </Button>
      </>
    }
    onClickSubmit={props.onSave}
  >
    {props.state === "simple" && (
      <SimpleForm
        value={props.value}
        onNext={props.receivedFormSimple}
        ref={props.simpleFormRef}
      />
    )}
    {props.state === "detail" && (
      <DetailForm
        value={props.value}
        onSubmit={props.onSubmit}
        onError={props.onError}
        ref={props.detailFormRef}
      />
    )}
  </FullScreenDialog>
);
