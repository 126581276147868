import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { FileThumbnail } from "views/atoms";
import { ModalPreview } from "./ModalPreview";

/**
 * ファイルギャラリーを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {array} props.value ファイル配列
 * @param {boolean} props.disabled 無効設定
 * @param {number} props.thumbnailWidth サムネイルの幅
 * @param {number} props.thumbnailHeight サムネイルの高さ
 * @fires FileGallery#onChange 変更時
 * @returns {JSX.Element}
 */
export const FileGallery = ({
  value = [],
  onChange = () => {},
  disabled = false,
  thumbnailWidth = 144,
  thumbnailHeight = 144,
  actions = <></>,
}) => {
  const [fileIndex, setFileIndex] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
    setFileIndex(0);
  }, []);

  const handleClickFileThumbnail = (index) => () => {
    setFileIndex(index);
    setOpen(true);
  };

  const handleDelete = (index) => () => {
    let result = [...value];
    result.splice(index, 1);
    onChange(result);
  };

  if (!value || value.length <= 0) {
    return <></>;
  }

  return (
    <>
      <Box display="flex" flexDirection={"row"} flexWrap="wrap">
        {value.map((file, index) => (
          <FileThumbnail
            key={file?.id ?? index}
            id={file?.id}
            name={file?.name}
            label={file?.label}
            disabled={disabled}
            loading={file?.loading}
            src={file?.src}
            onClick={handleClickFileThumbnail(index)}
            onDelete={handleDelete(index)}
            width={thumbnailWidth}
            height={thumbnailHeight}
          />
        ))}
      </Box>
      <ModalPreview
        open={open}
        onChange={onChange}
        onClose={() => setOpen(false)}
        value={value}
        index={fileIndex}
        actions={actions}
        disabled={disabled}
      />
    </>
  );
};
