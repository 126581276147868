import { styles } from "./styles";

/**
 * 引数を受けてJSX Elemntを返します。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({ render, ...props }) => {
  const classes = styles();

  return render({
    classes: classes,
    ...props,
  });
};
