import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PersonIcon from "@mui/icons-material/Person";
import { Box, Chip, Skeleton, Stack, Typography, Card } from "@mui/material";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { DateTime } from "luxon";
import { XGridComponent } from "views/molecules";
import { Form as QuantityForm } from "features/Collects/Records/Pc/Form";

/**
 *  回収記録表の表示を行うプレゼンテーションコンポーネントです。
 * @param {object} props
 * @returns JSX 要素
 */
export const Presententer = (props) => {
  return (
    <>
      <Stack spacing={2}>
        {props?.journal === null ? (
          <Skeleton variant="rectangular" height={40} />
        ) : (
          <Stack direction="row" alignItems="center" spacing={2} height={40}>
            <Typography variant="h5" fontWeight={600}>
              {props.journal.name}
            </Typography>
            <Typography variant="h6" color="text.secondary">
              {DateTime.fromFormat(props.journal.departureDate, "yyyy-MM-dd")
                .setLocale("jp-JP")
                .toFormat("yyyy/MM/dd (EEE)")}
              {props.getOdometers(props.journal)}
            </Typography>
          </Stack>
        )}
        <Box>
          {props?.journal === null ? (
            <Stack direction="row" spacing={1}>
              <Skeleton variant="rectangular" width={200} height={32} />
              <Skeleton variant="rectangular" width={200} height={32} />
              <Skeleton variant="rectangular" width={200} height={32} />
            </Stack>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                gap: 1,
              }}
            >
              {props.journal?.assignedUsers &&
                props.journal.assignedUsers?.map((user, index) => (
                  <Chip
                    key={index}
                    icon={<PersonIcon />}
                    label={user.name}
                    color="success"
                    sx={{
                      mr: 1,
                    }}
                  />
                ))}
              {props.journal?.assignedVehicle && (
                <Chip
                  icon={<DirectionsCarIcon />}
                  label={props.journal?.assignedVehicle?.number}
                  color="info"
                />
              )}
              <Card
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: 60,
                  width: 65,
                  cursor: "pointer",
                  background: "#fff",
                }}
                onClick={props.onExportCsv}
                variant="outlined"
              >
                <CloudDownloadOutlinedIcon fontSize="large" color="action" />
                <Typography fontSize={10} fontWeight={600}>
                  エクスポート
                </Typography>
              </Card>
            </Box>
          )}
        </Box>
        {props?.journal === null ? (
          <Skeleton variant="rectangular" height={400} />
        ) : (
          <XGridComponent
            onChangeSelection={props.onSelectedRow}
            rows={props.gridRows}
            columns={props.gridColumns}
            onToolbar={false}
            pagination={false}
            checkboxSelection={false}
            multipleSelection={false}
            disableSelectionOnClick={false}
            disableColumnMenu={true}
            pageSizeDisplay={10}
            apiRef={props.apiRef}
          />
        )}
      </Stack>
      <QuantityForm
        open={!!props.selectedValue}
        onClose={props.onCloseForm}
        onCompleted={props.onChangeQuantity}
        item={props.selectedValue?.item}
        task={props.selectedValue?.task}
      />
    </>
  );
};
