import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";
import { XGridComponent } from "views/molecules";
import { debugLog } from "utils/log";

const state = {
  waiting: "waiting",
  success: "success",
};

/**
 * CsvExporter Component
 *
 * The CsvExporter component provides a button that, when clicked, exports data to a CSV file.
 * It supports both fetching data from a server using the provided fetchData function
 * and using pre-existing data directly.
 *
 * @param {Object} props - The properties of the CsvExporter component.
 * @param {Function} props.fetchData - A function that fetches data from a server.
 *        If provided, fetchData will be used, and data prop will be ignored.
 * @param {Array} props.data - Pre-existing data to be used if fetchData is not provided.
 * @param {string} props.filename - The name of the exported CSV file.
 *        Defaults to "csv-data-file.csv" if not provided.
 * @param {Array} props.columns - An array of column definitions for the XGridComponent.
 * @param {ReactNode} props.children - A function that returns a React component.
 *        This function receives props that include onClick event.
 * @returns JSX element
 */
const CsvExporter = ({ fetchData, data, filename, children, columns }) => {
  const [csvExportData, setCsvExportData] = useState([]);
  const [requestState, setRequestState] = useState(state.waiting);
  const apiRef = useGridApiRef();

  useEffect(() => {
    if (csvExportData.length && requestState !== state.success) {
      try {
        const gridApi = apiRef.current;
        if (gridApi) {
          gridApi.exportDataAsCsv({
            fileName: filename || "csv-data-file",
            allColumns: true,
            includeHeaders: true,
            delimiter: ",",
            utf8WithBom: true,
          });
        }
        setRequestState(state.success);
        setCsvExportData([]);
      } catch (error) {
        debugLog("csvExport.error", error);
      }
    }
  }, [apiRef, csvExportData, filename, requestState]);

  const exportToCsv = async () => {
    setCsvExportData([]);
    try {
      const apiData = fetchData ? await fetchData() : data;
      setRequestState(state.waiting);
      setCsvExportData(apiData);
    } catch (error) {
      debugLog("getJournals.fetch.error", error);
    }
  };

  return (
    <>
      {/* The XGridComponent is wrapped in a Box with display: "none"
          to render it in the DOM but make it not visible */}
      <Box
        sx={{
          display: "none",
        }}
      >
        <XGridComponent
          rows={csvExportData}
          columns={columns}
          apiRef={apiRef}
        />
      </Box>
      {/* The children prop is cloned with an added onClick event to trigger exportToCsv */}
      {React.cloneElement(children, { onClick: exportToCsv })}
    </>
  );
};

CsvExporter.propTypes = {
  data: PropTypes.array,
  filename: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.element.isRequired,
  fetchData: PropTypes.func,
};

export default CsvExporter;
