export const useStyles = ((theme) => ({
  box_search: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
    "& >.MuiFormControl-root": {
      maxWidth: "350px",
    },
    "& input": {
      padding: "10.5px 14px",
    },
  },
}));
