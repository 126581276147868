import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 事業者・事業場情報を表示するコンポーネントです
 * @param {object} submit 事業所・事業場を登録するフォームをサブミット
 * @param {object} onSubmitted フォームのデータを取得する
 * @param {object} value 現在の事業場情報です
 * @param {array} dataTable テーブルのデータ
 * @param {string} titleTable モーダルの時にテーブルのタイトルが表示する
 * @param {boolean} checkboxSelection 行頭にチェックボックスを表示する
 */
export const WorkplaceTable = containerPresententer(Container, Presententer);
