import { useStyles } from "./styles";
import useClasses from "utils/useClasses";

/**
 * 情報の項目を表示するコンポーネントです。
 * @param {string} label ラベル
 * @returns {JSX.Element}
 */
export const Container = ({ render, label = "", ...props }) => {
  const classes = useClasses(useStyles);

  return render({
    classes: classes,
    label: label,
    ...props,
  });
};
