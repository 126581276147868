import { TabsDetails } from "views/molecules";
import { WorkplaceTabCollectionPrecaution } from "../WorkplaceTabCollectionPrecaution";
import { WorkplaceTabSimpleInformation } from "../WorkplaceTabSimpleInformation";
import { WorkplaceTabWastes } from "../WorkplaceTabWastes";

/**
 * 車輛情報タブの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  if (!props.value) {
    return <></>;
  }

  const tabs = [];

  if (
    props.value?.categories?.some(
      (category) => category.id === "dischargeSite"
    ) ??
    false
  ) {
    tabs.push(
      {
        name: "廃棄物",
        tabContent: <WorkplaceTabWastes value={props.value} />,
      },
      {
        name: "回収注意点",
        tabContent: <WorkplaceTabCollectionPrecaution value={props.value} />,
      }
    );
  }

  tabs.push({
    name: "基本情報",
    tabContent: <WorkplaceTabSimpleInformation value={props.value} />,
  });

  return (
    <TabsDetails value={props.tab} onChangeTab={props.getTab} tabs={tabs} />
  );
};

/* 
todo: 便利機能として設定する際に復元させる
import { WorkplaceTabRegularCollection } from "../WorkplaceTabRegularCollection";
{ name: "定期回収", tabContent: <WorkplaceTabRegularCollection /> }, 
*/
