import { useStyles } from "./styles";
import useClasses from "utils/useClasses";
import { debugLog } from "utils/log";

/**
 * ボタンを表示するコンテナコンポーネントです
 * @param {string} label ラベル
 * @param {string} variant 例）contained・outlined・text
 * @param {string} color 例）primary・secondary・text
 * @fires Container#onClick クリック時
 * @param {boolean} disabled 有効無効
 * @param {string} className クラス名
 * @param {string} icon アイコン
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  label = "",
  variant = "contained",
  color = "primary",
  onClick = (e) => debugLog("RenderButton.クリック時: ", e),
  disabled = false,
  className = "",
  icon,
  ...props
}) => {
  const classes = useClasses(useStyles);
  return render({
    classes: classes,
    className: className,
    icon: icon,
    label: label,
    color: color,
    variant: variant,
    disabled: disabled,
    onClick: onClick,
    ...props,
  });
};
