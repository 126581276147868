import { useSelector } from "react-redux";
import {
  disposalMethodsSelector,
  isLoadingSelector,
} from "ducks/DisposalMethods";
import lodash from "lodash";

/**
 * 処分方法を選択するコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onChange,
  disabled = false,
  error,
  helperText,
  ...props
}) => {
  const disposalMethods = useSelector(disposalMethodsSelector);
  const isLoading = useSelector(isLoadingSelector);

  return render({
    value: value,
    disposalMethods: lodash.groupBy(
      lodash.orderBy(disposalMethods, ["asc", "code"]),
      "largeClassName"
    ),
    isLoading: isLoading,
    disabled: disabled,
    error: error,
    helperText: helperText,
    onChange: onChange,
    ...props,
  });
};
