import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * ステップのフレームを表示するコンポーネントです。
 * @param {int} number ステップのナンバー
 * @param {string} title ステップのタイトル
 * @param {JSX.Elemnt} children ステップの内容
 */
export const StepFrame = containerPresententer(Container, Presententer);
