import { containerPresententer } from "utils/HoC";
import {Container} from "./Container";
import {Presententer} from "./Presententer";

/**
 *　郵便番号テキストフィールドを表示するコンポーネントです
 *  inputの周りにボーダーします。
 * @param {string} label テキストのレベル
 * @param {funct} onChange データ更新を処理する
 * @param {boolean} required 必須かどうか設定
 * @param {string} value テキストフィールドのバリュー
 * @param {object} onGetFullAddress 全体の住所を取得します。
 * @returns {JSX.Elemnt} JSX 要素
 */
export const PostCodeInputField = containerPresententer(
  Container,
  Presententer
);
