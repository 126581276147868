import { Avatar, Box, Typography, Stack } from "@mui/material";
import React from "react";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import clsx from "clsx";

/**
 * 1コースを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Stack
      className={clsx(props.classes?.root, "horizontalScroll")}
      spacing={1}
    >
      <Typography className="course_name">
        {props?.value?.name ?? ""}
      </Typography>
      <Box className={"drivers"}>
        <Avatar className="avatar" />
        <Typography
          variant="caption"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {props.value?.assignedUsers?.map((user) => user?.name)?.join() ?? ""}
        </Typography>
      </Box>
      <Box className={"vehicle"}>
        <Avatar className="avatar" src={props.value?.assignedVehicle?.icon}>
          <DirectionsCarIcon />
        </Avatar>
        <Typography
          variant="caption"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {props.value?.assignedVehicle?.number ?? ""}
        </Typography>
      </Box>
    </Stack>
  );
};
