import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

/**
 * 業務の進捗を表示するコンポーネントです。
 * @param {number} current 現在こなした業務数
 * @param {number} total トータル業務数
 * @returns {JSX.Element}
 */
const TodaysBusinessProgress = ({
  current = 0,
  total = 100,
  loading = false,
}) => {
  if (loading) {
    return <Skeleton variant="rectangular" height={100} />;
  }

  const toPercent = (current, total) => {
    if (!current || !total || total === 0) {
      return 0;
    }

    return ((current / total) * 100).toFixed(0);
  };

  return (
    <Card
      sx={{
        p: 2,
      }}
    >
      <Stack>
        <Box>
          <Typography>{"本日の回収業務"}</Typography>
        </Box>
        <Stack
          flexDirection={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
              }}
            >{`${toPercent(current, total)}%`}</Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
            >{`${current}/${total}`}</Typography>
          </Box>
        </Stack>
        <LinearProgress variant="determinate" value={(current / total) * 100} />
      </Stack>
    </Card>
  );
};

/**
 * 回収量を表示するコンポーネントです。
 * @param {number} quantity 量(kg)
 * @returns {JSX.Element}
 */
const TodaysCollectQuantity = ({ quantity = 0, loading = false }) => {
  if (loading) {
    return <Skeleton variant="rectangular" height={100} />;
  }

  return (
    <Card
      sx={{
        p: 2,
        height: "100%",
      }}
    >
      <Stack>
        <Box>
          <Typography>{"本日の回収量"}</Typography>
        </Box>
        <Box display="flex" justifyContent={"right"}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
            }}
          >{`${quantity.toLocaleString()}kg`}</Typography>
        </Box>
      </Stack>
    </Card>
  );
};

/**
 * 選択項目を表示するコンポーネントです。
 * @param {string} title タイトル
 * @param {string} subtitle サブタイトル
 * @fires Item#onClick クリック時
 * @returns {JSX.Element}
 */
const Item = ({ title = "", subtitle = "", onClick = () => {}, url = "" }) => {
  return (
    <Card onClick={() => onClick(url)}>
      <CardActionArea>
        <CardContent>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Stack spacing={1}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                }}
              >
                {title ?? ""}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "gray",
                }}
              >
                {subtitle ?? ""}
              </Typography>
            </Stack>
            <Box>
              <ChevronRightIcon />
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

/**
 * モバイルのダッシュボードを表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        p: 2,
      }}
    >
      <Grid item xs={6}>
        <TodaysBusinessProgress
          current={props.value?.doneCount ?? 0}
          total={props.value?.total ?? 0}
          loading={props.loading}
        />
      </Grid>
      <Grid item xs={6}>
        <TodaysCollectQuantity
          quantity={props.value?.weightTotalOfKg ?? 0}
          loading={props.loading}
        />
      </Grid>
      <Grid item xs={12}>
        <Item
          title={"回収記録"}
          subtitle={"配車されたコースの回収業務の記録を行います。"}
          url="/collect-mobile"
          onClick={props.onClick}
        />
      </Grid>
      <Grid item xs={12}>
        <Item
          title={"車輛点検"}
          subtitle={"車輛の点検業務を行います。"}
          url="/vehicle-inspection-list"
          onClick={props.onClick}
        />
      </Grid>
    </Grid>
  );
};
