import DescriptionIcon from "@mui/icons-material/Description";

/**
 * その他ファイルを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const OtherFile = (props) => {
  return <DescriptionIcon {...props} />;
};
