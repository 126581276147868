import React, {
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { DragDropContext } from "react-beautiful-dnd";
import { handleOnDragEnd } from "utils/BeautifulDnDFunctions";
import {
  updateAutomationSettingThunk,
  updateCollectionCyclesToTrashThunk,
  fetchPointsInBackgroundThunk,
} from "ducks/Automation";

import MapBox from "./MapBox";
import { useInitialState, ordersType, DROPPABLEID } from "./useInitialState";
import CourseBox from "./CourseBox";
import UnAssignOrderList from "./UnAssignOrderList";

const useHasNextPage = (offset, query, total, pagination) => {
  return useMemo(() => {
    if (query.length && pagination.offset > pagination.total) return false;
    const nextPageOffset = query.length ? offset : pagination.offset;
    return query.length
      ? nextPageOffset < total
      : nextPageOffset < pagination.total;
  }, [offset, query, total, pagination.total, pagination.offset]);
};

const Setting = forwardRef(({ courseId }, ref) => {
  const listRef = useRef(null);
  const dispatch = useDispatch();
  const courseNameRef = useRef(null);
  const {
    items,
    setItems,
    searchedState,
    onSearchedWordSet,
    activeCollectionPointId,
    setActiveCollectionPointId,
    renderDirection,
    setRenderDirection,
    isMapRenderAvailable,
    setIsMapRenderAvailable,
    onChangeDrivers,
    onChangeVehicle,
    paginationState,
    handleOnChangeName,
    isLoadingMore,
    handleLoadMore,
    handleReorder,
    handleMove,
    trashedIds: oldTrashedIds,
    onSearchStateReset,
  } = useInitialState(courseId, listRef);
  const { isPointLoading } = useSelector((state) => state.Automation);

  const course = useMemo(() => {
    return items.done?.courses.find((course) => course.id === courseId) || {};
  }, [courseId, items?.done?.courses]);

  const scrollToItem = (index) => {
    const itemHeight = 160;
    const scrollPosition = index * itemHeight;
    listRef.current.scrollTo(scrollPosition, "start");
  };

  const onMarkerClick = (id) => {
    if (activeCollectionPointId === id) {
      setActiveCollectionPointId(null);
    } else {
      setActiveCollectionPointId(id);
    }
    const coursePointIndex = listRef.current?.props?.itemData?.items.findIndex(
      (v) => v.cycleId === id
    );
    scrollToItem(coursePointIndex);
  };

  const hasNextUnassignedOrders = useHasNextPage(
    searchedState.still.offset,
    searchedState.still.query,
    searchedState.still.total,
    paginationState.still
  );

  const hasNextTrashedOrders = useHasNextPage(
    searchedState.trashed.offset,
    searchedState.trashed.query,
    searchedState.trashed.total,
    paginationState.trashed
  );

  const handlePointsResponse = useCallback(
    (updateResponse, pointsResponse) => {
      const {
        id,
        createdAt,
        updatedAt,
        version,
        course: updatedCourse,
      } = updateResponse.payload;
      if (pointsResponse.type === "points/fetchPointsInBackground/fulfilled") {
        const { points } = pointsResponse.payload[0];
        return {
          success: true,
          data: {
            id,
            createdAt,
            updatedAt,
            version,
            courses: items.done.courses.map((item) =>
              item.id === course.id
                ? {
                    ...updatedCourse,
                    points: points || [],
                  }
                : item
            ),
          },
        };
      } else {
        return {
          success: true,
          data: {
            id,
            createdAt,
            updatedAt,
            version,
            courses: items.done.courses.map((item) =>
              item.id === course.id ? updateResponse.payload.course : item
            ),
          },
          loadError: true,
        };
      }
    },
    [course?.id, items?.done?.courses]
  );

  const handleTrashedCycles = useCallback(
    async (id, version) => {
      let trashedListIds = null;
      if (searchedState.trashed.query?.length) {
        const ids = onSearchStateReset(ordersType.trashed);
        trashedListIds = ids;
      }
      const trashedIds = trashedListIds ?? items.trashed.map((i) => i.cycleId);
      const trashParams = {
        id,
        collectionCycleIds: trashedIds,
        oldTrashedIds: oldTrashedIds,
        version,
      };
      return await dispatch(updateCollectionCyclesToTrashThunk(trashParams));
    },
    [
      dispatch,
      items.trashed,
      oldTrashedIds,
      onSearchStateReset,
      searchedState.trashed.query?.length,
    ]
  );

  const handleUpdateData = useCallback(async () => {
    const courseName = courseNameRef.current?.trim();
    try {
      const item = items.done?.courses.find((item) => item.id === course.id);

      if (!item) {
        return { success: false, error: "エラーが発生しました。" };
      }

      const params = {
        id: items.done.id,
        course: {
          id: item.version !== undefined ? item.id : undefined,
          name: item.id === course.id ? courseName || item.name : item.name,
          departureTime: item.departureTime,
          assignedVehicleId: item.assignedVehicle?.id,
          assignedUserIds: item.assignedUsers.map((v) => v.id),
          points: item.points.map((point) => ({
            workplaceId: point.workplace.id,
            assignedRegularlyWasteCollectionCycleIds:
              point.assignedRegularlyWasteCollectionCycles.map(
                (schedule) => schedule.cycleId
              ),
          })),
          order:
            items.done?.courses.findIndex((item) => item.id === course.id) || 0,
          version: item.version,
        },
        version: items.done.version,
      };

      const updateResponse = await dispatch(
        updateAutomationSettingThunk(params)
      );

      if (
        updateResponse.type === "data/updateAutomationSetting/fulfilled" &&
        updateResponse.payload?.id
      ) {
        const deleteResponse = await handleTrashedCycles(
          updateResponse.payload.id,
          updateResponse.payload.version
        );

        if (
          deleteResponse?.type !==
          "data/updateCollectionCyclesToTrash/fulfilled"
        ) {
          const { id, createdAt, updatedAt, version, course } =
            updateResponse.payload;
          return {
            success: true,
            data: {
              id,
              createdAt,
              updatedAt,
              version,
              courses: items.done.courses.map((item) =>
                item.id === course.id ? course : item
              ),
            },
            loadError: true,
          };
        }

        const pointsResponse = await dispatch(
          fetchPointsInBackgroundThunk([updateResponse.payload.course.id])
        );

        return handlePointsResponse(updateResponse, pointsResponse);
      } else {
        return { success: false, error: updateResponse.error?.message };
      }
    } catch (error) {
      return { success: false, error: error.message };
    }
  }, [
    courseNameRef,
    dispatch,
    items,
    course,
    handlePointsResponse,
    handleTrashedCycles,
  ]);

  useImperativeHandle(ref, () => ({ handleUpdateData }), [handleUpdateData]);

  return (
    <DragDropContext onDragEnd={handleOnDragEnd(handleReorder, handleMove)}>
      <Box
        height="100vh"
        width="100vw"
        position="absolute"
        top={0}
        left={0}
        pt={8}
        display="flex"
        boxSizing="border-box"
      >
        <Box width="290px" overflow="auto">
          <CourseBox
            course={course}
            courseNameRef={courseNameRef}
            listRef={listRef}
            setItems={setItems}
            items={items}
            onChangeDrivers={onChangeDrivers}
            onChangeVehicle={onChangeVehicle}
            handleOnChangeName={handleOnChangeName}
          />
        </Box>
        <Box width="280px">
          <UnAssignOrderList
            onSearch={(text) => onSearchedWordSet(text, ordersType.still)}
            searchText={searchedState.still.query}
            value={items.still?.map((item) => {
              return {
                ...item,
                id: item.cycleId,
              };
            })}
            isLoadMore={isLoadingMore}
            isSkeletonLoading={
              searchedState.still.searching ||
              paginationState.still.loading ||
              isPointLoading
            }
            showEmptyMessage={
              !searchedState.still.searching &&
              searchedState.still.query.length > 0 &&
              !searchedState.still.searching &&
              !isPointLoading &&
              items.still.length === 0
            }
            droppableId={DROPPABLEID.UNASSIGNED}
            showCourse={
              !paginationState.still.loading &&
              !searchedState.still.searching &&
              !isPointLoading
            }
            handleLoadMore={() => handleLoadMore(ordersType.still)}
            hasNextPage={hasNextUnassignedOrders}
            title="定期回収"
          />
        </Box>
        <Box width="280px">
          <UnAssignOrderList
            onSearch={(text) => onSearchedWordSet(text, ordersType.trashed)}
            searchText={searchedState.trashed.query}
            value={items.trashed?.map((item) => {
              return {
                ...item,
                id: item.cycleId,
              };
            })}
            isLoadMore={isLoadingMore}
            isSkeletonLoading={
              searchedState.trashed.searching ||
              paginationState.trashed.loading ||
              isPointLoading
            }
            showEmptyMessage={
              !searchedState.trashed.searching &&
              searchedState.trashed.query.length > 0 &&
              !searchedState.trashed.searching &&
              !isPointLoading &&
              items.trashed.length === 0
            }
            showCourse={
              !paginationState.still.loading &&
              !searchedState.trashed.searching &&
              !isPointLoading
            }
            handleLoadMore={() => handleLoadMore(ordersType.trashed)}
            hasNextPage={hasNextTrashedOrders}
            title="設定対象外"
            droppableId={DROPPABLEID.TRASH}
          />
        </Box>
        <MapBox
          course={course}
          setIsMapRenderAvailable={setIsMapRenderAvailable}
          isPointLoading={isPointLoading}
          activeCollectionPointId={activeCollectionPointId}
          setRenderDirection={setRenderDirection}
          renderDirection={renderDirection}
          isMapRenderAvailable={isMapRenderAvailable}
          onMarkerClick={onMarkerClick}
        />
      </Box>
    </DragDropContext>
  );
});

export default React.memo(Setting);
