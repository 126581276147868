import { matchPath, generatePath, useLocation } from "react-router-dom";
import routes from "routes";
import { styles } from "./styles";

const getMatchRoutes = (path) => {
  const targetRoutes = routes?.filter((route) => route.showBreadcrumbs) ?? [];
  let matches = [];
  if (targetRoutes.some((route) => !!matchPath(route.path, path))) {
    const pathName = path.replace(/\/$/, "").split("/");
    pathName.forEach((_, index) => {
      const pathSection = `${pathName.slice(0, index + 1).join("/")}`;
      targetRoutes.some((route) => {
        const res = matchPath(route.path, pathSection);
        if (res) {
          matches.push({ ...route, to: generatePath(res.pattern.path, res.params) });
          return true;
        }
        return false;
      });
    });
  }
  return matches;
};
/**
 * パンくずリストを表示するコンテナコンポーネントです。
 * @param {func} render JSX.Elementを返すメソッド
 */
export const Container = ({ render }) => {
  const location = useLocation();
  const classes = styles();

  const matchRoutes = getMatchRoutes(location.pathname);
  return render({
    classes: classes,
    pathname: location.pathname,
    matchRoutes: matchRoutes,
    routes: [],
  });
};
