import { containerPresententer } from "../../../utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 認証画面のテンプレートを表示するコンポーネントです。
 */
export const AuthenticationTemplate = containerPresententer(
  Container,
  Presententer
);
