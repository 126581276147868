import useClasses from "utils/useClasses";
import { styles } from "./styles";

/**
 * 回収場所リストを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 回収場所リスト
 * @param {string} droppableId Droppableコンポーネントのid
 * @returns {func}
 */
export const Container = ({
  render,
  value,
  droppableId = "CollectPoints",
  searchWord = "",
  maxHeight,
  maxWeight,
  ...props
}) => {
  const classes = useClasses(styles);
  return render({
    classes: classes,
    value: value.map((item, index) =>
    item.assignedRegularlyWasteCollectionCycles.map((cycle) => ({
      ...cycle,
      order: index + 1,
    }))
  ).flat(),
    droppableId: droppableId,
    maxHeight: maxHeight,
    maxWeight: maxWeight,
    ...props,
  });
};
