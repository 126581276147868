import { Box, FormControl, FormHelperText, Select } from "@mui/material";
import clsx from "clsx";
import React from "react";

/**
 *　ポップアップセレクションでデータを選択するコンポーネントです。
 @param {string} label セレクションのラベル
 @param {boolean} hideLabel 
 @param {string} placeholder プレースホルダー
 @param {boolean} required 必須かどうかを設定する
 @param {object} value 選択されたアイテムです
 @param {func} onChange 選択するアイテムが更新するイベントです 
 @param {array} options アイテム配列 
 @param {boolean} disabled 無効にする 例）false
 */
export const Presententer = (props) => {
  return (
    <Box display="flex" flexDirection="column" fontSize="14px">
      <div
        className={clsx(
          props.classes.label,
          props.hideLabel && props.classes.hideLabel
        )}
      >
        {props.label}
        {props.required ? (
          <span className={props.classes.required}>必須</span>
        ) : (
          ""
        )}
      </div>
      <FormControl
        fullWidth
        variant="outlined"
        className={props.classes.style_inputField}
        size="small"
        error={Boolean(props?.error)}
        disabled={props.disabled}
      >
        <Select
          native
          value={props.value ?? ""}
          onChange={props.onChange}
          displayEmpty
          className={clsx(
            !props.value && props.classes.emptyValue,
            props.classes.popupMultiple
          )}
          inputProps={{
            name: `${props.name}`,
            code: `${props.name}-label-placeholder`,
          }}
        >
          {props.options?.map((option, index) => (
            <option key={index} value={option.code}>
              {option.name}
            </option>
          ))}
        </Select>
        {props.error?.message ? (
          <FormHelperText>{props.error?.message}</FormHelperText>
        ) : (
          ""
        )}
      </FormControl>
    </Box>
  );
};
