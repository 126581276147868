import { Container, Grid } from "@mui/material";
import React from "react";
import { Controller, FormProvider } from "react-hook-form";
import {
  AddressFromPostCode,
  DatePicker,
  FileGallery,
  InputField,
} from "views/molecules";
import { Uploader } from "views/molecules/Uploader";

/**
 * 産業廃棄物許可証のフォームを表示するコンテナコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Elemnt} JSX 要素
 */
export const Presententer = (props) => {
  return (
    <FormProvider {...props.methods}>
      <form id={props.id} onSubmit={props.submit} noValidate>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Controller
                name="number"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    label="許可番号"
                    value={value}
                    onChange={onChange}
                    required={true}
                    error={error}
                    inputProps={{
                      maxLength: 11,
                    }}
                    helperText="10桁の場合、頭に0を付けてください"
                    disabled={props.loading}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="japaneseLocalGovernment"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    label="許可主体"
                    value={
                      (value?.cityName ?? "") === ""
                        ? value?.prefectures?.name
                        : value?.cityName
                    }
                    onChange={onChange}
                    required={true}
                    error={error}
                    placeholder="許可番号から自動で入力されます。"
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled={props.loading}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="category"
                control={props.control}
                render={({ field: { value }, fieldState: { error } }) => {
                  return (
                    <InputField
                      label="業の区分"
                      value={value?.name}
                      required={true}
                      error={error}
                      placeholder="許可番号から自動で入力されます。"
                      InputProps={{
                        readOnly: true,
                      }}
                      disabled={props.loading}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="licensedOn"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    label="許可の年月日"
                    value={value}
                    onChange={onChange}
                    required={true}
                    disableFuture={true}
                    error={error}
                    disabled={props.loading}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="licenseExpirationOn"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    label="許可の有効年月日"
                    value={value}
                    onChange={onChange}
                    required={true}
                    disablePast={true}
                    error={error}
                    disabled={props.loading}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="certifiedAsExcellentOn"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    label="優良認定日"
                    value={value}
                    onChange={onChange}
                    required={false}
                    error={error}
                    disabled={props.loading}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="companyName"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    label="会社名"
                    value={value}
                    onChange={onChange}
                    required={true}
                    error={error}
                    placeholder="会社名を入力します。"
                    disabled={props.loading}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="representativeName"
                control={props.control}
                render={({ field: { value, onChange } }) => (
                  <InputField
                    label="代表者名"
                    value={value}
                    onChange={onChange}
                    placeholder="代表者名を入力します。"
                    disabled={props.loading}
                  />
                )}
              />
            </Grid>
            <AddressFromPostCode control={props.control} />
            <Grid item xs={12}>
              <Controller
                name="remarks"
                control={props.control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InputField
                    label="備考"
                    value={value}
                    onChange={onChange}
                    error={error}
                    multiline={true}
                    rows={3}
                    placeholder="特記事項があれば記載します。"
                    disabled={props.loading}
                  />
                )}
              />
            </Grid>
            <Grid item sx={12}>
              <Controller
                name="files"
                control={props.control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <Uploader
                      fullWidth={true}
                      maxSize={26214400}
                      onChange={(files) => {
                        onChange([...(value ?? []), ...files]);
                      }}
                      dropzoneText="最大ファイルサイズ:25MB"
                    />
                    <FileGallery value={value} onChange={onChange} />
                  </>
                )}
              />
            </Grid>
          </Grid>
        </Container>
      </form>
    </FormProvider>
  );
};
