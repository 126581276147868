import { Box, Drawer, List, ListSubheader } from "@mui/material";
import React from "react";
import { TextSearcher } from "../TextSearcher";

/**
 * ドロワーの表示を行うプレゼンテーションコンポーネントです。
 * @param {object} children ドロワーの内容
 * @param {object} classes クラス名
 * @param {func} onCloseDialog ドロワーを閉じるイベントです
 * @param {func} onChangeSearch フリーワード検索を更新するイベントです
 * @param {boolean} open ドロワーが有効か無効にする。
 */
export const Presententer = (props) => {
  return (
    <div>
      <Drawer
        variant="persistent"
        anchor="right"
        open={props.open}
        elevation={6}
        classes={{
          paper: props.classes.drawerPaper,
        }}
        sx={{
          width: props.width,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: props.width,
          },
        }}
      >
        <div className={props.classes.drawer}/>
        <Box className={props.classes.drawerHeader}>
          <List disablePadding={true}>
            <ListSubheader
              disableGutters={true}
              className={props.classes.overideSticky}
              sx={{ top: "64px" }}
            >
              <Box className={props.classes.BoxSticky} boxShadow={2}>
                {/* <Box position="absolute" top={0} left="0" zIndex="tooltip">
                  <IconButton onClick={props.onCloseDrawer}>
                    <CloseIcon />
                  </IconButton>
                </Box> */}
                <Box
                  flexGrow={1}
                  fontWeight="fontWeightMedium"
                  fontSize="16px"
                  textAlign="center"
                  color="text.primary"
                >
                  {props.titleDrawer}
                </Box>
                <Box lineHeight="normal">
                  <TextSearcher onChangeSearch={props.onChangeSearch} />
                </Box>
              </Box>
            </ListSubheader>
            <Box className={props.classes.lists}>{props.children}</Box>
          </List>
        </Box>
      </Drawer>
    </div>
  );
};
