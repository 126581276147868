import React from "react";
import { ModalPopup } from "views/molecules";
import { WorkplaceSelector } from "views/molecules";
import { Box } from "@mui/material";

/**
 * 事業場の選択を表示するプレゼンテーションコンポーネントです
 * @param {object} classes　クラス名
 * @param {boolean} open モーダルが有効か無効を設定する
 * @param {func} handleClose モーダルを閉じるイベントです
 * @param {object} value 現在の事業場情報です
 * @param {array} dataTable テーブルのデータ
 * @param {func} getItemSelected テーブルのデータを更新する時のイベントです
 * @returns
 */
export const Presententer = (props) => {
  return (
    <ModalPopup open={props.open} onClose={props.onClose}>
      <Box pt={3} pb={3} sx={{ height: "85vh" }}>
        <WorkplaceSelector
          companyId={props.companyId}
          value={props.value}
          title="事業場の選択"
          onChange={props.onChange}
        />
      </Box>
    </ModalPopup>
  );
};
