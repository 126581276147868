import { Container, Stack } from "@mui/material";
import { Button as ContractsAlertButton } from "features/Contracts/Alert/Button";
import { Button as LicensesAlertButton } from "features/Licenses/Alert/Button";
import { Button as VehicleRemindButton } from "features/Vehicles/Remind/Alert/Button";
import { If } from "views/atoms";
import {
  // MonthCollectedCardWidget,
  NotAssignedOrderCardWidget,
  TodayScheduleCardWidget,
  WeekCollectedChartsWidget
} from "./Components";

export const Presententer = (props) => {
  return (
      <Container sx={{ pt: 2 }}>
        <Stack spacing={3}>
          <Stack direction="row" spacing={2}>
            <TodayScheduleCardWidget />
            {/* <MonthCollectedCardWidget /> */}
            <NotAssignedOrderCardWidget />
            <If condition={props.isNotGeneralUser}>
              <LicensesAlertButton />
              <ContractsAlertButton />
            </If>
            <VehicleRemindButton />
          </Stack>
          <WeekCollectedChartsWidget />
        </Stack>
      </Container>
  );
};
