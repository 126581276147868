import { yupResolver } from "@hookform/resolvers/yup";
import { industrySectorsSelector } from "ducks/IndustrySectors";
import { workplaceCategoriesSelector } from "ducks/WorkplaceCategories";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { styles } from "./styles";
import { basicSchema } from "./Validations/basic";
import { getLatLng, fetchLatLng } from "views/templates/Mobile/Collect/Utils";
import { useJsApiLoader } from "@react-google-maps/api";

const defaultValues = {
  code: "",
  categories: [],
  industrySector: undefined,
  name: "",
  phone: null,
  fax: null,
  email: null,
  postalCode: "",
  prefectures: undefined,
  city: "",
  streetAddress: "",
  otherAddress: "",
  inputType: "manual",
  remarks: "",
};

/**
 * 新しい事業場を登録するフォームを表示するコンテナコンポーネントです。
 * @param {string} id 固有番号
 * @fires Container#onSubmit 送信時
 * @fires Container#onError エラー時
 * @param {object} value 値
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  id = "",
  onSubmit,
  onError = (err) => debugLog(err),
  value,
  ...props
}) => {
  const classes = useClasses(styles);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const industrySectors = useSelector(industrySectorsSelector);
  const workplaceCategories = useSelector(workplaceCategoriesSelector);
  useJsApiLoader({
    id: "google-map",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_LICENSE_KEY,
    region: "JP",
    language: "ja",
  });

  const format = (value) => {
    if (!value) {
      return null;
    }

    const { industrySector, isMapInput, ...other } = value;

    return {
      industrySector: industrySector,
      inputType: isMapInput ? "from-map" : "manual",
      ...other,
    };
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...format(value),
    },
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    methods.reset({
      ...defaultValues,
      ...format(value),
    });
  }, [methods, value]);

  const beforeSubmit = (value) => {
    const { prefectures, city, streetAddress, otherAddress, inputType } = value;
    const address = `${prefectures.name}${city}${streetAddress}${otherAddress}`;
    const isMapInput = inputType === "from-map";
    delete value.inputType;
    if (!isMapInput) {
      getLatLng(address)
        .then((res) => {
          const data = {
            ...value,
            position: fetchLatLng(res),
            isMapInput: isMapInput,
          };
          onSubmit(data);
        })
        .catch((err) => {
          debugLog(err);
          setIsOpenConfirmDialog(true);
        });
    } else {
      try {
        const data = {
          ...value,
          isMapInput: isMapInput,
        };
        onSubmit(data);
      } catch (error) {
        debugLog(error);
        setIsOpenConfirmDialog(true);
      }
    }
  };

  const handleForceSubmit = () => {
    onSubmit(methods.getValues());
    setIsOpenConfirmDialog(false);
  };

  return render({
    id: id,
    submit: methods.handleSubmit(beforeSubmit, onError),
    forceSubmit: handleForceSubmit,
    closeConfirmDialog: () => setIsOpenConfirmDialog(false),
    isOpenConfirmDialog: isOpenConfirmDialog,
    classes: classes,
    control: methods.control,
    workplaceCategories: workplaceCategories,
    industrySectors: industrySectors,
    methods: methods,
    ...props,
  });
};
