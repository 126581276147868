import { removeUserAccount } from "api/graphql/mutations";
import { useMutation } from "utils/useMutation";
import { ConfirmDialog } from "views/molecules";

/**
 * 削除するダイアログを表示するコンポーネント。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {boolean} props.open 開閉状態
 * @fires StopDialog#onClose 閉じる時
 * @returns {JSX.Element}
 */
export const RemoveDialog = ({
  value = null,
  open = false,
  onCompleted = () => {},
  onClose = () => {},
}) => {
  const [removeMutation, removeMutationStatus] = useMutation(
    removeUserAccount,
    {
      onCompleted: () => {
        onCompleted();
        onClose();
      },
      succeedMessage: "削除しました。",
      errorMessage: "エラーが発生したため、削除できませんでした。",
    }
  );

  return (
    <ConfirmDialog
      disabled={removeMutationStatus.loading}
      open={open}
      title="削除確認"
      message="削除します。よろしいですか？"
      positiveText="はい"
      negativeText="いいえ"
      onPositive={() => {
        removeMutation({
          input: {
            id: value.id,
          },
        });
      }}
      onNegative={onClose}
    />
  );
};
