import PropTypes from "prop-types";
import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * グーグルマップを表示するコンポーネントです。
 * @param {object} center It contains the center elements object
 */
export const GoogleMapApi = containerPresententer(Container, Presententer);

GoogleMapApi.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    zoom: PropTypes.number,
  }).isRequired
};
