import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { CloudUploadOutlined } from "@mui/icons-material";
import React from "react";
import { RenderPDF } from "views/atoms";

/**
 * 複数ファイルのアップロードの表示を行うプレゼンテーションコンポーネントです
 * @param {object} classes ギャラリーの値
 * @param {string} label ラベル
 * @param {func} getRootProps react-dropzoneのAPI
 * @param {func} getInputProps react-dropzoneのAPI
 * @param {array} files アップロードされたファイルの配列
 * @param {func} onDeleteFile アップロードされたファイルを取得する
 */
export const Presententer = (props) => (
  <div>
    {props.showUpload && (
      <>
        {props.label ? (
          <Box fontSize="14px" color=" #616161" mb={1}>
            {props.label}
          </Box>
        ) : null}
        <Box className={props.classes.box_image_upload}>
          <div {...props.getRootProps({ className: "dropzone" })}>
            <input {...props.getInputProps()} />
            <label
              htmlFor="contained-button-file"
              className={props.classes.labelImage}
            >
              <CloudUploadOutlined
                className={props.classes.iconUpload}
                size="large"
              />
              <Typography variant="subtitle2">
                最大ファイルサイズ：25MB　ファイル形式:PDF
              </Typography>
            </label>
            <Button
              variant="contained"
              color="primary"
              className={props.classes.button_upload}
            >
              ファイルを選択
            </Button>
            {Boolean(props.errors) && (
              <Box color="error.main" mt={2}>
                {props.errors}
              </Box>
            )}
          </div>
        </Box>
      </>
    )}

    {props.files?.file ? (
      <Box className={props.classes.box_viewPDF}>
        <Box className={props.classes.file_infor}>
          <TextField
            size="medium"
            variant="outlined"
            fullWidth
            value={props.newName?.name}
            onChange={props.onChangeName}
            className={props.classes.file_name}
            InputProps={{
              endAdornment: (
                <Box className={props.classes.box_delete}>
                  <Box className={props.classes.file_size}>
                    <span>.{props?.newName?.suffix}</span>
                    <span>{props.newName?.size}</span>
                  </Box>
                  <Box className={props.classes.file_delete}>
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={props.onDeleteFile}
                      >
                        削除
                      </Button>
                    </InputAdornment>
                  </Box>
                </Box>
              ),
            }}
          />
        </Box>
        <Box className={props.classes.file_view}>
          <RenderPDF filePDF={props.files?.file} showCheckbox={false} />
        </Box>
      </Box>
    ) : null}

    {}
  </div>
);
