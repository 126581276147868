import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  text_validation: {
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontSize: "13px",
    borderRadius: 20,
    paddingTop: "3px",
    paddingBottom: "3px",
  },
  message: {
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontSize: "13px",
    borderRadius: 20,
    paddingTop: "3px",
    paddingBottom: "3px",
  },
  number: {
    textAlign: "center",
    border: " 2px solid",
    borderRadius: "20px",
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    padding: 0,
    minWidth: "30px",
    minHeight: "25px",
    marginRight: theme.spacing(1),
    display: "inline-block",
    lineHeight: "21px",
    "&.completed": {
      borderColor: theme.palette.text.disabled,
      color: theme.palette.text.disabled,
      backgroundColor: "transparent",
    },
    "&.incompleted": {
      color: "dodgerblue",
      backgroundColor: "transparent",
    },
  },
  time: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: "12px",
    color: theme.palette.grey[400],
    "& svg": {
      fontSize: "18px",
      marginRight: "5px",
    },
  },
  collect_detail: {
    display: "flex",
    flexDirection: "column",
    height: "100svh",
    backgroundColor: theme.palette.background.default,
  },
  //ヘッダー
  collect_header: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: 0,
  },
  collect_content: {
    flex: 1,
    overflow: "auto"
  },
  //フッター
  collect_switch: {
    textAlign: "center",
    border: "1px solid",
    borderColor: theme.palette.grey[800],
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: "4px",
    marginBottom: theme.spacing(2),
    "& .MuiTypography-root": {
      fontSize: "13px",
      fontWeight: "600",
    },
    "& .MuiFormControlLabel-root": {
      margin: 0,
    },
  },
  collect_footer: { padding: theme.spacing(2, 5) },
}));
