import { WarningOutlined } from "@mui/icons-material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Stack,
} from "@mui/material";
import React from "react";
import { If } from "views/atoms";

/**
 * 未配車の回収スケジュールを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box boxShadow={3} p={3} borderRadius={1} marginBottom={2}>
      <If condition={(props?.rawRows?.length ?? 0) > 0}>
        <Stack direction="row" justifyContent={"space-between"}>
          <Typography
            color="secondary"
            sx={{ display: "flex", alignItems: "center", mb: 2 }}
          >
            <WarningOutlined
              fontSize="large"
              color="secondary"
              sx={{ mr: 1 }}
            />
            未配車の回収スケジュールがあります。
          </Typography>
          <FormControlLabel
            label="翌日分を表示する"
            control={
              <Checkbox
                checked={props.visibleNextDay}
                onChange={props.onChangeNextDay}
              />
            }
          />
        </Stack>
      </If>
      <Box sx={{ maxHeight: 650, width: "100%" }}>
        <DataGridPro
          autoHeight
          rows={props.rows}
          columns={props.columns}
          onToolbar={false}
          checkboxSelection={false}
          multipleSelection={false}
          disableSelectionOnClick={false}
          disableColumnMenu={true}
          pageSize={10}
          loading={props.loading}
          pagination
        />
      </Box>
    </Box>
  );
};
