import useClasses from "utils/useClasses";
import { useStyles } from "./styles";

/**
 * 取引先詳細を表示するコンテナコンポーネントです
 * @param {object} value 事業場情報
 * @param {func} onChangeTab タブ変更時
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  tabNumber,
  value = {},
  onChangeTab,
  ...props
}) => {
  const classes = useClasses(useStyles);

  const handlChange = (tabNumber) => {
    onChangeTab && onChangeTab(tabNumber);
  };

  return render({
    ...props,
    tabNumber: tabNumber,
    value: value,
    classes: classes,
    onChangeTab: handlChange,
  });
};
