import { useRef } from "react";

/**
 * チェックシートテンプレート詳細を表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  value,
  isSubmit,
  onUpdate,
  onRemove,
  form,
  confirm,
  ...props
}) => {
  const fomrRef = useRef(null);

  const handleUpdate = (data) => {
    onUpdate && onUpdate(data);
  };

  return render({
    ...props,
    value: value,
    onUpdate: handleUpdate,
    form: form,
    fomrRef: fomrRef,
    isSubmit: isSubmit,
    onRemove: onRemove,
    confirm: confirm,
  });
};
