import { containerPresententer } from "utils/HoC";
import PropTypes from "prop-types";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 現在地から回収ポイントまでルートを表示します。
 * @param {array} points
 */
export const DistanceMatrix = containerPresententer(Container, Presententer);

DistanceMatrix.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    })
  ).isRequired,
};
