import { useStyles } from "./styles";
import useClasses from "utils/useClasses";

const initialState = {
  basic: null,
  galleries: [],
  vehicles: [],
};

/**
 * 事業場情報(基本情報・ギャラリー・車両)を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {func} getTab タブのナンバーを取得する
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, getTab, value, ...props }) => {
  const classes = useClasses(useStyles);

  return render({
    value: { ...initialState, basic: value },
    classes: classes,
    getTab: getTab,
  });
};
