import { Alert, AlertTitle, Snackbar, Slide } from "@mui/material";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

/**
 * アラートを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  vertical = "bottom",
  horizontal = "center",
  messages,
  onClose,
}) => {
  if (!messages || !messages.length) {
    return <></>;
  }

  return messages.map((message, index) => (
    <Snackbar
      open={true}
      autoHideDuration={message?.autoHideDuration ?? 6000}
      onClose={onClose(index)}
      key={index}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={onClose(index)}
        severity={message?.severity ?? "info"}
      >
        {message?.title && <AlertTitle>{message.title}</AlertTitle>}
        {message?.value}
      </Alert>
    </Snackbar>
  ));
};
