import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useOpener } from "utils/useOpener";
import { Items } from "./Items";

/**
 * フローを選択するコンポーネントです。(レイアウト)
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {boolean} props.loading 読み込み中であるか
 * @param {boolean} props.disabled 無効設定
 * @param {array} props.options フロー情報配列
 * @fires Layout#onChange 変更時
 * @returns {JSX.Element}
 */
export const Layout = ({
  id,
  value = null,
  onChange = () => {},
  options = [],
  loading = false,
  disabled = false,
}) => {
  const selector = useOpener();
  const [current, setCurrent] = useState(value);

  const handleDecided = () => {
    onChange(current);
    selector.toggle(false);
  };

  useEffect(() => {
    setCurrent(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(value)]);

  useEffect(() => {
    if (options?.length === 1) {
      onChange(options?.[0]?.wasteFlowId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, JSON.stringify(options)]);

  return (
    <>
      {options.length > 1 && (
        <Button onClick={() => selector.toggle(true)} disabled={disabled}>
          {"処理フローを選択する"}
        </Button>
      )}
      <Dialog open={selector.open} onClose={() => selector.toggle(false)}>
        <DialogTitle>{"フロー選択"}</DialogTitle>
        <DialogContent>
          <Items
            options={options}
            value={current}
            onChange={setCurrent}
            loading={loading}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => selector.toggle(false)} color="error">
            {"キャンセル"}
          </Button>
          <Button onClick={handleDecided} autoFocus>
            {"決定"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
