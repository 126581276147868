import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 許可証情報を表示するコンポーネントです
 * @param {object} props プロパティ
 * @param {object} props.value 事業場情報
 * @fires LicenseTable#onSelected 選択された時
 * @fires LicenseTable#onSubmitted 送信された時
 * @returns {JSX.Element}
 */
export const LicenseTable = containerPresententer(Container, Presententer);
