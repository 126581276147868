import React from "react";
import { InformationItem, InformationGroup } from "views/atoms";
import { XGridComponent } from "views/molecules";
import { Paper } from "@mui/material";

/**
 * 処理フロー詳細を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @return {JSX.Element}
 */
export const Presententer = (props) => {
  const { value } = props;
  return (
    <Paper sx={{ p: 2 }}>
      <InformationGroup subheader={"基本情報"}>
        <InformationItem label="管理番号">
          {value?.number ?? ""}
        </InformationItem>
        <InformationItem label="名称">{value?.name ?? ""}</InformationItem>
        <InformationItem label="廃棄物の種類">
          {value?.targetWasteType?.smallClass?.name ||
            value?.targetWasteType.middleClass?.name ||
            value?.targetWasteType?.largeClass?.name}
        </InformationItem>
        <InformationItem label="廃棄物の詳細種類">
          {value?.targetWasteKind ?? ""}
        </InformationItem>
      </InformationGroup>
      <InformationGroup subheader={"運搬区間情報"}>
        {value?.transportProcesses?.map?.((transportProcess, index) => (
          <React.Fragment key={index}>
            <InformationItem label="運搬区間">
              {transportProcess?.section ?? ""}
            </InformationItem>
            <InformationItem
              label={`運搬受託者（${transportProcess?.section ?? ""}区間）`}
            >
              {transportProcess?.carrierCompany?.name ?? ""}
            </InformationItem>
            <InformationItem
              label={`運搬方法${transportProcess?.section ?? ""}区間）`}
            >
              {transportProcess?.transportMethod?.name ?? ""}
            </InformationItem>
            <InformationItem
              label={`運搬先の事業場（${transportProcess?.section ?? ""}区間）`}
            >
              {transportProcess.transportDestinationWorkplace
                ?.belongInCompanyName ?? ""}{" "}
              {transportProcess.transportDestinationWorkplace?.name ?? ""}
            </InformationItem>
          </React.Fragment>
        )) ?? <></>}
      </InformationGroup>
      <InformationGroup subheader={"処分情報"}>
        <InformationItem label="処分受託者">
          {value?.disposalProcess?.disposalCompany?.name ?? ""}
        </InformationItem>
        <InformationItem label="処分事業場">
          {value?.disposalProcess?.disposalWorkplace?.name ?? ""}
        </InformationItem>
        <InformationItem label="処分方法">
          {(value?.disposalProcess?.disposalMethod?.smallClassName ||
            value?.disposalProcess?.disposalMethod?.middleClassName ||
            value?.disposalProcess?.disposalMethod?.largeClassName) ??
            ""}
        </InformationItem>
        <InformationItem label="処分方法の詳細">
          {value?.disposalProcess?.disposalMethodDetails ?? ""}
        </InformationItem>
      </InformationGroup>
      <InformationGroup subheader={"最終処分情報"}>
        <XGridComponent
          rows={
            value?.lastDisposalProcesses?.map(
              (item) => item?.disposalWorkplace
            ) ?? []
          }
          columns={props.columns}
          disableSelectionOnClick={true}
          onDownload={false}
          onFilter={false}
          onSearcher={false}
          onColumns={false}
          titleTable={""}
        />
      </InformationGroup>
    </Paper>
  );
};
