import {
  // CameraAltOutlined,
  LogoutOutlined,
  // ManageAccountsOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  // Link,
  Menu,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import PersonIcon from "@mui/icons-material/Person";
// import { AccountSetting } from "..";

/**
 * ユーザーボタンを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 */
export const Presententer = (props) => (
  <>
    <Button onClick={props.onClickUserButton} className={props.classes.avatar}>
      <Avatar src={props.value?.icon} sx={{ mr: 1 }}>
        {!props.value?.icon && <PersonIcon />}
      </Avatar>
      {!props.isHidden && <Typography>{props.value?.name}</Typography>}
    </Button>
    <Menu
      anchorEl={props.anchorEl}
      open={Boolean(props.anchorEl)}
      onClose={props.onCloseMenu}
      sx={{
        "& .MuiPaper-root": {
          minWidth: 300,
          mt: "6px",
        },
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <IconButton
              size="small"
              sx={{
                border: "2px solid #fff",
                backgroundColor: "#fff",
                padding: "2px",
                transition: "0.3s all linear",
                "&:hover": {
                  borderColor: (theme) => theme.palette.primary.main,
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: "#fff",
                },
              }}
            >
              {/* <CameraAltOutlined
                fontSize="small"
                sx={{ width: "0.8em", height: "0.8em" }}
              /> */}
            </IconButton>
          }
        >
          <Avatar
            src={props.value?.icon}
            sx={{
              width: 64,
              height: 64,
              backgroundColor: (theme) => theme.palette.icon.toolbarUser,
            }}
          >
            {props.value?.name}
          </Avatar>
        </Badge>

        <Typography sx={{ mt: 2, mb: 1 }}>{props.value?.name}</Typography>
        <Typography
          sx={{
            mb: 2,
            fontSize: "14px",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {props.value?.email ?? ""}
        </Typography>
      </Box>
      <Divider />
      {/*
      // UNDONE: 12月リリースでは隠す 
      <MenuItem sx={{ pt: 2, pb: 2 }} onClick={props.onOpenDialog}>
        <ListItemIcon>
          <ManageAccountsOutlined fontSize="medium" />
        </ListItemIcon>
        <ListItemText sx={{ "& .MuiTypography-root": { fontSize: "15px" } }}>
          アカウント管理
        </ListItemText>
      </MenuItem> */}
      <Divider sx={{ mt: "0 !important", mb: "0 !important" }} />
      <MenuItem sx={{ pt: 2, pb: 2 }} onClick={props.onSignOut}>
        <ListItemIcon>
          <LogoutOutlined fontSize="medium" />
        </ListItemIcon>
        <ListItemText sx={{ "& .MuiTypography-root": { fontSize: "15px" } }}>
          ログアウト
        </ListItemText>
      </MenuItem>
      {/*
      // UNDONE: 12月リリースでは隠す 
      <Divider sx={{ mt: "0 !important", mb: "0 !important" }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: "12px",
          pt: 2,
          pb: 1,
          pl: 2,
          pr: 2,
        }}
      >
        <Link href="/">プライバシーポリシー</Link>
        <Link href="/">サービス利用規約</Link>
      </Box> */}
    </Menu>
    {/*
    // UNDONE: 12月リリースでは隠す  
    <AccountSetting
      open={props.open}
      onClose={props.onCloseDialog}
      value={props.value}
    /> */}
  </>
);
