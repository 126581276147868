import { useStyles } from "./styles";
import useClasses from "utils/useClasses";

/**
 * サイドメニューを表示するコンテナコンポーネントです。
 * @param {render} render 引数を受けてJSX Elementを返します。
 * @param {array} routes システムのパス情報
 * @param {boolean} open 小さくなるかどうか
 * @returns {JSX.Element} JSX要素
 */
export const Container = ({
  render,
  routes,
  open = true,
  exact = false,
  subheader,
}) => {
  const classes = useClasses(useStyles);

  return render({
    classes: classes,
    routes: routes,
    open: open,
    exact: exact,
    subheader: subheader,
  });
};
