import { Card, CardContent, Skeleton, Typography } from "@mui/material";
import { FullScreenDialog, XGridComponent } from "views/molecules";

export const Presententer = (props) => {
  if (props.loading) {
    return <Skeleton variant="rectangular" width={275} height={130} />;
  }

  return (
    <>
      <Card
        sx={{ minWidth: 275, width: 275, height: 130, cursor: "Pointer" }}
        onClick={() => props.dialog.toggle(true)}
      >
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            未配車の回収依頼
          </Typography>
          <Typography variant="h3" component="h2" align="right">
            {props.value}
          </Typography>
        </CardContent>
      </Card>
      <FullScreenDialog
        title="未配車の回収依頼一覧"
        textConfirm=""
        open={props.dialog.open}
        onClose={() => props.dialog.toggle(false)}
        onClickSubmit={() => {}}
        visibilityConfirm={false}
      >
        <XGridComponent
          onChangeSelection={props.onSelected}
          checkboxSelection={false}
          multipleSelection={false}
          onDownload={false}
          onFilter={false}
          onColumns={false}
          {...props.xGridParams}
          rows={props.xGridParams.rows.map((r, i) => ({ id: i, ...r }))}
        />
      </FullScreenDialog>
    </>
  );
};
