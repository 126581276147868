import { makeStyles } from "@mui/styles";

export const styles = makeStyles((theme) => ({
  outer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    maxWidth: 600,
    width: "100%",
    minWidth: 280,
    boxSizing: "border-box",
    padding: 10,
    margin: "0 auto",
    "& > .icon_area": {
      padding: "20px 0",
    },
    "& > .title_area": {
      padding: "10px 0",
    },
    "& > .action_area": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "& > .details_area": {},
      "& > .retry_area": {},
    },
    "& > .message_area": {
      padding: "5px 0",
    },
  },
}));
