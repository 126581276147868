import { Box } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { InformationItem } from "views/atoms";
import { XGridComponent } from "views/molecules";

/**
 * スポット回収を詳しく表示するプレゼンテーションコンポーネントです。
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  const { value } = props;
  return (
    <>
      <div>
        <Box className={props.classes.title}>基本情報</Box>
        <InformationItem label="排出事業場">{`${
          value?.wasteGeneratorCompany?.name ?? ""
        }${value?.wasteCollectionWorkplace?.name ?? ""}`}</InformationItem>
        {/* <InformationItem label="収集運搬業者">
          {value?.collection?.name ?? ""}
        </InformationItem> */}
        <InformationItem label="回収日">
          {value?.scheduleDate ? DateTime.fromISO(value.scheduleDate).toFormat("yyyy/MM/dd") : ""}
        </InformationItem>
        <InformationItem label="回収時間">{`${
          value?.scheduleTimeRangeStart
            ? value?.scheduleTimeRangeStart.substring(0, 5)
            : ""
        } ～ ${
          value?.scheduleTimeRangeEnd
            ? value?.scheduleTimeRangeEnd.substring(0, 5)
            : ""
        }`}</InformationItem>
        <InformationItem label="備考">
          <div
            style={{
              whiteSpace: "pre",
            }}
          >
            {value?.remarks ?? ""}
          </div>
        </InformationItem>
      </div>
      <Box mt={2}>
        <Box className={props.classes.title}>排出情報</Box>
        <XGridComponent
          rows={value?.wastes ?? []}
          columns={props.columns}
          onToolbar={false}
          onSearcher={false}
          onDownload={false}
          onColumns={false}
          onFilter={false}
          multipleSelection={false}
          checkboxSelection={false}
          disableSelectionOnClick={true}
        />
      </Box>
    </>
  );
};
