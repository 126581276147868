import { containerPresententer } from "utils/HoC";
import {Container} from "./Container";
import {Presententer} from "./Presententer";

/**
 *　テキストパスワードを表示するコンポーネントです
 *  inputの周りにボーダーします。
 * @param {string} label テキストのレベル
 * @param {funct} onChange データ更新を処理する
 * @param {boolean} required 必須かどうか設定
 * @param {string} value テキストフィールドのバリュー
 * @param {string} error エラーが出る
 * @returns {JSX.Elemnt} JSX 要素
 */
export const InputFieldPassword = containerPresententer(Container, Presententer);
