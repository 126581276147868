import { onOpenguiderSteps } from "ducks/GuiderSteps/slice";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import useClasses from "utils/useClasses";

/**
 * 好き数を受けて、JSX.Elementを返します。
 * @callback render
 */
export const Container = ({ render, ...props }) => {
  const dispatch = useDispatch();
  const { visibility } = useSelector((state) => state.GuiderSteps);
  const classes = useClasses(useStyles);
  const handleClickBeginner = () => {
    dispatch(onOpenguiderSteps());
  };
  return render({
    classes: classes,
    onClickBeginner: handleClickBeginner,
    visibility: visibility,
    ...props,
  });
};
