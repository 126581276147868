import { Box, Button, Container, Paper } from "@mui/material";
import React from "react";
import { Details, Form } from "views/organisms/Slip/PaperManifest";

/**
 * 紙マニフェストの詳細を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Container sx={{ pt: 2 }}>
    <Paper sx={{ p: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
        <Button
          variant="contained"
          color="error"
          onClick={props.onDelete}
          style={{
            marginRight: "10px",
          }}
          disabled={!props.value}
        >
          削除
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={props.onEdit}
          disabled={!props.value}
        >
          修正
        </Button>
      </Box>
      <Details value={props.value} disabled={true} />
    </Paper>
    <Form
      title={"紙マニフェストの編集"}
      isLoading={props.isLoading}
      open={props.open}
      onClose={props.onClose}
      onSubmit={props.onSubmit}
      value={props.value}
    />
  </Container>
);
