import reducer, {
  automationsSliceSelector,
  addNewCourse,
  updateCoursesOrder,
  removeNewCourse,
  updateAutomationCourses,
  resetToInitialState
} from "./slice";
import {
  fetchAllData,
  fetchAutomationSettings,
  fetchUnassignedOrders,
  deleteCourseThunk,
  fetchUserAccountsByOffset,
  fetchVehiclesByOffset,
  fetchVirtualCoursePointsByOffset,
  updateCourseOrderThunk,
  fetchTrashedOrders,
  updateAutomationSettingThunk,
  updateCollectionCyclesToTrashThunk,
  fetchPointsInBackgroundThunk,
  unassignedParallelSearch,
  createAutomationSettingsThunk
} from "./api";
export default reducer;
export {
  fetchAllData,
  fetchAutomationSettings,
  fetchUnassignedOrders,
  automationsSliceSelector,
  addNewCourse,
  updateCoursesOrder,
  deleteCourseThunk,
  fetchUserAccountsByOffset,
  fetchVehiclesByOffset,
  fetchVirtualCoursePointsByOffset,
  updateCourseOrderThunk,
  removeNewCourse,
  fetchTrashedOrders,
  updateAutomationSettingThunk,
  updateCollectionCyclesToTrashThunk,
  fetchPointsInBackgroundThunk,
  unassignedParallelSearch,
  updateAutomationCourses,
  createAutomationSettingsThunk,
  resetToInitialState
};
