import { styles } from "./styles";
import useClasses from "utils/useClasses";
import { useEffect } from "react";

/**
 * 1コースを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  const classes = useClasses(styles);

  const scrollHorizontal = (event) => {
    if (event.deltaX === -0) {
      event.stopPropagation();
      event.preventDefault();
      // noinspection JSSuspiciousNameCombination
      document?.querySelector(".scrollTarget")?.scrollBy(event.deltaY, 0);
    }
  };

  useEffect(() => {
    document.querySelectorAll(".horizontalScroll").forEach(function (element) {
      element.addEventListener("mousewheel", scrollHorizontal);
    });

    return () => {
      document
        .querySelectorAll(".horizontalScroll")
        .forEach(function (element) {
          element.removeEventListener("mousewheel", scrollHorizontal);
        });
    };
  }, []);

  return render({
    classes: classes,
    value: value,
    ...props,
  });
};
