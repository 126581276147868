import reducer from "./slice";
import {
  selector,
  prefecturesSelector,
  isLoadingSelector,
  errorSelector,
} from "./slice";
import { fetchPrefectures } from "./api";

export default reducer;

export {
  prefecturesSelector,
  selector,
  isLoadingSelector,
  errorSelector,
  fetchPrefectures,
};
