import { Button, FormHelperText, Grid } from "@mui/material";
import React from "react";
import { RenderButtonToolBar } from "utils/XgridExpand";
import { ModalPopup, XGridComponent } from "views/molecules";
import { WastesForm } from "../WastesForm";

const Form = React.forwardRef((props, ref) => (
  <WastesForm {...props} _ref={ref} />
));

/**
 * 排出情報を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Elemnt}
 */
export const Presententer = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <XGridComponent
          rows={props.rows ?? []}
          columns={props.columns}
          onSearcher={false}
          onDownload={false}
          onColumns={false}
          onFilter={false}
          multipleSelection={false}
          checkboxSelection={false}
          disableSelectionOnClick={true}
          actionButton={
            <RenderButtonToolBar
              label={"廃棄物を追加"}
              onClick={props.onOpenModal}
              disabled={!props.dischargeSourceWorkplaceId}
            />
          }
        />
        <FormHelperText error={!!props.errors?.wastes}>{props.errors?.wastes?.message}</FormHelperText>
        <ModalPopup open={props.open} onClose={props.onCloseModal}>
          <div className={props.classes.title}>
            {props.selectedValue ? "廃棄物の編集" : "廃棄物の追加"}
          </div>
          <div className={props.classes.context}>
            <Form
              dischargeSourceWorkplaceId={props.dischargeSourceWorkplaceId}
              value={props.selectedValue?.data}
              onSubmit={props.onSubmit}
              ref={props.formRef}
            />
          </div>
          <div className={props.classes.actions}>
            <Button
              variant="outlined"
              color="primary"
              onClick={props.onCloseModal}
              sx={{
                marginRight: "10px",
              }}
            >
              {"キャンセル"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={props.onDecided}
            >
              {"決定"}
            </Button>
          </div>
        </ModalPopup>
      </Grid>
    </Grid>
  );
};
