import { Container, Paper, } from "@mui/material";
import React from "react";
import { RenderButtonToolBar } from "utils/XgridExpand";
import { FullScreenDialog, XGridComponent } from "views/molecules";
import { WorkplaceForm } from "views/organisms/Masters/Partner/WorkplaceForm";

/**
 * 取引先事業場を選択するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <>
    <XGridComponent
      autoHeight={props.autoHeight}
      value={props.value}
      onChangeSelection={props.onChangeSelection}
      checkboxSelection={props.checkbox}
      multipleSelection={props.multiple}
      onDownload={props.download}
      titleTable={props.title}
      actionButton={
        props.belongInCompanyId && (
          <RenderButtonToolBar
            label="新しい取引先事業場を追加する"
            onClick={props.onOpenDialog}
          />
        )
      }
      {...props.xGridParams}
    />
    <FullScreenDialog
      onClose={props.onCloseDialog}
      title={"新しい取引先事業場の登録"}
      textConfirm={"登録"}
      open={props.open}
      formId="client-workplace-form"
      isSubmit={props.isSubmit}
      disabled={props.isSubmit}
    >
      <Container maxWidth="md">
        <Paper
          elevation={3}
          sx={{
            padding: 5,
          }}
        >
          <WorkplaceForm id="client-workplace-form" onSubmit={props.onSubmit} />
        </Paper>
      </Container>
    </FullScreenDialog>
  </>
);
