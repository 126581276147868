import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  box_image_upload: {
    border: "1px solid",
    borderColor: theme.palette.text.disabled,
    borderRadius: "4px",
    textAlign: "center",
    padding: "16px 0",
  },
  button_upload: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  iconUpload: {
    fontSize: "4rem",
    color: theme.palette.text.secondary,
  },
}));
