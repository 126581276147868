import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 確認ダイアログを表示するコンポーネントです
 * @param {string} title タイトル
 * @param {string} message メッセージ
 * @param {boolean} open 開閉
 * @fires ConfirmDialog#onPositive 肯定時
 * @fires ConfirmDialog#onNegative 否定時
 * @param {string} positiveText 肯定テキスト
 * @param {string} negativeText 否定テキスト
 */
export const ConfirmDialog = containerPresententer(Container, Presententer);
