import { SinglePDF } from "views/atoms/SinglePDF";
import TermsOfService from "resources/pdf/TermsOfService.pdf";

/**
 * 利用規約を表示するコンポーネント。
 * @param {number} props.width 横幅
 * @returns {JSX.Element}
 */
export const TermsOfServicePDF = ({ width }) => {
  return <SinglePDF file={TermsOfService} />;
};
