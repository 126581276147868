import { Container, Grid, Paper } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { DatePicker, InputField, InputFieldPopppSelect } from "views/molecules";
import { WorkplaceTable } from "../WorkplaceTable";

/**
 * 事業者情報のフォームを行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 */
export const Presententer = (props) => {
  return (
    <>
      <form id={props.id} onSubmit={props.submit} noValidate>
        <Container maxWidth="md">
          <Paper elevation={3} className={props.classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputField
                        label="名称"
                        placeholder="事業者の名称を入力します。"
                        required={true}
                        value={value}
                        onChange={onChange}
                        error={error}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="corporateNumber"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <InputField
                      label="法人番号"
                      placeholder="例：0123456789012"
                      value={value}
                      onChange={(e) => {
                        const result = e.target.value;
                        const pattern = new RegExp(/^\d*$/);
                        if ((result && pattern.test(result)) || !result) {
                          onChange(e.target.value);
                        }
                      }}
                      classOverride={props.classes.corporateNumber}
                      error={error}
                      inputProps={{
                        maxLength: 13,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="representativeName"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <InputField
                      label="代表者"
                      placeholder="代表者の氏名を入力します。"
                      required={false}
                      value={value}
                      onChange={onChange}
                      error={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="establishmentedOn"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <DatePicker
                        label="設立日"
                        placeholder="例：2000/10/10"
                        value={value}
                        onChange={onChange}
                        error={error}
                        required={false}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="url"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <InputField
                      label="企業ホームページ"
                      placeholder="例：http://www.greener.co.jp"
                      value={value}
                      onChange={onChange}
                      error={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="headOffice"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <InputFieldPopppSelect
                      label="本社事業場"
                      value={value}
                      onChange={onChange}
                      onOpenModal={props.onOpenModal}
                      error={Boolean(error?.message)}
                      helperText={(!!error?.message ? '必須入力項目です' : '')}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </form>
      <WorkplaceTable
        companyId={props.getValues()?.id}
        open={props.openModal}
        value={props.getValues("headOffice")}
        onClose={props.onCloseModal}
        onChange={props.onChangeHeadOffice}
      />
    </>
  );
};
