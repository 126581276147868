import { Grid } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { DatePicker, FieldDecorateFrame } from "views/molecules";
import { ReportingMethodsField } from "./ChildComponents";

/**
 * 処理フローの管理方法を設定するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          name="validityStartOn"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <DatePicker
              label="回収開始日"
              value={value}
              onChange={onChange}
              required={true}
              error={error}
              disableFuture={false}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="reportingMedia"
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FieldDecorateFrame label={"報告媒体"} required={true}>
              {(params) => (
                <ReportingMethodsField
                  value={value}
                  onChange={onChange}
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
            </FieldDecorateFrame>
          )}
        />
      </Grid>
    </Grid>
  );
};
