import { createSlice } from "@reduxjs/toolkit";
const defaultValue = {
  origin: "",
  destination: "",
  estimatedTimeArrival: null,
};
const Direction = createSlice({
  name: "direction",
  initialState: defaultValue,
  reducers: {
    onOrigin: (state, action) => {
      state.origin = action.payload;
    },
    onDirection: (state, action) => {
      state.destination = action.payload;
    },
    onEstimatedTimeArrival: (state, action) => {
      const d = new Date();
      d.setMinutes(d.getMinutes() + Math.round(action.payload / 60));
      state.estimatedTimeArrival = `${d.getHours()}:${
        d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()
      }`;
    },
  },
});

export const { onDirection, onOrigin, onEstimatedTimeArrival } =
  Direction.actions;
export default Direction.reducer;
