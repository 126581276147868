import React from "react";
import { TabsDetails } from "views/molecules";
import { TabSimpleInformation, TabVehicle } from "./ChildComponents";

/**
 * 事業場情報(基本情報・ギャラリー・車輛)を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <TabsDetails
      getTab={props.getTab}
      tabs={[
        {
          name: "基本情報",
          tabContent: <TabSimpleInformation value={props.value?.basic} />,
        },
        {
          name: "車輛",
          tabContent: <TabVehicle value={props.value?.vehicles} />,
        },
      ]}
    />
  );
};
