import React from "react";
import { XGridComponent } from "views/molecules";

/**
 * 廃棄物を選択するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <div>
      <XGridComponent
        onClose={props.onClose}
        value={props.value}
        onChangeSelection={props.onChangeSelect}
        checkboxSelection={false}
        multipleSelection={false}
        onDownload={false}
        titleTable={"廃棄物の選択"}
        {...props.xGridParams}
        // REMARKS: 追加が必要になったら、他のSelectorコンポーネントと同様にフォームを入れる
      />
    </div>
  );
};
