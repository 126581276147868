import { add as addAlert } from "ducks/Alert";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { styles } from "./styles";

const columns = [
  {
    field: "category",
    headerName: "カテゴリ",
    width: 160,
  },
  {
    field: "receivedAt",
    headerName: "受領日",
    width: 160,
  },
  {
    field: "filename",
    headerName: "ファイル名",
    width: 160,
  },
  {
    field: "issuer",
    headerName: "担当者",
    width: 160,
  },
  {
    field: "remarks",
    headerName: "備考",
    width: 160,
    valueFormatter: (params) => {
      return params.value ? params.value?.split("\n").join(" ") : "";
    },
  },
  {
    field: "createdAt",
    headerName: "登録日",
    width: 160,
  },
];

/**
 * 廃棄物詳細の資料を表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  const classes = useClasses(styles);
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const handleDecided = () => {
    formRef?.current?.submit();
  };

  const handleSubmit = (data) => {
    // UNDONE: 資料登録api待ち
    setIsSubmit(true);
    const createApi = (data) => Promise.resolve(data);
    createApi(data)
      .then((res) => {
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        setOpen(false);
      })
      .catch((err) => {
        debugLog("DocumentForm.submit.error ", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return render({
    classes: classes,
    value: value,
    columns: columns,
    open: open,
    onOpenDialog: () => setOpen(true),
    onCloseDialog: () => setOpen(false),
    onDecided: handleDecided,
    onSubmit: handleSubmit,
    formRef: formRef,
    isSubmit: isSubmit,
    ...props,
  });
};
