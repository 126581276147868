import {
  createWasteManagementMethod,
  updateWaste,
  removeWaste,
} from "api/graphql/mutations";
import {
  getClient,
  getWorkplace,
  searchWasteManagementMethodsByOffset,
} from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { companySelector } from "ducks/Company";
import { toggle } from "ducks/Loading";
import { WeightConvevrtedValueUnitsSelector } from "ducks/WeightConvertedValueUnits";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { debugLog } from "utils/log";
import { useQuery } from "utils/useQuery";
import { useMutation } from "utils/useMutation";
import { useLocation, useNavigate } from "react-router-dom";

/**
 * 廃棄物の詳細画面を表示するコンテナコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, id, wasteId, load, ...props }) => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [client, setClient] = useState(null);
  const [error, setError] = useState(null);
  const [selectedFlows, setSelectedFlows] = useState([]);
  const [fixedFilter, setFixedFilter] = useState();
  const basicFormRef = useRef(null);
  const detailFormRef = useRef(null);
  const detailsRef = useRef(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const weightConvevrtedValueUnits = useSelector(
    WeightConvevrtedValueUnitsSelector
  );
  const company = useSelector(companySelector);
  const { workplaceId } = useParams();
  const [removeWasteMutation, removeWasteMutationStatus] = useMutation(
    removeWaste,
    {
      onCompleted: (data) => {
        navigate({
          pathname: `/master/partner/${id}/workplace/${workplaceId}`,
        });
      },
      succeedMessage: "削除しました。",
      errorMessage: "エラーが発生したため、削除に失敗しました。",
    },
    false,
    null,
    null
  );

  useEffect(() => {
    const qs = new URLSearchParams(location.search);
    setTab(qs.get("tab") ? parseInt(qs.get("tab")) : 0);
  }, [location.search]);

  const workplace = useQuery({
    query: getWorkplace,
    variables: {
      id: workplaceId,
    },
  });

  useEffect(() => {
    dispatch(toggle(true));
    API.graphql(
      graphqlOperation(getClient, {
        id: id,
      })
    )
      .then((res) => {
        setClient(res.data.getClient);
      })
      .catch((err) => {
        debugLog("クライアント情報取得に失敗: ", err);
      })
      .finally(() => {
        dispatch(toggle(false));
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    API.graphql(
      graphqlOperation(searchWasteManagementMethodsByOffset, {
        managedWasteId: wasteId,
      })
    )
      .then((res) => {
        setFixedFilter({
          and: [
            { targetWasteTypeId: { eq: value?.type?.id } },
            { managementWasteId: { ne: wasteId } },
          ],
        });
      })
      .catch((err) => {});
  }, [value, wasteId]);

  const handleGetTabNumber = (newTab) => {
    setTab(newTab);
  };

  const formatBasicData = (data) => {
    if (!data) {
      return null;
    }

    const { dischargeFrequency, ...other } = data;

    return {
      dischargeFrequency: {
        wasteDischargeInterval: dischargeFrequency.interval,
        numberOfTimes: dischargeFrequency.numberOfTimes,
      },
      ...other,
    };
  };

  const handleUpdateBasic = (data) => {
    setIsSubmit(true);
    const {
      id,
      number,
      type,
      kind,
      name,
      packing,
      packingDetails,
      quantityUnit,
      weightConvertedValue,
      dischargeFrequency,
      dischargeAmountPerOneTime,
      isImportedWaste,
      version,
    } = data;

    API.graphql(
      graphqlOperation(updateWaste, {
        input: {
          id: id,
          number: number,
          wasteTypeId: type.id,
          kind: kind,
          name: name,
          wastePackingCode: packing?.code,
          packingDetails: packingDetails,
          wasteQuantityUnitCode: quantityUnit.code,
          weightConvertedValue:
            quantityUnit?.name === "kg"
              ? 1
              : quantityUnit?.name === "t"
              ? 1000
              : weightConvertedValue,
          weightConvertedValueUnitId: weightConvevrtedValueUnits.find(
            (item) => item.name === quantityUnit.name
          )?.id,
          dischargeFrequency: {
            wasteDischargeIntervalId:
              dischargeFrequency?.wasteDischargeInterval?.id,
            numberOfTimes: dischargeFrequency?.numberOfTimes,
          },
          dischargeAmountPerOneTime: dischargeAmountPerOneTime,
          isImportedWaste: isImportedWaste,
          expectedVersion: version,
        },
      })
    )
      .then((res) => {
        dispatch(
          addAlert({
            value: "更新しました。",
            severity: "success",
          })
        );
        load();
        setOpen(false);
      })
      .catch((err) => {
        debugLog(err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、更新できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleSubmit = () => {
    setError(null);
    setIsSubmit(true);
    if (open === "basic") {
      basicFormRef.current.submit();
    } else if (open === "flow") {
      API.graphql(
        graphqlOperation(createWasteManagementMethod, {
          input: {
            wasteId: wasteId,
            wasteFlowIds: selectedFlows.map((item) => item.id),
          },
        })
      )
        .then((res) => {
          detailsRef.current.refresh();
          dispatch(
            addAlert({
              value: "保存しました。",
              severity: "success",
            })
          );
          setSelectedFlows([]);
          setOpen(null);
        })
        .catch((err) => {
          debugLog("処理フロー紐づけ編集に失敗: ", err);
          dispatch(
            addAlert({
              value: "エラーが発生したため、保存できませんでした。",
              severity: "error",
            })
          );
        })
        .finally(() => {
          setIsSubmit(false);
        });
    }
  };

  return render({
    ...props,
    company: company,
    selectedFlows: selectedFlows,
    client: client,
    value: value,
    tab: tab,
    open: open,
    getTab: handleGetTabNumber,
    onOpen: (name) => () => setOpen(name),
    onClose: () => setOpen(null),
    basicFormRef: basicFormRef,
    detailFormRef: detailFormRef,
    id: id,
    wasteId: wasteId,
    formatBasicData: formatBasicData,
    onUpdateBasic: handleUpdateBasic,
    onSubmit: handleSubmit,
    onSelectedFlows: (data) => {
      setSelectedFlows(data);
    },
    isSubmit: isSubmit,
    error: error,
    fixedFilter: fixedFilter,
    detailsRef: detailsRef,
    workplace: workplace?.data?.getWorkplace,
    removeWasteMutation: removeWasteMutation,
    removeWasteMutationStatus: removeWasteMutationStatus,
  });
};
