import { useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { basicSchema } from "./Validations/basic";
import { debugLog } from "utils/log";
import { DateTime } from "luxon";

const defaultValues = {
  validityStartOn: DateTime.now().toFormat("yyyy/MM/dd"),
  reportingMedia: [],
};

/**
 * 処理フローの管理方法を設定するコンテナコンポーネントです。
 * @param {object} value 処理フロー管理方法
 * @fires Container#onSubmit 送信時
 * @fires Containe#onError エラー時
 * @param {object} props
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  managedCompanyId,
  value,
  onSubmit = (data) => debugLog("管理方法成功: ", data),
  onError = (err) => debugLog("管理方法失敗: ", err),
  ...props
}) => {
  const { trigger, getValues, control, formState } = useForm({
    mode: "onBlur",
    resolver: yupResolver(basicSchema),
    defaultValues: {
      ...defaultValues,
      ...value,
    },
  });

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      trigger().then((res) => {
        if (res) {
          onSubmit && onSubmit(getValues());
        } else {
          onError(formState.errors);
        }
      });
    },
  }));

  return render({
    value: value,
    managedCompanyId: managedCompanyId,
    control: control,
    ...props,
  });
};
