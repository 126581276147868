import { useNavigate } from "react-router-dom";

/**
 * モバイルの車輛点検を表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  inspectionExpirationDateForm,
  odoMeterForm,
  checkSheetSelector,
  vehicleInspectionForm,
  loading,
  xGridParams,
  value,
  onCreateInspection,
  onUpdateVehicleCertificate,
  onUpdateVehicleInstrument,
  ...props
}) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(`/vehicle-inspection-list`);
  };

  return render({
    ...props,
    onBack: handleBack,
    inspectionExpirationDateForm: inspectionExpirationDateForm,
    odoMeterForm: odoMeterForm,
    checkSheetSelector: checkSheetSelector,
    vehicleInspectionForm: vehicleInspectionForm,
    loading: loading,
    xGridParams: xGridParams,
    value: value,
    onCreateInspection: onCreateInspection,
    onUpdateVehicleCertificate: onUpdateVehicleCertificate,
    onUpdateVehicleInstrument: onUpdateVehicleInstrument,
  });
};
