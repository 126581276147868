import React from "react";
import { InformationItem } from "views/atoms";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * 処理フロー詳細の表示を行うプレゼンテーションコンポーネントです
 * @param {object} classes クラス名
 * @param {object} value 事業場情報
 */
export const Presententer = (props) => {
  const { value } = props;
  if (props.loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <InformationItem label="管理番号">{value?.number ?? ""}</InformationItem>
      <InformationItem label="名称">{value?.name ?? ""}</InformationItem>
      <InformationItem label="廃棄物の種類">
        {value?.targetWasteType?.smallClass?.name ||
          value?.targetWasteType?.middleClass?.name ||
          value?.targetWasteType?.largeClass?.name ||
          ""}
      </InformationItem>
      <InformationItem label="廃棄物の詳細種類">
        {value?.targetWasteKind ?? ""}
      </InformationItem>
      {value?.transportProcesses?.map((process, index) => (
        <React.Fragment key={index}>
          <InformationItem label={"廃棄物の受託者" + process.section}>
            {process?.carrierCompany?.name ?? ""}
          </InformationItem>
          <InformationItem label={"運搬方法" + process.section}>
            {process?.transportMethod?.name ?? ""}
          </InformationItem>
        </React.Fragment>
      ))}
      <InformationItem label="処分事業場">
        {value?.disposalProcess?.disposalCompany?.name ?? ""}
      </InformationItem>
      <InformationItem label="処分方法">
        {value?.disposalProcess?.disposalMethod?.smallClassName ||
          value?.disposalProcess?.disposalMethod?.middleClassName ||
          value?.disposalProcess?.disposalMethod?.largeClassName ||
          ""}
        {`${
          value?.disposalProcess?.disposalMethod?.code
            ? `(${value?.disposalProcess?.disposalMethod?.code})`
            : ""
        }`}
      </InformationItem>
      <InformationItem label="処分方法の詳細">
        {value?.disposalProcess?.disposalMethod?.disposalMethodDetails || ""}
      </InformationItem>
    </div>
  );
};
