import { useEffect, useState } from "react";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { DateTime } from "luxon";
import {
  getUnassignedWasteCollectionScheduleCount,
  listWasteCollectionSchedulesCount,
} from "api/graphql/queries";
import { debugLog } from "utils/log";
import { formatDisplayDate } from "utils/format";
import { useXGridComponents } from "utils/useXGridComponents";
import { useOpener } from "utils/useOpener";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    field: "scheduleDate",
    headerName: "回収日",
    width: 150,
    renderCell: (params) =>
      formatDisplayDate({
        source: params.value,
        destFormat: "yyyy/MM/dd (EEE)",
      }),
  },
  {
    field: "unassignedCount",
    headerName: "未配車数",
    flex: 1,
  },
  {
    field: "assignedCount",
    headerName: "配車数合計",
    flex: 1,
  },
  {
    field: "lastUpdatedOn",
    headerName: "最終更新日",
    flex: 1,
    renderCell: (params) => {
      if (params.value === null) {
        return "-";
      }
      const luxonDate = DateTime.fromISO(params.value, { zone: "utc" });
      return luxonDate.toFormat("yyyy/MM/dd");
    },
  },
];

export const Container = ({ render, ...props }) => {
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const dialog = useOpener();
  const navigate = useNavigate();
  const obj = useXGridComponents(
    columns,
    listWasteCollectionSchedulesCount,
    {
      other: {
        sortDirection: "DESC",
      },
      limit: 360,
    },
    null
  );

  const load = () => {
    setLoading(true);
    return API.graphql(
      graphqlOperation(getUnassignedWasteCollectionScheduleCount)
    )
      .then((res) => {
        setValue(res.data.getUnassignedWasteCollectionScheduleCount);
      })
      .catch((err) => {
        debugLog(
          "getUnassignedWasteCollectionScheduleCountの呼び出し失敗: ",
          err
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelected = (params) => {
    const row = obj.params.rows
      .map((d, i) => ({ id: i, ...d }))
      .find((i) => i.id === params[0]);
    navigate(`/allocation/schedule?date=${row.scheduleDate}`);
  };

  useEffect(() => {
    load();
  }, []);

  return render({
    onSelected: handleSelected,
    value: value,
    loading: loading,
    xGridParams: obj.params,
    dialog: dialog,
    ...props,
  });
};
