import { ConfirmDialog } from "views/molecules";
import { useMutation } from "utils/useMutation";
import { suspendUserAccount } from "api/graphql/mutations";

/**
 * 停止するダイアログを表示するコンポーネント。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {boolean} props.open 開閉状態
 * @fires StopDialog#onClose 閉じる時
 * @returns {JSX.Element}
 */
export const StopDialog = ({
  value = null,
  open = false,
  onCompleted = () => {},
  onClose = () => {},
}) => {
  const [stopMutation, stopMutationStatus] = useMutation(suspendUserAccount, {
    onCompleted: () => {
      onCompleted();
      onClose();
    },
    succeedMessage: "停止しました。",
    errorMessage: "エラーが発生したため、停止できませんでした。",
  });

  return (
    <ConfirmDialog
      disabled={stopMutationStatus.loading}
      open={open}
      title="停止確認"
      message="停止します。よろしいですか？"
      positiveText="はい"
      negativeText="いいえ"
      onPositive={() => {
        stopMutation({
          input: {
            id: value.id,
          },
        });
      }}
      onNegative={onClose}
    />
  );
};
