import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * コンテナ管理を追加するフォームコンポーネントです。
 * @param {boolean} open 開閉状態
 * @fires AddDialogForm#onClose 閉じる時
 * @fires AddDialogForm#onNotification 更新通知
 * @return {JSX.Element}
 */
export const AddDialogForm = containerPresententer(Container, Presententer);
