import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { debugLog } from "utils/log";
import { basicSchema } from "./Validations/basic";

const defaultValues = {
  date: new Date(),
  times: null,
  remarks: "",
};

/**
 * 定期回収の回収情報を入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @fires Container#onSubmit 送信時
 * @fires Container#onError エラー時
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onSubmit = (data) => debugLog(data),
  onError = (err) => debugLog(err),
  ...props
}) => {
  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    methods.reset({
      ...defaultValues,
      ...value,
    });
  }, [methods, value]);

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      methods.trigger().then((res) => {
        if (res) {
          onSubmit(methods.getValues());
        } else {
          onError(methods.formState.errors);
        }
      });
    },
  }));

  return render({
    methods: methods,
    ...props,
  });
};
