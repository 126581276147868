import { Jwnet } from "features/ExternalServiceLinkage/Jwnet";

/**
 * Jwnetの設定を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Jwnet
      value={props.value}
      onNotificationChange={props.onNotificationChange}
    />
  );
};
