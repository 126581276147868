import {
  removePaperManifest,
  updatePaperManifest,
} from "api/graphql/mutations";
import { getPaperManifest } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { toggle } from "ducks/Loading";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { PhoneNumberFormatUtil } from "utils/format";
import { debugLog } from "utils/log";

/**
 * 紙マニフェストの詳細を表示するコンテナコンポーネントです
 * @callback render
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [value, setValue] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const getDate = (value) => {
    if (!value || value === "") {
      return null;
    }

    let result = DateTime.fromFormat(value, "yyyy/MM/dd");

    if (result.isValid) {
      return result.toISODate();
    }

    result = DateTime.fromJSDate(new Date(value));

    if (result.isValid) {
      return result.toISODate();
    }

    return value;
  };

  const fetchManifest = useCallback(() => {
    dispatch(toggle(true));
    return API.graphql(
      graphqlOperation(getPaperManifest, {
        id: id,
      })
    )
      .then((res) => {
        setValue(res.data.getPaperManifest);
      })
      .catch((err) => {
        debugLog("紙マニフェストの読み込み失敗: ", err);
      })
      .finally(() => {
        dispatch(toggle(false));
      });
  }, [dispatch, id]);

  const updateManifest = (data) => {
    const {
      name,
      phone,
      postalCode,
      prefectures,
      city,
      streetAddress,
      otherAddress,
      paperManifestType,
      manifestInfoType,
      manifestCardinalNumberType,
      issueDate,
      intermediateProcessingManagementType,
      lastDisposalPlantDescribeType,
      wasteGeneratorCompany,
      wasteGeneratorWorkplace,
      waste,
      plannedLastDisposalPlant,
      wasteCarrierCompany,
      wasteTransportDestination,
      wasteTransshipmentStorage,
      wasteDisposalCompany,
      wasteLastDisposalPlant,
      wasteTransportReport,
      wasteDisposalReport,
      createdAt,
      updatedAt,
      ...other
    } = data;

    return API.graphql(
      graphqlOperation(updatePaperManifest, {
        input: {
          manifestInfoTypeCode: manifestInfoType?.code,
          manifestCardinalNumberTypeCode: manifestCardinalNumberType?.code,
          issueDate: getDate(issueDate),
          intermediateProcessingManagementTypeCode:
            intermediateProcessingManagementType?.code,
          lastDisposalPlantDescribeTypeCode:
            lastDisposalPlantDescribeType?.code,
          wasteGeneratorCompany: {
            ...wasteGeneratorCompany,
            id: undefined,
            prefecturesCode: wasteGeneratorCompany?.prefectures?.code,
            prefectures: undefined,
            phone: wasteGeneratorCompany?.phone
              ? PhoneNumberFormatUtil.formatInternational(
                  wasteGeneratorCompany.phone
                )
              : null,
          },
          wasteGeneratorWorkplace: {
            ...wasteGeneratorWorkplace,
            prefecturesCode: wasteGeneratorWorkplace?.prefectures?.code,
            prefectures: undefined,
            phone: wasteGeneratorWorkplace?.phone
              ? PhoneNumberFormatUtil.formatInternational(
                  wasteGeneratorWorkplace.phone
                )
              : null,
          },
          waste: {
            ...waste,
            quantityValue:
              !waste.quantityValue || waste.quantityValue === ""
                ? null
                : waste.quantityValue,
            quantityUnitCode: waste.quantityUnit?.code,
            types: waste.types?.map((type) => {
              return {
                id: type.wasteType.id,
                name: type.wasteTypeName,
              };
            }),
            quantityUnit: undefined,
          },
          plannedLastDisposalPlant: {
            ...plannedLastDisposalPlant,
            prefecturesCode:
              plannedLastDisposalPlant?.prefectures?.code ?? null,
            prefectures: undefined,
            phone: plannedLastDisposalPlant?.phone
              ? PhoneNumberFormatUtil.formatInternational(
                  plannedLastDisposalPlant.phone
                )
              : null,
          },
          wasteCarrierCompany: {
            ...wasteCarrierCompany,
            id: undefined,
            prefecturesCode: wasteCarrierCompany?.prefectures?.code,
            prefectures: undefined,
            phone: wasteCarrierCompany?.phone
              ? PhoneNumberFormatUtil.formatInternational(
                  wasteCarrierCompany.phone
                )
              : null,
          },
          wasteTransportDestination: {
            ...wasteTransportDestination,
            prefecturesCode: wasteTransportDestination?.prefectures?.code,
            prefectures: undefined,
            phone: wasteTransportDestination?.phone
              ? PhoneNumberFormatUtil.formatInternational(
                  wasteTransportDestination.phone
                )
              : null,
          },
          wasteTransshipmentStorage: {
            ...wasteTransshipmentStorage,
            prefecturesCode: wasteTransshipmentStorage?.prefectures?.code,
            prefectures: undefined,
            phone: wasteTransshipmentStorage?.phone
              ? PhoneNumberFormatUtil.formatInternational(
                  wasteTransshipmentStorage.phone
                )
              : null,
          },
          wasteDisposalCompany: {
            ...wasteDisposalCompany,
            prefecturesCode: wasteDisposalCompany?.prefectures?.code,
            prefectures: undefined,
            phone: wasteDisposalCompany?.phone
              ? PhoneNumberFormatUtil.formatInternational(
                  wasteDisposalCompany.phone
                )
              : null,
          },
          wasteLastDisposalPlant: {
            ...wasteLastDisposalPlant,
            prefecturesCode: wasteLastDisposalPlant?.prefectures?.code,
            prefectures: undefined,
            phone: wasteLastDisposalPlant?.phone
              ? PhoneNumberFormatUtil.formatInternational(
                  wasteLastDisposalPlant.phone
                )
              : null,
          },
          wasteTransportReport: {
            ...wasteTransportReport,
            valuablesQuantityUnitCode:
              wasteTransportReport.valuablesQuantityUnit?.code,
            valuablesQuantityValue:
              !wasteTransportReport?.valuablesQuantityValue ||
              wasteTransportReport?.valuablesQuantityValue === ""
                ? null
                : wasteTransportReport?.valuablesQuantityValue,
            valuablesQuantityUnit: undefined,
            endOfTransportationDate: getDate(
              wasteTransportReport?.endOfTransportationDate
            ),
          },
          wasteDisposalReport: {
            ...wasteDisposalReport,
            endOfDisposalDate: getDate(wasteDisposalReport?.endOfDisposalDate),
            endOfLastDisposalDate: getDate(
              wasteDisposalReport?.endOfLastDisposalDate
            ),
          },
          ...other,
          wasteGeneratorCompanyId: undefined,
          wasteGeneratorWorkplaceId: undefined,
          wasteId: undefined,
          wasteFlowId: undefined,
        },
      })
    );
  };

  const deleteManifest = () => {
    return API.graphql(
      graphqlOperation(removePaperManifest, {
        input: {
          id: id,
        },
      })
    );
  };

  useEffect(() => {
    fetchManifest();
  }, [fetchManifest]);

  const handleSubmit = (data) => {
    setIsLoading(false);
    updateManifest(data)
      .then((res) => {
        dispatch(
          addAlert({
            value: "保存しました。",
            severity: "success",
          })
        );
        setValue(res.data.updatePaperManifest);
        setOpen(false);
      })
      .catch((err) => {
        debugLog("保存失敗: ", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、保存できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelete = () => {
    setIsLoading(true);
    deleteManifest()
      .then((res) => {
        navigate(`/slip/paper`, { replace: true });
        dispatch(
          addAlert({
            value: "削除しました。",
            severity: "success",
          })
        );
      })
      .catch((err) => {
        debugLog("削除失敗: ", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、削除できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEdit = () => {
    setOpen(true);
  };

  return render({
    value: value,
    open: open,
    isLoading: isLoading,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    onSubmit: handleSubmit,
    onDelete: handleDelete,
    onEdit: handleEdit,
    ...props,
  });
};
