import { Box, Typography } from "@mui/material";
import React from "react";

/**
 * コースなしを知らせるプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box
      sx={{
        textAlign: "center",
        padding: (theme) => theme.spacing(2, 0),
      }}
    >
      <Typography color="text.secondary">データがありません。</Typography>
    </Box>
  );
};
