import { Button, Paper, Stack } from "@mui/material";
import { DateTime } from "luxon";
import { useOpener } from "utils/useOpener";
import { InformationGroup, InformationItem } from "views/atoms";
import { AgreeDialog } from "../AgreeDialog";

/**
 * 確認フォームを表示するコンポーネント。
 * @param {object} props プロパティ
 * @param {object} props.value 入力値
 * @param {boolean} props.disabled 無効設定
 * @fires ConfirmingForm#onBack 戻る時
 * @fires ConfirmingForm#onApplied 申請時
 * @returns {JSX.Element}
 */
export const ConfirmingForm = ({
  value = null,
  onBack = () => {},
  onApplied = () => {},
  disabled = false,
}) => {
  const dialog = useOpener();

  const handleApplied = async (params) => {
    onApplied && onApplied(params);
    dialog.toggle(false);
  };

  return (
    <Paper
      sx={{
        p: 2,
      }}
    >
      <Stack spacing={2}>
        <InformationGroup label="会社情報">
          <InformationItem label="名称">
            {value?.company?.companyName ?? ""}
          </InformationItem>
          <InformationItem label="法人番号">
            {value?.company?.corporateNumber ?? ""}
          </InformationItem>
          <InformationItem label="代表者名">
            {value?.company?.representativeName ?? ""}
          </InformationItem>
          <InformationItem label="設立日">
            {value?.company?.establishmentedOn
              ? DateTime.fromJSDate(value.company.establishmentedOn).toFormat(
                  "yyyy/MM/dd"
                )
              : ""}
          </InformationItem>
          <InformationItem label="企業ホームページ">
            {value?.company?.url ?? ""}
          </InformationItem>
        </InformationGroup>
        <InformationGroup label="アカウント情報">
          <InformationItem label="名前">
            {value?.account?.userAccountName ?? ""}
          </InformationItem>
          <InformationItem label="メールアドレス">
            {value?.account?.email ?? ""}
          </InformationItem>
        </InformationGroup>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Button
            variant="contained"
            color="inherit"
            onClick={() => onBack(null)}
            disabled={disabled}
          >
            {"戻る"}
          </Button>
          <Button
            disabled={disabled}
            variant="contained"
            color="primary"
            onClick={() => {
              dialog.toggle(true);
            }}
          >
            {"申請する"}
          </Button>
        </Stack>
      </Stack>
      <AgreeDialog
        open={dialog.open}
        onClose={() => dialog.toggle(false)}
        disabled={disabled}
        onAgree={handleApplied}
      />
    </Paper>
  );
};
