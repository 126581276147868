import { useStyles } from "./styles";
import useClasses from "utils/useClasses";

/**
 * 基本情報のタブの表示を行うコンテナコンポーネントです
 * @param {object} value 事業場情報
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  const classes = useClasses(useStyles);

  const getAddress = (value) => {
    if (!value) {
      return "";
    }
    return `〒${value?.postalCode ?? ""} ${value.prefectures?.name ?? ""}${
      value?.city ?? ""
    }${value?.streetAddress ?? ""}${value?.otherAddress ?? ""}`;
  };

  return render({
    classes: classes,
    value: {
      ...value,
      address: getAddress(value)
    },
    ...props,
  });
};
