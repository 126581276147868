import { Box, Button } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { DateTime } from "luxon";
import { DatePicker } from "views/atoms";
import { FieldDecorateFrame } from "views/molecules";

/**
 * 終了日を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <Box pt={3} pb={3}>
    <div
      style={{
        paddingBottom: "20px",
      }}
    >
      <Controller
        name="date"
        control={props.control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <FieldDecorateFrame label="終了日">
              {(params) => (
                <DatePicker
                  value={value}
                  onChange={(date) => {
                    const minDate = DateTime.fromISO(props.minDate, {
                      setZone: "Asia/Tokyo",
                    }).toFormat("yyyy/MM/dd");

                    if (new Date(date) < new Date(minDate)) {
                      props.setError("date", {
                        type: "manual",
                        message: "終了日は開始日より前に設定できません。",
                      });
                    } else {
                      props.clearError("date");
                    }
                    onChange(date);
                  }}
                  placeholder="例）2021/06/06"
                  error={Boolean(error)}
                  helperText={error?.message}
                  minDate={new Date(props.minDate) ?? new Date()}
                />
              )}
            </FieldDecorateFrame>
          );
        }}
      />
    </div>
    <Button
      variant="outlined"
      color="primary"
      onClick={props.onCancel}
      sx={{
        marginRight: "10px",
      }}
    >
      {"キャンセル"}
    </Button>
    <Button variant="contained" color="primary" onClick={props.onConfirm}>
      {"決定"}
    </Button>
  </Box>
);
