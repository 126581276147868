import { useState } from "react";

/**
 * 処理フローの選択するコンテナコンポーネントです。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({
  render,
  value,
  option,
  onChange,
  onClickDetails,
  display = false,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  return render({
    value: value,
    option: option,
    onChange: onChange,
    display: display,
    open: open,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    ...props,
  });
};
