import { Button, Box, Link } from "@mui/material";
import { FullScreenDialog, XGridComponent } from "views/molecules";
import { PartnerForm } from "views/organisms/Masters/Partner";

/**
 * 取引先の表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <>
    <XGridComponent
      onChangeSelection={props.onChangeSelection}
      checkboxSelection={false}
      multipleSelection={false}
      onDownload={true}
      actionButton={
        <Box
          display="flex"
          alignItems="flex-end"
          flexDirection="column"
          mt={-5}
          gap={1}
        >
          <Link
            color="primary"
            sx={{
              cursor: "pointer",
              fontWeight: 600,
            }}
            onClick={props.navigateToBulkCreation}
            fontSize={16}
          >
            {"一括登録"}
          </Link>
          <Button
            variant="contained"
            color="primary"
            onClick={props.onOpenDialog}
          >
            {"新しい取引先を追加"}
          </Button>
        </Box>
      }
      {...props.xGridParams}
    />
    <FullScreenDialog
      onClose={props.onCloseDialog}
      title={"新しい取引先の登録"}
      textConfirm={"登録"}
      open={props.open}
      formId="partner-form"
      isSubmit={props.isSubmit}
      disabled={props.isSubmit}
    >
      <PartnerForm id="partner-form" onSubmit={props.submit} />
    </FullScreenDialog>
  </>
);
