import {
  Card,
  CardActionArea,
  CardContent,
  Skeleton,
  Typography,
  Badge,
} from "@mui/material";

/**
 * 許可証アラートボタンコンポーネントです。(レイアウト)
 * @param {object} props プロパティ
 * @param {boolean} props.loading 読み込み中であるか
 * @param {number} props.count 対応が必要な許可証の数
 * @returns {JSX.Element}
 */
export const Layout = ({ loading = false, count = 0, onClick = () => {} }) => {
  if (loading === true) {
    return <Skeleton variant="rectangular" width={160} height={50} />;
  }

  if (count <= 0) {
    return <></>;
  }

  return (
    <Card
      onClick={onClick}
      sx={{
        height: "100%",
      }}
    >
      <CardActionArea>
        <CardContent>
          <Badge
            badgeContent={count}
            color="error"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Typography
              color="textSecondary"
              gutterBottom
              sx={{
                "&::after": {
                  position: "absolute",
                  top: "-10px",
                  left: "-10px",
                  width: "5px",
                  height: "5px",
                  borderRadius: "50%",
                  animation: "ripple 1.2s infinite ease-in-out",
                  border: "1px solid #f50057",
                  content: '""',
                },
                "@keyframes ripple": {
                  "0%": {
                    transform: "scale(.8)",
                    opacity: 1,
                  },
                  "100%": {
                    transform: "scale(3.4)",
                    opacity: 0,
                  },
                },
              }}
            >
              {"許可証アラート"}
            </Typography>
          </Badge>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
