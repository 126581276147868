import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";

/**
 * 報告方法を選択するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <FormControl component="fieldset" fullWidth error={props.error}>
      <FormLabel component="legend">{props.label}</FormLabel>
      <FormGroup aria-label="group" name={props.name}>
        {props.options.map((option, index) => (
          <FormControlLabel
            key={index}
            name={option.value}
            value={props.value?.includes((item) => option.value === item) ?? false}
            onChange={props.onChange}
            control={<Checkbox />}
            label={
              <Box>
                <Box className={props.classes.title}>{option.title}</Box>
                <Box className={props.classes.description}>
                  {option.description}
                </Box>
              </Box>
            }
          />
        ))}
      </FormGroup>
      <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
    </FormControl>
  );
};
