import { EditOutlined } from "@mui/icons-material";
import { TimelineConnector, TimelineSeparator } from "@mui/lab";
import clsx from "clsx";
import { If } from "views/atoms";
import { RenderButton } from "views/atoms";
import { DotIndicator } from "views/molecules";

const getClassName = (props) => {
  if (props.request === true) {
    return "request";
  }

  if (props.completed === true) {
    return "completed";
  }

  if (props.current === true) {
    return "current";
  }

  if (props.completed === false) {
    return "incomplete";
  }

  return "";
};

/**
 * コースリストのヘッダーを表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => {
  return (
    <TimelineSeparator className={props.classes.container}>
      <TimelineConnector
        className={clsx("line", getClassName(props))}
        {...props.dragHandleProps}
      />
      <DotIndicator isAnimated showDot={props.value?.workplace?.precautions?.length}>
        <RenderButton
          variant={props.current ? "contained" : "outlined"}
          color={
            props.completed === false || props.current === true
              ? "primary"
              : "inherit"
          }
          className={clsx("number", getClassName(props))}
          label={props.index + 1}
          icon={
            <If
              condition={
                props.current === false &&
                props.completed === false &&
                props.passed === true
              }
            >
              <EditOutlined />
            </If>
          }
          onClick={props.onClick}
          disableElevation={true}
        />
      </DotIndicator>
      <TimelineConnector
        className={clsx("line", getClassName(props))}
        {...props.dragHandleProps}
      />
    </TimelineSeparator>
  );
};
