import useClasses from "utils/useClasses";
import { styles } from "./styles";

/**
 * モーダルポップアップを表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  open,
  onClose,
  children,
  className = null,
  ...props
}) => {
  const classes = useClasses(styles);

  return render({
    classes: classes,
    children: children,
    open: open,
    onClose: onClose,
    className: className,
    ...props,
  });
};
