import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const Container = ({ render, ...props }) => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, [location.pathname, navigate]);

  return render({
    ...props,
  });
};
