import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 取引先事業場廃棄物情報を新規登録するコンポーネントです。
 */
export const WasteRegistrationForm = containerPresententer(
  Container,
  Presententer
);
