import { useMediaQuery } from "@mui/material";

/**
 * 各種サイズを取得用のロジックです。
 * @returns {object}
 */
const useSize = () => {
  const isMobileSize = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return { isMobileSize };
};

export default useSize;
