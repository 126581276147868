import React from "react";
import { List } from "views/templates/Mobile/Collect";
import { AppBar } from "views/organisms/Mobile/Common/AppBar";

/**
 * モバイルの回収一覧を表示するプレゼンテーションコンポーネントです。
 */
export const Presententer = (props) => (
  <>
    <AppBar />
    <List {...props} />
  </>
);
