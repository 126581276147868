import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 内容を囲むするコンポーネントです
 * @param {JSX Element} children
 * @param {string} title タイトル
 * @param {string} subTitle サブタイトル、字幕
 */
export const Panel = containerPresententer(Container, Presententer);
