/**
 * コンテナ管理の表を表示するコンテナコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  return render({
    ...props,
  });
};
