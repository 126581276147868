import { styles } from "./styles";
import useClasses from "utils/useClasses";

/**
 * ラディオの表示を行うコンテナコンポーネントです。
 * @callback render
 */
export const Container = ({ render, ...props }) => {
  const classes = useClasses(styles);
  return render({
    classes: classes,
    ...props,
  });
};
