import {
  recordCarryWorkLocation,
  recordWasteCollectionTasks,
} from "api/graphql/mutations";
import { getCarryWorkJournal } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { DateTime } from "luxon";
import { useRef, useState, useEffect, createRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { debugLog } from "utils/log";

/**
 * 回収詳細を入力するコンテナコンポーネントです。
 * @param {object} props プロパティ
 * @returns {func} 描画関数
 */
export const Container = ({
  render,
  values,
  index = 0,
  open = false,
  onSubmit = (data) => debugLog("数量入力: ", data),
  onClose = debugLog,
  onNotificationChangeIndex = debugLog,
  ...props
}) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const formRefs = useRef([]);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    values?.forEach((_, i) => {
      formRefs.current[i] = createRef();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(values)]);

  const handleSubmit = (index) => {
    formRefs.current?.at(index).submit?.();
  };

  const handleRecordUpdate = (data) => {
    const index = values.findIndex((v) => v.id === data.id);
    const results = [...values];
    results.splice(index, 1, data);
    onSubmit(results);
  };

  const fetchCarryWorkJoural = () => {
    return API.graphql(
      graphqlOperation(getCarryWorkJournal, {
        id: id,
      })
    )
      .then((res) => {
        debugLog("getCarryWorkJournalの実行に成功: ", res);
        return res;
      })
      .catch((err) => {
        debugLog("getCarryWorkJournalの実行に失敗: ", err);
      });
  };

  const retryDataGetAndSet = (data, index, isSubmit = false) => {
    fetchCarryWorkJoural()
      .then((res) => {
        const tasks = res.data.getCarryWorkJournal.tasks;
        const task = tasks.find((t) => t.id === data.id);
        handleRecordUpdate(tasks);

        const modifiedData = {
          ...data,
          recordTasks: task?.tasks.map((item, index) => {
            return {
              taskId: item.id,
              wasteFlowId: item?.record?.wasteFlowId,
              quantity: data.recordTasks?.[index]?.quantity ?? 0,
              quantityUnitCode: item?.waste?.quantityUnit?.code,
              weightOfKg: item?.record?.weightOfKg,
              remarks: data.recordTasks?.[index].remarks,
              recordVersion: item?.record?.version,
            };
          }),
          passedRecord: task?.passedRecord,
        };
        if (isSubmit) {
          submit(modifiedData, index, true);
        } else {
          recordTasks(modifiedData, index, true);
        }
      })
      .catch((err) => {
        debugLog("getCarryWorkJournalの実行に失敗: ", err);
        handleRecordUpdate(data);
      });
  };

  const recordLocation = (id, arrivedAt, departedAt, recordVersion) => {
    return API.graphql(
      graphqlOperation(recordCarryWorkLocation, {
        input: {
          locationId: id,
          arrivedAt: arrivedAt,
          departedAt: departedAt,
          recordVersion: recordVersion,
        },
      })
    )
      .then((res) => {
        debugLog("recordCarryWorkLocationの実行に成功: ", res);
        return res;
      })
      .catch((err) => {
        debugLog("recordCarryWorkLocationの実行に失敗: ", err);
      });
  };

  const handleClose = () => {
    onClose();
  };

  const recordTasks = (data, currentIndex, isRetry = false) => {
    setIsSubmit(true);
    const { passedRecord, ...rest } = data;
    return API.graphql(
      graphqlOperation(recordWasteCollectionTasks, {
        input: {
          ...rest,
        },
      })
    )
      .then((res) => {
        debugLog("recordWasteCollectionTasksの実行に成功: ", res);
        if (!data?.passedRecord || !data?.passedRecord?.arrivedAt) {
          const now = DateTime.fromJSDate(new Date()).toISO();
          recordLocation(
            data.locationId,
            now,
            now,
            data?.passedRecord?.recordVersion
          )
            .then((res2) => {
              const data = {
                ...res.data.recordWasteCollectionTasks,
                passedRecord: res2.data.recordCarryWorkLocation,
              };
              handleRecordUpdate(data);
              setIsSubmit(false);
            })
            .catch((err) => {
              debugLog("recordCarryWorkLocationの実行に失敗: ", err);
              handleRecordUpdate(res.data.recordWasteCollectionTasks);
            });
        } else {
          handleRecordUpdate(res.data.recordWasteCollectionTasks);
          setIsSubmit(false);
        }
      })
      .catch((err) => {
        if (!isRetry) {
          retryDataGetAndSet(data, currentIndex);
        } else {
          setIsSubmit(false);
        }
        debugLog("recordWasteCollectionTasksの実行に失敗: ", err);
      });
  };

  const submit = (data, index, isRetry = false) => {
    const { passedRecord, ...rest } = data;
    setIsSubmit(true);
    API.graphql(
      graphqlOperation(recordWasteCollectionTasks, {
        input: {
          ...rest,
        },
      })
    )
      .then((res) => {
        // recordが空白
        if (!data?.passedRecord || !data?.passedRecord?.arrivedAt) {
          const now = DateTime.fromJSDate(new Date()).toISO();
          API.graphql(
            graphqlOperation(recordCarryWorkLocation, {
              input: {
                locationId: data.locationId,
                arrivedAt: now,
                departedAt: now,
                recordVersion: data?.passedRecord?.recordVersion,
              },
            })
          )
            .then((res2) => {
              const data = {
                ...res.data.recordWasteCollectionTasks,
                passedRecord: res2.data.recordCarryWorkLocation,
              };
              handleRecordUpdate(data);
              setIsSubmit(false);
            })
            .catch((err) => {
              debugLog("recordCarryWorkLocationの実行に失敗: ", err);
              handleRecordUpdate(res.data.recordWasteCollectionTasks);
            });
        } else {
          handleRecordUpdate(res.data.recordWasteCollectionTasks);
          setIsSubmit(false);
        }
        handleClose();
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
      })
      .catch((err) => {
        debugLog("数量入力に失敗: ", err);
        if (!isRetry) {
          retryDataGetAndSet(data, index, true);
        } else {
          setIsSubmit(false);
          dispatch(
            addAlert({
              value: "エラーが発生したため、登録できませんでした。",
              severity: "error",
            })
          );
        }
      });
  };

  const handleSwiped = async (indexLast) => {
    await formRefs?.current?.at(indexLast).focus?.();
    await formRefs?.current
      .at(indexLast)
      ?.get?.()
      ?.then((res) => {
        if (res) {
          recordTasks(res, indexLast);
        }
      });
  };

  const handleSlideChangeTransitionStart = () => {
    setIsSubmit(true);
  };

  return render({
    index: index,
    values: values,
    handleSwiped: handleSwiped,
    open: open,
    onClose: handleClose,
    onConfirmed: handleSubmit,
    loading: isSubmit,
    formRefs: formRefs,
    onSubmit: submit,
    onNotificationChangeIndex: onNotificationChangeIndex,
    handleSlideChangeTransitionStart: handleSlideChangeTransitionStart,
    ...props,
  });
};
