import { containerPresententer } from "utils/HoC";
import PropTypes from "prop-types";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * マークを表示するコンポーネントです。
 * @param {string} title Tooltip title
 * @param {JSX.Element} children what to display on the tooltip
 * @param {string} size size of tooltip button
 * @param {string} variant button variant
 * @param {string} color button color
 * @param {object} sx button styole
 * @param {string} placement tooltip placement
 */
export const ReactToolTip = containerPresententer(Container, Presententer);

ReactToolTip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  sx: PropTypes.object,
  placement: PropTypes.string,
};
