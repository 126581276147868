import React from "react";
import { EventAvailableOutlined, SettingsOutlined } from "@mui/icons-material";
import { Grid, Typography, Box, Button } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { RenderMenuSelectItem } from "views/atoms";
import { AlertDialog } from "views/molecules";

/**
 * Formats a given number of seconds into a human-readable time string.
 *
 * @param {number} seconds - The number of seconds to format.
 * @returns {string} A formatted time string in the format "X minutes and Y seconds".
 */
const formatTime = (seconds) => {
  // Calculate the number of minutes and remaining seconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Create a formatted time string based on the calculated values
  let timeString = minutes ? `${minutes} 分 と` : "";
  timeString += ` ${remainingSeconds} 秒`;

  return timeString;
};

/**
 * 配車のメニュー選択を行う画面を表示するプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: 0 }}>
        <Grid item>
          <RenderMenuSelectItem
            icon={EventAvailableOutlined}
            label="配車表"
            className="allocation_schedule"
            onClick={() => props.onClick("schedule")}
          />
        </Grid>
        <Grid item>
          <RenderMenuSelectItem
            icon={SettingsOutlined}
            label="配車計画"
            className="allocation_setting"
            onClick={() => props.onClick("setting")}
            loading={props.loading}
          />
        </Grid>
      </Grid>
      {props.showAlert && (
        <AlertDialog open={props.showAlert} handleClose={props.closeAlert}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 2,
            }}
          >
            <WarningIcon
              color="warning"
              sx={{
                height: 100,
                width: 100,
              }}
            />
            <br />
            <Typography fontWeight={700}>
              他のユーザーが配車計画を更新しています
            </Typography>
            <Typography fontWeight={700}>
              最大 {formatTime(props.queingCount || 0 * 28)}{" "}
              かかる場合があります。
            </Typography>
            <Typography
              sx={{
                marginTop: 1,
              }}
              fontWeight={700}
            >
              完了までしばらくお待ちください。
            </Typography>
            <Button
              variant="contained"
              onClick={props.closeAlert}
              sx={{
                paddingX: 4,
                marginTop: 3,
              }}
            >
              はい
            </Button>
          </Box>
        </AlertDialog>
      )}
    </>
  );
};
