import { useRef, useState, useEffect } from "react";
import { debugLog } from "utils/log";

/**
 * チェックシートのテンプレートを作成するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @fires Container#onSubmit 送信時
 * @fires Container#onError エラー時
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  open = false,
  onClose = () => {},
  title = "新しいチェックシートのテンプレートを登録",
  value,
  loading = false,
  onSubmit = (data) => {
    debugLog("チェックシートテンプレートフォームの送信：", data);
  },
  onError = (errors) => {
    debugLog("チェックシートテンプレートフォームのエラー：", errors);
  },
  mode = "register",
  confirmButtonText = "登録",
  ...props
}) => {
  const form1Ref = useRef(null);
  const form2Ref = useRef(null);
  const [step, setStep] = useState("step1");
  const [form1Value, setForm1Value] = useState(null);
  const [form2Value, setForm2Value] = useState(null);

  useEffect(() => {
    if (open) {
      setStep("step1");

      const {
        category = {
          id: "vehiclePeriodicInspection",
          name: "車輛定期点検",
        },
        name = null,
        description = null,
        remarks = null,
        sections = [],
      } = value ?? {};

      setForm1Value({
        category: category,
        name: name,
        description: description,
        remarks: remarks,
      });

      setForm2Value({
        sections: sections?.map((section) => ({
          ...section,
          order: undefined
        })),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(value ?? ""), open]);

  const handleRegister = () => {
    form2Ref.current.submit();
  };

  const handleSubmitStep1Form = (data) => {
    setForm1Value(data);
    setStep("step2");
  };

  const handleSubmitStep2Form = (data) => {
    setForm2Value(data);
    onSubmit({
      ...form1Value,
      ...data,
    });
  };

  const handleNext = () => {
    form1Ref.current.submit();
  };

  const handlePrevious = () => {
    setForm2Value(form2Ref.current.get());
    setStep("step1");
  };

  return render({
    ...props,
    open: open,
    onClose: onClose,
    loading: loading,
    form1Ref: form1Ref,
    form2Ref: form2Ref,
    step: step,
    form1Value: form1Value,
    form2Value: form2Value,
    title: title,
    onSubmitStep1Form: handleSubmitStep1Form,
    onSubmitStep2Form: handleSubmitStep2Form,
    onNext: handleNext,
    onPrevious: handlePrevious,
    onRegister: handleRegister,
    mode: mode,
    confirmButtonText: confirmButtonText,
  });
};
