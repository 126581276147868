import { stylesCreator } from "./styles";

export const Container = ({
  render,
  open = false,
  title,
  onClose,
  children,
  showAppbar = true,
  avatarLabel,
}) => {
  const classes = stylesCreator();

  return render({
    classes: classes,
    open: open,
    onClose: onClose,
    avatarLabel: avatarLabel,
    title: title,
    showAppbar: showAppbar,
    children: children,
  });
};
