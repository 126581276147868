import { useReducer } from "react";
import { ConsoleLogger as Logger } from "@aws-amplify/core";
import {
  sendUserAttributeVerificationCode,
  confirmUserAttribute,
} from "aws-amplify/auth";
import { useDispatch, useSelector } from "react-redux";
import { AUTHSTATES, selector, set } from "ducks/Auth";
import { debugLog } from "utils/log";

const initialState = {
  loading: false,
  delivery: false,
  error: null,
};

const actions = {
  VERIFY_SUCCEECED: "VerifyContact/verify/succeeded",
  VERIFY_FAILED: "VerifyContact/verify/failed",
  SUBMIT_BEGIN: "VerifyContact/submit/begin",
  SUBMIT_SUCCEEDED: "VerifyContact/submit/succeeded",
  SUBMIT_FAILED: "VerifyContact/submit/failed",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.VERIFY_SUCCEECED:
      return {
        ...state,
        delivery: true,
      };
    case actions.VERIFY_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case actions.SUBMIT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case actions.SUBMIT_SUCCEEDED:
      return {
        ...state,
        delivery: false,
        loading: false,
      };
    case actions.SUBMIT_FAILED:
      return {
        ...state,
        loading: false,
        locked: action.payload?.locked ?? false,
        error: action.payload.error,
      };
    default:
      break;
  }
};

const logger = new Logger("VerifyContact");

/**
 * 有効な認証状態
 */
const VALID_AUTO_STATES = [AUTHSTATES.CONFIRM_VERIFY_USER];

/**
 * 連絡先確認画面を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { authState } = useSelector(selector);
  const appDispatch = useDispatch();

  const verify = () => {
    // 電話番号を確認に使用しないのでメール固定
    sendUserAttributeVerificationCode({ userAttributeKey: "email" })
      .then((data) => {
        logger.debug(data);
        dispatch({
          type: actions.VERIFY_SUCCEECED,
        });
      })
      .catch((err) => {
        logger.debug(err);
        dispatch({
          type: actions.VERIFY_FAILED,
          payload: "エラーが発生しました。",
        });
      });
  };

  const onStateChange = (state) => {
    appDispatch(set(state));
  };

  const submit = (code) => {
    dispatch({ type: actions.SUBMIT_BEGIN });
    // 電話番号を確認に使用しないのでメール固定
    confirmUserAttribute({ userAttributeKey: "email", confirmationCode: code })
      .then((data) => {
        logger.debug(data);
        dispatch({
          type: actions.SUBMIT_SUCCEEDED,
        });
        onStateChange(AUTHSTATES.SIGN_IN);
      })
      .catch((err) => {
        switch (err.code) {
          case "CodeMismatchException":
            dispatch({
              type: actions.SUBMIT_FAILED,
              payload: {
                error: "入力されたコードに誤りがあります。",
              },
            });
            break;
          case "LimitExceededException":
            dispatch({
              type: actions.SUBMIT_FAILED,
              payload: {
                error: "入力規定回数を超えました。",
              },
            });
            break;
          case "ExpiredCodeException":
            dispatch({
              type: actions.SUBMIT_FAILED,
              payload: {
                error: "入力されたコードの有効期限が切れています。",
                delivery: false,
              },
            });
            break;
          default:
            logger.debug(err);
            dispatch({
              type: actions.SUBMIT_FAILED,
              payload: {
                error: "エラーが発生しました。",
              },
            });
            break;
        }
      });
  };

  return render({
    onTransiteLogin: () => onStateChange(AUTHSTATES.SIGN_IN),
    onVerify: verify,
    onSubmit: submit,
    onError: debugLog,
    delivery: state.delivery,
    loading: state.loading,
    isValid: VALID_AUTO_STATES.includes(authState),
    ...props,
  });
};
