import {
  List,
  Paper,
  Stack,
  Box,
  Button,
  ListSubheader,
  Typography,
  Divider,
  Avatar,
  InputAdornment,
  TextField,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { Item } from "./Item";
import { Droppable, Draggable } from "react-beautiful-dnd";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  ...(isDragging && {
    background: "rgb(235,235,235)",
  }),
});

/**
 * チェックシートのグループを表示・入力するコンポーネントです。
 * @param {object} props プロパティ
 * @param {integer} props.number 数字
 * @param {array} props.value 値
 * @fires Group#onChange 変更時
 * @param {boolean} props.disabled 無効設定
 * @param {object} props.errors エラーオブジェクト
 * @returns {JSX.Element}
 */
export const Group = ({
  id,
  number = 1,
  value = null,
  onChange = () => {},
  onDelete = () => {},
  disabled = false,
  readOnly = false,
  errors,
}) => {
  const handleTitleChange = (event) => {
    onChange({
      ...value,
      name: event.target.value,
    });
  };

  const handleItemDelete = (index) => () => {
    if (!value?.items?.length || value?.items?.length <= 1) {
      return;
    }

    const result = [...(value?.items ?? [])];
    result.splice(index, 1);
    onChange({
      ...value,
      items: result,
    });
  };

  const handleItemChange = (index) => (newValue) => {
    const result = [...(value?.items ?? [])];
    result.splice(index, 1, newValue);
    onChange({
      ...value,
      items: result,
    });
  };

  const handleAdd = () => {
    const result = [...(value?.items ?? []), { name: null }];
    onChange({
      ...value,
      items: result,
    });
  };

  return (
    <Draggable
      key={number}
      draggableId={`${id}`}
      index={number - 1}
      isDragDisabled={disabled || readOnly}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <Paper>
            <Stack>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  m={2}
                  sx={{
                    width: "100%",
                  }}
                >
                  {readOnly === false && (
                    <Box>
                      <div {...provided.dragHandleProps}>
                        <DragIndicatorIcon
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        />
                      </div>
                    </Box>
                  )}
                  <Box>
                    <Avatar
                      sx={{
                        width: "32px",
                        height: "32px",
                        backgroundColor: "black",
                      }}
                    >
                      {number}
                    </Avatar>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    {readOnly === false && (
                      <TextField
                        value={value?.name ?? ""}
                        fullWidth
                        onChange={handleTitleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CreateOutlinedIcon />
                            </InputAdornment>
                          ),
                          disableUnderline: true,
                        }}
                        variant="standard"
                        placeholder="ここにチェック内容を入力します。"
                        error={!!errors}
                        helperText={errors?.name?.message}
                        disabled={disabled}
                      />
                    )}
                    {readOnly === true && (
                      <Typography
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        {value?.name}
                      </Typography>
                    )}
                  </Box>
                </Stack>
                {readOnly === false && (
                  <Box display="flex" alignItems="center">
                    <IconButton
                      edge="end"
                      aria-label="clear"
                      onClick={onDelete}
                      sx={{
                        mr: 3,
                      }}
                      disabled={disabled}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                )}
              </Stack>
              {readOnly === false && <Divider />}
              <List
                component={"div"}
                subheader={
                  readOnly === false ? (
                    <ListSubheader id="subheader" component={"div"}>
                      <Stack spacing={2} direction="row" pt={2} pb={2}>
                        <Box
                          sx={{
                            width: "34px",
                          }}
                        ></Box>
                        <Typography pr={3}>{"No"}</Typography>
                        <Typography>{"チェック内容"}</Typography>
                        <FormHelperText error={!!errors?.items?.message}>
                          {errors?.items?.message}
                        </FormHelperText>
                      </Stack>
                    </ListSubheader>
                  ) : (
                    <></>
                  )
                }
              >
                <Droppable droppableId={`${id}-items`} type={`${number - 1}`}>
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {value?.items?.map((item, index) => (
                        <React.Fragment key={index}>
                          <Divider />
                          <Item
                            id={`${id}-items${index}`}
                            value={item}
                            number={index + 1}
                            onChange={handleItemChange(index)}
                            onDelete={handleItemDelete(index)}
                            error={errors?.items?.[index]}
                            helperText={
                              errors?.items?.[index]?.message ||
                              errors?.items?.message
                            }
                            disabled={disabled}
                            readOnly={readOnly}
                          />
                        </React.Fragment>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </List>
              {readOnly === false && (
                <Box
                  display={"flex"}
                  flexDirection="row"
                  justifyContent={"center"}
                  p={3}
                >
                  <Button
                    variant="contained"
                    onClick={handleAdd}
                    disabled={disabled}
                  >
                    {"新しい行を追加"}
                  </Button>
                </Box>
              )}
            </Stack>
          </Paper>
        </div>
      )}
    </Draggable>
  );
};
