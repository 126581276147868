import { Box, ClickAwayListener, TextField } from "@mui/material";
import React from "react";

/**
 * コース名を表示・入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  if (props.edit) {
    return (
      <ClickAwayListener onClickAway={props.handleClickAway}>
        <TextField
          value={props.name}
          onChange={props.onChangeName}
          variant="outlined"
          color="primary"
          size={props.size || "medium"}
          fullWidth
          onMouseDown={props.handleMouseDown}
          onKeyDown={props.handleKeyDown}
          autoFocus
        />
      </ClickAwayListener>
    );
  } else {
    return (
      <Box onClick={props.onEdit}>{props.name || "新しい名前を設定する"}</Box>
    );
  }
};
