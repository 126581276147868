import { Button, DialogContentText, Paper, Stack } from "@mui/material";
import { blue } from "@mui/material/colors";
import { formatDisplayAddress, formatDisplayDate } from "utils/format";
import { InformationItem, Mark } from "views/atoms";
import { ConfirmDialog, FileGallery, FullScreenDialog } from "views/molecules";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { Caption } from "views/organisms/DetailsFrame/ChildComponents";
import { LicenseIndustrialWasteForm } from "views/organisms/Masters/License";

/**
 * 産業廃棄物許可証を詳しく表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  value,
  onOpenDialog,
  onCloseDialog,
  onSubmit,
  isSubmit,
  open,
  selectedPDF,
  onClickPDF,
  onClosePDF,
  onDelete,
  isOpenDeleteDialog,
  toggleDeleteDialog,
}) => {
  return (
    <>
      <DetailsFrame
        caption={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "16px",
            }}
          >
            <div>
              <Mark text="産業廃棄物許可証" backgroundColor={blue[500]} />
              <Caption title={value?.number} />
            </div>
          </div>
        }
        actions={
          <Stack direction={"row"} spacing={2}>
            <Button
              variant="contained"
              color="error"
              onClick={() => toggleDeleteDialog(true)}
              disabled={isSubmit || !value}
            >
              削除する
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onOpenDialog}
              disabled={isSubmit || !value}
            >
              許可証を編集する
            </Button>
          </Stack>
        }
      >
        <Paper sx={{ p: 2 }}>
          <InformationItem label="許可番号">{value?.number}</InformationItem>
          <InformationItem label="許可主体">{`${
            value?.japaneseLocalGovernment?.prefectures?.name ?? ""
          }${value?.japaneseLocalGovernment?.cityName ?? ""}`}</InformationItem>
          <InformationItem label="業の区分">
            {value?.category?.name}
          </InformationItem>
          <InformationItem label="許可の年月日">
            {formatDisplayDate({ source: value?.licensedOn })}
          </InformationItem>
          <InformationItem label="許可の有効年月日">
            {formatDisplayDate({ source: value?.licenseExpirationOn })}
          </InformationItem>
          <InformationItem label="優良認定日">
            {formatDisplayDate({ source: value?.certifiedAsExcellentOn })}
          </InformationItem>
          <InformationItem label="会社名">{value?.companyName}</InformationItem>
          <InformationItem label="代表者名">
            {value?.representativeName}
          </InformationItem>
          <InformationItem label="住所">
            {formatDisplayAddress(value)}
          </InformationItem>
          <InformationItem label="備考">{value?.remarks}</InformationItem>
          <InformationItem label="添付ファイル">
            <FileGallery value={value?.files} disabled={true} />
          </InformationItem>
        </Paper>
        <FullScreenDialog
          open={open}
          formId="industrial-waste-license-form"
          title="許可証の編集"
          textConfirm="保存"
          onClose={onCloseDialog}
          isSubmit={isSubmit}
          disabled={isSubmit}
        >
          <LicenseIndustrialWasteForm
            id="industrial-waste-license-form"
            onSubmit={onSubmit}
            value={value}
          />
        </FullScreenDialog>
        <ConfirmDialog
          title="削除します。よろしいですか？"
          message={
            <DialogContentText>
              削除されたデータを元に戻すことはできません。
            </DialogContentText>
          }
          open={isOpenDeleteDialog}
          positiveText={"削除"}
          negativeText={"キャンセル"}
          onPositive={onDelete}
          onNegative={() => toggleDeleteDialog(false)}
        />
      </DetailsFrame>
    </>
  );
};
