import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useStyles } from "./styles";

/**
 * ファイルサイズのフォーマットを行います。
 * @param {number} bytes ファイルサイズ
 * @param {number} decimals
 * @returns {string}
 */
export const format = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return "0 Bytes";
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

/**
 * Fileオブジェクトをbase64に変換します。
 * @param {object} file ファイルオブジェクト
 */
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * ファイルアップローダーを表示するコンテナコンポーネントです
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string} label ラベル
 * @param {string | array string} accept 受け入れる拡張子
 * @param {number} maxSize ファイル最大サイズbyte
 * @param {boolean} multiple 複数ファイル受け入れるか
 * @fires Container#onChange ファイルがドロップしたときの処理
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  label,
  accept,
  maxSize = Infinity,
  multiple = true,
  onChange,
  ...props
}) => {
  const classes = useStyles();
  const [error, setError] = useState(null);

  const handleDropAccepted = useCallback(
    (files) => {
      for (const file of files) {
        toBase64(file).then((result) => {
          onChange({
            name: file?.name,
            extensions: file?.name.split(".")[1],
            size: format(file?.size, 2),
            context: result,
          });
        });
      }
    },
    [onChange]
  );

  const handleDropRejected = useCallback(
    (rejections) => {
      rejections.forEach((rejection) => {
        for (const err of rejection.errors) {
          if (err.code === "file-invalid-type") {
            setError(`ファイル形式に誤りがあります。`);
          } else if (err.code === "file-too-large") {
            setError(
              `ファイルサイズの上限(${format(
                maxSize
              )})を超えたため、アップロードできませんでした。`
            );
          }
        }
      });
    },
    [maxSize]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept,
    maxSize: maxSize,
    multiple: multiple,
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejected,
  });

  const getSubtitle = () => {
    let result = "";
    if (maxSize !== Infinity) {
      result = `最大ファイルサイズ：${format(maxSize)}  `;
    }
    if (accept) {
      if (Array.isArray(accept)) {
        result += `ファイル形式：${accept
          .map((item) => item.replace(".", ""))
          .join(",")}`;
      } else {
        result += `ファイル形式：${accept.replace(".", "")}`;
      }
    } else {
      result += `ファイル形式：なし`;
    }

    return result;
  };

  return render({
    label: label,
    classes: classes,
    error: error,
    helperText: error,
    getRootProps: getRootProps,
    getInputProps: getInputProps,
    getSubtitle: getSubtitle,
    ...props,
  });
};
