import React, { useState, useRef, useEffect, useMemo } from "react";
import { Box, Typography, Button, Skeleton } from "@mui/material";
import { WarningOutlined } from "@mui/icons-material";
import _ from "lodash";

import { findArrayDifferences } from "views/organisms/Allocation/utils";
import { usePreviousValue } from "utils/hooks";

import {
  GoogleMapApi,
  Direction,
  Marker,
} from "views/organisms/GoogleMapForWeb";

const MapBox = ({
  course,
  setIsMapRenderAvailable,
  isPointLoading,
  activeCollectionPointId,
  setRenderDirection,
  renderDirection,
  isMapRenderAvailable,
  onMarkerClick,
}) => {
  const [mapCenter, setMapCenter] = useState({
    lat: 35.681236,
    lng: 139.767125,
    zoom: 6,
  });
  const [movingPoint, setMovingPoint] = useState({
    id: null,
  });

  const handleMarkerClick = ({ id, lat, lng }) => {
    setMapCenter({
      lat,
      lng,
      zoom: 16,
    });
    onMarkerClick(id);
  };

  const mapRef = useRef(null);

  const flatedPoints = useMemo(() => {
    if (course?.points?.length) {
      return (
        course?.points
          .map(({ assignedRegularlyWasteCollectionCycles }, index) => {
            return assignedRegularlyWasteCollectionCycles.map((value) => ({
              ...value,
              order: value.order || index + 1,
            }));
          })
          .flat() || []
      );
    }
    return [];
  }, [course?.points]);

  /* The `markerPoints` variable is using the `useMemo` hook to memoize the result of a function. */
  const markerPoints = useMemo(() => {
    if (!flatedPoints || flatedPoints.length === 0) {
      return [];
    }
    const updatedPoints = flatedPoints
      .map((v) => {
        const position = v.wasteCollectionWorkplace?.position;
        const lat = position ? position.lat : null;
        const lng = position ? position.lng : null;

        if (lat && lng) {
          return {
            id: v.cycleId,
            lat,
            lng,
          };
        }
        return null;
      })
      .filter((item) => item);

    const splitPoints = [];
    for (let i = 0; i < updatedPoints.length; i += 24) {
      const subarray = updatedPoints.slice(i, i + 24);
      if (i + 24 < updatedPoints.length) {
        subarray.push(updatedPoints[i + 24]);
      }
      splitPoints.push(subarray);
    }
    return splitPoints;
  }, [flatedPoints]);

  const markerPositions = useMemo(() => {
    return _.unionBy(markerPoints.flat(), (v) => v.id).map((item) => {
      return {
        ...item,
        label: flatedPoints.find((v) => v.cycleId === item.id).order,
      };
    });
  }, [markerPoints, flatedPoints]);

  const prevItem = usePreviousValue(markerPositions);

  useEffect(() => {
    if (prevItem && prevItem !== markerPositions) {
      const diff = findArrayDifferences(markerPositions, prevItem);
      if (diff.length === 1 && diff[0].type === "add") {
        if (diff[0].item.lat && diff[0].item.lng) {
          setMapCenter({
            lat: diff[0].item.lat,
            lng: diff[0].item.lng,
            zoom: 12,
          });
        }
        setMovingPoint({
          id: diff[0].item.id,
        });
        setIsMapRenderAvailable(true);
      }
    }
  }, [markerPositions, prevItem, course, setIsMapRenderAvailable]);

  const handleRenderDirection = () => {
    setRenderDirection(true);
    setIsMapRenderAvailable(false);
  };

  return (
    <Box
      style={{
        width: "calc(100% - 650px)",
      }}
    >
      <Box
        py={1}
        px={2}
        height="52px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          color="secondary"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!markerPoints.length && !isPointLoading && (
            <>
              <WarningOutlined
                fontSize="small"
                color="secondary"
                sx={{ mr: 1 }}
              />
              表示可能なルートがありません
            </>
          )}
        </Typography>
        <Button
          color="primary"
          variant="outlined"
          disabled={markerPoints?.flat().length < 2 || !isMapRenderAvailable}
          onClick={handleRenderDirection}
        >
          ルート確認
        </Button>
      </Box>
      <Box
        style={{
          height: "calc(100vh - 117px)",
          padding: "5px",
          paddingTop: "0",
          borderRadius: "10px",
        }}
      >
        {markerPoints ? (
          <GoogleMapApi center={mapCenter} mapRef={mapRef}>
            {markerPositions.length &&
              _.uniqBy(markerPositions, "label").map((marker, index) => {
                return (
                  <React.Fragment key={marker.id}>
                    <Marker
                      noOfMarkers={markerPositions.length}
                      onClickMarker={handleMarkerClick}
                      index={index}
                      marker={marker}
                      animate={
                        marker.id === activeCollectionPointId ||
                        marker.id === movingPoint?.id
                      }
                      isActive={marker.id === activeCollectionPointId}
                    ></Marker>
                  </React.Fragment>
                );
              })}

            {renderDirection &&
              !isPointLoading &&
              markerPoints.map((points, index) => (
                <React.Fragment key={index}>
                  <Direction points={points} />
                </React.Fragment>
              ))}
          </GoogleMapApi>
        ) : (
          <Skeleton
            variant="rectangular"
            sx={{
              borderRadius: 1,
            }}
            height="100%"
            animation="wave"
          />
        )}
      </Box>
    </Box>
  );
};

export default MapBox;
