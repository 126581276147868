import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Slide,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { DatePicker, TextField } from "views/atoms";
import { FileGallery, Uploader } from "views/molecules";
import { basicSchema } from "./Validation";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * チェック内容を入力するコンポーネントです。
 * @param {object} props プロパティ
 * @param {number} index インデックス
 * @param {string} name 名前
 * @param {array} options オプション
 * @param {array} value 値
 * @fires Inspection#onChange 変更時
 * @returns {JSX.Element}
 */
const Inspection = ({ value, onChange = () => {}, disabled }) => {
  const handleChange = (item, index) => () => {
    let result = [...(value?.items ?? [])];
    result.splice(index, 1, {
      ...item,
      checked: !item.checked,
    });
    onChange({
      ...value,
      items: result,
    });
  };

  return (
    <Paper
      sx={{
        p: 3,
      }}
    >
      <Box
        display="flex"
        flexDirection={"row"}
        sx={{
          p: 1,
        }}
      >
        <Box
          sx={{
            pr: 5,
          }}
        >
          <Avatar
            sx={{
              backgroundColor: "black",
            }}
          >
            {value?.order + 1}
          </Avatar>
        </Box>
        <Box>{value?.name}</Box>
      </Box>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
        }}
      >
        {value?.items?.map((item, itemIndex) => {
          return (
            <React.Fragment key={itemIndex}>
              <Divider />
              <ListItem
                disabled={disabled}
                secondaryAction={
                  <Checkbox edge="end" checked={item?.checked} />
                }
                onClick={handleChange(item, itemIndex)}
              >
                <ListItemButton>
                  <Box display="flex" flexDirection={"row"}>
                    <Box
                      sx={{
                        pr: 5,
                      }}
                    >
                      {item.order + 1}
                    </Box>
                    <Box>
                      <ListItemText id={item.order} primary={item.name} />
                    </Box>
                  </Box>
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>
    </Paper>
  );
};

/**
 * チェック内容を入力するコンポーネントです。
 * @param {object} props プロパティ
 * @param {array} options オプション
 * @param {array} value 値
 * @fires Inspection#onChange 変更時
 * @returns {JSX.Element}
 */
const Inspections = ({ value = [], onChange = () => {}, disabled }) => {
  const handleChange = (index) => (v) => {
    let result = [...value];
    result.splice(index, 1, v);
    onChange(result);
  };

  return (
    <>
      {value?.map((section, index) => {
        return (
          <Inspection
            value={section}
            key={index}
            onChange={handleChange(index)}
            disabled={disabled}
          />
        );
      })}
    </>
  );
};

const defaultValues = {
  personInChargeName: null,
  checkedOn: null,
  remarks: null,
  files: [],
  sections: [],
};

/**
 * 車輛点検を入力するフォームコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} value 値
 * @param {string} title タイトル
 * @param {boolean} open 開閉状態
 * @param {boolean} disabled 無効設定
 * @fires VehicleInspectionForm#onSubmit 送信時
 * @fires VehicleInspectionForm#onClose 閉じる時
 * @fires VehicleInspectionForm#onError エラー時
 * @returns {JSX.Element}
 */
export const VehicleInspectionEditForm = ({
  value,
  title = "",
  open = false,
  onClose = () => {},
  disabled = false,
  onSubmit = () => {},
  onError = () => {},
}) => {
  const { trigger, reset, watch, formState, control } = useForm({
    mode: "onBlur",
    resolver: yupResolver(basicSchema),
    defaultValues: {
      ...defaultValues,
      ...value,
    },
  });

  useEffect(() => {
    if (open) {
      reset({
        ...defaultValues,
        personInChargeName: value?.personInChargeName,
        checkedOn: DateTime.fromISO(value.checkedOn).toJSDate(),
        ...value,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, open, JSON.stringify(value)]);

  const handleClickUpdate = async () => {
    const result = await trigger();
    if (result) {
      onSubmit &&
        onSubmit({
          id: value?.id,
          ...watch(),
        });
    } else {
      onError && onError(formState.errors);
    }
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="subtitle1"
              component="div"
            >
              {title}
            </Typography>
            <Button
              variant="outlined"
              autoFocus
              color="inherit"
              onClick={handleClickUpdate}
              disabled={disabled}
            >
              {"保存"}
            </Button>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <Stack>
          <Stack
            sx={{
              p: 2,
            }}
            spacing={2}
          >
            <Controller
              name={`personInChargeName`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  label="点検者"
                  required={true}
                  placeholder="点検者を入力します。"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  disabled={disabled}
                />
              )}
            />
            <Controller
              name={`checkedOn`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <DatePicker
                  label="点検日"
                  required={true}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  disabled={disabled}
                />
              )}
            />
            <Controller
              name={`remarks`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  label="備考"
                  required={false}
                  placeholder="特記事項があれば入力します。"
                  value={value}
                  onChange={onChange}
                  error={error}
                  rows={3}
                  multiline={true}
                  disabled={disabled}
                  fullWidth
                />
              )}
            />
            <Controller
              name="files"
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  <Uploader
                    disabled={disabled}
                    fullWidth={true}
                    maxSize={26214400}
                    onChange={(files) => {
                      onChange([...(value ?? []), ...files]);
                    }}
                    dropzoneText="最大ファイルサイズ:25MB、ファイル形式:JPEG、PDF"
                    accept={[".pdf", ".jpg", ".jpeg"]}
                  />
                  <FileGallery
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                  />
                </>
              )}
            />
          </Stack>
        </Stack>
        <Stack spacing={2}>
          <Controller
            name={`sections`}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Inspections
                value={value}
                onChange={onChange}
                disabled={disabled}
              />
            )}
          />
        </Stack>
        <Box
          sx={(theme) => ({
            p: 2,
            display: "none",
            [theme.breakpoints.down("sm")]: {
              display: "block",
            },
          })}
        >
          <Button
            fullWidth
            variant="contained"
            onClick={handleClickUpdate}
            disabled={disabled}
          >
            {"保存"}
          </Button>
        </Box>
      </Dialog>
    </>
  );
};
