import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { Close, MoreVert } from "@mui/icons-material";
import React from "react";

/**
 * コースのメニューを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <>
    <IconButton
      aria-describedby={props.open ? "transitions-popper" : undefined}
      size="small"
      onClick={props.onToggle}
    >
      <MoreVert />
    </IconButton>
    <Popper
      placement="bottom-end"
      id={props.open ? "transitions-popper" : undefined}
      open={props.open}
      anchorEl={props.anchorEl}
      transition
      className={props.classes.box_menu_popper}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper elevation={3}>
            <Box
              className={props.classes.box__Title}
              borderBottom={1}
              borderColor="grey.500"
            >
              {props.title}
              <IconButton onClick={props.onCloseMenu} size="small">
                <Close />
              </IconButton>
            </Box>
            <ClickAwayListener onClickAway={props.onCloseMenu}>
              <MenuList
                autoFocusItem={props.open}
                id="menu-list-grow"
                onKeyDown={props.onListKeyDown}
              >
                {props.menuList &&
                  props.menuList.map((item) => {
                    return (
                      <MenuItem
                        key={item.id}
                        onClick={() => props.onMenuItemClick(item.id)}
                        disabled={item.disabled}
                      >
                        {item.title}
                      </MenuItem>
                    );
                  })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </>
);
