import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import {
  Box,
  Button,
  Chip,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

const calcProgressPercent = (current = 0.0, total = 0.0) => {
  if (total === 0.0) {
    return 0;
  }

  return ((current ?? 0.0) / (total ?? 0.0)) * 100.0;
};

/**
 * 選択項目を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {string} props.key キー
 * @param {boolean} props.disabled 無効設定
 * @fires Item#onResume 再開するボタン
 * @returns {JSX.Element}
 */
export const Item = ({ value, disabled = false, onResume = () => {} }) => {
  return (
    <Paper
      sx={{
        p: 2,
      }}
      key={value.id}
    >
      <Stack spacing={2}>
        <Typography
          sx={{
            fontWeight: "bold",
          }}
          variant="subtitle1"
        >
          {value?.name ?? ""}
        </Typography>
        <Stack
          direction={"row"}
          spacing={2}
          sx={{
            overflowX: "scroll",
          }}
        >
          {value?.assignedUsers?.map((user) => (
            <Chip
              key={user.id}
              icon={<PermIdentityIcon />}
              label={user?.name ?? ""}
              color="success"
            />
          ))}
          {!!value?.assignedVehicle && (
            <Chip
              icon={<LocalShippingOutlinedIcon />}
              label={value?.assignedVehicle?.number ?? ""}
              color="primary"
            />
          )}
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Stack
            direction={"row"}
            alignItems="center"
            spacing={2}
            sx={{
              width: "80vw",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <LinearProgress
                variant="determinate"
                value={
                  value?.taskTotal !== 0.0
                    ? calcProgressPercent(
                        value?.taskDoneCount,
                        value?.taskTotal
                      )
                    : 100
                }
              />
            </Box>
            <Typography
              sx={{
                color: "gray",
              }}
            >
              {`${
                value?.taskTotal !== 0.0
                  ? calcProgressPercent(
                      value?.taskDoneCount,
                      value?.taskTotal
                    ).toFixed()
                  : 100
              }%`}
            </Typography>
          </Stack>
          <Typography
            sx={{
              color: "gray",
            }}
          >
            {`${value?.taskDoneCount ?? 0}/${value?.taskTotal ?? 0}`}
          </Typography>
        </Stack>
        <Button disabled={disabled} variant="outlined" onClick={onResume}>
          {value?.taskDoneCount === 0 ? "回収を開始する" : "回収を再開する"}
        </Button>
      </Stack>
    </Paper>
  );
};
