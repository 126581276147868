import reducer from "./slice";
import {
  selector,
  transportMethodsSelector,
  isLoadingSelector,
  errorSelector,
} from "./slice";
import { fetchTransportMethods } from "./api";

export default reducer;

export {
  transportMethodsSelector,
  selector,
  isLoadingSelector,
  errorSelector,
  fetchTransportMethods,
};
