import { useSignOut } from "utils/useSignOut";
import { userSelector } from "ducks/User";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useOpener } from "utils/useOpener";

export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const menu = useOpener();
  const { signOut } = useSignOut();
  const handleClickMenu = (url) => {
    navigate(url);
    menu.toggle(false);
  };

  const handleSignout = () => {
    navigate(
      {
        pathname: "/",
      },
      {
        replace: true,
      }
    );
    signOut();
  };

  return render({
    ...props,
    menu: menu,
    onClickMenu: handleClickMenu,
    user: user,
    signOut: handleSignout,
  });
};
