import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

/**
 * 処分方法を選択するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) =>
  !props.isLoading ? (
    <FormControl
      sx={{ minWidth: 200 }}
      disabled={props.disabled}
      error={props.error}
    >
      <InputLabel htmlFor="grouped-select">{props.label}</InputLabel>
      <Select
        id="grouped-select"
        value={props.value ?? ""}
        label={props.label}
        onChange={props.onChange}
        multiple={props.multiple}
      >
        <MenuItem value="">
          <em>未選択</em>
        </MenuItem>
        {Object.keys(props.disposalMethods).map((key) => {
          return [
            <ListSubheader>{key}</ListSubheader>,
            props.disposalMethods[key]?.map((item, index) => {
              return (
                <MenuItem value={item.code} key={item.code}>
                  {item.smallClassName ||
                    item.middleClassName ||
                    item.largeClassName}
                </MenuItem>
              );
            }),
          ];
        })}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  ) : (
    <></>
  );
