import { createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

const initialState = {
  date: DateTime.now().toFormat("yyyy-MM-dd"),
};

const Date = createSlice({
  name: "date",
  initialState,
  reducers: {
    set: (state, action) => {
      state.date = action.payload;
    },
  },
});

export const { set } = Date.actions;

export const selector = (store) => store.Date;

export default Date.reducer;
