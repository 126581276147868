import { yupResolver } from "@hookform/resolvers/yup";
import { modelOfVehiclesSelector } from "ducks/ModelOfVehicles";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { DriversLicenses } from "utils/Context";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { styles } from "./styles";
import { basicSchema } from "./Validations/basic";

const defaultValues = {
  number: "",
  modelOfVehicles: null,
  parkingCertificateOwnerWorkplace: null,
  totalWeight: null,
  loadCapacity: null,
  ridingCapacity: null,
  driversLicense: null,
  purchasedOn: null,
  firstYearRegistrationOn: null,
  remarks: "",
};

/**
 * 新しい車輛を登録するフォームを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({
  render,
  id,
  value,
  companyId,
  onSubmit = (data) => debugLog("VehicleForm.onSubmit", data),
  onError = (err) => debugLog("VehicleForm.onSubmit.error:", err),
  disableSelectOffice = false,
  ...props
}) => {
  const classes = useClasses(styles);
  const [open, setOpen] = useState(false);
  const modelOfVehicles = useSelector(modelOfVehiclesSelector);

  const { reset, setValue, control, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(basicSchema),
  });

  useEffect(() => {
    reset({
      ...defaultValues,
      ...value,
    });
  }, [reset, value]);

  const handleGetCertificationOffice = (e) => {
    setValue("parkingCertificateOwnerWorkplace", e.value[0], { shouldValidate: true });
    setOpen(false);
  };

  return render({
    id: id,
    companyId: companyId,
    classes: classes,
    control: control,
    modelOfVehicles: modelOfVehicles,
    driversLicenses: DriversLicenses,
    open: open,
    onOpenModal: () => setOpen(true),
    onCloseModal: () => setOpen(false),
    getCertificationOffice: handleGetCertificationOffice,
    disableSelectOffice: disableSelectOffice,
    submit: handleSubmit(onSubmit, onError),
    ...props,
  });
};
