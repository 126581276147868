import { useEffect } from "react";
import html from "./Forbidden.htm";

const getContent = (text) => {
  if (!text) {
    return "";
  }

  const domParser = new DOMParser();

  return domParser.parseFromString(text, "text/html").querySelector("body")
    .innerHTML;
};

/**
 * ページのアクセスが禁止だった時に表示するコンポーネント。
 * @returns {JSX.Element}
 */
export const Forbidden = () => {
  useEffect(() => {
    fetch(html)
      .then((response) => response.text())
      .then(
        (text) =>
          (document.querySelector("#inner").innerHTML = getContent(text))
      );
  }, []);

  return <div id="inner"></div>;
};
