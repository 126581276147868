import { add as addAlert } from "ducks/Alert";
import { setVisilityBeginnersMark } from "ducks/GuiderSteps/slice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { styles } from "./styles";

/**
 * 事業者情報画面を表示するコンポーネントです。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({ render, value, onSubmitted, submit, ...props }) => {
  const classes = useClasses(styles);
  const [isSubmit, setIsSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setVisilityBeginnersMark());
  }, [dispatch]);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSubmit = (data) => {
    setIsSubmit(true);
    submit(data)
      .then((res) => {
        onSubmitted && onSubmitted(res);
        handleCloseDialog();
        dispatch(
          addAlert({
            value: "保存しました。",
            severity: "success",
          })
        );
      })
      .catch((err) => {
        debugLog("Corporation.onSubmit.error", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、保存できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  return render({
    classes: classes,
    value: value,
    onSubmit: handleSubmit,
    open: open,
    onCloseDialog: handleCloseDialog,
    onOpenDialog: handleOpenDialog,
    isSubmit: isSubmit,
    ...props,
  });
};
