import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    verticalAlign: "middle",
    padding: "5px 5px 0px 20px",
    justifyContent: "space-between",
  },
  fileName: {
    fontWeight: "bold",
    fontSize: "16px",
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    "& > *": {
      margin: "0px 3px 0px 3px",
    },
  },
  context: {
    backgroundColor: theme.palette.background.paper,
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  title: {
    padding: "5px 5px 0px 20px",
  },
}));
