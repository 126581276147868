import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Paper, Stack } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { withForwardRef } from "utils/HoC";
import { TextField } from "views/atoms";
import { Selector } from "ducks/ChecksheetCategories/Components";
import { basicSchema } from "./Validations";
import { useImperativeHandle, useEffect } from "react";
import { debugLog } from "utils/log";

const defaultValues = {
  category: {
    id: "vehiclePeriodicInspection",
    name: "車輛定期点検",
  },
  name: null,
  description: null,
  remarks: null,
};

export const Step1Form = withForwardRef(
  ({
    value,
    onSubmit = () => {},
    onError = debugLog,
    disabled = false,
    mode,
    _ref,
  }) => {
    const { trigger, getValues, control, formState, reset } = useForm({
      mode: "onBlur",
      resolver: yupResolver(basicSchema),
      defaultValues: {
        ...defaultValues,
        ...value,
      },
    });

    useEffect(() => {
      reset({
        ...defaultValues,
        ...value,
      });
    }, [reset, value]);

    useImperativeHandle(_ref, () => ({
      submit: () => {
        trigger().then((res) => {
          if (res) {
            onSubmit(getValues());
          } else {
            console.log(getValues());
            onError(formState.errors);
          }
        });
      },
      get: () => getValues(),
    }));

    return (
      <Paper
        sx={{
          p: 3,
        }}
      >
        <Stack spacing={2}>
          <Box>
            {mode === "register" && (
              <Controller
                name={`category`}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Selector
                      label="分類"
                      required={true}
                      value={value}
                      onChange={onChange}
                      error={error}
                      disabled={disabled}
                    />
                  );
                }}
              />
            )}
          </Box>
          <Box>
            <Controller
              name={`name`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  label="名称"
                  required={true}
                  placeholder="名称を入力します。"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  disabled={disabled}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={`description`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  label="説明"
                  required={false}
                  placeholder="チェックシートの説明を入力します。"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  disabled={disabled}
                  rows={3}
                  multiline={true}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={`remarks`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  label="備考"
                  required={false}
                  placeholder={
                    "特記事項があれば入力します。\nこの項目はテンプレート利用時には表示されません。"
                  }
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message}
                  disabled={disabled}
                  row={3}
                  multiline={true}
                  fullWidth
                />
              )}
            />
          </Box>
        </Stack>
      </Paper>
    );
  }
);
