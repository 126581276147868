import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import clsx from "clsx";
import React from "react";

/**
 * ポップアップセレクションでデータを選択するコンポーネントです。
 * @param {string} label セレクションのラベル
 * @param {boolean} hideLabel
 * @param {string} placeholder プレースホルダー
 * @param {boolean} required 必須かどうかを設定する
 * @param {object} value 選択されたアイテムです
 * @param {func} onChange 選択するアイテムが更新するイベントです
 * @param {array} options アイテム配列
 * @param {boolean} disabled 無効にする 例）false
 * @param {boolean} multiple 複数選択するかどうかを設定する 例）false
 * @param {string} error エラーが出る
 * @param {string} optionValueField オプションの値のフィールド名 例）"code"
 * @param {string} optionNameField オプションの名前のフィールド名 例）"name"
 * @param {object} sx スタイル
 */
export const Presententer = (props) => {
  const {
    classes,
    label,
    value,
    required,
    placeholder,
    error,
    disabled,
    multiple,
    hideLabel,
    options,
    optionValueField,
    optionNameField,
    onChange,
    sx,
  } = props;

  return (
    <Box sx={sx} display="flex" flexDirection="column" fontSize="14px">
      <div className={clsx(classes.label, hideLabel && classes.hideLabel)}>
        {label}
        {required && <span className={classes.required}>必須</span>}
      </div>
      <FormControl
        fullWidth={props.fullWidth}
        variant="outlined"
        className={classes.style_inputField}
        size="medium"
        error={Boolean(error)}
        disabled={disabled}
      >
        <Select
          value={
            multiple
              ? value?.map((v) => v[optionValueField]) ?? []
              : value?.[optionValueField] ?? ""
          }
          onChange={onChange}
          displayEmpty
          className={clsx(!value && classes.emptyValue, classes.popupMultiple)}
          input={multiple ? <OutlinedInput /> : null}
          multiple={multiple}
          renderValue={(selected) => {
            if (
              (multiple && selected?.length === 0) ||
              (!multiple && !selected)
            ) {
              return (
                <em className={classes.placeholderStyle}>{placeholder}</em>
              );
            }
            return multiple
              ? selected
                  ?.map(
                    (v) =>
                      options.find(
                        (option) => option[optionValueField] === v
                      )?.[optionNameField]
                  )
                  .join("、")
              : options.find(
                  (option) => option[optionValueField] === selected
                )?.[optionNameField];
          }}
        >
          {!multiple && (
            <MenuItem value="" className={classes.placeholder}>
              {placeholder}
            </MenuItem>
          )}
          {options?.map((option, index) => (
            <MenuItem key={index} value={option[optionValueField]}>
              {multiple && (
                <Checkbox
                  checked={
                    value?.some(
                      (v) => option[optionValueField] === v[optionValueField]
                    ) ?? false
                  }
                />
              )}
              {option[optionNameField]}
            </MenuItem>
          ))}
        </Select>
        {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
      </FormControl>
    </Box>
  );
};
