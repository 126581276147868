import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 処理フローの詳細を表示を行うコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 処理フローの情報
 * @param {boolean} props.open ダイアログの表示状態
 * @param {func} props.onClose ダイアログを閉じる関数
 */
export const WasteFlowDetailDialog = containerPresententer(Container, Presententer);
