import LaunchIcon from "@mui/icons-material/Launch";
import { CardActionArea, Stack, Typography } from "@mui/material";
import React from "react";
import { debugLog } from "utils/log";

/**
 * 注意点の項目を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {string} props.value.name 名前
 * @param {MouseEvent} props.onClick クリック時
 * @returns {JSX.Element} JSX.Element
 */
export const ListItem = ({
  value,
  onClick = (data) => debugLog("注意点項目クリック：", data),
}) => {
  return (
    <CardActionArea onClick={() => onClick(value.id)}>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        sx={{
          padding: "4px",
        }}
      >
        <Typography
          style={{
            color: "grey",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {value?.name ?? ""}
        </Typography>
        <span
          style={{
            color: "grey",
          }}
        >
          <LaunchIcon />
        </span>
      </Stack>
    </CardActionArea>
  );
};
