import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * モーダルポップアップを表示するコンポーネントです。
 * @param {boolean} open 開閉状態
 * @param {JSX.Element} children 子要素
 * @param {string} className クラス名
 * @fires ModalPopup#onClose 閉じるとき
 * @returns {JSX.Element}
 */
export const ModalPopup = containerPresententer(Container, Presententer);
