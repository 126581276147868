import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import React from "react";
import { DatePicker } from "views/atoms";

/**
 * 期間を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  label,
  error,
  helperText,
  id,
  value,
  onChange,
  ...props
}) => {
  return (
    <FormControl error={error} {...props}>
      <FormLabel
        sx={{
          fontSize: "14px",
        }}
        component="legend"
      >
        {label}
      </FormLabel>
      <FormGroup
        sx={{
          border: "1px solid #aaa",
          borderColor: error ? "red" : "#aaa",
          padding: "12px",
          borderRadius: "4px",
        }}
        row={true}
      >
        <DatePicker
          value={value?.start}
          onChange={onChange("start")}
          inputVariant="standard"
        />
        <Box mr={1} ml={1} display="flex" alignItems="center">
          ～
        </Box>
        <DatePicker
          value={value?.end}
          onChange={onChange("end")}
          inputVariant="standard"
        />
      </FormGroup>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};
