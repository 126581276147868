import { styles } from "./styles";
import useClasses from "utils/useClasses";

/*
  object shape
    items
*/

/**
 * 未配車の回収スケジュールを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {array} value 未配車の回収スケジュール情報
 * @param {string} droppableId ドロップコンポーネントのid(react-beautiful-dndで使用)
 * @param {string} searchWord 検索ワード(ヒットしたアイテムを表示)
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value = [],
  droppableId = "stillCollects",
  nextDay,
  ...props
}) => {
  const classes = useClasses(styles);
  return render({
    classes: classes,
    value: value,
    droppableId: droppableId,
    nextDay: nextDay,
    ...props,
  });
};
