import { Button } from "@mui/material";
import { stopRegularlyWasteCollectionCycle } from "api/graphql/mutations";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { DateTime } from "luxon";
import { useReducer, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { CycleCategory } from "../CycleCategory";
import { styles } from "./styles";

const initialState = {
  open: false,
  selectedValue: null,
};

const ActionTypes = {
  OPEN: "endate_form/open",
  CLOSE: "endate_form/close",
};

const reducerEndDateFunc = (state, action) => {
  switch (action.type) {
    case ActionTypes.OPEN:
      return {
        ...state,
        open: true,
        selectedValue: action.payload,
      };

    case ActionTypes.CLOSE:
      return {
        ...state,
        open: false,
        selectedValue: null,
      };

    default:
      return state;
  }
};

/*
object shape
  discharges
  transporter
  wastes
  remarks
  cycles
    cycle
    times
      start
      end
    date
      start
      end
    schedules
  collects
*/

const getColumns = (onSpecificEndDate) => {
  return [
    {
      field: "cycle",
      headerName: "回収周期",
      width: 170,
      flex: 1,
      renderCell: (params) => {
        return <CycleCategory value={params.row} />;
      },
    },
    {
      field: "times",
      headerName: "回収時間",
      width: 170,
      flex: 1,
      renderCell: (params) => {
        return `${
          params?.row?.scheduleTimeRangeStart
            ? params?.row?.scheduleTimeRangeStart.substring(0, 5)
            : ""
        } ～ ${
          params?.row?.scheduleTimeRangeEnd
            ? params?.row?.scheduleTimeRangeEnd.substring(0, 5)
            : ""
        }`;
      },
    },
    {
      field: "cycleStartDate",
      headerName: "回収開始日",
      width: 170,
      flex: 1,
      valueFormatter: (params) => {
        return params?.value
          ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd")
          : "";
      },
    },
    {
      field: "cycleEndDate",
      headerName: "回収終了日",
      width: 170,
      flex: 1,
      renderCell: (params) => {
        if (params?.value) {
          const now = DateTime.now();
          const target = DateTime.fromISO(params.value);
          if (now <= target) {
            return (
              <Button
                variant="text"
                onClick={() => onSpecificEndDate(params.row)}
              >
                {target.toFormat("yyyy/MM/dd")}
              </Button>
            );
          } else {
            return target.toFormat("yyyy/MM/dd");
          }
        } else {
          return (
            <Button
              variant="text"
              onClick={() => onSpecificEndDate(params.row)}
            >
              終了日を指定する
            </Button>
          );
        }
      },
    },
    {
      field: "remarks",
      headerName: "備考",
      width: 250,
      flex: 1,
      valueFormatter: (params) => {
        return params.value ? params.value?.split("\n").join(" ") : "";
      },
    },
  ];
};

/**
 * 定期回収詳細を表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @fires Container#onNotificationChange 変更通知時
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onNotificationChange = () => debugLog("RegularlyDetails.notification.change"),
  ...props
}) => {
  const classes = useClasses(styles);
  const dispatch = useDispatch();
  const [refetchWasteOrder, setRefetchWasteOrder] = useState(false);
  const [isAddCycleOpen, setIsAddCycleOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef();
  const [{ open, selectedValue }, endDatedispatch] = useReducer(
    reducerEndDateFunc,
    initialState
  );

  const onSpecificEndDate = (row) => {
    endDatedispatch({
      type: ActionTypes.OPEN,
      payload: row,
    });
  };

  const handleChangeCollects = () => {
    onNotificationChange();
  };

  const exeUpdateEndDate = (data) => {
    return API.graphql(
      graphqlOperation(stopRegularlyWasteCollectionCycle, {
        input: {
          id: selectedValue.id,
          cycleEndDate: DateTime.fromJSDate(new Date(data?.date)).toISODate(),
        },
      })
    );
  };

  const handleConfirmEndDate = (date) => {
    exeUpdateEndDate(date)
      .then((res) => {
        onNotificationChange();
        setRefetchWasteOrder(true);
        endDatedispatch({
          type: ActionTypes.CLOSE,
        });
        dispatch(
          addAlert({
            value: "更新しました。",
            severity: "success",
          })
        );
      })
      .catch((err) => {
        debugLog("RegularlyDetails.update.endate.error", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      });
  };

  const handleCancelEndDate = () => {
    endDatedispatch({
      type: ActionTypes.CLOSE,
    });
  };

  const handleSubmitCycle = () => {
    formRef?.current?.submit();
  };

  const handleSubmitSuccess = () => {
    dispatch(
      addAlert({
        value: "更新しました。",
        severity: "success",
      })
    );
    setIsAddCycleOpen(false);
    setRefetchWasteOrder(true);
    onNotificationChange();
  };

  const handleSubmitError = () => {
    setIsSubmitting(false);
    dispatch(
      addAlert({
        value: "エラーが発生したため、登録できませんでした。",
        severity: "error",
      })
    );
  };

  return render({
    ...props,
    open: open,
    selectedValue: selectedValue,
    classes: classes,
    value: value,
    columns: getColumns(onSpecificEndDate),
    refetchWasteOrder: refetchWasteOrder,
    isAddCycleOpen: isAddCycleOpen,
    isSubmitting: isSubmitting,
    formRef: formRef,
    setRefetchWasteOrder: setRefetchWasteOrder,
    onChangeCollects: handleChangeCollects,
    onConfirmEndDate: handleConfirmEndDate,
    onCancelEndDate: handleCancelEndDate,
    setIsAddCycleOpen: setIsAddCycleOpen,
    handleSubmitCycle: handleSubmitCycle,
    handleSubmitSuccess: handleSubmitSuccess,
    handleSubmitError: handleSubmitError,
  });
};
