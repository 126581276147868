import { yupResolver } from "@hookform/resolvers/yup";
import { Paper, Stack } from "@mui/material";
import { rolesSelector } from "ducks/Roles";
import { useEffect, useImperativeHandle } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { withForwardRef } from "utils/HoC";
import { debugLog } from "utils/log";
import { TextField } from "views/atoms";
import { RoleSelector } from "../RoleSelector";
import { basicSchema } from "./Validations";
import { userSelector } from "ducks/User";

const defaultValues = {
  role: null,
  name: null,
  email: null,
};

/**
 * メンバーを入力するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {boolean} props.disabled 無効設定
 * @param {object} props.customErrors - カスタムエラー
 * @fires Form#onSubmit 送信時
 * @fires Form#onError エラー時
 * @returns {JSX.Element}
 */
export const Form = withForwardRef(
  ({
    value,
    onSubmit = (res) => debugLog(res),
    onError = (err) => debugLog(err),
    disabled = false,
    editMode = false,
    customErrors = null,
    _ref,
  }) => {
    const roles = useSelector(rolesSelector);
    const currentUser = useSelector(userSelector);
    const { control, getValues, trigger, formState, reset } = useForm({
      mode: "onBlur",
      defaultValues: {
        ...defaultValues,
        ...value,
        role: !!value
          ? roles.find((role) => role.name === value?.roleName)
          : null,
        roleName: undefined,
      },
      resolver: yupResolver(basicSchema),
    });

    useEffect(() => {
      reset({
        ...defaultValues,
        ...value,
        role: !!value
          ? roles.find((role) => role.name === value?.roleName)
          : null,
        roleName: undefined,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset]);

    useImperativeHandle(_ref, () => ({
      submit: async () => {
        trigger().then((res) => {
          if (res) {
            const { name, role, email } = getValues();
            onSubmit &&
              onSubmit({
                id: value?.id,
                planSubscriberId: currentUser.planSubscriberId,
                name: name,
                roleId: role?.id,
                email: email,
                expectedVersion: value?.version,
              });
          } else {
            onError && onError(formState.errors);
          }
        });
      },
    }));

    return (
      <Paper
        sx={{
          p: 2,
        }}
      >
        <Stack spacing={2}>
          <Controller
            name={`role`}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <RoleSelector
                  value={value}
                  onChange={onChange}
                  error={error || customErrors?.role}
                  disabled={disabled}
                />
              );
            }}
          />
          <Controller
            name={`name`}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <TextField
                  label="名前"
                  required={editMode === true ? false : true}
                  placeholder="メンバーの名前を入力します。"
                  value={value}
                  onChange={onChange}
                  error={!!error || !!customErrors?.name}
                  helperText={error?.message || customErrors?.name?.message}
                  disabled={disabled}
                  fullWidth
                />
              );
            }}
          />
          <Controller
            name={`email`}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <TextField
                  label="メールアドレス"
                  required={editMode === true ? false : true}
                  placeholder="例) info@ecope-pro.co.jp"
                  value={value}
                  onChange={onChange}
                  error={!!error || !!customErrors?.email}
                  helperText={error?.message || customErrors?.email?.message}
                  disabled={disabled || editMode}
                  fullWidth
                />
              );
            }}
          />
        </Stack>
      </Paper>
    );
  }
);
