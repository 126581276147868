import { FlowRadio } from "../FlowRadio";

/**
 * 処理フローの選択を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    props.options?.map((option, index) => (
      <div key={index}>
        <FlowRadio
          value={props.value}
          option={option}
          onChange={props.onChange}
          onClickDetails={props.onClickDetails}
          display={props.display}
        />
      </div>
    )) ?? <></>
  );
};
