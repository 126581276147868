import { add } from "ducks/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { sleep } from "./sleep";

export const useMutation = (
  MUTATION,
  options,
  failure = false,
  errorValue = "テストモジュール: 失敗した場合",
  returnValue = null
) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    data: null,
    error: null,
    loading: false,
  });

  const mutation = (variables) => {
    options?.onBeforeExecute?.();

    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    if (failure) {
      return sleep(
        2,
        () => Promise.reject(errorValue)
          .catch((err) => {
            options?.errorMessage &&
              dispatch(
                add({
                  value: options?.errorMessage,
                  severity: "error",
                })
              );
            setState((prevState) => ({
              ...prevState,
              error: err,
            }));
            options?.onError?.(err);
          })
          .finally(() => {
            setState((prevState) => ({
              ...prevState,
              loading: false,
            }));
          })
      );
    } else {
      return sleep(
        2,
        () => Promise.resolve(returnValue)
          .then((res) => {
            setState((prevState) => ({
              ...prevState,
              data: res,
            }));
            options?.succeedMessage &&
              dispatch(
                add({
                  value: options?.succeedMessage,
                  severity: "success",
                })
              );
            options?.onCompleted?.(res);
          })
          .finally(() => {
            setState((prevState) => ({
              ...prevState,
              loading: false,
            }));
          })
      );
    }
  };

  return [mutation, { ...state }];
};
