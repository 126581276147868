import { Box } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { RenderEditDateTime, RenderEditRemarks, RenderEditTime } from "./Child";
import { styles } from "./styles";

const columns = [
  {
    field: "emissionBusiness",
    headerName: "排出事業場",
    flex: 1,
  },
  {
    field: "arrivalTime",
    headerName: "到着時間",
    width: 180,
    editable: true,
    type: "dateTime",
    renderEditCell: RenderEditTime,
    // renderCell: renderTime,
    valueFormatter: (params) =>
      DateTime.fromJSDate(params?.value).toLocaleString(
        DateTime.TIME_24_WITH_SECONDS
      ),
  },
  {
    field: "departureTime",
    headerName: "出発時間",
    width: 180,
    editable: true,
    type: "dateTime",
    renderEditCell: RenderEditTime,
    valueFormatter: (params) =>
      DateTime.fromJSDate(params?.value).toLocaleString(
        DateTime.TIME_24_WITH_SECONDS
      ),
  },
  {
    field: "waste",
    headerName: "廃棄物",
    flex: 1,
  },
  {
    field: "quantity",
    headerName: "数量",
    width: 110,
    editable: true,
    type: "number",
    valueFormatter: (params) => {
      return `${params?.value}袋`;
    },
  },
  {
    field: "inputTime",
    headerName: "入力日時",
    width: 180,
    editable: true,
    renderEditCell: RenderEditDateTime,
    type: "dateTime",
  },
];
const defaultValues = {
  collects: [],
};

/**
 * 引数を受けてJSX Elemntを返します。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({
  render,
  value,
  open,
  onCloseDialog,
  getDataSubmit,
  ...props
}) => {
  const classes = styles();

  const methods = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
    //resolver: yupResolver(),
  });
  const { trigger, getValues, reset } = methods;

  const handleSubmit = async () => {
    const result = await trigger();
    if (result) {
      const data = getValues();
      getDataSubmit && getDataSubmit(data);
    }
  };

  useEffect(() => {
    if (value) {
      reset(value);
    }
  }, [reset, value]);

  const RenderNumber = ({ id }) => {
    return (
      <Box sx={{ justifyContent: "center", display: "flex", width: "100%" }}>
        {value?.findIndex((v) => v.id === id) + 1}
      </Box>
    );
  };
  const numberColumn = {
    field: "no",
    headerName: "No",
    width: 100,
    renderCell: RenderNumber,
  };

  columns.unshift(numberColumn);
  columns.push({
    field: "remarks",
    headerName: "備考",
    renderCell: (params) => <Box sx={{ width: "100%" }}>{params?.value}</Box>,
    renderEditCell: RenderEditRemarks,
    editable: true,
    width: 200,
    valueFormatter: (params) => {
      return params.value ? params.value?.split("\n").join(" ") : "";
    },
  });

  return render({
    open: open,
    classes: classes,
    onCloseDialog: onCloseDialog,
    onSubmit: handleSubmit,
    methods: methods,
    rows: value,
    columns: columns,
    ...props,
  });
};
