import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 変更ボータンが付くテキストフィルドが表示するコンポーネントです。
 * @param {string} label テキストのレベル
 * @param {object} classes クラス名
 * @param {boolean} showIcon テキストの頭にアイコンを表示します　デフォルト：true
 * @param {funct} onChange データ更新を処理する
 * @param {boolean} required 必須かどうか設定
 * @param {object} value テキストフィールドのバリュー 例）{id: "", name:"", ...}
 * @param {boolena} error エラー
 * @param {func} onOpenModal モーダルを開くイベント
 * @param {boolean} disabledButtom ボータンを無効にする
 * @param {string} textConfirm ボータンの文字
 */
export const InputFieldPopppSelect = containerPresententer(
  Container,
  Presententer
);
