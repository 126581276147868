import { Box, Button, TextField } from "@mui/material";
import clsx from "clsx";

/**
 * 郵便番号を入力するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  label,
  onChange,
  state,
  classes,
  required = false,
  onSearch,
  onSearchShow = false,
  errors,
  disabled,
  ...props
}) => {
  return (
    <Box display="flex" flexDirection="column" fontSize="14px">
      <div className={classes.label}>
        {label} {required ? <span className={classes.required}>必須</span> : ""}
      </div>
      <TextField
        size="medium"
        variant="outlined"
        value={state}
        onChange={onChange}
        className={clsx(classes.style_inputField, classes.field)}
        required={required}
        error={Boolean(errors)}
        helperText={
          Boolean(errors) ? (
            <span className={classes.error_message}>{errors}</span>
          ) : null
        }
        InputProps={{
          endAdornment: (
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={!onSearchShow || disabled}
              onClick={() => onSearch()}
              className={classes.button_search}
            >
              検索
            </Button>
          ),
        }}
        inputProps={{
          maxLength: 8,
        }}
        disabled={disabled}
        {...props}
      />
    </Box>
  );
};
