/**
 * The function `searchForKeys` recursively searches for specified keys in an object and returns an
 * array of corresponding values.
 * @param obj - The `obj` parameter is an object that you want to search for keys in.
 * @param keys - An array of strings representing the keys to search for in the object.
 * @param [result] - The `result` parameter is an array that stores the values of the keys that are
 * found in the object.
 * @returns The function `searchForKeys` returns an array containing the values of the keys that were
 * found in the object.
 */
export const searchForKeys = (obj, keys, result = []) => {
  for (let key in obj) {
    if (typeof obj[key] === "object") {
      searchForKeys(obj[key], keys, result);
    } else if (keys.includes(key)) {
      result.push(obj[key]);
    }
  }
  return result;
};
