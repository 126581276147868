import React from "react";

/**
 * 回収サイクルを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  if (!props.value || !props.value?.cycleCategoryId) {
    return <></>;
  }
  switch (props.value.cycleCategoryId) {
    case "everyDay":
      return props.value?.cycleCategoryName;
    case "everyWeek":
    case "first":
    case "second":
    case "third":
    case "fourth":
    case "fifth":
      return `${props.value?.cycleCategoryName} ${props.value.weekDays
        .map(
          (weekday) =>
            props.DayOfWeek.find((item) => item.code === weekday).shortName
        )
        .join("・")}`;
    case "everyOtherWeek":
      return `${props.value?.cycleCategoryName} ${props.value.weekDays
        .map(
          (weekday) =>
            props.DayOfWeek.find((item) => item.code === weekday).shortName
        )
        .join("・")} ${props.value.weeks
        .map((week) => props.Week.find((item) => item.code === week).name)
        .join("・")}`;
    case "everyMonth":
      return `${props.value?.cycleCategoryName} ${props.value.weeks
        .map((week) => props.Week.find((item) => item.code === week).name)
        .join("・")}`;
    case "interval":
      return ` ${props.value.intervalDays} 日${props.value.cycleCategoryName}`;
    case "customize":
      return ``;
    default:
      return ``;
  }
};
