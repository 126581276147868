import yup, { dateSchema, textSchema, remarksSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  storageWorkplace: yup.object().required(),
  removedOn: dateSchema.required(),
  storedOn: dateSchema.required(),
  vehicle: yup.object().nullable(),
  personInChargeName: textSchema.nullable(),
  remarks: remarksSchema.nullable(),
});
