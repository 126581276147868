import { createCollectionPrecaution } from "api/graphql/mutations";
import {
  getWorkplace,
  searchCollectionPrecautionsByOffset,
} from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  useParams,
  useNavigate,
  useLocation,
  createSearchParams,
} from "react-router-dom";
import useClasses from "utils/useClasses";
import { useXGridComponents } from "utils/useXGridComponents";
import { useStyles } from "./styles";
import { debugLog } from "utils/log";
import { toQueryString, fromQueryString } from "utils/useXGridComponents";
import { useQuery } from "utils/useQuery";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

const columns = [
  {
    field: "lastUpdatedOn",
    headerName: "最終更新日",
    minWidth: 150,
    filterable: false,
  },
  {
    field: "name",
    headerName: "タイトル",
    minWidth: 200,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "fileCount",
    headerName: "添付ファイル数",
    minWidth: 180,
    sortable: false,
    type: "number",
  },
];

/**
 * 回収注意点タブの表示を行うコンテナコンポーネントです
 * @param {object} value 事業場情報
 * @callback render
 */
export const Container = ({ render, value, ...props }) => {
  const classes = useClasses(useStyles);
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpenRegisterDialog, setIsOpenRegisterDialog] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const { workplaceId } = useParams();
  const workplace = useQuery({
    query: getWorkplace,
    variables: {
      id: workplaceId,
    },
  });

  const xGridObject = useXGridComponents(
    columns,
    searchCollectionPrecautionsByOffset,
    {
      filter: {
        and: [
          {
            ownerWorkplaceId: {
              eq: value.id,
            },
          },
        ],
      },
    },
    {
      sort: {
        direction: "desc",
        field: "lastUpdatedOn",
      },
      ...fromQueryString(location.search),
    }
  );

  const dispatch = useDispatch();

  const handleSubmit = (data) => {
    setIsSubmit(true);
    const { name, message, tags, files } = data;
    API.graphql(
      graphqlOperation(createCollectionPrecaution, {
        input: {
          name: name,
          message: message,
          tags: tags,
          ownerWorkplaceId: value.id,
          files:
            files?.map((file, index) => ({
              name: file.name,
              base64: file.context,
              order: index,
              label: file.name,
            })) ?? null,
        },
      })
    )
      .then((res) => {
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        xGridObject.functions.refetch();
        setIsOpenRegisterDialog(false);
      })
      .catch((err) => {
        debugLog("回収注意点.登録失敗: ", err);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const handleChangeGridSelection = (params) => {
    const selected = xGridObject.params.rows.find(
      (row) => row.id === params[0]
    );
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({
          ...Object.fromEntries(new URLSearchParams(location.search)),
          ...Object.fromEntries(
            new URLSearchParams(toQueryString(xGridObject.search))
          ),
        }).toString()}`,
      },
      {
        replace: true,
      }
    );

    navigate(`${location.pathname}/precaution/collection/${selected.id}`);
  };

  return render({
    ...props,
    classes: classes,
    handleChangeGridSelection: handleChangeGridSelection,
    isOpenRegisterDialog: isOpenRegisterDialog,
    handleOpenRegisterDialog: () => setIsOpenRegisterDialog(true),
    handleCloseRegisterDialog: () => setIsOpenRegisterDialog(false),
    xGridParams: xGridObject.params,
    handleSubmit: handleSubmit,
    isSubmit: isSubmit,
    workplace: workplace?.data?.getWorkplace,
  });
};
