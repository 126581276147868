import {
  removeWasteLicense,
  updateMunicipalSolidWasteLicense,
} from "api/graphql/mutations";
import { getMunicipalSolidWasteLicense } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { companySelector } from "ducks/Company";
import { toggle } from "ducks/Loading";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toApiValue, toFormValue } from "utils/ImageFunctions";
import { debugLog } from "utils/log";
import { useMutation } from "utils/useMutation";

/**
 * 許可証の情報を詳しく表示するコンテナコンポーネントです。
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const company = useSelector(companySelector);
  const [open, setOpen] = useState(false);
  const [license, setLicense] = useState(null);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const dispatch = useDispatch();
  const queries = new URLSearchParams(useLocation().search);
  const fromAlert = queries.get("alert") !== null;

  const loadLicense = useCallback(() => {
    dispatch(toggle(true));
    if (company) {
      API.graphql(
        graphqlOperation(getMunicipalSolidWasteLicense, {
          id: id,
        })
      )
        .then((result) => {
          debugLog("generalWasteLicense.onLoad.succeeded", result);
          toFormValue({
            files: result.data.getMunicipalSolidWasteLicense?.files,
          }).then((files) => {
            setLicense({
              ...result.data.getMunicipalSolidWasteLicense,
              files: files,
            });
          });
        })
        .catch((error) => {
          debugLog("generalWasteLicense.onLoad.failed", error);
        })
        .finally(() => {
          dispatch(toggle(false));
        });
    }
  }, [dispatch, company, id]);

  useEffect(() => {
    loadLicense();
  }, [loadLicense]);

  const [
    runUpdateMunicipalSolidWasteLicense,
    updateMunicipalSolidWasteLicenseStatus,
  ] = useMutation(updateMunicipalSolidWasteLicense, {
    onCompleted: (data) => {
      if (fromAlert) {
        navigate(
          {
            pathname: `/alert-licenses`,
          },
          {
            replace: true,
          }
        );
      } else {
        loadLicense();
        setOpen(false);
      }
    },
    succeedMessage: "保存しました。",
    errorMessage: "エラーが発生したため、保存できませんでした。",
  });
  const [runRemoveWasteLicense, runRemoveWasteLicenseState] = useMutation(
    removeWasteLicense,
    {
      onCompleted: (data) => {
        if (fromAlert) {
          navigate(
            {
              pathname: `/alert-licenses`,
            },
            {
              replace: true,
            }
          );
        } else {
          navigate(`/master/license/waste`);
        }
      },
      succeedMessage: "削除しました。",
      errorMessage: "エラーが発生したため、削除できませんでした。",
    }
  );

  const updateLicense = async (params) => {
    const {
      category,
      japaneseLocalGovernment,
      licenseExpirationOn,
      licensedOn,
      certifiedAsExcellentOn,
      createdAt,
      updatedAt,
      version,
      licensedCompanyId,
      prefectures,
      state,
      files,
      position,
      inputType,
      ...other
    } = params;

    runUpdateMunicipalSolidWasteLicense({
      input: {
        ...other,
        municipalSolidWasteLicenseBusinessCategoryCode: category.code,
        japaneseLocalGovernmentId: japaneseLocalGovernment.id,
        licenseExpirationOn:
          licenseExpirationOn &&
          DateTime.fromJSDate(new Date(licenseExpirationOn)).toFormat(
            "yyyy-MM-dd"
          ),
        licensedOn:
          licensedOn &&
          DateTime.fromJSDate(new Date(licensedOn)).toFormat("yyyy-MM-dd"),
        expectedVersion: version,
        prefecturesCode: prefectures.code,
        files: await toApiValue({ files: files }),
        isMapInput: inputType === "from-map" ? true : false,
      },
    });
  };

  return render({
    value: license,
    open: open,
    isSubmit:
      runRemoveWasteLicenseState?.loading ||
      updateMunicipalSolidWasteLicenseStatus.loading,
    onOpenDialog: () => setOpen(true),
    onCloseDialog: () => setOpen(false),
    onDelete: () =>
      runRemoveWasteLicense({
        input: {
          id: id,
        },
      }),
    onSubmit: updateLicense,
    isOpenDeleteDialog: isOpenDeleteDialog,
    toggleOpenDeleteDialog: (v) => setIsOpenDeleteDialog(v),
    ...props,
  });
};
