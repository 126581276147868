export const styles = (theme) => ({
  form_addNew: {
    padding: theme.spacing(5),
  },
  maxWeightField: {
    width: 180,
  },
  weightField: {
    width: 180,
  },
  ridingField: {
    width: 180,
  },
});
