import { useCallback } from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

/**
 * 引数を受けてJSX Elemntを返します。
 * @callback render
 * @param {arrayOf} points ルート
 * @returns
 */
export const Container = ({ render, points }) => {
  const [directions, setDirections] = useState();

  const calculateDirections = useCallback((points) => {
    const waypoints = points.map((item) => {
      return {
        location: { lat: item.lat, lng: item.lng },
        stopover: true,
      };
    });

    const origin = points[0];
    const destination = points[points.length - 1];
    const directionsServiceOptions = {
      origin,
      destination,
      waypoints,
      travelMode: window.google.maps.TravelMode.DRIVING,
      avoidTolls: true,
      avoidHighways: true,
    };

    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(directionsServiceOptions, (response, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        setDirections(response);
      }
    });
  }, []);

  useEffect(() => {
    if (points.length) {
      calculateDirections(points);
    }
  }, [points, calculateDirections]);

  return render({
    directions,
  });
};

Container.propTypes = {
  render: PropTypes.func.isRequired,
  points: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    })
  ).isRequired,
};
