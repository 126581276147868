import { Fade, Menu, MenuItem } from "@mui/material";
import { RenderButtonToolBar } from "utils/XgridExpand";
import { FullScreenDialog, XGridComponent } from "views/molecules";
import { ExchangeForm } from "../ExchangeForm";
import { InstallationForm } from "../InstallationForm";
import { MovementForm } from "../MovementForm";
import { ReinstallationForm } from "../ReinstallationForm";
import { WithdrawalForm } from "../WithdrawalForm";

/**
 * 保管メニューを表示するコンポーネントです。
 * @param {boolean} open 開閉状態
 * @param {JSX.Element} anchorEl アンカー要素
 * @fires Safekeeping#onClose 閉じる時
 * @fires Safekeeping#onExchange 交換時
 * @fires Safekeeping#onWithdrawal 撤去時
 * @param {boolean} disabled 無効設定
 * @returns {JSX.Element}
 */
const SafekeepingMenu = ({
  open = false,
  anchorEl = null,
  onClose = () => {},
  onExchange = () => {},
  onWithdrawal = () => {},
  disabled = false,
}) => {
  return (
    <Menu
      id="safekeeping-menu"
      MenuListProps={{
        "aria-labelledby": "fade-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
    >
      <MenuItem disabled={disabled} onClick={onExchange}>
        {"交換"}
      </MenuItem>
      <MenuItem disabled={disabled} onClick={onWithdrawal}>
        {"撤去"}
      </MenuItem>
    </Menu>
  );
};

/**
 * 設置メニューを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {boolean} props.open 開閉状態
 * @param {object} props.anchorEl アンカー要素
 * @fires InstallationMenu#onClose 閉じる時
 * @fires InstallationMenu#onInstall 設置時
 * @fires InstallationMenu#onMove 移動時
 * @param {boolean} disabled 無効設定
 * @returns {JSX.Element}
 */
const InstallationMenu = ({
  open = false,
  anchorEl = null,
  onClose = () => {},
  onInstall = () => {},
  onMove = () => {},
  disabled = false,
}) => {
  return (
    <Menu
      id="fade-menu"
      MenuListProps={{
        "aria-labelledby": "fade-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      TransitionComponent={Fade}
    >
      <MenuItem disabled={disabled} onClick={onInstall}>
        {"設置"}
      </MenuItem>
      <MenuItem disabled={disabled} onClick={onMove}>
        {"移動"}
      </MenuItem>
    </Menu>
  );
};

/**
 * コンテナ管理のリストを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <div>
      <XGridComponent
        onClose={props.onClose}
        onChangeSelection={props.onSelected}
        checkboxSelection={props.checkbox}
        multipleSelection={props.multiple}
        titleTable={props.title}
        onCellClick={(params, event) => {
          if (params.colDef.field === "") {
            event.defaultMuiPrevented = true;
          }
        }}
        onDownload={true}
        actionButton={
          props.action && (
            <RenderButtonToolBar
              label="コンテナを設置する"
              onClick={() => props.installationForm.toggle(true)}
            />
          )
        }
        {...props.xGridParams}
      />
      <SafekeepingMenu
        open={props.safekeepingMenu.open}
        anchorEl={props.safekeepingMenu?.options?.anchorEl}
        onClose={() => props.safekeepingMenu.toggle(false)}
        onExchange={() => props.exchangeForm.toggle(true)}
        onWithdrawal={() => props.withdrawalForm.toggle(true)}
        disabled={props.loading}
      />
      <InstallationMenu
        open={props.installationMenu.open}
        anchorEl={props.installationMenu?.options?.anchorEl}
        onClose={() => props.installationMenu.toggle(false)}
        onInstall={() => props.reinstallationForm.toggle(true)}
        onMove={() => props.movementForm.toggle(true)}
        disabled={props.loading}
      />
      <FullScreenDialog
        title={"コンテナを設置する"}
        textConfirm={"登録"}
        open={props.installationForm.open}
        onClose={() => props.installationForm.toggle(false)}
        isSubmit={props.isSubmit}
        disabled={props.isSubmit}
        onClickSubmit={() => props?.installationFormRef?.current?.submit?.()}
      >
        <InstallationForm
          onSubmit={props.onInstallation}
          _ref={props.installationFormRef}
          disabled={props.loading}
        />
      </FullScreenDialog>
      <FullScreenDialog
        title={"コンテナを設置する"}
        textConfirm={"登録"}
        open={props.reinstallationForm.open}
        onClose={() => props.reinstallationForm.toggle(false)}
        isSubmit={props.isSubmit}
        disabled={props.isSubmit}
        onClickSubmit={() => props.reinstallationFormRef?.current?.submit?.()}
      >
        <ReinstallationForm
          info={props.installationMenu?.options?.value}
          value={props.value}
          onSubmit={props.onReinstallation}
          _ref={props.reinstallationFormRef}
          disabled={props.loading}
        />
      </FullScreenDialog>
      <FullScreenDialog
        title={"コンテナを交換する"}
        textConfirm={"登録"}
        open={props.exchangeForm.open}
        onClose={() => props.exchangeForm.toggle(false)}
        isSubmit={props.isSubmit}
        disabled={props.isSubmit}
        onClickSubmit={() => props.exchangeFormRef?.current?.submit?.()}
      >
        <ExchangeForm
          info={props.safekeepingMenu?.options?.value}
          onSubmit={props.onExchange}
          _ref={props.exchangeFormRef}
          disabled={props.loading}
        />
      </FullScreenDialog>
      <FullScreenDialog
        title={"コンテナを撤去する"}
        textConfirm={"登録"}
        open={props.withdrawalForm.open}
        onClose={() => props.withdrawalForm.toggle(false)}
        isSubmit={props.isSubmit}
        disabled={props.isSubmit}
        onClickSubmit={() => props.withdrawalFormRef?.current?.submit?.()}
      >
        <WithdrawalForm
          info={props.safekeepingMenu?.options?.value}
          onSubmit={props.onWithdrawal}
          _ref={props.withdrawalFormRef}
          disabled={props.loading}
        />
      </FullScreenDialog>
      <FullScreenDialog
        title={"コンテナを移動する"}
        textConfirm={"登録"}
        open={props.movementForm.open}
        onClose={() => props.movementForm.toggle(false)}
        isSubmit={props.isSubmit}
        disabled={props.isSubmit}
        onClickSubmit={() => props?.movememtFormRef?.current?.submit?.()}
      >
        <MovementForm
          info={props.installationMenu?.options?.value}
          onSubmit={props.onMove}
          _ref={props.movememtFormRef}
          disabled={props.loading}
        />
      </FullScreenDialog>
    </div>
  );
};
