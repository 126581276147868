import { Grid } from "@mui/material";
import React from "react";
// import { SelectPopup } from "views/molecules";
import { Transportation } from "../Transportation";
// import { Controller } from "react-hook-form";

/**
 * 運搬区間情報を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Elemnt}
 */
export const Presententer = (props) => {
  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={4}>
        <Controller
          name={`transportProcesses`}
          control={props.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <SelectPopup
              label="運搬区間"
              value={props.sectionNumberOptions.find(
                (item, index) => item.code === props.sectionfields?.length
              )}
              onChange={props.onChangeSectionNumber}
              options={props.sectionNumberOptions}
              required={false}
              error={error}
              optionValueField="code"
              optionNameField="name"
            />
          )}
        />
      </Grid> */}
      {props.sectionfields.map((_, index) => (
        <Grid key={index} item xs={12}>
          <Transportation
            index={index}
            number={index + 1}
            onChange={props.onChangeTransportation(index)}
          />
        </Grid>
      ))}
      <Grid item xs={8} />
    </Grid>
  );
};
