import React from "react";
import { FormProvider } from "react-hook-form";
import { StepFrame } from "views/molecules";
import { BasicInformation, DischargeInformationForm } from "./ChildComponents";

/**
 * スポット回収フォームを入力するコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Elemnt}
 */
export const Presententer = (props) => (
  <FormProvider {...props.methods}>
    <StepFrame number="1" title="基本情報">
      <BasicInformation />
    </StepFrame>
    <StepFrame number="2" title="排出情報">
      <DischargeInformationForm />
    </StepFrame>
  </FormProvider>
);
