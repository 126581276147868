import { yupResolver } from "@hookform/resolvers/yup";
import {
  listIndustrialWasteLicenseBusinessCategories,
  listJapaneseLocalGovernments,
  listOrdinanceDesignatedCityForWastes,
} from "api/graphql/queries";
import { companySelector } from "ducks/Company";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toWasteLicense } from "utils/Context";
import { debugLog } from "utils/log";
import useClasses from "utils/useClasses";
import { useQuery } from "utils/useQuery";
import { styles } from "./styles";
import { licenseSchema } from "./Validations";

const defaultValues = {
  number: "",
  japaneseLocalGovernment: null,
  licensedOn: null,
  licenseExpirationOn: null,
  certifiedAsExcellentOn: null,
  companyName: "",
  representativeName: "",
  postalCode: "",
  inputType: "manual",
  remarks: "",
  files: [],
};

/**
 * 産業廃棄物許可証のフォームを表示するコンテナコンポーネントです。
 * @callback render
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  id = "IndustrialWaste",
  onSubmit,
  onError = (err) => debugLog(err),
  ...props
}) => {
  const classes = useClasses(styles);
  const company = useSelector(companySelector);
  const japaneseLocalGovernments = useQuery({
    query: listJapaneseLocalGovernments,
    variables: {
      wasteLicenseGrantor: "industrialWaste",
    },
  });

  const industrialWasteLicenseBusinessCategories = useQuery({
    query: listIndustrialWasteLicenseBusinessCategories,
  });

  const ordinanceDesignatedCityForWastes = useQuery({
    query: listOrdinanceDesignatedCityForWastes,
  });

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(licenseSchema),
  });

  const { reset, control, handleSubmit, setValue, watch, formState, trigger } =
    methods;
  const { isDirty } = formState;

  useEffect(() => {
    reset({
      ...defaultValues,
      ...value,
      companyName: value?.companyName ?? company?.name,
      representativeName:
        value?.representativeName ?? company?.representativeName,
      licensedCompanyId: value?.licensedCompanyId ?? company?.id,
      postalCode: value?.postalCode ?? company?.headOfficeWorkplace?.postalCode,
      prefectures:
        value?.prefectures ?? company?.headOfficeWorkplace?.prefectures,
      city: value?.city ?? company?.headOfficeWorkplace?.city,
      streetAddress:
        value?.streetAddress ?? company?.headOfficeWorkplace?.streetAddress,
      otherAddress:
        value?.otherAddress ?? company?.headOfficeWorkplace?.otherAddress,
      files: value?.files,
      inputType: value?.isMapInput ? "from-map" : "manual",
    });
  }, [company, reset, value]);

  const licenseNumber = watch("number");

  useEffect(() => {
    if (isDirty) {
      const wasteLicense = toWasteLicense(
        licenseNumber?.replace(/[^0-9]/g, "")
      );

      if (wasteLicense?.issuer) {
        setValue(
          "japaneseLocalGovernment",
          japaneseLocalGovernments?.data?.listJapaneseLocalGovernments?.items?.find(
            (i) =>
              i.id ===
                ordinanceDesignatedCityForWastes?.data?.listOrdinanceDesignatedCityForWastes?.items?.find(
                  (i) => i.number === wasteLicense?.issuer?.code
                )?.japaneseLocalGovernmentId ?? null
          )
        );
      } else {
        setValue("japaneseLocalGovernment", null);
      }

      if (wasteLicense?.categoryCode) {
        setValue(
          "category",
          industrialWasteLicenseBusinessCategories?.data?.listIndustrialWasteLicenseBusinessCategories?.items.find(
            (i) => i.code === wasteLicense?.categoryCode
          ),
          { shouldValidate: true }
        );
      } else {
        setValue("category", null);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, licenseNumber, setValue]);

  const licensedOn = watch("licensedOn");
  const licenseExpirationOn = watch("licenseExpirationOn");
  useEffect(() => {
    if (isDirty && licenseExpirationOn) {
      trigger("licenseExpirationOn");
    }
  }, [isDirty, licensedOn, licenseExpirationOn, trigger]);

  return render({
    classes: classes,
    control: control,
    methods: methods,
    id: id,
    submit: handleSubmit(onSubmit, onError),
    loading:
      industrialWasteLicenseBusinessCategories.loading ||
      japaneseLocalGovernments.loading ||
      ordinanceDesignatedCityForWastes.loading,
    ...props,
  });
};
