import { getCompany } from "api/graphql/queries";
import { updateCompany as updateAPI } from "api/graphql/mutations";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "utils/graphqlOperation";

/**
 * 取得します。
 * @returns {object}
 */
const fetch = async () => {
  let result = null;
  try {
    const fetchedData = await API.graphql(graphqlOperation(getCompany));
    result = fetchedData.data.getCompany;
  } catch (error) {
    throw error;
  }
  return result;
};

export const fetchCompany = createAsyncThunk(
  "company/fetch",
  async (_, thunkApi) => {
    return fetch();
  }
);

/**
 * 更新します
 * @param {object} data 更新データ
 */
const update = async (data) => {
  try {
    await API.graphql(graphqlOperation(updateAPI, { input: data }));
  } catch (err) {
    throw err;
  }
};

export const updateCompany = createAsyncThunk(
  "company/update",
  async (args, thunkApi) => {
    return update(args);
  }
);
