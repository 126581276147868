import { DeleteOutlineOutlined } from "@mui/icons-material";
import React from "react";
import { WasteDetailTabs } from "views/organisms/Masters/Partner";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { Caption } from "views/organisms/DetailsFrame/ChildComponents";
import {
  Avatar,
  Button,
  Container,
  Paper,
  FormHelperText,
  Stack,
} from "@mui/material";
import { FullScreenDialog, WasteFlowSelector } from "views/molecules";
import { WasteBasicForm } from "views/organisms/Masters/Partner/WasteBasicForm";
import { ConfirmDialog } from "views/molecules";

const WasteDetails = React.forwardRef((props, ref) => {
  return <WasteDetailTabs _ref={ref} {...props} />;
});

const BasicForm = React.forwardRef((props, ref) => {
  return <WasteBasicForm {...props} _ref={ref} />;
});

const DetailForm = <></>;

/**
 * 廃棄物の詳細画面を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = ({
  tab = 0,
  getTab,
  value,
  open,
  onOpen,
  onClose,
  onSubmit,
  isSubmit,
  basicFormRef,
  detailFormRef,
  id,
  wasteId,
  formatBasicData,
  onUpdateBasic,
  client,
  selectedFlows,
  onSelectedFlows,
  error,
  company,
  fixedFilter,
  detailsRef,
  workplace,
  removeWasteMutation,
  removeWasteMutationStatus,
}) => {
  return (
    <div>
      <DetailsFrame
        caption={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Avatar
              sx={{
                marginRight: 3,
              }}
            >
              <DeleteOutlineOutlined />
            </Avatar>
            <Caption
              title={
                value?.type?.smallClass?.name ||
                value?.type?.middleClass?.name ||
                value?.type?.largeClass?.name
              }
            />
          </div>
        }
        actions={
          <>
            {tab === 1 &&
              (client?.state ? client?.state !== "removed" : false) &&
              (workplace?.state ? workplace?.state !== "removed" : false) && (
                <Stack spacing={2} direction="row">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={onOpen("remove")}
                    disabled={!value}
                  >
                    {"削除する"}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onOpen("basic")}
                    disabled={!value}
                  >
                    {"編集する"}
                  </Button>
                </Stack>
              )}
            {/* {tab === 1 && (
              <Button
                variant="contained"
                color="primary"
                onClick={onOpenDetailsDialog}
              >
                {"詳細情報を編集する"}
              </Button>
            )} */}
            {tab === 0 &&
              (workplace?.state ? workplace?.state !== "removed" : false) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onOpen("flow")}
                  disabled={!value}
                >
                  {"処理フローを追加する"}
                </Button>
              )}
          </>
        }
      >
        <WasteDetails
          value={value}
          onChangeTab={getTab}
          companyId={client?.companyId}
          wasteId={wasteId}
          ref={detailsRef}
        />
        <FullScreenDialog
          title={
            open === "basic"
              ? `廃棄物「${
                  value?.name ||
                  value?.type?.smallClass?.name ||
                  value?.type?.middleClass?.name ||
                  value?.type?.largeClass?.name ||
                  ""
                }」基本情報の編集`
              : open === "flow"
              ? `廃棄物「${
                  value?.name ||
                  value?.type?.smallClass?.name ||
                  value?.type?.middleClass?.name ||
                  value?.type?.largeClass?.name ||
                  ""
                }」処理フローの追加`
              : ""
          }
          textConfirm="保存"
          open={open === "basic" || open === "details" || open === "flow"}
          onClose={onClose}
          onClickSubmit={onSubmit}
          isSubmit={isSubmit}
          disabled={isSubmit}
          container={false}
        >
          <Container maxWidth="md">
            <Paper
              elevation={3}
              sx={{
                padding: 5,
              }}
            >
              {open === "basic" && (
                <BasicForm
                  ref={basicFormRef}
                  value={formatBasicData(value)}
                  onSubmit={onUpdateBasic}
                  editMode={true}
                />
              )}
              {/* UNDONE: 詳細情報の登録更新フォームが必要 */}
              {open === "details" && <DetailForm ref={detailFormRef} />}
              {open === "flow" && (
                <>
                  <WasteFlowSelector
                    value={selectedFlows}
                    action={false}
                    checkbox={true}
                    multiple={true}
                    managedCompanyId={company?.id}
                    onSelected={onSelectedFlows}
                    fixedFilter={fixedFilter}
                  />
                  <FormHelperText error={true}>{error}</FormHelperText>
                </>
              )}
            </Paper>
          </Container>
        </FullScreenDialog>
      </DetailsFrame>
      <ConfirmDialog
        disabled={removeWasteMutationStatus.loading}
        open={open === "remove"}
        title="削除確認"
        message="削除します。よろしいですか？"
        positiveText="はい"
        negativeText="いいえ"
        onPositive={() => {
          removeWasteMutation({
            input: {
              id: wasteId,
            },
          });
        }}
        onNegative={onClose}
      />
    </div>
  );
};
