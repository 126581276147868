/**
 * 日付の選択の表示を行うコンテナコンポーネントです。
 */
export const Container = ({
  render,
  placeholder,
  value,
  onChange,
  helperText,
  error,
  label,
  inputVariant,
  required = false,
  ...props
}) => {
  const handleChange = (newValue) => {
    onChange && onChange(newValue);
  };

  return render({
    ...props,
    value: value,
    placeholder: placeholder,
    onChange: handleChange,
    helperText: helperText,
    error: error,
    label: label,
    inputVariant: inputVariant,
    required: required,
  });
};
