/**
 * 車検記録簿を表示するコンテナコンポーネントです。
 */
export const Container = ({
  render,
  xGridParams,
  onSelected,
  ...props
}) => {
  return render({
    ...props,
    xGridParams,
    onSelected,
  });
};
