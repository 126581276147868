import { AvTimer } from "@mui/icons-material";
import { Alert, Slide } from "@mui/material";
import React from "react";

/**
 * 到着予定時間の通知を表示するプレゼンテーションコンポーネントです。
 * @return {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Slide
      in={!!props.value}
      direction="right"
      mountOnEnter
      unmountOnExit
      className="notification arrival_schedule"
    >
      <Alert
        icon={<AvTimer color="inherit" />}
        variant="filled"
        severity="info"
      >
        到着予定　{props.value}
      </Alert>
    </Slide>
  );
};
