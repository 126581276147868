import { useWindowHeight } from "@react-hook/window-size";
import { useState } from "react";
import { debugLog } from "utils/log";
import { useStyles } from "./styles";
import { useOpener } from "utils/useOpener";

/**
 * 回収を開始するリストを表示するコンテナコンポーネントです。
 * @returns {func} 描画関数
 */
export const Container = ({
  render,
  value,
  open = false,
  onClose,
  onClickNumber,
  onRecordTask = (data) => debugLog("数量入力: ", data),
  onSort = (data) => debugLog("ソート結果: ", data),
  next,
  ...props
}) => {
  const classes = useStyles();
  const detailsOpener = useOpener();
  const screenHeight = useWindowHeight();
  const [collectItemIndex, setCollectItemIndex] = useState(0);
  const [swipeIndex, setSwipeIndex] = useState();

  const handleOpenDetailsDialog = (index) => {
    setCollectItemIndex(index);
    detailsOpener.toggle(true);
  };

  const handleChangeIndex = (index, id, request) => {
    setSwipeIndex({ index: index, id: id, request: request });
  };

  const handleRecordTask = (data) => {
    onRecordTask(data);
  };

  const handleDragEnd = (results, provided) => {
    const { source, destination } = results;

    if (!destination) {
      return;
    }

    if (destination?.index === source?.index) {
      return;
    }

    let result = [...value];
    const temp = result.splice(source.index, 1);
    result.splice(destination.index, 0, temp[0]);

    onSort(result);
  };

  return render({
    classes: classes,
    value: value,
    collectItemIndex: collectItemIndex,
    open: open,
    onClose: onClose,
    onClickNumber: onClickNumber,
    onRecordTask: handleRecordTask,
    detailsOpener: detailsOpener,
    handleOpenDetailsDialog: handleOpenDetailsDialog,
    swipeIndex: swipeIndex,
    onChangeIndex: handleChangeIndex,
    onDragEnd: handleDragEnd,
    next: next,
    screenHeight: screenHeight,
    ...props,
  });
};
