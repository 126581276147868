import {
  getClient, getWasteFlow,
  searchWasteManagementMethodsByOffset
} from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { toggle } from "ducks/Loading";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { debugLog } from "utils/log";
import { useQuery } from "utils/useQuery";

/**
 * 取引先事業処理フロー詳細ページを表示するコンテナコンポーネントです。
 * @param {props} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const [value, setValue] = useState(null);
  const [flow, setFlow] = useState(null);
  const { id, wasteId, flowId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const client = useQuery({
    query: getClient,
    variables: {
      id: id,
    },
    onError: () => {
      navigate(
        {
          pathname: `/NotFound`,
        },
        {
          replace: true,
        }
      );
    },
  });

  const load = useCallback(() => {
    dispatch(toggle(true));
    API.graphql(
      graphqlOperation(searchWasteManagementMethodsByOffset, {
        managedWasteId: wasteId,
        filter: {
          or: [{ id: { wildcard: flowId } }],
        },
      })
    )
      .then((res) => {
        const managementMethods =
          res.data.searchWasteManagementMethodsByOffset.items[0];
        setValue(managementMethods);
        API.graphql(
          graphqlOperation(getWasteFlow, {
            id: managementMethods.wasteFlowId,
          })
        )
          .then((res2) => {
            setFlow(res2.data.getWasteFlow);
          })
          .catch((err) => {
            debugLog("処理フロー取得に失敗: ", err);
          });
      })
      .catch((err) => {
        debugLog("報告方法取得失敗: ", err);
      })
      .finally(() => {
        dispatch(toggle(false));
      });
  }, [dispatch, wasteId, flowId]);

  useEffect(() => {
    load();
  }, [load]);

  const handleNotificationChanged = () => {
    load();
  };

  return render({
    value: value,
    flow: flow,
    onNotificationChanged: handleNotificationChanged,
    client: client?.data?.getClient,
    ...props,
  });
};
