import { Button, Paper, Stack, Typography } from "@mui/material";
import { lightGreen } from "@mui/material/colors";
import { InformationItem, Mark } from "views/atoms";
import { ConfirmDialog } from "views/molecules";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { TemplateForm } from "views/organisms/Masters/CheckSheet/TemplateForm";
import { Groups } from "views/organisms/Masters/CheckSheet/TemplateForm/Step2Form/Groups";

/**
 * チェックシートテンプレート詳細を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 */
export const Presententer = (props) => {
  return (
    <>
      <DetailsFrame
        caption={
          <Stack>
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: "bold",
              }}
            >
              {props.value?.name ?? ""}
            </Typography>
            <Mark
              text={props.value?.category?.name}
              backgroundColor={lightGreen[500]}
            />
          </Stack>
        }
        actions={
          props.value?.state !== "removed" ? (
            <Stack spacing={2} direction="row">
              <Button
                color="error"
                variant="contained"
                onClick={() => props.confirm.toggle(true)}
                disabled={props.isSubmit}
              >
                {"削除する"}
              </Button>
              <Button
                variant="contained"
                onClick={() => props.form.toggle(true)}
                disabled={props.isSubmit}
              >
                {"編集する"}
              </Button>
            </Stack>
          ) : (
            <></>
          )
        }
      >
        <Stack spacing={2}>
          <Paper
            sx={{
              p: 2,
            }}
          >
            <Stack spacing={2}>
              <InformationItem label="説明">
                {props.value?.description ?? ""}
              </InformationItem>
              <InformationItem label="備考">
                {props.value?.remarks ?? ""}
              </InformationItem>
            </Stack>
          </Paper>
          <Groups value={props.value?.sections} readOnly={true} />
        </Stack>
      </DetailsFrame>
      <TemplateForm
        title={`${props.value?.name}テンプレートの更新`}
        confirmButtonText={"保存"}
        value={props.value}
        open={props.form.open}
        mode="edit"
        onClose={() => props.form.toggle(false)}
        onSubmit={props.onUpdate}
        loading={props.isSubmit}
      />
      <ConfirmDialog
        title="削除確認"
        message="削除します。よろしいですか？"
        open={props.confirm.open}
        positiveText={"削除"}
        negativeText={"キャンセル"}
        onPositive={props.onRemove}
        onNegative={() => props.confirm.toggle(false)}
        disabled={props.isSubmit}
      />
    </>
  );
};

export default Presententer;
