export const styles = (theme) => ({
  box_masterInfor: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  box_header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
  },
  box_icon_text: {
    display: "flex",
    alignItems: "center",
  },
  avart: {
    marginRight: theme.spacing(4),
    "& .MuiAvatar-root": {
      width: "60px",
      height: "60px",
    },
  },
  icon: {},
  text_infor: {},
  box_content: { padding: " 16px 32px", backgroundColor: " #fff" },
});
