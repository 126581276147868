import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { fetchPlans } from "./api";

/*
object shape
  []
    code
    name
*/

const plansSlice = createSlice({
  name: "plans",
  initialState: {
    plans: null,
    isLoading: false,
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlans.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.plans = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchPlans.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

/**
 * ストアから加入プランを取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.Plans;

/**
 * ストアから加入プランを取得します。
 */
export const plansSelector = createSelector(selector, (plans) => plans.plans);

/**
 * ストアから読み込みを取得します。
 */
export const isLoadingSelector = createSelector(
  selector,
  (plans) => plans.isLoading
);

/**
 * ストアからエラーを取得します。
 */
export const errorSelector = createSelector(selector, (plans) => plans.error);

export default plansSlice.reducer;
