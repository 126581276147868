import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 取引先事業場を選択するコンポーネントです。
 * @fires ClientWorkplaceSelector#onSelected
 */
export const ClientWorkplaceSelector = containerPresententer(
  Container,
  Presententer
);
