import { yupResolver } from "@hookform/resolvers/yup";
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { debugLog } from "utils/log";
import { v4 } from "uuid";
import { DatePicker, RadioGroup, TextField } from "views/atoms";
import {
  ClientWorkplaceSelector,
  InputFieldPopppSelect,
} from "views/molecules";
import { basicSchema } from "./Validations";

const locationSelectorDefaultValues = {
  installationLocationCategory: "Installation",
  installationWorkplace: null,
};

const LocationSelector = ({
  value,
  onChange = () => {},
  open = false,
  onClose = () => {},
}) => {
  const [formValue, setFormValue] = useState(value);

  useEffect(() => {
    setFormValue({
      ...locationSelectorDefaultValues,
      ...value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(value)]);

  const filter = useMemo(() => {
    return {
      and: [
        {
          categoryId: {
            eq:
              formValue?.installationLocationCategory === "Installation"
                ? "containerInstallationLocation"
                : "containerStorage",
          },
        },
      ],
    };
  }, [formValue?.installationLocationCategory]);

  const onDecide = () => {
    onChange(formValue);
    onClose();
  };

  const onChangeCategory = (event) => {
    setFormValue({
      installationWorkplace: null,
      installationLocationCategory: event.target.value,
    });
  };

  const onChangeWorkplace = (newValue) => {
    setFormValue((prevState) => ({
      ...prevState,
      installationWorkplace: newValue?.[0] ?? null,
    }));
  };

  return (
    <>
      <Dialog open={open}>
        <DialogContent>
          <Stack spacing={2}>
            <Box>
              <RadioGroup
                name="category"
                label="種類"
                value={formValue?.installationLocationCategory}
                onChange={onChangeCategory}
                options={[
                  {
                    label: "設置",
                    value: "Installation",
                  },
                  {
                    label: "保管",
                    value: "Storage",
                  },
                ]}
                row={true}
              />
            </Box>
            <Box pt={3} pb={3} sx={{ height: "85vh" }}>
              <ClientWorkplaceSelector
                value={formValue?.installationWorkplace}
                onSelected={onChangeWorkplace}
                fixedOptions={{
                  filter: filter,
                }}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose} color="error">
            {"キャンセル"}
          </Button>
          <Button onClick={onDecide} autoFocus>
            {"決定"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

/**
 * 一括設定を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @fires BulkSettings#onReflect 反映時
 * @returns {JSX.Element}
 */
export const BulkSettings = ({ value, onReflect = () => {} }) => {
  const [formValue, setFormValue] = useState(value);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setFormValue(value);
  }, [value]);

  const handleReflect = () => {
    onReflect(formValue);
  };

  const handleChange = (name) => (value) => {
    setFormValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeWorkplace = ({
    installationLocationCategory,
    installationWorkplace,
  }) => {
    setFormValue((prevState) => ({
      ...prevState,
      installationLocationCategory: installationLocationCategory,
      installationWorkplace: installationWorkplace,
    }));
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Stack spacing={2}>
        <Stack direction={"row"} justifyContent="space-between">
          <Box>
            <Typography
              sx={{
                fontSize: "22px",
              }}
            >
              {"一括設定"}
            </Typography>
          </Box>
          <Box>
            <Button onClick={handleReflect} variant="contained">
              {"すべてのコンテナに反映する"}
            </Button>
          </Box>
        </Stack>
        <Box>
          <RadioGroup
            row={true}
            label="管理番号"
            value={formValue?.number}
            onChange={(e) => handleChange("number")(e.target.value)}
            required={false}
            options={[
              {
                label: "何もしない",
                value: "none",
              },
              {
                label: "連番を自動設定する",
                value: "sequential",
              },
              {
                label: "UUIDを自動設定する",
                value: "uuid",
              },
            ]}
          />
        </Box>
        <Box>
          <>
            <InputFieldPopppSelect
              label={"現在の設置・保管場所"}
              value={
                formValue?.installationWorkplace
                  ? {
                      id: formValue?.installationWorkplace?.id,
                      name: `${formValue?.installationWorkplace?.belongInCompanyName} ${formValue?.installationWorkplace?.name}`,
                    }
                  : null
              }
              required={false}
              placeholder={"管理事業場を選択します。"}
              onOpenModal={() => setOpen(true)}
              textConfirm="選択"
            />
            <LocationSelector
              value={formValue}
              open={open}
              onClose={() => setOpen(false)}
              onChange={handleChangeWorkplace}
            />
          </>
        </Box>
        <Box>
          <DatePicker
            label="設置・保管日"
            required={false}
            value={formValue?.installedOn}
            onChange={handleChange("installedOn")}
          />
        </Box>
      </Stack>
    </Paper>
  );
};

/**
 * 設定を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {number} props.index 位置
 * @param {object} props.value 値
 * @fires Settings#onChange 変更時
 * @param {object} props.error エラー
 * @returns {JSX.Element}
 */
const Settings = ({ index, value, onChange = () => {}, error }) => {
  const [open, setOpen] = useState(false);

  const handleChange = (name) => (v) => {
    onChange({
      ...value,
      [name]: v,
    });
  };

  const onChangeWorkplace = ({
    installationLocationCategory,
    installationWorkplace,
  }) => {
    onChange({
      ...value,
      installationLocationCategory: installationLocationCategory,
      installationWorkplace: installationWorkplace,
    });
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Stack spacing={2}>
        <Box>
          <Avatar sx={{ bgcolor: grey[800] }}>{index}</Avatar>
        </Box>
        <Box>
          <TextField
            label="管理番号"
            required={true}
            placeholder="管理番号を入力します。"
            value={value?.number ?? ""}
            onChange={(e) => handleChange("number")(e.target.value)}
            error={!!error?.number}
            helperText={error?.number?.message}
            fullWidth
          />
        </Box>
        <Box>
          <>
            <InputFieldPopppSelect
              label={"現在の設置・保管場所"}
              value={
                value?.installationWorkplace
                  ? {
                      id: value?.installationWorkplace?.id,
                      name: `${
                        value?.installationWorkplace?.belongInCompanyName ?? ""
                      } ${value?.installationWorkplace?.name ?? ""}`,
                    }
                  : null
              }
              required={true}
              placeholder={"管理事業場を選択します。"}
              onOpenModal={() => setOpen(true)}
              textConfirm="選択"
              error={error?.installationWorkplace}
            />
            <LocationSelector
              value={value}
              open={open}
              onClose={() => setOpen(false)}
              onChange={onChangeWorkplace}
            />
          </>
        </Box>
        <Box>
          <DatePicker
            label="設置・保管日"
            required={true}
            value={value?.installedOn ?? null}
            onChange={(v) => handleChange("installedOn")(v)}
            error={error?.installedOn}
            helperText={error?.installedOn?.message}
          />
        </Box>
      </Stack>
    </Paper>
  );
};

/**
 * コンテナ追加のステップ2を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @fires Step2Form#onSubmit 送信時
 * @fires Step2Form#onError エラー時
 * @param {object} props.other その他
 * @returns {JSX.Element}
 */
export const Step2Form = ({
  value,
  onSubmit = (data) => debugLog("コンテナ追加のステップ2の送信：", data),
  onError = (err) => debugLog("コンテナ追加のステップ2のエラー：", err),
  totalOfOwned = 0,
  ...other
}) => {
  const { trigger, watch, getValues, setValue, formState, control } = useForm({
    mode: "onBlur",
    resolver: yupResolver(basicSchema),
    defaultValues: {
      configs: null,
    },
  });

  const [bulk, setBulk] = useState({
    number: "none",
    installationWorkplace: null,
    installationLocationCategory: "Installation",
    installedOn: null,
  });

  useEffect(() => {
    setBulk({
      number: "none",
      installationWorkplace: value?.name,
      installationLocationCategory: "Installation",
      installedOn: null,
    });

    setValue("configs", [...Array(parseInt(value?.additionsCount ?? 0, 10))], {
      shouldDirty: true,
    });
  }, [setValue, value]);

  const handleReflect = (data) => {
    setBulk(data);
    let result = [...watch("configs")];
    for (let i = 0; i < result.length; i++) {
      let item = {
        number: null,
        installationWorkplace: null,
        installationLocationCategory: "Installation",
        installedOn: null,
      };

      if (data?.number === "none") {
        item.number = result?.[i]?.number ?? null;
      } else if (data?.number === "sequential") {
        item.number = ("00000000" + (totalOfOwned + i + 1)).slice(-8);
      } else if (data?.number === "uuid") {
        item.number = v4();
      }

      if (!!data?.installationWorkplace) {
        item.installationWorkplace = data?.installationWorkplace ?? null;
      } else {
        item.installationWorkplace = result?.[i]?.installationWorkplace ?? null;
      }

      if (!!data?.installationLocationCategory) {
        item.installationLocationCategory =
          data?.installationLocationCategory ?? null;
      } else {
        item.installationLocationCategory =
          result?.[i]?.installationLocationCategory ?? null;
      }

      if (!!data?.installedOn) {
        item.installedOn = data?.installedOn ?? null;
      } else {
        item.installedOn = result?.[i]?.installedOn ?? null;
      }

      result[i] = item;
    }

    setValue("configs", result, { shouldDirty: true });
  };

  useImperativeHandle(other._ref, () => ({
    submit: () => {
      trigger().then((res) => {
        if (res) {
          onSubmit(getValues());
        } else {
          onError(formState.errors);
        }
      });
    },
  }));

  return (
    <Container maxWidth="md">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <BulkSettings value={bulk} onReflect={handleReflect} />
        </Grid>
        <Controller
          name={`configs`}
          control={control}
          render={({
            field: { value: configs, onChange },
            fieldState: { error },
          }) => (
            <>
              {configs?.map((config, i) => (
                <Grid key={i} item xs={12}>
                  <Settings
                    index={i + 1}
                    value={config}
                    onChange={(data) => {
                      let result = [...configs];
                      result.splice(i, 1, data);
                      onChange(result);
                    }}
                    error={error?.[i]}
                  />
                </Grid>
              ))}
            </>
          )}
        />
      </Grid>
    </Container>
  );
};
