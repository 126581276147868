import { useStyles } from "./styles";
import useClasses from "utils/useClasses";

/**
 * 基本情報のタブの表示を行うコンテナコンポーネントです
 * @param {object} value 事業場情報
 */
export const Container = ({ render, value, ...props }) => {
  const classes = useClasses(useStyles);

  return render({ ...props, classes: classes, value: value });
};
