import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { fetchDisposalMethods } from "./api";

/*
object shape
  []
    code
    largeClassName
    middleClassName
    smallClassName
*/

const disposalMethodsSlice = createSlice({
  name: "disposalMethods",
  initialState: {
    disposalMethods: null,
    isLoading: false,
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDisposalMethods.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchDisposalMethods.fulfilled, (state, action) => {
        state.disposalMethods = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchDisposalMethods.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

/**
 * ストアから処分方法全体を取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.DisposalMethods;

/**
 * ストアから処分方法を取得します。
 */
export const disposalMethodsSelector = createSelector(
  selector,
  (disposalMethods) => disposalMethods.disposalMethods
);

/**
 * ストアから読み込みを取得します。
 */
export const isLoadingSelector = createSelector(
  selector,
  (disposalMethods) => disposalMethods.isLoading
);

/**
 * ストアからエラーを取得します。
 */
export const errorSelector = createSelector(
  selector,
  (disposalMethods) => disposalMethods.error
);

export default disposalMethodsSlice.reducer;
