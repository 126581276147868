import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 一般廃棄物許可証のフォームを表示するコンポーネントです。
 * @param {object} value フォームの値
 * @param {string} id フォームの値
 * @param {func} onSubmit 登録・編集を処理する
 * @param {func} onError エラーを処理
 */
export const LicenseGeneralWasteForm = containerPresententer(
  Container,
  Presententer
);
