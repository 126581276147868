import { useState } from "react";
import useClasses from "utils/useClasses";
import { useStyles } from "./styles";

/**
 * 回収注意点情報の詳細を表示するコンテナコンポーネントです。
 * @param {object} props プロパティ
 * @param {func} props.render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} props.value 回収注意点情報
 * @param {func} props.onChangeTab タブ変更時の処理
 * @returns {JSX.Element}
 */
export const Container = (props) => {
  const { render, value, onChangeTab } = props;
  const classes = useClasses(useStyles);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);

  return render({
    ...props,
    value: value,
    classes: classes,
    onChangeTab: onChangeTab,
    selectedFileUrl: selectedFileUrl,
    handleSelectedFileUrlChanged: (url) => setSelectedFileUrl(url),
  });
};
