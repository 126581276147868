import { useRef, useEffect } from "react";
import { register } from "swiper/element/bundle";

register();

/**
 * swiper-container要素を作成するコンポーネントです。
 * @param {object} props プロパティ
 * @param {JSX.Element} props.children 子要素
 * @param {string} props.direction スライドの方向 (horizontal | vertical)
 * @param {number} props.initialSlide 初期スライド (0 ~ n)
 * @param {string} props.virtual 仮想スライドを有効にするかどうか (true | false)
 * @param {object | boolean} props.pagination ページネーション
 * @param {object | boolean} props.navigation ナビゲーション
 * @param {function} props.onSlideChange スライドが変更されたときのコールバック
 * @param {function} props.onSlideChangeTransitionStart スライドが変更される遷移開始時のコールバック
 * @param {boolean} props.enabled 有効かどうか
 * @param {boolean} props.autoHeight 高さを自動調整するかどうか (true | false)
 * @returns {JSX.Element}
 */
export const SwiperContainer = ({
    children,
    direction = "horizontal",
    initialSlide = 0,
    virtual = "false",
    pagination = null,
    navigation = null,
    onSlideChange = (swiper) => {},
    onSlideChangeTransitionStart = (swiper) => {},
    enabled = true,
    autoHeight = false,
}) => {
    const swiperContainerEl = useRef(null);

    useEffect(() => {
        const element = swiperContainerEl.current;

        if (!element) {
            return;
        }

        element.addEventListener("swiperslidechange", onSlideChange);
        element.addEventListener("slideChangeTransitionStart", onSlideChangeTransitionStart);
        element.swiper.enabled = enabled;
        element.swiper.initialSlide = initialSlide;

        return () => {
            element.removeEventListener("swiperslidechange", onSlideChange);
            element.removeEventListener("slideChangeTransitionStart", onSlideChangeTransitionStart);
        }
    }, [onSlideChange, onSlideChangeTransitionStart, enabled, initialSlide]);

    return <swiper-container
        enabled={enabled}
        ref={swiperContainerEl}
        initial-slide={initialSlide}
        pagination={pagination}
        navigation={navigation}
        virtual={virtual}
        autoHeight={autoHeight}
        direction={direction}
    >
        {children}
    </swiper-container>
}