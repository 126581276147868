import React from "react";
import { Paper, Box, Skeleton, Typography } from "@mui/material";
import { TextSearcher } from "views/molecules/TextSearcher";
import { StillCourse } from "views/organisms/Allocation";

const UnAssignOrderList = ({
  onSearch,
  searchText,
  value,
  isLoadMore,
  isSkeletonLoading,
  showEmptyMessage,
  showCourse,
  handleLoadMore,
  hasNextPage,
  title,
  droppableId,
}) => {
  return (
    <Paper sx={{ height: "100%", p: 1 }}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box
          fontWeight="fontWeightMedium"
          fontSize="16px"
          textAlign="center"
          color="text.primary"
        >
          {title}
        </Box>
        <TextSearcher
          value={searchText}
          fieldSize="small"
          iconSize="small"
          onChangeSearch={onSearch}
        />
        <Box
          sx={{
            flexGrow: 1,
            mt: 1,
            width: "100%",
          }}
        >
          {showCourse && (
            <StillCourse
              hasNextPage={hasNextPage}
              handleLoadMore={handleLoadMore}
              value={value}
              query={searchText}
              droppableId={droppableId}
              loading={isLoadMore}
            />
          )}
          {showEmptyMessage && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 4,
                px: 3,
              }}
            >
              <Typography color="text.secondary">
                指定されたキーワードに一致する検索データはありません。
              </Typography>
            </Box>
          )}
          {isSkeletonLoading &&
            Array.from({ length: 3 }).map((_, index) => (
              <Skeleton
                key={index}
                sx={{
                  borderRadius: 1,
                  marginBottom: 2,
                }}
                variant="rectangular"
                width="100%"
                height="30%"
                animation="wave"
              />
            ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default UnAssignOrderList;
