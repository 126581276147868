import React from "react";
import Button from "@mui/material/Button";
import { HtmlTooltip } from "./styles";
/**
 * マークを表示するプレゼンテーションコンポーネントです。
 * @param {string} title Tooltip title
 * @param {JSX.Element} hildren What to display on the tooltip
 * @param {string} size Size of tooltip button 
 * @param {string} variant Button variant
 * @param {string} color Button color
 * @param {object} sx Button styole
 * @param {string} placement Tooltip placement
 * @returns {JSX.Element}
 */
export const Presententer = ({
  title,
  size,
  variant,
  color,
  sx,
  children,
  placement,
}) => {
  return (
    <HtmlTooltip
      placement={placement}
      title={<React.Fragment>{children}</React.Fragment>}
    >
      <Button size={size} variant={variant} color={color} sx={sx}>
        {title}
      </Button>
    </HtmlTooltip>
  );
};
