import { TimelineContent } from "@mui/lab";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import { Box, List } from "@mui/material";
import { Header } from "./Header";
import { Item } from "./Item";

/**
 * 回収を開始するリスト表示を行うコンテナプレゼンテーションです
 * @param {object} classes クラス名
 * @param {object} value コース情報
 * @param {number} index コースのインデックス
 * @param {func} onClickNumber　ナビモードのシートを開くか閉じるか設定
 * @param {object} openDialog　ダイアログの開閉状態
 * @param {func} onOpenDialog　回収詳細ダイアログを開く
 * @param {func} onCloseDialog　ダイアログを閉じる時
 * @param {object} collectItem 回収ポイント値
 * @param {object} naviModeItem　ナビモード値
 * @param {boolean} openNavi　ナビモードのシートの開閉状態
 * @param {func} onCloseNavi　ナビモードのシートが閉じる時
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box
      className={timelineItemClasses.root}
      sx={{
        display: "flex",
      }}
    >
      <Header
        index={props.index}
        value={props?.value}
        current={props.next === props.index}
        onClick={() => props.onClickNumber(props?.value?.id)}
      />
      <TimelineContent>
        <List
          dense={true}
          disablePadding={true}
          className={props.classes.collect_item}
        >
          <Item
            value={props?.value}
            name={props?.value?.workplace?.name}
            belongInCompanyName={props?.value?.workplace?.belongInCompanyName}
            onClick={() => props.onOpenDialog(props.index)}
          />
        </List>
      </TimelineContent>
    </Box>
  );
};
