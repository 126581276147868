import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { fetchWasteQuantityUnits } from "./api";

/*
object shape
  []
    code
    name
*/

const wasteQuantityUnitsSlice = createSlice({
  name: "wasteQuantityUnits",
  initialState: {
    wasteQuantityUnits: null,
    isLoading: false,
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWasteQuantityUnits.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchWasteQuantityUnits.fulfilled, (state, action) => {
        state.wasteQuantityUnits = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchWasteQuantityUnits.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

/**
 * ストアから廃棄物数量単位全体を取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.WasteQuantityUnits;

/**
 * ストアから廃棄物数量単位を取得します。
 */
export const wasteQuantityUnitsSelector = createSelector(
  selector,
  (wasteQuantityUnits) => wasteQuantityUnits.wasteQuantityUnits
);

/**
 * ストアから読み込みを取得します。
 */
export const isLoadingSelector = createSelector(
  selector,
  (wasteQuantityUnits) => wasteQuantityUnits.isLoading
);

/**
 * ストアからエラーを取得します。
 */
export const errorSelector = createSelector(
  selector,
  (wasteQuantityUnits) => wasteQuantityUnits.error
);

export default wasteQuantityUnitsSlice.reducer;
