import React from "react";
import { RenderButtonToolBar } from "utils/XgridExpand";
import { XGridComponent, FullScreenDialog } from "views/molecules";
import { WorkplaceForm } from "views/organisms/Masters/Workplace";
import { If } from "views/atoms";

/**
 * 事業場タブの表示を行うプレゼンテーションコンポーネントです
 * @param {object} value 事業者情報
 * @param {array} rows ターブルの内容
 * @param {array} columns テーブルのカラム
 * @param {func} onChangeSelection 行をクリックする時のイベント
 * @param {boolean} open ダイアログを開くイベント
 * @param {func} onCloseDialog ダイアログを開くイベント
 * @param {func} onOpenDialog ダイアログを開くイベント
 */
export const Presententer = (props) => (
  <If condition={!!props.value?.companyId}>
    <div>
      <XGridComponent
        onChangeSelection={props.onChangeSelection}
        checkboxSelection={false}
        multipleSelection={false}
        onDownload={true}
        actionButton={
          <RenderButtonToolBar
            label="新しい事業場を追加"
            onClick={props.onOpenDialog}
          />
        }
        {...props.xGridParams}
      />
      <FullScreenDialog
        formId={"workplace-form"}
        open={props.open}
        title={`新しい事業場の追加`}
        textConfirm={"登録"}
        onClose={props.onCloseDialog}
        isSubmit={props.isSubmit}
        disabled={props.isSubmit}
      >
        <WorkplaceForm id={"workplace-form"} onSubmit={props.submit} />
      </FullScreenDialog>
    </div>
  </If>
);
