import { debugLog } from "utils/log";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { basicSchema } from "./Validations/basic";
import { DateTime } from "luxon";

/**
 * 終了日を入力するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @fires Container#onConfirm 決定時
 * @fires Container#onCancel キャンセル時
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  onConfirm = (data) => debugLog(data),
  onCancel = () => debugLog("EndDateForm.onCancel"),
  ...props
}) => {
  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      ...{ date: value ? DateTime.fromISO(value).toJSDate() : new Date() },
    },
    resolver: yupResolver(basicSchema),
  });

  const handleConfirm = async () => {
    const result = await methods.trigger();
    if (result) {
      onConfirm(methods.getValues());
    }
  };

  useEffect(() => {
    methods.reset({
      ...{ date: value ? DateTime.fromISO(value).toJSDate() : new Date() },
    });
  }, [methods, value]);

  return render({
    value: value,
    onConfirm: handleConfirm,
    onCancel: onCancel,
    control: methods.control,
    setError: methods.setError,
    clearError: methods.clearErrors,
    ...props,
  });
};
