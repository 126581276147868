import { createSlice } from "@reduxjs/toolkit";
import { fetchWorkplaceCategories } from "./api";
import { createSelector } from "reselect";

const workplaceCategoriesSlice = createSlice({
  name: "workplaceCategories",
  initialState: {
    data: null,
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkplaceCategories.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchWorkplaceCategories.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchWorkplaceCategories.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

/**
 * ストアから事業場種類を取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.WorkplaceCategories;

/**
 * ストアから事業場種類を取得します。
 */
export const workplaceCategoriesSelector = createSelector(
  selector,
  (workplaceCategories) => workplaceCategories.data
);

/**
 * ストアから読み込みを取得します。
 */
export const isLoadingSelector = createSelector(
  selector,
  (workplaceCategories) => workplaceCategories.isLoading
);

/**
 * ストアからエラーを取得します。
 */
export const errorSelector = createSelector(
  selector,
  (workplaceCategories) => workplaceCategories.error
);

export default workplaceCategoriesSlice.reducer;
