import React from "react";
import PropTypes from "prop-types";

/**
 * ドットインジケーターのプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */

export const Presententer = ({ children, style, classes, showDot }) => {
  return (
    <div className={classes.onlineIndicatorContainer} style={style}>
      {children}
      {showDot ? <div className={classes.onlineIndicator} /> : null}
    </div>
  );
};

Presententer.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  showDot: PropTypes.bool,
  classes: PropTypes.object,
};
