import { useStyles } from "./styles";
import { useEffect } from "react";
import useClasses from "utils/useClasses";

/**
 * 車輛情報を表示するコンテナコンポーネントです
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  const classes = useClasses(useStyles);
  useEffect(() => {
    // todo: ギャラリー読み込み処理
  }, []);

  const handleSubmittedImage = (res) => {
    // todo: ギャラリー再読み込み処理
  };

  return render({
    classes: classes,
    value: value,
    submitImage: () => Promise.resolve(), // todo: ギャラリー登録API
    onSubmittedImage: handleSubmittedImage,
    ...props,
  });
};
