import yup, {
  textSchema,
  urlSchema,
  remarksSchema,
  dateSchema,
} from "utils/yup";

export const basicSchema = yup.object().shape({
  categories: yup
    .array()
    .of(
      yup.object().shape({
        code: yup.string(),
        name: yup.string(),
      })
    )
    .required(),
  name: textSchema.required(),
  representativeName: textSchema,
  corporateNumber: yup
    .string()
    .test("length", "13桁で入力してください。", function (value) {
      if (!value || value === "") {
        return true;
      }
      if (value.length !== 13) {
        return false;
      }
      return !isNaN(value);
    })
    .nullable(),
  establishmentedOn: dateSchema,
  url: urlSchema,
  headOffice: yup.object().shape().nullable(),
  remarks: remarksSchema,
});
