import { createSlice } from "@reduxjs/toolkit";
import { fetchCycleCategories } from "./api";
import { createSelector } from "reselect";

const cycleCategoriesSlice = createSlice({
  name: "cycleCategories",
  initialState: {
    data: null,
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCycleCategories.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchCycleCategories.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchCycleCategories.rejected, (state, action) => {
        state.error = action.error;
        state.isLoading = false;
      });
  },
});

/**
 * ストアから回収サイクル種類を取得します。
 * @param {object} store ストア
 * @returns {object}
 */
export const selector = (store) => store.CycleCategories;

/**
 * ストアから回収サイクル種類を取得します。
 */
export const cycleCategoriesSelector = createSelector(
  selector,
  (cycleCategories) => cycleCategories.data
);

/**
 * ストアから読み込みを取得します。
 */
export const isLoadingSelector = createSelector(
  selector,
  (cycleCategories) => cycleCategories.isLoading
);

/**
 * ストアからエラーを取得します。
 */
export const errorSelector = createSelector(
  selector,
  (cycleCategories) => cycleCategories.error
);

export default cycleCategoriesSlice.reducer;
