import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * コースのコンテナを表示するコンポーネントです。
 * @param {object} value コース情報
 */
export const Card = containerPresententer(Container, Presententer);
export const RenderCard = ({ data, index, style }) => {
  const item = data.items[index];
  return (
    <div style={{ ...style, paddingRight: "8px" }}>
      <Card value={item} index={index + 1} />
    </div>
  );
};