import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 回収詳細を入力するコンポーネントです。
 * @param {object} props プロパティ
 * @param {array} props.values 回収詳細
 * @param {number} props.index 回収詳細の番号
 * @param {boolean} props.open ダイアログの表示状態
 * @param {func} props.onSubmit 回収詳細を送信する関数
 * @param {func} props.onClose ダイアログを閉じる関数
 * @param {func} props.onNotificationChangeIndex インデックス変更通知
 * @returns {JSX.Element}
 */
export const CollectDetails = containerPresententer(Container, Presententer);
