import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 回収注意点情報の詳細を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 回収注意点情報
 * @param {func} props.onChangeTab タブ変更時の処理
 * @returns {JSX.Element}
 */
export const CollectionPrecautionDetailsTabs = containerPresententer(
  Container,
  Presententer
);
