import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Container, Grid, Paper } from "@mui/material";
import { companySelector } from "ducks/Company";
import { Selector as ContainerAdditionalTypesSelector } from "ducks/ContainerAdditionalTypes/Components";
import { useImperativeHandle, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { debugLog } from "utils/log";
import {
  DatePicker,
  InputField,
  InputFieldPopppSelect,
  ModalPopup,
  WorkplaceSelector
} from "views/molecules";
import { basicSchema } from "./Validations";

/**
 * コンテナ追加のステップ1を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @fires Step1Form#onSubmit 送信時
 * @fires Step1Form#onError エラー時
 * @returns {JSX.Element}
 */
export const Step1Form = ({
  value,
  onSubmit = (data) => debugLog("コンテナ追加のステップ1の送信：", data),
  onError = (err) => debugLog("コンテナ追加のステップ1のエラー：", err),
  ...props
}) => {
  const company = useSelector(companySelector);
  const [open, setOpen] = useState(false);
  const { control, trigger, formState, getValues } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...value,
    },
    resolver: yupResolver(basicSchema),
  });

  useImperativeHandle(props._ref, () => ({
    submit: () => {
      trigger().then((res) => {
        if (res) {
          onSubmit(getValues());
        } else {
          onError(formState.errors);
        }
      });
    },
  }));

  return (
    <Container maxWidth="md">
      <Paper
        sx={{
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="manageWorkplace"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <>
                  <InputFieldPopppSelect
                    label={"管理事業場"}
                    value={value}
                    onChange={onChange}
                    required={true}
                    placeholder={"管理事業場を選択します。"}
                    error={error}
                    onOpenModal={() => setOpen(true)}
                    textConfirm="選択"
                  />
                  <ModalPopup open={open} onClose={() => setOpen(false)}>
                    <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                      <WorkplaceSelector
                        value={value}
                        companyId={company.id}
                        onChange={(e) => {
                          onChange(e.value?.[0]);
                          setOpen(false);
                        }}
                      />
                    </Box>
                  </ModalPopup>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="additionalTypes"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <ContainerAdditionalTypesSelector
                  label="追加種別"
                  required={true}
                  value={value}
                  onChange={onChange}
                  error={error}
                  disabled={false}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="addedOn"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <DatePicker
                  label="追加日"
                  required={true}
                  value={value}
                  onChange={onChange}
                  error={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="additionsCount"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <InputField
                  label="追加数"
                  value={value}
                  placeholder="例) 10"
                  onChange={onChange}
                  required={true}
                  error={error}
                  type={"number"}
                  InputProps={{ inputProps: { min: 1, max: 99 } }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
