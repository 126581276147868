import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 車輛定期点検記録簿詳細を表示するコンポーネントです。
 */
export const PeriodicInspectionsDetails = containerPresententer(
  Container,
  Presententer
);
