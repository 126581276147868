import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  wrap_collect: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: "4px",
    border: "1px solid",
    borderColor: theme.palette.grey[200],
    height: "100%",
     "& .fc-media-screen": { height: "100%" },
    "& .fc-toolbar": {
      justifyContent: "center",
      "& button": {
        backgroundColor: "transparent !important",
        borderColor: "transparent !important",
        color: `${theme.palette.grey[600]} !important`,
        "&:focus": {
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: "none !important",
        },
      },
      "& .fc-toolbar-title": {
        fontSize: "1.1em",
        color: theme.palette.grey[700],
      },
    },
  },
}));
