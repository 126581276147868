import { onCloseDialog, onOpenDialog } from "ducks/Dialog/slice";
import { useDispatch, useSelector } from "react-redux";

/**
 * 引数を受けて、JSX.Elementを返します。
 * @param {object} props プロパティ
 * @callback render
 * @returns
 */
export const Container = ({ render, value, collectDefault, ...props }) => {
  const { isOpen } = useSelector((state) => state.Dialog);
  const dispatch = useDispatch();
  const open = isOpen.some((v) => v === "CollectEditForm");
  const handleOpen = () => {
    dispatch(onOpenDialog("CollectEditForm"));
  };
  const handleClose = () => {
    dispatch(onCloseDialog("CollectEditForm"));
  };

  const getDataSubmit = (params) => {
    console.log("params: ", params);
  };

  return render({
    value: value,
    collectDefault: collectDefault,

    onOpen: handleOpen,
    open: open,
    onClose: handleClose,
    getDataSubmit: getDataSubmit,
    ...props,
  });
};
