import { containerPresententer } from "utils/HoC";
import { Container as ContainerOfContainer } from "./Container";
import { Presententer } from "./Presententer";

/**
 * コンテナ管理画面を表示するコンポーネントです。
 */
export const Container = containerPresententer(
  ContainerOfContainer,
  Presententer
);
