import reducer from "./slice";
import {
  selector,
  industrySectorsSelector,
  isLoadingSelector,
  errorSelector,
} from "./slice";
import { fetchIndustrySectors } from "./api";

export default reducer;

export {
  selector,
  industrySectorsSelector,
  isLoadingSelector,
  errorSelector,
  fetchIndustrySectors,
};
