import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 事業者の廃棄物タブを表示するコンテナプレゼンテーションです
 * @param {object} value 廃棄物情報
 * @param {object} initialState
 */
export const WorkplaceTabWastes = containerPresententer(
  Container,
  Presententer
);
