import React from "react";
import { AuthenticatedTemplate } from "views/organisms";
import { CollectLists } from "views/templates/Mobile";

/**
 * モバイルの回収一覧の表示を行うプレゼンテーションコンポーネントです
 * @param {array} value 回収一覧のテストデータ
 * @returns
 */
export const Presententer = (props) => (
  <AuthenticatedTemplate>
    <CollectLists {...props} />
  </AuthenticatedTemplate>
);
