import { requiredField, style_inputField } from "utils/styles";

export const styles = ((theme) => ({
  ...style_inputField(theme),
  field: {
    width: 200,
  },
  label: {
    color: theme.palette.text.secondary,
  },
  required: {
    ...requiredField(theme),
  },
  button_search: {
    //    marginRight: theme.spacing(2),
  },
  error_message: {
    fontSize: "0.7rem",
    whiteSpace: "nowrap",
  },
}));
