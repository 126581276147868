import { Box, Button, Paper, Skeleton, Stack } from "@mui/material";
import { lightGreen, red } from "@mui/material/colors";
import { userSelector } from "ducks/User";
import { Form } from "features/MembersManage/Form";
import { RemoveDialog } from "features/MembersManage/RemoveDialog";
import { ResumeDialog } from "features/MembersManage/ResumeDialog";
import { StopDialog } from "features/MembersManage/StopDialog";
import { canControl } from "features/MembersManage/utils";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useOpener } from "utils/useOpener";
import { If, InformationItem, Mark } from "views/atoms";
import { FullScreenDialog } from "views/molecules";
import { DetailsFrame } from "views/organisms/DetailsFrame";
import { Caption } from "views/organisms/DetailsFrame/ChildComponents";

/**
 * メンバー管理詳細を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {object} props.form フォームの開閉
 * @param {boolean} props.loading 読み込み中
 * @fires Details#onUpdate 更新時
 * @returns {JSX.Element}
 */
export const Details = ({ value, form, loading, onUpdate, onRefetch }) => {
  const ref = useRef(null);
  const currentUser = useSelector(userSelector);
  const stopDialog = useOpener();
  const resumeDialog = useOpener();
  const removeDialog = useOpener();
  const navigate = useNavigate();

  const handleRemove = () => {
    removeDialog.toggle(false);

    navigate(
      {
        pathname: `/members`,
      },
      {
        replace: true,
      }
    );
  };

  return (
    <>
      <Box>
        <DetailsFrame
          caption={
            <Stack>
              <Caption title={value?.name ?? ""} />
              <If condition={value?.state === "active"}>
                <Mark text={"有効"} backgroundColor={lightGreen[500]} />
              </If>
              <If condition={value?.state === "suspend"}>
                <Mark text={"停止"} backgroundColor={red[500]} />
              </If>
            </Stack>
          }
          actions={
            <Stack spacing={2} direction="row">
              <If condition={canControl(currentUser, value)}>
                <If condition={value?.state === "active"}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => stopDialog.toggle(true)}
                    disabled={loading}
                  >
                    {"利用停止する"}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => form.toggle(true)}
                    disabled={loading}
                  >
                    {"編集する"}
                  </Button>
                </If>
                <If condition={value?.state === "suspend"}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => removeDialog.toggle(true)}
                    disabled={loading}
                  >
                    {"削除する"}
                  </Button>
                  <Button
                    color="success"
                    variant="contained"
                    onClick={() => resumeDialog.toggle(true)}
                    disabled={loading}
                  >
                    {"利用再開する"}
                  </Button>
                </If>
              </If>
            </Stack>
          }
        >
          <If
            condition={loading === true}
            elseComponent={
              <Paper sx={{ p: 2 }}>
                <InformationItem label="権限">
                  {value?.roleName ?? ""}
                </InformationItem>
                <InformationItem label="メールアドレス">
                  {value?.email ?? ""}
                </InformationItem>
              </Paper>
            }
          >
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
          </If>
        </DetailsFrame>
      </Box>
      <StopDialog
        value={value}
        open={stopDialog.open}
        onCompleted={() => {
          onRefetch && onRefetch();
        }}
        onClose={() => stopDialog.toggle(false)}
      />
      <ResumeDialog
        value={value}
        open={resumeDialog.open}
        onCompleted={() => {
          onRefetch && onRefetch();
        }}
        onClose={() => resumeDialog.toggle(false)}
      />
      <RemoveDialog
        value={value}
        open={removeDialog.open}
        onCompleted={handleRemove}
        onClose={() => removeDialog.toggle(false)}
      />
      <FullScreenDialog
        open={form.open}
        onClose={() => form.toggle(false)}
        title={`${value?.name}の編集`}
        textConfirm={"保存"}
        isSubmit={loading}
        disabled={loading}
        onClickSubmit={() => ref?.current?.submit?.()}
      >
        <Form
          value={value}
          onSubmit={onUpdate}
          disabled={loading}
          editMode={true}
          _ref={ref}
        />
      </FullScreenDialog>
    </>
  );
};
