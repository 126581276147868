import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";
import { DayOfWeek, Week } from "utils/Context";

/**
 * 回収サイクルを表示するコンポーネントです。
 */
export const CycleCategory = containerPresententer(Container, Presententer);

/**
 * 回収サイクルのテキストを取得します。
 * @param {object} value プロパティ
 * @returns {String}
 */
export const getText = (value) => {
  if (!value || !value?.cycleCategoryId) {
    return "";
  }

  switch (value.cycleCategoryId) {
    case "everyDay":
      return value?.cycleCategoryName;
    case "everyWeek":
    case "first":
    case "second":
    case "third":
    case "fourth":
    case "fifth":
      return `${value?.cycleCategoryName} ${value.weekDays
        .map(
          (weekday) => DayOfWeek.find((item) => item.code === weekday).shortName
        )
        .join("・")}`;
    case "everyOtherWeek":
      return `${value?.cycleCategoryName} ${value.weekDays
        .map(
          (weekday) => DayOfWeek.find((item) => item.code === weekday).shortName
        )
        .join("・")} ${value.weeks
        .map((week) => Week.find((item) => item.code === week).name)
        .join("・")}`;
    case "everyMonth":
      return `${value?.cycleCategoryName} ${value.weeks
        .map((week) => Week.find((item) => item.code === week).name)
        .join("・")}`;
    case "interval":
      return ` ${value.intervalDays} 日間隔`;
    case "customize":
      return ``;
    default:
      return ``;
  }
};
