import { getClient } from "api/graphql/queries";
import { useQuery } from "utils/useQuery";
import { useNavigate, useParams } from "react-router-dom";

/**
 * マスタ管理取引先の契約詳細の表示を行うコンテナコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const client = useQuery({
    query: getClient,
    variables: {
      id: id,
    },
    onError: () => {
      navigate(
        {
          pathname: `/NotFound`,
        },
        {
          replace: true,
        }
      );
    },
  });

  return render({
    client: client.getClient?.data,
    ...props,
  });
};
