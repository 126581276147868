import { searchCarryWorkJournalsByOffset } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { useCallback } from "react";
import { DateTime } from "luxon";

/**
 * モバイルの回収一覧を表示するコンテナコンポーネントです。
 * @callback render
 * @returns {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const search = useCallback((date) => {
    return API.graphql(
      graphqlOperation(searchCarryWorkJournalsByOffset, {
        filter: {
          date: { eq: DateTime.fromJSDate(date).toISODate() },
          assignedOnly: { eq: true },
        },
      })
    ).then((res) => {
      return res.data.searchCarryWorkJournalsByOffset;
    });
  }, []);

  return render({
    search: search,
    ...props,
  });
};
