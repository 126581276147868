import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Paper, Stack } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "views/atoms";
import { schema } from "./schema";

const defaultValues = {
  email: null,
  userAccountName: null,
};

/**
 * アカウント情報フォームです。
 * @param {object} props プロパティ
 * @param {object} props.value アカウント情報
 * @fires AccoutForm#onBack 戻る
 * @fires AcountForm#onNext 次へ
 * @returns {JSX.Element}
 */
export const AccoutForm = ({
  value = null,
  onBack = () => {},
  onNext = () => {},
}) => {
  const { control, getValues, trigger, formState } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      ...value,
    },
    resolver: yupResolver(schema),
  });

  const handleNext = async () => {
    const result = await trigger();

    if (result) {
      onNext({
        ...getValues(),
        departments: null,
        position: null,
        phone: null,
      });
    } else {
      console.log(formState.errors);
    }
  };

  return (
    <Paper
      sx={{
        p: 2,
      }}
    >
      <Stack spacing={2}>
        <Controller
          name={`userAccountName`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextField
                label="ユーザー名"
                replaceholder=""
                required={true}
                value={value}
                onChange={onChange}
                error={Boolean(error)}
                helperText={error?.message}
                fullWidth={true}
              />
            );
          }}
        />
        <Controller
          name={`email`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextField
                label="メールアドレス"
                required={true}
                value={value}
                onChange={onChange}
                error={Boolean(error)}
                helperText={error?.message}
                fullWidth={true}
              />
            );
          }}
        />
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Button
            variant="contained"
            color="inherit"
            onClick={() => onBack(getValues())}
          >
            {"戻る"}
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext}>
            {"次へ"}
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};
