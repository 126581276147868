import PropTypes from "prop-types";
import { debugLog } from "utils/log";
import { useStyles } from "./styles";
/**
 * 引数を受けてJSX Elemntを返します。
 * @callback render
 * @param {array} places 場所
 * @param {object} marker
 * @param {number} index
 * @param {number} noOfMarkers
 * @param {func} onClickMarker
 * @param {bool} isActive
 * @param {bool} animate
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({
  render,
  index,
  marker,
  noOfMarkers,
  isActive,
  animate,
  onClickMarker = (data) => debugLog("マーカーがクリックされた: ", data),
}) => {
  const classes = useStyles();
  return render({
    marker,
    index,
    classes,
    noOfMarkers,
    onClickMarker,
    isActive,
    animate,
  });
};

Container.propTypes  = {
  marker: PropTypes.object,
  index: PropTypes.number,
  noOfMarkers: PropTypes.number,
  onClickMarker: PropTypes.func,
  isActive: PropTypes.bool,
  animate: PropTypes.bool,
};
