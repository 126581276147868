import React from "react";
import { RenderButtonToolBar } from "utils/XgridExpand";
import { FullScreenDialog, XGridComponent } from "views/molecules";
import { ContractForm } from "../../../ContractForm";

/**
 * 事業場タブの表示を行うプレゼンテーションコンポーネントです
 * @param {object} value 事業者情報
 * @param {array} rows ターブルの内容
 * @param {array} columns テーブルのカラム
 * @param {func} onChangeSelection 行をクリックする時のイベント
 * @param {boolean} open ダイアログを開くイベント
 * @param {func} onCloseDialog ダイアログを開くイベント
 * @param {func} onOpenDialog ダイアログを開くイベント
 */
export const Presententer = (props) => (
  <div>
    <XGridComponent
      {...props.xGridParams}
      onChangeSelection={props.onChangeSelection}
      checkboxSelection={false}
      multipleSelection={false}
      onDownload={true}
      actionButton={
        <RenderButtonToolBar
          label="新しい契約を追加"
          onClick={props.onOpenDialog}
        />
      }
    />
    <FullScreenDialog
      open={props.open}
      onClose={props.onCloseDialog}
      title={`新しい契約の追加`}
      textConfirm="登録"
      formId="AddNewContract"
      isSubmit={props.isSubmit}
      disabled={props.isSubmit}
    >
      <ContractForm id={"AddNewContract"} value={props.formValue} onSubmit={props.onSubmit} />
    </FullScreenDialog>
  </div>
);
