import { useCallback, useEffect, useState, useMemo } from "react";
import { useParams } from "react-router";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { getCarryWorkJournal } from "api/graphql/queries";
import { toggle } from "ducks/Loading";
import { debugLog } from "utils/log";
import { useDispatch } from "react-redux";
import { DateTime } from "luxon";

const gridColumns = [
  { field: "courseName", headerName: "コース名", width: 90, hide: true },
  { field: "departureDate", headerName: "日付", width: 90, hide: true },
  { field: "assignedUsers", headerName: "ドライバー名", width: 90, hide: true },
  {
    field: "vehicleNo",
    headerName: "車輌 ",
    width: 90,
    hide: true,
  },
  {
    field: "pointNo",
    headerName: "回収番号",
    width: 100,
  },
  {
    field: "discharger",
    headerName: "排出事業者",
    width: 200,
  },
  {
    field: "dischargeSite",
    headerName: "排出事業場",
    width: 200,
  },
  {
    field: "waste",
    headerName: "廃棄物",
    width: 200,
  },
  {
    field: "recordQuantity",
    headerName: "回収記録数量",
    width: 110,
  },
  {
    field: "quantityInKg",
    headerName: "回収量(kg)",
    width: 110,
  },
  {
    field: "recordRemarks",
    headerName: "回収記録備考",
    width: 110,
    valueFormatter: (params) => {
      return params.value ? params.value?.split("\n").join(" ") : "";
    },
  },
  // {
  //   field: "arrivedAt",
  //   headerName: "到着時間",
  //   width: 140,
  //   valueFormatter: (params) => {
  //     return params.value
  //       ? DateTime.fromISO(params.value, { setZone: "Asia/Tokyo" }).toFormat(
  //           "yyyy/MM/dd HH:mm"
  //         )
  //       : "";
  //   },
  // },
  // {
  //   field: "departedAt",
  //   headerName: "出発時間",
  //   width: 140,
  //   valueFormatter: (params) => {
  //     return params.value
  //       ? DateTime.fromISO(params.value, { setZone: "Asia/Tokyo" }).toFormat(
  //           "yyyy/MM/dd HH:mm"
  //         )
  //       : "";
  //   },
  // },
  {
    field: "recordedAt",
    headerName: "回収記録日時",
    width: 140,
    valueFormatter: (params) => {
      return params.value
        ? DateTime.fromISO(params.value, { setZone: "Asia/Tokyo" }).toFormat(
            "yyyy/MM/dd HH:mm"
          )
        : "";
    },
  },
  {
    field: "updatedAt",
    headerName: "最終更新日時",
    width: 140,
    valueFormatter: (params) => {
      return params.value
        ? DateTime.fromISO(params.value, { setZone: "Asia/Tokyo" }).toFormat(
            "yyyy/MM/dd HH:mm"
          )
        : "";
    },
  },
  {
    field: "mileage",
    headerName: "走行距離",
    width: 140,
  },
  {
    field: "address",
    headerName: "住所",
    width: 500,
  },
];

const getWasteFullName = (waste) => {
  if (waste == null) {
    return "";
  }
  const format = (wasteClassName) =>
    !waste.name ? wasteClassName : `[${wasteClassName}] ${waste.name}`.trim();
  const { largeClass, middleClass, smallClass } = waste.type;
  return smallClass.name !== ""
    ? format(smallClass.name)
    : middleClass.name !== ""
    ? format(middleClass.name)
    : format(largeClass.name);
};

const getOdometers = ({ instruments }, isHidden = false) => {
  if (
    !!instruments &&
    !!instruments.find((instrument) => instrument.type === "Start")?.odometer &&
    !!instruments.find((instrument) => instrument.type === "Finish")?.odometer
  ) {
    if (isHidden) {
      return `${
        instruments.find((instrument) => instrument.type === "Finish")
          .odometer -
        instruments.find((instrument) => instrument.type === "Start").odometer
      }km`;
    }
    return `  [走行距離：${
      instruments.find((instrument) => instrument.type === "Finish").odometer -
      instruments.find((instrument) => instrument.type === "Start").odometer
    }Km]`;
  }

  return "";
};

const convertToRows = (journal) => {
  const mileage = journal?.instruments?.length
    ? getOdometers(journal, true)
    : null;
  return (
    journal?.items
      ?.flatMap((item, itemIndex) =>
        item.tasks.map((task) => ({
          id: task.id,
          pointId: item.id,
          discharger: item.workplace.belongInCompanyName,
          dischargeSite: item.workplace.name,
          arrivedAt: item.passedRecord?.arrivedAt ?? null,
          departedAt: item.passedRecord?.departedAt ?? null,
          waste: getWasteFullName(task.waste),
          recordQuantity:
            task.record == null
              ? ""
              : `${task.record?.quantity}${task.record?.quantityUnit?.name}`,
          quantityInKg: task.record
            ? task.record?.weightOfKg
              ? task.record?.weightOfKg + "kg"
              : null
            : null,
          mileage: mileage,
          recordRemarks: task.record?.remarks ?? "",
          recordedAt: task.record?.createdAt ?? null,
          updatedAt: task.record?.updatedAt ?? null,
          address: `〒${
            item.workplace?.postalCode +
            " " +
            item.workplace?.prefectures.name +
            item.workplace?.city +
            item.workplace?.streetAddress +
            item.workplace?.otherAddress
          }`,
          courseName: journal.name,
          departureDate: DateTime.fromFormat(
            journal.departureDate,
            "yyyy-MM-dd"
          )
            .setLocale("jp-JP")
            .toFormat("yyyy/MM/dd"),
          assignedUsers:
            journal.assignedUsers?.map((item) => item.name)?.join(",") || "",
          vehicleNo: journal.assignedVehicle?.number,
        }))
      )
      ?.map((item, index) => {
        return { ...item, pointNo: index + 1 };
      }) ?? []
  );
};

/**
 * 引数を受けて、JSX.Elementを返します。
 * @param {object} props プロパティ
 * @callback render
 * @returns
 */
export const Container = ({ render, ...props }) => {
  const [journal, setJournal] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();

  const memoizedRows = useMemo(() => convertToRows(journal), [journal]);

  const load = useCallback(() => {
    dispatch(toggle(true));
    setJournal(null);
    API.graphql(
      graphqlOperation(getCarryWorkJournal, {
        id: id,
      })
    )
      .then((res) => {
        setJournal(res.data.getCarryWorkJournal);
      })
      .catch((err) => {
        debugLog("情報の取得に失敗: ", err);
      })
      .finally(() => {
        dispatch(toggle(false));
      });
  }, [id, dispatch]);

  useEffect(() => {
    load();
  }, [load]);

  const handleChangeQuantity = () => {
    load();
  };

  const onSelectedRow = (params) => {
    const selectedRow = memoizedRows.find((row) => row.id === params[0]);
    setSelectedPoint(
      journal.items.find((item) => item.id === selectedRow.pointId)
    );

    setSelectedValue({
      item: journal.items.find((item) => item.id === selectedRow.pointId),
      task: journal.items
        .find((item) => item.id === selectedRow.pointId)
        .tasks.find((task) => task.id === params[0]),
    });
  };

  const onExportCsv = () => {
    const gridApi = apiRef.current;
    if (gridApi) {
      gridApi.exportDataAsCsv({
        fileName: `${journal.name}-file`,
        allColumns: true,
        includeHeaders: true,
        delimiter: ",",
        utf8WithBom: true,
      });
    }
  };

  return render({
    journal: journal,
    gridColumns: gridColumns,
    gridRows: memoizedRows,
    selectedPoint: selectedPoint,
    isOpenRecordDialog: selectedPoint !== null,
    onSelectedRow: onSelectedRow,
    onCloseRecordDialog: () => setSelectedPoint(null),
    selectedValue: selectedValue,
    onCloseForm: () => setSelectedValue(null),
    onChangeQuantity: handleChangeQuantity,
    onExportCsv: onExportCsv,
    apiRef: apiRef,
    getOdometers: getOdometers,
    ...props,
  });
};
