import React from "react";
import { InformationItem } from "views/atoms";

/**
 * 基本情報のタブの表示を行うプレゼンテーションコンポーネントです。
 * @returns {JSX.Element}
 */
export const Presententer = (props) => (
  <div>
    <InformationItem label="固有コード">
      {props.value?.code ?? ""}
    </InformationItem>
    <InformationItem label="業種">
      {props.value?.industrySector?.subClass?.name ||
        props.value?.industrySector?.smallClass?.name ||
        props.value?.industrySector?.middleClass?.name ||
        props.value?.industrySector?.largeClass?.name ||
        ""}
    </InformationItem>
    <InformationItem label="名称">{props.value?.name ?? ""}</InformationItem>
    <InformationItem label="所在地">
      {props.value?.address ?? ""}
    </InformationItem>
    <InformationItem label="電話番号">
      {props.value?.phone ?? ""}
    </InformationItem>
    <InformationItem label="FAX番号">{props.value?.fax ?? ""}</InformationItem>
    <InformationItem label="メールアドレス">
      {props.value?.email ?? ""}
    </InformationItem>
    <InformationItem label="特記事項">
      {props.value?.remarks ?? ""}
    </InformationItem>
  </div>
);
