import { PhoneNumberFormatUtil } from "utils/format";

/**
 * 処理フロー最終処分事業場情報をタブ形式で表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {object} value 値
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({ render, value, ...props }) => {
  const columns = [
    {
      field: "name",
      headerName: "名称",
      width: 350,
    },
    {
      field: "address",
      headerName: "名称",
      width: 500,
      renderCell: (params) => {
        const { row } = params;
        return `〒${row.postalCode} ${row.prefectures.name}${row.city}${row.streetAddress}${row.otherAddress}`;
      },
    },
    {
      field: "phone",
      headerName: "電話",
      width: 200,
      renderCell: (params) => {
        const { row } = params;
        return PhoneNumberFormatUtil.formatNational(row.phone);
      },
    },
  ];

  return render({
    value: value,
    columns: columns,
    ...props,
  });
};
