import React from "react";
import { FormProvider } from "react-hook-form";
import { Grid, Box, Paper, Container } from "@mui/material";
import { Controller } from "react-hook-form";
import {
  SelectPopup,
  InputField,
  DatePicker,
  InputFieldPopppSelect,
  ClientWorkplaceSelector,
  ModalPopup,
} from "views/molecules";

/**
 * 取引先の登録・編集を行うのフォームを表示するプレゼンテーションコンポーネントです。
 * @param {object} props ポロパティ
 * @param {object} control　reactのcontrol
 * @param {array} truckType 車種
 * @param {func} onOpenModal 事業場の種類
 * @returns {JSX.Elemnt} JSX 要素
 */
export const Presententer = (props) => {
  return (
    <form onSubmit={props.submit} id={props.id} noValidate>
      <FormProvider {...props.methods}>
        <Container maxWidth="md">
          <Paper
            elevation={3}
            sx={{
              padding: 5,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="categories"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <SelectPopup
                        label="事業者区分"
                        required={true}
                        value={value}
                        onChange={onChange}
                        options={props.ComanyCategories}
                        error={error}
                        multiple={true}
                        optionValueField="id"
                        optionNameField="name"
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <InputField
                      label="名称"
                      required={true}
                      placeholder="事業者の名称を入力します。"
                      value={value}
                      onChange={onChange}
                      error={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="corporateNumber"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <InputField
                      label="法人番号"
                      placeholder="法人番号を入力します。"
                      value={value}
                      onChange={(e) => {
                        const result = e.target.value;
                        const pattern = new RegExp(/^\d*$/);
                        if ((result && pattern.test(result)) || !result) {
                          onChange(e.target.value);
                        }
                      }}
                      classOverride={props.classes.corporationNumber}
                      error={error}
                      inputProps={{
                        maxLength: 13,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="representativeName"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <InputField
                      label="代表者"
                      required={false}
                      value={value}
                      onChange={onChange}
                      error={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="establishmentedOn"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <DatePicker
                        label="設立日"
                        placeholder="例）2021/06/06"
                        value={value}
                        onChange={onChange}
                        error={error}
                        required={false}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="url"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <InputField
                      label="企業ホームページ"
                      placeholder="例）https://ecpope-pro.co.jp"
                      value={value}
                      onChange={onChange}
                      error={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="remarks"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <InputField
                      label="企業概要"
                      placeholder="企業概要を入力します。"
                      multiline
                      rows={4}
                      value={value}
                      onChange={onChange}
                      error={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="headOfficeWorkplace"
                  control={props.control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <>
                        <InputFieldPopppSelect
                          label="本社事業場"
                          required={false}
                          value={value}
                          onChange={onChange}
                          onOpenModal={props.onOpen}
                          error={Boolean(error?.message)}
                          helperText={error?.message}
                        />
                        <ModalPopup open={props.open} onClose={props.onClose}>
                          <Box pt={3} pb={3} sx={{ height: "85vh" }}>
                            <ClientWorkplaceSelector
                              autoHeight={false}
                              onSelected={onChange}
                              value={value}
                              onClose={props.onClose}
                              fixedOptions={{
                                other: {
                                  belongInCompanyId: props.companyId,
                                },
                              }}
                              visibleCompanyName={false}
                            />
                          </Box>
                        </ModalPopup>
                      </>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </FormProvider>
    </form>
  );
};
