import yup, { dateSchema, remarksSchema } from "utils/yup";

export const basicSchema = yup.object().shape({
  inspectionCompany: yup.object().nullable().required(),
  inspectionWorkplace: yup.object().nullable().required(),
  receivedOn: dateSchema.required(),
  completedOn: dateSchema.required(),
  result: yup.string().required(),
  expiredOn: dateSchema.required(),
  price: yup.number().integer().positive().nullable().required(),
  remarks: remarksSchema,
  files: yup.array(),
});
