import { InformationItem } from "views/atoms";

/**
 * 処理フロー基本情報をタブ形式で表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <>
      <InformationItem label="管理番号">
        {props.value?.number ?? ""}
      </InformationItem>
      <InformationItem label="名称">{props.value?.name ?? ""}</InformationItem>
      <InformationItem label="廃棄物の種類">
        {props.value?.targetWasteType?.smallClass?.name ||
          props.value?.targetWasteType?.middleClass?.name ||
          props.value?.targetWasteType?.largeClass?.name ||
          props.value?.targetWasteType?.group?.name ||
          ""}
      </InformationItem>
    </>
  );
};
