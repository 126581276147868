import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";
/**
 * マスタ管理の車輛一覧の画面を表示するコンポーネントです。
 * @param {object} dataTable テーブルのデータ
 * @param {object} onTransite 詳細ページに移動する処理
 * @param {func} submit 車輛追加フォームをサブミットする
 * @param {func} onSubmitted 車輛追加フォームの値を取得します。
 */
export const VehicleList = containerPresententer(Container, Presententer);
