import { onCloseDialog } from "ducks/Dialog/slice";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "./styles";

/**
 * 引数を受けてJSX Elemntを返します。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({
  render,
  onClick,
  message,

  request,
  ...props
}) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.Dialog);
  const open = isOpen.some((v) => v === request?.id);

  const handleClick = () => {
    onClick && onClick(request);
    dispatch(onCloseDialog(`${request?.id}`));
  };

  return render({
    classes: classes,
    onClick: handleClick,
    message: message,
    open: open,

    ...props,
  });
};
