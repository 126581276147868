import { PlaylistAddCheckOutlined } from "@mui/icons-material";
import { Box, Link } from "@mui/material";

/**
 * ナビモードの詳細の表示を行うコンテナプレゼンテーションです
 * @param {object} value
 * @returns
 */
export const Presententer = (props) => {
  return (
    <>
      <Box className={props.classes.content}>
        {props.value?.tasks?.map((task, index) => {
          return (
            <Box className={props.classes.text} key={index}>
              <span>
                <span className={"label"}>廃棄物</span>
                {task?.waste?.name ||
                  task?.waste?.type?.smallClass?.name ||
                  task?.waste?.type?.middleClass?.name ||
                  task?.waste?.type?.largeClass?.name ||
                  ""}
              </span>
              <span>
                <span className={"label"}>排出量</span>
                {`${task?.wasteQuantity}${task?.waste?.quantityUnit?.name}`}
              </span>
              <span>
                <span className={"label"}>備考</span>
                <div style={{ whiteSpace: "pre-wrap" }}>{task?.remarks}</div>
              </span>
            </Box>
          );
        })}
      </Box>
      <Box className={props.classes.content}>
        <Box className={props.classes.text}>
          <span>
            <span className={"label"}>住所</span>
            {`〒${props.value?.workplace?.postalCode ?? ""} ${
              props.value?.workplace?.prefectures?.name ?? ""
            }${props.value?.workplace?.city ?? ""}${
              props.value?.workplace?.streetAddress ?? ""
            }${props.value?.workplace?.otherAddress ?? ""}`}
          </span>
          <span>
            <span className={"label"}>電話</span>
            <Link
              underline="always"
              color="textPrimary"
              href={`tel:${props.value?.workplace?.phone ?? ""}`}
            >
              {props.value?.workplace?.phone ?? ""}
            </Link>
          </span>
        </Box>
        <Box className={props.classes.text} mt={2}>
          <span>
            <span className={"label"}>情報</span>
          </span>
          <span>
            <span className={"label icon"}>
              <PlaylistAddCheckOutlined fontSize="small" color="primary" />
            </span>
            {props.value?.workplace?.remarks}
          </span>
        </Box>
      </Box>
    </>
  );
};
