import { AccessTimeOutlined } from "@mui/icons-material";
import { Avatar, Box, Typography } from "@mui/material";
import React from "react";

/**
 * コースのコンテナの表示を行うコンテナプレゼンテーションです
 * @param {object} classes クラス名
 * @param {object} value　コースのヘッダー
 * @returns
 */

export const Presententer = (props) => {
  return (
    <Box
      sx={{
        borderRadius: "4px",
        border: (theme) => `1px solid${theme.palette.grey[500]}`,
        bgcolor: "#fff",
      }}
      p={1}
      mb={1}
    >
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Avatar
          sx={{
            width: "20px",
            height: "20px",
            fontSize: "12px",
            fontWeight: "bold",
            bgcolor: (theme) => theme.palette.grey[900],
          }}
        >
          {props?.index}
        </Avatar>
        <Typography
          sx={{
            fontSize: "13px",
            color: (theme) => theme.palette.grey[500],
            display: "flex",
            alignItems: "center",
          }}
        >
          <AccessTimeOutlined fontSize="small" sx={{ marginRight: "4px" }} />
          {props?.value?.times?.time}
        </Typography>
      </Box>
      <Typography
        sx={{ color: (theme) => theme.palette.grey[800], fontSize: "15px" }}
      >
        {props?.value?.name}
      </Typography>
      <Typography
        sx={{ color: (theme) => theme.palette.grey[500], fontSize: "14px" }}
      >
        {props?.value?.productField}
      </Typography>
    </Box>
  );
};