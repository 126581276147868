import { Box } from "@mui/material";
import React from "react";
import { CollectPoints, Header } from "./ChildComponents";

/**
 * 1コースの表示を行うプレゼンテーションコンポーネントです
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        marginRight: (theme) => theme.spacing(2),
        padding: (theme) => theme.spacing(1),
      }}
    >
      <Header value={props.value} />
      <CollectPoints
        value={props.value?.points}
        height={props.height}
        width={props.width}
      />
    </Box>
  );
};
