import { useMemo, useState } from "react";
import { debugLog } from "utils/log";
import { Marker } from "@react-google-maps/api";
import {
  includesTasksNotEnterd,
  isAllTaskCompleted,
} from "views/templates/Mobile/Collect/Utils";
import { createTheme } from "@mui/material";
const theme = createTheme();

const clusterStyles = {
  textColor: "white",
  url: `${window.location.origin}/logo.png`,
  height: 150,
  width: 150,
};
const options = {
  gridSize: 50,
  styles: clusterStyles,
  maxZoom: 15,
};

const mainColor_02 = "#2196f3";
const mainColor = theme.palette.primary.main;
/**
 * アイコンや場所情報を表示するコンポーネントです
 * @param {array} value ポイントのマーカー
 * @param {func} onClickMarker ポイントのマーカーをクリックするイベント
 * @param {object} currentLocation 現在地の経度、緯度
 * @param {object} nextDestination — 次の目的地
 */
export const Markers = ({
  value = [],
  onClickMarker = (data) => debugLog("マーカーがクリックされた: ", data),
  onClickCurrent = (data) =>
    debugLog("現在地のマーカーがクリックされた: ", data),
  current,
  nextDestination,
  animation,
}) => {
  const [activeMarkerId, setActiveMarkerId] = useState(null);
  const markerSmall = useMemo(
    () => ({
      size: new window.google.maps.Size(45, 45),
      anchor: new window.google.maps.Point(28, 33),
      scaledSize: new window.google.maps.Size(50, 50),
      labelOrigin: new window.google.maps.Point(24, 19),
      labelSize: "20px",
    }),
    []
  );

  const markerLarg = useMemo(
    () => ({
      size: new window.google.maps.Size(73, 73),
      scaledSize: new window.google.maps.Size(80, 80),
      anchor: new window.google.maps.Point(43, 60),
      labelOrigin: new window.google.maps.Point(40, 32),
      labelSize: "30px",
    }),
    []
  );

  // // マーカーをクリックするイベント
  const handleClickMarker = (id) => {
    setActiveMarkerId(id);
    onClickMarker(id);
  };

  if (!value?.length) return null;

  return (
    <>
      {value?.map((marker, index) => {
        if (marker.lat && marker.lng)
          return (
            <Marker
              key={index}
              position={{
                lat: marker?.lat,
                lng: marker?.lng,
              }}
              options={options}
              animation={window.google.maps.Animation.DROP}
              icon={{
                url: `${
                  (isAllTaskCompleted(marker.tasks) &&
                    "/marker_collected_bg.png") ||
                  (includesTasksNotEnterd(marker.tasks) &&
                    "/marker_region_in_bg.png") ||
                  (marker.id === nextDestination?.id &&
                    "/marker_next_bg.png") ||
                  (marker.completed === "3" && "/marker_not_collect_bg.png") ||
                  "/marker_region_in_bg.png"
                }`,
                origin: new window.google.maps.Point(0, 0),
                ...(marker.id === activeMarkerId ? markerLarg : markerSmall),
              }}
              label={{
                text: `${index + 1}`,
                className: "label_google",
                color: `${
                  (isAllTaskCompleted(marker.tasks) &&
                    `${theme.palette.grey[500]}`) ||
                  (includesTasksNotEnterd(marker.tasks) && `${mainColor}`) ||
                  (marker.id === nextDestination?.id && "#fff") ||
                  (marker.completed === "3" && `${mainColor_02}`) ||
                  `${mainColor}`
                }`,
                fontSize:
                  marker.id === activeMarkerId
                    ? `${markerLarg.labelSize}`
                    : `${markerSmall.labelSize}`,
              }}
              zIndex={
                nextDestination?.id === marker.id
                  ? value?.length + 1
                  : index + 1
              }
              onClick={() => handleClickMarker(marker.id, index)}
            />
          );
        return null;
      })}
      {current && (
        <Marker
          position={current}
          animation={animation}
          onClick={onClickCurrent}
        />
      )}
    </>
  );
};
