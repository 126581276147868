/**
 * 引数を受けてJSX Elemntを返します。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({
  render,
  value,
  open = false,
  onClose,
  ...props
}) => {
  return render({
    value: value,
    open: open,
    onClose: onClose,
    ...props,
  });
};
