import { SelectPopup } from "views/molecules";
import { rolesSelector, isLoadingSelector, errorSelector } from "ducks/Roles";
import { useSelector } from "react-redux";
import { Skeleton, Typography } from "@mui/material";
import { userSelector } from "ducks/User";
import { fetchRemoveRoleNames } from "../utils";

/**
 * 権限を選択するコンポーネントです。
 * @param {object} props プロパティ
 * @param {object} props.value 値
 * @param {object} props.error エラーオブジェクト
 * @param {boolean} props.disabled 無効設定
 * @fires RoleSelector#onChange 変更時
 * @returns {JSX.Element}
 */
export const RoleSelector = ({
  value,
  onChange = () => {},
  error,
  disabled = false,
}) => {
  const currentUser = useSelector(userSelector);
  const removeRoleNames = fetchRemoveRoleNames(currentUser?.roleName);
  const roles = useSelector(rolesSelector);
  const isLoading = useSelector(isLoadingSelector);
  const errors = useSelector(errorSelector);

  if (isLoading === true) {
    return <Skeleton />;
  }

  if (!!errors) {
    return <Typography color="error.main">エラーが発生しました。</Typography>;
  }

  const _options = roles.filter((role) =>
    removeRoleNames.length <= 0
      ? true
      : !removeRoleNames.some((name) => name === role.name)
  );

  return (
    <SelectPopup
      disabled={disabled}
      label="権限"
      required={true}
      value={value ?? null}
      onChange={onChange}
      error={error}
      options={_options ?? []}
      optionValueField="id"
      optionNameField="name"
    />
  );
};
