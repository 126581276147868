import { makeStyles } from "@mui/styles";

export const stylesCreator = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontSize: "15px",
  },
  container: {
    marginTop: theme.spacing(12),
  },
  appBar: {},
  appBarSpacer: theme.mixins.toolbar,
  dialogPaperStyles: {
    "& .main_main_container": {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
  },
  [theme.breakpoints.down("sm")]: {
    dialogPaperStyles: {
      "& .main_main_container": {
        height: "100%",
        marginTop: 0,
        marginBottom: 0,
        padding: 0,
        overflowY: "auto",
      },
    },
  },
  [theme.breakpoints.up("sm")]: {
    dialogPaperStyles: {
      "& .main_main_container": {
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
  },
}));
