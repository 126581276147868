import { Typography } from "@mui/material";
import {
  NearMeOutlined,
  OutlinedFlagOutlined,
  PauseCircleFilledOutlined,
} from "@mui/icons-material";
import { AppBar, Box, Button } from "@mui/material";
import clsx from "clsx";
import { OdometerRecordForm } from "features/Vehicles/Instruments/OdometerRecordForm";
import { Alert } from "views/molecules";
import {
  ArrivalScheduleAlert,
  CollectBottomSheet,
  GoogleMapApi,
} from "views/organisms/Mobile/Collect";
import { NaviMode } from "views/organisms/Mobile/Collect/NaviMode";
import { isAllTaskCompleted } from "views/templates/Mobile/Collect/Utils";
import { StrictIf } from "views/atoms";

/**
 * ヘッダーコンポーネントです。
 * @param {object} classes クラス名
 * @param {string} name 回収コース名
 * @param {array} items 回収ポイント
 * @param {string} message メッセージ
 * @returns {JSX.Element}
 */
const Header = ({ classes, name, items, message }) => {
  return (
    <Box className={classes.header}>
      <AppBar
        position="fixed"
        color="primary"
        className={classes.appBar}
        elevation={0}
      >
        <Box className={classes.header_name}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {name && <span>{name}</span>}
            <span>
              {`${
                items?.filter((item) => isAllTaskCompleted(item.tasks))
                  ?.length ?? "0"
              }/${items?.length ?? "0"}`}
            </span>
          </Box>
          {/* TODO: メッセージの確認 */}
          <Box>
            {message && <Typography>{message}</Typography>}
          </Box>
        </Box>
      </AppBar>
      <div className={classes.toolbar} />
    </Box>
  );
};

/**
 * 回収の開始の表示を行うコンテナコンポーネントです
 * @param {object} classes クラス名
 * @param {object} value　回収コース情報
 * @param {array} collects　回収リスト情報
 * @param {boolean} open  シートをオーブンかオフにする
 * @param {func} onClose　シートを閉じる
 * @param {array} collectRoute　フッターボタンのルート
 * @param {array} collects　回収ポイントをマップで表示します
 * @param {func} onClickGetPoint マップでのマーカー。回収リストのナンバーをクリックするイベント
 * @param {object} markerCenter マップで中心にするポイント
 * @param {array} markerPoints 回収ポイントの配列
 * @returns
 */
export const Presententer = (props) => {
  return (
    <>
      <Box
        className={props.classes.collect_start_wrap}
        sx={{
          WebkitUserSelect: "none",
          height: `${props.screenHeight}px`,
        }}
      >
        <Header
          classes={props.classes}
          name={props?.value?.name}
          items={props?.value?.items}
          message={props?.message?.value}
        />
        <Box flex={1} className={props.classes.content}>
          <Box className="alertAndNotifications">
            <ArrivalScheduleAlert value={props?.timeArrival} />
          </Box>
          {props.current && (
            <GoogleMapApi
              center={props.markerCenter}
              current={props.current}
              onClickMarker={props.onClickGetPoint}
              markerPoints={props.value?.items}
              nextDestination={props.nextPoint}
              isLoaded={props.isLoaded}
              loadError={props.loadError}
              mapStyleParams={{
                style: 2,
              }}
            />
          )}
          {!props.odometerForm?.open && !props.openNavi && props.open && (
            <CollectBottomSheet
              value={props.value?.items}
              open={props.open}
              onClose={props.onClose}
              onClickNumber={props.onClickGetPoint}
              onRecordTask={props.onRecordTask}
              onSort={props.onSortTasks}
              next={props.naviModeItem?.index ?? 0}
            />
          )}
        </Box>
        <Box className={props.classes.footer}>
          <AppBar
            position="fixed"
            color="transparent"
            className={clsx(props.classes.appBar, props.classes.appBar_footer)}
            elevation={0}
          >
            <Button
              disableElevation={true}
              className={"buttonMenuMobile"}
              color="inherit"
              onClick={props.onBack}
              disabled={!props.value}
              startIcon={<PauseCircleFilledOutlined />}
            >
              <StrictIf
                target={props.value?.items}
                condition={props.value?.items?.filter((item) => isAllTaskCompleted(item.tasks))?.length !== props.value?.items?.length}
                whenTrueComponent={<>{"中断"}</>}
                whenFalseComponent={<>{"完了"}</>}
              />
            </Button>
            <Button
              disableElevation={true}
              className={"buttonMenuMobile"}
              color="inherit"
              onClick={props.onCurrentLocation}
              disabled={!props.value}
              startIcon={<NearMeOutlined />}
            >
              {"現在地"}
            </Button>
            <Button
              disableElevation={true}
              className={"buttonMenuMobile"}
              color="inherit"
              onClick={props.onClickCourse}
              disabled={!props.value}
              startIcon={<OutlinedFlagOutlined />}
            >
              {"コース"}
            </Button>
          </AppBar>
          <div className={props.classes.toolbar_footer} />
        </Box>
        {!props.odometerForm?.open && props.openNavi && !props.open && (
          <NaviMode
            open={props.openNavi}
            onClose={props.onCloseNaviMode}
            value={props.naviModeItem}
            number={props?.naviModeItem?.index + 1}
            scheduledTime={null}
          />
        )}
        <Alert />
      </Box>
      {props.odometerForm?.open && (
        <OdometerRecordForm
          journalId={props.value?.id}
          type={
            props.value?.items?.filter((item) => isAllTaskCompleted(item.tasks))
              ?.length !== props.value?.items?.length
              ? "Interruption"
              : "Finish"
          }
          value={props?.managementVehicle?.odometer}
          open={props.odometerForm?.open}
          onClose={() => props.odometerForm?.toggle(false)}
          onCompleted={props.onCompletedUpdateOdometer}
          onSkiped={props.onSkiped}
        />
      )}
    </>
  );
};
