// 受注
export const OrderGuiderSteps = [
  {
    element: ".orderSpot",
    position: "bottom",
    intro: "事業者情報",
  },
  {
    element: ".orderRegularly",
    position: "bottom",
    intro: "事業者・事業場情報",
  },
];
// マスタの初心者
export const MasterGuiderSteps = [
  {
    element: ".corporation",
    position: "bottom",
    intro: "事業者情報",
  },
  {
    element: ".workplace",
    position: "bottom",
    intro: "事業者・事業場情報",
  },
  {
    element: ".license",
    position: "bottom",
    intro: "許可書情報",
  },
  {
    element: ".vehicle",
    position: "bottom",
    intro: "車両情報",
  },
  {
    element: ".flow",
    position: "bottom",
    intro: "処理フロー情報",
  },
  {
    element: ".partner",
    position: "bottom",
    intro: "取引先情報",
  },
];

// マスタの事業場の初心者
export const WorkplaceGuiderSteps = [
  {
    element: ".workplaceTableButton",
    position: "bottom",
    intro: "新しい事業所・事業場を登録します",
  },

  {
    element: ".guiderXGridExport",
    position: "bottom",
    intro: "CSVファイルをダウンロードします。",
  },
  {
    element: ".guiderXGridFilter",
    position: "bottom",
    intro: "行を並べ替えります。",
  },
  {
    element: ".guiderXGridColumn",
    position: "bottom",
    intro: "表示列",
  },
  {
    element: ".guiderXGridSearch",
    position: "bottom",
    intro: "条件を入力して、検索できます。",
  },
  {
    element: ".MuiDataGrid-main",
    position: "top",
    intro: "テーブルのデータ",
  },
  {
    element: ".MuiDataGrid-columnSeparator",
    position: "top-middle-aligned",
    intro: "コラムの大きさが変更できます",
  },
];

// マスタの事業場の初心者
export const XGridTableGuiderSteps = [
  {
    element: ".guiderToolbarButton",
    position: "bottom",
    intro: "ダイヤログを開きます。",
  },

  {
    element: ".guiderXGridExport",
    position: "bottom",
    intro: "CSVファイルをダウンロードします。",
  },
  {
    element: ".guiderXGridFilter",
    position: "bottom",
    intro: "行を並べ替えります。",
  },
  {
    element: ".guiderXGridColumn",
    position: "bottom",
    intro: "表示列",
  },
  {
    element: ".guiderXGridSearch",
    position: "bottom",
    intro: "条件を入力して、検索できます。",
  },
  {
    element: ".MuiDataGrid-main",
    position: "top-middle-aligned",
    intro: "テーブルのデータ",
  },
  {
    element: ".MuiDataGrid-columnSeparator",
    position: "top-middle-aligned",
    intro: "コラムの大きさが変更できます",
  },
];
