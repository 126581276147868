import { useState, useEffect, useRef } from "react";
import useClasses from "utils/useClasses";
import { styles } from "./styles";
import { useOpener } from "utils/useOpener";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

/**
 * コンテナ種類詳細を表示するコンテナコンポーネントです。
 * @callback render
 * @returns {JSX.Element} JSX 要素
 */
export const Container = ({
  render,
  id,
  value,
  onUpdate,
  loading,
  numberOfInstallations,
  numberOfStorage,
  numberOfActives,
  totalOfOwned,
  containers,
  onNotification,
  confirm,
  onRemove,
  ...props
}) => {
  const [tab, setTab] = useState(0);
  const formRef = useRef(null);
  const classes = useClasses(styles);
  const addDialogForm = useOpener();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const qs = new URLSearchParams(location.search);
    setTab(qs.get("tab") ? parseInt(qs.get("tab")) : 0);
  }, [location.search]);

  const handleChangeTab = (newTabNumber) => {
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams({ tab: newTabNumber })}`,
      },
      {
        replace: true,
      }
    );

    setTab(newTabNumber);
  };

  const handleClickSave = () => {
    formRef?.current?.submit();
  };

  return render({
    ...props,
    id: id,
    value: value,
    classes: classes,
    formRef: formRef,
    onClickSave: handleClickSave,
    onSubmit: onUpdate,
    loading: loading,
    tab: tab,
    onChangeTab: handleChangeTab,
    numberOfInstallations: numberOfInstallations,
    numberOfStorage: numberOfStorage,
    totalOfOwned: totalOfOwned,
    numberOfActives: numberOfActives,
    containers: containers,
    addDialogForm: addDialogForm,
    onNotification: onNotification,
    onRemove: onRemove,
    confirm: confirm
  });
};
