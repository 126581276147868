import { ContactMailOutlined, LockOutlined } from "@mui/icons-material";
import React from "react";
import { FullScreenDialogStyle2 } from "../FullScreenDialogStyle2";
import { Security, UserProfile } from "./Child";

/**
 * アカウント管理を表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <>
      <FullScreenDialogStyle2
        open={props.open}
        onClose={props.onClose}
        title="アカウント管理"
        tabs={[
          {
            icon: <ContactMailOutlined />,
            label: "ユーザープロフィール",
            content: <UserProfile />,
          },
          {
            icon: <LockOutlined />,
            label: "セキュリティ",
            content: <Security />,
          },
        ]}
      />
    </>
  );
};
