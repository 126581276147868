import { containerPresententer } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 回収記録の編集ダイアログを表示するコンポーネントです。
 * @param {boolean} open ダイアログを開くか閉じるを設定する
 * @param {func} onCloseDialog  ダイアログを閉じるイベント
 * @param {object} value 処理フローのフォームのデータ
 * @param {func} getDataSubmit フォームのデータを取得します。
 */
export const CollectDialog = containerPresententer(Container, Presententer);
