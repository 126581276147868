import { containerPresententer, withForwardRef } from "utils/HoC";
import { Container } from "./Container";
import { Presententer } from "./Presententer";

/**
 * 日付の選択するコンポーネントです。
 * @param {string} value 値
 * @param {string} placeholder プレースホルダー
 * @fires Container#onChange 変更時
 * @param {string} helperText 補助的説明文
 * @param {boolean} error エラーであるか
 * @param {string} label ラベル
 * @param {boolean} required 必須設定
 * @param {object} props その他プロパティ
 */
export const DatePicker = withForwardRef(
  containerPresententer(Container, Presententer)
);
