import { yupResolver } from "@hookform/resolvers/yup";
import { Paper } from "@mui/material";
import { useImperativeHandle } from "react";
import { Controller, useForm } from "react-hook-form";
import { withForwardRef } from "utils/HoC";
import { basicSchema } from "./Validations";
import { Groups } from "./Groups";
import { debugLog } from "utils/log";

const defaultValues = {
  sections: [],
};

export const Step2Form = withForwardRef(
  ({
    value,
    onSubmit = () => {},
    onError = debugLog,
    disabled = false,
    _ref,
  }) => {
    const { trigger, getValues, control, formState } = useForm({
      mode: "onBlur",
      resolver: yupResolver(basicSchema),
      defaultValues: {
        ...defaultValues,
        ...value,
      },
    });

    useImperativeHandle(_ref, () => ({
      submit: () => {
        trigger().then((res) => {
          if (res) {
            onSubmit(getValues());
          } else {
            onError(formState.errors);
          }
        });
      },
      get: () => getValues(),
    }));

    return (
      <Paper
        sx={{
          p: 3,
        }}
      >
        <Controller
          name={`sections`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Groups
                value={value}
                onChange={onChange}
                errors={error}
                disabled={disabled}
              />
            );
          }}
        />
      </Paper>
    );
  }
);
